import { Component } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AuthenticationService } from './auth/authentication.service';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'template';
  menuMode = 'static';

  lightMenu = false;

  inputStyle:any = 'outlined'

  ripple!: boolean;
  /*=====================Validation Variable Start =========================== */
  public static emailPattern = "^[A-Za-z0-9._%+-]+@[a-z0-9.?-]+[\.]+[a-z]{2,4}$";
  public static validQuantityPattern = "^(0|[1-9][0-9]*)$";
  public static validPricePattern = "^(0|[1-9][0-9]*)+(\.[0-9][0-9]?)?$";
  public static phonePattern = "^\\+?[0-9]{8,14}$";
  public static passwordPattern = "^[A-Za-z0-9_@./#&+-]{8,}$"
  public static strongPasswordRegx = "^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%&\*()\-+=])(?=.{8,}).*$";
  public static fiterConditionPattern = "(\\(\\d\\s(AND|OR|or|and)\\s\\d\\))?\\s?(OR|AND|or|and)?\\s?(\\(\\d\\s(AND|OR|or|and)\\s\\d\\))?\\s?(OR|AND|or|and)?\\s?(\\(\\d\\s(AND|OR|or|and)\\s\\d\\))";
  public static taxConditionPattern = "^[0-9][0-9]?$|^100$";
  public static alphabetPattern = "^[a-zA-Z.\\s*]+$"
  public static numberPattern = "^[0-9]*$"
  public static fourNumberPattern = "[1-9][0-9]{3}"
  public static regex = /^\d+(\.\d{1,2})?$/i;
  public static calendarFormat = {
    lastDay : '[Yesterday]',
    sameDay : '[]',
    nextDay : '[Tomorrow]',
    lastWeek : 'DD/MM/YYYY',
    nextWeek : 'dddd',
    sameElse : 'L'
  }
  addonFeature: any;
  /*=====================Validation Variable Ends =========================== */

  constructor(private primengConfig: PrimeNGConfig,
    private router: Router,
    private auth : AuthenticationService,
    translate: TranslateService) {
      console.log(localStorage.getItem('addonFeatureList'))
      
      if(localStorage.getItem('addonFeatureList')){
        this.addonFeature = this.auth.getAddonFeatureLists();
          if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
          translate.setDefaultLang('en-ms');
          translate.use('en-ms');
          }else{
            translate.setDefaultLang('en');
            translate.use('en');
          }
      }else{
        translate.setDefaultLang('en');
        translate.use('en');
      }
      this.router.events.subscribe((event :any)=> {
        if (event instanceof NavigationEnd) {
        //   if(event.url.indexOf('/login') >= 0 && this.auth.getToken()){
        //     if(window.innerWidth < 720){
        //       this.router.navigate(['/mobile']);
        //     } else {
        //       this.router.navigate(['/dashboard/customer/all']);
        //     }
        //   }
         }
      });

  }

  ngOnInit() {
    
    
      this.primengConfig.ripple = true;
      this.ripple = true;
      
  /*============================ Check Is Token is valid or not ================= */
    this.router.events.subscribe((event :any)=> {
        
      if (event instanceof NavigationEnd) {
      //  console.log(event.url,"-----",event.url.indexOf('login'),"====",event.url.indexOf('/login'),"===",JSON.stringify(this.auth.getToken()) === '{}',"-----",this.auth.getToken())
       if((event.url == '/' || event.url.indexOf('login') >= 0) && this.auth.getToken()){
        // //debugger
          if(window.innerWidth < 720){
            this.router.navigate(['/mobile']);
          } else {
            //console.log(" we are in")
            this.router.navigate(['/dashboard/customers']);
          }
        }
      }
    });
   
  }
}


