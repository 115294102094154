<div class="left-right-space h-100">
  <div class="flex-between mt-1 ms-2 mb-2">
    <!-- Filter and New -->
    <div class="custella-dashboard-top-right d-flex ms-auto">
      <button class="border rounded-1 px-04 ms-2 btn-32">
        <img alt="logo" src="assets\svg\refresh.svg" (click)="refresh()" />
    </button>
      <span class="p-input-icon-right w-100 ms-2" *ngIf="!noTask">
        <!-- <i class="pi pi-search me-3"></i> -->
        <input type="text" pInputText autocomplete="off" [placeholder]="'search' | translate" [(ngModel)]="searchValue" (keydown.enter)="filterTaskResults()" (keyup)="onSearchCheck()" />
        <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"></i>
      </span>
      <button type="button" class="border rounded-1 px-04 ms-2 btn-32"  (click)="filterTaskResults()">
        <img alt="logo" src="assets\svg\search-dark.svg" class="Search"/>
    </button>
      <p-button label="New" icon="pi pi-plus" styleClass="ms-2 h-32"
        *ngIf="createTask" (click)="newTask()"></p-button>
    </div>
  </div>
  <div class="position-relative fw-normal display-table h-92">
    <div class="table-space ag-grid-table-full" style="height: 100%;">
      <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefs"
        [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true" [paginationPageSize]="25" [pagination]="false" [paginationPageSize]="25"
        [rowData]="tasks" [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate" (cellClicked)="view($event)"
        [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
      <div class="col-12">
        <p class="ag-record-row-summary-panel padds text-end">
          {{ totalRecord }} {{ "records" | translate }}
        </p>
      </div>
    </div>
  </div>
</div>