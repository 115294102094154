<div id="cancel_contract_container">
    <form (ngSubmit)="onSubmit()">
        <div class="custella-modal d-flex flex-column">
            <div class="custella-modal-header">
                <div class="text-14 font-medium" id="title_CancelContra"> {{ "cancel_contract" | translate }} &dash; <span class="text-12 fst-italic">{{ "remove_items_that_need_to_be_returned" | translate }}</span></div>

                <i class="pi pi-times w-24px dialog-close-icon"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
            </div>

            <div class="custella-modal-body p-0">
                <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 p-0" style="background: none; box-shadow: none;">
                    <div class="custella-form-container-content m-0 p-0">
                        <p-tabView>
                            <p-tabPanel header="Assets">
                                <div class="d-block">
                                    <div class="headerTable">
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetName" translate>
                                            {{ "asset_name" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetStatus" translate>
                                            {{ "status" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetBin" translate>
                                            {{ "bin" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetQty" translate>
                                            {{ "qty" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetReturnQty" translate>
                                            {{ "return_qty" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAssetUpdInv" translate>
                                            {{ "update_inventory" }}
                                        </span>
                                    </div>
                                    <div class="contentTable" *ngFor="let row of contractItemsAssets; index as i">
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAssetNameField" type="text" disabled readonly value="{{ row?.assets?.name }}" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown [options]="allStatus" optionValue="value" id="picklist_CancAssetStatusField" optionLabel="label" [placeholder]="'select_status' | translate" [(ngModel)]="row.status" [ngModelOptions]="{ standalone: true }">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="" *ngIf="row.status" id="value_CancAssetStatus">
                                                        {{ returnStatus(row.status) | translate }}
                                                    </div>
                                                </ng-template>

                                                <ng-template let-status pTemplate="item" id="item_CancAssetStatus">
                                                    {{ status.label | translate }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown
                                                [options]="bins"
                                                id="picklist_CancAssetBinField"
                                                optionValue="id"
                                                optionLabel="name"
                                                [placeholder]="'select_bin' | translate"
                                                [ngModelOptions]="{ standalone: true }"
                                                (onChange)="selectBin(i)"
                                                [(ngModel)]="row.bin.id"
                                            ></p-dropdown>
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAssetQtyField" type="text" disabled readonly [(ngModel)]="row.qty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" type="number" id="input_CancAssetReturnQtyField" min="0" onkeypress="return event.charCode >= 48" [(ngModel)]="row.returnQty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-checkbox class="my-2" [binary]="true" [ngModelOptions]="{ standalone: true }" id="check_CancAssetUpdInv" [(ngModel)]="row.updateInventory"></p-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>

                            <p-tabPanel header="Accessories">
                                <div class="d-block">
                                    <div class="headerTable">
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesName" translate>
                                            {{ "asset_name" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesStatus" translate>
                                            {{ "status" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesBin" translate>
                                            {{ "bin" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesQty" translate>
                                            {{ "qty" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesReturnQty" translate>
                                            {{ "return_qty" }}
                                        </span>
                                        <span class="d-block col border px-2 py-3" id="label_CancAccesUpdInv" translate>
                                            {{ "update_inventory" }}
                                        </span>
                                    </div>
                                    <div class="contentTable" *ngFor="let row of contractItemsAccessories; index as i">
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" type="text" id="input_CancAccesNameField" disabled readonly value="{{ row?.accessory?.name }}" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown [options]="allStatus" optionValue="value" id="picklist_CancAccesStatusField" optionLabel="label" [placeholder]="'select_status' | translate" [(ngModel)]="row.status" [ngModelOptions]="{ standalone: true }">
                                                <ng-template pTemplate="selectedItem">
                                                    <div class="" *ngIf="row.status" id="value_CancAccesStatus">
                                                        {{ returnStatus(row.status) | translate }}
                                                    </div>
                                                </ng-template>

                                                <ng-template let-status pTemplate="item" id="item_CancAccesStatus">
                                                    {{ status.label | translate }}
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div class="d-block col border px-2 py-3">
                                            <p-dropdown
                                                [options]="bins"
                                                optionValue="id"
                                                id="picklist_CancAccesBinField"
                                                optionLabel="name"
                                                [placeholder]="'select_bin' | translate"
                                                [ngModelOptions]="{ standalone: true }"
                                                [ngModelOptions]="{ standalone: true }"
                                                (onChange)="selectBin1(i)"
                                                [(ngModel)]="row.bin.id"
                                            ></p-dropdown>
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAccesQtyField" type="text" disabled readonly [(ngModel)]="row.qty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <input pInputText autocomplete="off" id="input_CancAccesReturnQtyField" type="number" min="0" onkeypress="return event.charCode >= 48" [(ngModel)]="row.returnQty" [ngModelOptions]="{ standalone: true }" />
                                        </div>
                                        <div class="d-block col border px-2 py-3">
                                            <p-checkbox class="my-2" [binary]="true" id="check_CancAccesUpdInv" [ngModelOptions]="{ standalone: true }" [(ngModel)]="row.updateInventory"></p-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </p-tabPanel>
                        </p-tabView>
                    </div>
                </div>
            </div>

            <div class="custella-modal-footer d-flex align-items-center justify-content-end">
                <p-button type="submit" styleClass="h-32" [label]="'save' | translate" id="btn_CancelContraSubmit"></p-button>
            </div>
        </div>
    </form>
</div>
