<div class="custella-modal">
    <div class="custella-modal-header align-items-center">
        <div class="custella-modal-header-text"> {{ "view_order" | translate }}</div>
        <i class="pi pi-times"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRefPlanning.hide()"></i>
    </div>
    <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
        <div class="px-4 pb-3">
            <p-dropdown [options]="allItems" optionLabel="label" optionValue="id" *ngIf="allItems && allItems.length > 0" (onChange)="onSelectItemChange($event)"></p-dropdown>
        </div>

        <div class="in-screen-tabs height-80 overflow-scroll px-4 pb-4">
            <p-tabView id="viewDOTabs">
                <p-tabPanel header="Delivery Overview">
                    <div class="mb-2">
                        <app-delivery-order-header [deliveryOrder]="deliveryOrder" [showSelector]="false"></app-delivery-order-header>
                    </div>
                    
                    <app-delivery-order-status-bar [currentStatus]="deliveryOrder?.deliveryOrder.orderStatus" [currentLineItem]="currentLineItem" [deliveryOrder]="deliveryOrder"></app-delivery-order-status-bar>
                    <app-delivery-order-data-box *ngIf="currentLineItem" [viewPort]="'window'" [orderLineItem]="currentLineItem" [data]="deliveryOrder"></app-delivery-order-data-box>
                </p-tabPanel>
                <p-tabPanel header="Delivery Details">
                    <app-delivery-order-info-box [data]="deliveryOrder"></app-delivery-order-info-box>
                </p-tabPanel>
                <p-tabPanel header="Completion Summary">
                    <app-delivery-order-complete-summary [orderLineItem]="currentLineItem" [asComponent]="true"></app-delivery-order-complete-summary>
                </p-tabPanel>
                <p-tabPanel header="Timeline">
                    <app-delivery-order-timeline [deliveryOrderId]="deliveryOrder?.deliveryOrder.id"></app-delivery-order-timeline>
                </p-tabPanel>
                <p-tabPanel header="Packages">
                    <app-delivery-order-packages [line]="currentLineItem" [packages]="currentLineItem?.deleveryOrderLineItemPackages" [packageMetaData]="deliveryOrder?.deliveryOrderSummary" [asComponent]="true"></app-delivery-order-packages>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
