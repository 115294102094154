<div class="">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_VehLastLoc"> {{ "last_location" | translate }} </div>

            <i class="pi pi-times pt-1" id="btn_VehLastLocClose"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>

        <div class="custella-modal-body">
            
            <div class="custella-content-row-input mb-3 loc-calendar">
                <span class="custella-content-row-input-title-three mb-1" id="label_VehLocDate">{{ "date" | translate }}</span>
                <p-calendar class="mb-3" [defaultDate]="defaultDate" name="startDate" id="picklist_VehLocStartDateField" [placeholder]="'select_date' | translate" selectionMode="single" dateFormat="mm/dd/yy" yearRange="2000:2030" (ngModelChange)="onDateChange($event)" [(ngModel)]="dateSearch" [yearNavigator]="true"appendTo="body"></p-calendar>

              <div class="custella-form-container-content">
                <div class="custella-content-row mb-3 mt-4">
                    <div class="custella-content-row-input col-5">
                        <span class="custella-content-row-input-title-three" id="label_VehLocVehNo" translate>{{'vehicle_number'}}</span>
                    </div>
                    <div class="custella-content-row-input col-2">
                        <span class="custella-content-row-input-title-three" id="label_VehLocTime" translate>{{'time'}}</span>
                    </div>
                    <div class="custella-content-row-input col-5">
                        <span class="custella-content-row-input-title-three" id="label_VehLocLocation" translate>{{'location'}}</span>
                    </div>
                </div>

            <ng-container *ngIf="vehicleLocation">
                <div *ngFor="let vehicleLC of vehicleLocation;let i = index">
                    <form [formGroup]="vehicleLocationForm[i]">
                        <div class="custella-content-row mb-3">
                            <!-- NAME -->
                            <div class="custella-content-row-input col-5">
                                <span id="value_VehLocVehName">{{vehicleLC.vehicle.name}}</span>
                            </div>
                            <!-- TIME -->
                            <div class="custella-content-row-input col-2">
                                <input type="time" pInputText autocomplete="off" [(ngModel)]="vehicleLC.arrivalTime1" formControlName="arrivalTime" id="input_VehLocTimeField"  name="time" >
                                <div class="p-error text-10" *ngIf="vehicleLocationForm[i].controls['arrivalTime'].touched && vehicleLocationForm[i].controls['arrivalTime'].invalid">
                                    <span *ngIf="vehicleLocationForm[i].controls['arrivalTime'].errors?.required" id="label_VehLocTimeFieldReqField" translate>{{'required_field'}}</span>
                                </div>
                            </div>
                            <!-- LOCATION -->
                            <div class="custella-content-row-input col-4">
                                <input ngx-google-places-autocomplete  type="text" pInputText autocomplete="off" id="input_VehLocLocationField" [(ngModel)]="vehicleLC.address" name="address" formControlName="location" #placesRef="ngx-places" (change)="resetAddress(i)" (onAddressChange)="handleAddressChange($event,i)">
                            </div>
                            <!-- Button -->
                            <div class="custella-content-row-input col-1">
                                <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded ms-2" id="btn_VehLocHome" (click)="setStartLocation('home',i)"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </ng-container>

              </div>

            </div>
        </div>

        <div class="custella-modal-footer d-flex align-items-center justify-content-end">
            <p-button type="submit" id="btn_VehLocSubmit" [label]="'save' | translate" [disabled]="submitTouched && !update"  (onClick)="submitForm()" type="submit" styleClass="ms-2 h-32"></p-button>
        </div>
    </div>
</div>
