
<!-- Bootstrap Modal -->

<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_SlaCriteriaDetsStart"  *ngIf="data.criteriaType=='startCriteria'"> {{ "start_criteria_details" | translate }} </span>
            <span class="text-14 font-medium ms-2" id="title_SlaCriteriaDetsPause" *ngIf="data.criteriaType=='pauseCriteria'"> {{ "pause_criteria_details" | translate }} </span>
            <span class="text-14 font-medium ms-2" id="title_SlaCriteriaDetsEnd" *ngIf="data.criteriaType=='endCriteria'"> {{ "end_criteria_details" | translate }} </span>

            <i class="pi pi-times" id="btn_SlaCriteriaDetsClose" (click)="modalRef.hide()"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 height-80 overflow-scroll m-0">
                <!-- Match All -->
                <form [formGroup]="addFilterForm">
                    <div class="custella-form-container-title-two-grey d-flex align-items-center">
                        <div class="text-14 font-medium me-2" id="title_SlaCritMatchAll">{{"match_all" | translate}} </div>
                        <div class="text-10" id="label_SlaCritMatchAllDesc"><i>(SLA will be active if ALL these criteria are met)</i></div>
                    </div>
                    <div class="custella-form-container-content my-2">
                        <div class="custella-content-row-four col-12 justify-content-between">
                            <div class="d-flex col-11">
                                <!-- Attribute -->
                                <div class="custella-content-row-four-input col-3">
                                    <span class="custella-content-row-input-title" id="label_SlaCritAllAttribute">Attribute</span>
                                    <p-dropdown (onChange)="setAttr($event)" [options]="filterAttrs" id="picklist_SlaCritAllAttributeField" filterBy="valueName" optionGroupChildren="items" optionLabel="valueName"  formControlName="attr"   name="attr"  placeholder="{{'select_an_attribute' | translate}}"
                                        [(ngModel)]="selectedAttr" [group]="true" [filter]="true">
                                        <ng-template let-group pTemplate="group" id="value_SlaCritAllAttributeField">
                                            {{group.valueName}}
                                        </ng-template>
                                        <ng-template let-item pTemplate="modelFieldList" id="item_SlaCritAllAttributeField">
                                            {{item.valueName | titlecase}}
                                        </ng-template>
                                    </p-dropdown>
                                    <div *ngIf="addFilterForm.controls['attr'].touched && addFilterForm.controls['attr'].invalid">
                                        <span class="text-10 p-error" *ngIf="addFilterForm.controls['attr'].errors?.required" id="label_SlaCritAllAttributeReqField" translate>{{'required_field'}}</span>
                                    </div>
                                </div>
                                <!--------------------------------------- Operator---------------------------------------------------------------->
                                <div class="custella-content-row-four-input col-2">
                                    <span class="custella-content-row-input-title" id="label_SlaCritAllOperator">Operator</span>
                                    <p-dropdown (onChange)="setOperator($event)" [options]="displayOperators" id="picklist_SlaCritAllOperatorField" optionValue="value"  optionLabel="name"  formControlName="operator"   name="operator"  placeholder="{{'select_an_operator' | translate}}"
                                    [(ngModel)]="selectedOper" [filter]="true">
                                   </p-dropdown>
                                   <div  *ngIf="addFilterForm.controls['operator'].touched && addFilterForm.controls['operator'].invalid">
                                    <span class="text-10 p-error" *ngIf="addFilterForm.controls['operator'].errors?.required" id="label_SlaCritAllOperatorReqField" translate>{{'required_field'}}</span>
                                  </div>
                                </div>
                                <!--------------------------------------- Value Section---------------------------------------------------------------->
                                <div class="custella-content-row-four-input col-6" *ngIf="selectedAttr">
                                    <span class="custella-content-row-input-title" id="label_SlaCritAllValue">Value</span>
                                    <!--------------------------------------- Input Text---------------------------------------------------------------->
                                    <div class="col-6" *ngIf="selectedAttr?.type == 'INPUT_FIELD' && (selectedAttr?.operator != 'IS NOT NULL' && selectedAttr?.operator != 'IS NULL' )">  
                                        <input type="text" pInputText autocomplete="off" id="input_SlaCritAllValueField" [(ngModel)]="selectedValue" formControlName="value" name="value"  >
                                        <div *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                            <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SlaCritAllValueReqField" translate>{{'required_field'}}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Multi Choice---------------------------------------------------------------->
                                    <div class="col-6" *ngIf="selectedAttr?.type == 'MULTIPLE_CHOICE'">
                                        <p-multiSelect [options]="optionList" formControlName="value" [(ngModel)]="valueArray" id="picklist_SlaCritAllValueField" [maxSelectedLabels]="5" [selectedItemsLabel]="'{0} items selected'" optionLabel="itemName" (onChange)="onSelectAll($event)"></p-multiSelect>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                            <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SlaCritAllValueMultiReqField" translate>{{'required_field'}}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Date Time && Not Between---------------------------------------------------------------->
                                   <div class="col-6" *ngIf="selectedAttr?.type == 'DATE_TIME' && selectedOper !== 'BETWEEN' && (selectedAttr?.operator != 'IS NOT NULL' && selectedAttr?.operator != 'IS NULL' )">
                                        <p-calendar  dateFormat="dd/mm/yy"    selectionMode="single" id="picklist_SlaCritAllValueDateTimeField" placeholder="{{ 'select_date' | translate }}" formControlName="value" name="selectedDate" [(ngModel)]="selectedDate" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                            <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SlaCritAllValueDateTimeReqField" translate>{{'required_field'}}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Date Time &&  Between---------------------------------------------------------------->
                                   <div class="d-flex" *ngIf="selectedAttr?.type == 'DATE_TIME' && selectedOper === 'BETWEEN'">
                                        <div class="col">
                                            <p-calendar  dateFormat="dd/mm/yy" yearRange="2000:2030" id="picklist_SlaCritAllValueDateStartField"  selectionMode="single" placeholder="{{ 'select_date' | translate }}" formControlName="value" name="selectedDate" [(ngModel)]="selectedDate" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                            <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value'].touched && addFilterForm.controls['value'].invalid">
                                                <span class="text-10 p-error" *ngIf="addFilterForm.controls['value'].errors?.required" id="label_SlaCritAllValueDateStartReqField" translate>{{'required_field'}}</span>
                                            </div> 
                                        </div> 
                                        <div class="mx-2 mt-2" >-</div>
                                        <div class="col">
                                            <p-calendar  dateFormat="dd/mm/yy" yearRange="2000:2030" id="picklist_SlaCritAllValueDateEndField"  selectionMode="single" placeholder="{{ 'select_date' | translate }}" formControlName="value2" name="selectedDate2" [(ngModel)]="selectedDate2" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                            <div class="alert col px-0 py-0 text-right" *ngIf="addFilterForm.controls['value2'].touched && addFilterForm.controls['value2'].invalid">
                                                <span class="text-10 p-error" *ngIf="addFilterForm.controls['value2'].errors?.required" id="label_SlaCritAllValueDateEndReqField" translate>{{'required_field'}}</span>
                                            </div> 
                                        </div> 
                                    </div>
    
                                </div>
                            </div>
                            <!-- Remove and Add -->
                            <div class="custella-content-row-four-input col-1 d-flex justify-content-end">
                                <div class="text-10 cursor-pointer mt-3 pt-3 me-3" *ngIf="selectedAttr" id="btn_SlaCritAllValueClear" style="color: #6366F1; font-weight:500" (click)="removeSelectedOption()">Clear</div>
                               <div class="mt-3 pt-1"> <p-button icon="pi pi-plus" id="btn_SlaCritAllValueAdd" (click)="addNewFilter()"></p-button></div>
                            </div>
                         

                        </div>
                        <div class="custella-content-row my-0">
                            <span  class="custella-content-row-input-sub p-error" id="title_SlaCritAllErrorMsg">{{errorMessage}}</span>
                        </div>

                        <div class="custella-content-row pe-0">
                            <div class="custella-grey-box" style="background-color: white;">
                                <div class="">
                                    <div class="filter-item mr-2 mb-2 d-inline-block" *ngFor="let filter of filterArray">
                                        <div class="rounded-5 bg-purple px-2 py-1 mx-1 d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <span class="text-12 font-white text-nowrap px-1" id="label_SlaCritAllValueName">{{filter?.valueName}}</span>
                                                
                                                <div *ngIf="filter.type == 'MULTIPLE_CHOICE'" class="text-12 font-white text-nowrap px-1">
                                                    <span class="px-1" id="label_SlaCritAllPickValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="label_SlaCritAllPickValueString">
                                                        {{value?.stringValue}}
                                                        <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SlaCritAllPickValueStringSep">,</span>
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAllBetValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="label_SlaCritAllBetValueDate">
                                                        {{value.value | momentDate : dateFormat : timezone}}  {{value.value | momentDate : 'hh:mm a' : timezone}}
                                                       <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SlaCritAllBetValueDateSep">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAllDateTimeValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="label_SlaCritAllDateTimeValueDateTime">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}} {{filter?.modelFieldFilterList[0]?.value | momentDate : 'hh:mm a' : timezone}}
                                                    </span>
                                                </div>

                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAllDateBetValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="label_SlaCritAllDateBetValueDate">
                                                        {{value.value | momentDate : dateFormat : timezone}} 
                                                       <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SlaCritAllDateBetValueDateSep">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAllDateValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="label_SlaCritAllDateValueTimezone">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}}
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type != 'DATE' && filter.type != 'DATE_TIME' && filter.type != 'MULTIPLE_CHOICE' && (filter.operator !== 'BETWEEN')">
                                                    <span class="px-1" id="label_SlaCritAllValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="label_SlaCritAllValue">{{filter?.modelFieldFilterList[0]?.value}}</span>
                                                </div>
                                            </div>
                                        
                                            <i class="pi pi-times-circle font-white" id="btn_SlaCritAllRemove" (click)="removeFilterOption(filter)"></i>
                                        </div>
                                    </div>
                                    

                                </div>                          
                            </div>
                        </div>
                    </div>
                </form>

                <!-- Match Any -->
                <div class="custella-form-container-title-two-grey d-flex align-items-center">
                    <div class="text-14 font-medium me-2" id="title_SlaCritMatchAny">{{"match_any" | translate}} </div>
                    <div class="text-10" id="label_SlaCritMatchAnyDesc"><i>(SLA will be active if ANY these criteria are met)</i></div>
                </div>
                <form [formGroup]="addAnyFilterForm">
                    <div class="custella-form-container-content my-2">
                        <div class="custella-content-row-four col-12 justify-content-between">

                            <div class="d-flex col-11">
                                <!-- Attribute -->
                                <div class="custella-content-row-four-input col-3">
                                    <span class="custella-content-row-input-title" id="label_SlaCritAnyAttribute">Attribute</span>
                                    <p-dropdown (onChange)="setAttrAny($event)" [options]="filterAttrs" filterBy="valueName" id="picklist_SlaCritAnyAttributeField" optionGroupChildren="items" optionLabel="valueName"  formControlName="attrAny"   name="attrAny"  placeholder="{{'select_an_attribute' | translate}}"
                                            [(ngModel)]="selectedAttrAny" [group]="true" [filter]="true">
                                            <ng-template let-group pTemplate="group" id="value_SlaCritAnyAttributeField">
                                                {{group.valueName}}
                                            </ng-template>
                                            <ng-template let-item pTemplate="modelFieldList" id="item_SlaCritAnyAttributeField">
                                                {{item.valueName | titlecase}}
                                            </ng-template>
                                    </p-dropdown>
                                    <div *ngIf="addAnyFilterForm.controls['attrAny'].touched && addAnyFilterForm.controls['attrAny'].invalid">
                                        <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['attrAny'].errors?.required" id="label_SlaCritAnyAttributeReqField" translate>{{'required_field'}}</span>
                                    </div>
                                </div>

                                <!-- Operator -->
                                <div class="custella-content-row-four-input col-2">
                                    <span class="custella-content-row-input-title" id="label_SlaCritAnyOperator">Operator</span>
                                    <p-dropdown (onChange)="setOperatorAny($event)" [options]="displayOperatorsAny" id="picklist_SlaCritAnyOperatorField" optionValue="value"  optionLabel="name"  formControlName="operatorAny"   name="operatorAny"  placeholder="{{'select_an_operator' | translate}}"
                                        [(ngModel)]="selectedOperAny" [filter]="true">
                                    </p-dropdown>
                                    <div  *ngIf="addAnyFilterForm.controls['operatorAny'].touched && addAnyFilterForm.controls['operatorAny'].invalid">
                                        <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['operatorAny'].errors?.required" id="label_SlaCritAnyOperatorReqField" translate>{{'required_field'}}</span>
                                    </div>
                                </div>

                                <!--------------------------------------- Value Section---------------------------------------------------------------->
                                <div class="custella-content-row-four-input col-6" *ngIf="selectedAttrAny">
                                    <span class="custella-content-row-input-title" id="label_SlaCritAnyValue">Value</span>
                                    <!--------------------------------------- Input Text---------------------------------------------------------------->
                                    <div class="col-6" *ngIf="selectedAttrAny?.type == 'INPUT_FIELD' && (selectedAttrAny?.operator != 'IS NOT NULL' && selectedAttrAny?.operator != 'IS NULL' )">  
                                        <input type="text" pInputText autocomplete="off" id="input_SlaCritAnyValueField" [(ngModel)]="selectedValueAny" formControlName="valueAny" name="valueAny"  >
                                        <div  *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SlaCritAnyValueReqField" translate>{{'required_field'}}</span>
                                        </div> 
                                    </div>
                                    <!--------------------------------------- Multi Choice---------------------------------------------------------------->
                                <div class="col-6" *ngIf="selectedAttrAny?.type == 'MULTIPLE_CHOICE'">
                                
                                <p-multiSelect [options]="optionListAny" formControlName="valueAny" [(ngModel)]="valueArrayAny" id="picklist_SlaCritAnyValueField" [maxSelectedLabels]="5" [selectedItemsLabel]="'{0} items selected'"
                                optionLabel="itemName" (onChange)="onSelectAllAny($event)"></p-multiSelect>

                                    <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                        <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SlaCritAnyValueMultiReqField" translate>{{'required_field'}}</span>
                                </div> 
                                </div>
                                    <!--------------------------------------- Date Time && Not Between---------------------------------------------------------------->
                                <div class="col-6" *ngIf="selectedAttrAny?.type == 'DATE_TIME' && selectedOperAny !== 'BETWEEN' && (selectedAttrAny?.operator != 'IS NOT NULL' && selectedAttrAny?.operator != 'IS NULL' )">
                                        <p-calendar  dateFormat="dd/mm/yy"  id="picklist_SlaCritAnyValueDateTimeField"  selectionMode="single" placeholder="{{ 'select_date' | translate }}" formControlName="valueAny" name="selectedDateAny" [(ngModel)]="selectedDateAny" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SlaCritAnyValueDateTimeReqField" translate>{{'required_field'}}</span>
                                    </div> 
                                    </div>
                                    <!--------------------------------------- Date Time &&  Between---------------------------------------------------------------->
                                <div class="d-flex" *ngIf="selectedAttrAny?.type == 'DATE_TIME' && selectedOperAny === 'BETWEEN'" class="d-flex">
                                    <div class="col">
                                        <p-calendar  dateFormat="dd/mm/yy" id="picklist_SlaCritAnyValueDateStartField"  selectionMode="single" placeholder="{{ 'select_date' | translate }}" formControlName="valueAny" name="selectedDateAny" [(ngModel)]="selectedDateAny" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny'].touched && addAnyFilterForm.controls['valueAny'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny'].errors?.required" id="label_SlaCritAnyValueDateStartReqField" translate>{{'required_field'}}</span>
                                        </div> 
                                    </div> 
                                    <div class="mx-2 mt-2" >-</div>
                                    <div class="col">
                                        <p-calendar  dateFormat="dd/mm/yy"   id="picklist_SlaCritAnyValueDateEndField" selectionMode="single" placeholder="{{ 'select_date' | translate }}" formControlName="valueAny2" name="selectedDate2" [(ngModel)]="selectedDateAny2" [showTime]="true" hourFormat="12" appendTo="body"></p-calendar>
                                        <div class="alert col px-0 py-0 text-right" *ngIf="addAnyFilterForm.controls['valueAny2'].touched && addAnyFilterForm.controls['valueAny2'].invalid">
                                            <span class="text-10 p-error" *ngIf="addAnyFilterForm.controls['valueAny2'].errors?.required" id="label_SlaCritAnyValueDateEndReqField" translate>{{'required_field'}}</span>
                                        </div> 
                                    </div> 
                                </div>

                                </div>
                            </div>

                            <div class="custella-content-row-four-input col-1 d-flex justify-content-end">
                                <div class="text-10 cursor-pointer mt-3 pt-3 me-3" id="btn_SlaCritAnyValueClear" *ngIf="selectedAttrAny" style="color: #6366F1; font-weight:500" (click)="removeSelectedOptionAny()">Clear</div>
                                <div class="mt-3 pt-1"> <p-button icon="pi pi-plus" id="btn_SlaCritAnyValueAdd" (click)="addNewFilterAny()"></p-button></div>
                            </div>
                        </div>



                        <div class="custella-content-row my-0">
                            <span  class="custella-content-row-input-sub p-error" id="title_SlaCritAnyErrorMsg">{{errorMessageAny}}</span>
                        </div>
                        <div class="custella-content-row pe-0">
                            <div class="custella-grey-box" style="background-color: white;">
                                <div class="">
                                    <div class="filter-item mr-2 mb-2 d-inline-flex" *ngFor="let filter of filterArrayAny">
                                        <div class="rounded-5 bg-purple px-2 py-1 mx-1 d-flex align-items-center">
                                            <div class="d-flex align-items-center">
                                                <span class="text-12 font-white text-nowrap px-1" id="label_SlaCritAnyValueName">{{filter?.valueName}}</span>
                                                
                                                <div *ngIf="filter.type == 'MULTIPLE_CHOICE'" class="text-12 font-white text-wrap px-1">
                                                    <span class="px-1" id="label_SlaCritAnyPickValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="label_SlaCritAnyPickValueString">
                                                        {{value?.stringValue}}
                                                        <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SlaCritAnyPickValueStringSep">,</span>
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white text-wrap px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAnyBetValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="label_SlaCritAnyBetValueDate">
                                                        {{value.value | momentDate : dateFormat : timezone}}  {{value.value | momentDate : 'hh:mm a' : timezone}}
                                                    <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SlaCritAnyBetValueDateSep">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white text-wrap px-1" *ngIf="filter.type == 'DATE_TIME' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAnyDateTimeValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="label_SlaCritAnyDateTimeValueDateTime">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}} {{filter?.modelFieldFilterList[0]?.value | momentDate : 'hh:mm a' : timezone}}
                                                    </span>
                                                </div>

                                                <div class="text-12 font-white text-wrap px-1" *ngIf="filter.type == 'DATE' && filter.operator == 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAnyDateBetValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" *ngFor="let value of filter?.modelFieldFilterList; let i = index;" id="label_SlaCritAnyDateBetValueDate">
                                                        {{value.value | momentDate : dateFormat : timezone}} 
                                                    <span *ngIf="filter?.modelFieldFilterList.length > 1 && i < filter?.modelFieldFilterList.length -1" id="label_SlaCritAnyDateBetValueDateSep">-</span>
                                                    </span>
                                                </div>
                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type == 'DATE' && filter.operator != 'BETWEEN'">
                                                    <span class="px-1" id="label_SlaCritAnyDateValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="label_SlaCritAnyDateValueTimezone">
                                                        {{filter?.modelFieldFilterList[0]?.value | momentDate : dateFormat : timezone}}
                                                    </span>
                                                </div>


                                                <div class="text-12 font-white text-nowrap px-1" *ngIf="filter.type != 'DATE' && filter.type != 'DATE_TIME' && filter.type != 'MULTIPLE_CHOICE' && (filter.operator !== 'BETWEEN')">
                                                    <span class="px-1" id="label_SlaCritAnyValueOperatorName">{{filter?.operatorName}}</span>
                                                    <span class="px-1" id="label_SlaCritAnyValue">{{filter?.modelFieldFilterList[0]?.value}}</span>
                                                </div>
                                            </div>
                                        
                                            <i class="pi pi-times-circle font-white" id="btn_SlaCritAnyRemove" (click)="removeFilterOptionAny(filter)"></i>
                                        </div>
                                    </div>
                                    

                                </div>                          
                            </div>
                        </div>
                    </div>
                 </form>
                <div class="custella-form-container-footer-two pb-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_SlaCriteriaCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                        <p-button [label]="buttonLabel" (click)="checkValidation()" id="btn_SlaCriteriaSubmit" styleClass="ms-2 h-32"></p-button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>