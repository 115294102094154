import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-salesforce-integration',
  templateUrl: './salesforce-integration.component.html'
})
export class SalesforceIntegrationComponent implements OnInit {

  company : any = {};
  title: any;
  submitTouched : boolean = false;

  constructor(private settingsService : SettingService,
    private errorUtil: ErrorUtil,
    public modalRef : BsModalRef) { }

  ngOnInit() {
    this.settingsService.getOrgSFSettings().subscribe((res:any) => {
      this.company = res;
    })
  }

  onSubmit(){
    this.submitTouched = true;
    var data = {
      tenantId: 1,
      syncCustomerToSf: this.company.syncCustomerToSf,
      syncConstactToSf: this.company.syncConstactToSf,
      syncJoToSf: this.company.syncJoToSf,
      syncSoToSf: this.company.syncSoToSf,
      syncProductToSf: this.company.syncProductToSf
    }
    this.settingsService.updateOrgSFSettings(data).subscribe((res:any) => {
      if(res.status === 200 || res.status === 201){
        this.errorUtil.setErrorMessage(200, "Salesforce settings updated successfully", null, "success", 2000);
      this.modalRef.hide();
      }
    })
  }

}
