import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { AppEvents, PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { SettingService } from "src/app/_services/admin/setting.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { VehicleService } from "src/app/_services/vehicle.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { first } from "rxjs/operators";

@Component({
  selector: "app-vehicle-details",
  templateUrl: "./vehicle-details.component.html",
  styleUrls: ["./vehicle-details.component.scss"],
})
export class VehicleDetailsComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Input() vehicleDetail: any;

  public displayTable = false;

  public dateFormat!: string;
  public timezone!: string;

  public addonFeatureSNL: any;

  public orgSettings: any;

  public create: any;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private settingService: SettingService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private errorUtil: ErrorUtil,
    private vehicleService: VehicleService,
    private perm: PermissionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.create = this.perm.capable("vehicles", "create");

    this.dateFormat = localStorage.getItem("date_format")!;
    this.timezone = this.auth.getUserTimezone()!;

    this.addonFeatureSNL = this.auth.getAddonFeatureLists().addonSNL;

    this.push(
      this.settingService.getOrgSettings(1).subscribe((res: any) => {
        this.orgSettings = res;
      })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Open Vehicle Type ******************************************/
  public openVehicleType(id: any) {
    this.router.navigate(
      ["/dashboard/setup/fleetSetup/vehicleType/view/" + id],
      { queryParams: { id: id } }
    );
  }

  /******************************************** Remove Driver ******************************************/
  public deleteDriver(value: any) {
    const item = {
      deleteType: "Driver Vehicle",
      id: value.id,
      driverId: value.driver.id,
      vehicleId: value.vehicle.id,
      message: `Are you sure you want to remove ${value.driver.user.fullName} from ${value.vehicle.name}?`,
    };

    this.deleteRecordService
      .getItems(item)
      .pipe(first())
      .subscribe((data) => {
        if (data.message.toLowerCase() === "success") {
          this.errorUtil.setErrorMessage(
            200,
            "Driver removed from vehicle successfully",
            null,
           1
          );

          this.util.sendData({
            action: AppEvents.REFRESH_VEHICLE_DETAILS,
            data: null,
          });
        }
      });
  }

  /******************************************** Add Driver ******************************************/
  public addDriver() {
    this.displayTable = true;
  }

  /******************************************** Close Table ******************************************/
  public closeTable() {
    this.displayTable = false;
  }

  /******************************************** Got Value ******************************************/
  public gotValue($event: any) {
    this.closeTable();
    const addVehicle: any = { driver: {}, vehicle: {}, isDefault: true };

    let finalData = [];
    addVehicle.vehicle = this.vehicleDetail.vehicle;
    addVehicle.driver = $event;
    finalData.push(addVehicle);

    this.push(
      this.vehicleService.assignVehicleToDriver(finalData).subscribe((data) => {
        if (data.status === 200) {
          this.util.sendData({
            action: AppEvents.REFRESH_VEHICLE_DETAILS,
            data: null,
          });
        } else {
          this.errorUtil.setErrorMessage(
            data.status,
            null,
            data.error.title,
            "error",
            2000
          );
        }
      })
    );
  }

  /******************************************** Navigate To ******************************************/
  public navigateToDriver(driver: any) {
    this.router.navigate(["dashboard/setup/drivers/view"], {
      queryParams: { id: driver.id, edit: false },
    });
  }
  push(obs: any) {
    super.push(obs);
  }
}
