import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";

import * as moment from "moment-timezone";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { VehicleService } from "src/app/_services/vehicle.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-trips-table",
  templateUrl: "./trips-table.component.html",
  styleUrls: ["./trips-table.component.scss"],
})
export class TripsTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public gridOptionsRoutes!: Partial<GridOptions>;
  public columnDefsRoutes: any;
  public defaultColDef: any;
  public gridApi: any;

  public totalRecord: any;

  @Input() vehicleId: any = null;
  @Input() driverId: any = null;
  public rowData: any[] = [];
  public noRoutes = false;

  constructor(
    private router: Router,
    private vehicleService: VehicleService,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.gridOptionsRoutes = {
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      enableRangeSelection: true,
      enableCharts: true,
      popupParent: document.body,
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefsRoutes = [
      {
        headerName: "Route No.",
        field: "routeNumber",
        headerTooltip: "#",
        width: 170,
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.routeNumber;
          }
        },
      },
      {
        headerName: "Plan",
        field: "planNumber",
        width: 100,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
      },
      {
        headerName: "Start Date",
        field: "planDateTime",
        width: 110,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return moment(params.value).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: "Distance",
        field: "statistics.distance",
        width: 100,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          return (
            (parseInt(params.data.statistics.distance) * 0.001).toFixed(2) +
            " km"
          );
        },
      },
      {
        headerName: "Duration",
        field: "statistics.duration",
        width: 100,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border-left",
        cellRenderer: function (params: any) {
          return (
            (parseInt(params.data.statistics.duration) / 60).toFixed(2) +
            " hours"
          );
        },
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
  }

  /******************************************** On Grid Routes ******************************************/
  public onGridReadyRoutes(params: any) {
    if (params) {
      this.gridApi = params.api;

      this.push(
        this.vehicleService
          .getAllVehicleRoutes(this.vehicleId, this.driverId)
          .subscribe((data: any) => {
            this.rowData = data.data;
            if (data.status === "NO_DATA") {
              this.noRoutes = true;
            } else {
              this.noRoutes = false;
            }

            this.totalRecord = data.total;
          })
      );

      this.gridApi.sizeColumnsToFit();
    }
  }

  /******************************************** On Click Cell ******************************************/
  public openRoute(e: any) {
    if (e.colDef.field === "routeNumber") {
      this.router.navigate(["dashboard/vehicles/trips/" + e.data.id], {
        queryParams: {
          routeId: e.data.id,
          routeNumber: e.data.routeNumber,
          vehicleId: this.vehicleId,
        },
      });
    } else if (e.colDef.field === "planNumber") {
      this.router.navigate(
        ["dashboard/planningHub/view"],
        { queryParams: { id: e.data.planId } }
      );
    }
  }

  /******************************************** Get Context Menu Items ******************************************/
  public getContextMenuItems = (params: any) => {
    let result: any[] = [];

    params.node.data.field = params.column.colId;
    if (
      params.column.colId == "routeNumber" ||
      params.column.colId == "planNumber"
    ) {
      result = [
        {
          name: "Open link in new tab",
          action: () => this.util.openItemToNewTab(params.node.data),
        },
      ];
    }

    return result;
  };

  /******************************************** Add ALl the Subscription ******************************************/
 push(obs:any) {
  super.push(obs);
}
}
