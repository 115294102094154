import {Component,OnInit,ChangeDetectorRef,ViewChild,ElementRef,NgZone,Input,Output,EventEmitter,OnChanges, SimpleChange, SimpleChanges, AfterViewInit} from "@angular/core";
import * as _ from "lodash";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
declare var google: any;
@Component({
  selector: 'app-search-google-map',
  templateUrl: './search-google-map.component.html',
  styleUrls: ['./search-google-map.component.scss']
})
export class SearchGoogleMapComponent implements OnInit,OnChanges, AfterViewInit {
  loadingBay: any = {locationLat: null,locationLong: null,address: null,street: null,city: null,state: null,postCode: null,country: null,homeLocation: ""};
  homePlaceChanged: boolean = false;
  autocomplete: any;
  autocompleteLsr: any;
  flag: boolean = true;
   zoom!: number;
  @ViewChild("input_SearGoogAddressField", {static: false})
  public homeAddressSearchElementRef!: ElementRef;
  locationLat: any = 3.100117;
  locationLong: any = 101.59091899999999;
  address: any;
  @Output() addressData = new EventEmitter();
  @Input() data: any;
  @Input() searchBoxShow: any;
  @Input() errorMessage: any;
  @Input() readOnly: any;

  @Input() showLocateBtn = true;
  homeLocation:any
  // https://mapstyle.withgoogle.com/ (for theme style change json can copy from here - click "FINISH" button)
 
  options: any;
  overlays!: any[];
  @ViewChild('gmap', { static: false })
  gmap!: ElementRef;
  activeState: boolean= true
  infoWindow: any;
  homeAdd: any;

  readOnlyFields = {
    latitude: false,
    longitude: false
  }
  countries: any;

  constructor(private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private util: UtilServiceService,
    ) { }

  ngOnInit() {
    this.countries = this.util.getCountryList();
     this.loadingBay = this.data;
     console.log(this.loadingBay,"====")
      this.locationLat = this.data.locationLat ? this.data.locationLat : 3.100117;
      this.locationLong = this.data.locationLong ? this.data.locationLong : 101.59091899999999;
      this.options = {
        center: {lat: this.locationLat, lng: this.locationLong},
        zoom: 16,
        scrollwheel:true
    }
    ////debugger
      this.initOverlays(this.locationLat,this.locationLong);
    
        this.infoWindow = new google.maps.InfoWindow();
    
      console.log( this.options)   
  }
  i: any=0
  initOverlays(lat : any, lng : any) {
    console.log(lat,"===",lng,"====",this.i)
   
    if(this.i === 0){
    console.log(lat,"===",lng,"====",this.overlays)
   
        this.overlays = [
            new google.maps.Marker({position: {lat:lat, lng: lng}, title:"Konyaalti"}),
           ];
          this.i++
    }
    
}

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.loadingBay,"====")
    console.log(changes,"changes===")
    if (changes['errorMessage']) {
      this.errorMessage= this.errorMessage;
    }

    if (changes?.readOnly) {
      this.allowFields();
    }
  }
  ngAfterViewInit(): void {
    console.log(this.loadingBay,"*******")
    
  }

  initLisner() {
    if (this.autocompleteLsr)
      google.maps.event.removeListener(this.autocompleteLsr);
    var addressFormatForm: any = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      sublocality_level_1: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };
    
    this.autocompleteLsr = this.autocomplete.addListener("place_changed",() => {
     
        this.homePlaceChanged = true;
        this.loadingBay.street = "";
        this.loadingBay.city = "";
        this.loadingBay.state = "";
        this.loadingBay.postCode = "";
        this.loadingBay.country = "";
        this.loadingBay.locationLat = "";
        this.loadingBay.locationLong = "";
        this.ngZone.run(() => {
          //get the place result
          let place: any = this.autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.homeAdd = document.getElementById("input_SearGoogAddressField")
          console.log(place,"====",this.homeAdd.value,"=====",this.homeLocation)
          //debugger
          //set latitude, longitude and zoom
          this.overlays = [
            new google.maps.Marker({position: {lat:place.geometry.location.lat(), lng: place.geometry.location.lng()}, title:"Konyaalti",draggable: true})];
            ////debugger
          const markerAddress = new google.maps.Marker({position: {lat:place.geometry.location.lat(), lng: place.geometry.location.lng()}, title:"Konyaalti",draggable: true});
         (this.gmap as any).map.panTo(markerAddress.position);
         var zooming = (this.gmap as any).getMap()
         zooming.setZoom(16);
        
          this.loadingBay.locationLat = place.geometry.location.lat();
          this.locationLat = place.geometry.location.lat();
          this.loadingBay.locationLong = place.geometry.location.lng();
          this.locationLong = place.geometry.location.lng();
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (addressFormatForm[addressType]) {
              var checkAddress = _.includes(place.address_components[i].types,addressType);
              if (checkAddress) {
                if (addressType === "route" ||addressType === "sublocality_level_1" ||addressType === "sublocality" ||addressType === "street_number") {
                  if (this.loadingBay.street === "") {
                    this.loadingBay.street += place.address_components[i][addressFormatForm[addressType]];
                  } else {
                    this.loadingBay.street += ", " + place.address_components[i][addressFormatForm[addressType]];
                  }
                } else if (addressType === "locality" ||addressType === "political") {
                  this.loadingBay.city = place.address_components[i][addressFormatForm[addressType]];
                } else if (addressType === "administrative_area_level_1") {
                  this.loadingBay.state = place.address_components[i][addressFormatForm[addressType]];
                } else if (addressType === "postal_code") {
                  this.loadingBay.postCode = place.address_components[i][addressFormatForm[addressType]];
                } else if (addressType === "country") {
                  this.loadingBay.country = place.address_components[i][addressFormatForm[addressType]];
                }
              }
            }
          }
          if (place.types.includes("establishment")) {
            this.loadingBay.street = place.name + ", " + this.loadingBay.street;
          }
          // this.loadingBay.homePlaceId = place.place_id;
           this.setData(this.loadingBay);
          // this.cdr.detectChanges();
        });
        setTimeout(() => {
          this.homePlaceChanged = false;
        }, 2000);
      }
    );
  }

  callGoogleAutocomplete() {
    //load Places Autocomplete(search time calling)
    
      if (this.autocomplete)
        google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = new google.maps.places.Autocomplete(
        this.homeAddressSearchElementRef.nativeElement,
        {
          // Specify only the fields you need
          fields: ['place_id', 'geometry', 'name', 'address_components','types'],
        }
      );
      this.initLisner();
   
  }

  getPlaceApiCall(event:any) {
    let field = document.getElementById("input_SearGoogAddressField");
    let val = event.target.value;
    if (val.length < 3) {
      google.maps.event.removeListener(this.autocompleteLsr);
      google.maps.event.clearInstanceListeners(field);
      if (this.autocomplete)
        google.maps.event.clearInstanceListeners(this.autocomplete);
      $(".pac-container").remove();
      this.flag = true;
    } else if (this.flag) {
      this.callGoogleAutocomplete();
      this.flag = false;
    }
  }
  manualSearch() {
    debugger
    setTimeout(() => {
      var addressFormatForm: any = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        sublocality_level_1: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
        postal_code: "short_name",
      };
      if (!this.homePlaceChanged) {
        this.loadingBay.street = "";
        this.loadingBay.city = "";
        this.loadingBay.state = "";
        this.loadingBay.postCode = "";
        this.loadingBay.country = "";
        var geocoder = new google.maps.Geocoder();
        var address = this.loadingBay.homeLocation;
        var self = this;
        console.log(address)
        //debugger
        geocoder.geocode({ address: address }, function (results: any, status: any) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              self.loadingBay.locationLat = results[0].geometry.location.lat();
              self.locationLat = results[0].geometry.location.lat();
              self.loadingBay.locationLong = results[0].geometry.location.lng();
              self.locationLong = results[0].geometry.location.lng();
              for (var i = 0; i < results[0].address_components.length; i++) {
                var addressType = results[0].address_components[i].types[0];
                if (addressFormatForm[addressType]) {
                  var checkAddress = _.includes(results[0].address_components[i].types,addressType);
                  if (checkAddress) {
                    if (addressType === "route" ||addressType === "sublocality_level_1" ||addressType === "sublocality" ||addressType === "street_number") {
                      if (self.loadingBay.street === "") {
                        self.loadingBay.street += results[0].address_components[i][addressFormatForm[addressType]];
                      } else {
                        self.loadingBay.street += ", " +results[0].address_components[i][addressFormatForm[addressType]];
                      }
                    } else if (addressType === "locality" ||addressType === "political") {
                      self.loadingBay.city = results[0].address_components[i][addressFormatForm[addressType]];
                    } else if (addressType === "administrative_area_level_1") {
                      self.loadingBay.state = results[0].address_components[i][addressFormatForm[addressType]];
                    } else if (addressType === "postal_code") {
                      self.loadingBay.postCode = results[0].address_components[i][addressFormatForm[addressType]];
                    } else if (addressType === "country") {
                      self.loadingBay.country = results[0].address_components[i][addressFormatForm[addressType]];
                    }
                  }
                }
              }
              // self.loadingBay.homePlaceId = results[0].place_id;
              self.setData(self.loadingBay);
              //if (!self.cdr['destroyed']) {
              self.cdr.detectChanges();
             /// }
            } else {
            }
          } else {
          }
        });
      }
    }, 1000);
  }

  manualAddressSearch() {
    var addressFormatForm: any = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      sublocality_level_1: "long_name",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };
    var geocoder = new google.maps.Geocoder();
    this.address = "";
    if (this.loadingBay.street) {
      this.address = this.address? this.address + "," + this.loadingBay.street: this.loadingBay.street;
    }
    if (this.loadingBay.city) {
      this.address = this.address ? this.address + "," + this.loadingBay.city : this.loadingBay.city;
    }
    if (this.loadingBay.state) {
      this.address = this.address? this.address + "," + this.loadingBay.state : this.loadingBay.state;
    }
    if (this.loadingBay.postCode) {
      this.address = this.address ? this.address + "," + this.loadingBay.postCode : this.loadingBay.postCode;
    }
    if (this.loadingBay.country) {
      this.address = this.address ? this.address + "," + this.loadingBay.country : this.loadingBay.country;
    }
    if (this.loadingBay.locationLat) {
      this.address = this.address ? this.address + "," + this.loadingBay.locationLat: this.loadingBay.locationLat;
    }
    if (this.loadingBay.locationLong) {
      this.address = this.address ? this.address + "," + this.loadingBay.locationLong : this.loadingBay.locationLong;
    }
    var self = this;
    console.log(this.address)
    //debugger
    geocoder.geocode({ address: this.address }, function (results: any, status: any) {
      console.log(results)
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0]) {
         ////debugger
         self.overlays = [
          new google.maps.Marker({position: {lat:results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}, title:"Konyaalti"}),
         ];
         
          self.initOverlays(results[0].geometry.location.lat(),results[0].geometry.location.lng());
          const markerAddress = new google.maps.Marker({position: {lat:results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()}, title:"Konyaalti",draggable: true});
         (self.gmap as any).map.panTo(markerAddress.position);
         var zooming = (self.gmap as any).getMap()
         zooming.setZoom(16);

          self.loadingBay.locationLat = results[0].geometry.location.lat();
          self.locationLat = results[0].geometry.location.lat();
          self.loadingBay.locationLong = results[0].geometry.location.lng();
          self.locationLong = results[0].geometry.location.lng();
          for (var i = 0; i < results[0].address_components.length; i++) {
            var addressType = results[0].address_components[i].types[0];
            if (addressFormatForm[addressType]) {
              var checkAddress = _.includes(results[0].address_components[i].types,addressType);
              if (checkAddress) {
                if (addressType === "route" ||addressType === "sublocality_level_1" ||addressType === "sublocality" ||addressType === "street_number") {
                  if (self.loadingBay.street === "") { 
                    self.loadingBay.street += results[0].address_components[i][addressFormatForm[addressType]];
                  } else { 
                    self.loadingBay.street += ", " + results[0].address_components[i][ addressFormatForm[addressType]]; 
                  }
                } else if (addressType === "locality" ||addressType === "political") {
                  self.loadingBay.city = results[0].address_components[i][addressFormatForm[addressType]];
                } else if (addressType === "administrative_area_level_1") {
                  self.loadingBay.state = results[0].address_components[i][addressFormatForm[addressType]];
                } else if (addressType === "postal_code") {
                  self.loadingBay.postCode = results[0].address_components[i][addressFormatForm[addressType]];
                } else if (addressType === "country") {
                  self.loadingBay.country = results[0].address_components[i][addressFormatForm[addressType]];
                }
              }
            }
          }
          //self.loadingBay.homePlaceId = results[0].place_id;
          self.setData(self.loadingBay);
          self.cdr.detectChanges();
        } else {
        }
      } else {
        /////self.toaster.warn("", "Location not found");
      }
    });
  }

  changeData(latitude  : any,longitude : any){
     //set latitude, longitude and zoom
    
  }
    /**************************************** On Click Billing Map and Drag map ************************************/
 
    markerDragEnd(event: any) {
      console.log(event,"====",event.overlay.getPosition())
       var selectedPosition: any = event.overlay.getPosition()
       this.setMapData( selectedPosition.lat(), selectedPosition.lng())
      
    }
  
  
       mapClicked($event : any, type : any) {
          console.log($event,"====",$event.latLng.lng())
         
            this.setMapData( $event.latLng.lat(), $event.latLng.lng())
         }
      setMapData(latitude: any, longitude: any){
        var addressFormatForm : any= {
          street_number: "short_name",
          route: "long_name",
          locality: "long_name",
          sublocality_level_1: "long_name",
          administrative_area_level_1: "short_name",
          country: "long_name",
          postal_code: "short_name",
        };
        this.loadingBay.street = "";
        this.loadingBay.city = "";
        this.loadingBay.state = "";
        this.loadingBay.postCode = "";
        this.loadingBay.country = "";
        var geocoder = new google.maps.Geocoder();
        var latitude = latitude;
        var longitude = longitude;
        var latlng = { lat: latitude, lng: longitude };
        var self = this;
        this.overlays = [
            new google.maps.Marker({position: {lat:latitude, lng: longitude}, title:"Konyaalti",draggable: true})];
            //debugger
          const markerAddress = new google.maps.Marker({position: {lat:latitude, lng: longitude}, title:"Konyaalti",draggable: true});
         (this.gmap as any).map.panTo(markerAddress.position);
         var zooming = (this.gmap as any).getMap()
         zooming.setZoom(16);

        geocoder.geocode({ location: latlng }, function (results:any, status:any) {
          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              self.loadingBay.locationLat = results[0].geometry.location.lat();
              self.locationLat = results[0].geometry.location.lat();
              self.loadingBay.locationLong = results[0].geometry.location.lng();
              self.locationLong = results[0].geometry.location.lng();
              for (var i = 0; i < results[0].address_components.length; i++) {
                var addressType = results[0].address_components[i].types[0];
                if (addressFormatForm[addressType]) {
                  var checkAddress = _.includes(results[0].address_components[i].types,addressType);
                  if (checkAddress) {
                    if (addressType === "route" ||addressType === "sublocality_level_1" ||addressType === "sublocality" ||addressType === "street_number") {
                      if (self.loadingBay.street === "") {
                        self.loadingBay.street += results[0].address_components[i][addressFormatForm[addressType] ]; 
                      } 
                      else {
                        self.loadingBay.street += ", " +results[0].address_components[i][addressFormatForm[addressType]]; 
                      }
                    } else if (addressType === "locality" || addressType === "political") {
                      self.loadingBay.city = results[0].address_components[i][addressFormatForm[addressType]];
                    } else if (addressType === "administrative_area_level_1") {
                      self.loadingBay.state = results[0].address_components[i][addressFormatForm[addressType]];
                    } else if (addressType === "postal_code") {
                      self.loadingBay.postCode = results[0].address_components[i][addressFormatForm[addressType]];
                    } else if (addressType === "country") {
                      self.loadingBay.country = results[0].address_components[i][addressFormatForm[addressType]];
                    }
                  }
                }
              }
              //self.loadingBay.homePlaceId = results[0].place_id;
              self.setData(self.loadingBay);
              self.cdr.detectChanges();
            } else {
            }
          } else {
          }
        });
         }

  setData(data: any){
    this.addressData.emit(data);
    
  }

toggle(index: number) {
  
    if(this.activeState){
      this.activeState = false
    }else{
      this.activeState = true
    }
    console.log(this.activeState,"===")
}

private allowFields() {
  if (this.readOnly) {
    if (this.readOnly.latitude) {
      this.readOnlyFields.latitude = true;
    }

    if (this.readOnly.longitude) {
      this.readOnlyFields.longitude = true;
    }
  }
}


}
