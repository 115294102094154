import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ChubbDashboardAppEvents } from "src/app/_models/global.data.model";
import { ChubbServicesService } from "src/app/_services/chubb-services.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-chubb-team-task",
  templateUrl: "./chubb-team-task.component.html",
  styleUrls: ["./chubb-team-task.component.scss"],
})
export class ChubbTeamTaskComponent extends SubscriptionUtil implements OnInit {
  @Input() isSlideshowOn = false;
  @Input() teams: any[] = [];

  private teamColor: any[] = [];

  public teamTasks = {
    selectedRegion: "Central",
    selectedTimeframe: "daily",
    regions: <any[]>[],
    timeFrames: <any[]>[],
    teams: <any[]>[],
    noData: false,
  };

  constructor(
    private utilService: UtilServiceService,
    private router: Router,
    private chubbService: ChubbServicesService,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // clear on default
    this.teamTasks.teams = [];

    if (changes && changes.teams.currentValue) {
      this.processTeams(changes.teams.currentValue);
    }
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    // set team color
    this.teamColor = JSON.parse(localStorage.getItem("chubb-team-color")!)
      ? JSON.parse(localStorage.getItem("chubb-team-color")!)
      : [];
  }

  /******************************************** Init ******************************************/
  private init() {
    this.teamTasks.teams = [];

    this.teamTasks.regions = this.chubbService.getRegions();
    this.teamTasks.timeFrames = this.chubbService.getTimeFrames("standard");

    this.push(
      this.utilService.getData().subscribe((chubbEvent: any) => {
        if (
          chubbEvent &&
          chubbEvent.action === ChubbDashboardAppEvents.REFRESH_PANEL_ONE
        ) {
          this.getData();
        }
      })
    );

    if (!this.isSlideshowOn) {
      this.getData();
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Get Data ******************************************/
  private getData() {
    const params = {
      region: encodeURIComponent(this.teamTasks.selectedRegion),
      duration: this.teamTasks.selectedTimeframe,
    };

    this.push(
      this.chubbService
        .getTaskByTeam(params)
        .pipe(take(1))
        .subscribe(
          (resp) => {
            let allTeams = resp as any[];
            if (allTeams.length > 8) {
              allTeams = allTeams.splice(0, 8);
            }

            this.processTeams(allTeams);
          },
          (error: any) => {
            this.errorUtil.setErrorMessage(
              error.status,
              null,
              error.error.title,
              "error",
              2000
            );
          }
        )
    );
  }

  /******************************************** Update Data ******************************************/
  public updateData($event: any) {
    this.getData();
  }

  /******************************************** Process Teams ******************************************/
  public processTeams(teamsData: any[]) {
    this.teamTasks.teams = [];

    const temp: any[] = [];

    teamsData.forEach((team) => {
      const color = this.returnRandomColor();
      const theTeamColor = this.teamColor.find(
        (theTeam) => theTeam.team === team.name
      );

      const chosenColor = theTeamColor ? theTeamColor.color : color;

      temp.push({
        id: team.id,
        name: team.name,
        color: chosenColor,
        tasks: {
          open: team.openTasks ? team.openTasks : 0,
          closed: team.closedTasks ? team.closedTasks : 0,
        },
        order: team.order,
      });

      const index = this.teamColor.findIndex((tc) => tc.team === team.name);

      if (index === -1) {
        this.teamColor.push({
          team: team.name,
          color: color,
        });
      }
    });

    // save team color
    localStorage.setItem("chubb-team-color", JSON.stringify(this.teamColor));

    this.teamTasks.teams = temp.sort(
      (teamA, teamB) => teamA.order - teamB.order
    );

    if (this.teamTasks.teams.length > 0) {
      this.teamTasks.noData = false;
    } else {
      this.teamTasks.noData = true;
    }
  }

  /******************************************** Return Random Color ******************************************/
  public returnRandomColor(): string {
    return this.utilService.getRandomColor();
  }

  /******************************************** NAVIGATE TO TASK BY TEAM PAGE ******************************************/
  public navigateToTaskByTeam(team: any) {
    const id = team.id;
    const teamName = team.name;

    this.chubbService.setTempData({
      action: ChubbDashboardAppEvents.DATA_PASS_BY,
      data: {
        current: {
          duration: this.teamTasks.selectedTimeframe,
        },
      },
    });

    this.router.navigate([
      `/dashboard/chubb/taskByTeam/${id}/${teamName}`,
    ]);
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
