import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class DriverService {
  public refresherDriverList$: EventEmitter<any>;
  private ENDPOINT_CREATE_DRIVER = "/drivers";
  private ENDPOINT_DRIVER_BY_ID = "/drivers/";
  private ENDPOINT_VEHICLES_LIST = "/vehicles/all?active=true&unAssigned=true";
  private ENDPOINT_ASSIGN_VEHICLE_TO_DRIVER = "/vehicles/add/drivers";
  private ENDPOINT_UPLOAD_DRIVER_ATTACHMENTS =
    "/attachments/upload?parentType=DRIVER_ATTACHMENT&parentId=";

  private ENDPOINT_VEHICLES_ROUTE_TRIP =
    "/fleet-draft-route-plans/vehicle-route-trip";
  private ENDPOINT_VEHICLES_ACTUAL_ROUTE_TRIP =
    "/fleet-draft-route-plans/actual/vehicle-route-trip";

  private ENDPOINT_USER_DRIVER_BY_ID = "/get-user-driver-profile/";
  private ENDPOINT_UPDATE_USER_DRIVER_BY_ID = "/update-user-driver-profile/";

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http
  ) {
    this.refresherDriverList$ = new EventEmitter();
  }

  public getUserDriverById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_USER_DRIVER_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public getDriverById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_DRIVER_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public createNewDriver(body: any) {
    console.log(body);
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_DRIVER, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }
  public getVehicle() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLES_LIST)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public deleteDriver(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DRIVER_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public getDriversList(searchValue: any, status: any, parameters: any) {
    let params = new HttpParams();

    if (status !== "" && status !== null) {
      params = params.set("status", status);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    console.log(parameters);
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/drivers`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public getVehicleCategories() {
    return this.http.get(environment.base_url + "/vehicle-categories").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error.status);
      })
    );
  }

  public uploadDriverAttachment(id: any, formData: any) {
    const headers = new Headers()
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_DRIVER_ATTACHMENTS + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public assignVehicleToDriver(body: any) {
    return this.http
      .post(
        environment.base_url + this.ENDPOINT_ASSIGN_VEHICLE_TO_DRIVER,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refresherDriverList$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public deleteVehicleToDriver(driverId: any, vehicleId: any) {
    return this.http
      .put(environment.base_url + "/vehicles/remove/drivers?" + 'driverId='+driverId+'&vehicleId='+vehicleId, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherDriverList$.emit();
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  /////////////////////////////////////////////////// Driver Trips //////////////////////////////////////////////
  //planned  routes
  public getAllPlannedTripRoutes(routeId: any) {
    let params = new HttpParams();
    if (routeId) params = params.set("routeId", routeId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLES_ROUTE_TRIP, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }
  // actual routes
  public getAllActualTripRoutes(routeId: any, driverId: any, vehicleId: any) {
    let params = new HttpParams();
    if (routeId) params = params.set("routeId", routeId);
    if (driverId) params = params.set("driverId", driverId);
    if (vehicleId) params = params.set("vehicleId", vehicleId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLES_ACTUAL_ROUTE_TRIP, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }

  public editUserDriver(body: any, id: string) {
    return this.http
      .put(
        environment.base_url + this.ENDPOINT_UPDATE_USER_DRIVER_BY_ID + id,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error.status);
        })
      );
  }
}
