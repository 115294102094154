<form [formGroup]="changeVehicleForm">
<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <div>
            <span class="text-16 font-medium" translate>{{title}}</span>
        </div>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body tix pt-2">
    <div class="d-flex align-items-start justify-content-between mt-1">
        <div class="w-50">
            <div class="custella-form-container rounded-2 ms-0 me-1" style="box-shadow: none; border: 1px solid #dee2e6;">
                <!-- Container Content -->
                <div class="custella-form-container-title text-14 font-medium rounded-top">
                    Step 1: Search for the vehicle you'd like to change
                </div>
                <div class="custella-form-container-content pb-2" style="min-height: 250px;">
                    <div class="custella-content-row-input col-12">
                        <p-dropdown [(ngModel)]="selectedVehicleData" formControlName="vehicle" name="status" (onChange)="search2($event)"
                                        [options]="vehicles2" [showClear]="true" placeholder="Select Vehicle">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex d-flex" *ngIf="selectedVehicleData">
                                                <div class="me-2" [ngClass]="selectedVehicleData.vehicle.vehicleNumber"></div>
                                                    <span> {{selectedVehicleData.vehicle.name }} </span>
                                            </div>
                                        </ng-template>
                                        <ng-template let-row pTemplate="item">
                                                <div class="flex d-flex">
                                                    <div class="me-2" [ngClass]="row.vehicle.vehicleNumber"></div>
                                                    <span> {{row.vehicle.name }} </span>
                                                </div>
                                        </ng-template>
                        </p-dropdown>
                        <div class="p-error text-10" *ngIf="changeVehicleForm.controls['vehicle'].touched && changeVehicleForm.controls['vehicle'].invalid">
                            <span *ngIf="changeVehicleForm.controls['vehicle'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row mb-3" *ngIf="!searchVehicle">
                        <div class="custella-content-row-input col-12">
                            <div class="d-flex align-items-center justify-content-center mt-4">
                                <img src="assets/images/truck.png" alt="Choose vehicle to add order to">
                            </div>
                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <span class="text-14 font-semiBold d-block m-3"
                                    translate>Choose the vehicle to switch from</span>
                            </div>
                        </div>
                    </div>
                    <div class="my-3 py-1 rounded-2 col-12" style="border: 1px solid #d4d4d4;" *ngIf="searchVehicle">
                        <div class="custella-form-container-content">
                            <div>
                                <div class="d-flex align-items-center text-nowrap" style="border-bottom: 1px solid lightgrey;">
                                    <div class="my-3 ms-2 me-4 col-2">
                                        <p-avatar *ngIf="!existingVehicleData?.vehicleImage?.attachmentUrl"image="assets/images/truck-avatar.png"  styleClass="wh-80px" size="xlarge"> </p-avatar>
                                        <p-avatar *ngIf="existingVehicleData?.vehicleImage?.attachmentUrl" styleClass="wh-80px" size="xlarge" [image]="existingVehicleData?.vehicleImage?.attachmentUrl" alt=""></p-avatar>
                                        <!-- <span class="d-block">
                                            <span *ngIf="existingVehicleData?.vehicleStatus === 'DEPOT'" class="p-tag p-component" style="background-color: #c8e6c9 !important ;">
                                                <span class="p-tag-value text-dark">Depot</span>
                                            </span>
                                            <span *ngIf="existingVehicleData?.vehicleStatus === 'ENROUTE'" class="p-tag p-component" style="background-color: #edc054 !important ;">
                                                <span class="p-tag-value text-dark">Enroute</span>
                                            </span>
                                            <span *ngIf="existingVehicleData?.vehicleStatus === 'MAINTENANCE'" class="p-tag p-component" style="background-color: #ce4343 !important ;">
                                                <span class="p-tag-value text-dark">Maintenance</span>
                                            </span>
                                        </span> -->
                                    </div>
                                    <span class="text-20 font-semiBold">{{existingVehicleData?.vehicleType?.name}}</span>
                                </div>
                                <div>
                                    <div class="col">
                                        <div class="custella-content-row">
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" translate>{{'vehicle_no'}}</span>
                                                <span class="custella-content-row-input-title-three">{{existingVehicleData?.vehicleNumber}}</span>
                                            </div>
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" translate>{{'chassis_number'}}</span>
                                                <span class="custella-content-row-input-title-three">{{existingVehicleData?.chassisNumber}}</span>
                                            </div>
                                        </div>
                                        <div class="custella-content-row">
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" translate>{{'driver'}}</span>
                                                <span class="custella-content-row-input-title-three">{{existingVehicleData?.defaultDriver?.user?.fullName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-50">
            <div class="custella-form-container rounded-2 ms-1 me-0" style="box-shadow: none; border: 1px solid #dee2e6;">
                <div class="custella-form-container-title text-14 font-medium rounded-top" translate>
                    Step 2: Select the vehicle you'd like to replace it with
                </div>
                <div class="custella-form-container-content pb-2" style="min-height: 250px;">
                    <div class="custella-content-row-input col-12">
                        <div class="p-input-icon-right w-100 d-flex">
                            <div class="p-input-icon-right w-100">
                                <p-autoComplete [readonly]="vehicleId" [(ngModel)]="vehicleName" placeholder="Search Vehicle" (onBlur)="onValidVehicle()" (onSelect)="selectVehicleValue($event)"
                                    field="name" formControlName="vehicle1" [suggestions]="vehicles"
                                    (completeMethod)="getVehicleIdsFirstWay($event)">
                                    </p-autoComplete>
                                <i *ngIf="vehicleName" (click)="clearVehicleValue()"
                                    class="p-autocomplete-clear-icon pi pi-times "></i>
                                </div>
                               
                                <button class="lookup-button me-0" (click)="showVehicleTable()"> <i class="pi pi-search"></i> </button>

                                <footer *ngIf="displayTable1" class="custella-form-container-footer hidePopup">
                                    <p-dialog header="Vehicle Details" [(visible)]="displayTable1" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false" [resizable]="false">
                                        <app-custella-vehicle  (vehicleSelector)="gotSelectedVehicle($event)" [notInVehicleIds]="notInVehicleIds" (closeVehicleView)="closeVehicleView()"></app-custella-vehicle>
                                    </p-dialog>
                                </footer>
                        </div>
                        <div class="p-error text-10" *ngIf="changeVehicleForm.controls['vehicle1'].touched && changeVehicleForm.controls['vehicle1'].invalid">
                            <span *ngIf="changeVehicleForm.controls['vehicle1'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row mb-3" *ngIf="!vehicleName">
                        <div class="custella-content-row-input col-12">
                            <div class="d-flex align-items-center justify-content-center mt-4">
                                <img src="assets/images/truck.png" alt="Choose vehicle to switch to">
                            </div>
                            <div class="d-flex align-items-center justify-content-center mb-4">
                                <span class="text-14 font-semiBold d-block m-3"
                                    translate>Choose the vehicle to switch to</span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="vehicleName" class="my-3 py-1 rounded-2 col-12" style="border: 1px solid #d4d4d4;" >
                        <div class="custella-form-container-content">
                            <div>
                                <div class="d-flex align-items-center text-nowrap" style="border-bottom: 1px solid lightgrey;">
                                    <div class="my-3 ms-2 me-4">
                                        <p-avatar *ngIf="!vehicleData?.vehicleImage?.attachmentUrl"image="assets/images/truck-avatar.png"  styleClass="wh-80px" size="xlarge"> </p-avatar>
                                        <p-avatar *ngIf="vehicleData?.vehicleImage?.attachmentUrl" styleClass="wh-80px" size="xlarge" [image]="vehicleData?.vehicleImage?.attachmentUrl" alt=""></p-avatar>
                                        
                                        <!-- <span class="d-block">
                                            <span *ngIf="vehicleData?.vehicleStatus === 'DEPOT'" class="p-tag p-component" style="background-color: #c8e6c9 !important ;">
                                                <span class="p-tag-value text-dark">Depot</span>
                                            </span>
                                            <span *ngIf="vehicleData?.vehicleStatus === 'ENROUTE'" class="p-tag p-component" style="background-color: #edc054 !important ;">
                                                <span class="p-tag-value text-dark">Enroute</span>
                                            </span>
                                            <span *ngIf="vehicleData?.vehicleStatus === 'MAINTENANCE'" class="p-tag p-component" style="background-color: #ce4343 !important ;">
                                                <span class="p-tag-value text-dark">Maintenance</span>
                                            </span>
                                        </span> -->
                                    </div>
                                    <span class="text-20 font-semiBold">{{vehicleData?.vehicleType?.name}}</span>
                                </div>
                                <div>
                                    <div class="col">
                                        <div class="custella-content-row">
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" translate>{{'vehicle_no'}}</span>
                                                <span class="custella-content-row-input-title-three">{{vehicleData?.vehicleNumber}}</span>
                                            </div>
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" translate>{{'chassis_number'}}</span>
                                                <span class="custella-content-row-input-title-three">{{vehicleData?.chassisNumber}}</span>
                                            </div>
                                        </div>
                                        <div class="custella-content-row">
                                            <div class="custella-content-row-input col-6">
                                                <span class="custella-content-row-input-title-two" translate>{{'driver'}}</span>
                                                <span class="custella-content-row-input-title-three">{{vehicleData?.defaultDriver?.user?.fullName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex align-items-center justify-content-end">
    <button class="custella-btn-white mx-1 text-12" (click)="openModal('modifyPlan',data)" translate>{{'back'}}</button>
    <p-button type="button" (onClick)="switch()" icon="pi pi-arrow-right" label="Switch"></p-button>

</div>
</form>