<div class="">
    <form (ngSubmit)="onSubmit()" [formGroup]="addProfileForm">
        <div class="custella-modal d-flex flex-column">
            <div class="custella-modal-header">
                <div class="custella-modal-header-text" id="title_SetUsClone"> {{ "clone" | translate }} </div>
  
                <i class="pi pi-times pt-1"  [pTooltip]="'close'|translate" tooltipPosition="left"  id="btn_SetUsCloneClose" (click)="modalRef.hide()"></i>
            </div>
  
            <div class="custella-modal-body pb-0">
                <div class="custella-content-row-input mb-2">
                    <span class="custella-content-row-input-title" id="label_SetUsCloneAccName">{{ "access_name" | translate }}</span>

                    <input pInputText autocomplete="off" type="text" formControlName="name" id="input_SetUsAccNameField" [(ngModel)]="profile.name" name="name" (ngModelChange)="assignFieldName(profile.name)" >

                    <div class="p-error text-10" *ngIf="addProfileForm.controls['name'].touched && addProfileForm.controls['name'].invalid">
                        <span *ngIf="addProfileForm.controls['name'].errors?.required" id="label_SetUsAccNameReqField" translate>{{'required_field'}}</span>
                    </div>
                </div>
            </div>
  
            <div class="custella-modal-footer d-flex justify-content-end">
                <p-button [disabled]="submitTouched" type="submit" styleClass="h-32" id="btn_SetUsCloneSubmit" [label]="'save' | translate"></p-button>
            </div>
        </div>
    </form>
  </div>
  