<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <span class="text-16 font-medium" translate>{{title}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<form [formGroup]="removeForm">
    <!-- Remove order from plan -->
    <!-- <div class="modal-body tix">
        <div class="mx-2 mb-4">
            <div class="custella-form-container-content my-2">
                <div class="custella-content-row-input col-12">
                    <span class="custella-content-row-input-title" translate>{{'select_order_to_remove'}}</span>
                    <div class="d-flex align-items-center">
                        <div class="p-input-icon-right w-100">
                            <p-autoComplete placeholder="Orders"></p-autoComplete>
                        </div>
                        <button class="lookup-button" style="padding-top: 7px;"> <i class="pi pi-search"></i>
                        </button>
                    </div>
                </div>
                <div class="custella-content-row mb-3" *ngIf="!toggle1">
                    <div class="custella-content-row-input col-12">
                        <div class="d-flex align-items-center justify-content-center mt-4">
                            <img src="assets\svg\table\select_order.svg" alt="Add New Order by Selecting">
                        </div>
                        <div class="d-flex align-items-center justify-content-center">
                            <span class="text-14 font-semiBold d-block m-3" translate>{{'add_new_order_select'}}</span>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content p-0" *ngIf="toggle1">
                
                </div>
            </div>

        </div>
    </div> -->
    <!-- Remove Vehicle from plan & adjust replan-->
    <div class="modal-body ph">
    <!--------------------------------------------- remove orders ------------------------------------->        
        <div class="mx-2 mb-4" *ngIf="data.optionType == 'removeOrder'">
            <div class="custella-form-container-content my-2">
                <div class="custella-content-row-input col-12">
                    <span class="custella-content-row-input-title">Select the order you would like to remove from the plan</span>
                    <div class="d-flex align-items-center">
                        <div class="p-input-icon-right w-100">
                            <p-dropdown   [filter]="true" placeholder="Orders" [(ngModel)]="searchValue" (onChange)="search()" [ngModelOptions]="{standalone: true}"
                            [options]="rowData" optionLabel="name" optionValue="orderNumber" [showClear]="true"></p-dropdown>
                        </div>
                    </div>
                </div>
                
            </div>
        
            <div class="custella-form-container-content" style="height: 358px;">
                    <div class=" ag-grid-table-full h-100" style="height: calc(100% - 95px);" style="border: 1px solid #dee2e6;">
                        <ag-grid-angular
                            #agGrid
                            style="height: 100%;"
                            class="ag-theme-balham"
                            [columnDefs]="columnDefs"
                            [gridOptions]="gridOptions"
                            [defaultColDef]="defaultColDef"
                            [maxConcurrentDatasourceRequests]="-1"
                            [suppressDragLeaveHidesColumns]="true"
                            [paginationPageSize]="25"
                            [pagination]="false"
                            [paginationPageSize]="25"
                            [rowData]="rowData"
                            [frameworkComponents]="frameworkComponents"
                            (gridReady)="onGridReady($event)"
                            [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                            (cellClicked)="viewPlan($event)"
                        ></ag-grid-angular>
            
                        <div class="col-12">
                            <p class="ag-record-row-summary-panel padds text-end">{{totalRecord}} {{'records' | translate}}</p>
                        </div>
                    </div>
            </div>

        </div>
    <!--------------------------------------------- remove vehicles and adjust Replan ------------------------------------->        
    <div class="mx-2 mb-4" *ngIf="data.optionType == 'removeVehicle' || data.optionType == 'adjustReplan'">
        <div class="custella-form-container-content my-2">
            <div class="custella-content-row-input col-12">
                <span *ngIf="data.optionType == 'removeVehicle'" class="custella-content-row-input-title" translate>{{'select_vehicle_to_remove'}}</span>
                <span *ngIf="data.optionType == 'adjustReplan'" class="custella-content-row-input-title" translate>Lock the vehicles you do not want to replan</span>
                <div class="d-flex align-items-center">
                    <div class="p-input-icon-right w-100">
                        <p-dropdown  placeholder="Vehicles"  optionLabel="vehicleNumber" 
                        [filter]="true" 
                        filterBy="vehicleNumber" 
                        optionValue="vehicleNumber" 
                        [(ngModel)]="searchVehicle" (onChange)="searchByVehicle($event)" [ngModelOptions]="{standalone: true}"
                        [options]="vehiclesData"  [showClear]="true">
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <!-- <div class="custella-content-row mb-3" *ngIf="!toggle1">
                <div class="custella-content-row-input col-12">                               
                    <div class="d-flex align-items-center justify-content-center mt-4">
                        <img src="assets/images/truck.png" alt="Choose vehicle to add order to">
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        <span class="text-14 font-semiBold d-block m-3" translate>{{'select_vehicle_to_remove'}}</span>
                    </div>
                </div>
            </div> -->
        </div>
        <div style="height: 377px;overflow-y: hidden;" class="overflow-scroll">
            <div class="custella-form-container mx-0 mb-4 rounded-top-left rounded-top-right rounded-bottom-left rounded-bottom-right" style="box-shadow: none; border: 1px solid #dee2e6;" *ngFor="let list of dragableDataList; index as i">
                <div class="custella-form-container-title-two-grey d-flex align-items-center rounded-top-left rounded-top-right">
                    <div class="col-1"  *ngIf="data.optionType == 'removeVehicle'">
                        <div class="p-field-checkbox">
                            <p-checkbox [binary]="true" (onChange)="chkSelcted(list)" [(ngModel)]="list.checkValue" [ngModelOptions]="{standalone: true}"></p-checkbox>
                        </div>
                    </div>
                    <div class="col-1" *ngIf="data.optionType == 'adjustReplan'">
                        <img *ngIf="!list.locked" (click)="$event.stopPropagation();lockVehicle(list.locked,list.routeId,list)" class="w-28px cursor-pointer" src="../../../../assets/images/planning-hub/ic-lock-white.png">
                        <img *ngIf="list.locked" (click)="$event.stopPropagation();lockVehicle(list.locked,list.routeId,list)" class="w-28px cursor-pointer" src="../../../../assets/images/planning-hub/ic-lock-black.png">
                    </div>
                    <div class="col-2">
                        <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                        <span class="text-12 font-black font-semiBold d-block" translate>{{list?.vehicleName}}</span>
                    </div>
                    <div class="col-2">
                        <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                        <span *ngIf="list?.capacity < 50" class="text-12 font-black font-semiBold d-block lowCapacity">{{list?.capacity}}%</span>
                        <span *ngIf="list?.capacity > 50 && list?.capacity < 80" class="text-12 font-black font-semiBold d-block mediumCapacity">{{list?.capacity}}%</span>
                        <span *ngIf="list?.capacity > 80" class="text-12 font-black font-semiBold d-block highCapacity">{{list?.capacity}}%</span>
                    </div>
                    <div class="col-2">
                        <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                        <span class="text-12 font-black font-semiBold d-block" translate>{{list?.duration | minutesSeconds}}</span>
                    </div>
                    <div class="col-2">
                        <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                        <span class="text-12 font-black font-semiBold d-block" translate>{{list?.distance | distanceToKm2}}</span>
                    </div>
                    <div class="text-end ms-auto">
                        <i class="pi pi-caret-up mt-1 ms-3" #wfcElem (click)="toggle(wfcElem)" data-bs-toggle="collapse" [attr.href]="'#collapseBox' + i"></i>

                    </div>
                </div>
                <div class="custella-form-container-content overflow-scroll collapse show" [id]="'collapseBox' + i">
                    <div class="pb-0" style="overflow-y: hidden;">
                        <div *ngFor="let item of list?.relatedVehicleEvents;index as i" class="border rounded-1 flex-between mb-2 py-2 px-3">
                            <div *ngIf="item" class="col p-0">
                                <div *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')" class="d-flex align-items-center" [ngClass]="{'fade-opacity-04' : (item.type=='pickup'&&item.status=='PICKED_UP'|| item.type=='pickup'&&item.status=='DELIVERED' || item.type=='delivery'&&item.status=='DELIVERED')}"> 
                                    <div class="d-flex align-items-center col-5">
                                        <img *ngIf="item?.type=='pickup'" src="assets/svg/table/ph_arrow_green.svg" alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <img *ngIf="item?.type=='delivery'" src="assets/svg/table/ph_arrow.svg" alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <img *ngIf="item?.type=='pickup_and_delivery'" src="assets/svg/table/dropoff-pickup.svg" alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <span class="text-12">{{item.orderCustomer}}</span>
                                    </div>
                                    <div class="d-flex align-items-center col" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                        <img src="assets\svg\table\clock.svg" alt="Available Vehicle" class="w-19px me-1">
                                        <span class="text-12 color-var-500">{{item.startTime}} - {{item.endTime}}</span>
                                    </div>
                                    <div  class="d-flex align-items-center col-5" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                        <span class="text-12 color-var-500">{{item.note}}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--------------------------------------------- cancel orders ------------------------------------->        
    <div class="mx-2 mb-4" *ngIf="data.optionType == 'cancelOrder'">
        <div class="custella-form-container-content my-2">
            <div class="custella-content-row-input col-12">
                <span class="custella-content-row-input-title">Select the order to be canceled</span>
                <div class="d-flex align-items-center">
                    <div class="p-input-icon-right w-100">
                        <p-dropdown  placeholder="Orders" [(ngModel)]="searchCancelDO" (onChange)="searchCancelOrder($event)" formControlName="order"
                        [options]="data.assignedOrders"  [showClear]="true"   [filter]="true">
                        <ng-template pTemplate="selectedItem">
                            <div *ngIf="searchCancelDO">
                                {{ returnValue(searchCancelDO) }}
                            </div>
                        </ng-template>

                        <ng-template let-assignedOrders pTemplate="item">
                            {{ assignedOrders.deliveryOrder.name }}
                        </ng-template>
                    </p-dropdown>
                        <div class="p-error text-10" *ngIf="removeForm.controls['order'].touched && removeForm.controls['order'].invalid">
                            <span *ngIf="removeForm.controls['order'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    
        <div class="custella-form-container-content" style="height: 320px;">
            
            <div class="custella-content-row mb-3" *ngIf="!searchValue">
                <div class="custella-content-row-input col-12">                               
                    <div class="d-flex align-items-center justify-content-center mt-4">
                        <img src="assets\svg\table\select_order.svg" alt="Add New Order by Selecting">
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                        <span class="text-14 font-semiBold d-block m-3" translate>Select an order to be cancelled</span>
                    </div>
                </div>
            </div>

            <div class="bg-grey my-3 px-4 py-1 rounded-2" style="border: 1px solid #dee2e6;" *ngIf="searchValue">
                <div class="custella-form-container-content">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'order_name'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.name ? orderData?.deliveryOrder?.name:'-'}}</span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'status'}}</span>
                            <span class="custella-content-row-input-title-three"> 
                                <span class="p-mr-2 p-tag p-component" [attr.style]="'background-color:' + orderData?.deliveryOrder?.orderStatusColor + ' !important;'">
                                <span class="p-tag-value text-dark">{{orderData?.deliveryOrder?.orderStatusName}}</span>
                            </span>
                        </span>
                        </div>
                    </div>
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'contact'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.owner?.fullName ? orderData?.owner?.fullName:'-'}}  {{orderData?.owner?.phone ? orderData?.owner?.phone:''}}</span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'pic_name'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.picName ? orderData?.deliveryOrder?.picName:'-'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'customer'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.customer?.name ? orderData?.deliveryOrder?.customer?.name:'-'}}</span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two"
                                translate>{{'pic_contact'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.picContact ? orderData?.deliveryOrder?.picContact:'-'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'priority'}}</span>
                            <span class="custella-content-row-input-title-three">
                                {{orderData?.deliveryOrder?.priority ? orderData?.deliveryOrder?.priority:'-'}}
                            </span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two"
                                translate>{{'preferred_vehicle_group'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.vehicleTypeGroup?.name ? orderData?.vehicleTypeGroup?.name:'-'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'order_type'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.deleveryType ? orderData?.deliveryOrder?.deleveryType:'-'}}</span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title-two" translate>{{'geofence'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.geoFence?.geoFenceName ? orderData?.deliveryOrder?.geoFence?.geoFenceName:'-'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title-two" translate>{{'remarks'}}</span>
                            <span class="custella-content-row-input-title-three">{{orderData?.deliveryOrder?.description ? orderData?.deliveryOrder?.description:'-'}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
    </div>
    <div class="modal-footer d-flex align-items-center justify-content-end">
        <button class="custella-btn-white ms-1 me-2 text-12" style="height: unset" (click)="openModal('modifyPlan',data)" translate>{{'back'}}</button>
        <button *ngIf="data.optionType == 'removeOrder'" (click)="remove()" pButton type="button" label="{{'remove'|translate}}" icon="pi pi-trash" iconPos="left" class="p-button-danger"></button>
        <button *ngIf="data.optionType == 'removeVehicle'"  (click)="removeVehicle()" pButton type="button" label="{{'remove'|translate}}" icon="pi pi-trash" iconPos="left" class="p-button-danger"></button>
        <button *ngIf="data.optionType == 'cancelOrder'"  (click)="proceed()" pButton type="button" label="{{'proceed'|translate}}" icon="pi pi-trash" iconPos="left" class="p-button-danger"></button>
        <button *ngIf="data.optionType == 'adjustReplan'"  (click)="replan()" pButton type="button" label="{{'replan'|translate}}"  class="p-button-danger"></button>

    </div>
</form>