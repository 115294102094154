import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimeNGConfig } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { AssetService } from 'src/app/_services/asset.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { locatns } from '../IqcFqc';

@Component({
  selector: 'app-add-edit-task',
  templateUrl: './add-edit-task.component.html',
  styleUrls: ['./add-edit-task.component.scss']
})
export class AddEditTaskComponent implements OnInit {
  buttonLabel: string = 'Save';
  skipTaskListIds: any = [];
  id: any;
  _id: any;
  mode: any = 'IQC';
  equipmentList: any = [];
  makeList: any = [];
  manufacturerList: any = [];
  departmentList: any = [];
  filter: any = { equipmentType: null, manufacturer: null, makeModel: null, taskType: null, location: null, departmentId: null };
  taskTriggers: any = {
    questionId: null,
    answerId: null,
    maintenanceTaskId: null,
    tasks: []
  }
  taskTriggersIqcFqc: any = {
    jobOrderId: null,
    currentUserId: null,
    taskListIds: []
  }
  taskForm!: UntypedFormGroup;
  locations = locatns;
  taskTypes = [{ name: 'Preventive Maintenance Minor', label: 'Preventive Maintenance Minor' },
  { name: 'Preventive Maintenance Major', label: 'Preventive Maintenance Major' },
  { name: 'IQC', label: 'IQC' },
  { name: 'FQC', label: 'FQC' }];


  subscriber: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  subscriber2: Subscription = new Subscription();
  subscriber3: Subscription = new Subscription();
  subscriber4: Subscription = new Subscription();
  subscriber5: Subscription = new Subscription();
  subscriber6: Subscription = new Subscription();

  /*======================  ===================*/
  gridApi: any;
  logGridParams: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  searchValue: any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  pageSize: any = 25;
  taskTypeList = [];
  public gridOptions: Partial<GridOptions> | any;
  rowSelection: any;
  selectAllChecked: boolean = false;
  formData: any = { tasks: [] };
  orderGridTotalLength: any;
  noTaskType: boolean = false;
  displayTaskicon: boolean = false;
  overlayNoRowsTemplate: any;
  submitTouched: boolean = true;
  fromPage: any;
  userId: string = '';
  taskTrigerList: any;

  @ViewChild("myDropDown", { static: false }) myDropDown: Dropdown | any

  constructor(private breadcrumbService: BreadcrumbService,
    private util: UtilServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private iqcfqcService: IqcFqcService,
    private spinner: NgxSpinnerService,
    private assetsService: AssetService,
    private errorUtil: ErrorUtil,
    private customerService: CustomersService,
    private cd: ChangeDetectorRef,
    private auth: AuthenticationService,
    private primengConfig: PrimeNGConfig) {

    this.taskForm = new UntypedFormGroup({
      'equipment': new UntypedFormControl(null),
      'taskType': new UntypedFormControl(null),
      'location': new UntypedFormControl(null),
      'makeModel': new UntypedFormControl(null),
      'manufacturer': new UntypedFormControl(null),
      'departmentId': new UntypedFormControl(null),
      'searchValue': new UntypedFormControl(null)
    });

    this.iqcfqcService.getEquipment().subscribe((res: any) => {
      console.log(res)
      this.equipmentList = res.data;
    });

    this.iqcfqcService.getMakeModel().subscribe((res: any) => {
      console.log(res)
      this.makeList = res.data;
    });
    this.iqcfqcService.getManufacturer().subscribe((res: any) => {
      console.log(res)
      this.manufacturerList = res.data;
    });
    this.iqcfqcService.getDepartment().subscribe((res: any) => {
      console.log(res)
      this.departmentList = res.data;
    });
    this.gridInit();
  }

  ngOnInit(): void {
    this.loadParm();
    this.userId = this.auth.getUserId();
    this.taskTriggersIqcFqc.jobOrderId = this.id;
    this.taskTriggersIqcFqc.currentUserId = this.userId;
    this.loadBreadCrum();
    this.cd.detectChanges();
  }

  loadParm() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.route.queryParams.subscribe(params => {
      this._id = params["_id"];
      this.mode = params["mode"];
      this.mode = this.mode ? this.mode : "IQC";
      // this.skipTaskListIds = params["skipTaskListIds"];
      //   if (this.skipTaskListIds) {
      //     this.skipTaskListIds = this.skipTaskListIds.map((i: any) => this.skipTaskListIds && !isNaN(i) ? Number(i) : -1)
      //       .filter((i: number) => i > 0);
      //     // let m = '';
      //     // this.skipTaskListIds = this.skipTaskListIds
      //     //   .forEach((i: number, j: number) => { 
      //     //     m += j < this.skipTaskListIds.length-1 ? i + ',' : i ;
      //     //   });
      //     // this.skipTaskListIds = m;
      //   }
    });
  }

  loadBreadCrum() {
    let tr = this.mode == 'FQC';
    this.breadcrumbService.setItems([
      { label: tr ? 'FQC' : 'IQC', routerLink: [tr ? "/dashboard/fqc-fqc" : "/dashboard/iqc-fqc"], queryParams: { activeIndex: tr ? 1 : 0 } },
      { label: "Details", routerLink: [tr ? "/dashboard/iqc-fqc/fqc-view/" + this._id : "/dashboard/iqc-fqc/iqc-view/" + this._id] },
      { label: 'Add Task' }
    ]);
  }

  gridInit() {
    this.rowSelection = "multiple";
    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      enableRangeSelection: true,
      enableCharts: true,
      sortingOrder: ["desc", "asc"],
      popupParent: document.body,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    }
    this.columnDefs = [
      { headerName: 'Task Name', field: 'taskName', width: 100, headerTooltip: "#", },
      { headerName: 'Task Type', field: 'taskType', width: 80, headerTooltip: "#", },
      { headerName: 'Equipment Type', field: 'equipmentType', width: 120, headerTooltip: "#", },
      { headerName: 'Manufacturer', field: 'manufacturer', width: 100, headerTooltip: "#", },
      { headerName: "Make/Model", field: "makeModel", width: 100, headerTooltip: "#", },
      { headerName: "Location", field: "location", width: 80, headerTooltip: "#", },
      { headerName: "Department", field: "department.name", width: 100, headerTooltip: "#", },
      {
        headerName: "Select", field: "", width: 80,
        headerTooltip: "#",
        cellStyle: (params: any) => {
          if (params.data) {
            return params.data.disabled ? { 'pointer-events': 'none', opacity: '0.4' } : '';
          }
        },
        checkboxSelection: true,
        cellRenderer: (params: any) => {
          if (this.selectAllChecked || params.data.disabled) {
            params.node.setSelected(true);
          }
          else if (!this.selectAllChecked && this.formData.tasks.length >= 1) {
            for (var i = 0; i <= this.formData.tasks.length - 1; i++) {
              if (this.formData.tasks[i]._id === params.data._id) {
                params.node.setSelected(true);
                return;
              }
              else {
                params.node.setSelected(false);
              }
            }
          }
          else {
            params.node.setSelected(false);
          }
        }
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipComponent: TableHeaderToolTipComponent
    };

    this.overlayNoRowsTemplate = "<span style=\"padding: 10px; font-size: 14px;\">Record not found</span>";
  }

  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  filterResults(vent: any) {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.logGridParams = params;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.showNoRowsOverlay();
  }

  //order table inside checkbox checked
  onSelectionChanged(event: any) {
    console.log(event)
    var index = _.findIndex(this.taskTriggers.tasks, (row: any) => {
      return row._id == event.data._id;
    });
    if (event.node.selected) {
      if (index <= -1) {
        var user = { _id: event.data._id }
        this.taskTriggers.tasks.push(user);

        // this.taskTriggers.tasks.push(event.data);
        if (this.taskTriggers.tasks.length == this.orderGridTotalLength) {
          this.selectAllChecked = true;
          $('#checkboxCustella').removeClass('indeterminate');
        } else if (this.taskTriggers.tasks.length < this.orderGridTotalLength) {
          this.selectAllChecked = false;
          $('#checkboxCustella').addClass('indeterminate');
        }
      }
    } else {
      if (index > -1) {
        console.log(index, "-----")
        // this.selectAllChecked = false;
        //this.allCheckedDataLength = null;
        this.taskTriggers.tasks.splice(index, 1);

        if (this.taskTriggers.tasks.length <= 0) {
          this.selectAllChecked = false;
          $('#checkboxCustella').removeClass('indeterminate');
        }
        else if (this.taskTriggers.tasks.length >= 1 && this.taskTriggers.tasks.length < this.orderGridTotalLength) {
          this.selectAllChecked = false;
          $('#checkboxCustella').addClass('indeterminate');
        }
      }
    }
    console.log(this.taskTriggers.tasks.length, "====")
    if (this.taskTriggers.tasks.length == 0) {
      this.submitTouched = true

    } else {
      this.submitTouched = false
    }
  }

  addTask() {
    var formData = _.cloneDeep(this.taskTriggersIqcFqc)
    this.taskTriggers.tasks.forEach((element: any) => {
      formData.taskListIds.push(element._id);
    });
    this.iqcfqcService.saveTaskForIqcFqc(formData).subscribe((res: any) => {
      console.log(res)
      if (res.status == 201 || res.status == 200) {
        this.errorUtil.setErrorMessage(200, 'Task added successfully', null, 'success',1000);
        let r = this.mode == "FQC" ? 'dashboard/iqc-fqc/fqc-view/' : 'dashboard/iqc-fqc/iqc-view/';
        this.router.navigate([r + this._id], { queryParams: { activeIndex: 1 } });
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error',3000);
      }
    })
  }

  search() {
    this.taskTriggers.tasks = []
    this.iqcfqcService.getAllTaskTrigger(this.filter.equipmentType, this.filter.manufacturer,
      this.filter.makeModel, this.filter.taskType, this.filter.location,
      this.filter.departmentId, this.skipTaskListIds).subscribe((data: any) => {
        console.log(data)
        if (data.status == false) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.taskTrigerList = data.data;//to show data in descending order
          console.log(this.taskTrigerList)
          // if (!this.taskTrigerList.length) { //show like this only bcoz this is not pagination call
          //   this.noTaskType = true;
          // } else {
          //   this.noTaskType = false;
          // }
        }
      })
  }

  onSearchClear(): void {
    this.searchValue = null;
    this.gridApi.resetQuickFilter();
    this.search();
    this.filterResults('');
  }


  resetSearch() {
    this.filter.equipmentType = null
    this.filter.manufacturer = null
    this.filter.makeModel = null
    this.filter.taskType = null
    this.filter.location = null
    this.filter.departmentId = null
    if (this.myDropDown) this.myDropDown.clear(null);
    this.search();
  }

  goBack() {
    this.util.goBack();
  }

}
