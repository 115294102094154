import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-iqc-details',
  templateUrl: './iqc-details.component.html'
})
export class IqcDetailsComponent implements OnInit {
  @Output() getIQCdata = new EventEmitter();
  @Input() id = null;
  params: any;
  accessToken: any;
  iqcData: any;
  refresherFqc: Subscription
  tasks: any;
  questionList: any = [];
  damageClaimsJobOrders: any;
  serviceOrderJobOrders: any;
  timezone!: string;
  dateFormat: any;
  subscriber: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  subscriber2: Subscription = new Subscription();
  statuses: any;
  fieldLabel: any;
  colorCode: any;

  constructor(
    private route: ActivatedRoute,
    private iqcfqcService: IqcFqcService,
    public util: UtilServiceService,
    private modalService2: ModalServiceService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private customerService: CustomersService,
    private jobOrderService: JobOrdersService,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService,
  ) {
    this.refresherFqc = this.iqcfqcService.refreshIqc$.subscribe(
      (res: any) => {
        // this.router.navigate(['/dashboard/IqcFqc/Iqc']);
        if (res == 'Deleted FQC') {
          this.util.goBack();
        } else {
          this.getFQCList()
        }

      });
    //   this.refresherIqc = this.iqcfqcService.refresherIqc$.subscribe((res: any) => {
    //     this.getTaskData() 
    //     this.getDamagedTaskData() 
    // });
    // this.refresherAttachments = this.customerService.refreshList$.subscribe((res : any) => {
    //   this.getTaskData() 
    //   this.getDamagedTaskData()
    // });
  }

  ngOnInit(): void {
    // this.createTask = this.perm.capable('tasks', 'create');
    this.spinner.show();
    this.init();
  }
/******************************************** start and load data ******************************************/
private init() {
 
    this.jobOrderService.getJobOrderStatuses().subscribe((res: any) => {
      this.statuses = res;

      this.getFQCList();
    })
  
}
  ngOnDestroy() {
    this.refresherFqc.unsubscribe();
    // this.refresherAttachments.unsubscribe();
    // this.refresherIqc.unsubscribe()
    this.timezone = this.auth.getUserTimezone();
    this.dateFormat = localStorage.getItem('date_format');
    if (this.subscriber) this.subscriber.unsubscribe();
    if (this.subscriber1) this.subscriber1.unsubscribe();
    if (this.subscriber2) this.subscriber2.unsubscribe();
  }

 /******************************************** View Delivery Order ******************************************/
 public sendData() {
  this.getIQCdata.emit(this.iqcData);
}
  getFQCList() {
    this.questionList = []
    this.subscriber = this.iqcfqcService.getIqcById(this.id)
      .subscribe((data: any) => {
        this.iqcData = data.data;
        this.sendData()
        if(this.iqcData.status== "OPEN") {
          this.fieldLabel= "Open"
              this.colorCode= "#B2B2B2"
          }else if(this.iqcData.status== "NEW"){
            this.fieldLabel= "New"
              this.colorCode= "#B2B2B2"
          }else if(this.iqcData.status== "IN_PROGRESS") {
            this.fieldLabel= "In Progress"
            this.colorCode= "#FDA31A"
            }else if(this.iqcData.status== "COMPLETED") {
              this.fieldLabel= "Completed"
              this.colorCode="#4ED15E"
            }else if(this.iqcData.status== "PENDING") {
              this.fieldLabel= "Pending"
              this.colorCode="#8C34B0"
            }else if(this.iqcData.status== "CANCELLED") {
              this.fieldLabel= "Cancelled"
              this.colorCode="#ED3636"
            }else if(this.iqcData.status== "IN_COMPLETED") {
              this.fieldLabel= "In Completed"
             this.colorCode= "#975E50"
            }else if(this.iqcData.status== "CLOSED_ESCALATED") {
             this.fieldLabel="Closed Escalated"
             this.colorCode= "#1F9EB5"
            }else if(this.iqcData.status== "DRAFT") {
            this.fieldLabel= "Draft"
             this.colorCode= "#1F9EB5"
             }
        this.spinner.hide();
       // this.iqcData.status = this.iqcData.status.toLowerCase();//this.iqcData.status.replace('_', ' ');
        this.iqcData.createdDate = moment.tz(new Date(this.iqcData.created_at), this.auth.getUserTimezone()).format('hh:mm A' + ', ' + localStorage.getItem('date_format')!.toUpperCase())
        this.iqcData.lastModifiedDate = moment.tz(new Date(this.iqcData.updated_at), this.auth.getUserTimezone()).format('hh:mm A' + ', ' + localStorage.getItem('date_format')!.toUpperCase())
        if (this.iqcData.serviceOrderJobOrder != null) {
          // this.getTaskData()
          this.subscriber1 = this.jobOrderService.getJobOrderById(this.iqcData.serviceOrderJobOrder.id).subscribe((res: any) => {
            this.serviceOrderJobOrders = res;
            var jobStatus = this.serviceOrderJobOrders.jobOrder?.status;
            var index = _.findIndex(this.statuses, function (status: any) {
              return status.name == jobStatus;
            });
    
            if (index >= 0) {
              this.serviceOrderJobOrders.jobOrder.statusLabel = this.statuses[index].fieldLabel;
              this.serviceOrderJobOrders.jobOrder.statusColor = this.statuses[index].colorCode+'50 !important';
            }
            console.log(this.serviceOrderJobOrders)
          })
        }
        if (this.iqcData.damageClaimsJobOrder != null) {
          // this.getDamagedTaskData()
          this.subscriber2 = this.jobOrderService.getJobOrderById(this.iqcData.damageClaimsJobOrder.id).subscribe((res: any) => {
            this.damageClaimsJobOrders = res;
            var jobStatus = this.damageClaimsJobOrders.jobOrder?.status;
            var index = _.findIndex(this.statuses, function (status: any) {
              return status.name == jobStatus;
            });
    
            if (index >= 0) {
              this.damageClaimsJobOrders.jobOrder.statusLabel = this.statuses[index].fieldLabel;
              this.damageClaimsJobOrders.jobOrder.statusColor = this.statuses[index].colorCode+'50 !important';
            }
            console.log(this.serviceOrderJobOrders)
          })

        }

        const groupBy = (array: any, key: any) => {
          return array.reduce((result: any, currentValue: any) => {
            (result[currentValue.question[key]] = result[currentValue.question[key]] || []).push(currentValue);
            return result;
          }, {}); // empty object is the initial value for result object
        };
        const personGroupedByColor = groupBy(this.iqcData.userResponse, 'sectionName');
        for (var i in personGroupedByColor) {
          this.questionList.push({ name: i, detail: personGroupedByColor[i] });
        }
      });


    this.spinner.hide();
  }

  downloadForm(file: any) {
    window.open(file, "_blank");
  }

  viewPage(data: any, page: any) {
    if (page === "jobOrderNumber"){
    localStorage.setItem("activeIndexJobOrder","0");
      this.router.navigate(['dashboard/jobOrders/view/' + data], { queryParams: { jobOrderId: data } });
    }
    else if (page === "customer")
      this.router.navigate(['dashboard/customers/view/' + data], { queryParams: { id: data } });
    else if (page === "assets")
    {
    localStorage.setItem("activeIndexAssetView", "0");
      this.router.navigate(['dashboard/assets/view/' + data], { queryParams: { id: data } });
    }
    else if (page === "iqc")
      this.router.navigate(["/dashboard/iqc-fqc/fqc-view/" + data]);

  }

  viewUser(id: any, edit: any) {
    this.router.navigate(['/dashboard/setup/allUser/view'], { queryParams: { id: id, edit: edit } });
  }

  /******************************************** menu action ******************************************/
  public delete(action: string) {
    if (action === "delete") {
      const data = {
        deleteType: "IQC",
        id: this.id,
        message: `Are you sure you want to delete ${this.iqcData.runningNumber}?`,
      };

      this.deleteRecordService.getItems(data).subscribe(() => {
        this.router.navigate(["dashboard/iqc-fqc"]);
      });
    }
  }

  edit(e: any) {
    if (e !== null) {
      this.router.navigate(["dashboard/iqc-fqc/iqc-create/" + this.id], { queryParams: { isEdit: true } });
    }
  }
}
