import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import * as moment from "moment-timezone";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-sla-table",
  templateUrl: "./sla-table.component.html",
  styleUrls: ["./sla-table.component.scss"],
})
export class SlaTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  gridApiSlaStep: any;
  logGridParamsSlaStep: any;
  gridColumnApi: any;
  columnDefsSlaStep: any;
  defaultColDefSlaStep: any;
  searchValueSlaStep: any;
  gridParamsSlaStep: any;
  startFromSlaStep: any;
  parametersSlaStep: any;
  pageSizeSlaStep: any = 25;
  slaSteps: any;
  public gridOptionsSlaStep!: Partial<GridOptions>;
  noSlaStep: boolean = false;

  frameworkComponents = {
    buttonRenderer: ButtonRendererComponent,
  };

  dateFormateChangeTime: any;

  totalRecord: any;

  @Input() loadedDymanically: boolean = false;
  @Input() ticketId: any;
  slaData: any;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private route: ActivatedRoute,
    private ticketService: TicketsService,
    private spinner: NgxSpinnerService,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init Start ******************************************/
  private preInit() {
    this.gridOptionsSlaStep = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDefSlaStep = {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.columnDefsSlaStep = [
      {
        headerName: "Name",
        field: "slaStepName",
        width: 100,
        // cellClass: function (params: any) {
        //   return ["text-1-5 font-weight-bold table_default_color"];
        // },
        headerTooltip: "#",
      },
      {
        headerName: "Duration (mins)",
        field: "slaStepViolationDuration",
        width: 80,
        valueFormatter: (params: any) => this.minuteConversion(params),
        headerTooltip: "#",
      },
      {
        headerName: "At Risk",
        field: "atRiskDateTime",
        width: 100,
        valueFormatter: (params: any) => this.dateFormateChange(params),
        headerTooltip: "#",
      },
      {
        headerName: "Violation",
        field: "violationDateTime",
        width: 100,
        valueFormatter: (params: any) => this.dateFormateChange(params),
        headerTooltip: "#",
      },
      {
        headerName: "Completed",
        field: "completedAt",
        width: 140,
        headerTooltip: "#",
        valueFormatter: (param: any) => this.dateFormateChange(param),
      },
      {
        headerName: "Violation Status",
        field: "violated",
        width: 80,
        cellRenderer: function (params: any) {
          if (params.data && params.data.violated) {
            return "Violated";
          } else {
            return "Not Violated";
          }
        },
        headerTooltip: "#",
      },
      {
        headerName: "SLA Status",
        field: "slaStatus",
        width: 100,
        headerClass: "hide-action-border-left",
        headerTooltip: "#",
      },
    ];
  }

  /******************************************** Init ******************************************/
  private init() {
    this.dateFormateChangeTime = this.auth.getUserTimezone();

    if (!this.ticketId) {
      this.ticketId = this.route.snapshot.params["id"];
    }
  }

  /******************************************** Minute Conversation ******************************************/
  private minuteConversion(params: any) {
    if (params.data) {
      let ms = params.data.slaStepViolationDuration * 60 * 1000;

      const days = Math.floor(ms / (24 * 60 * 60 * 1000));
      const daysms = ms % (24 * 60 * 60 * 1000);
      const hours = Math.floor(daysms / (60 * 60 * 1000));
      const hoursms = ms % (60 * 60 * 1000);
      const minutes = Math.floor(hoursms / (60 * 1000));
      const minutesms = ms % (60 * 1000);
      const sec = Math.floor(minutesms / 1000);
      var day = days < 9 ? "0" + days + "d " : days + "d ";
      var hour = hours < 9 ? "0" + hours + "h " : hours + "h ";
      var minute = minutes < 9 ? "0" + minutes + "min" : minutes + "min";

      if (days < 1) {
        if (hours <= 0) {
          return minute;
        } else {
          return hour + minute;
        }
      } else {
        return day + hour + minute;
      }
    }
  }

  /******************************************** Set Date Format ******************************************/
  private dateFormateChange(params: any) {
    if (
      params.data &&
      params.data.atRiskDateTime !== null &&
      params.colDef.field == "atRiskDateTime"
    ) {
      return moment
        .tz(params.data.atRiskDateTime, this.dateFormateChangeTime)
        .format(
          " hh:mm A " + localStorage.getItem("date_format")!.toUpperCase()
        );
    } else if (
      params.data &&
      params.data.violationDateTime !== null &&
      params.colDef.field == "violationDateTime"
    ) {
      return moment
        .tz(params.data.violationDateTime, this.dateFormateChangeTime)
        .format(
          " hh:mm A " + localStorage.getItem("date_format")!.toUpperCase()
        );
    }else if (
      params.data &&
      params.data.completedAt !== null &&
      params.colDef.field == "completedAt"
    ) {
      return moment
        .tz(params.data.completedAt, this.dateFormateChangeTime)
        .format(
          " hh:mm A " + localStorage.getItem("date_format")!.toUpperCase()
        );
    }
  }

  /******************************************** On Table Click ******************************************/
  public viewSlaStep(e: any) {
    // if (e.colDef.field === "slaPackage.name") {
    //   this.router.navigate(
    //     ["/dashboard/setup/sla/package/view/" + e.data.slaPackage.id],
    //     { queryParams: { id: e.data.slaPackage.id } }
    //   );
    // }
  }

  /******************************************** On Grid Ready ******************************************/
  public onGridReadySlaStep(params: any) {
    this.logGridParamsSlaStep = params;

    this.gridApiSlaStep = params.api;
    this.gridApiSlaStep.sizeColumnsToFit();

    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    this.gridApiSlaStep.sizeColumnsToFit();
    this.gridApiSlaStep.setDatasource(dataSource);
  }

  /******************************************** Get Server Data ******************************************/
  public getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.spinner.show();
    this.gridApiSlaStep.hideOverlay();

    this.push(
      this.ticketService.getAllSlaByTicketId(this.ticketId).subscribe(
        (data: any) => {
          this.slaSteps = data;
          this.totalRecord = this.slaSteps.length;
          this.setGridData(
            agGridGetRowsParams,
            this.slaSteps,
            this.totalRecord
          );

          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();

          this.errorUtil.setErrorMessage(err);
        }
      )
    );
  }

  /******************************************** set data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number
  ) {
    if (resultItems.length === 0) {
      this.noSlaStep = true;
      this.gridApiSlaStep.showNoRowsOverlay();
    } else {
      this.noSlaStep = false;
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "slaPackage.name") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    if (data.field === "slaPackage.name") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["/dashboard/setup/sla/package/view/" + data.slaPackage.id],
          { queryParams: { id: data.slaPackage.id } }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }
   push(obs:any) {
    super.push(obs);
  }
}
