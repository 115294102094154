import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ScaleType } from "@swimlane/ngx-charts";
import { HillsService } from "src/app/_services/hills.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-national-dashboard",
  templateUrl: "./national-dashboard.component.html",
  styleUrls: ["./national-dashboard.component.scss"],
})
export class NationalDashboardComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, AfterViewInit
{
  multi1: any = [];
  multi2: any = [];
  multi3: any = [];
  multi4: any = [];

  multi5: any = [];
  multi6: any = [];
  multi7: any = [];
  multi8: any = [];

  multiProduct: any = [];
  multiTechnician: any = [];

  viewHorizontalBar1: any = [1000, 1000];
  viewHorizontalBar2: any = [1000, 1000];
  viewHorizontalBar3: any = [1000, 1000];
  viewHorizontalBar4: any = [1000, 1000];
  viewHorizontalBar5: any = [1000, 1000];
  viewHorizontalBar6: any = [1000, 1000];
  viewHorizontalBar7: any = [1000, 1000];
  viewHorizontalBar8: any = [1000, 1000];
  viewHorizontalBarProduct: any = [1000, 1000];
  viewHorizontalBarTechnician: any = [1000, 1000];

  // options
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  timeline: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;

  colorSchemeHTR = {
    name: "schemeColorHTR",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#27938E"],
  };
  colorSchemeHostel = {
    name: "schemeColorHostel",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#B26417"],
  };
  colorSchemeLincor = {
    name: "schemeColorLincor",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#48192D"],
  };
  colorSchemeGetwell = {
    name: "schemeColorGetwell",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#BF9620"],
  };
  colorSchemeProduct = {
    name: "schemeColorProduct",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#4D516A"],
  };

  @ViewChild("htrContainer", { static: false }) htrContainer!: ElementRef;
  @ViewChild("hostelContainer", { static: false }) hostelContainer!: ElementRef;
  @ViewChild("lincorContainer", { static: false }) lincorContainer!: ElementRef;
  @ViewChild("getWellContainer", { static: false })
  getWellContainer!: ElementRef;

  @ViewChild("htrResoContainer", { static: false })
  htrResoContainer!: ElementRef;
  @ViewChild("hostelResoContainer", { static: false })
  hostelResoContainer!: ElementRef;
  @ViewChild("lincorResoContainer", { static: false })
  lincorResoContainer!: ElementRef;
  @ViewChild("getWellResoContainer", { static: false })
  getWellResoContainer!: ElementRef;

  @ViewChild("productContainer", { static: false })
  productContainer!: ElementRef;
  @ViewChild("joTechContainer", { static: false }) joTechContainer!: ElementRef;

  constructor(private hillService: HillsService) {
    super();
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit(): void {
    this.afterView();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.firstClick();
    this.resolutionClick();
    this.productClick();
    this.technicianClick();
  }

  /******************************************** After View Init ******************************************/
  private afterView() {
    // fault
    this.resizeChart(
      this.htrContainer.nativeElement.offsetWidth,
      this.htrContainer.nativeElement.offsetHeight,
      "htr"
    );
    this.resizeChart(
      this.hostelContainer.nativeElement.offsetWidth,
      this.hostelContainer.nativeElement.offsetHeight,
      "hostel"
    );
    this.resizeChart(
      this.lincorContainer.nativeElement.offsetWidth,
      this.lincorContainer.nativeElement.offsetHeight,
      "lincor"
    );
    this.resizeChart(
      this.getWellContainer.nativeElement.offsetWidth,
      this.getWellContainer.nativeElement.offsetHeight,
      "getwell"
    );

    // resolution
    this.resizeChart(
      this.htrResoContainer.nativeElement.offsetWidth,
      this.htrResoContainer.nativeElement.offsetHeight,
      "htr"
    );
    this.resizeChart(
      this.hostelResoContainer.nativeElement.offsetWidth,
      this.hostelResoContainer.nativeElement.offsetHeight,
      "hostel"
    );
    this.resizeChart(
      this.lincorResoContainer.nativeElement.offsetWidth,
      this.lincorResoContainer.nativeElement.offsetHeight,
      "lincor"
    );
    this.resizeChart(
      this.getWellResoContainer.nativeElement.offsetWidth,
      this.getWellResoContainer.nativeElement.offsetHeight,
      "getwell"
    );

    // misc
    this.resizeChart(
      this.productContainer.nativeElement.offsetWidth,
      this.productContainer.nativeElement.offsetHeight,
      "product"
    );
    this.resizeChart(
      this.joTechContainer.nativeElement.offsetWidth,
      this.joTechContainer.nativeElement.offsetHeight,
      "jotech"
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** XAxis Tick Formating ******************************************/
  public xAxisTickFormattingFn(value: any) {
    if (value % 1 === 0) {
      return value.toLocaleString();
    } else {
      return "";
    }
  }

  /******************************************** First Click ******************************************/
  public firstClick() {
    this.push(
      this.hillService
        .getClosedFaultType("", 12, "HTR")
        .subscribe((res: any) => {
          this.multi1 = res;

          // if (this.multi1.length <= 5) {
          //   this.viewHorizontalBar1 = [1000, 200];
          // } else if (this.multi1.length > 5 && this.multi1.length < 10) {
          //   this.viewHorizontalBar1 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar1 = [1000, 300];
          // }
        })
    );

    this.push(
      this.hillService
        .getClosedFaultType("", 12, "HOSTEL")
        .subscribe((res: any) => {
          this.multi2 = res;
          // if (this.multi2.length <= 5) {
          //   this.viewHorizontalBar2 = [1000, 200];
          // } else if (this.multi2.length > 5 && this.multi2.length < 10) {
          //   this.viewHorizontalBar2 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar2 = [1000, 300];
          // }
        })
    );

    this.push(
      this.hillService
        .getClosedFaultType("", 12, "LINCOR")
        .subscribe((res: any) => {
          this.multi3 = res;
          // if (this.multi3.length <= 5) {
          //   this.viewHorizontalBar3 = [1000, 200];
          // } else if (this.multi3.length > 5 && this.multi3.length < 10) {
          //   this.viewHorizontalBar3 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar3 = [1000, 300];
          // }
        })
    );

    this.push(
      this.hillService
        .getClosedFaultType("", 12, "GETWELL")
        .subscribe((res: any) => {
          this.multi4 = res;
          // if (this.multi4.length <= 5) {
          //   this.viewHorizontalBar4 = [1000, 200];
          // } else if (this.multi4.length > 5 && this.multi4.length < 10) {
          //   this.viewHorizontalBar4 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar3 = [1000, 300];
          // }
        })
    );
  }

  /******************************************** Resolution Click ******************************************/
  public resolutionClick() {
    this.push(
      this.hillService
        .getResolutionType("", 12, "HTR")
        .subscribe((res: any) => {
          this.multi5 = res;
          // if (this.multi5.length <= 5) {
          //   this.viewHorizontalBar5 = [1000, 200];
          // } else if (this.multi5.length > 5 && this.multi5.length < 10) {
          //   this.viewHorizontalBar5 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar5 = [1000, 300];
          // }
        })
    );

    this.push(
      this.hillService
        .getResolutionType("", 12, "HOSTEL")
        .subscribe((res: any) => {
          this.multi6 = res;
          // if (this.multi6.length <= 5) {
          //   this.viewHorizontalBar6 = [1000, 200];
          // } else if (this.multi6.length > 5 && this.multi6.length < 10) {
          //   this.viewHorizontalBar6 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar6 = [1000, 300];
          // }
        })
    );

    this.push(
      this.hillService
        .getResolutionType("", 12, "LINCOR")
        .subscribe((res: any) => {
          this.multi7 = res;
          // if (this.multi7.length <= 5) {
          //   this.viewHorizontalBar7 = [1000, 200];
          // } else if (this.multi7.length > 5 && this.multi7.length < 10) {
          //   this.viewHorizontalBar7 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar7 = [1000, 300];
          // }
        })
    );

    this.push(
      this.hillService
        .getResolutionType("", 12, "GETWELL")
        .subscribe((res: any) => {
          this.multi8 = res;
          // if (this.multi8.length <= 5) {
          //   this.viewHorizontalBar8 = [1000, 200];
          // } else if (this.multi8.length > 5 && this.multi8.length < 10) {
          //   this.viewHorizontalBar8 = [1000, 500];
          // } else {
          //   this.viewHorizontalBar7 = [1000, 300];
          // }
        })
    );
  }

  /******************************************** Product Click ******************************************/
  public productClick() {
    this.push(
      this.hillService.getProductUsage("", 12).subscribe((res: any) => {
        this.multiProduct = res;
        // if (this.multiProduct.length <= 5) {
        //   this.viewHorizontalBarProduct = [1000, 200];
        // } else if (
        //   this.multiProduct.length > 5 &&
        //   this.multiProduct.length < 10
        // ) {
        //   this.viewHorizontalBarProduct = [1000, 500];
        // } else {
        //   this.viewHorizontalBarProduct = [1000, 300];
        // }
      })
    );
  }

  /******************************************** Technician Click ******************************************/
  public technicianClick() {
    this.push(
      this.hillService.getJobsTechnician("", 12).subscribe((res: any) => {
        this.multiTechnician = res;
        // if (this.multiTechnician.length <= 5) {
        //   this.viewHorizontalBarTechnician = [1000, 200];
        // } else if (
        //   this.multiTechnician.length > 5 &&
        //   this.multiTechnician.length < 10
        // ) {
        //   this.viewHorizontalBarTechnician = [1000, 500];
        // } else {
        //   this.viewHorizontalBarTechnician = [1000, 300];
        // }
      })
    );
  }

  /******************************************** Resize Chart ******************************************/
  public resizeChart(width: number, height: number, chart: string) {
    let widthView = width - 100;
    let heightView = height;

    if (chart === "htr") {
      this.viewHorizontalBar1 = [widthView, 300];
      this.viewHorizontalBar5 = [widthView, 300];
    }

    if (chart === "hostel") {
      this.viewHorizontalBar2 = [widthView, 300];
      this.viewHorizontalBar6 = [widthView, 300];
    }

    if (chart === "lincor") {
      this.viewHorizontalBar3 = [widthView, 300];
      this.viewHorizontalBar7 = [widthView, 300];
    }

    if (chart === "getwell") {
      this.viewHorizontalBar4 = [widthView, 300];
      this.viewHorizontalBar8 = [widthView, 300];
    }

    if (chart === "product") {
      this.viewHorizontalBarProduct = [widthView, 300];
    }

    if (chart === "jotech") {
      this.viewHorizontalBarTechnician = [widthView, 300];
    }
  }
 
  push(obs:any) {
    super.push(obs);
  }
}
