<div class="custella-form-container-content" *ngIf="teamTasks.noData === false">
    <div class="custella-content-row" style="justify-content: unset;" *ngIf="!isSlideshowOn">
        <div class="custella-content-row-input col-3">
            <p-dropdown [options]="teamTasks.regions" optionLabel="label" optionValue="name" [(ngModel)]="teamTasks.selectedRegion" (onChange)="updateData($event)" id="picklist_ChubbTaskTimeFrameField"></p-dropdown>
        </div>
        <div class="custella-content-row-input col-3">
            <p-dropdown [options]="teamTasks.timeFrames" [(ngModel)]="teamTasks.selectedTimeframe" optionLabel="label" optionValue="name" (onChange)="updateData($event)" id="picklist_ChubbTaskRegionField"></p-dropdown>
        </div>
    </div>
    <div class="custella-content-row pe-0">
        <div class="all-team-box w-100" *ngIf="teamTasks.noData === false">

            <div class="team-box cursor-pointer" *ngFor="let team of teamTasks.teams" id="btn_ChubbTaskNavigate" (click)="navigateToTaskByTeam(team)">
                <div class="color-bar" [ngStyle]="{'background-color': team.color}"></div>
                <div class="team-name">
                    <span class="text-16 font-medium text-white" id="label_ChubbTaskTeamName">{{team.name | uppercase}}</span>
                </div>
                <div class="team-task-data">
                    <div class="d-flex flex-column mx-5">
                        <span class="mb-0 text-white text-10" id="label_ChubbTaskOpen">
                            <i>{{ "open_task" | translate }}</i>
                        </span>
                        <span class="mb-0 text-white me-2 font-medium text-20" id="value_ChubbTaskOpen">
                            {{team.tasks.open}}
                        </span>
                    </div>
                    <div class="divider-end-white"></div>
                    <div class="d-flex flex-column mx-5">
                        <span class="mb-0 text-white text-10" id="label_ChubbTaskClose">
                            <i>{{ "closed_task" | translate }}</i>
                        </span>
                        <span class="mb-0 text-white me-2 font-medium text-20" id="value_ChubbTaskClose">
                            {{team.tasks.closed}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="teamTasks.noData" class="col-12 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2"  *ngIf="!isSlideshowOn">
        <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold" translate>
            {{ "no_records_found" }}
        </p>
    </div>

    <div class="col-md-12 px-0 my-2 text-center mt-2"  *ngIf="isSlideshowOn">
        <img src="assets\svg\empty-state.svg" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold text-white" translate>
            {{ "no_records_found" }}
        </p>
    </div>
</div>