import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild } from '@angular/core';
import {MapInfoWindow, MapMarker} from '@angular/google-maps';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-view-checkin-map',
  templateUrl: './view-checkin-map.component.html',
  styleUrls: ['./view-checkin-map.component.scss']
})
export class ViewCheckinMapComponent implements OnInit {
  
  map : any
  data : any;
  zoom: any;
  markers: any[] = [];
  latlngBounds : any;
  title : any;
  optionsMap: any;
  @ViewChild(MapInfoWindow,{static: false})
  infoWindow!: MapInfoWindow;
  displayActual:boolean = false
  selectedUserdata: any;
  pointer: any={x:12.4,y:13}
   scaledSize :any ={height: 34,width: 24}
   timezone: any

  constructor(public modalRef : BsModalRef,
    private auth: AuthenticationService,
  ) { }

  ngOnInit() {
     // Get the user's timezone, this will be useful for displaying time-related information correctly
    this.timezone = this.auth.getUserTimezone()

   // Set the center of the map to the location from the activity data
  // This uses the latitude and longitude from the activity, which represents the most recent location
   this.optionsMap = {
    center: { 
      lat: parseFloat(this.data.activity.lattitude), // Convert the latitude to a floating-point number
      lng: parseFloat(this.data.activity.longitude) },// Convert the longitude to a floating-point number
    zoom: 17, // Default zoom level for the map
  };
    console.log("View Map----- ", this.data);
    this.zoom = 12;

     // Set the title based on the activity type
    if(this.data.activity.type == 'TASK_CHECKIN'){
      this.title = 'Check In Location'; // Title when the activity is a check-in
    } else {
      this.title = 'Check Out Location'; // Title when the activity is a check-out
    }

     // Marker for the task location, where the task is supposed to be performed
    var marker : any = {
      lat : this.data.task.latitude, // Latitude of the task location
      lng : this.data.task.longitude, // Longitude of the task location
      name : this.data.task.taskName, // Name of the task
      startTime : this.data.task.startDateTime, // Start time of the task
      endTime : this.data.task.endDateTime, // End time of the task
      id : this.data.task.id , // Task ID
      location : this.data.task.location, // Task location description
      icon : '../../../../assets/images/map-icons/task_icon.png' // Icon for the task marker
    }
    this.markers.push(marker);

    // Marker for the activity location, where the user has checked in or checked out
    var marker2 : any = {
      lat : parseFloat(this.data.activity.lattitude), // Convert and use the latitude of the activity location
      lng : parseFloat(this.data.activity.longitude), // Convert and use the longitude of the activity location
      name : `${this.data.activity.note} Location`, // Note or description of the activity location
      icon : '../../../../assets/images/planning-hub/Blue Pin.png' // Icon for the activity marker
    };
    this.markers.push(marker2); // Add the activity marker to the markers array

    console.log(this.markers); // Log the markers array for debugging purposes
  }
  openInfoWindow(marker: MapMarker,data : any) {
    var geocoder = new google.maps.Geocoder;
    console.log(marker)
    var self = this;
    var latlng = {lat: data.userLattitude, lng: data.userLongitude};
   
    this.displayActual = true
    this.selectedUserdata = data
    this.infoWindow.open(marker);
  }

}
