import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ProfilesService } from "src/app/_services/admin/profiles.service";
import { ErrorUtil } from "src/app/_utilities/error";

@Component({
  selector: "app-clone-access",
  templateUrl: "./clone-access.component.html",
  styleUrls: ["./clone-access.component.scss"],
})
export class CloneAccessComponent implements OnInit {
  formData: any = {};
  profile: any = {};
  submitTouched = false;
  addProfileForm!: UntypedFormGroup;
  data: any;

  constructor(
    public modalRef: BsModalRef,
    private profileService: ProfilesService,
    private router: Router,
    private errorUtil: ErrorUtil
  ) {
    this.preInit();
  }

  ngOnInit() {}

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.addProfileForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, [Validators.required]),
    });
  }

  /******************************************** On Submit ******************************************/
  public onSubmit() {
    this.submitTouched = true;
    if (!this.addProfileForm.valid) {
      for (const i in this.addProfileForm.controls) {
        this.addProfileForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    this.profileService
      .cloneProfile(this.data.id, this.profile.name)
      .subscribe((res: any) => {
        //console.log("Response ", res);
        if (res.status === 200 || res.status === 201) {
          this.modalRef.hide();

          this.errorUtil.setErrorMessage(
            200,
            "Profile cloned successfully",
            null,
            "success",
            1000
          );

          this.router.navigate(
            ["dashboard/setup/profiles/view/" + res.body.profile.id],
            { queryParams: { id: res.body.profile.id } }
          );
        }

        this.submitTouched = false;
      });
  }

  /******************************************** Assign Field Name ******************************************/
  public assignFieldName(val: any) {
    if (val) {
      this.profile.fieldName = this.profile.name.replace(/ /g, "_");
    }
  }
}
