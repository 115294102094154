import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { DeliveryOrderSetupService } from "src/app/_services/delivery-order-setup.service";

import * as moment from "moment-timezone";
import { DeliveryOrderService } from "src/app/_services/delivery-order.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { DeliveryOrderStatus, PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "../../table-header-tool-tip/table-header-tool-tip.component";

var dateFormateChange: any;
var statusDO: any[] = [];
var orderType: any[] = [];
var doOrderWays: any[] = [];

@Component({
  selector: "app-custella-do",
  templateUrl: "./custella-do.component.html",
  styleUrls: ["./custella-do.component.scss"],
})
export class CustellaDOComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  @Output() doSelector = new EventEmitter();
  @Output() closeDOView = new EventEmitter();
  @Input() notInOrderIds: any = "";
  @Input() statuses: any = [];

  public filterToggle = false;
  public totalRecord: any;

  dateConfig: any;
  dateRange: any;
  customers: any[] = [];
  filter: any = {
    OrderStatus: null,
    customerId: null,
    salesOrderId: null,
    orderType: null,
    fromDate: "",
    toDate: "",
    splitType: null,
  };
  deliveryOrders: any[] = [];
  deliveryOrders1: any[] = [];
  deliveryOrder = [];
  public gridOptions!: Partial<GridOptions>;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  @Input() searchValue: any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  nodeliveryOrder: boolean = false;
  selectedRange: any;
  pageSize: any = 25;
  maxDate = new Date();
  overlayNoRowsTemplate: any;
  note: any;
  actionData: any;
  // statuses: any;
  addonFeatureSNL: any;

  public customerId: any;
  public salesOrderId: any;

  public allPhases = [
    {
      value: "LEG_FIRST",
      label: "Pickup",
    },
    {
      value: "LEG_SECOND",
      label: "Drop-off",
    },
  ];

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private deliveryOrderSetupService: DeliveryOrderSetupService,
    private deliveryOrderService: DeliveryOrderService,
    private deleteRecordService: DeleteRecordService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length > 2
    ) {
      this.refresh();
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length === 0
    ) {
      this.refresh();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.addonFeatureSNL = this.auth.getAddonFeatureLists().addonSNL;

    if (this.addonFeatureSNL === "SNL_TENANT") {
      this.columnDefs = [
        {
          headerName: "Delivery Order Number",
          sort: "desc",
          field: "name",
          width: 250,
          headerTooltip: "#",
          cellClass: function (params: any) {
            return ["text-1-5 font-weight-bold table_default_color"];
          },
          cellRenderer: function (params: any) {
            if (params.data) {
              var splitType = "";
              if (params.data.splitType == "LEG_FIRST") {
                splitType =
                  '<div style="background: #26363e;height:20px;padding: 5px;margin-left: 4px; margin-top: 2.5px; border-radius: 5px" class="d-flex align-items-center"><span style="color: #fff">1</span></div>';
              } else if (params.data.splitType == "LEG_SECOND") {
                splitType =
                  '<div style="background: #26363e;height:20px;padding: 5px;margin-left: 4px; margin-top: 2.5px; border-radius: 5px" class="d-flex align-items-center"><span style="color: #fff">2</span></div>';
              }
              return (
                "<div class='d-flex'>" + params.data.name + splitType + "</div>"
              );
            }
          },
        },
        {
          headerName: "Order Date & Time",
          field: "orderDateTime",
          width: 200,
          headerTooltip: "#",
          cellRenderer: function (params: any) {
            if (params.data && params.data.orderDateTime) {
              return (
                moment
                  .tz(params.data.orderDateTime, dateFormateChange)
                  .format("hh:mm A") +
                ", " +
                moment
                  .tz(params.data.orderDateTime, dateFormateChange)
                  .format(localStorage.getItem("date_format")!.toUpperCase())
              );
            }
          },
        },
        {
          headerName: "Customer",
          field: "customer.name",
          width: 150,
          headerTooltip: "#",
          cellClass: function (params: any) {
            return ["text-1-5 font-weight-bold table_default_color"];
          },
        },
        {
          headerName: "Dropoff",
          field: "address",
          width: 200,
          tooltipField: "tooltipValue",
          sortable: false,
          headerTooltip: "#",
          cellClass: function (params: any) {
            return ["hand"];
          },
          cellRenderer: function (params: any) {
            if (params && params.data && params.data.deliveryOrderLineItemVMS) {
              var deliveryOrderLineItemVMS = "";
              if (params.data.deliveryOrderLineItemVMS.length > 1) {
                let coun = params.data.deliveryOrderLineItemVMS.length - 1;
                deliveryOrderLineItemVMS =
                  '<div class="py-1 px-2 text-1" style="background-color: #b5b5b5; border-radius: 9px;border-radius: 9px; position: absolute; z-index: 9999;top: 1px;right: 0;">+' +
                  coun +
                  " </div>";
              }
              if (
                params.data.deliveryOrderLineItemVMS &&
                params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem &&
                params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem
                  .dropOffAddress
              ) {
                return (
                  '<div class="text-1-5 py-1 d-flex align-items-center"><p class="pl-2 mb-2 normal-font" style="color:#000">' +
                  params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem
                    .dropOffAddress +
                  "</p></div> " +
                  deliveryOrderLineItemVMS
                );
              } else if (
                params.data.deliveryOrderLineItemVMS &&
                params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem &&
                params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem
                  .dropOffBay
              ) {
                return (
                  '<div class="text-1-5 py-1 d-flex align-items-center"><p class="pl-2 mb-2 normal-font" style="color:#000">' +
                  params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem
                    .dropOffBay.name +
                  "</p></div> " +
                  deliveryOrderLineItemVMS
                );
              } else if (
                params.data.deliveryOrderLineItemVMS &&
                params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem &&
                params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem
                  .dropOffDepot
              ) {
                return (
                  '<div class="text-1-5 py-1 d-flex align-items-center"><p class="pl-2 mb-2 normal-font" style="color:#000">' +
                  params.data.deliveryOrderLineItemVMS[0].deleveryOrderLineItem
                    .dropOffDepot.name +
                  "</p></div> " +
                  deliveryOrderLineItemVMS
                );
              } else {
                return "-";
              }
            }
          },
        },

        {
          headerName: "Pickup Date",
          field: "pickupDate",
          width: 150,
          headerTooltip: "#",
          cellRenderer: function (params: any) {
            if (params.data && params.data.pickupDate) {
              return moment(params.data.pickupDate).format(
                localStorage.getItem("date_format")!.toUpperCase()
              );
            }
          },
        },
        {
          headerName: "Status",
          width: 150,
          field: "orderStatus",
          headerTooltip: "#",
          cellRenderer: this.formatStatus,
        },
      ];
    }

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      suppressHorizontalScroll: true,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  }

  /******************************************** Formats DO Status ******************************************/
  private formatStatus(params: any) {
    if (params.data) {
      for (var i = 0; i < statusDO.length; i++) {
        if (String(params.data.orderStatus) == String(statusDO[i].name)) {
          let status = "";
          status =
            ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
            statusDO[i].colorCode +
            "50 !important ;color:" +
            statusDO[i].colorCode +
            '"><span class="p-tag-value text-dark">' +
            statusDO[i].label +
            "</span></span>";

          return status;
        }
      }
    }
  }

  /******************************************** Init ******************************************/
  loaded = false
  private init() {
    this.maxDate.setDate(this.maxDate.getDate());
    dateFormateChange = this.auth.getUserTimezone();
/**================================== Get DO Order Status =========================================== */
    this.statuses = DeliveryOrderStatus.statusList
    if(this.statuses && this.statuses.length > 0){
        this.statuses.forEach((element: any) => {
          statusDO.push(element);
        });
        localStorage.setItem("doStatuses", JSON.stringify(this.statuses));
      }else {
        this.setDeliveryOrderStatusData()
    //);
  }
/**================================== Get DO Order Type =========================================== */
  if(DeliveryOrderStatus.orderTypeList.length > 0){
    DeliveryOrderStatus.orderTypeList.forEach((element: any) => {
      orderType.push(element);
    });
    
  }else {
    this.setDeliveryOrderTypeData()
//);
}
/**================================== Get DO Order Way =========================================== */
  if(DeliveryOrderStatus.orderWayList.length > 0){
    DeliveryOrderStatus.orderWayList.forEach((element: any) => {
      doOrderWays.push(element);
    });
    
  }else {
    this.setDeliveryOrderWayData()
//);
}

   

    if (this.addonFeatureSNL != "SNL_TENANT") {
      this.push(
        this.deliveryOrderService
          .getListColumn("delevery_order")
          .subscribe((res: any) => {
            var columnDefs = this.gridOptions.columnDefs!;
            res.tableColumnSetups.forEach((element: any) => {
              this.cellRenderer(element);

              if (element.apiField === "delevery_order.name") {
                element.field = "orderNumber";
              }

              columnDefs.push(element);
              this.gridApi.setColumnDefs(columnDefs);
            });

            this.gridApi.setColumnDefs(columnDefs);
            this.loaded = true;
            this.onGridReady(this.gridParams)
          })
      );
    }else {this.loaded = true; this.onGridReady(this.gridParams)}
  }
  /**================================== Set DO Order Way =========================================== */
  setDeliveryOrderWayData(){
    this.push(
      this.deliveryOrderService.getOrderWay().subscribe((resOrderWays: any) => {
        DeliveryOrderStatus.orderWayList = resOrderWays
        resOrderWays.forEach((element: any) => {
          doOrderWays.push(element);
        });
      })
    );
  }
  /**================================== Set DO Order Tyoe =========================================== */
  setDeliveryOrderTypeData(){
    this.push(
      this.deliveryOrderSetupService.getActiveOrderType().subscribe((resType: any) => {
        DeliveryOrderStatus.orderTypeList = resType
          resType.forEach((element: any) => {
            orderType.push(element);
          });
        })
    );
  }
/**================================== Set DO Order Status =========================================== */
  setDeliveryOrderStatusData(){
    this.deliveryOrderSetupService.getAllStatus().subscribe((res:any) => {
      this.statuses = res.sort((elementA: any, elementB: any) => {
        if (elementA?.order > elementB?.order) {
          return 1;
        }

        if (elementA?.order < elementB?.order) {
          return -1;
        }

        return 0;
      });
      DeliveryOrderStatus.statusList = this.statuses
      this.statuses.forEach((element: any) => {
        statusDO.push(element);
      });
    })
  }
  /******************************************** Render Cell ******************************************/
  private cellRenderer(element: any) {
    element.headerTooltip = "#";

    if (element.field === "name") {
      element.cellClass = function (params: any) {
        return ["text-1-5 font-weight-bold table_default_color"];
      };
    } else if (element.field === "customer.name") {
      element.cellClass = function (params: any) {
        return ["text-1-5 font-weight-bold table_default_color"];
      };
    } else if (element.dataType == "date") {
      if (element.field == "orderDateTime") {
        element.valueFormatter = function (params: any) {
          if (params.data && params.data.orderDateTime) {
            return (
              moment
                .tz(params.data.orderDateTime, dateFormateChange)
                .format("hh:mm A") +
              ", " +
              moment
                .tz(params.data.orderDateTime, dateFormateChange)
                .format(localStorage.getItem("date_format")!.toUpperCase())
            );
          }
        };
      } else if (element.field == "pickupDate") {
        element.valueFormatter = function (params: any) {
          if (params.data && params.data.pickupDate) {
            return moment
              .tz(params.data.pickupDate, dateFormateChange)
              .format(localStorage.getItem("date_format")!.toUpperCase());
          }
        };
      }
    } else if (element.field == "orderStatus") {
      element.cellRenderer = this.formatStatus;
    } else if (element.field == "deleveryType") {
      element.cellRenderer = function (params: any) {
        if (params && params.data && params.data.deleveryType) {
          var data = null;
          for (var i = 0; i < orderType.length; i++) {
            if (String(params.data.deleveryType) == String(orderType[i].name)) {
              data = orderType[i].label;
            }
          }
          return data;
        }
      };
    } else if (element.field == "deliveryOrderWay") {
      element.cellRenderer = function (params: any) {
        if (params && params.data && params.data.deliveryOrderWay) {
          var data = null;
          for (var i = 0; i < doOrderWays.length; i++) {
            if (
              String(params.data.deliveryOrderWay) ==
              String(doOrderWays[i].name)
            ) {
              data = doOrderWays[i].label;
            }
          }
          return data;
        }
      };
    }

    if (element.field === 'geoFence') {
      element.cellRenderer = function (params: any) {
        if (params && params.data && params.data.geoFence) {
          return params.data.geoFence.geoFenceName;
        }
      };
    }
  }

  /******************************************** On Grid Ready ******************************************/
  i_ = 0;
  public async onGridReady(params: any) {
    console.log(params)
    // this.spinner.show();
    // let t = setTimeout(() => {
    this.gridParams = params;
    this.gridApi = await params.api;
    if(this.gridApi) await this.gridApi.sizeColumnsToFit();
    this.gridApi.showLoadingOverlay();
    if(!this.loaded)  return
    if (params) {
      this.gridParams = params;
      this.searchValue = this.searchValue ? this.searchValue : "";
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var dataSource = await {
        getRows: (params: any) => {
          this.gridApi.hideOverlay();
          this.startFrom = params.startRow;
          if (params.sortModel.length <= 0) {
            this.parameters = { colId: "orderDateTime", sort: "asc" };
            params.sortModel.push(this.parameters);
          }

          this.push(
            this.deliveryOrderService
              .getDeliveryOrders(
                this.filter.fromDate,
                this.filter.toDate,
                this.filter.OrderStatus,
                this.filter.customerId,
                this.filter.orderType,
                this.searchValue,
                params,
                this.filter.salesOrderId,
                this.filter.splitType,
                this.notInOrderIds,
                null,
                this.addonFeatureSNL,
                null,
                null
              )
              .subscribe((data: any) => {
                if (data.status === "NO_DATA") {
                  this.nodeliveryOrder = true;
                } else {
                  this.nodeliveryOrder = false;
                  this.deliveryOrders = data.data;
                  this.deliveryOrders.forEach((element) => {
                    if (element.deliveryOrderLineItemVMS) {
                      this.note = [];
                      var bay = [];
                      element.deliveryOrderLineItemVMS.forEach((ele: any) => {
                        ele.deleveryOrderLineItemPackages.forEach(
                          (pack: any) => {
                            this.note.push(
                              "(" + pack.quantity + ")" + "-" + pack.name
                            );
                          }
                        );
                      });

                      if (element.deliveryOrderLineItemVMS.length > 1) {
                        for (
                          var i = 1;
                          i <= element.deliveryOrderLineItemVMS.length - 1;
                          i++
                        ) {
                          if (
                            element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem &&
                            !element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem.dropOffBay
                          ) {
                            bay.push(
                              element.deliveryOrderLineItemVMS[i]
                                .deleveryOrderLineItem.dropOffAddress
                            );
                          } else if (
                            element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem &&
                            element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem.dropOffBay
                          ) {
                            bay.push(
                              element.deliveryOrderLineItemVMS[i]
                                .deleveryOrderLineItem.dropOffBay.name
                            );
                          }
                        }
                      }
                    }
                    element.tooltipValue = bay;
                    element.note = this.note;
                  });
                  if (this.deliveryOrders.length === 0) {
                    this.gridApi.showNoRowsOverlay();

                    this.totalRecord = 0;

                    params.successCallback([], 0);
                  } else {
                    this.totalRecord = data.total;

                    params.successCallback(this.deliveryOrders, data.total);
                  }
                }
                // this.spinner.hide();
              })
          );
        },
      };

      if(this.gridApi){
        this.gridApi.sizeColumnsToFit();
        this.gridApi.setDatasource(dataSource);
      }
    }
  // }, 1000);
  }

  /******************************************** On Refresh ******************************************/
  public refresh() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** View Delivery Order ******************************************/
  public viewDeliveryOrder(e: any) {
    this.doSelector.emit(e.data);
  }

  closeDO() {
    this.closeDOView.emit(false);
  }
  quickSearch() {
    this.onGridReady(this.gridParams);
  }

    /******************************************** Right Click Context Menu ******************************************/
    public getContextMenuItems = (params: any) => {
      params.node.data.field = params.column.colId;
      let url = "";
      let data = "";
      console.log(data);
  
      let results = [];
  
      if (params.column.colId == "name") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }

      if (params.column.colId == "customer.name") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }
  
      return results;
    };
  
    /******************************************** Redirect To ******************************************/
    private redirectToNewTab(data: any) {
      let url;
      if (data.field === "name") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["dashboard/deliveryOrders/view/" + data.id],
            {
              queryParams: { doId: data.id },
            }
          )
        );
      }

      if (data.field === "customer.name") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["dashboard/customers/view/" + data.id],
            {
              queryParams: { id: data.id },
            }
          )
        );
      }
  
      if (url) {
        url = url.replace("%23", "#");
        this.util.openItemToNewTab(url);
      }
    }

    push(obs:any) {
      super.push(obs);
    }
}
