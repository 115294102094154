import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UsersService } from 'src/app/_services/admin/users.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-disconnect-confmation',
  templateUrl: './disconnect-confmation.component.html',
  styleUrls: ['./disconnect-confmation.component.scss']
})
export class DisconnectConfmationComponent implements OnInit {

  data: any;
  page: any
  title: any;
  goBack : boolean = false;

  constructor(public modalRef : BsModalRef,
    private errorUtil: ErrorUtil,
    private userService : UsersService) { }

  ngOnInit() {
    console.log(this.data);
  }

  onSubmit() {

if(this.page == 'Google'){
    this.userService.disconnectGoogleCalender().subscribe((res:any)=>{
      // console.log("Response ", res);
      this.errorUtil.setErrorMessage(200, 'Google Calendar disconnected successfully' ,  null, 'success',3000);;
      this.modalRef.hide();
    });
  }else if(this.page == 'Microsoft'){
    this.userService.disconnectMicrosoftCalender().subscribe((res:any)=>{
      // console.log("Response ", res);
      this.errorUtil.setErrorMessage(200, 'Microsoft Calendar disconnected successfully' ,  null, 'success',3000);;
      this.modalRef.hide();
    });
  }else{
    this.userService.disconnectCalendlyCalender().subscribe((res:any)=>{
      // console.log("Response ", res);
      this.errorUtil.setErrorMessage(200, 'Calendly disconnected successfully' ,  null, 'success',3000);;
      this.modalRef.hide();
    });
  }

  }
}