import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalServiceService } from 'src/app/_services/modal-service.service';

@Component({
  selector: 'app-task-calendar-view',
  templateUrl: './task-calendar-view.component.html'
})
export class TaskCalendarViewComponent implements OnInit {

  constructor(private route: Router, private modal: ModalServiceService) { }

  ngOnInit(): void {
  }

  optimizeTask(){
    this.route.navigate(['dashboard/tasks/optimize'])
  }
  cloneTask(){
    this.modal.openModal("taskClone", null);
  }

}
