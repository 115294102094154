<div class="custella-setup-stickey-breadcrumb"> 
    <p-breadcrumb [model]="items"></p-breadcrumb> 
 </div> 
<div class="position-relative fw-normal mt-5" style="height: 100%">
    <p-tabView #tabView class="px-0" (onChange)="newTab($event)">
        <p-tabPanel header="IQC" [selected]="activeIndex == 0" >
            <app-iqc ></app-iqc>
        </p-tabPanel>
        <p-tabPanel header="FQC" [selected]="activeIndex == 1" >
            <app-fqc ></app-fqc>
        </p-tabPanel>
    </p-tabView>
</div>
