<form (ngSubmit)="onSubmit()" [formGroup]="partOrderForm">
    <div class="custella-form-container position-relative d-block rounded-2" style="margin-top: 3.5rem;">
        <!-- Container Content -->
        <div class="custella-form-container-title text-14 font-medium rounded-top">
            <span>{{ "part_order_details" | translate }}</span>
        </div>
        <div class="custella-form-container-content" style="min-height: 10rem;">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" translate>
                        {{ "job_order_id" | translate }}
                    </span>
  
                    <input pInputText autocomplete="off" type="text" disabled [(ngModel)]="partOrder.jobOrderNumber" [ngModelOptions]="{ standalone: true }" />
                </div>
  
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title mandatory" translate>
                        {{ "part_order_no" | translate }}
                    </span>
  
                    <input pInputText autocomplete="off" type="text" [(ngModel)]="partOrder.partOrderNumber" formControlName="partOrderNo" name="partOrderNo" />
  
                    <div
                        class="p-error text-10"
                        *ngIf="
              partOrderForm.controls['partOrderNo'].touched &&
              partOrderForm.controls['partOrderNo'].invalid
            "
                    >
                        <span *ngIf="partOrderForm.controls['partOrderNo'].errors?.required" translate>{{ "required_field" }}</span>
                    </div>
                </div>
            </div>
  
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" translate>
                        {{ "order_date" | translate }}
                    </span>
  
                    <p-calendar
                        [defaultDate]="defaultDate"
                        name="date"
                        [placeholder]="'select_date' | translate"
                        selectionMode="single"
                        dateFormat="mm/dd/yy"
                        yearRange="2000:2030"
                        [(ngModel)]="partOrder.orderDate"
                        [ngModelOptions]="{ standalone: true }"
                        [yearNavigator]="true"
                    appendTo="body"></p-calendar>
                </div>
  
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" translate>
                        {{ "order_status" | translate }}
                    </span>
  
                    <p-dropdown [placeholder]="'select_status' | translate" [options]="allStatus" optionLabel="label" optionValue="value" name="status" [(ngModel)]="partOrder.orderStatus" [ngModelOptions]="{ standalone: true }">
                        <ng-template let-option pTemplate="selectedItem">
                            {{ option.label | translate }}
                        </ng-template>
  
                        <ng-template let-status pTemplate="item">
                            <span>
                                {{ status.label | translate }}
                            </span>
                        </ng-template>
                    </p-dropdown>
                </div>
            </div>
  
            <div class="custella-form-container-footer px-0">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 mx-2" (click)="cancel()">{{'cancel' | translate}}</button>
  
                    <button pButton type="button" (click)="onSubmit()">
                        {{ "save" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
  </form>
  