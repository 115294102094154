import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TabView } from "primeng/tabview";
import { DeleteRecordService } from "../../../_services/delete-record.service";
import { ActivatedRoute, Router } from "@angular/router";
import { VehicleService } from "../../../_services/vehicle.service";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { AppEvents, PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { PermissionService } from "src/app/auth/permission.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-view-vehicle",
  templateUrl: "./view-vehicle.component.html",
  styleUrls: ["./view-vehicle.component.scss"],
})
export class ViewVehicleComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  activeIndex: any;

  public toggleSection = {
    details: false,
    history: false,
    activity: false,
    fuel: false,
    trips: false,
  };

  public headerValue = "";
  public toggleEditVisibility = false;

  public items: any[] = [];

  private id: any;
  public vehicleData: any;

  update!: boolean;
  remove!: boolean;

  @ViewChild(TabView, { static: false }) tabView!: TabView;

  constructor(
    private deleteRecordService: DeleteRecordService,
    private router: Router,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private breadcrumbs: BreadcrumbService,
    private util: UtilServiceService,
    private perm: PermissionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.update = this.perm.capable("vehicles", "update");
    this.remove = this.perm.capable("vehicles", "remove");

    this.push(
      this.util.getData().subscribe((data: any) => {
        if (data) {
          if (
            data.action &&
            data.action === AppEvents.REFRESH_VEHICLE_DETAILS
          ) {
            this.getData();
          }
        }
      })
    );

    this.id = this.route.snapshot.queryParamMap.get("id");

    this.items = [
      {
        label: "Delete",
        id: 'btn_VehDelete',
        command: (e: any) => {
          this.menuAction("delete");
        },
        visible: this.remove,
      },
    ];

    this.activeIndex =
      localStorage.getItem("activeIndex") == undefined
        ? 0
        : Number(localStorage.getItem("activeIndex"));

    setTimeout(() => {
      this.loadData(this.activeIndex, this.tabView);
    }, 500);

    this.getData();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    this.breadcrumbs.setItems([]);
    super.ngOnDestroy();
  }

  /******************************************** Pre Set Tab ******************************************/
  private getData() {
    this.push(
      this.vehicleService.getVehicleById(this.id).subscribe((data) => {
        this.vehicleData = data;

        this.setBreadcrumbs();
      })
    );
  }

  private setBreadcrumbs() {
    this.breadcrumbs.setItems([
      {
        label: "Vehicles",
        routerLink: ["/dashboard/vehicles"],
        command: () => {
          this.util.resetTabView();
        },
        meta: {navigation: {root: true}}
      },
      { label: this.vehicleData.vehicle.name, meta: {navigation: {include: true}} },
    ]);
  }

  /******************************************** Pre Set Tab ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.setTabHeader(headerValue);
    } else {
      const headerValue = this.tabView.tabs[0].header;
      this.setTabHeader(headerValue);
    }
  }

  /******************************************** Handle Tab Change ******************************************/
  public handleChange(e: any, tabView: TabView) {
    localStorage.setItem("activeIndex", e.index);
    this.activeIndex = e.index;
    const headerValue = tabView.tabs[e.index].header;

    this.setTabHeader(headerValue);
  }

  /******************************************** Display Tab ******************************************/
  private setTabHeader(headerValue: any) {
    if (headerValue == "Details") this.toggleSection.details = true;
    if (headerValue == "History") this.toggleSection.history = true;
    if (headerValue == "Activity") this.toggleSection.activity = true;
    if (headerValue == "Fuel") this.toggleSection.fuel = true;
    if (headerValue == "Trips") this.toggleSection.trips = true;

    this.handleEditVisibility();
  }

  /******************************************** handle edit visibility ******************************************/
  private handleEditVisibility() {
    if (this.activeIndex == 0) {
      this.toggleEditVisibility = true;
    } else {
      this.toggleEditVisibility = false;
    }
  }

  /******************************************** Three Dot Actions ******************************************/
  public menuAction(action: string) {
    if (action === "delete") {
      const data = {
        deleteType: "Vehicle",
        id: this.id,
        message: `Are you sure you want to delete ${this.vehicleData.vehicle.vehicleNumber} - ${this.vehicleData.vehicle.name}?`,
      };

      this.push(
        this.deleteRecordService.getItems(data).subscribe((data) => {
          if (data.status === 200) {
            this.router.navigate(["dashboard/vehicles"]);
          }
        })
      );
    }
  }

  /******************************************** Edit ******************************************/
  public edit() {
    this.util.setPersistantItem(
      PERSISTANT_ITEM.VEHICLE,
      this.vehicleData.vehicle
    );

    this.router.navigate(["dashboard/vehicles/update"]);
  }
   push(obs:any) {
    super.push(obs);
  }
}
