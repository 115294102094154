import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ChecklistService } from "src/app/_services/checklist.service";

@Component({
  selector: "app-custella-machines-specialist",
  templateUrl: "./custella-machines-specialist.component.html",
  styleUrls: ["./custella-machines-specialist.component.scss"],
})
export class CustellaMachinesSpecialistComponent implements OnInit {
  @Output() onMSSelect = new EventEmitter();
  @Output() closeView = new EventEmitter();
  @Input() siteId: any = null;

  searchValue: any;

  products: any;
  currentRecordNumber: any;
  //  noCustomers: boolean = false;
  //  noCustomersIcon: boolean = false;
  filter: any = { status: "", userTypes: "" };
  dateFormat: any;
  soitemsHandlerSubscription!: Subscription;
  filterData: boolean = true;
  noData: boolean | null = null;
  constructor(
    private router: Router,
    private checklistService: ChecklistService
  ) {
    //   this.soitemsHandlerSubscription = salesOrdersService.soitemsHandler.subscribe((response: any) => {
    //     console.log(response)
    //     this.selectSO( response);
    // });
  }

  ngOnInit() {
    this.dateFormat = localStorage.getItem("date_format");
  }

  selectMS(val: any) {
    this.onMSSelect.emit(val);
  }

  closeMSView() {
    this.closeView.emit(false);
  }
  ngOnDestroy() {
    if (this.soitemsHandlerSubscription) {
      this.soitemsHandlerSubscription.unsubscribe();
    }
  }

  onNoData(hasData: boolean) {
    this.noData = hasData;
  }
}
