<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <div>
            <span class="text-16 font-medium" translate>{{title}}</span>
            <span class="text-12 font-medium color-var-500 d-block" translate>{{'drag_orders_from_left'}}</span>
        </div>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body tix pt-2">
    <div class="d-flex align-items-start justify-content-between mt-1">
        <!-- ---------------------------------------Left side-------------------------------------------------- -->
        <div class="w-50" style="height: 432px; overflow-y: hidden">
            <div class="custella-form-container rounded-2 ms-0 me-2" style="box-shadow: none; border: 1px solid #dee2e6; height: 432px; overflow-y: hidden">
                <!-- Container Content -->
                <div class="custella-form-container-title text-14 font-medium rounded-top" translate>
                    {{"vehicle_one"}}
                </div>
                <div class="custella-form-container-content mb-2">
                    <div class="custella-content-row-input col-12">
                        <p-dropdown placeholder="Vehicles" optionLabel="vehicleNumber" [filter]="false"
                            filterBy="vehicleNumber" optionValue="id" [(ngModel)]="search1"
                            (onChange)="filterResults1()" [ngModelOptions]="{standalone: true}" [options]="vehiclesData"
                            [showClear]="true">
                        </p-dropdown>
                    </div>
                    <div class="custella-content-row mb-3" *ngIf="!dragableDataList || dragableDataList?.length==0">
                        <div class="custella-content-row-input col-12">
                            <div class="d-flex align-items-center justify-content-center mt-4">
                                <img src="assets/images/truck.png" alt="Choose vehicle to add order to">
                            </div>
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="text-14 font-semiBold d-block m-3"
                                    translate>{{'add_new_order_select'}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="">
                    <div class="custella-form-container mx-0" style="overflow-y: auto; height: 325px; box-shadow: none;" *ngFor="let list of dragableDataList">
                        <div class="custella-form-container-title-two-grey d-flex align-items-center py-1">
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                                <span class="text-12 font-black font-semiBold d-block"
                                    translate>{{list?.vehicleNumber}}</span>
                            </div>
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                                <span *ngIf="list?.capacity < 50"
                                    class="text-12 font-black font-semiBold d-block lowCapacity">{{list?.capacity}}%</span>
                                <span *ngIf="list?.capacity > 50 && list?.capacity < 80"
                                    class="text-12 font-black font-semiBold d-block mediumCapacity">{{list?.capacity}}%</span>
                                <span *ngIf="list?.capacity > 80"
                                    class="text-12 font-black font-semiBold d-block highCapacity">{{list?.capacity}}%</span>
                            </div>
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                                <span class="text-12 font-black font-semiBold d-block" translate>{{list?.duration |
                                    minutesSeconds}}</span>
                            </div>
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                                <span class="text-12 font-black font-semiBold d-block" translate>{{list?.distance |
                                    distanceToKm2}}</span>
                            </div>
                        </div>
                        <div class="">
                            <div class="custella-form-container-content pb-2 mb-0" style="overflow-y: auto;height: 250px;box-shadow: none;">
                                <div class="collapse show" id="wfcBox">
                                    <div *ngFor="let item of list?.relatedVehicleEvents;index as i" [dndDraggable]="item" dndEffectAllowed="move"
                                        (dndMoved)="onDragged(i,item, list.relatedVehicleEvents, 'move')"
                                        class="border rounded-1 cursor-pointer flex-between mb-2 py-2 ps-1 pe-4">
                                        <div class="dndPlaceholderLeft" dndPlaceholderRef>
                                            <img src="assets\svg\table\dots-vertical.svg" alt="Drag Icon">
                                        </div>
                                        <div *ngIf="item" class="col-12 ps-1 p-0">
                                            <div *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')"
                                                class="d-flex align-items-center"
                                                [ngClass]="{'fade-opacity-04' : (item.type=='pickup'&&item.status=='PICKED_UP'|| item.type=='pickup'&&item.status=='DELIVERED' || item.type=='delivery'&&item.status=='DELIVERED')}">
                                                <div class="d-flex align-items-center col-4">
                                                    <img *ngIf="item?.type=='pickup'" src="assets/svg/table/ph_arrow_green.svg"
                                                        alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                                    <img *ngIf="item?.type=='delivery'" src="assets/svg/table/ph_arrow.svg"
                                                        alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                                    <img *ngIf="item?.type=='pickup_and_delivery'" src="assets/svg/table/dropoff-pickup.svg"
                                                        alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                                    <span class="text-10 ph-peek">{{item.orderName}}</span>
                                                </div>
                                                <div class="d-flex align-items-center col-4 ps-1"
                                                    *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                    <img src="assets\svg\table\clock.svg" alt="Available Vehicle" class="w-19px me-1">
                                                    <span class="text-10 color-var-500">{{item.startTime}} -
                                                        {{item.endTime}}</span>
                                                </div>
                                                <div class="d-flex align-items-center col-4 ps-2"
                                                    *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                    <span class="text-10 color-var-500">{{item.note}}</span>
                                                </div>
                                            </div>
                                        </div>
                            
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>

            </div>
        </div>
        <!-- ---------------------------------------Right side-------------------------------------------------- -->
        <div class="w-50" style="height: 432px; overflow-y: hidden" >
            <div class="custella-form-container rounded-2 ms-2 me-0" style="box-shadow: none; border: 1px solid #dee2e6; height: 432px; overflow-y: hidden">
                <div class="custella-form-container-title text-14 font-medium rounded-top" translate>
                    {{"vehicle_two"}}
                </div>
                <div class="custella-form-container-content mb-2">
                    <div class="custella-content-row-input col-12">
                        <p-dropdown placeholder="Vehicles" optionLabel="vehicleNumber" [filter]="false"
                            filterBy="vehicleNumber" optionValue="id" [(ngModel)]="search2"
                            (onChange)="filterResults2()" [ngModelOptions]="{standalone: true}" [options]="vehiclesData"
                            [showClear]="true">
                        </p-dropdown>
                    </div>
                    <div class="custella-content-row mb-3" *ngIf="!dragableDataList2 || dragableDataList2?.length==0">
                        <div class="custella-content-row-input col-12">
                            <div class="d-flex align-items-center justify-content-center mt-4">
                                <img src="assets/images/truck.png" alt="Choose vehicle to add order to">
                            </div>
                            <div class="d-flex align-items-center justify-content-center">
                                <span class="text-14 font-semiBold d-block m-3"
                                    translate>{{'choose_vehicle_to_add_order'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="">
                    <div *ngFor="let list2 of dragableDataList2" style="overflow-y: auto; height: 325px; box-shadow: none;" class="container2">
                        <div class="custella-form-container-title-two-grey d-flex align-items-center py-1">
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                                <span class="text-12 font-black font-semiBold d-block"
                                    translate>{{list2?.vehicleName}}</span>
                            </div>
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                                <span *ngIf="list2?.capacity < 50"
                                    class="text-12 font-black font-semiBold d-block lowCapacity">{{list2?.capacity}}%</span>
                                <span *ngIf="list2?.capacity > 50 && list2?.capacity < 80"
                                    class="text-12 font-black font-semiBold d-block mediumCapacity">{{list2?.capacity}}%</span>
                                <span *ngIf="list2?.capacity > 80"
                                    class="text-12 font-black font-semiBold d-block highCapacity">{{list2?.capacity}}%</span>
                            </div>
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                                <span class="text-12 font-black font-semiBold d-block" translate>{{list2?.duration |
                                    minutesSeconds}}</span>
                            </div>
                            <div class="col">
                                <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                                <span class="text-12 font-black font-semiBold d-block" translate>{{list2?.distance |
                                    distanceToKm2}}</span>
                            </div>
                        </div>
                        <div class="">
                            <div class="custella-form-container-content pb-4 mb-0" style="overflow-y: auto;height: 250px;box-shadow: none;">
                                <div dndDropzone [dndHorizontal]="true" dndEffectAllowed="move"
                                    (dndDrop)="onDrop($event, list2.relatedVehicleEvents)" class="collapse show"
                                    id="wfcBox">
                                    <div class="dndPlaceholderRight border" dndPlaceholderRef></div>
                                    <div *ngFor="let item of list2?.relatedVehicleEvents;index as i"
                                        class="border rounded-1 cursor-pointer flex-between mb-2 py-2 ps-1 pe-3">
                                        <div *ngIf="item" class="col-12 ps-1 p-0">
                                            <div *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')"
                                                class="d-flex align-items-center"
                                                [ngClass]="{'fade-opacity-04' : (item.type=='pickup'&&item.status=='PICKED_UP'|| item.type=='pickup'&&item.status=='DELIVERED' || item.type=='delivery'&&item.status=='DELIVERED')}">
                                                <div class="d-flex align-items-center col-4 ps-1">
                                                    <img *ngIf="item?.type=='pickup'"
                                                        src="assets/svg/table/ph_arrow_green.svg"
                                                        alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                                    <img *ngIf="item?.type=='delivery'" src="assets/svg/table/ph_arrow.svg"
                                                        alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                                    <img *ngIf="item?.type=='pickup_and_delivery'"
                                                        src="assets/svg/table/dropoff-pickup.svg"
                                                        alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                                    <span class="text-10 ph-peek">{{item.orderName}}</span>
                                                </div>
                                                <div class="d-flex align-items-center col-4 ps-1"
                                                    *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                    <img src="assets\svg\table\clock.svg" alt="Available Vehicle"
                                                        class="w-19px me-1">
                                                    <span class="text-10 color-var-500">{{item.startTime}} -
                                                        {{item.endTime}}</span>
                                                </div>
                                                <div class="d-flex align-items-center col-4 ps-2"
                                                    *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                    <span class="text-10 color-var-500">{{item.note}}</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex align-items-center justify-content-end">
    <button class="custella-btn-white mx-1 text-12" (click)="openModal('modifyPlan',data)" translate>{{'back'}}</button>

</div>