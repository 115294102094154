<div class="integration-center">
    <div class="flex-align-center" style="gap: 5rem;">
        <div>
            <img *ngIf="page=='google'" src="assets/svg/google-calendar.svg" class="w-70px" alt="">
            <img *ngIf="page=='microsoft'" src="assets/svg/office365.svg" class="w-70px" alt="">
            <img *ngIf="page=='calendly'" src="assets/svg/calendly.svg" class="w-70px" alt="">
        </div>
        <div class="text-center">
            <h5>Connecting</h5>
            <p>This window will close in {{time}} seconds...</p>
        </div>
        <div>
            <img src="../../../../assets/svg/table/Fieldex_Bg.svg" class="w-70px" alt="">
        </div>
    </div>
</div>
