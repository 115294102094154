<div class="">
    <div class="custella-modal d-flex flex-column">
        <div class="custella-modal-header">
            <div class="custella-modal-header-text" id="title_ShiftCancel"> <span> {{ "shift_cancellation" | translate }} </span> </div>
            <i class="pi pi-times pt-1" id="btn_ShiftCancelClose" [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>

        <div class="custella-modal-body">
            <div class="custella-form-container" style="margin: 0px; box-shadow: none; background: none;">
                <div class="custella-form-container-content-border-bottom text-12 font-medium px-3">
                    <div class="custella-content-row pe-0">
                        <div class="custella-content-row-input w-100 me-0">
                            <span class="custella-content-row-input-title" id="label_ShiftReasonCancel">{{ "reason_for_cancellation" | translate }}</span>
                            <span class="custella-content-row-input-title-three" id="value_ShiftReasonCancel" *ngIf="data.type!='Shift'">{{data.rejectReason}}</span>
                            <textarea *ngIf="data.type==='Shift'" id="input_ShiftReasonCancelField" pInputTextarea [(ngModel)]="formData.cancellationReason" name="cancellationReason" rows="4" autocomplete="off" [maxlength]="320000"></textarea>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content-border-bottom text-12 font-medium px-3">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input d-flex w-100">
                            <div class="col-6">
                                <span class="custella-content-row-input-title" id="label_ShiftDate">{{ "date" | translate }}</span>
                                <span class="custella-content-row-input-title-three" id="value_ShiftDate" *ngIf="data?.date; else otherDate;">{{data?.date | momentDate: dateFormat}}</span>
                                <ng-template #otherDate>
                                    <span class="custella-content-row-input-title-three" id="value_ShiftOtherDate" *ngIf="data?.createdDateTime; else otherOtherDate;">{{data?.createdDateTime | momentDate: dateFormat}}</span>
                                </ng-template>
                                <ng-template #otherOtherDate>
                                    <span class="custella-content-row-input-title-three" id="value_ShiftOtherDate2" *ngIf="data?.startDate;">{{data?.startDate | momentDate: dateFormat}}</span>
                                </ng-template>
                            </div>
                            <div class="col-6">
                                <span class="custella-content-row-input-title" id="label_ShiftTime">{{ "shift_time" | translate }}</span>
                                <span class="custella-content-row-input-title-three">
                                    <ng-container *ngIf="data?.startTime">
                                        <span id="value_ShiftStartTime"> {{data?.startTime | momentDate :'hh:mm a' : timezone}} </span>
                                         - 
                                         <span id="value_ShiftEndTime"> {{data?.endTime | momentDate :'hh:mm a' : timezone}} </span>
                                    </ng-container>
                                    <ng-container *ngIf="data?.startDateTime">
                                        <span  id="value_ShiftDateStartTime"> {{data?.startDateTime | momentDate :'hh:mm a' : timezone}} </span>
                                         - 
                                         <span id="value_ShiftDateEndTime"> {{data?.endDateTime | momentDate :'hh:mm a' : timezone}} </span>
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content text-12 font-medium mb-0 mt-3 px-3">
                    <div class="custella-content-row my-0">
                        <div class="custella-content-row-input d-flex w-100">
                            <div class="col-6">
                                <span class="custella-content-row-input-title" id="label_ShiftAssignBy">{{ "assigned_by" | translate }}</span>
                                <span class="custella-content-row-input-title-three" id="value_ShiftAssignBy">{{data.assignedBy?.fullName}}</span>
                            </div>
                            <div class="col-6">
                                <span class="custella-content-row-input-title" id="label_ShiftAssignTo">{{ "assigned_to" | translate }}</span>
                                <span class="custella-content-row-input-title-three" id="value_ShiftAssignTo">{{data.user.fullName}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="custella-modal-footer d-flex align-items-center justify-content-end">
            <button pButton type="button" *ngIf="data.type==='Shift'" id="btn_ShiftSubmit" (click)="onSubmit()">{{'send' | translate}}</button>
            <button pButton type="button" *ngIf="data.type!='Shift' && this.data.status === 'REQUEST_FOR_CANCELATION'" (click)="rejectShift('ACCEPT_CANCELLATION')" class="custella-btn p-button-success text-12 mx-1" id="btn_ShiftApprove">{{'approve' | translate}}</button>
            <button pButton type="button" *ngIf="data.type!='Shift' && this.data.status === 'REQUEST_FOR_CANCELATION'" (click)="rejectShift('REJECT_CANCELLATION')" class="custella-btn p-button-danger text-12 ms-1" id="btn_ShiftReject">{{'reject' | translate}}</button>
        </div>
    </div>
</div>
