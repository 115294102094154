import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/_services/admin/users.service';
import { AssetService } from 'src/app/_services/asset.service';
import { CalendlyIntService } from 'src/app/_services/calendly-int.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AppComponent } from 'src/app/app.component';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-calendly-link',
  templateUrl: './calendly-link.component.html'
})
export class CalendlyLinkComponent implements OnInit {
  calendlyLinkForm!: UntypedFormGroup;
  submitTouched: boolean = false;
  products: any[] = [];
  contacts: any[] = [];
  customerSurveyConfigs: any[] = [];
  calendlyLinkData: any = {
    jobOrder: { id: null },
    fromUser: { id: null },
    orderOwner: { id: null, fullName: null, email: null },
    fromEmails: null,
    fromType: "JOB_ORDER_OWNER",
    toType: "ORDER_CONTACT",
    toEmails: null,
    updateJoDateTime: true,
    generateTask: false
  }
  mode: any;
  data: any;
  sendTo: any = 'ORDER_CONTACT'
  btnTextCopy: string = 'copy_link';
  /** SO Variable */


  customerSurveyDetails: any
  buttonLabel: any = 'Generate Link'
  tooltipText!: string;
  users: any[] = [];
  userId: any;
  isSend: boolean = false;
  calendlyLinkDataOld: any = {};
  toContact: { id: null } | any = { id: null };

  isSubmited = false;
  isContactsDisabled = true;
  @ViewChild("toEmails") el: ElementRef | any;
  @ViewChild("toEmails2") el2: ElementRef | any;

  constructor(public modalRef: BsModalRef,
    private calendlyIntService: CalendlyIntService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private errorUtil: ErrorUtil,
    private auth: AuthenticationService,
    private userService: UsersService,
    private assetService: AssetService
  ) {
    this.calendlyLinkForm = new UntypedFormGroup({
      'fromUser': new UntypedFormControl(null),
      'toEmails': new UntypedFormControl(null),
    });

  }

  ngOnInit(): void {
    // console.log(this.data)
    this.init();
    // this.calendlyLinkData.calendlyDetails.id =  this.customerSurveyDetails ? this.customerSurveyDetails.customerSurveyConfig.id :null

  }

  init() {
    if (this.data.calendlyDetails) this.calendlyLinkData = { ...this.calendlyLinkData, ...this.data.calendlyDetails };
    this.calendlyLinkData.jobOrder.id = this.data.jobOrder.id;
    this.calendlyLinkData.orderOwner = this.data.jobOrder.orderOwner;
    if (this.calendlyLinkData.fromType != 'CURRENT_USER_DOWNLINE' || !this.calendlyLinkData.fromEmails) {
      this.calendlyLinkData.fromUser.id = this.data.jobOrder.orderOwner.id;
      this.calendlyLinkData.fromEmails = this.data.jobOrder.orderOwner.email;
      this.calendlyLinkData.fromType = 'JOB_ORDER_OWNER';
    }

    if (this.calendlyLinkData.id && this.calendlyLinkData.toType == 'CUSTOM' && this.calendlyLinkData.toEmails) {
      this.sendTo = this.calendlyLinkData.toType;
    } else if (this.calendlyLinkData.id && this.calendlyLinkData.toType == 'CUSTOMER_CONTACT' && this.calendlyLinkData.toEmails) {
      this.sendTo = this.calendlyLinkData.toType;
      if (this.calendlyLinkData.toContact) this.toContact = { id: this.calendlyLinkData.toContact.id };
    } else {
      let isJoContact = this.data.jobOrder.contactPersonEmail != '' && this.data.jobOrder.contactPersonEmail;
      this.calendlyLinkData.toEmails = isJoContact ? this.data.jobOrder.contactPersonEmail : this.data.jobOrder.customer?.email;
      // if(!isJoContact && this.data.jobOrder.customer?.id) this.toContact = { id: this.data.jobOrder.customer?.id };
      this.sendTo = isJoContact || (!this.data.jobOrder.customer?.email || this.data.jobOrder.customer?.email == '') ? 'ORDER_CONTACT' : 'CUSTOMER_CONTACT';
      this.calendlyLinkData.toType = this.sendTo;
      if (this.calendlyLinkData.toType == 'CUSTOMER_CONTACT') this.toContact = { id: this.data.jobOrder.customer?.id };
    }
    //this.customerSurveyDetails = this.data.customerSurveyDetails
    this.buttonLabel = this.customerSurveyDetails ? 'Send' : 'Generate Link';
    this.getUserList();
    this.getContactList();
    this.isSend = this.calendlyLinkData.id != null;
    this.calendlyLinkDataOld = JSON.parse(JSON.stringify(this.calendlyLinkData));
  }

  sendEmailTo(sendTo: any) {
    this.calendlyLinkData.toType = sendTo
    this.sendTo = sendTo
    if (sendTo == 'ORDER_CONTACT') {
      this.calendlyLinkData.toEmails = this.data.jobOrder.contactPersonEmail;
      this.toContact = null;
    } else if (sendTo == 'CUSTOMER_CONTACT') {
      this.calendlyLinkData.toEmails = this.data.jobOrder.customer?.email;
      if (this.calendlyLinkData.toEmails && this.calendlyLinkData.toEmails != '') {
        let c = this.contacts.filter(c => c.email).filter(c => c.email == this.calendlyLinkData.toEmails);
        if (c.length > 0) { this.toContact = { id: c[0].id }; this.calendlyLinkData.toEmails = c[0].email; }
        else if (this.contacts.length > 0) {
          this.toContact = { id: this.contacts[0].id }; this.calendlyLinkData.toEmails = this.contacts[0].email;
        }
      } else {
        this.calendlyLinkForm.controls["toEmails"].setValue(this.contacts.length > 0 ? this.contacts[0]?.email : null);
        this.toContact = this.contacts.length > 0 ? { id: this.contacts[0].id } : null;
      }
    } else {
      this.calendlyLinkData.toEmails = null;//this.calendlyLinkDataOld.toEmails;
      this.toContact = null;
    }
    this.calendlyLinkForm.controls['toEmails'].clearValidators();
    this.calendlyLinkForm.controls['toEmails'].updateValueAndValidity();
  }

  fromTypeTo(fromType: any) {
    this.calendlyLinkData.fromType = fromType;
    this.calendlyLinkForm.controls["fromUser"].clearValidators();
    this.calendlyLinkForm.controls["fromUser"].updateValueAndValidity();
    // if(this.calendlyLinkData.fromType =='CURRENT_USER_DOWNLINE'){

    // }
    this.detectChanges();
  }
  /******************************************** Get User List ******************************************/
  getUserList() {
    this.userService.getAllDownlineCalendlyUsersForLeavesGroup(this.auth.getUserId())
      .subscribe((res: any) => {
        this.users = res.data
        const usr = this.users.map((user: any) => {
          return {
            id: user.id,
            name: user.fullName,
            label: user.fullName,
            email: user.email,
          };
        })
        this.users = usr;
        let u = this.users.filter(c => c.email).filter(c => c.email == this.calendlyLinkData.fromEmails);
        if (u.length > 0) { this.calendlyLinkData.fromUser = { id: u[0].id }; this.calendlyLinkData.fromEmails = u[0].email; }
        else if (this.users.length > 0) {
          this.calendlyLinkData.fromUser = { id: this.users[0].id }; this.calendlyLinkData.fromEmails = this.users[0].email;
        }
        //this.users.id = res.data
        console.log(this.users)
      });
  }

  /******************************************** Get Contacts List ******************************************/
  getContactList() {
    if (this.data.jobOrder?.customer?.id)
      this.assetService.getCustodians(this.data.jobOrder?.customer?.id)
        .subscribe((res: any) => {
          if (res.ok) {
            this.contacts = res.body;
            const usr = this.contacts.map((user: any) => {
              return {
                id: user.id,
                name: user.fullName,
                label: user.fullName,
                email: user.email,
              };
            }).filter(c => c.email != null && c.email != '');
            this.contacts = usr;
            this.isContactsDisabled = this.contacts.length < 2;
            if (this.calendlyLinkData.toType == 'CUSTOMER_CONTACT') {
              let c = this.contacts.filter(c => c.email).filter(c => c.email == this.calendlyLinkData.toEmails);
              if (c.length > 0) { this.toContact = { id: c[0].id }; this.calendlyLinkData.toEmails = c[0].email; }
              else if (this.contacts.length > 0) {
                this.toContact = { id: this.contacts[0].id }; this.calendlyLinkData.toEmails = this.contacts[0].email;
              }
              // this.contacts.forEach(c => {
              //   if (c.email == this.calendlyLinkData.toEmails) this.toContact = { id: c.id }
              // });
            }
          }
        });
  }
  onchangeUser(ev: any) {
    // console.log(this.userId)
    if (ev.value) {
      let u = this.users.filter(c => c.email == ev.value);
      if (u.length > 0) { this.calendlyLinkData.fromUser = { id: u[0].id }; this.calendlyLinkData.fromEmails = u[0].email; }
      else if (this.users.length > 0) {
        this.calendlyLinkData.fromUser = { id: this.users[0].id }; this.calendlyLinkData.fromEmails = this.users[0].email;
      }
      // this.users.forEach((c: any) => {
      //   if (c.id == ev.value) { this.calendlyLinkData.fromUser = { id: c.id }; this.calendlyLinkData.fromEmails = c.email; }
      // });
    } else this.calendlyLinkData.fromUser = null;

    this.detectChanges();
    //debugger
    //   if(this.leave.user.id){
    //   if(this.addonFeature.addonMS === 'MACHINE_SPECIALIST'){
    //     this.push(
    //       this.userService.getAllDownlineUsersSiteById(this.leave.user.id)
    //         .subscribe((res: any) => {
    //         if(res){
    //             this.siteName = res.defaultSite.siteName
    //             this.leave.site={id: res.defaultSite.id}
    //         }else{
    //           this.siteName = null
    //           this.leave.site={id: null}
    //         }

    //           console.log(res)
    //         })
    //     );
    //   }
    // }else{
    //   this.siteName = null
    //   this.leave.site={id: null}
    // }

    this.detectChanges();
  }

  onchangeContacts(ev: any) {
    // console.log(this.calendlyLinkData.toEmails)
    if (ev.value) {
      let c = this.contacts.filter(c => c.email == ev.value);
      if (c.length > 0) { this.toContact = { id: c[0].id }; this.calendlyLinkData.toEmails = c[0].email; }
      else if (this.contacts.length > 0) {
        this.toContact = { id: this.contacts[0].id }; this.calendlyLinkData.toEmails = this.contacts[0].email;
      }
      // this.contacts.forEach(c => {
      //   if (c.email == ev.value) { this.toContact = { id: c.id }; this.calendlyLinkData.toEmails = ev.value; }
      // });
    } else {
      this.toContact = null;
      this.calendlyLinkData.toEmails = null;
    }

    this.detectChanges();
  }

  copyLink() {
    this.btnTextCopy = "Link Copied";
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.calendlyLinkData?.inviteLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);

    setTimeout(() => {
      this.btnTextCopy = 'copy_link';
    }, 3000);
  }

  detectChanges() {
    this.isSend = !(this.calendlyLinkData.jobOrder.id != this.calendlyLinkDataOld.jobOrder.id ||
      this.calendlyLinkData.fromUser.id != this.calendlyLinkDataOld.fromUser.id ||
      this.calendlyLinkData.orderOwner.id != this.calendlyLinkDataOld.orderOwner.id ||
      this.calendlyLinkData.fromEmails != this.calendlyLinkDataOld.fromEmails ||
      this.calendlyLinkData.fromType != this.calendlyLinkDataOld.fromType ||
      this.calendlyLinkData.updateJoDateTime != this.calendlyLinkDataOld.updateJoDateTime ||
      this.calendlyLinkData.generateTask != this.calendlyLinkDataOld.generateTask || this.calendlyLinkData.id == null);
  }

  generateLink() {
    this.submitTouched = true;
    this.isSubmited = true;
    this.calendlyLinkForm.controls["toEmails"].clearValidators();
    this.calendlyLinkForm.controls["toEmails"].updateValueAndValidity();
    this.calendlyLinkForm.controls['fromUser'].setValidators([Validators.required]);
    this.calendlyLinkForm.controls['fromUser'].updateValueAndValidity();
    if (!this.calendlyLinkForm.valid) {
      for (var i in this.calendlyLinkForm.controls) {
        this.calendlyLinkForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return;
    }
    this.isSubmited = false;
    if (this.calendlyLinkData.fromType == 'JOB_ORDER_OWNER' && this.calendlyLinkData.orderOwner) {
      this.calendlyLinkData.fromEmails = this.calendlyLinkData.orderOwner.email;
      this.calendlyLinkData.fromUser = { id: this.calendlyLinkData.orderOwner.id };
    } else if (this.userId) {
      this.users.forEach((u: any) => {
        if (u.id == this.userId) { this.calendlyLinkData.fromUser = { id: u.id }; this.calendlyLinkData.fromEmails = u.email }
      });
    }
    let { fromEmails, updateJoDateTime, generateTask, fromType, inviteLink, jobOrder, fromUser, toContact, toEmails, toType } = this.calendlyLinkData;
    let payLoad = { fromEmails, updateJoDateTime, generateTask, fromType, inviteLink, jobOrder, fromUser };
    // return false;
    this.spinner.show();
    this.calendlyIntService.generateCalendlyLink(payLoad).subscribe((res: any) => {
      this.spinner.hide();
      this.submitTouched = false;
      this.isSubmited = false;
      if (res.status == 200 || res.status == 201) {
        this.calendlyLinkData = { ...this.calendlyLinkData, ...res.body, toContact, toEmails, toType };
        this.reset();
        this.errorUtil.setErrorMessage(200, 'Calendly Link created succesfully', null, 'success', 1000);
        // this.modalRef.hide()
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
        this.calendlyLinkData = { ...this.calendlyLinkData, toContact, toEmails, toType };
      }
    }, err => {
      this.spinner.hide();
      this.submitTouched = false;
      this.isSubmited = false;
      this.errorUtil.setErrorMessage(err.status, null, err.error.title, 'error', 3000);
      this.calendlyLinkData = { ...this.calendlyLinkData, toContact, toEmails, toType };
    });
  }

  reset() {

    if (this.calendlyLinkData.id && this.calendlyLinkData.toType == 'CUSTOM' && this.calendlyLinkData.toEmails) {
      this.sendTo = this.calendlyLinkData.toType;
    } else if (this.calendlyLinkData.id && this.calendlyLinkData.toType == 'CUSTOMER_CONTACT' && this.calendlyLinkData.toEmails) {
      this.sendTo = this.calendlyLinkData.toType;
      if (this.calendlyLinkData.toContact) this.toContact = { id: this.calendlyLinkData.toContact.id };
    } else {
      let isJoContact = this.data.jobOrder.contactPersonEmail != '' && this.data.jobOrder.contactPersonEmail;
      this.calendlyLinkData.toEmails = isJoContact ? this.data.jobOrder.contactPersonEmail : this.data.jobOrder.customer?.email;
      // if(!isJoContact && this.data.jobOrder.customer?.id) this.toContact = { id: this.data.jobOrder.customer?.id };
      this.sendTo = isJoContact || (!this.data.jobOrder.customer?.email || this.data.jobOrder.customer?.email == '') ? 'ORDER_CONTACT' : 'CUSTOMER_CONTACT';
      this.calendlyLinkData.toType = this.sendTo;
      if (this.calendlyLinkData.toType == 'CUSTOMER_CONTACT') this.toContact = { id: this.data.jobOrder.customer?.id };
    }
    //this.customerSurveyDetails = this.data.customerSurveyDetails
    this.buttonLabel = this.customerSurveyDetails ? 'Send' : 'Generate Link';
    this.getUserList();
    this.getContactList();
    this.isSend = this.calendlyLinkData.id != null;
    this.calendlyLinkDataOld = JSON.parse(JSON.stringify(this.calendlyLinkData));
  }

  onSubmitForm() {
    this.calendlyLinkForm.controls["fromUser"].clearValidators();
    this.calendlyLinkForm.controls["fromUser"].updateValueAndValidity();
    this.calendlyLinkForm.controls['toEmails'].setValidators([Validators.required]);
    this.calendlyLinkForm.controls['toEmails'].updateValueAndValidity();
    if (this.calendlyLinkData.toType == 'CUSTOM') {
      if (this.calendlyLinkData.toEmails && this.isInValidEmails()) this.calendlyLinkForm.controls['toEmails'].setErrors({ pattern: 'invalid_email_format' });
    } else {
      this.calendlyLinkForm.controls['toEmails'].setValidators([Validators.required, Validators.pattern(AppComponent.emailPattern)]);
      this.calendlyLinkForm.controls['toEmails'].updateValueAndValidity();
    }
    this.submitTouched = true;
    this.isSubmited = true;
    if (!this.calendlyLinkForm.valid) {
      for (var i in this.calendlyLinkForm.controls) {
        this.calendlyLinkForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return;
    }

    this.isSubmited = false;
    this.calendlyLinkData.toContact = this.calendlyLinkData.toType == 'CUSTOMER_CONTACT' && this.toContact?.id ? this.toContact : null;
    let { id, toEmails, toType, toContact } = this.calendlyLinkData;
    let payLoad = { id, toEmails, toType, toContact };
    // return false;
    this.spinner.show();
    this.calendlyIntService.sendCalendlyLink(payLoad).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 200 || res.status == 201) {
        this.errorUtil.setErrorMessage(200, 'Calendly link sent successfully', null, 'success', 1000);
        this.modalRef.hide()
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      }
    }, err => {
      this.spinner.hide();
      this.errorUtil.setErrorMessage(err.status, null, err.error.title, 'error', 3000);
    });
    this.submitTouched = false;
  }

  isInValidEmails() {
    let emails = this.calendlyLinkData.toEmails.split(',');
    // console.log(emails)
    let invalidEmails = [];

    for (let i = 0; i < emails.length; i++) {
      if (!this.validateEmail(emails[i].trim())) {
        invalidEmails.push(emails[i].trim())
      };
    };
    return invalidEmails.length > 0;
  }

  validateEmail(email: string) {
    let re: any = /^[A-Za-z0-9._%+-]+@[a-z0-9.?-]+[\.]+[a-z]{2,4}$/;// /[a-zA-Z0-9_.+-,;]+@(?:(?:[a-zA-Z0-9-]+\.,;)?[a-zA-Z]+\.,;)?(7-11)\.com/;//
    return re.test(email);
  }
}
