import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { JobOrdersService } from 'src/app/_services/job-orders.service';

@Component({
  selector: 'app-custella-jo-template',
  templateUrl: './custella-jo-template.component.html',
  styleUrls: ['./custella-jo-template.component.scss']
})
export class CustellaJoTemplateComponent extends SubscriptionUtil
implements OnInit, OnDestroy
{
@Output() itemSelector = new EventEmitter();
@Output() closeView = new EventEmitter();


gridApi: any;
gridColumnApi: any;
columnDefs: any;
defaultColDef: any;
frameworkComponents: any;
gridParams : any;
startFrom: any;
product: any;
pageSize: any = 25;
parameters: any;
gridOptions: GridOptions;
overlayNoRowsTemplate: string;
productsFamilies: any;
productsType: any;
currentRecordNumber: any;
totalRecord: any;

 searchValue: any=null
  checklistData: any;

constructor(private jobOrdersService : JobOrdersService,
  private errorUtil: ErrorUtil,) {
  super();

  this.gridOptions = {
    cacheBlockSize: 25,
    rowModelType: "infinite",
    sortingOrder: ["desc", "asc"],
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    tooltipShowDelay: 100,
  };
  this.columnDefs =  [
    { headerName: 'Template Name', field: 'templateName', width: 150, headerTooltip: "#",cellClass: function (params: any) { return ['table_default_color'];}},
        { headerName: 'Job Order Name', field: 'jobOrderName', width: 170,headerTooltip: "#" },
        { headerName: 'Type', field: 'site.name', width: 80, headerTooltip: "#",},
        { headerName: 'System', field: 'systemValue.name', width: 100,headerTooltip: "#"},
        { headerName: 'Fault', field: 'fault.name', width: 100,headerTooltip: "#"},
        { headerName: 'Service Zone', field: 'serviceZone.name', width: 100,headerTooltip: "#"},
        { headerName: 'Template Status', field: 'active', width: 100, headerTooltip: "#",
          cellRenderer: function (params: any) {
            if(params.data){
              if(params.data.templateStatus == 'ACTIVE'){
                return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
              }else{
                return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
                }
            }
          }
        },
  ];
  this.defaultColDef = {
    enableRowGroup: true,//ag-Grid-Enterprise
    enablePivot: true,//ag-Grid-Enterprise
    enableValue: true,//ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent
  };
  this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";

  
}

ngOnInit(): void {}

ngOnDestroy(): void {
  super.ngOnDestroy();
}
public viewCell($event: any) {
  const field = $event.colDef.field;

  if (field === "templateName") {
    this.selectItem($event.data);
  }
}
selectItem(val: any) {
  this.itemSelector.emit(val);
}

public closeTable() {
  this.closeView.emit(false);
}
 
push(obs:any) {
  super.push(obs);
}



/************************************************ Search Data ****************************************/
quickSearch() {
  this.onGridReady(this.gridParams);
  
}
/***************************************** Initilize Grid Table ****************************************/
onGridReady(params: any) {
  //console.log(params)
  this.gridParams = params;

  this.gridApi = params.api;
  this.gridApi.sizeColumnsToFit();
  var dataSource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.getServerSideData(params);
    },
  };

  this.gridApi.sizeColumnsToFit();
  this.gridApi.setDatasource(dataSource);
}

/********************************** Call Customer API to Display data *********************************/
getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  // this.spinner.show()
  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);
  console.log('Making a server request: ', serverRequest);

  this.push(this.jobOrdersService.getAllJobOrderTemplates(this.searchValue, serverRequest,null,null,null) .subscribe(
        (data: any) => {
          console.log(data.body);
          this.checklistData = data.body.data;
          this.totalRecord = data.body.total;
          this.setGridData(
            agGridGetRowsParams,
            this.checklistData,
            data.body.total,
            data.body.status
          );
          //this.spinner.hide()
        },
        (err) => {
          //console.log(err,"===>>>")
          this.errorUtil.setErrorMessage(err);
        }
      )
  );
}
/**************************************** Set Data for Grid Table ****************************************/
private setGridData( agGridGetRowsParams: IServerSideGetRowsParams,resultItems: any[],totalCount: number, status: string ) {
  console.log(resultItems, "===");
  
 
    if (this.checklistData.length === 0) {
      this.gridApi.showNoRowsOverlay();
       } else {
       agGridGetRowsParams.successCallback(this.checklistData, totalCount);
    }
  
 
}
/********************************** Request Parameter for api ****************************************/

getRequestParams(agGridRequest: any): ServerRequest {
  if (agGridRequest.sortModel.length <= 0) {
    this.parameters = { colId: "fault.name", sort: "asc" };
    agGridRequest.sortModel.push(this.parameters);
  }

  //console.log(agGridRequest)
  this.currentRecordNumber = agGridRequest.startRow;
  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel,
  };
}


}