<div>
  <div class="flex-between">
      <i class="pi pi-times w-24px dialog-close-icon" id="btn_SalesOrderClose" (click)="closeMSView()"></i>
  </div>
  <div class="position-relative text-start">
      <span class="mb-0 text-14 font-light color-var-500" id="label_SalesOrderSelectFromList">{{'select_ms_from_list'|translate}}</span>
      <div class="d-flex align-items-center mt-2">
          <span class="p-input-icon-left w-100 py-2">
              <i class="pi pi-search"></i>
              <input type="text" pInputText autocomplete="off" id="input_SalesOrderSelectFromListField" [placeholder]="'select_ms_placeholder'|translate" [(ngModel)]="searchValue" />
          </span>
      </div>
      <div *ngIf="noData === true" class="col-12 mt-5 py-4">
          <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
              <img src="assets\svg\empty-state.svg" alt="" />
              <p class="text-6 my-1 font-weight-bold" translate>{{'no_records_found'}}</p>
          </div>
      </div>
      <div [hidden]="noData === true" class="table-space" style="height: 42vh;">
          <app-machines-specialist-table [filterData]="filterData" [siteId]="siteId" [displayAction]="false" [searchValue]="searchValue" (noDataEvent)="selectMS($event)"></app-machines-specialist-table>
      </div>
  </div>
</div>
