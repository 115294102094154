import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { PermissionService } from "src/app/auth/permission.service";
import { VehicleService } from "src/app/_services/vehicle.service";

import * as moment from "moment-timezone";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";

@Component({
  selector: "app-vehicle-fuel",
  templateUrl: "./vehicle-fuel.component.html",
  styleUrls: ["./vehicle-fuel.component.scss"],
})
export class VehicleFuelComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  params: any;

  gridApi: any;
  logGridParams: any;
  columnDefs: any;
  columnDefsRoutes: any;
  columnDefsLogs: any;
  defaultColDef: any;
  frameworkComponents: any;
  searchValue: any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  public gridOptions!: Partial<GridOptions>;
  vehicleFuelHistory: any;
  vehicleId: any;
  rowData: any;
  noFuelHistory: boolean = false;
  totalRecord: any;

  overlayNoRowsTemplate = "";

  private id: any;

  @Input() vehicleDetail: any;

  create: any;

  constructor(
    private router: Router,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private perm: PermissionService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

    this.columnDefs = [
      {
        headerName: "Date",
        field: "createdDate",
        width: 190,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return moment(params.data.createdDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: "Time",
        field: "createdDate",
        width: 173,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return moment(params.data.createdDate).format("hh:mm a");
          }
        },
      },
      {
        headerName: "Amount",
        field: "amount",
        width: 70,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 text-right"];
        },
        valueFormatter: this.currencyFormatter,
      },
      {
        headerName: "Litres",
        field: "fuelVolume",
        width: 60,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.fuelVolume + "L";
          }
        },
      },
      {
        headerName: "Driver",
        field: "driver.user.fullName",
        width: 140,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data && params.data.driver) {
            return params.data.driver.user.fullName;
          }
        },
      },
      {
        headerName: "Action",
        filter: false,
        width: 90,
        headerTooltip: "#",
        sortable: false,
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "center" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.edit.bind(this),
          permissions: {
            update: this.perm.capable("vehicles", "update"),
            remove: this.perm.capable("vehicles", "remove"),
          },
          hidden: {
            update: !this.perm.capable("vehicles", "update"),
            remove: !this.perm.capable("vehicles", "remove"),
          },
        },
      },
    ];
  }

  /******************************************** Init ******************************************/
  private init() {
    this.create = this.perm.capable("vehicles", "create");
    this.id = this.route.snapshot.queryParamMap.get("id");
  }

  /******************************************** Click Cell ******************************************/
  public goToViewFuel(e: any) {
    if (e.colDef.field === "createdDate") {
      this.router.navigate(["dashboard/vehicles/fuel/view/" + e.data.id], {
        queryParams: { id: e.data.id },
      });
    } else if (e.colDef.field === "driver.user.fullName") {
      this.router.navigate(["dashboard/setup/drivers/view"], {
        queryParams: { id: e.data.driver.user.id,edit:false },
      });
    }
  }

  /******************************************** Edit ******************************************/
  public edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.VEHICLE_FUEL, e.rowData);

      this.router.navigate(["dashboard/vehicles/fuel/update"]);
    } else if (e.action === "delete") {
      const item = {
        deleteType: "Vehicle Fuel",
        id: e.rowData.id,
        message: `Are you sure you want to delete this fuel?`,
      };

      this.push(
        this.deleteRecordService.getItems(item).subscribe((data) => {
          if (data.status === 200) {
            this.refresh();
          }
        })
      );
    }
  }

  /******************************************** On Grid Ready ******************************************/
  public onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;

    var dataSource = {
      getRows: (params: any) => {
        this.startFrom = params.startRow + 1;
        if (params.sortModel.length <= 0) {
          this.parameters = { colId: "vehicleFuelHistory.id", sort: "desc" };
          params.sortModel.push(this.parameters);
        }

        this.push(
          this.vehicleService
            .getVehicleFuelHistoryById(this.id, params)
            .subscribe((data: any) => {
              this.gridApi.hideOverlay();

              this.vehicleFuelHistory = data.data;

              this.totalRecord = data.total;

              if (data.status === "NO_DATA") {
                this.noFuelHistory = true;
              } else {
                this.noFuelHistory = false;
                if (this.vehicleFuelHistory.length === 0) {
                  this.gridApi.showNoRowsOverlay();

                  params.successCallback([], 0);
                } else {
                  params.successCallback(this.vehicleFuelHistory, data.total);
                }
              }
            })
        );
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /******************************************** New ******************************************/
  public new() {
    this.util.setPersistantItem(
      PERSISTANT_ITEM.VEHICLE_FUEL,
      this.vehicleDetail
    );

    this.router.navigate(["dashboard/vehicles/fuel/create"]);
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** Currency Formatter ******************************************/
  public currencyFormatter(params: any) {
    var usdFormate = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });

    return usdFormate.format(params.value);
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "createdDate") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (params.column.colId == "driver.user.fullName") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    if (data.field === "createdDate") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/vehicles/fuel/view/" + data.id], {
          queryParams: { id: data.id },
        })
      );
    }

    if (data.field === "driver.user.fullName") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/setup/drivers/view"], {
          queryParams: { id: data.driver.user.id,edit:false },
        })
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }
   push(obs:any) {
    super.push(obs);
  }
}
