import { Component, OnInit } from '@angular/core';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, forkJoin } from 'rxjs';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { ProjectService } from 'src/app/_services/project.service';
import { SiteService } from 'src/app/_services/site.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { TeamService } from 'src/app/_services/team.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';

@Component({
  selector: 'app-add-jo-task-template',
  templateUrl: './add-jo-task-template.component.html',
  styleUrls: ['./add-jo-task-template.component.scss']
})
export class AddJoTaskTemplateComponent implements OnInit {
  filter: any = { teamId: null, siteId: null, projectId: null };

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: GridOptions;
  overlayNoRowsTemplate: string;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;

  refresherPickList!: Subscription
  DataDetails: any[] = [];
  notaskTemplate: boolean = false;
  notaskTemplateIcon: boolean = false;
  taskTemplateData: any;
  teams: any;
  allProjects: any[] = [];
  allSites: any[] = [];
  tmplJobOrderId: any;
  taskTriggers: any = {
    id: null,
    tmplTasks: []
  }
  selectAllChecked: boolean = false;
  formData: any = { tasks: [] };
  rowSelection: any;
  orderGridTotalLength: any;
  submitTouched!: boolean;
  data: any;

  constructor(
    private spinner: NgxSpinnerService,
    private tasksService: TasksService,
    private errorUtil: ErrorUtil,
    private teamService: TeamService,
    private projectService: ProjectService,
    private sitesService: SiteService,
    public modalRef: BsModalRef,
    private checklistService: ChecklistService) {
    this.rowSelection = "multiple";
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    this.columnDefs = [
      { headerName: 'Template Name', field: 'templateName', width: 150, headerTooltip: "#" },
      { headerName: 'Task Name', field: 'taskName', width: 170, headerTooltip: "#" },
      { headerName: 'Site', field: 'site.name', width: 80, headerTooltip: "#", },
      { headerName: 'Project', field: 'project.name', width: 100, headerTooltip: "#" },
      { headerName: 'Team', field: 'team.name', width: 100, headerTooltip: "#" },
      {
        headerName: "Select All", field: "", width: 80,
        cellStyle: (params: any) => {
          if (params.data) {
            return params.data.disabled ? { 'pointer-events': 'none', opacity: '0.4' } : '';
          }
        },
        headerTooltip: "#",
        checkboxSelection: true,
        cellRenderer: (params: any) => {
          if (params.data) {
            if (this.selectAllChecked || params.data.disabled) {
              params.node.setSelected(true);
            }
            else if (!this.selectAllChecked && this.formData.tasks.length >= 1) {
              for (var i = 0; i <= this.formData.tasks.length - 1; i++) {
                if (this.formData.tasks[i].id === params.data.id) {
                  params.node.setSelected(true);
                  return;
                }
                else {
                  params.node.setSelected(false);
                }
              }
            }
            else {
              params.node.setSelected(false);
            }
          }
        }
      },
    ];
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    this.overlayNoRowsTemplate = "<div class='not-found-task'><span>Record not found</span> </div>";

  }
  ngOnInit() {
    // console.log(this.data)
    if (this.data.comingFrom == undefined) {
      this.taskTriggers.id = this.data.tmplJobOrderId
    } else {
      this.taskTriggers.tmplChecklistAns = { id: this.data.selectedAnswerId }
    }

    this.data.skipTaskListIds = this.data.skipTaskListIds && this.data.skipTaskListIds!=''? this.data.skipTaskListIds : null;

    if(this.data.skipTaskListIds){
      this.data.skipTaskListIds = this.data.skipTaskListIds.split(',');
      this.data.skipTaskListIds = this.data.skipTaskListIds.map((i:any)=> Number(i)); 
    }

    this.getFilterList()
  }
  /******************************************** Filter List ******************************************/
  getFilterList() {
    const sources = [
      this.sitesService.getSitePicklist(null),
      this.teamService.getAllTeamsPickList(),
      this.projectService.getAllProjectPickList(null),
    ];

    forkJoin(sources).subscribe(
      ([
        sitesResponce,
        teamsResponce,
        projectsResponce,
      ]) => {
        this.allSites = sitesResponce
        this.teams = teamsResponce;
        this.allProjects = projectsResponce;
      }
    )
  }


  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  filterResults() {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  /***************************************** Initilize Grid Table ****************************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }

  /********************************** Call Customer API to Display data *********************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log('Making a server request: ', serverRequest);
    this.tasksService.getAllTaskTemplates_(this.searchValue, serverRequest, this.filter.siteId, this.filter.projectId, this.filter.teamId, null, this.data.skipTaskListIds, {}).subscribe(
      (data: any) => {
        console.log(data.body);
        this.taskTemplateData = data.body.data;
        this.totalRecord = data.body.total;
        this.orderGridTotalLength = data.body.total;

        this.setGridData(
          agGridGetRowsParams,
          this.taskTemplateData,
          data.body.total,
          data.body.status
        );
        //this.spinner.hide()
      },
      (err) => {
        //console.log(err,"===>>>")
        this.errorUtil.setErrorMessage(err);
      }
    )
  }
  /**************************************** Set Data for Grid Table ****************************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
    console.log(resultItems, "===");


    if (this.taskTemplateData.length === 0) {
      this.gridApi.showNoRowsOverlay();
      this.notaskTemplate = false;
      this.notaskTemplateIcon = true;
    } else {
      this.notaskTemplate = true;
      this.notaskTemplateIcon = false;
      agGridGetRowsParams.successCallback(this.taskTemplateData, totalCount);
    }


  }
  /********************************** Request Parameter for api ****************************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "team.teamName", sort: "asc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }


  //order table inside checkbox checked
  onSelectionChanged(event: any) {
    console.log(event)
    var index = _.findIndex(this.taskTriggers.tmplTasks, (row: any) => {
      return row.id == event.data.id;
    });
    if (event.node.selected) {
      if (index <= -1) {
        var user = { id: event.data.id }
        this.taskTriggers.tmplTasks.push(user);

        // this.taskTriggers.tmplTasks.push(event.data);
        if (this.taskTriggers.tmplTasks.length == this.orderGridTotalLength) {
          this.selectAllChecked = true;
          $('#checkboxCustella').removeClass('indeterminate');
        } else if (this.taskTriggers.tmplTasks.length < this.orderGridTotalLength) {
          this.selectAllChecked = false;
          $('#checkboxCustella').addClass('indeterminate');
        }
      }
    } else {
      if (index > -1) {
        console.log(index, "-----")
        // this.selectAllChecked = false;
        //this.allCheckedDataLength = null;
        this.taskTriggers.tmplTasks.splice(index, 1);

        if (this.taskTriggers.tmplTasks.length <= 0) {
          this.selectAllChecked = false;
          $('#checkboxCustella').removeClass('indeterminate');
        }
        else if (this.taskTriggers.tmplTasks.length >= 1 && this.taskTriggers.tmplTasks.length < this.orderGridTotalLength) {
          this.selectAllChecked = false;
          $('#checkboxCustella').addClass('indeterminate');
        }
      }
    }
    console.log(this.taskTriggers.tmplTasks.length, "====")
    if (this.taskTriggers.tmplTasks.length == 0) {
      this.submitTouched = true

    } else {
      this.submitTouched = false
    }
  }

  addTask() {
    if (this.data.comingFrom == undefined) {
      this.addJoTaskTemplate()
    } else {
      this.addChecklistQuestionTaskTemplate()
    }
  }

  addJoTaskTemplate() {
    if (!this.taskTriggers.tmplTasks || this.taskTriggers.tmplTasks.length == 0) return false;
    var formData = _.cloneDeep(this.taskTriggers)
    this.submitTouched = true
    this.tasksService.saveTaskToJO(formData).subscribe((res: any) => {
      if (res.status == 201 || res.status == 200) {
        this.errorUtil.setErrorMessage(200, 'Task added successfully', null, 'success', 1000);
        this.modalRef.hide();
      } else {
        this.submitTouched = false
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      }
    })
  }
  addChecklistQuestionTaskTemplate() {
    if (!this.taskTriggers.tmplTasks || this.taskTriggers.tmplTasks.length == 0) return false;
    let formData = _.cloneDeep(this.taskTriggers)
    if(this.data.skipTaskListIds  && formData){
      formData.tmplTasks = formData.tmplTasks.filter((t:any)=> !this.data.skipTaskListIds.includes(t.id));
    }
    if (!formData.tmplTasks || formData.tmplTasks.length == 0) return false;
    this.submitTouched = true
    this.checklistService.createAnswerByTask(formData).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status === 200 || res.status === 201) {
          this.errorUtil.setErrorMessage(200, "Task added successfully", null, "success", 3000);
          this.modalRef.hide();
        }
      },
      (error) => {
        console.log(error);
        this.spinner.hide();
        this.errorUtil.setErrorMessage(error.status, null, error.error.title, "error", 3000);
      }
    )

  }

  /************************************************ Search Data ****************************************/
  quickSearch() {
    this.onGridReady(this.gridParams);
  }

  resetSearch() {
    this.filter.teamId = null
    this.filter.siteId = null
    this.filter.projectId = null
    this.quickSearch();
  }
}
