import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfilesService } from 'src/app/_services/admin/profiles.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss']
})
export class UploadPhotoComponent implements OnInit {
  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  submitTouched : boolean = true;
  croppedImage: any;
  cropperReady!: boolean;
  toastHeader!: string;
  uploadUrl!: string;
  required: boolean = false;
  attachments: any;
  uploadedPhotos:Array<any>=[];
  uploadPhotoForm !: UntypedFormGroup;
  title!: string;
  type!: string;
  data : any;
  imageChangedEvent: any

  image: any;

  constructor(
    public modalRef: BsModalRef,
    private profilesService : ProfilesService,
    private spinner: NgxSpinnerService
  ) { 
    this.uploadPhotoForm = new UntypedFormGroup({
      'photo': new UntypedFormControl(null, [Validators.required]),
    });
    
  }

  ngOnInit(): void {
    console.log(this.data)
    this.cropperReady = true;
    if(this.type == 'user'){
      this.uploadUrl = environment.base_url + '/attachments/upload/image?parentId='+this.data.id+'&parentType=USER_PROFILE';
      this.toastHeader = 'Profile Photo';
    } else if(this.type == 'team'){
      this.uploadUrl = environment.base_url + '/attachments/upload/image?parentId='+this.data.id+'&parentType=TEAM_PROFILE';
      this.toastHeader = 'Team Photo';
    } else if(this.type == 'customer'){
      this.uploadUrl = environment.base_url + '/attachments/upload/image?parentId='+this.data.id+'&parentType=CUSTOMER_PROFILE';
      this.toastHeader = 'Customer Photo';
    } else if(this.type == 'org'){
      this.uploadUrl = environment.base_url + '/attachments/upload/image?parentId='+this.data.id+'&parentType=ORG_LOGO';
      this.toastHeader = 'Org Photo';
      //attachments/upload?parentType=PACKAGE_TYPE_ATTACHMENT
    } else if(this.type == 'PACKAGE_CATEGORY_ATTACHMENT' || this.type == 'PACKAGE_TYPE_ATTACHMENT'){
      this.uploadUrl = environment.base_url + '/attachments/upload?parentType='+this.type;
      this.toastHeader = 'Package Type Photo';
    } else if(this.type == 'CalendlyLogo'){
      this.uploadUrl = environment.base_url + '/attachments/upload/image?parentType=DYNAMIC_FORM';
      this.toastHeader = 'Calendly Logo';
  }
}

  uploadFile() {
    
    $("#uploaderFile").click();
   
  }
  onFileChange(event: any) {
    console.log("We are Call====")
    console.log(event,"====")
      this.imgChangeEvt = event;
      this.imageChangedEvent = event.src; 
      this.submitTouched = false;
      this.image = event;

      console.log(this.submitTouched,"====")
  }
  cropImg(e: ImageCroppedEvent) {
      this.croppedImage = e;
  }
  imgLoad() {
    this.cropperReady = true;
    this.submitTouched = false;
      // display cropper tool
  }
  initCropper() {
      // init cropper
  }
  
  imgFailed() {
      // error msg
  }
  submitForm(){
    if(!this.submitTouched){
      this.spinner.show();
      this.submitTouched = true;
      
      var formData ={ base64File:'',file:''};

console.log(formData,"======")
      formData.base64File = this.croppedImage.base64;

console.log(formData,"======")
      if(this.type == 'PACKAGE_CATEGORY_ATTACHMENT' || this.type == 'PACKAGE_TYPE_ATTACHMENT'){
        formData.file = this.croppedImage.base64;
      }
console.log(formData,"======")
      this.profilesService.uploadPhotoToServerJson(this.uploadUrl, formData).subscribe((res: any) => {
        if(res.status == 200 || res.status == 201){
            // var body=JSON.parse(res.response._body);
            ////this.toaster.success(this.toastHeader, 'Photo uploaded successfully');
            if(this.type == 'user'){
              this.profilesService.profilePhotoUploaded(res.body);
            } else {
              this.profilesService.photoUploaded(res.body);
            }
            this.modalRef.hide();
            this.spinner.hide();
          }
      })
    }
  }

}
