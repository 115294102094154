import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FleetDashboardService {
  refreshList$: EventEmitter<{}>;
  private ENDPOINT_FLEET_CHART = "/delivery-orders/dashboard/chart";
  private ENDPOINT_PENDING_DO = "/delivery-orders/dashboard/new";
  private ENDPOINT_FLEET_BY_PLAN = "/delivery-orders/dashboard/by-plan";
  private ENDPOINT_FLEET_PLAN_LIST = "/fleet-draft-route-plans/list";
  private ENDPOINT_LIST_FLEET_NOTIFICATIONS = "/driver-vehicle-logs/dashboard";
  // Hills Dashboard
  private ENDPOINT_LIST_HILLS_BOX_CHART = "/hills/box-chart";
  private ENDPOINT_LIST_HILLS_FAULT_BY_SYSTEM = "/hills/fault-by-system";
  private ENDPOINT_LIST_HILLS_PIE_CHART = "/hills/pie-chart";
  private ENDPOINT_LIST_HILLS_LINE_CHART = "/hills/line-chart";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH =
    "/hills/closed-job-per-month";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_BY_TIMEFRAME =
    "/hills/closed-job-by-timeframe";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH_BY_TECHNICIAN =
    "/hills/closed-job-per-month-by-technician";
  // private ENDPOINT_GET_LAST_SIX_MONTH_LIST="/hills/get-last-six-month-list";

  // customer inside dashboard
  private ENDPOINT_FAULT_TYPE_BY_BAR_CHART =
    "/hills/fault-type-by-system/bar-chart";
  private ENDPOINT_RESOLUTION_BY_SYSTEM_BAR_CHART =
    "/hills/resolution-by-system/bar-chart";
  private ENDPOINT_NUMBER_OF_FAULTS_LOGGED = "/hills/number-of-faults-logged";
  private ENDPOINT_LINE_CHART_FAULTS_ABLE_TO_FIX =
    "/hills/line-chart/faults-able-to-fix";
  private ENDPOINT_LINE_CHART_BILLABLE_JOBS = "/hills/line-chart/billable-jobs";
  private ENDPOINT_PRODUCT_COUNT = "/hills/product-count";
  private ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH_BY_TECHNICIAN_V2 =
    "/hills/v2/closed-job-per-month-by-technician";
  private ENDPOINT_JOB_BY_MONTH = "/hills/jobs-by-month";
  private ENDPOINT_RED_FLAGS = "/hills/red-flag-jobs-by-month";
  private ENDPOINT_JOBS_BY_TECHNICIAN = "/hills/jobs-by-technician";

  // Magnum Dashboard
  // private ENDPOINT_FAULT_PIE_CHART = "/dashboard/pie-chart?joType=Fault Call&group=fault&fromDate="
  private ENDPOINT_FAULT_PIE_CHART = "/dashboard/pie-chart";
  private ENDPOINT_FAULT_BAR_CHART = "/dashboard/assets-data";
  private ENDPOINT_DAILY_PROGRESS = "/dashboard/daily-progress";
  private ENDPOINT_OVER_DUE = "/dashboard/overdue-fault-calls";
  private ENDPOINT_PREVENTIVE_MAINTENANCE =
    "/dashboard/preventative-maintenance";
  private ENDPOINT_FAULT_TYPES = "/faults";
  private ENDPOINT_FAULT_HIERARCHY = "/dashboard/hierarchy";

  constructor(private http: HttpClient) {
    this.refreshList$ = new EventEmitter();
  }

  public getFleetChart(id: any, selectDate: any) {
    let params = new HttpParams();

    if (id !== "" && id !== null) {
      params = params.set("routePlanId", id);
    }

    if (selectDate !== "") {
      params = params.set("date", selectDate);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_FLEET_CHART, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPendingDO() {
    return this.http.get(environment.base_url + this.ENDPOINT_PENDING_DO).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getPlansList(selectDate: any) {
    let params = new HttpParams();

    if (selectDate !== null && selectDate !== "") {
      params = params.set("date", selectDate);
    }

    return this.http
      .get(environment.base_url + "/fleet-draft-route-plans/list", {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getFleetNotifications(routePlanId: any, selectDate: any) {
    let params = new HttpParams();

    if (routePlanId !== "" && routePlanId !== null) {
      params = params.set("planId", routePlanId);
    }

    if (selectDate !== "") {
      params = params.set("date", selectDate);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_FLEET_NOTIFICATIONS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getFleetDO(id: any, selectDate: any) {
    let params = new HttpParams();

    if (id !== "" && id !== null) {
      params = params.set("routePlanId", id);
    }

    if (selectDate !== "") {
      params = params.set("date", selectDate);
    }

    return this.http
      .get(environment.base_url + this.ENDPOINT_FLEET_BY_PLAN, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  ///////////////////////////////////// hills dashboard//////////////////////////////////////////////
  public getBoxesData() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_BOX_CHART)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getBarChart() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_FAULT_BY_SYSTEM)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getPieChart() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_PIE_CHART)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getLineChart() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_HILLS_LINE_CHART)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getHorizontalStacked(redFlag: any) {
    let params = new HttpParams();
    params = params.set("redFlag", redFlag);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getVerticalStacked(byState: any) {
    let params = new HttpParams();
    params = params.set("byState", byState);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_LIST_HILLS_CLOSED_JOB_BY_TIMEFRAME,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getTechniciansData() {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_LIST_HILLS_CLOSED_JOB_PER_MONTH_BY_TECHNICIAN
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // public getTableHeaders(){
  //   return this.http.get( environment.base_url+this.ENDPOINT_GET_LAST_SIX_MONTH_LIST).map( (resp:any) => {
  //     return resp;
  //   }
  // ).catch( error => {
  //       return Observable.of( error );
  //   });
  // }

  ////////////////////////////////// customer inside dashboard ////////////////////////////////////////
  public getVerticalStackedByMonth(customerId: any, groupBy: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);

    params = params.set("groupBy", groupBy);

    params = params.set("months", months);

    return this.http
      .get(
        environment.base_url + this.ENDPOINT_LIST_HILLS_CLOSED_JOB_BY_TIMEFRAME,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/fault-type-by-system/bar-chart?customerId=&months=6&system=HTR
  public getClosedFaultType(customerId: any, months: any, system: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    params = params.set("system", system);
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_TYPE_BY_BAR_CHART, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/resolution-by-system/bar-chart?customerId=&months=6&system=HTR
  public getResolutionType(customerId: any, months: any, system: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    params = params.set("system", system);
    return this.http
      .get(
        environment.base_url + this.ENDPOINT_RESOLUTION_BY_SYSTEM_BAR_CHART,
        { params: params }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/number-of-faults-logged?customerId=&months=12
  public getFaultsData(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_NUMBER_OF_FAULTS_LOGGED, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/line-chart/faults-able-to-fix?months=12&ableToFix=true
  public getFaultFixed(months: any, ableToFix: any) {
    let params = new HttpParams();
    params = params.set("months", months);
    params = params.set("ableToFix", ableToFix);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LINE_CHART_FAULTS_ABLE_TO_FIX, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/line-chart/billable-jobs?months=12&billable=true
  public getBillableJobs(months: any, billable: any) {
    let params = new HttpParams();
    params = params.set("months", months);
    params = params.set("billable", billable);
    return this.http
      .get(environment.base_url + this.ENDPOINT_LINE_CHART_BILLABLE_JOBS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/product-count?customerId=15&months=12
  public getProductUsage(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_PRODUCT_COUNT, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/jobs-by-month?customerId=&months=12
  public getClosedJobOrders(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOB_BY_MONTH, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/red-flag-jobs-by-month?customerId=&months=12
  public getClosedRedFlags(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_RED_FLAGS, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  // {{ip}}/api/hills/jobs-by-technician?customerId=&months=12
  public getJobsTechnician(customerId: any, months: any) {
    let params = new HttpParams();
    params = params.set("customerId", customerId);
    params = params.set("months", months);
    return this.http
      .get(environment.base_url + this.ENDPOINT_JOBS_BY_TECHNICIAN, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  ///////////////////////////////////////////////////////  Magnum Dashboard  //////////////////////////////////

  public getMagnumPieChart(
    joType: any,
    group: any,
    roleId: any,
    fromDate: any,
    toDate: any,
    systems: any
  ) {
    // var fromDate = '2020-01-01';
    let params = new HttpParams();
    params = params.set("joTypes", joType);
    params = params.set("group", group);
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    if (systems) {
      params = params.set("systems", systems);
    }
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_PIE_CHART, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumBarHorizontalStacked(
    roleId: any,
    fromDate: any,
    toDate: any
  ) {
    // roleId no need here
    let params = new HttpParams();
    //params = params.set('fromDate', fromDate);
    //params = params.set('toDate', toDate);
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_BAR_CHART, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumProgressBar(roleId: any, fromDate: any, toDate: any) {
    let params = new HttpParams();
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    return this.http
      .get(environment.base_url + this.ENDPOINT_DAILY_PROGRESS, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumOverDue(roleId: any, fromDate: any, toDate: any) {
    let params = new HttpParams();
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    return this.http
      .get(environment.base_url + this.ENDPOINT_OVER_DUE, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumPreventiveMaintenance(
    roleId: any,
    fromDate: any,
    toDate: any
  ) {
    // var fromDate = '2020-01-01';
    let params = new HttpParams();
    params = params.set("fromDate", fromDate);
    params = params.set("toDate", toDate);
    if (roleId != null) {
      params = params.set("roleId", roleId);
    }
    // return this.http.get( environment.base_url+this.ENDPOINT_PREVENTIVE_MAINTENANCE).map( (resp:any) => {
    return this.http
      .get(environment.base_url + this.ENDPOINT_PREVENTIVE_MAINTENANCE, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getMagnumFaultTypes() {
    return this.http.get(environment.base_url + this.ENDPOINT_FAULT_TYPES).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getMagnumCompanies() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_FAULT_HIERARCHY)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
