<div class="topbar">
    <div class="custella-topbar-left" id="topbar-left">
        <!-- Service dropdown -->
        <!-- <div class="dropdown">
            <a id="custella-menu-button" href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="assets\svg\header\top-dots.svg" />
            </a>

            <ul class="dropdown-menu custella-service">
                <li class="flex-between mt-1" [ngClass]="{'disabledMenu':!salesEnabled || !salePer}" (click)="salesEnabled ? menuType('sales'):null">
                    <a [ngClass]="{'active':defaultTenantCategory === 'sales' && salePer}" class="custella-service dropdown-item flex-between">
                        <span class="me-5 py-2 text-12" translate>{{'sales'}}</span>
                        <img *ngIf="salesEnabled && salePer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        <p-button *ngIf="!salePer" label="Add On" (onClick)="executeAddon()"></p-button>
                    </a>
                </li>
                <li class="mt-1" [ngClass]="{'disabledMenu':!serviceEnabled || !servicePer}" (click)="serviceEnabled && servicePer?menuType('service'):null">
                    <div class="d-flex flex-between">
                        <a [ngClass]="{'active': defaultTenantCategory === 'service' && servicePer}" class="custella-service dropdown-item flex-between">
                            <span class="me-5 py-2 text-12" translate>{{'services'}}</span>
                            <img *ngIf="serviceEnabled && servicePer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        </a>
                        <p-button *ngIf="!servicePer" label="Add On" (onClick)="executeAddon()"></p-button>
                    </div>



                    <a [ngClass]="{'active': defaultTenantCategory === 'service' && servicePer}" class="custella-service dropdown-item flex-between">
                        <span class="me-5 py-2 text-12" translate>{{'services'}}</span>
                        <img *ngIf="serviceEnabled && servicePer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        <p-button *ngIf="!servicePer" label="Add On" (onClick)="executeAddon()"></p-button>
                    </a>
                </li>
                <li class="mb-1" [ngClass]="{'disabledMenu':!fleetEnabled || !fleetPer}" (click)="fleetEnabled && fleetPer ?menuType('fleet'):null">
                    <div class="d-flex flex-between">
                        <a [ngClass]="{'active': defaultTenantCategory === 'fleet' && fleetPer}" class="custella-service dropdown-item flex-between">
                            <span class="me-3 py-2 text-12" translate>{{'fleet'}}</span>
                            <img *ngIf="fleetEnabled && fleetPer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        </a>
                        <p-button *ngIf="!fleetPer" label="Add On" (onClick)="executeAddon()" ></p-button>
                    </div>

                    <a [ngClass]="{'active': defaultTenantCategory === 'fleet' && fleetPer}" class="custella-service dropdown-item flex-between">
                        <span class="me-3 py-2 text-12" translate>{{'fleet'}}</span>
                        <img *ngIf="fleetEnabled && fleetPer" src="assets\svg\header\arrow-circle-right.svg" alt="" />
                        <p-button *ngIf="!fleetPer" label="Add On" (onClick)="executeAddon()" ></p-button>
                    </a>
                </li>
            </ul>
        </div>

        <button pButton [label]="defaultTenantCategory" class="text-capitalize" style="height: 24px;"></button>-->
        <span class="custella-topbar-title">{{companyName}}</span> 
    </div>

    <a id="options-menu-button" href="javascript:void(0)"> <span class="pi pi-ellipsis-h"></span> </a>
    <div class="d-flex position-relative justify-content-center align-items-center me-2">
        <!-- <a class="ng-star-inserted">
            <span class="position-relative mx-2"><img src="assets/svg/header/search.svg" class="cursor-pointer w-28px" /></span>
        </a> -->
        <a class="ng-star-inserted header" routerLink="messages">
            <img src="assets/svg/header/comments.svg" class="cursor-pointer w-28px mx-2" />
            <span *ngIf="messageCount > 0" class="position-relative mx-2" pBadge [value]="messageCount"></span>
        </a>

        <!-- Notifications -->
        <div class="dropdown header">
            <a class="ng-star-inserted" data-bs-toggle="dropdown" aria-expanded="false" (click)="openNotificationPanel()">
                <img src="assets/svg/header/bell.svg" class="cursor-pointer w-28px mx-2" />
                <span *ngIf="notificationCount > 0" class="position-relative mx-2" pBadge [value]="notificationCount"></span>
            </a>
            <ul class="custella-notification dropdown-menu">
                <li class="flex-between border-bottom border-secondary px-1 py-2">
                    <div class="d-flex align-items-center me-3">
                        <img class="mx-1 w-28px" src="assets\svg\header\bell.svg" />
                        <span class="fs-6 ps-1" style="padding-top: 2px;">Notification</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0)" class="text-decoration-underline" (click)="clearAllNotifications()"><small class="d-flex align-items-center text-nowrap text-10">Mark all as read</small></a>
                    </div>
                </li>
                <div class="custella-notification-body overflow-scroll p-0 m-0">
                    <li class="border-bottom border-secondary px-1" *ngFor="let notification of notifications | orderBy: '-createdDate'">
                        <a  href="javascript:void(0)" (click)="viewNotification(notification)" [ngClass]="{'notifcation-active' : notification.status === 'UNREAD'}" class="dropdown-item d-flex align-items-start p-2">
                            <div *ngIf="!notification.displayDefaultIcons">
                                <img *ngIf="notification.type.indexOf('TEAM_') > -1" src="assets\svg\notification\team.svg" class="pt-1" />
                                <img *ngIf="notification.type.indexOf('TASK_') > -1" src="assets\svg\header\task-white.svg" class="pt-1" />
                                <img *ngIf="notification.type.indexOf('LEAVE_') > -1" src="assets\svg\notification\leave.svg" class="pt-1" />
                                <img *ngIf="notification.type.indexOf('CONSIGNMENT_') > -1 || notification.type.indexOf('REQUISITION_') > -1 || notification.type.indexOf('BIN_TRANSFER_REQUEST') > -1" src="assets\svg\notification\inventory.svg" class="pt-1" />
                            </div>
                            <div *ngIf="notification.displayDefaultIcons">
                                <img src="assets\svg\notification\default.svg" class="pt-1" />
                                </div>
                            <div class="text-left mx-2">
                                <span class="text-wrap text-12">{{notification.title}}</span>
                                <span class="d-block color-var-500 text-10">{{notification.calendarTime}} {{'at'}} {{notification.createdDate | date : 'shortTime'}}</span>
                            </div>
                        </a>
                    </li>
                </div>
               
                <!-- <li class="border-bottom border-secondary px-1">
                    <a  href="javascript:void(0)" class="dropdown-item d-flex align-items-start p-2">
                        <img src="assets\svg\header\task-white.svg" class="pt-1" />
                        <div class="text-left mx-2">
                            <span class="text-wrap text-12"><strong class="font-violet">Nathan</strong> has completed task <strong class="font-violet">Task 2</strong>.</span>
                            <span class="d-block color-var-500 text-10">31/03/2021, 1:07PM</span>
                        </div>
                    </a>
                </li> -->
            </ul>
        </div>
        <!-- Profile Settings -->
        <div class="dropdown profie">
            <a class="position-relative" href="javascript:void(0)" data-bs-toggle="dropdown" aria-expanded="false">
                <p-avatar [image]="photoUrl" styleClass="mx-2 mt-2 display-dp" shape="circle"></p-avatar>
                <div [ngClass]="{'custella-online-mini' : onlineStatus, 'custella-offline-mini' : !onlineStatus}"></div>
            </a>

            <ul class="custella-profile dropdown-menu mt-3" onclick="event.stopPropagation()">
                <li class="border-bottom border-secondary pb-1">
                    <div class="dropdown-item d-flex align-items-center profile">
                        <div class="position-relative">
                            <p-avatar [image]="photoUrl" styleClass="mt-2" shape="circle" size="large"></p-avatar>
                            <div class="custella-online" [ngClass]="{'custella-online' : onlineStatus, 'custella-offline' : !onlineStatus}"></div>
                        </div>
                        <div class="m-1 p-2">
                            <span class="d-block text-12">{{fullName}}</span>
                            <span class="text-muted text-12">{{userEmail}}</span>
                        </div>
                    </div>
                </li>
                <div class="border-bottom border-secondary p-2">
                    <li>
                        <a class="dropdown-item px-1 py-2 text-12" routerLinkActive="active" routerLink="/dashboard/setup/allUser/view"  (click)="resetUserTab()"><img src="assets\svg\header\customer-white.svg"class="me-1 w-24px" />My Profile</a>
                    </li>
                    <!-- <li>
                        <p-panelMenu [model]="panelMenuItems"></p-panelMenu>
                    </li> -->
                    <li class="custella-lang">
                        <div class="custella-lang-menu accordion accordion-flush">
                            <div class="accordion-item">
                                <div class="accordion-header" id="language">
                                    <button class="accordion-button collapsed justify-content-between px-1 py-2" data-bs-toggle="collapse" data-bs-target="#choose-lang" aria-expanded="false" aria-controls="choose-lang">
                                        <div>
                                            <img src="assets\svg\header\globe.svg" class="me-1 w-24px" />
                                            <span class="text-12 font-white" *ngIf="currentLang === 'English'">
                                                English
                                            </span>

                                            <span class="text-12 font-white" *ngIf="currentLang === 'Malay'">
                                                Malay
                                            </span>

                                            <span class="text-12 font-white" *ngIf="currentLang === 'Chinese'">
                                                Chinese
                                            </span>
                                        </div>
                                        <i class="pi pi-angle-down" style="color: #e0e0e0;"></i>
                                    </button>

                                </div>
                                <ul class="p-0 m-0 accordion-collapse collapse"  id="choose-lang">
                                    <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('en')" *ngIf="currentLang !== 'English'">
                                        <a class="px-1">
                                            <span class="text-12 font-white" style="padding-left: 1.75rem;">English</span>
                                        </a>
                                    </li>
                                    <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('my')" *ngIf="currentLang !== 'Malay'">
                                        <a class="px-1">
                                            <span class="text-12 font-white" style="padding-left: 1.75rem;">Malay</span>
                                        </a>
                                    </li>
                                    <li class="py-2 px-0 my-0" aria-labelledby="language" (click)="useLanguage('ch')" *ngIf="currentLang !== 'Chinese'">
                                        <a class="px-1">
                                            <span class="text-12 font-white" style="padding-left: 1.75rem;">Chinese</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a *ngIf="onlineStatus" class="dropdown-item px-1 py-2 text-12 d-flex" (click)="changeOnlineStatus(false)">
                        <div class="custella-go-offline"></div>Go offline
                        </a>
                        <a  *ngIf="!onlineStatus"class="dropdown-item px-1 py-2 text-12 d-flex" (click)="changeOnlineStatus(true)">
                            <div class="custella-go-online"></div>Go Online
                         </a>
                    </li>
                </div>
                <div class="p-2">
                    <!-- <li>
                        <a class="dropdown-item px-1 py-2 text-12" href="javascript:void(0)"><img src="assets\svg\header\phone.svg" class="me-1 w-24px" />Contact Support</a>
                    </li>
                    <li>
                        <a class="dropdown-item px-1 py-2 text-12" href="javascript:void(0)"><img src="assets\svg\header\question-circle.svg" class="me-1 w-24px" /><span class="ps-1">FAQ</span></a>
                    </li> -->
                    <li>
                        <a class="dropdown-item px-1 py-2" (click)="doLogOut()"><img src="assets\svg\header\log-out.svg" class="w-24px" /><span class="ps-1 text-12" style="color: #ffb1b1;">Log out</span></a>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</div>
<p-sidebar (onHide)="handleClick($event)" [(visible)]="visibleSidebarTask" [style]="{width:'calc(100% - 20px)', background:'#fafafa'}" position="right" [baseZIndex]="1000" [showCloseIcon]="showCloseIcon">
    <app-view-tasks  *ngIf="newTaskId" [taskId]="newTaskId" showClose="no" (closeSlide)="closeSlide()"></app-view-tasks>
  </p-sidebar>
