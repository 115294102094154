<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img src="assets\svg\dialog\Confirmation-w.svg">
            <span class="text-16 font-medium ms-3" id="title_SetDeliverFormat">Delivery Order Format</span>
        </div>
        <i class="pi pi-times w-24px" (click)="modalRef.hide()" id="btn_SetDeliverPicklistClose"></i>
    </div>
    <div class="modal-body tix height-80">
        <div class="m-0 pb-3 px-2">
            <span class="text-12 d-block font-semiBold" id="label_SetDeliverFormatQuest" translate>{{'what_is_delivery_order_format'}}</span>
            <span class="text-12" id="label_SetDeliverFormatDesc" translate>{{'the_delivery_order_format_allows'}}</span>
        </div>
        <div>
            <p-table [value]="do" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="">
                        <th class="col-2" id="label_SetDeliverVar">{{'variable'|translate}}</th>
                        <th class="col-2" id="label_SetDeliverNec">{{'necessity'|translate}}</th>
                        <th class="col-2" id="label_SetDeliverDesc">{{'description'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-do>
                    <tr class="text-12 font-medium">
                        <td class="col-2" id="value_SetDeliverVar">{{do.variable}}</td>
                        <td class="col-2 text-left" id="value_SetDeliverNec">{{do.necessity}}</td>
                        <td class="col-2 text-left" id="value_SetDeliverDesc">{{do.desc}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="pt-2">
            <div class="m-1"><span class="text-12 font-medium" id="label_SetDeliverFormatEg">{{'here_examples'|translate}}</span></div>
            <p-table [value]="do2" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr class="">
                        <th class="col-2" id="label_SetDeliverTixFormat">Ticket Format</th>
                        <th class="col-2" id="label_SetDeliverSeqNo">{{'seq_no'|translate}}</th>
                        <th class="col-2" id="label_SetDeliverFormatNo">{{'formatted_no'|translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-do2>
                    <tr class="text-12 font-medium">
                        <td id="value_SetDeliverTixFormat">{{do2.format}}</td>
                        <td class="col-2 text-left" id="value_SetDeliverSeqNo">
                            <tr id="value_SetDeliverSeqNo1">{{do2.seq1}}</tr>
                            <tr id="value_SetDeliverSeqNo2">{{do2.seq2}}</tr>    
                        </td>
                        <td class="col-2 text-left" id="value_SetDeliverFormatNo">
                            <tr id="value_SetDeliverFormatNo1">{{do2.number1}}</tr>
                            <tr id="value_SetDeliverFormatNo2">{{do2.number2}}</tr>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    
    
    </div>
    <!-- </div> -->