<form [formGroup]="switchOrderForm">
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <span class="text-16 font-medium" translate>{{title}}</span>
        </div>
        <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
    <div class="modal-body tix height-80 pb-0 pt-2">
        <div class="rounded-2 mx-2">
            <!-- Container Content -->
            <div class="custella-form-container-content p-0 m-0">
                <div class="custella-content-row-input col-12 pb-3">
                    <span *ngIf="data.optionType=='switchOrderSameVehicle'" class="custella-content-row-input-title"
                        translate>{{'select_a_vehicle_and_drag'}}</span>
                    <p *ngIf="data.optionType=='returnOrder'"
                        class="text-1-5 mb-0 pb-2 Roboto-Medium selectVehicle letter-spacing-23">Select order</p>


                    <div class="d-flex align-items-center" *ngIf="data.optionType=='switchOrderSameVehicle'">
                        <div class="p-input-icon-right w-100">
                            <p-dropdown placeholder="Vehicles" optionLabel="vehicleNumber" [filter]="false"
                                filterBy="vehicleNumber" optionValue="id" [(ngModel)]="search2" (onChange)="search()"
                                formControlName="vehicle" name="vehicle" [options]="vehiclesData" [showClear]="true">
                            </p-dropdown>
                            <div class="text-10 p-error"
                                *ngIf="switchOrderForm.controls['vehicle'].touched && switchOrderForm.controls['vehicle'].invalid">
                                <span *ngIf="switchOrderForm.controls['vehicle'].errors?.required" id="label_RequiredField"
                                    translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center" *ngIf="data.optionType=='returnOrder'">
                        <div class="p-input-icon-right w-100">
                            <p-dropdown placeholder="Order" [filter]="false" filterBy="name" optionLabel="name"
                                optionValue="orderNumber" [(ngModel)]="searchOrderValue" formControlName="order"
                                name="order" (onChange)="searchOrder()" [options]="ordersData" [showClear]="true">
                            </p-dropdown>
                            <div class="text-10 p-error"
                                *ngIf="switchOrderForm.controls['order'].touched && switchOrderForm.controls['order'].invalid">
                                <span *ngIf="switchOrderForm.controls['order'].errors?.required" id="label_RequiredField"
                                    translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <!------------------------------------------ Remove - return order -------------------------------->
                <div *ngIf="dragableDataList&&data.optionType=='returnOrder'"
                    class="custella-form-container-content p-0 border rounded-2 mb-2 mx-0">
                    <div class="custella-form-container-title-two-grey d-flex align-items-center ps-3" style="background: #eaeaea; padding: 0.85rem 1.95rem;">
                        <div class="col" *ngIf="orderName">
                            <span class="text-12 font-black font-semiBold d-block">{{orderName}}</span>
                        </div>
                    </div>

                    <div class="">
                        <div *ngFor="let item of dragableDataList;index as i"
                            class="border rounded-2 cursor-pointer flex-between py-2 px-3 mx-3 my-2">
                            <div *ngIf="item" class="col p-0">
                                <div (click)="selectCard(item,i)"
                                    *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')"
                                    class="d-flex align-items-center"
                                    [ngClass]="{'fade-opacity-04' :item.status =='WITH_DRIVER' || (item.deliveryOrderWay=='delivery'||item.deliveryOrderWay=='pickup_and_delivery'),'li-box3' : item.selectedCard}">
                                    <div class="d-flex align-items-center col-7">
                                        <img *ngIf="item?.type=='pickup'" src="assets/svg/table/ph_arrow_green.svg"
                                            alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <img *ngIf="item?.type=='delivery'" src="assets/svg/table/ph_arrow.svg"
                                            alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <img *ngIf="item?.type=='pickup_and_delivery'"
                                            src="assets/svg/table/dropoff-pickup.svg" alt="Delivered Planning Hub"
                                            style="height: 20px;" class="me-2">
                                        <span class="text-12">{{item.orderCustomer}}</span>
                                    </div>
                                    <div class="d-flex align-items-center col"
                                        *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                        <img src="assets\svg\table\clock.svg" alt="Available Vehicle"
                                            class="w-19px me-1">
                                        <span class="text-12 color-var-500">{{item.startTime}} - {{item.endTime}}</span>
                                    </div>
                                    <div class="d-flex align-items-center col"
                                        *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                        <span class="text-12 color-var-500">{{item.note}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!------------------------------------------ Switch order same vehicle -------------------------------->
                <div *ngIf="dragableDataList&&data.optionType=='switchOrderSameVehicle'"
                    class="custella-form-container-content m-0 p-0" style="min-height: 100px;">


                    <div class="min-height: 100px;">
                        <div class="custella-form-container rounded-bottom mx-0" style="box-shadow: none; background: none;">
                            <div class="custella-form-container-title-two-grey d-flex align-items-center rounded mt-1 py-1 px-3">

                                <div class="col">
                                    <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                                    <span class="text-12 font-black font-semiBold d-block"
                                        translate>{{dragableDataList?.vehicleName}}</span>
                                </div>
                                <div class="col">
                                    <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                                    <img *ngIf="dragableDataList?.capacity > 0 && dragableDataList?.capacity < 50"
                                        src="assets/svg/table/green_capacity.svg" alt="Low Capacity"
                                        class="w-14px mx-1">
                                    <span *ngIf="dragableDataList?.capacity > 0 && dragableDataList?.capacity < 50"
                                        class="text-14 font-semiBold font-green">{{dragableDataList?.capacity}}%</span>

                                    <img *ngIf="dragableDataList?.capacity > 50 && dragableDataList?.capacity < 80"
                                        src="assets/svg/table/orange_capacity.svg" alt="Medium Capacity"
                                        class="w-14px mx-1">
                                    <span *ngIf="dragableDataList?.capacity > 50 && dragableDataList?.capacity < 80"
                                        class="text-14 font-semiBold font-orange2">{{dragableDataList?.capacity}}%</span>

                                    <img *ngIf="dragableDataList?.capacity > 80" src="assets/svg/table/red_capacity.svg"
                                        alt="High Capacity" class="w-14px mx-1">
                                    <span *ngIf="dragableDataList?.capacity > 80"
                                        class="text-14 font-semiBold font-red">{{dragableDataList?.capacity}}%</span>

                                </div>
                                <div class="col">
                                    <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                                    <span class="text-12 font-black font-semiBold d-block"
                                        translate>{{dragableDataList?.duration | minutesSeconds}}</span>
                                </div>
                                <div class="col">
                                    <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                                    <span class="text-12 font-black font-semiBold d-block"
                                        translate>{{dragableDataList?.distance | distanceToKm2}}</span>
                                </div>
                            </div>
                            <div class="custella-form-container-content m-0 p-0 height-45">
                                <div class="height-45 overflow-scroll">
                                    <div dndDropzone [dndHorizontal]="true" dndEffectAllowed="move"
                                        (dndDrop)="onDrop($event,dragableDataList?.relatedVehicleEvents)">
                                        <!-- {{dragableDataList?.relatedVehicleEvents.length}} -->

                                        <div *ngFor="let item of dragableDataList?.relatedVehicleEvents;index as i"
                                            [dndDraggable]="item" dndEffectAllowed="move"
                                            [dndDisableIf]="((item.orderStatus=='COMPLETED')||(type=='postDispatch'&&item.type=='pickup'&&item.status=='WITH_DRIVER')||(type=='postDispatch'&&item.type=='pickup'&&item.status=='DELIVERED')||(type=='postDispatch'&&item.type=='delivery'&&item.status=='DELIVERED')) ? true: false"
                                            (dndStart)="onDragStart($event)"
                                            (dndCopied)="onDragged(item, dragableDataList?.relatedVehicleEvents, 'copy')"
                                            (dndMoved)="onDragged(item, dragableDataList?.relatedVehicleEvents, 'move')"
                                            (dndCanceled)="onDragged(item, dragableDataList?.relatedVehicleEvents, 'none')"
                                            (dndEnd)="onDragEnd($event)">
                                            <div dndPlaceholderRef class="dndPlaceholder"> </div>
                                            <div class="custella-content-row-two my-2"
                                                *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                <div class="custella-content-row-two-input col-12">
                                                    <div class="custella-cards-list py-2">
                                                        <div class="justify-content-between align-items-center d-flex">
                                                            <div class="d-block">
                                                                <div class="">
                                                                    <div class="d-flex align-items-center">
                                                                        <img *ngIf="item?.deliveryOrderWay=='pickup'" src="assets/svg/table/ph_arrow_green.svg" alt="Delivered Planning Hub" class="w-19px ms-1">
                                                                        <img *ngIf="item?.deliveryOrderWay=='delivery'" src="assets/svg/table/ph_arrow.svg" alt="Delivered Planning Hub" class="w-19px ms-1">
                                                                        <img *ngIf="item?.deliveryOrderWay=='pickup_and_delivery'" src="assets/svg/table/dropoff-pickup.svg" alt="Delivered Planning Hub" class="w-19px ms-1">
                                                                        <span class="text-10 font-medium ms-2">{{item.orderName}} - {{item.orderStatus}}</span>
                                                                    </div>
                                                                    <div class="ms-32px">
                                                                        <span class="text-10 color-var-500 d-block">{{item?.orderCustomer}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex">
                                                                <img src="assets\svg\table\clock.svg" alt="Available Vehicle"
                                                                class="w-19px me-1">
                                                                <span class="text-10 color-var-500">{{item.startTime}} - {{item.endTime}}</span>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer d-flex align-items-center justify-content-end">
        <button class="custella-btn-white mx-1 text-12" (click)="openModal('modifyPlan',data)" translate>{{'back'}}</button>
        <p-button *ngIf="data.optionType=='returnOrder'" (onClick)="proceed()" type="button" icon="pi pi-arrow-right"
            label="{{'proceed' | translate}}"></p-button>
        <p-button *ngIf="data.optionType=='switchOrderSameVehicle'" (onClick)="reorderSchedular()" type="button"
            icon="pi pi-arrow-right" label="{{'save' | translate}}"></p-button>

    </div>
</form>