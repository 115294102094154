import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  COMPONENT_CREATION,
  HELPDESK_APP_EVENTS,
} from "src/app/_models/global.data.model";
import { HelpdeskService } from "src/app/_services/helpdesk.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ViewTicketComponent } from "../tickets/view-ticket/view-ticket.component";
import { HelpdeskToastComponent } from "./helpdesk-toast/helpdesk-toast.component";
import { HelpdeskUserSelectionComponent } from "./helpdesk-user-selection/helpdesk-user-selection.component";

@Component({
  selector: "app-helpdesk",
  templateUrl: "./helpdesk.component.html",
  styleUrls: ["./helpdesk.component.scss"],
})
export class HelpdeskComponent implements OnInit {
  private subscriptionCollection: Subscription[] = [];

  @ViewChild("componentContainer", { read: ViewContainerRef }) container: any;
  @ViewChildren("ticketDynamicContainer", { read: ViewContainerRef })
  ticketDynamicContainer!: QueryList<ViewContainerRef>;

  private componentRef!: ComponentRef<any>;

  public ticketCounts = {};
  public isTicketOnView = false;

  public listOfAllTicketViews: any[] = [];

  constructor(
    private helpDeskService: HelpdeskService,
    private resolver: ComponentFactoryResolver,
    private utilService: UtilServiceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  private init() {
    this.getTicketCount();

    const sub = this.utilService.getData().subscribe((data: any) => {
      if (data) {
        // when click asign link text
        if (data.action === HELPDESK_APP_EVENTS.ON_HELPDESK_TICKET_ASSIGN) {
          this.clearComponent().then(() => {
            this.createComponent(
              COMPONENT_CREATION.HELPDESK_ASSIGNEE_SELECTION,
              data.data
            );
          });
        }

        // when click claim link text
        if (data.action === HELPDESK_APP_EVENTS.ON_HELPDESK_TICKET_CLAIM) {
          this.clearComponent().then(() => {
            this.createComponent(COMPONENT_CREATION.HELPDESK_TOAST, data.data);
          });
        }

        // when got assigneed user to a ticket - of clicking the assign button
        if (data.action === HELPDESK_APP_EVENTS.ON_HELPDESK_TICKET_ASSIGNEE) {
          this.clearComponent().then(() => {
            this.createComponent(COMPONENT_CREATION.HELPDESK_TOAST, data.data);
          });
        }

        // when click on ticket and is need to be created
        if (data.action === HELPDESK_APP_EVENTS.ON_VIEW_TICKET) {
          this.toggleTicketOnView(true).then(() => {
            this.createComponent(COMPONENT_CREATION.TICKET_VIEW, data.data);
          });
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_CLOSE_TAB_TICKET) {
          this.onTabCloseTicket(data.data.id);
        }

        if (data.action === HELPDESK_APP_EVENTS.CLEAR_HELPDESK_COMPONENT) {
          this.clearComponent();
        }

        // on tab change or click on ticket menu
        if (
          data.action === HELPDESK_APP_EVENTS.ON_TAB_CHANGE ||
          data.action === HELPDESK_APP_EVENTS.GET_TICKET_MENU
        ) {
          this.toggleTicketOnView(false).then(() => {
            this.clearComponent();
          });
        }
      }
    });

    const sub2 = this.router.events.subscribe(() => {
      console.log("ROUTE CHANGE");
      this.clearComponent();
      this.clearTicketComponent();

      // send to toast to cancel any actions of assign and claim
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_HELPDESK_ROUTE_CHANGE,
        data: {},
      });
    });

    this.subscriptionCollection.push(sub);
    this.subscriptionCollection.push(sub2);
  }

  private getTicketCount() {
    return new Promise((resolve, reject) => {
      this.helpDeskService.getTicketCount().subscribe((data) => {
        resolve(data);

        if (data) {
          this.ticketCounts = data;
        }
      });
    });
  }

  private onTabCloseTicket(id: any) {
    const index = this.listOfAllTicketViews.findIndex((tv) => tv.id === id);

    if (index !== -1) {
      const theTicketComponentRef =
        this.listOfAllTicketViews[index].componentRef;
      if (theTicketComponentRef) {
        theTicketComponentRef.destroy();
      }

      this.listOfAllTicketViews.splice(index, 1);
    }
  }

  private addTicketView(ticketView: any) {
    this.listOfAllTicketViews.push(ticketView);
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 500);
    });
  }

  private ticketViewExist(id: any) {
    const index = this.listOfAllTicketViews.findIndex(
      (ticketView) => ticketView.id === id
    );

    if (index !== -1) {
      return {
        ticketView: this.listOfAllTicketViews[index],
        index,
        found: true,
      };
    }

    return {
      ticketView: null,
      index,
      found: false,
    };
  }

  private toggleTicketOnView(isOn: boolean) {
    this.isTicketOnView = isOn;
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 600);
    });
  }

  // pass any data to insert into component
  private createComponent(createWhat: COMPONENT_CREATION, genericData: any) {
    let factory: ComponentFactory<any>;

    if (createWhat === COMPONENT_CREATION.HELPDESK_ASSIGNEE_SELECTION) {
      factory = this.resolver.resolveComponentFactory(
        HelpdeskUserSelectionComponent
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.genericData = genericData;
    }

    if (createWhat === COMPONENT_CREATION.HELPDESK_TOAST) {
      factory = this.resolver.resolveComponentFactory(HelpdeskToastComponent);
      this.componentRef = this.container.createComponent(factory);
      console.log(this.componentRef);
      this.componentRef.instance.genericData = genericData;
    }

    if (createWhat === COMPONENT_CREATION.TICKET_VIEW) {
      const exist = this.ticketViewExist(genericData.id).found;

      if (exist === false) {
        // other ticket view hide
        this.listOfAllTicketViews.forEach((tv) => (tv.hidden = true));

        // add ticket view
        this.addTicketView({
          id: genericData.id,
          hidden: false,
          componentRef: null,
        }).then(() => {
          setTimeout(() => {
            this.ticketDynamicContainer.toArray().map((vcr, index) => {
              if (vcr.length === 0) {
                factory =
                  this.resolver.resolveComponentFactory(ViewTicketComponent);
                const componentRef = vcr.createComponent(factory);
                componentRef.instance.genericData = genericData;

                this.listOfAllTicketViews[index].componentRef = componentRef;
              }
            });
          }, 500);
        });
      } else {
        // if already exist hide or show
        this.listOfAllTicketViews.map(
          (tv) => (tv.hidden = tv.id === genericData.id ? false : true)
        );
      }
    }
  }

  private clearComponent() {
    return new Promise<void>((resolve, reject) => {
      if (this.container) {
        this.container.clear();

        if (this.componentRef) {
          this.componentRef.destroy();
        }
      }

      resolve();
    });
  }

  private clearTicketComponent() {
    return new Promise<void>((resolve, reject) => {
      if (this.ticketDynamicContainer) {
        this.ticketDynamicContainer.forEach((vcr) => {
          if (vcr) {
            vcr.clear();
          }
        });
      }

      resolve();
    });
  }

  // does clean up
  private cleanUp(): void {
    this.clearComponent();
    this.clearTicketComponent();

    this.subscriptionCollection.forEach((subs) => {
      subs.unsubscribe();
    });

    this.subscriptionCollection = [];
  }
}
