import { Component, OnInit , Output, EventEmitter, Input, ChangeDetectorRef, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { AuthenticationService } from '../../../auth/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { DomSanitizer } from '@angular/platform-browser';
import { debug } from 'console';

@Component({
  selector: 'app-view-dynamic-form',
  templateUrl: './view-dynamic-form.component.html',
  styleUrls: ['./view-dynamic-form.component.css'],
  changeDetection : ChangeDetectionStrategy.OnPush,
})
export class ViewDynamicFormComponent implements OnInit {
  @Input() fields: any;
  @Input() data: any;

  modifiedField: any;
  dateFormat: any;
  timezone: any;
  dateTimeFormat: any;
  validateName: any;
  constructor( 
    private auth : AuthenticationService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {
   
   }

  ngOnInit() {
    let nullArray: any = []
    this.dateFormat = localStorage.getItem('date_format');
    this.dateTimeFormat = ' hh:mm a, ' + localStorage.getItem('date_format');
    this.timezone = this.auth.getUserTimezone();
    
    this.fields.forEach((element: any) => {
      if(element.fields){
        
        _.remove(element.fields, (n: any) => {
          return n['componentType'] == 'Hidden';
        });
       
          element.fields.forEach((column : any)=> {
           /*======================================= CheckBox : if true display yes else no============*/
            if(column.componentType == "CheckBox"){
                 column.value = column.value ? 'Yes':'No'
              }
 /*======================================= Lookup : display value base on displayColumn ..============*/
              if(column.componentType == "Lookup" || column.componentType== "Select"){
                if(column.displayColumn && column.value){
                  
                  const keys = Object.keys(column.value);
                  for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                        console.log(key,"==============",column.displayColumn,"-------",column.value[column.displayColumn]);
                        column.value.name = column.value[column.displayColumn] ? column.value[column.displayColumn]:column.value.name 
                       }
                     }

              }
              
              console.log("1",column)
              if(column.componentType == "Signature" && column.value){
                 column.value = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${column.value}`);
              }
              console.log("2",column)
              if(column.componentType == "PickList" && column.multiSelect){
                
                if(column.value){
                  var newOption: any []= []
                  column.options.forEach((elemenPT  : any)=> {
                      column.value.forEach((element  : any)=> {
                        if(elemenPT.value == element.value){
                          newOption.push(elemenPT)
                        }
                       })
                   })                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
                   column.value = newOption
                   if(column.displayColumn){
                      const keys = Object.keys(column.value);
                      for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                            console.log(key,"==============",column.displayColumn,"-------",column.value[column.displayColumn]);
                            column.value.label = column.value[column.displayColumn] ? column.value[column.displayColumn]:column.value.name 
                           }
                     }
                    }

              }
                  this.findObjectType(column)
              });
              if (element.fields.length %2 != 0){
                console.log( element.fields);
                element.fields.push(nullArray)
            }

            // console.log(element.fields.length,"=======>>>>>>>>>>>")

        }
    }); 
   

  }

  findObjectType(column: any){

    column.specialProviderSelected = 'String'

            if(column.componentType == 'DatePicker'){
              column.specialProviderSelected = 'DatePicker'
            }else if(column.componentType == 'DateTimePicker'){
              column.specialProviderSelected = 'DateTimePicker'

            }else if(column.componentType == 'PlaceAutoComplete'){
              column.specialProviderSelected = 'PlaceAutoComplete'

            }else{

              if(Array.isArray(column.value)){
                column.specialProviderSelected = 'Array'
              
              }else if((typeof (column.value))  === 'object') {
                column.specialProviderSelected = 'Object'
              }
            }

  }
  redirectPage(field: any,id: any){
    console.log(field,id)
  if(id){
    if (field.redirectPage === "jobOrder") {
      localStorage.setItem("activeIndex", "0");
      this.router.navigate(['dashboard/jobOrders/view/' + id], { queryParams: { jobOrderId: id } });
    }else if(field.redirectPage === "salesOrder") {
      localStorage.setItem("activeIndex", "0");
      this.router.navigate(['dashboard/salesOrders/'+id], { queryParams: {salesOrderId: id}});
    } else if (field.redirectPage === "customer"){
      localStorage.setItem("activeIndex", "0");
    this.router.navigate(['dashboard/customers/view/' + id], { queryParams: { id: id}});
    } else if (field.redirectPage === "user"){
      this.router.navigate(['/dashboard/setup/allUser/view'], { queryParams: { id: id,edit:false}});
    } else if (field.redirectPage === "assets"){
      localStorage.setItem("activeIndex", "0");
      this.router.navigate(['dashboard/assets/view/'+id], { queryParams: { id : id}});
    } else if (field.redirectPage === "inventoryBin" && field.userId){
      localStorage.setItem("activeIndex", "0");
      this.router.navigate(['dashboard/inventory/bin/view/'+id], { queryParams : {'id' : id, view: 'user'}});
    } else if (field.redirectPage === "inventoryBin" && !field.userId){
      localStorage.setItem("activeIndex", "0");
      this.router.navigate(['dashboard/inventory/bin/view/'+id], { queryParams : {'id' : id, view: 'location'}});
    } 

  }
}


public downloadAttachment(attachment: any,field: any,ind: any) {
  let fileName = "";
  let fileType = "";
  let url = "";
  try {
    fileName = attachment.fileName;
    if (fileName)
      fileType = (attachment.fileName as string)
        ? (attachment.fileName as string).split(".")[
            (attachment.fileName as string).split(".").length - 1
          ]
        : "";
    url = attachment.attachmentUrl;

    if (fileName && fileType) {
      FileSaver.saveAs(url, fileName);
    } else {
      throw new Error("Cannot find filename and filetype");
    }
  } catch (e) {
    url = attachment.attachmentUrl;
    fileName = (url as string)
      ? (url as string).split("/")[(url as string).split("/").length - 1]
      : "";
    fileType = (fileName as string)
      ? (fileName as string).split(".")[(url as string).split(".").length - 1]
      : "";

      FileSaver.saveAs(url, fileName);
  }
}
}
