import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../auth/authentication.service';
import { ApiService } from '../../_services/api.service';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import * as JsEncryptModule from 'jsencrypt';
import { PublicComponent } from '../public.component';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ProfileFeaturesService } from 'src/app/_services/profile-features.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { delay, catchError } from 'rxjs/operators';
import { forkJoin, of, throwError } from 'rxjs';
import { ErrorUtil } from 'src/app/_utilities/error';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  value: any;
  errorMessage!: string;
  login: any = {
    username: '',
    password: '',
    remember: false
  };
  permissions!: { role: any; roles: any[]; };
  submitting: boolean = false;
  hasWrongCredentials: boolean = false;
  loginForm: UntypedFormGroup;
  submitTouched: boolean = false;
  crypt: any = new JsEncryptModule.JSEncrypt();
  fieldTextType!: boolean;
  checkRemember!: boolean;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private perm: ProfileFeaturesService,
    private userService: UsersService,
    private _cookieService: CookieService,
    private spinner: NgxSpinnerService,
    private util: UtilServiceService,
    private errorUtil: ErrorUtil
  ) {
    // Regular expression to enforce strong passwords
    const StrongPasswordRegx: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

    // Initialize the login form with validators
    this.loginForm = new UntypedFormGroup({
      'username': new UntypedFormControl(null, [Validators.required]),
      'password': new UntypedFormControl(null, [Validators.required]),
      'remember': new UntypedFormControl(null)
    });

    // Set the public encryption key for JsEncrypt
    this.crypt.setPublicKey(PublicComponent.publicEncryptionKey);
  }

  ngOnInit() {
    this.spinner.hide(); // Hide the spinner on initialization

    // Check if "remember me" is enabled and populate the form if so
    if (this._cookieService.get(btoa('remember')) == btoa('true')) {
      this.login.username = atob(this._cookieService.get(btoa('username')));
      this.login.password = atob(this._cookieService.get(btoa('password')));
      this.login.remember = atob(this._cookieService.get(btoa('remember')));
    }
    this.checkRemember = this.login.remember ? true : false;
  }

  /******************************************** Sign In ******************************************/
  signIn(): any {
    this.spinner.show(); // Show the spinner during submission

    this.submitTouched = true; // Mark the form as touched
    this.hasWrongCredentials = false;

    // Validate the form
    if (!this.loginForm.valid) {
      for (var i in this.loginForm.controls) {
        this.loginForm.controls[i].markAsTouched();
      }
      this.spinner.hide();
      return false;
    }

    this.submitting = true;

    // Encrypt the username and password
    var encryptedUsername = this.crypt.encrypt(this.loginForm.value.username);
    var encryptedPassword = this.crypt.encrypt(this.loginForm.value.password);

    // Prepare the login data
    var data = {
      "password": this.login.password,
      "rememberMe": this.checkRemember,
      "username": this.login.username
    };

    this.login.remember = this.checkRemember ? true : false;

    // Store credentials in cookies if "remember me" is checked
    if (this.login.remember) {
      this._cookieService.set(btoa('username'), btoa(this.login.username));
      this._cookieService.set(btoa('password'), btoa(this.login.password));
      this._cookieService.set(btoa('remember'), btoa(this.login.remember));
    } else {
      this._cookieService.deleteAll();
    }

    // Authenticate the user
    this.authService.loginAdmin(data).subscribe((res: any) => {
      this.hasWrongCredentials = false;
      this.submitting = false;

      // Redirect based on screen size
      if (window.innerWidth < 720) {
        setTimeout(() => {
          this.router.navigate(['/mobile']);
          localStorage.clear();
        }, 1000);
      } else {
        this.getAccountInfo(); // Fetch account information after login
      }

    }, (err) => {
      this.handleError(err); // Handle authentication errors
    });
  }

  /******************************************** Validate Form Fields ******************************************/
  validate(field: any) {
    return this.loginForm.controls[field].invalid && (this.loginForm.controls[field].dirty || this.loginForm.controls[field].touched || this.submitTouched);
  }

  /******************************************** Get Account Information ******************************************/
  getAccountInfo() {
    try {
      this.apiService.getProfile().subscribe(profileResponse => {
        if (profileResponse) {
          var tenantId = 'CU-' + (profileResponse.tenantId + "").padStart(6, "0");

          var activereportToken = {
            "password": this.loginForm.value.password,
            "tenantName": tenantId,
            "username": this.loginForm.value.username
          };

          // Update user location
          this.authService.getTocketWYN(activereportToken).subscribe((res: any) => {
            // Handle response for user location update
          });

          if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
              this.userService.updateUserLocation(profileResponse.id, position.coords.latitude, position.coords.longitude).subscribe((res: any) => {
                // Handle user location update success
              });
            });
          }

          // Store user profile information in local storage
          this.storeProfileInfo(profileResponse);
        }
      }, (err) => {
        // Handle errors in fetching profile information
      });
    } catch (error) {
      console.error(error);
    }
  }

  /******************************************** Store Profile Information ******************************************/
  storeProfileInfo(profileResponse: any) {
    localStorage.setItem('lang', 'en');
    localStorage.setItem('profile_id', btoa(profileResponse.id));
    localStorage.setItem('user_name', btoa(profileResponse.firstName));
    if (profileResponse.licenceVM.trial) {
      localStorage.setItem('trialEndDate', profileResponse.licenceVM.validTill);
    }
    localStorage.setItem('qrCodeType', profileResponse.qrCodeType);
    this.util.setCompanyName(profileResponse.companyName);
    var profileInfo = {
      fullName: profileResponse.firstName + ' ' + profileResponse.lastName,
      email: profileResponse.email,
      tenantId: profileResponse.tenantId,
      firstName: profileResponse.firstName,
      tenantCategories: profileResponse.tenantCategories,
      createdDate: profileResponse.licenceVM.createdDate,
      renewalDate: profileResponse.licenceVM.validTill,
      trial: profileResponse.licenceVM.trial,
      login: profileResponse.login
    };
    localStorage.setItem('profile_info', btoa(JSON.stringify(profileInfo)));
    localStorage.setItem('date_format', profileResponse.dateFormatString);
    this.getProfileFeature(profileResponse);
  }

  /******************************************** Get Profile Features ******************************************/
  getProfileFeature(profileResponse: any) {
    try {
      this.perm.getProfileFeatures().subscribe(permissionResponse => {
        if (permissionResponse) {
          this.storePermissions(permissionResponse); // Store permissions and navigate based on them
          ///this.getUserProfile(permissionResponse, profileResponse);
          this.navigateBasedOnProfile(profileResponse, permissionResponse);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  /******************************************** Store Permissions and Navigate ******************************************/
  storePermissions(permissionResponse: any) {
    localStorage.setItem('role_token', btoa(permissionResponse.profile.name));
    this.permissions = { role: permissionResponse.profile.name, roles: [] };

    // Process module access features
    permissionResponse.moduleAccessFeatures.forEach((role: any) => {
      var tempRole = {
        moduleName: role.feature.name.toLowerCase(),
        read: role.readFeature,
        create: role.createFeature,
        update: role.updateFeature,
        remove: role.deleteFeature
      };
      this.permissions.roles.push(tempRole);
    });

    // // Process admin settings features
    // permissionResponse.adminSettingsFeatures.forEach((role2: any) => {
    //   var tempRole = {
    //     moduleName: role2.feature.name.toLowerCase(),
    //     read: role2.typeValue === 'YES',
    //     create: role2.typeValue === 'YES',
    //     update: role2.typeValue === 'YES',
    //     remove: role2.typeValue === 'YES'
    //   };

    //   // Special handling for "Manage ORG Detail" feature
    //   if (role2.feature.name === 'Manage ORG Detail') {
    //     var findSetup = _.filter(permissionResponse.moduleAccessFeatures, (o) => o.feature.name == 'setup');
    //     if (findSetup.length) {
    //       tempRole.read = tempRole.create = tempRole.update = tempRole.remove = false;
    //     }
    //   }

    //   this.permissions.roles.push(tempRole);
    // });

    // Handle additional profile permissions
    this.addAdditionalPermissions(permissionResponse);

    // Store menu permissions
    var menuPermissions = {
      defaultTenantCategory: permissionResponse.profile.defaultTenantCategory.toLowerCase(),
      sales: permissionResponse.profile.sales,
      service: permissionResponse.profile.service,
      fleet: permissionResponse.profile.fleet
    };
    localStorage.setItem('menuPermissions', JSON.stringify(menuPermissions));
    localStorage.setItem('permissions', JSON.stringify(this.permissions));
    localStorage.setItem('allowAssignShift', permissionResponse.profile.allowAssignShift);
    localStorage.setItem('allowFilterByHierarchy', permissionResponse.profile.allowFilterByHierarchy);
  }

  /******************************************** Add Additional Profile Permissions ******************************************/
  addAdditionalPermissions(permissionResponse: any) {
    permissionResponse.adminSettingsFeatures.forEach((role2: any )=> {
      if(role2.typeValue === 'YES'){
        var tempRole = {
          moduleName : role2.feature.name.toLowerCase(),
          read : true,
          create : true,
          update : true,
          remove : true
        }
      } else {
        if(role2.feature.name === 'Manage ORG Detail'){
          var findSetup = _.filter(permissionResponse.moduleAccessFeatures, function(o) { return o.feature.name == 'setup'; })
          if(findSetup.length){
            var tempRole = {
              moduleName : role2.feature.name.toLowerCase(),
              read : false,
              create : false,
              update : false,
              remove : false
            }
          } else {
            var tempRole = {
              moduleName : role2.feature.name.toLowerCase(),
              read : false,
              create : false,
              update : false,
              remove : false
            }
          }
        } else {
          var tempRole = {
            moduleName : role2.feature.name.toLowerCase(),
            read : false,
            create : false,
            update : false,
            remove : false
          }
        }
      }
      this.permissions.roles.push(tempRole);
    });

    if(permissionResponse.profile.allowAssignTask){
      var tempRole = {
        moduleName : 'allow assign task',
        read : true,
        create : true,
        update : true,
        remove : true
      }
    } else {
      var tempRole = {
        moduleName : 'allow assign task',
        read : false,
        create : false,
        update : false,
        remove : false
      }
    }
    this.permissions.roles.push(tempRole);

    if(permissionResponse.profile.binUser){
      var tempRole = {
        moduleName : 'bin user',
        read : true,
        create : true,
        update : true,
        remove : true
      }
    } else {
      var tempRole = {
        moduleName : 'bin user',
        read : false,
        create : false,
        update : false,
        remove : false
      }
    }


    if(permissionResponse.profile.helpDeskUser){
      var tempRole = {
        moduleName : 'helpdesk user',
        read : true,
        create : true,
        update : true,
        remove : true
      }
    } else {
      var tempRole = {
        moduleName : 'helpdesk user',
        read : false,
        create : false,
        update : false,
        remove : false
      }
    }

    this.permissions.roles.push(tempRole);

    if(permissionResponse.profile.allowViewSalesorderPrice){
      var tempRole = {
        moduleName : 'allow view sales order price',
        read : true,
        create : true,
        update : true,
        remove : true
      }
    } else {
      var tempRole = {
        moduleName : 'allow view sales order price',
        read : false,
        create : false,
        update : false,
        remove : false
      }
    }
    this.permissions.roles.push(tempRole);
  }

  /******************************************** Get User Profile ******************************************/
  getUserProfile(permissionResponse: any, profileResponse: any) {
    this.userService.getUserProfile(this.authService.getUserId()).subscribe((userProfileResponse: any) => {
      if (userProfileResponse) {
        this.storeUserProfile(userProfileResponse); // Store user profile details in local storage
        this.navigateBasedOnProfile(profileResponse, permissionResponse);
      }
    }, (err) => {
      // Handle errors in fetching user profile
    });
  }

  /******************************************** Store User Profile ******************************************/
  storeUserProfile(userProfileResponse: any) {
    var homeAddress = '';
    var officeAddress = '';

    // Construct home and office addresses
    for (let key in userProfileResponse) {
      if (key.startsWith('home') && userProfileResponse[key]) {
        homeAddress += userProfileResponse[key] + ', ';
      }
      if (key.startsWith('office') && userProfileResponse[key]) {
        officeAddress += userProfileResponse[key] + ', ';
      }
    }

    // Store the addresses in local storage
    if (homeAddress) {
      var homeAdd = { address: homeAddress, placeId: userProfileResponse.homePlaceId, lat: userProfileResponse.homeLatitude, lng: userProfileResponse.homeLongitude };
      localStorage.setItem('home_address', JSON.stringify(homeAdd));
    }
    if (officeAddress) {
      var officeAdd = { address: officeAddress, placeId: userProfileResponse.officePlaceId, lat: userProfileResponse.officeLatitude, lng: userProfileResponse.officeLongitude };
      localStorage.setItem('office_address', JSON.stringify(officeAdd));
    }

    localStorage.setItem('buffer', JSON.stringify({ start: userProfileResponse.startBuffer, end: userProfileResponse.endBuffer }));
    localStorage.setItem('timezone', userProfileResponse.timezone);
    localStorage.setItem('shiftWorking', userProfileResponse.shiftWorking);
  }

  /******************************************** Navigate Based on Profile ******************************************/
  navigateBasedOnProfile(profileResponse: any, permissionResponse: any) {
    setTimeout(() => {
      if (profileResponse.firstTimeLogin) {
        this.router.navigate(['/profile-info']);
      } else {
        if (permissionResponse.profile.defaultTenantCategory.toLowerCase() === 'fleet') {
          this.router.navigate(['/dashboard/homeFleet']);
        } else {
          this.router.navigate(['/dashboard/home']);
        }
      }
      this.spinner.hide();
    }, 1000);
  }

  /******************************************** Handle Login Errors ******************************************/
  handleError(err: any) {
    var body = err.error;
    if (body.status === 403) {
      if (err.error.title) {
        this.errorUtil.setErrorMessage(0, err.error.title, null, 'error');
      } else {
        this.router.navigate(['/download-app']);
      }
    } else {
      this.processLoginError(body);
    }
    this.spinner.hide();
  }

  /******************************************** Process Login Errors ******************************************/
  processLoginError(body: any) {
    if (body.detail === 'Bad credentials') {
      this.errorMessage = "Invalid credentials. Please try again";
    } else if (body.message === 'error.validation') {
      this.errorMessage = "Please ensure you have no blank spaces before or after your username";
    } else {
      this.errorMessage = body.detail;
    }
    this.errorUtil.setErrorMessage(0, this.errorMessage, null, 'error');
    this.hasWrongCredentials = true;
  }

  /******************************************** Toggle Password Visibility ******************************************/
  showPassword() {
    this.fieldTextType = !this.fieldTextType;
  }

}
