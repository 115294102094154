import { of as observableOf, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "../auth/authentication.service";
import { RequestOptions, Http, Headers } from "@angular/http";

@Injectable()
export class VehicleService {
  public refresher$: EventEmitter<any>;
  public refresherTrailer$: EventEmitter<any>;
  public refresherVLL$: EventEmitter<any>;
  private ENDPOINT_CREATE_VEHICLE_TYPES = "/vehicle-types";
  private ENDPOINT_VEHICLE_BY_ID = "/vehicles/";
  private ENDPOINT_VEHICLES_LIST = "/vehicles";
  private ENDPOINT_CREATE_VEHICLE = "/vehicles";
  private ENDPOINT_UPLOAD_VEHICLE_ATTACHMENTS =
    "/attachments/upload?parentType=VEHICLE_PROFILE";
  private ENDPOINT_ASSIGN_VEHICLE_TO_DRIVER = "/vehicles/add/drivers";
  private ENDPOINT_VEHICLE_HISTORY_BY_ID = "/vehicle-histories/by-vehicle/";

  private ENDPOINT_CREATE_TRAILERS = "/trailers";
  private ENDPOINT_TRAILER_BY_ID = "/trailers/";

  private ENDPOINT_VEHICLE_TYPES_BY_ID = "/vehicle-types/";
  //routes
  private ENDPOINT_VEHICLE_ROUTES = "/fleet-draft-route-plans/by-vehicle";
  private ENDPOINT_VEHICLE_ROUTE_BY_ID =
    "/fleet-draft-route-plans/vehicle-trip";
  private ENDPOINT_VEHICLE_TRIP_TABLE_BY_ID =
    "/fleet-draft-route-plans/vehicle-trip/table";
  private ENDPOINT_UPLOAD_VEHICLE_FUEL_ATTACHMENTS =
    "/attachments/upload?parentType=VEHICLE_FUEL_RECIPT";
  private ENDPOINT_VEHICLE_FUEL_BY_ID = "/vehicle-fuel-histories/";

  //logs
  private ENDPOINT_DRIVER_HISTORY_LOG_BY_ID = "/driver-histories/by-driver/";
  private ENDPOINT_VEHICLE_HISTORY_LOG_BY_ID = "/vehicle-histories/by-vehicle/";

  // Vehicle Group
  private ENDPOINT_CREATE_VEHICLE_GROUP = "/vehicle-type-groups";
  private ENDPOINT_VEHICLE_GROUP_BY_ID = "/vehicle-type-groups/";

  private ENDPOINT_DELETE_ATTACHMENTS = "/attachments/";

  //vehicle checklist
  private ENDPOINT_VEHICLE_CHECKLIST = "/vehicle-checklists";
  private ENDPOINT_VEHICLE_CHECKLIST_BY_ID = "/vehicle-checklists/";

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http
  ) {
    this.refresher$ = new EventEmitter();
    this.refresherTrailer$ = new EventEmitter();
    this.refresherVLL$ = new EventEmitter();
  }

  public getVehicleStatus() {
    return [
      {
        id: null,
        name: "All",
        value: null,
      },
      {
        id: 1,
        name: "Enroute",
        value: "ENROUTE",
      },
      {
        id: 2,
        name: "Depot",
        value: "DEPOT",
      },
      {
        id: 3,
        name: "Maintenance",
        value: "MAINTENANCE",
      },
    ];
  }

  public getVehicleById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public createNewVehicleType(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_VEHICLE_TYPES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public updateVehicle(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_VEHICLE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public createNewVehicle(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_VEHICLE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicle() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLES_LIST)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getVehicaleType(VehicleCategories: any) {
    let params = new HttpParams();
    if (VehicleCategories !== "") {
      params = params.set("categoryId", VehicleCategories);
    }
    return this.http
      .get(environment.base_url + "/vehicle-types", { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public deleteVehicle(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_VEHICLE_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit("Deleted Vehicle");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehiclesList(
    vehicleTypeId: any,
    searchValue: any,
    status: any,
    subContractorId: any,
    parameters: any,
    notInVehicleIds: any,
    active: any,
    origin: any
  ) {
    let params = new HttpParams();
    if (vehicleTypeId !== "" && vehicleTypeId !== null) {
      params = params.set("vehicleTypeId", vehicleTypeId);
    }
    
    if (status !== "" && status !== null) {
      params = params.set("vehicleStatus", status);
    }
    if (notInVehicleIds !== "" && notInVehicleIds !== null) {
      params = params.set("notInVehicleIds", notInVehicleIds);
    }
    if (active !== "" && active !== null) {
      params = params.set("active", active);
    }

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    if (subContractorId) {
      params = params.set("subContractorId", subContractorId);
    }

    if (origin && origin !== "" && origin !== null) {
      params = params.set("origin", origin);
    }

    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/vehicles`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getVehicleCategories() {
    return this.http.get(environment.base_url + "/vehicle-categories").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

  getVehicleBrands() {
    return this.http.get(environment.base_url + "/vehicle-brands").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }
  getVehicleModels() {
    return this.http.get(environment.base_url + "/vehicle-models").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

  getVehicleWeightUnit() {
    return this.http
      .get(environment.base_url + "/package/weight-unit/options")
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getVehicleLengthUnit() {
    return this.http
      .get(environment.base_url + "/package/length-unit/options")
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getSubContractors() {
    return this.http.get(environment.base_url + "/sub-contractors").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }
  getDriversListInsideFuel() {
    return this.http
      .get(environment.base_url + "/drivers/pick-list?active=true")
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
  getDriversPickList() {
    return this.http
      .get(
        environment.base_url + "/drivers/pick-list?active=true&unAssigned=true"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getDriversList() {
    return this.http
      .get(
        environment.base_url + "/drivers/pick-list?active=true&unAssigned=true"
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  getDepots() {
    return this.http.get(environment.base_url + "/depots").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

  getTrailers() {
    return this.http.get(environment.base_url + "/trailers/all").pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

  public uploadVehicleProfile(formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_VEHICLE_ATTACHMENTS,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public assignVehicleToDriver(body: any) {
    return this.http
      .post(
        environment.base_url + this.ENDPOINT_ASSIGN_VEHICLE_TO_DRIVER,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public deleteVehicleToDriver(data: any) {
    return this.http
      .put(environment.base_url + "/vehicles/remove/drivers?" + data, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit();
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicleHistoryById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_HISTORY_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicleFuelHistoryById(vehicleId: any, parameters: any) {
    let params = new HttpParams();

    params = params.set("vehicleId", vehicleId);

    console.log(parameters);
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/vehicle-fuel-histories/paged`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /* ======================================================= Routes Section ===================================================================*/

  //routes grid
  // public getAllVehicleRoutes(startFrom,perPage,columnName,sortOrder,vehicleId,driverId){
  public getAllVehicleRoutes(vehicleId: any, driverId: any) {
    let params = new HttpParams();
    // if(startFrom)
    //   params = params.set('startFrom', startFrom);
    // if(perPage)
    //   params = params.set('perPage', perPage);
    if (vehicleId) params = params.set("vehicleId", vehicleId);
    if (driverId) params = params.set("driverId", driverId);
    // if(columnName)
    //   params = params.set('columnName', columnName);
    // if(sortOrder)
    //   params = params.set('sortOrder', sortOrder);
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_ROUTES, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicleRouteById(routeId: any) {
    let params = new HttpParams();
    if (routeId) params = params.set("routeId", routeId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_ROUTE_BY_ID, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicleRouteTableById(routeId: any) {
    let params = new HttpParams();
    if (routeId) params = params.set("routeId", routeId);
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_TRIP_TABLE_BY_ID, {
        observe: "response",
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /* =======================================================Driver Logs Section ===================================================================
============================================================================================================================================== */

  public getAllDriverLogs(driverId: any) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_DRIVER_HISTORY_LOG_BY_ID +
          driverId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /* =======================================================Vehicle Logs Section ===================================================================
============================================================================================================================================== */

  public getAllVehicleLogs(vehicleId: any) {
    return this.http
      .get(
        environment.base_url +
          this.ENDPOINT_VEHICLE_HISTORY_LOG_BY_ID +
          vehicleId,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          if (resp.status == 204) {
            return false;
          } else {
            return resp.body;
          }
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /* ======================================================= Trailer Section ===================================================================
============================================================================================================================================== */

  public getTrailerList(searchValue: any, status: any, parameters: any) {
    let params = new HttpParams();

    console.log(parameters);

    // if(vehicleTypeId !== ''){
    //   params = params.set('vehicleTypeId', vehicleTypeId);
    // }

    console.log(status);

    if (status !== "" && status !== null) {
      params = params.set("trailerStatus", status);
    }

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }
    console.log(parameters);
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/trailers`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public createNewTrailter(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_TRAILERS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTrailer$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public updateTrailer(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_TRAILERS, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTrailer$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public deleteTrailere(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_TRAILER_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherTrailer$.emit("Deleted Trailer");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public getTrailerById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_TRAILER_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /* ======================================================= Vehicle Type Section ===================================================================
  ============================================================================================================================================== */

  public getVehicleTypeList(searchValue: any, parameters: any) {
    let params = new HttpParams();

    if (
      searchValue !== "" &&
      searchValue != null &&
      searchValue !== undefined
    ) {
      params = params.set("search", searchValue);
    }
    if (parameters)
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/vehicle-types/paged`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public updateVehicleType(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_VEHICLE_TYPES, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public deleteVehicleType(id: any) {
    return this.http
      .delete(environment.base_url + "/vehicle-types/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit("Deleted Trailer");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicleTypeById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_TYPES_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehiclersListForDO(
    vehicleTypeId: any,
    searchValue: any,
    status: any,
    deliveryOrderId: any,
    planStartTime: any,
    planEndTime: any,
    ignoreVehicleId: any
  ) {
    let params = new HttpParams();

    if (vehicleTypeId !== "") {
      params = params.set("vehicleTypeId", vehicleTypeId);
    }
    // this os for remove order from DO and planning hub
    if (ignoreVehicleId !== "") {
      params = params.set(
        "ignoreVehicleId",
        ignoreVehicleId
      ); /* we want for boh option NEW_DRIVER_DIRECT_ASSIGN,REMOVE_ORDER_FROM_DRIVER_AND_OPTIMIZE_FOR_DRIVER_DISTANCE_METRIC,*/
    }

    /*=================this only for  NEW_DRIVER_DIRECT_ASSIGN============= */
    if (planStartTime !== "" && planEndTime !== "") {
      params = params.set("planStartTime", planStartTime);
      params = params.set("planEndTime", planEndTime);
    }
    /*/////////////////////////////////////////*/
    params = params.set("active", "true");
    if (status !== "") {
      params = params.set("vehicleStatus", status);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    params = params.set("includeUtilization", "true");
    params = params.set("forPlaningHub", "true");
    params = params.set("deliveryOrderId", deliveryOrderId);
    params = params.set("driverRequired", "true"); //hide inactive or suspended driver

    params = params
      .set("startFrom", "0")
      .set("perPage", "10")
      .set("sortOrder", "asc")
      .set("columnName", "vehicleNumber");

    return this.http
      .get(`${environment.base_url}/vehicles`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public uploadFuelAttachment(formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_VEHICLE_FUEL_ATTACHMENTS,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public createVehicleFuel(body: any) {
    return this.http
      .post(environment.base_url + "/vehicle-fuel-histories", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public updateVehicleFuel(body: any) {
    return this.http
      .put(environment.base_url + "/vehicle-fuel-histories", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public deleteFuel(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_VEHICLE_FUEL_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit("Deleted Fuel");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public getFuelById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_FUEL_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
  public getActivityList(
    driverId: any,
    vehicleId: any,
    searchValue: any,
    parameters: any
  ) {
    let params = new HttpParams();

    if (vehicleId !== "") {
      params = params.set("vehicleId", vehicleId);
    }
    if (driverId !== "") {
      params = params.set("driverId", driverId);
    }
    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/driver-vehicle-logs/paged`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public getTrailersHistoryList(
    trailerId: any,
    searchValue: any,
    parameters: any
  ) {
    let params = new HttpParams();

    params = params.set("trailerId", trailerId);

    if (searchValue !== "" && searchValue != null) {
      params = params.set("search", searchValue);
    }

    params = params
      .set("startFrom", parameters.startRow)
      .set("perPage", "25")
      .set("columnName", parameters.sortModel[0].colId)
      .set("sortOrder", parameters.sortModel[0].sort);
    return this.http
      .get(`${environment.base_url}/trailer-histories/filter`, {
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /**=========================== Vehicle Group =============================================================
   * ========================================================================================================= */

  public getVehicleGroupList() {
    let params = new HttpParams();

    return this.http
      .get(`${environment.base_url}/vehicle-type-groups`, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public createNewVehicleGroup(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_CREATE_VEHICLE_GROUP, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public updateVehicleGroup(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_CREATE_VEHICLE_GROUP, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit(body);
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public deleteVehicleGroup(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_VEHICLE_GROUP_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresher$.emit("Deleted Vehicle group");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  /**=========================== Vehicle LOCATION =============================================================
   * ========================================================================================================= */

  public getVehicleLocation(timeZone: any, searchDate: any) {
    let params = new HttpParams();

    return this.http
      .get(
        environment.base_url +
          "/vehicle-locations/search?timeZone=" +
          timeZone +
          "&date=" +
          searchDate
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public getVehicleLocationList(timeZone: any, searchDate: any) {
    let params = new HttpParams();

    return this.http
      .get(
        environment.base_url +
          "/vehicle-locations/by-date?timeZone=" +
          timeZone +
          "&date=" +
          searchDate
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
  public createVehicleLocation(body: any) {
    return this.http
      .post(environment.base_url + "/vehicle-locations/all", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherVLL$.emit(body);
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  public deleteVehicleLocation(id: any) {
    return this.http
      .delete(environment.base_url + "/vehicle-locations/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refresherVLL$.emit("Deleted Last Location");
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public deleteAttachment(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_ATTACHMENTS + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  public getTrailerStatus() {
    var status = [
      {
        id: 1,
        name: "Enroute",
        value: "ENROUTE",
      },
      {
        id: 2,
        name: "Depot",
        value: "DEPOT",
      },
      {
        id: 3,
        name: "Maintenance",
        value: "MAINTENANCE",
      },
    ];
    return status;
  }

  // -------------------------------------------Vehicle checklist-----------------------------------

  public getAllVehicleChecklist() {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_CHECKLIST, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  //edit data call
  public getCheckListById(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_VEHICLE_CHECKLIST_BY_ID + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  //add save
  public createCheckList(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_VEHICLE_CHECKLIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  //edit save
  public editCheckList(body: any) {
    return this.http
      .put(environment.base_url + this.ENDPOINT_VEHICLE_CHECKLIST, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }

  //delete
  public deleteCheckList(id: any) {
    return this.http
      .delete(
        environment.base_url + this.ENDPOINT_VEHICLE_CHECKLIST_BY_ID + id,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          //console.log(error);
          return observableOf(error);
        })
      );
  }
}
