<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <span class="text-20 font-semiBold ms-3">Availability</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div> 
<div class="modal-body tix">
    <div class="d-flex align-items-center rounded-2 custella-form-container">
        <div class="w-60 p-3">
            <span class="text-20 font-medium">Task 532525</span>
            <div class="d-flex align-items-center mt-1">
                <span class="d-flex align-items-center color-var-500 me-2">
                    <i class="pi pi-calendar"></i>
                    <span class="text-12 ms-1">17/05/2022</span>
                </span>
                <span class="d-flex align-items-center color-var-500 me-2">
                    <i class="pi pi-clock"></i>
                    <span class="text-12 ms-1">9:45AM - 11:45AM</span>
                </span>
            </div>
            <span class="d-flex align-items-center color-var-500 mt-1">
                <i class="pi pi-map-marker"></i>
                <span class="text-12 ms-1">Ten Kinrara, Jalan BK 5a/3, Bandar Kinrara, 47180 Puchong, Selangor, Malaysia</span>
            </span>
        </div>
        <div class="w-40 p-3 bg-white2">
            <p-dropdown optionLabel="name" placeholder="Team"></p-dropdown>
            <span class="d-flex align-items-center font-black m-1">
                <span>&#x2022;</span>
                <span class="text-12 font-medium d-block" translate>{{'accept_or_decline_task'}}</span>
            </span>
            <span class="d-flex align-items-center font-black m-1">
                <span>&#x2022;</span>
                <span class="text-12 font-medium d-block" translate>{{'check_in_or_out'}}</span>
            </span>
        </div>
    </div>

    <div class="my-2 ms-3 rounded-2">
        <div class="custella-form-container height-80 overflow-scroll position-relative d-block rounded-2 ms-2">
            <p-table [value]="files" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr class="">
                        <th class="col-2">Name</th>
                        <th class="col-2 text-center">Category</th>
                        <th class="col-2 text-center">Model</th>
                        <th class="col-2 text-center">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-files>
                    <tr class="text-12 font-medium">
                        <td>{{files.name}}</td>
                        <td class="col-2 text-center">{{files.category}}</td>
                        <td class="col-2 text-center">{{files.model}}</td>
                        <td class="col-2 text-center">
                            <button class="custella-del-button px-2 ms-1">
                                 <i class="fa-regular fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    
    <div class="custella-form-container-footer-float mt-4 mx-4 flex-between">
        <div class="d-flex align-items-center">
            <p-avatarGroup styleClass="p-mb-3">
                <p-avatar image="assets/images/profile-pic.png" shape="circle"></p-avatar>
                <p-avatar image="assets/images/profile-pic.png" shape="circle"></p-avatar>
                <p-avatar label="2+" shape="circle" styleClass="font-white bg-grey2"></p-avatar>
            </p-avatarGroup>
            <span class="text-12 mx-2">4 selected</span>
        </div>

        <div class="d-flex align-items-center justify-content-end">
            <button class="custella-btn-white text-12 mx-2">{{'cancel' | translate}}</button>
            <p-button label="Assign" styleClass="mx-2"></p-button>
        </div>

    </div>
</div>