<!-- Bootstrap Modal -->
<div class="custella-modal">
    <form [formGroup]="taskForm">
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_SetIqcTask">Task List</span>
            <i class="pi pi-times" (click)="modalRef.hide()" id="btn_SetIqcTaskClose"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container height-80 overflow-scroll w-100 rounded-2 m-0">
                <!-- Container Content -->
                <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center">
                    <span class="text-14 font-medium" id="title_SetIqcTaskSelFilter" translate>{{'select_filter'}}</span>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" id="label_SetIqcTaskEquip" translate>{{'equipment_type'}}</span>
                            <p-dropdown [options]="equipmentList" optionLabel="name" id="l-text" #myDropDown id="picklist_SetIqcTaskEquipField"
                                optionValue="_id" formControlName="equipment" name="equipment" filterBy="name"
                                [showClear]="true" [(ngModel)]="filter.equipmentType"
                                placeholder="Select Equipment Type">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list" id="value_SetIqcTaskEquipField">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>

                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" id="label_SetIqcTaskType" translate>{{'task_type'}}</span>
                            <p-dropdown [options]="taskTypes" optionLabel="label" id="l-text" #myDropDown id="picklist_SetIqcTaskTypeField"
                                optionValue="name" formControlName="taskType" name="taskType" filterBy="name"
                                [showClear]="true" [(ngModel)]="filter.taskType" placeholder="Select Task Type">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list" id="value_SetIqcTaskTypeField">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" id="label_SetIqcTaskLoc" translate>{{'location'}}</span>
                            <p-dropdown [options]="locations" optionLabel="fieldLabel" id="l-text" #myDropDown id="picklist_SetIqcTaskLocField"
                                optionValue="name" formControlName="location" name="location" filterBy="name"
                                [showClear]="true" [(ngModel)]="filter.location"
                                placeholder="{{ 'select_location' | translate }}">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list" id="value_SetIqcTaskLocField">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>

                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" id="label_SetIqcTaskMakeModel" translate>{{'make_model'}}</span>
                            <p-dropdown [options]="makeList" optionLabel="makeModel" id="l-text" #myDropDown id="picklist_SetIqcTaskMakeModelField"
                                optionValue="_id" formControlName="makeModel" name="makeModel" filterBy="makeModel"
                                [showClear]="true" [(ngModel)]="filter.makeModel" placeholder="Select Make Model">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list" id="value_SetIqcTaskMakeModelField">
                                        <div>{{ user.makeModel }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" id="label_SetIqcTaskManu" translate>{{'manufacturer'}}</span>
                            <p-dropdown [options]="manufacturerList" optionLabel="manufacturer" id="l-text" #myDropDown id="picklist_SetIqcTaskManuField"
                                optionValue="_id" formControlName="manufacturer" name="manufacturer" filterBy="name"
                                [showClear]="true" [(ngModel)]="filter.manufacturer" placeholder="Select Manufacturer">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list" id="value_SetIqcTaskManuField">
                                        <div>{{ user.manufacturer }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>

                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" id="label_SetIqcTaskDept" translate>{{'department'}}</span>
                            <p-dropdown [options]="departmentList" optionLabel="name" id="l-text" #myDropDown id="picklist_SetIqcTaskDeptField"
                                optionValue="_id" formControlName="departmentId" name="departmentId" filterBy="name"
                                [showClear]="true" [(ngModel)]="filter.departmentId"
                                placeholder="{{ 'select_department' | translate }}">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list" id="value_SetIqcTaskDeptField">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-footer mb-0">
                    <div class="d-flex align-items-center justify-content-end mb-0">
                        <button class="custella-btn-white text-12 mx-2" pButton pRipple class="p-button-secondary" id="btn_SetIqcTaskReset"
                            (click)="resetSearch()" label="Reset" style="margin-right: 7px;"></button>
                        <p-button pRipple (click)="search()" label="Search" id="btn_SetIqcTaskSearch"> </p-button>
                    </div>
                </div>
                <!-- Customer Information -->
                <div class="custella-form-container-title-two" id="label_SetIqcTaskList" translate>{{'task_list_details'}}</div>
                <div class="custella-form-container-content">
                    <div *ngIf="taskTrigerList" class="custella-content-row mb-1 pe-0">
                        <div class="custella-content-row-input col-12">
                            <span class="p-input-icon-left w-100"
                                [ngClass]="searchValue?.length>0 ? 'p-input-icon-right':''">
                                <i class="pi pi-search me-3" *ngIf="taskTrigerList"></i>
                                <input type="text" pInputText autocomplete="off" placeholder="Search" (keyup)="filterResults($event)" id="input_SetIqcTaskListSearchField"
                                    *ngIf="taskTrigerList" [(ngModel)]="searchValue" class="w-100"
                                    formControlName="searchValue" name="searchValue" />
                                <i class="pi pi-times-circle" (click)="onSearchClear()" id="btn_SetIqcTaskListSearchClear"
                                    *ngIf="searchValue?.length>0"></i>
                            </span>
                        </div>
                    </div>
                    <!-- ag-grid -->
                    <div class="custella-form-container-content-table">
                        <div class="custella-content-row mt-0 pe-0">
                            <div class="custella-content-row-input col-12">
                                <div style="padding-top: 0px;margin-top: 5px; height: 300px;width: 100%;">
                                    <ag-grid-angular #agGrid id="myGrid" style="height: 100%;width:100%"
                                        class="ag-theme-balham" [pagination]=true [paginationPageSize]="25"
                                        [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                                        [defaultColDef]="defaultColDef" [suppressRowClickSelection]="true"
                                        [rowSelection]="rowSelection" (rowSelected)="onSelectionChanged($event)"
                                        [rowData]="taskTrigerList" (gridReady)="onGridReady($event)"
                                        [overlayNoRowsTemplate]="overlayNoRowsTemplate"></ag-grid-angular>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Container Footer -->
                <div class="custella-form-container-footer pb-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_SetIqcTaskCancel" (click)="goBack()">{{'cancel' | translate}}</button>
                        <p-button [label]="buttonLabel" (click)="addTask()" id="btn_SetIqcTaskSubmit" styleClass="ms-2 h-32"></p-button>
                    </div>
                </div>
            </div>

        </div>
    </form>
</div>