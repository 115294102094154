import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../auth/authentication.service';
import { PermissionService } from '../auth/permission.service';

@Injectable()
export class MenuService {

  private menuSource = new Subject<any>();
  menuSourceHandler = this.menuSource.asObservable();

  private resetSource = new Subject();
  resetSource$ = this.resetSource.asObservable();

  rootLevelNodes: any;
  company!: boolean;
  users!: boolean;
  // profiles!: boolean;
  // roles!: boolean;
  billing!: boolean;
  licenses!: boolean;
  // manageTickets!: boolean;
  // manageInventory!: boolean;
  manageImportExport!: boolean;
  // manageNotification!: boolean;
  // manageGeofence!: boolean;
  salePer: boolean = false;
  servicePer: boolean = false;
  fleetPer: boolean = false;
  addonFeature: any;
  // drivers!: boolean;
  menuType: any;
  // fleetSetup!: boolean;
  assets!: boolean;
  jobs!: boolean;
  tickets!: boolean;
  packages!: boolean;
  vehicles!: boolean;
  module!: boolean;
  workflow!: boolean;
  menuRename:any ={
    site:"Site",
    asset:"Asset",
    customer:"Customer"
  }
  calendlyIntegration!: boolean;
  customerSurveyLink!: boolean;
  teams!: boolean;
  site!: boolean;
  project!: boolean;

  constructor(private perm: PermissionService,
    private auth: AuthenticationService) { }

  onMenuStateChange(items: any) {
    this.menuSource.next(items);
  }

  reset() {
    this.resetSource.next();
  }
  initPermission() {
    this.company = this.perm.capable('manage org detail', 'read');
    // this.profiles = this.perm.capable('manage access', 'read');
    // this.roles = this.perm.capable('manage hierarchy', 'read');
    this.billing = this.perm.capable('manage billing', 'read');
    this.licenses = this.perm.capable('manage licenses', 'read');
    // this.manageTickets = this.perm.capable('manage tickets', 'read');
    // this.manageInventory = this.perm.capable('manage inventory', 'read');
    this.manageImportExport = this.perm.capable('manage import & export', 'read');
    // this.manageGeofence = this.perm.capable('manage geo fence', 'read');
    // this.manageNotification = this.perm.capable('manage notification', 'read');
    // this.drivers = this.perm.capable('drivers', 'read');
    // this.fleetSetup = this.perm.capable('fleet setup', 'read');
    this.module = this.perm.capable('manage module', 'read');
    this.workflow = this.perm.capable('manage workflow', 'read');

    this.calendlyIntegration = this.perm.capable('manage integrations', 'read');

    this.customerSurveyLink = this.perm.capable('manage customer surveys', 'read');



    this.assets = this.perm.capable('assets', 'read');
    this.jobs = this.perm.capable('job order', 'read');
    this.tickets = this.perm.capable('tickets', 'read');

    this.packages = this.perm.capable('packages', 'read');

    this.users = this.perm.capable("manage users", "read");
    this.teams = this.perm.capable('teams', 'read');

    var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.addonFeature = this.auth.getAddonFeatureLists();
    this.salePer = this.findMenuPermissionsSALES(profileInfo.tenantCategories);
    this.servicePer = this.findMenuPermissionsSERVICE(profileInfo.tenantCategories);
    this.fleetPer = this.findMenuPermissionsFLEET(profileInfo.tenantCategories);

    this.site = this.perm.capable("site", "read");
    this.project = this.perm.capable("project", "read");

    var menuPerm: any = localStorage.getItem('menuPermissions')
    var menuPermissions = JSON.parse(menuPerm);
    this.menuType = sessionStorage.getItem('menuType') ? sessionStorage.getItem('menuType') : menuPermissions.defaultTenantCategory;
  }

  getMenuNode() {
    this.initPermission()
    
    console.log(this.addonFeature)
    if(this.addonFeature.addonMS == 'MACHINE_SPECIALIST'){
      this.menuRename ={
        site:"Estate",
        asset:"Machine",
        customer:"SOU"
      }
    }


  
    this.rootLevelNodes = [
      {
        name: "General", value: "general", hide: !this.company, data: [
          { name: "Organization Details", value: "organisationInformation", routerLink: "generalSettings" },
          // { name: "Default Formats", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Licenses and Billing", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Working Hours", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      },
      {
        name: "Users", value: "users", hide: !this.users, data: [
          { name: "Access", value: "access", hide: !this.users, routerLink: "profiles" },
          { name: "Drivers", value: "driver", hide: this.users && this.menuType == 'fleet' ? false : true, routerLink: "drivers" },
          { name: "Hierarchy", value: "Hierarchy", hide: !this.users, routerLink: "hierarchy" },
          { name: "Skills", value: "skills", hide: !this.users || !(this.menuType == 'sales' || this.menuType == 'service'), routerLink: "skills" },
          { name: "Teams", value: "teams", hide: !this.users, routerLink: "teams" },
          { name: "Users", value: "user", hide: !this.users, routerLink: "allUser" },
          { name: "User Groups", value: "userGroups", hide: !this.users || !(this.menuType == 'sales' || this.menuType == 'service'), routerLink: "groups" },
        ]
      },
      {
        name: "Modules", value: "module", hide: !this.module, data: [
          // { name: "Accessories", value: "accessories", hide: !this.profiles, routerLink: "dashboard/setup/profiles" },
          {
            name: this.menuRename.asset, value: "assets", hide: this.module && this.addonFeature.addonINVENTORY == 'INVENTORY' && (this.menuType == 'sales' || this.menuType == 'service') ? false : true, data: [
              { name: "Configuration", value: "assetDetails", routerLink: "asset/details" },
              { name: "Picklists", value: "assetFields", routerLink: "asset/picklist" },
              { name: "List View", value: "assetListView", routerLink: "asset/listView" },
            ]
          },
          { name: "Contract", value: "contracts", data: [
              { name: "List View", value: "contractsListView", routerLink: "contracts/listView" },
            ], hide: this.module && this.addonFeature.addonINVENTORY == 'INVENTORY' && (this.menuType == 'sales' || this.menuType == 'service') ? false : true
          },
          {
            name: this.menuRename.customer, value: "customer", data: [
              { name: "Configuration", value: "customerConfiguration", routerLink: "customer/config" },
              { name: "Picklists", value: "customerPicklist", routerLink: "customer/picklist" },
              // { name: "Sector Mapping", value: "customerSectMap", routerLink: "customer/mapping" },
              { name: "List View", value: "customerListView", routerLink: "customer/listView" },
            ]
          },
          {
            name: "Delivery Order", value: "deliveryOrder", hide: this.module && this.menuType == 'fleet' ? false : true, data: [
              { name: "Configuration", value: "deliveryOrderDetails", routerLink: "deliveryOrders/details" },
              { name: "Picklists", value: "deliveryOrderPicklist", routerLink: "deliveryOrders/picklist" },
              { name: "Default Loading Time", value: "deliveryOrderDefaultLoadingtime", routerLink: "deliveryOrders/loadingTime" },
              { name: "Verification Process", value: "deliveryOrderVerificationprocess", routerLink: "deliveryOrders/verification" },
              { name: "Validation Rules", value: "deliveryOrderValidationRules", routerLink: "deliveryOrders/validation" },
              { name: "Commission", value: "deliveryOrderCommission", routerLink: "deliveryOrders/commission" },

            ]
          },
          { name: "Depots", value: "depot", data: [], routerLink: "fleetSetup/depots", hide: this.module && this.menuType == 'fleet' ? false : true },
          { name: "Fleet Configuration", value: "fleetConfit", data: [], routerLink: "fleetSetup/fleetConfig", hide: this.module && this.menuType == 'fleet' ? false : true },
          {
            name: "General", value: "generalServiceZone", data: [
              { name: "Picklist", value: "picklist", routerLink: "general/picklist" },
            ]
          },
          { name: "Geofence", value: "geofencing", data: [], hide: !this.module, routerLink: "geofencing" },
          {
            name: "Inventory", value: "inventory", data: [
              { name: "Locations", value: "inventoryLocation", routerLink: "inventory/location" },
              { name: "Zones", value: "inventoryZone", routerLink: "inventory/zone" },
              { name: "Bins", value: "inventoryBin", routerLink: "inventory/bin" }
            ], hide: this.addonFeature.addonINVENTORY == 'INVENTORY' && this.module && this.menuType != 'fleet' ? false : true
          },
      
          {
            name: "Job Order", value: "jobOrder", hide: (this.menuType === 'service' && this.servicePer) || (this.menuType === 'sales' && this.servicePer) ? false : true, data: [
              { name: "Configuration", value: "jobOrderDetails", routerLink: "jobOrder/details" },
              { name: "Picklists", value: "jobOrderPicklist", routerLink: "jobOrder/picklist" },
              { name: "Templates", value: "jobOrderTemplates", routerLink: "jobOrder/templates", hide:this.workflow && this.menuType != 'fleet' && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true},
              { name: "Faults & Resolutions", value: "jobOrderFaultsResolution", routerLink: "jobOrder/faultresolution" },
              { name: "List View", value: "jobOrderListView", routerLink: "jobOrder/listView" },
            ]
          },
          {
            name: "Package", value: "packages", hide: this.module && this.menuType == 'fleet' && this.packages ? false : true, data: [
              { name: "Picklists", value: "packagesPicklist", routerLink: "packages/picklist" },
            ]
          },
          { name: "Preventive Maintenance", value: "preventiveMaintenance", data: [
            { name: "Configuration", value: "preventiveMaintenanceDetails", routerLink: "preventiveMaintenance/configuration" },
            { name: "List View", value: "preventiveMaintenanceListView", routerLink: "preventiveMaintenance/listView" },
          ], hide: !this.addonFeature?.addonDYNAMICMAINTENANCE || !(this.menuType == 'sales' || this.menuType == 'service'),
         },
          {
            name: "Product", value: "products", data: [
             // { name: "Picklists", value: "productsPicklist", routerLink: "products/picklist" },
              { name: "List View", value: "productsListView", routerLink: "products/listView" },
            ]
          },
          {
            name: "Project", value: "projects", hide:  ((this.menuType == 'sales' || this.menuType == 'service')) && this.addonFeature.addonPROJECT == 'PROJECT' ? false : true, data: [
              { name: "Configuration", value: "projectDetails", routerLink: "projects/details" },
              { name: "Picklists", value: "projectsPicklist", routerLink: "projects/picklist" },
              { name: "List View", value: "assetListView", routerLink: "projects/listView" },
            ]
          },
          {
            name: "Sales Order", value: "salesOrder", hide: this.salePer || this.servicePer ? false : true, data: [
              { name: "Configuration", value: "salesOrderDetails", routerLink: "salesOrder/details" },
              { name: "Picklists", value: "salesOrderPicklist", routerLink: "salesOrder/fields" },
              { name: "List View", value: "salesListView", routerLink: "salesOrder/listView" },
            ]
          },
          {
            name: this.menuRename.site, value: "sites", hide: ((this.menuType == 'sales' || this.menuType == 'service')) ? false : true, data: [
              { name: "Configuration", value: "siteDetails", routerLink: "sites/details" },
              { name: "Picklists", value: "sitesPicklist", routerLink: "sites/picklist" },
              { name: "List View", value: "assetListView", routerLink: "sites/listView" },
            ]
          },
          { name: "Subcontractor", value: "subContractor", data: [], routerLink: "fleetSetup/subContractors", hide: this.module && this.menuType == 'fleet' ? false : true },
          {
            name: "Task", value: "task", hide: (this.menuType === 'sales' || this.menuType === 'service') ? false : true, data: [
              { name: "Configuration", value: "taskConfiguration", routerLink: "tasks/config" },
              // { name: "Picklists", value: "taskPicklist", routerLink: "" },
              { name: "Templates", value: "taskTemplates", routerLink: "tasks/templates",hide: (this.menuType === 'sales' || this.menuType === 'service') && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true,},
              { name: "List View", value: "assetListView", routerLink: "tasks/listView" },
            ]
          },
          {
            name: "Ticket", value: "ticket", hide:  this.addonFeature.addonSLA == 'SLA' && (this.menuType === 'sales' || this.menuType === 'service') ? false : true, data: [
              { name: "Configuration", value: "ticketDetails", routerLink: "ticket/details" },
              { name: "Picklists", value: "ticketFields", routerLink: "ticket/fields" },
              { name: "List View", value: "ticketListView", routerLink: "ticket/listView" },
            ]
          },
               {
            name: "Vehicle", value: "vehicleSetup", data: [
              // { name: "Vehicles", value: "vehicles", routerLink: "dashboard/setup/myVehicles" },
              { name: "Vehicle Types", value: "vehicletype", routerLink: "fleetSetup/vehicleType" },
              { name: "Vehicle Groups", value: "vehicleGroup", routerLink: "fleetSetup/vehicleGroup" },
              { name: "Vehicle Checklist", value: "vehicleChecklist", routerLink: "fleetSetup/vehicleChecklist" },
              // { name: "Trailers", value: "trailers", routerLink: "dashboard/setup/myTrailers" },
              // { name: "Last Location", value: "lastlocation", hide: this.addonFeature.addonSLA == 'SLA' ? false : true, routerLink: "dashboard/setup/lastLocation" }
            ], hide: this.module && this.menuType === 'fleet' ? false : true
          },
        ]
      },
     
      {
        name: "Workflows", value: "workflows", hide: this.workflow && this.menuType !== 'fleet' ? false : true, data: [
       // name: "Workflows", value: "workflows",  data: [
          // {
          //   name: "Checklist", value: "checklist", data: [
          //     { name: "Task List", value: "taskList", routerLink: "checklist/taskList" },
          //     { name: "Equipment", value: "equipment", routerLink: "checklist/equipment" },
          //     { name: "Maintenance Tasks", value: "maintenanceTasks", routerLink: "checklist/maintenance" },
          //     { name: "Departments", value: "departments", routerLink: "checklist/departments" },
             
          //   ], hide: this.addonFeature.addonTTL == 'TTL_QC' && this.menuType != 'fleet' ? false : true
          // },
          {
            name: "Checklists", value: "checklists", data: [], hide:this.workflow && this.menuType != 'fleet' && (this.addonFeature.addonMS == 'MACHINE_SPECIALIST' || this.addonFeature.addonINVENTORY == 'INVENTORY') ? false : true,routerLink: "checklist"
          },
          { name: "Devices", value: "devices", data: [], hide: this.addonFeature.addonCHUBB == 'CHUBB' ? false : true, routerLink: "chubb/devices" },
          // { name: "Notifications", value: "notification", data: [], hide: this.addonFeature.addonMAGNUM == 'MAGNUM' && this.manageNotification ? false : true, routerLink: "dashboard/setup/notifications" },
          {
            name: "SLA", value: "sla", data: [
              { name: "SLA Packages", value: "inventoryLocation", routerLink: "sla/package" },
              { name: "SLA Steps", value: "inventoryLocation", routerLink: "sla/step" },
              { name: "SLA Business Hours", value: "inventoryLocation", routerLink: "sla/businessHours" },
            
            ], hide: this.addonFeature.addonSLA == 'SLA' && this.module && this.menuType != 'fleet' ? false : true
          },
          {
            name: "Notifications", value: "notifications", data: [], routerLink: "notifications/settings"
          },
        ]
      },
      { name: "Import/Export", value: "import", data: [], hide: !this.manageImportExport, routerLink: "dashboard/setup/import" },
      {
        name: "Integrations", value: "integrations",hide: this.calendlyIntegration ? false : true, data: [
          { name: "Configuration", value: "integrationConfig", routerLink: "integration/config" },
        ],
      },
      { name: "Customer Surveys", value: "survey", data: [], routerLink: "dashboard/setup/survey",hide: (this.customerSurveyLink && this.addonFeature.addonSURVEY == 'SURVEY') ? false : true, },
    ];
    return this.rootLevelNodes
  }

  getMenuTask() {
    this.rootLevelNodes = [
      { name: "Queue (10)", value: "queue", data: [], routerLink: "dashboard/setup/fleetSetup/fleetConfig" },

      {
        name: "My Task", value: "general", data: [
          { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
          { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      },
      {
        name: "Staf Task", value: "users", data: [
          { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
          { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      },

      {
        name: "Team Task", value: "sla", data: [
          { name: "All Task (14)", value: "organisationInformation", routerLink: "generalSettings" },
          { name: "Today (5)", value: "defaultFormats", routerLink: "defaultFormats" },
          { name: "Upcoming (3)", value: "LicenseBilling", routerLink: "licenseBilling" },
          { name: "Completed (11)", value: "orgWideWorkingHours", routerLink: "orgWorkingHours" },
        ]
      }
    ]
    return this.rootLevelNodes
  }


  /************************************* Main Menu Permission Variable Diaply **********************/
  findMenuPermissionsSALES(tenantCategories: any) {
    var saleIndex = tenantCategories.indexOf("SALES");
    if (saleIndex != -1) {
      return true;
    } else {
      return false
    }

  }
  findMenuPermissionsSERVICE(tenantCategories: any) {

    var serviceIndex = tenantCategories.indexOf("SERVICE");
    if (serviceIndex != -1) {
      return true;
    } else {
      return false
    }

  }
  findMenuPermissionsFLEET(tenantCategories: any) {

    var fleetIndex = tenantCategories.indexOf("FLEET");
    if (fleetIndex != -1) {
      return true;
    } else {
      return false
    }
  }
}
