import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ProfilesService } from 'src/app/_services/admin/profiles.service';
import { CalendlyIntService } from 'src/app/_services/calendly-int.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-update-theme',
  templateUrl: './update-theme.component.html',
  providers: [ConfirmationService]
})
export class UpdateThemeComponent implements OnInit {
  color: string = '#e7e7e7'
  color2: string = '#1a1a1a'
  color3: string = '#ffffff'
  color4: string = '#D9D9D9'
  color5: any = '#6366f1'
  data: any

  @ViewChild("fileUpload", { static: false })
  fileUpload!: any;

  themeConfg: any
  submitTouched: boolean = false;

  resetDefault: any={
    textCode:"#27373F",
    colorCode:"#FFFFFF",
    titleColorCode:"#27373F",
    description:null,
    enabled: true,
    id : 3,
    integrationType : "CALENDLY",
    logoAttachment :null,
    subTitle : 'Confirm your slot',
    title : "Company Name"
  }
  logoAttachment: any;
  refreshPhoto: Subscription;
  addThemeFrom: UntypedFormGroup;
  constructor(public modalRef: BsModalRef,
    private calendlyService: CalendlyIntService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil, private cd: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private modal: ModalServiceService,
    private profileService : ProfilesService,) {
      
      this.refreshPhoto = this.profileService.refreshPhoto$.subscribe((res:any) => {
        debugger
        this.themeConfg.logoAttachment.attachmentUrl = null
        console.log(res,"=====",res['attachmentUrl'],"===",res.attachmentUrl)
        this.themeConfg.logoAttachment.attachmentUrl = res.attachmentUrl
        this.themeConfg.logoAttachment.id = res.id
      });

      this.addThemeFrom = new UntypedFormGroup({
        'title': new UntypedFormControl(null, [Validators.required]),
       
      });
      
     }

  ngOnInit(): void {
    console.log(this.data)
    this.resetDefault.id = this.data.id
    this.themeConfg = this.data
    if(this.themeConfg.title){
      if(!this.themeConfg.logoAttachment){
        this.logoAttachment ='../../../../assets/svg/table/Fieldex_Bg.svg'
      }else{
        this.logoAttachment =this.themeConfg.logoAttachment.attachmentUrl
      }   
      this.color5 = this.themeConfg.colorCode
       setTimeout(() => {
          this.changeBGcolor(this.data.colorCode,this.data.titleColorCode)
        }, 1000);   
    
    }else{
      this.setRevertToDefault()
    }
  }
/******************************************* Change Background color ****************************************/
  changeBGcolor(colorCode: any,fontColor: any){
    console.log(colorCode)
    this.themeConfg.colorCode = colorCode
    $('.preview-calendly').css("background-color",colorCode);
    this.changeFontcolor(fontColor);
    this.color = fontColor;
  }
  changeBGcolor_(colorCode: any){
    console.log(colorCode)
    this.themeConfg.colorCode = colorCode
    $('.preview-calendly').css("background-color",colorCode);
  }
  /******************************************* Change Font Color ****************************************/
  changeFontcolor(fontColor: any){
    this.themeConfg.titleColorCode = fontColor;
    $('#comapny-name').css("color",fontColor);
    $('#subTitle').css("color",fontColor);
  }
  /******************************************Revert To Default*************************************/
  setRevertToDefault(){
    this.themeConfg = {...this.resetDefault}
    this.logoAttachment ='../../../../assets/svg/table/Fieldex_Bg.svg'
    this.changeBGcolor(this.themeConfg.colorCode, this.themeConfg.textCode )
    
  }
/******************************************* Upload Img ****************************************/

public uploadFile($event: any) {
  this.spinner.show();
  //console.log($event.files);

  this.parse($event.target.files);
}

parse(files: FileList): void {
  // const file: File = files.item(0) as File;
  let file: any = files.item(0);

  //console.log("FILES", file);

  const formData = new FormData();
  formData.append("file", file);

  //console.log("FORM DATA", formData);

  formData.forEach((data) => {
    //console.log(data);
  });

  this.calendlyService.uploadAttachment( formData).subscribe(
    (res: any) => {
      // console.log(res)
      this.themeConfg.logoAttachment = res
      this.logoAttachment =this.themeConfg.logoAttachment.attachmentUrl
      $("#uploaderFile").val('');
      this.spinner.hide();
    },
    (error) => {
      this.spinner.hide();
      $("#uploaderFile").val('');
      this.errorUtil.setErrorMessage(error.status, null, error.detail);
    }
  );
}
clearUpload() {
  let $this = this;
  this.confirmationService.confirm({
    key: 'confirm',
    message: 'Are you sure you want to remove logo?',
    accept: () => {
      if(this.fileUpload) this.fileUpload.clear();
      this.logoAttachment =this.resetDefault.logoAttachment;
     // this.themeConfg.logoAttachment.attachmentUrl= this.resetDefault.logoAttachment;
     this.themeConfg.logoAttachment = null
      this.cd.detectChanges();
  },
  reject:()=>{
    // this.reject()
  }
  });


}
selectFile() {
  $("#uploaderFile").click();
}

openModal(modalView : any, data: any){
//  this.modalRef.hide();
  this.modal.openModal(modalView, data);
}
/******************************************* Submit Data ****************************************/
  onSubmit(){
    this.submitTouched = true;
    if(!this.addThemeFrom.valid){
      for (var i in this.addThemeFrom.controls) {
        console.log(this.addThemeFrom.controls[i])
        this.addThemeFrom.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    console.log(this.themeConfg,"====")
    this.spinner.show()
  

    var themedata={... this.themeConfg}
      // if(themedata.logoAttachment == '../../../../assets/svg/table/Fieldex_Bg.svg'){
      //   themedata.logoAttachment = null
      // }
console.log(themedata)
debugger

    this.calendlyService.updateTheme(themedata).subscribe((res:any) => {
      console.log(res.body)
      this.spinner.hide()
      if(res.status == 201 || res.status == 200){
        this.errorUtil.setErrorMessage(200, 'Theme updated successfully' ,  null, 'success',1000);
        this.modalRef.hide()
         
      } else {
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        this.submitTouched = false;
      }
    })
  
  } 
}
