import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ProjectService } from "src/app/_services/project.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-custella-projects",
  templateUrl: "./custella-projects.component.html",
  styleUrls: ["./custella-projects.component.scss"],
})
export class CustellaProjectsComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Output() itemSelector = new EventEmitter();
  @Output() closeView = new EventEmitter();

  public searchValue = "";

  constructor(private projecService: ProjectService) {
    super();

    this.push(
      this.projecService.getSelectItem().subscribe((data) => {
        if (data) {
          this.selectItem(data);
        }
      })
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  selectItem(val: any) {
    this.itemSelector.emit(val);
  }

  public closeTable() {
    this.closeView.emit(false);
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
