<div class="left-right-space h-100">
    <div *ngIf="noHistory" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" translate>
                {{ "no_records_found" }}
            </p>
        </div>
    </div>

    <div [hidden]="noHistory" class="position-relative fw-normal" style="height: calc(100% - 35px);">
        <div [hidden]="noHistory" class="table-space ag-grid-table-full h-100" >
            <ag-grid-angular
                #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                [gridOptions]="gridOptions"
                [columnDefs]="columnDefsLogs"
                [defaultColDef]="defaultColDef"
                [rowData]="rowLogData"
                (gridReady)="onGridReadyLogs($event)"
            ></ag-grid-angular>
        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_VehHistTableRec">
                {{ totalRecord }} {{'records' | translate}}
            </p>
        </div>
    </div>
</div>