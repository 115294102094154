import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-customer-format',
  templateUrl: './customer-format.component.html'
})
export class CustomerFormatComponent implements OnInit {
  tix  !: any[];
  tix2  !: any[];

  constructor(
    public modalRef : BsModalRef
  ) { }

  ngOnInit(): void {
    this.tix=[
      {variable:"{0}", necessity:"Required", desc:"The number of zeros in the curly braces represents the minimum number of digits to be displayed. Smaller actual numbers will be padded with leading zeros"},
      {variable:"{YY} / {YYYY}", necessity:"Optional", desc:"Y characters represent the year of the ticket creation date. This can be displayed as 2 digits ('21') or 4 digits ('2021')"},
      {variable:"{MM}", necessity:"Optional", desc:"M characters represent the numeric month of the ticket creation date ('01' = Jan, '02' = Feb)"},
      {variable:"{DD}", necessity:"Optional", desc:"D characters represent the numeric day of the month of the record creation date (E.g. '01' to '31' for Jan)"}
    ],
    this.tix2=[
      {format:"{0}", seq1:"10", seq2:"1000", number1:"10", number2:"1000"},
      {format:"{000000}", seq1:"10", seq2:"1000", number1:"000010", number2:"001000"},
      {format:"{YYYY}{MM}-{0}", seq1:"10", seq2:"1000", number1:"202108-10", number2:"202108-1000"},
      {format:"C# {000000000}", seq1:"10", seq2:"1000", number1:"C# 000000010", number2:"C# 000001000"}
    ]
  }
}
