<!-- <div class="modal-content modal-fullscreen p-0"> -->
<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">
        <span class="text-16 font-medium ms-3" id="title_TaskCheckOut">Check-out of Task</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()" id="btn_TaskCheckOutClose"></i>
</div>

<div class="modal-body pt-0">
    <div class="container px-0" style="padding: 20px;">
        <p class="mb-0 text-4 pb-2 px-3" id="label_CheckOutMsg">{{message}}</p>

    </div>
</div>
<div class="modal-footer p-0">
    <button type="button" id="btn_CheckOutCancel" class="custella-btn-white mx-1 text-12" (click)="onSubmit(false)">No</button>
    <p-button type="button" id="btn_CheckOutSubmit" (click)="onSubmit(true)" label="{{'yes' | translate}}"></p-button>
</div>



<!-- </div> -->