<div class="">
    <div class="custella-modal d-flex flex-column">
      <div class="custella-modal-header">
        <div class="custella-modal-header-text">Delivery Order Tracking</div>
        <i class="pi pi-times pt-1" [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
      </div>
  
      <div class="custella-modal-body">
        <div class="custella-content-row-input mb-2">
          <span class="custella-content-row-input-title text-center">
            Dear Customer, Your order {{ data.orderNumber }} has been confirmed. You
            can see the details and track its progress at the following link:
            <br />
            <br />
            <a
              class="text-1-5 font-weight-bold custella-content-row-input-title-three-link"
              target="_blank"
              (click)="openURL()"
              style="line-break: anywhere"
              >{{ url }}</a
            >
            <br />
            <img
              src="assets/svg/table/copy.svg"
              alt="Copy Tracking Link"
              class="cursor-pointer"
              (click)="copyURL()"
              tooltipPosition="top"
              pTooltip="{{ tooltipText }}"
              [hideDelay]="1000"
            />
          </span>
        </div>
      </div>
    </div>
  </div>  