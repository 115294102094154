<div>
  <div class="flex-between"> 
  
    <i class="pi pi-times w-24px dialog-close-icon" id="btn_ProductsClose" [pTooltip]="'close'|translate" tooltipPosition="left" (click)="closeProduct()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="title" id="label_ProductsSelectList">{{'select_product_list'|translate}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off" id="input_ProductsSelectListField"  placeholder="Type Product Name"  [(ngModel)]="searchValue"/>
      </span>
      </div>
    <div class="height-42-stat border">
      <app-product-table [lookupFilter]="lookupFilter" [filterData]="filterData" [searchValue]="searchValue" ></app-product-table>
    </div>
  </div>

</div>