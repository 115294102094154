import { Component, OnInit, ViewChild } from "@angular/core";
import { TabView } from "primeng/tabview";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { AuthenticationService } from "src/app/auth/authentication.service";

@Component({
  selector: "app-home-fleet",
  templateUrl: "./home-fleet.component.html",
  styleUrls: ["./home-fleet.component.scss"],
})
export class HomeFleetComponent implements OnInit {
  activeIndex: any;

  public toggleSection = {
    fleetDashboard: false,
  };

  public isHeaderValue = false;
  public headerValue = "";

  loadDataDashboard: any = 0;

  @ViewChild(TabView, { static: false }) tabView!: TabView;

  constructor(
    private auth: AuthenticationService,
    private util: UtilServiceService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 1000);
  }

  /******************************************** init ******************************************/
  private init() {
    this.loadDataDashboard = 1;
    // delay for 1sec to let login load after registration
    this.loadAddon().then(() => {
      const activeTab = this.util.getTabActive();

      if (activeTab) {
        let foundTab = false;

        for (let t = 0; t < this.tabView?.tabs?.length; t++) {
          if (this.tabView?.tabs[t].header === activeTab) {
            this.activeIndex = t;
            foundTab = true;
          }
        }

        if (foundTab === false) {
          this.activeIndex = 0;
        }

        this.loadData(this.activeIndex, this.tabView);
      } else {
        this.activeIndex =
          localStorage.getItem("activeIndex") == undefined
            ? 0
            : Number(localStorage.getItem("activeIndex"));

        setTimeout(() => {
          this.loadData(this.activeIndex, this.tabView);
        }, 500);
      }
    });
  }

  /******************************************** Load Addon ******************************************/
  private loadAddon() {
    return new Promise<void>((resolve) => {
      // this.addonMagnum = this.auth.getAddonFeatureLists().addonMAGNUM;
      // this.addonHills = this.auth.getAddonFeatureLists().addonHILLS;
      // this.addonChubb = this.auth.getAddonFeatureLists().addonCHUBB;
      //   this.addonINVENTORY = this.auth.getAddonFeatureLists().addonINVENTORY;
      //   console.log(this.addonINVENTORY,"=====")
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }

  /******************************************** pre set tab ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.setTabHeader(headerValue);
    } else {
      if (this.tabView) {
        const headerValue = this.tabView.tabs[0]
          ? this.tabView.tabs[0].header
          : null;
        if (headerValue !== null) {
          this.setTabHeader(headerValue);
        }
      }
    }
  }

  /******************************************** handle on tab change ******************************************/
  public handleChange(e: any, tabView: TabView) {
    localStorage.setItem("activeIndex", e.index);
    this.activeIndex = e.index;
    const headerValue = tabView.tabs[e.index].header;

    this.setTabHeader(headerValue);
  }

  /******************************************** display tab content ******************************************/
  private setTabHeader(headerValue: any) {
    if (headerValue == "Fleet Dashboard") {
      this.toggleSection.fleetDashboard = true;
    }
  }
}
