<div class="modal-header p-dialog-header flex-between">
  <div class=" d-flex align-items-center">
    <img src="assets\svg\dialog\Confirmation-w.svg">
    <span class="text-16 font-medium ms-3">{{data.title}}</span>
  </div>
  <i class="pi pi-times w-24px" (click)="closedModal()"></i>
</div>
<!------------------------------- CANCEL PLAN BUTTTON ------------------------------------------------------------>
<form>

  <div class="modal-body pt-0" *ngIf="data.btnName == 'Cancele'">
    <!-- CANCELLING -->
    <div class=" text-center px-0 pb-0" style="padding: 20px;">
      <p class="mb-0 text-14 pb-2 px-3" *ngIf="data.msg_body_1">{{data.msg_body_1}}</p>

    </div>
    <p class="mb-0 text-12 pt-2 pb-4 px-3" translate *ngIf="data.msg_body_2">{{data.msg_body_2}}</p>
    <p class="mb-0 text-14 pb-2 px-3" *ngIf="data.msg_body_3">{{data.msg_body_3}}</p>
  </div>

  <div class="modal-footer p-0">
    <button type="button" class="custella-btn-white mx-1 text-12" (click)="closedModal()">No</button>
    <p-button type="button" (click)="onSubmit()" label="{{'yes' | translate}}"></p-button>
  </div>

</form>