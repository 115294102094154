<div class="custella-modal p-dropdown-width-0">
    <form [formGroup]="addTaskFullForm">
        <div class="custella-modal-header align-items-center">
          <div *ngIf="showChubbFormDiv == 0">
            <div class="text-14 font-medium" *ngIf="mode === 'add' || mode === 'add&back'" id="title_TaskNew">  <span> {{ "new_task" | translate }} </span>  </div>
            <div class="text-14 font-medium" *ngIf="mode === 'edit' || mode === 'edit&back'" id="title_TaskEdit"> <span> {{ "edit_task" | translate }} </span> </div>
          </div>
          <div *ngIf="showChubbFormDiv == 1">
            <div class="text-14 font-medium"  (click)="backTaskPage()" id="link_TaskReqForms">  <span> {{ "required_forms" | translate }} </span>  </div>
             </div>
            <i class="pi pi-times" id="btn_TaskClose" (click)="modalRef.hide()" [pTooltip]="'close'|translate" tooltipPosition="left"></i>
        </div>
  
        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
                <!-- Container Content -->
                <div  [hidden]="showChubbFormDiv == 1">
                <!-- Task name and Priority level -->
                <div class="custella-form-container-content m-0">
                    <div class="custella-content-row mb-3 pe-0">
                        <div class="custella-content-row-input col">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskName" translate>{{ "task_name" }} </span>
                            <input pInputText autocomplete="off" type="text" id="input_TaskNameField" [(ngModel)]="addTask.taskName" formControlName="name" name="name" />
                            <div class="p-error text-10" *ngIf=" addTaskFullForm.controls['name'].touched && addTaskFullForm.controls['name'].invalid ">
                                <span *ngIf="addTaskFullForm.controls['name'].errors?.required" id="label_TaskNameReqField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
  
                        <div class="custella-content-row-input me-0">
                            <span class="custella-content-row-input-title" id="label_TaskPriority" translate>{{ "priority" }} </span>
                            <div class="d-flex">
                                <p-dropdown [options]="allPriorities" id="picklist_TaskPriorityField"
                                [(ngModel)]="selectedPriority" 
                                (onChange)="changeTaskPriority($event)" 
                                optionLabel="value" [ngModelOptions]="{ standalone: true }">
                                    <ng-template pTemplate="selectedItem">
                                        <div class="flex d-flex" *ngIf="selectedPriority">
                                            <div class="circle me-2" [ngClass]="selectedPriority.classColor" id="label_TaskSelPriorityColor"></div>
                                        </div>
                                    </ng-template>
                                    <ng-template let-priority pTemplate="item">
                                        <div class="flex d-flex">
                                            <div class="circle me-2" [ngClass]="priority.classColor" id="label_TaskPriorityColor"></div>
                                            <span id="value_TaskPriorityColor"> {{ priority.value }} </span>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                    </div>
  
                    <div class="custella-time-content mt-1">
                        <!-- <div class="custella-content-row mb-3">
                            <div class="custella-content-row-input col-10">
                                <div class="setup d-flex align-items-center">
                                    <button pButton type="button" class="rounded-default-button py-1 me-2" [ngClass]="{'rounded-chosen-button':!addTask.adhoc}"  name="adhoc" id="specific" (click)="validateTimeFields(false)" inputId="addHocSepecificTime" translate > {{ "specific_time" }} </button>
                                    <button pButton type="button" class="rounded-default-button py-1" [ngClass]="{'rounded-chosen-button':addTask.adhoc}"   name="adhoc" id="duration" (click)="validateTimeFields(true)" inputId="addHocFindATime"  translate > {{ "find_a_time" }} </button>
                                    <i class="pi pi-info-circle ms-2" pTooltip="{{ 'select_find_a_time' | translate }}" tooltipPosition="top" [escape]="false"></i>
                                </div>
                            </div>
                        </div> -->
  
                        <div class="custella-content-row pe-0 mb-3">
                            <div class="d-flex col">
                                <div class="custella-content-row-input col-5">
                                  <span class="custella-content-row-input-title" id="label_TaskDate" translate>{{ "date" }} </span>
                                    <span class="p-input-icon-right w-100">
                                        <i class="pi pi-calendar" style="z-index: 1;" (click)="openCalendar()"></i>
                                        <p-calendar #calendar baseZIndex="10000" dateFormat="dd/mm/yy" yearRange="2000:2030" id="picklist_TaskDateField" [(ngModel)]="addTask.date" formControlName="date" name="date" placeholder="{{ 'select_date' | translate }}" [yearNavigator]="true"appendTo="body"></p-calendar>
                                    </span>
                                    <div class="p-error text-10" *ngIf=" addTaskFullForm.controls['date'].touched && addTaskFullForm.controls['date'].invalid " >
                                        <span *ngIf="addTaskFullForm.controls['date'].errors?.required" id="label_TaskDateReqField" translate>{{ "required_field" }}</span>
                                    </div>
                                </div>
                                <ng-container *ngIf="!addTask.adhoc">
                                    <div class="custella-content-row-input col me-0">
                                      <span class="custella-content-row-input-title" id="label_TaskStartTime" translate>{{ "start_time" }} </span>
                                        <span class="p-input-icon-right w-100">
                                            <i class="pi pi-clock" style="z-index: 1;" (click)="openStarTime()"></i>
                                            <p-calendar #starTime id="picklist_TaskStartTimeField" hourFormat="12" [placeholder]="'select_time' | translate" [timeOnly]="true" (ngModelChange)="changeEndTime()" [(ngModel)]="addTask.startTime" formControlName="startTime" name="startTime"></p-calendar>
                                        </span>
                                        <div class="p-error text-10" *ngIf=" addTaskFullForm.controls['startTime'].touched && addTaskFullForm.controls['startTime'].invalid " >
                                            <span *ngIf="addTaskFullForm.controls['startTime'].errors?.required" id="label_TaskStartTimeReqField">Required Field</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row-input mx-2" style="margin-top: 30px;">
                                      <span id="label_TaskToTime">to</span>
                                    </div>
                                    <div class="custella-content-row-input col">
                                      <span class="custella-content-row-input-title" id="label_TaskEndTime" translate>{{ "end_time" }} </span>
                                        <span class="p-input-icon-right w-100">
                                            <i class="pi pi-clock" style="z-index: 1;" (click)="openEndTime()"></i>
                                            <p-calendar #endTime  hourFormat="12" [placeholder]="'select_time' | translate" id="picklist_TaskEndTimeField" [timeOnly]="true" [(ngModel)]="addTask.endTime" formControlName="endTime" name="endTime"></p-calendar>
                                        </span>
                                    </div>
                                </ng-container>
  
                                <ng-container *ngIf="addTask.adhoc">
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskDuration" translate>{{ "duration" }} </span>
                                        <p-dropdown [options]="allDurations" id="picklist_TaskDurationField" optionLabel="label" optionValue="time" [(ngModel)]="addTask.duration" formControlName="duration" name="duration">
                                            <ng-template let-duration pTemplate="item" id="item_TaskDuration">
                                                <span>{{ duration.label }} {{ duration.timeLabel | translate }}</span>
                                            </ng-template>
                                        </p-dropdown>
                                    </div>
                                </ng-container>
                            </div>
  
                            <div class="">
                                <div class="custella-content-row-input me-0">
                                  <span class="custella-content-row-input-title" id="label_TaskLock" >{{ "lock" | translate}}  <i class="pi pi-info-circle" id="tooltip_TaskLock" pTooltip="{{'locking_a_task_puts' | translate}}" tooltipPosition="top" [escape]="false"></i> </span>
                                    <button class="btn-list-icon" id="btn_TaskLock" *ngIf="addTask.fixed" (click)="toggleFixed(false)" >
                                        <!-- <i class="pi pi-lock px-1 pt-1 pb-0 pt-05-rem" style="color: black;"></i> -->
                                        <i class="fa-regular fa-lock"></i>
                                    </button>
                                    <button class="btn-list-icon"  id="btn_TaskUnlock" *ngIf="!addTask.fixed" (click)="toggleFixed(true)">
                                        <i class="fa-regular fa-lock-open"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
  
                    <!-- Customer -->
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" id="label_TaskCust" translate>{{ "customer" }} </span>
                            <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete
                                    (onSelect)="onSelectValue($event)"
                                    id="picklist_TaskCustField"
                                    field="name"
                                    [suggestions]="customers"
                                    (onBlur)="onvalidCustomer()"  
                                    (completeMethod)="onKeyCustomerInput($event)"
                                    [(ngModel)]="selectedCustomer"
                                    formControlName="customer" 
                                    styleClass="w-100"
                                    >
                                </p-autoComplete>
                                <i *ngIf="addTask.customer" id="btn_TaskCustClear" (click)="clearValue('customer')" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </span>
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass" pTooltip="Customer Lookup" tooltipPosition="left"id="btn_TaskCustSearch" (click)="displayCustomerTable()"></button>
                            </div>
                            <footer *ngIf="showCustomerTable" class="custella-form-container-footer hidePopup">
                                <p-dialog header="Customer Details" id="title_CustDet" [(visible)]="showCustomerTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                    <app-custella-customers (selectCustomerEvent)="onSelectValue($event)" (closeCustomerEvent)="hideCustomerTable()"></app-custella-customers>
                                </p-dialog>
                            </footer>
                        </div>
                    </div>
  
                    <!-- Location -->
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" id="label_TaskLoc" translate>{{ "location" }} </span>
                            <div class="d-flex w-100 col">
                                <div class="d-flex col">
                                    <div class="col">
                                        <input
                                            pInputText autocomplete="off"
                                            (keyup)="getPlaceApiCall($event)"
                                            (change)="manualSearch($event)"
                                            [ngModelOptions]="{ standalone: true }"
                                            placeholder="Search for Location"
                                            autocorrect="off"
                                            autocapitalize="off"
                                            spellcheck="off"
                                            type="text"
                                            #homeAddressSearch
                                            [(ngModel)]="addTask.locationFull"
                                            id="input_TaskLocField" />
                                        <div class="p-error text-10" *ngIf=" addTaskFullForm.controls['location'].touched && addTaskFullForm.controls['location'].invalid " >
                                            <span *ngIf=" addTaskFullForm.controls['location'].errors?.required " id="label_TaskLocReqField" translate > {{ "required_field" }}</span>
                                        </div>
                                    </div>
                                    <button class="btn-list-icon ms-2" (click)="setStartLocation('home')" id="btn_TaskLocHome" [pTooltip]="'home' | translate" tooltipPosition="top">
                                        <!-- <img alt="logo" src="assets\svg\sidebar\home.svg" class="w-24px" /> -->
                                        <i class="fa-regular fa-house"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setStartLocation('office')" id="btn_TaskLocOffice" [pTooltip]="'office' | translate" tooltipPosition="top">
                                        <!-- <img alt="logo" src="assets\svg\table\briefcase-black.svg" class="w-24px" /> -->
                                        <i class="fa-regular fa-briefcase"></i>
                                    </button>
                                    <button class="btn-list-icon ms-2" (click)="setStartLocation('current')" id="btn_TaskLocCurrent" [pTooltip]="'current_location' | translate" tooltipPosition="top">
                                        <!-- <img alt="logo" src="assets\svg\table\map-marker-black.svg" class="w-24px" /> -->
                                        <i class="fa-regular fa-location-dot"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
  
                    <!-- Team -->
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" id="label_TaskTeam" translate>{{ "team" }} </span>
                            <p-dropdown placeholder="Select a Team" name="team" id="picklist_TaskTeamField" formControlName="team" [(ngModel)]="addTask.team.id" [options]="teams" [showClear]="true" optionLabel="name" optionValue="id"> </p-dropdown>
                        </div>
                    </div>
                    <div class="d-flex align-items-center my-3 mr-3 color-primary">
                        <span class="cursor-pointer text-12" (click)="toggleAdvanceOptions = !toggleAdvanceOptions" id="label_TaskTeamAdvOptions" translate>{{ "advanced_options" }} </span>
                        <i class="pi pi-angle-down ms-2" *ngIf="!toggleAdvanceOptions" id="btn_TaskTeamAdvOptionsUp" (click)="toggleAdvanceOptions = !toggleAdvanceOptions"></i>
                        <i class="pi pi-angle-up ms-2" *ngIf="toggleAdvanceOptions" id="btn_TaskTeamAdvOptionsDown" (click)="toggleAdvanceOptions = !toggleAdvanceOptions"></i>
                    </div>
                </div>
  
                <!-- show more details -->
                <div class="custella-form-container-title flex-between m-0 py-2" *ngIf="toggleAdvanceOptions" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                  <div class="font-medium text-14" id="title_TaskBuffer" translate>
                      {{ "buffer" }}
                      <i class="pi pi-info-circle py-1 mx-2" id="tooltip_TaskTimeAdded" pTooltip="{{ 'time_added_before_or' | translate }}"></i>
                  </div>
                </div>
                <ng-container *ngIf="toggleAdvanceOptions">
                    <div class="custella-form-container-content">
                        <!-- Buffer -->
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-input-box-title" translate>
                                    <span class="custella-content-row-input-title" id="label_TaskBufferBeforeTask" translate>{{ "buffer_before_task" }} </span>
                                </span>
                                <p-dropdown optionValue="value" optionLabel="value" id="picklist_TaskBufferBeforeTaskField" [options]="allBuffers" [(ngModel)]="addTask.startBuffer" formControlName="startBuffer" name="startBuffer">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="addTask.startBuffer" id="item_TaskBufferBeforeTaskSel">{{ addTask.startBuffer }} {{ getBufferLabel(addTask.startBuffer) | translate }}</span>
                                    </ng-template>
  
                                    <ng-template let-buffer pTemplate="item">
                                        <span id="item_TaskBufferBeforeTask">{{ buffer.value }} {{ buffer.label | translate }}</span>
                                    </ng-template>
                                </p-dropdown>
                                <div class="p-error text-10" *ngIf=" addTaskFullForm.controls['startBuffer'].touched && addTaskFullForm.controls['startBuffer'].invalid " >
                                    <span *ngIf=" addTaskFullForm.controls['startBuffer'].errors?.required " id="label_TaskBufferBeforeTaskReqField" translate >
                                        {{ "required_field" }}
                                    </span>
                                </div>
                            </div>
  
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title" id="label_TaskBufferAfterTask" translate>{{ "buffer_after_task" }} </span>
                                <p-dropdown optionValue="value" [options]="allBuffers" [(ngModel)]="addTask.endBuffer" formControlName="endBuffer" name="endBuffer">
                                    <ng-template pTemplate="selectedItem">
                                        <span *ngIf="addTask.endBuffer" id="item_TaskBufferAfterTaskSel">{{ addTask.endBuffer }} {{ getBufferLabel(addTask.endBuffer) | translate }}</span>
                                    </ng-template>
                                    <ng-template let-buffer pTemplate="item">
                                        <span id="item_TaskBufferAfterTask">{{ buffer.value }} {{ buffer.label | translate }}</span>
                                    </ng-template>
                                </p-dropdown>
                                <div class="p-error text-10" *ngIf=" addTaskFullForm.controls['endBuffer'].touched && addTaskFullForm.controls['endBuffer'].invalid " >
                                    <span *ngIf=" addTaskFullForm.controls['endBuffer'].errors?.required " id="label_TaskBufferAfterTaskReqField" translate > {{ "required_field" }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
  
                <div class="custella-form-container-title flex-between m-0 py-2" *ngIf="toggleAdvanceOptions" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                    <div class="font-medium text-14" id="title_TaskSkills" translate>
                        {{ "skills" }}
                        <i class="pi pi-info-circle py-1 mx-2" id="tooltip_TaskSkillsToFind" pTooltip="{{ 'skills_used_to_find' | translate }}"></i>
                    </div>
                </div>
  
                <ng-container *ngIf="toggleAdvanceOptions">
                    <div class="custella-form-container-content">
                            <div class="custella-content-row pe-0 mb-3">
                                <div class="d-flex col-12">
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskDept" translate>{{ "department" }} </span>
  
                                        <p-dropdown optionLabel="name" [options]="departments" name="" id="picklist_TaskDeptField" [(ngModel)]="selectedDepartment" [ngModelOptions]="{ standalone: true }" (onChange)="getRolesByDepartment(selectedDepartment)">
                                        </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskRole" translate>{{ "role" }} </span>
                                        <p-dropdown optionLabel="name" [options]="roles" id="picklist_TaskRoleField" name="" [(ngModel)]="selectedRole" [ngModelOptions]="{ standalone: true }" (onChange)="getLevelsByRole(selectedRole)"> </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input col">
                                        <span class="custella-content-row-input-title" id="label_TaskLevel" translate>{{ "level" }} </span>
                                        <p-dropdown optionLabel="name" [options]="levels" id="picklist_TaskLevelField" name="" [(ngModel)]="selectedLevel" [ngModelOptions]="{ standalone: true }"> </p-dropdown>
                                    </div>
                                    <div class="custella-content-row-input me-0">
                                        <span class="custella-content-row-input-title" id="label_TaskAdd" translate style="opacity: 0;">{{ "add" }} </span>
                                        <button pButton class="ml-2" (click)="addSkill()" id="btn_TaskAdd" icon="fa-regular fa-plus"></button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap gap-1">
                                <div class="skills d-flex px-3" *ngFor=" let skill of addTask.userDynamicSkillList; let i = index " >
                                    <p class="text-1 mb-0 mr-2" id="label_TaskSkillDynamic">{{ skill.label }}</p>
                                    <p class="mb-0 text-3 cursor-pointer font-weight-bold ms-3" id="btn_TaskSkillDynamicRemove" (click)="removeSkill(i)">X</p>
                                </div>
                            </div>
                    </div>
                </ng-container>
  
                <div class="custella-form-container-title flex-between m-0 py-2" *ngIf="toggleAdvanceOptions" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                  <div class="font-medium text-14" id="title_TaskAddDet" translate>
                      {{ "additional_details" }}
                  </div>
                </div>
                <!-- Description -->
                <ng-container *ngIf="toggleAdvanceOptions">
                  <div class="custella-form-container-content">  
                    <div class="custella-content-row-input mt-2">
                        <span class="custella-content-row-input-title" id="label_TaskDesc" translate>{{ "description" }} </span>
                        <textarea pInputTextarea autocomplete="off"id="input_TaskDescField" rows="3" class="mt-2" formControlName="description" [(ngModel)]="addTask.description" name="description" [maxlength]="320000"></textarea>
                      </div>
                  </div>
                  <div class="custella-form-container-content" *ngIf="permissions.siteShow">  
                      <div class="custella-content-row-input mt-2">
                          <span class="custella-content-row-input-title" id="label_TaskSite" translate>{{ "site" }} </span>
                          <div class="p-inputgroup search">
                            <span class="p-input-icon-right w-100">
                                <p-autoComplete styleClass="w-100" (onSelect)="onSiteSelect($event)" id="picklist_TaskSiteField" field="siteName" [suggestions]="allSites" (completeMethod)="getSite($event)" [(ngModel)]="selectedSite" [placeholder]="'sites' | translate" [ngModelOptions]="{standalone: true}"> </p-autoComplete>
                                <i *ngIf="selectedSite" (click)="clearValue('site')" id="btn_TaskSiteClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                            </span>
                            <button type="button" pButton icon="fa-regular fa-magnifying-glass" pTooltip="Site Lookup" tooltipPosition="left" id="btn_TaskSiteSearch" (click)="showTable('sites')"></button>
                        </div>
                        <footer *ngIf="showingTable.sites" class="custella-form-container-footer hidePopup">
                            <p-dialog [header]="'site_details' | translate" id="title_SiteDet" [(visible)]="showingTable.sites" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                <app-custella-sites (itemSelector)="onSiteSelect($event)" (closeView)="closeTable('sites')"></app-custella-sites>

                            </p-dialog>
                        </footer>
                      </div>
                    </div>
                    <div class="custella-form-container-content" *ngIf="permissions.projectShow && addonFeature.addonPROJECT == 'PROJECT'">  
                      <div class="custella-content-row-input mt-2">
                          <span class="custella-content-row-input-title" id="label_TaskProj" translate>{{ "project" }} </span>
                          <div class="p-inputgroup search">
                                <span class="p-input-icon-right w-100">
                                    <p-autoComplete styleClass="w-100" (onSelect)="onProjectSelect($event)" field="name" id="picklist_TaskProjField" [suggestions]="allProject" (completeMethod)="getProject($event)" [(ngModel)]="selectedProject" [placeholder]="'projects' | translate" [ngModelOptions]="{standalone: true}"> </p-autoComplete>     
                                    <i *ngIf="selectedProject" (click)="clearValue('project')" id="btn_TaskProjClear" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </span>
                                <button type="button" pButton icon="fa-regular fa-magnifying-glass" pTooltip="Project Lookup" tooltipPosition="left" id="btn_TaskProjSearch" (click)="showTable('project')"></button>
                          </div>
                        <footer *ngIf="showingTable.project" class="custella-form-container-footer hidePopup">
                            <p-dialog header="Projects Details" id="title_ProjDet" [(visible)]="showingTable.project" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                <app-custella-projects (itemSelector)="onProjectSelect($event)" (closeView)="closeTable('project')"></app-custella-projects>

                            </p-dialog>
                        </footer>
                      </div>
                    </div>
                </ng-container>
                </div>
  
                <!-- CHUBB FORMS START -->
                <ng-container *ngIf="chubbAddonFeature == 'CHUBB' && showChubbFormDiv == 1">
                    <!-- <div class="custella-form-container-title flex-between" style="border-top-left-radius: 0; border-top-right-radius: 0;">
                        <div class="custella-form-container-title-subtitle" translate>
                            {{ "forms" }}
                        </div>
                    </div> -->
  
                    <div class="custella-form-container-content">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                                <div class="custella-content-row">
                                    <div class="custella-content-row-input col-4">
                                        <span class="custella-content-row-input-title" id="label_TaskForms" translate>{{ "forms" }} </span>
                                        <span class="custella-content-row-input-title" id="label_TaskSerCheck" translate>{{ "servicing_checklist" }} </span>
                                        <span class="custella-content-row-input-title" id="label_TaskCustSerRep" translate>{{ "customer_service_report" }} </span>
                                        <span class="custella-content-row-input-title" id="label_TaskHandCert" translate>{{ "handover_certificate" }} </span>
                                    </div>
  
                                    <div class="custella-content-row-input col-2 d-flex flex-column">
                                        <span class="custella-content-row-input-title font-weight-bold" id="label_TaskFormAvail" translate>{{ "available" }} </span>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskSerCheck" [(ngModel)]="addTask.showServiceChecklist" [ngModelOptions]="{ standalone: true }" (onChange)="uncheck('showServiceChecklist')"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskCustSerRep" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.showCustomerServiceReport" (onChange)="uncheck('customerServiceReportRequired')"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskHandCert" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.showHandoverCertificate" (onChange)="uncheck('showHandoverCertificate')"> </p-checkbox>
                                    </div>
  
                                    <div class="custella-content-row-input col d-flex flex-column">
                                        <span class="custella-content-row-input-title font-weight-bold" translate>{{ "mandatory" }} </span>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskSerCheckMandatory" [(ngModel)]="addTask.serviceChecklistRequired" [ngModelOptions]="{ standalone: true }" (onChange)="selectMandatory()"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskCustSerRepMandatory" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.customerServiceReportRequired" (onChange)="selectMandatory2()"></p-checkbox>
                                        <p-checkbox class="mx-2 mb-1" [binary]="true" id="check_TaskHandCertMandatory" [ngModelOptions]="{ standalone: true }" [(ngModel)]="addTask.handoverCertificateRequired" (onChange)="selectMandatory3()"></p-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- CHUBB FORMS END -->
  
                <div class="custella-form-container-footer mb-0">
                    <div class="d-flex align-items-center justify-content-end">
                        <!-- Checkbox -->
                        <div class="custella-content-row-checkbox me-auto" [hidden]="showChubbFormDiv == 1">
                            <ng-container *ngIf=" comingFrom != 'JO' && (mode == 'add' || mode == 'add&back') " >
                                <div class="p-field-checkbox ms-2">
                                    <p-checkbox
                                        id="check_TaskGenerateJob"
                                        [binary]="true"
                                        [disabled]=" addTask.selectJobOrder && addTask.jobOrder.jobOrderNumber "
                                        class="date-checkbox"
                                        type="checkbox"
                                        [(ngModel)]="addTask.generateJobOrder"
                                        [ngModelOptions]="{ standalone: true }"
                                        (onChange)="setJOFunction()"
                                    ></p-checkbox>
                                </div>
                                <span class="custella-content-row-checkbox-text my-0 pt-1" id="label_TaskGenerateJob" translate>{{ "generate_job_order" }} </span>
                            </ng-container>
                        </div>
  
                        <ng-container *ngIf="showChubbFormDiv == 0">
                          <div class="dropdown mt-1 me-2" *ngIf="mode === 'add' || mode === 'add&back'">
                              <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                <i class="fa-regular fa-circle-info hvr" id="btn_TaskAddDropdown"></i>
                              </div>
                              <ul class="dropdown-menu dropdown-collaps p-0 w-25">
                                  <li class="flex-between border-bottom rounded-2 px-1 py-2">
                                      <div class="ms-2 me-3">
                                          <span class="font-medium font-black text-13" id="label_TaskCreate" translate>{{'create'}}</span>
                                          <span class="font-black text-12 d-block" id="value_TaskCreate" translate>{{'create_text_task'}}</span>
                                      </div>
                                  </li>
                                  <li class="flex-between border-bottom rounded-2 px-1 py-2">
                                      <div class="ms-2 me-3">
                                          <span class="font-medium font-black text-13" id="label_TaskSmartAssign" translate>{{'smart_assign'}}</span>
                                          <span class="font-black text-12 d-block" id="value_TaskSmartAssign" translate>{{'smart_assign_text_task'}}</span>
                                      </div>
                                  </li>
                                  <li class="flex-between border-bottom rounded-2 px-1 py-2">
                                      <div class="ms-2 me-3">
                                          <span class="font-medium font-black text-13" id="label_TaskAssign" translate>{{'assign'}}</span>
                                          <span class="font-black text-12 d-block" id="value_TaskAssign" translate>{{'assign_text_task'}}</span>
                                      </div>
                                  </li>
                              </ul>
                          </div>
  
                              <button
                                id="task-create-new"
                                class="custella-btn-white ms-2 text-12"
                                [disabled]="submitTouched || addTask.adhoc"
                                type="button"
                                *ngIf="mode === 'add' || mode === 'add&back'"
                                (click)="showChubForm('quick')"
                                id="btn_TaskCreate"
                                translate >
                                {{ "create" }}
                            </button>
                            <button
                                id="task-update"
                                class="custella-btn-white ms-2 text-12"
                                [disabled]="submitTouched || addTask.adhoc"
                                type="button"
                                *ngIf="mode === 'edit' || mode === 'edit&back'"
                                (click)="showChubForm('quick')"
                                id="btn_TaskUpdate"
                                translate >
                                {{ "save" }}
                            </button>
                        </ng-container>
                        <ng-container *ngIf="allowAssignTask && showChubbFormDiv == 0">
                          <div class="custella-dashboard-top-right">
                              <p-button id="task-smart-assign" id="btn_TaskSmartAssign" styleClass="ms-2 h-32 dark" [disabled]="submitTouched" type="button" *ngIf="mode === 'add' || mode === 'add&back'" (onClick)="showChubForm('smart-assign')" label="{{ 'smart_assign' | translate }}"> </p-button>
                          </div>
  
                          <p-button styleClass="ms-2 dark" id="btn_TaskAssign" [disabled]="submitTouched" type="button" *ngIf="mode === 'add' || mode === 'add&back'" (onClick)="showChubForm('exact-assign')" label="{{ 'assign' | translate }}"> </p-button>
  
                        </ng-container>
  
                        <ng-container *ngIf="showChubbFormDiv == 1">
                            <p-button id="task-create-new" styleClass="ms-2 h-32" id="btn_TaskSubmit" [disabled]="submitTouched || addTask.adhoc" type="button" (click)="onSubmit(showChubbFormSubmitted)" [label]="'save'|translate"></p-button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
  </div>
  