import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";

@Injectable({
  providedIn: "root",
})
export class DeliveryOrderService {
  private ENDPOINT_LIST_DO_ORDER_HISTORY =
    "/delivery-order-audit-histories?deliveryOrderId=";
  private ENDPOINT_DELETE_ATTACHMENTS = "/attachments/";
  private ENDPOINT_UPLOAD_DO_ATTACHMENTS =
    "/attachments/upload?parentType=DO_ATTACHMENT&parentId=";
  private ENDPOINT_LIST_DO_POLYLINE = "/delivery-orders/line-item/polyline/";

  private ENDPOINT_LIST_DELIVERY_ORDERS =
    environment.base_url + "/delivery-orders";

  private allStatuses = [
    {
      status: "NEW",
      color: "#7CBDFF",
      label: "New",
    },
    {
      status: "CONFIRMED",
      color: "#368DE5",
      label: "Confirmed",
    },
    {
      status: "DRIVER_ASSIGNED",
      color: "#9537E5",
      label: "Driver Assigned",
    },
    {
      status: "DISPATCHED",
      color: "#FFFFFF",
      label: "Dispatched",
    },
    {
      status: "WITH_DRIVER",
      color: "#E58635",
      label: "With Driver",
    },
    {
      status: "EN_ROUTE",
      color: "#E5D136",
      label: "En route",
    },
    {
      status: "DELIVERED",
      color: "#1B7E77",
      label: "Delivered",
    },
    {
      status: "FAILED_DELIVERY",
      color: "#CE4F46",
      label: "Failed Delivery",
    },
    {
      status: "RETURN_DELIVERY",
      color: "#7CBD4A",
      label: "Return To Sender",
    },
    {
      status: "CANCEL_DELIVERY",
      color: "#964242",
      label: "Cancel Delivery",
    },
    {
      status: "ON_HOLD",
      color: "#E461F5",
      label: "On Hold",
    },
  ];

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    private httpOnly: Http
  ) {}

  public getOrderWay() {
    return this.http
      .get(`${environment.base_url}/delivery-order-ways?active=true`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getListColumn(apiModule: any) {
    return this.http
      .get(
        environment.base_url +
          "/table-column-setups/by-module?apiModule=" +
          apiModule
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDeliveryOrders(
    fromDate: any,
    toDate: any,
    status: any,
    customerId: any,
    orderType: any,
    searchValue: any,
    parameters: any,
    salesOrder: any,
    splitType: any,
    notInOrderIds: any,
    origin: any,
    addonFeature: any,
    startPickupDate: any,
    endPickupDate: any
  ) {
    let params = new HttpParams();
    if (fromDate && toDate && fromDate !== "" && toDate !== "") {
      params = params
        .set("startOrderDate", fromDate)
        .set("endOrderDate", toDate);
    }

    if (status !== "" && status != null && status !== undefined) {
      params = params.set("OrderStatus", status);
    }
    if (customerId !== "" && customerId != null && status !== undefined) {
      params = params.set("customerId", customerId);
    }
    if (orderType !== "" && orderType != null && status !== undefined) {
      params = params.set("orderType", orderType);
    }
    if (searchValue !== "" && searchValue != null && status !== undefined) {
      params = params.set("search", searchValue);
    }
    if (salesOrder !== "" && salesOrder != null && status !== undefined) {
      params = params.set("salesOrderId", salesOrder);
    }
    if (splitType !== "" && splitType != null && status !== undefined) {
      params = params.set("splitType", splitType);
    }
    if (notInOrderIds !== "" && notInOrderIds != null && status !== undefined) {
      params = params.set("notInOrderIds", notInOrderIds);
    }
    if (origin !== "" && origin != null && origin !== undefined) {
      params = params.set("origin", origin);
    }
    if (startPickupDate !== "" && startPickupDate != null && status !== undefined) {
      params = params.set("startPickupDate", startPickupDate);
    }
    if (endPickupDate !== "" && endPickupDate != null && status !== undefined) {
      params = params.set("endPickupDate", endPickupDate);
    }
    if (parameters) {
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    }

    console.log(addonFeature)
   var url='/v3/delivery-orders'
    if(addonFeature =='SNL_TENANT'){
      url='/delivery-orders'
    }
    return this.http
      .get(`${environment.base_url}${url}`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteDeliveryOrder(id: any) {
    return this.http
      .delete(environment.base_url + "/delivery-orders/" + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDeliveryOrderById(id: any) {
    return this.http.get(`${environment.base_url}/delivery-orders/${id}`).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getDOOrderHistory(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_DO_ORDER_HISTORY + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public deleteAttachment(id: any) {
    return this.http
      .delete(environment.base_url + this.ENDPOINT_DELETE_ATTACHMENTS + id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public uploadDOAttachment(id: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + this.ENDPOINT_UPLOAD_DO_ATTACHMENTS + id,
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public returnGenericOrderStatusColor(status: string) {
    let statusColor = "#FFFFFF";
    for (let s = 0; s < this.allStatuses.length; s++) {
      if (this.allStatuses[s].status === status) {
        statusColor = this.allStatuses[s].color;
      }
    }

    return statusColor;
  }

  public getDOpolyline(id: any) {
    return this.http
      .get(environment.base_url + this.ENDPOINT_LIST_DO_POLYLINE + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDeliveryOrderActivity(deliveryOrderID: string) {
    return this.http
      .get(
        `${environment.base_url}/delivery-order-activities/by-delivery-order/${deliveryOrderID}`
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getEstimatedDistance(devItemOrderId: number) {
    return this.http
      .put(
        environment.base_url +
          `/delivery-orders/line-item/distance/${devItemOrderId}`,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getDefaultDeportByUser(userId: any) {
    return this.http
      .get(`${environment.base_url}/users/get-default-depot/${userId}`)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public getLoadingBaysByCustomerId(
    id: any,
    type: any,
    consignorConsigneeId: any
  ) {
    var consignorConsignee = "";

    if (consignorConsigneeId !== undefined) {
      consignorConsignee = "&consignorConsigneeId=" + consignorConsigneeId;
    }

    return this.http
      .get(
        environment.base_url +
          "/loading-bays/and-working-hours?status=true&customerId=" +
          id +
          "&bayType=" +
          type +
          consignorConsignee
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /* ======================================================================================================================
 Delivery order function for add / update / view / upload attachment / order history
=========================================================================================================================*/
  public createDeliveryOrder(body: any) {
    return this.http
      .post(this.ENDPOINT_LIST_DELIVERY_ORDERS, body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public updateDeliveryOrder(body: any) {
    return this.http
      .put(this.ENDPOINT_LIST_DELIVERY_ORDERS, body, { observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  /* ======================================================================================================================
 Delivery order Splite 
=========================================================================================================================*/
  public splitDo(body: any) {
    return this.http
      .post(environment.base_url + "/delivery-orders/split-by-type", body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          console.log(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* ======================================================================================================================
 Build Delivery Order Items for collections/arrays etc...
=========================================================================================================================*/
public buildLineOrderItems(deliveryOrderItemVMS: any[]) {
    const allLineItems: any[] = [];

    if (deliveryOrderItemVMS && deliveryOrderItemVMS.length > 0) {
      deliveryOrderItemVMS.forEach((element: any, index: any) => {
        element["value"] = element.deleveryOrderLineItem.lineItemNumber;
        element["label"] = `${element.deleveryOrderLineItem.lineItemNumber}-${
          index + 1
        }`;
        element["id"] = element.deleveryOrderLineItem.id;

        allLineItems.push(element);

        // this.allLineItems.push({
        //   value: element.deleveryOrderLineItem.lineItemNumber,
        //   label: `${element.deleveryOrderLineItem.lineItemNumber}-${
        //     index + 1
        //   }`,
        //   id: element.deleveryOrderLineItem.id,
        //   packages: element.deleveryOrderLineItemPackages,
        //   deleveryOrderLineItem: element.deleveryOrderLineItem,
        //   actualTourInfo: element.actualTourInfo,
        //   plannedTourInfo: element.plannedTourInfo,

        // });
      });
    }

    return allLineItems;
  }

  public updateDOEstimate(id: any, deliveryOrderLineItemId: any) {
    return this.http
      .get(
        `${environment.base_url}/delivery-orders/update-eta?deliveryOrderId=${id}&deliveryOrderLineItemId=${deliveryOrderLineItemId}`
      )
      .pipe(
        map((resp: any) => {
          console.log(resp);
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  public getSearchDO(orderNumber: any,notInOrderIds : any) {
    let params = new HttpParams();
    if (notInOrderIds !== "" && notInOrderIds != null) {
      params = params.set("notInOrderIds", notInOrderIds);
    }
    if (orderNumber !== "" && orderNumber != null) {
      params = params.set("search", orderNumber);
    }
      params = params
        .set("startFrom", '0')
        .set("perPage", "25")
        .set("columnName",'name')
        .set("sortOrder",'asc')
        .set("orderStatusList",'NEW,CONFIRMED,FAILED_DELIVERY,RETURN_TO_SENDER,ON_HOLD,UNSCHEDULED');

        var url='/delivery-orders'
     
    return this.http
      .get(environment.base_url +"/delivery-orders",{ params: params }) .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  public changeDeliveryOrderStatus(doId: any, status: any) {
    var body = new FormData();
    return this.http
      .put(
        environment.base_url + "/delivery-orders/" + doId + "/" + status,
        body,
        { observe: "response" }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
