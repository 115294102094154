<div class="d-flex button-box-top-right"> 
    <p-button  *ngIf="!configure" [label]="'edit'|translate" styleClass="h-28" (click)="editConfigure()"></p-button>  
    <div *ngIf="configure">
        <button pButton class="p-button-secondary h-28" [label]="'cancel' | translate" (click)="editConfigure()"></button>
        <p-button  [label]="'save'|translate" styleClass="ms-2 h-28" (click)="onSubmit()"></p-button>
    </div>
</div>

<div class="left-right-space mt-2">
    <div class="mb-2">
          <span class="text-20 font-semiBold" id="title_SetNotifyModule" translate>Notification Preferences</span>
    </div>  
    <div class="lv-desc">
        <span class="text">{{'module_notification_desc_2'|translate}}</span>
    </div>
</div>
<div class="custella-form-container left-right-space mb-4" *ngFor="let notifData of notificationListData | orderBy: 'name'">
    
    <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_SetNotifyStandard">{{notifData?.newModuleName}}&nbsp;{{'notifications'|translate}}</div>
    
    </div>
   
    <div class="custella-form-container-content px-0 m-0">
        <p-table [value]="notifData.detail" responsiveLayout="scroll" styleClass="p-datatable-gridlines">
            <ng-template pTemplate="header">
                <tr class="">
                    <th class="col-8 ps-4" id="label_SetNotifyStandNotification">{{'notification'|translate}}</th>
                    <th class="col-1 text-nowrap text-center" id="label_SetNotifyStandInApp">{{'in_app'|translate}}</th>
                    <th class="col-1 text-center" id="label_SetNotifyStandEmail">{{'email'|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-notificationsData>
                <tr class="text-12">
                    <td class="col-8 ps-4">
                        <span class="text-12 font-medium" id="label_SettAllowViewSo">{{notificationsData?.name}}&nbsp;</span> 
                        <i *ngIf="!notificationsData.active" class="fa-solid fa-triangle-exclamation font-orange" [pTooltip]="'notification_disabled'|translate"></i>
                        <span class="d-block text-12 color-var-500" id="desc_SettAllowViewSo" translate>{{notificationsData?.description}}</span>
                    </td>
                    
                    <td class="col-1 text-center">
                        <i id="icon_SettAllowViewSoNo" [ngClass]="{'fade-opacity-05':!notificationsData.active}" class="pi pi-check font-green pe-none" *ngIf="!configure && notificationsData?.inApp  == true"></i>
                        <i id="icon_SettAllowViewSoNo" [ngClass]="{'fade-opacity-05':!notificationsData.active}" class="pi pi-times font-red pe-none" *ngIf="!configure && notificationsData?.inApp  == false"></i>
                        <span class="text-12" *ngIf="notificationsData?.inApp == null">N/A</span>

                        <div class="p-field-checkbox" *ngIf="configure && notificationsData?.inApp != null">
                            <p-checkbox [binary]="true" [(ngModel)]="notificationsData.inApp" [disabled]="!notificationsData?.active"></p-checkbox>
                        </div>
                    </td>
                    <td class="col-1 text-center">
                        <i id="icon_SettAllowViewSoNo" class="pi pi-check font-green pe-none" *ngIf="!configure && notificationsData?.email  == true"></i>
                        <i id="icon_SettAllowViewSoNo" [ngClass]="{'fade-opacity-05':!notificationsData.active}" class="pi pi-times font-red pe-none" *ngIf="!configure && notificationsData?.email  == false"></i>
                        <span class="text-12" *ngIf="notificationsData?.email == null">N/A</span>

                        <div class="p-field-checkbox" *ngIf="configure && notificationsData?.email != null">
                            <p-checkbox [binary]="true" [(ngModel)]="notificationsData.email" [disabled]="!notificationsData?.active"></p-checkbox>
                        </div>
                    </td>
                  
                </tr>
            </ng-template>
        </p-table>
    </div>

</div>



<!--=======-=======-=======-=======-=======-=======CONFIRM DIALOG-=======-=======-=======-=======-=======-=======-=======-->
<!-- <div class="custella-custom-dialog">
    <p-dialog  showEffect="fade" [modal]="true" [draggable]="false" [(visible)]="display">
        <ng-template pTemplate="header">
            <div class="d-flex align-items-center">
                <img id="img_DialogConfirmation" src="assets\svg\dialog\Confirmation-w.svg" />
                <div id="title_DialogConfirmation" class="text-16 font-medium ms-3">{{'confirm_notification_update'|translate}}</div>
            </div>
        </ng-template>
        <div class="fst-italic text-12">
            <span>{{'changes_updated_modify'|translate}}</span>
        </div>
        <p-footer>
            <div class="d-flex align-items-center justify-content-end">
                <button class="custella-btn-white text-12 ms-2" (click)="cancel()">{{'cancel' | translate}}</button>
                <p-button type="submit" [label]="'apply_all_users'|translate" styleClass="dark" id="btn_ApproveAllUser"  ></p-button>
                <p-button type="submit" [label]="'update_new_users'|translate" id="btn_ApproveNewUser"  (click)="newUserOnly()"></p-button>
            </div>
        </p-footer>
    </p-dialog>
</div> -->