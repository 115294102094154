<div class="mt-56px">
    <!-- Container -->
    <form [formGroup]="addIQCForm">
        <div class="custella-form-container left-right-space rounded-2">
            <ng-container>
                <!-- Container Content -->
                <div class="custella-form-container-title text-14 font-medium rounded-top" translate>{{'iqc_details'}}</div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title mandatory" translate>{{ "asset" }}</span>
                            <div class="d-flex align-items-center">
                                <div class="p-input-icon-right w-100">
                                    <p-autoComplete
                                        (onBlur)="onvalidAsset()"
                                        (onSelect)="gotSelectedAsset($event)"
                                        field="name"
                                        [suggestions]="assets"
                                        (completeMethod)="getAssetByQuery($event)"
                                        id="userIdFirstWay"
                                        formControlName="assetsName"
                                        id="assetIdFirstWay"
                                        panelStyleClass="iqc-autocomplete"
                                        name="assetsName"
                                        [(ngModel)]="selectedAsset"
                                    ></p-autoComplete>
                                    <i *ngIf="iqcData.assets.id" (click)="clearAssetValue()" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </div>

                                <button class="lookup-button ms-2 me-0" (click)="showAssetTable()"><i class="pi pi-search"></i></button>

                                <footer *ngIf="displayAssetTable" class="custella-form-container-footer hidePopup">
                                    <p-dialog [header]="'asset_details' | translate" [(visible)]="displayAssetTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                        <app-custella-assets (closeAssetView)="closeAssetView()" (assetSelector)="gotSelectedAsset($event)"></app-custella-assets>
                                    </p-dialog>
                                </footer>
                            </div>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['assetsName']?.touched && (selectedAsset ==null || selectedAsset=='')">
                                <span *ngIf=" addIQCForm.controls['assetsName']?.errors?.required && (selectedAsset ==null || selectedAsset=='')" id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title mandatory" translate>{{ "location" }} </span>
                            <p-dropdown
                                [options]="locatns"
                                optionLabel="fieldLabel"
                                id="l-text"
                                #myDropDown
                                optionValue="name"
                                formControlName="location"
                                name="location"
                                filterBy="name"
                                [showClear]="true"
                                [(ngModel)]="iqcData.location"
                                placeholder="Select Location"
                                (onChange)="getQandA()"
                            >
                            </p-dropdown>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['location']?.touched && addIQCForm.controls['location'].invalid ">
                                <span *ngIf=" addIQCForm.controls['location']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate> {{ "customer_damage_claim"}}</span>
                            <div class="d-flex align-items-center">
                                <div class="p-input-icon-right w-100">
                                    <p-autoComplete
                                        (onSelect)="selectCustomerValue($event)"
                                        field="name"
                                        id="customer"
                                        [suggestions]="customers"
                                        (completeMethod)="getCustomer($event)"
                                        panelStyleClass="iqc-autocomplete"
                                        [(ngModel)]="selectedCustomer"
                                        [ngModelOptions]="{ standalone: true }"
                                        placeholder="{{ 'select_customer' | translate }}"
                                    ></p-autoComplete>
                                    <i *ngIf="iqcData?.customer?.id != null" (click)="clearValue(false)" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </div>
                                <button type="button" class="lookup-button ms-2 me-0" (click)="showTable()"><i class="pi pi-search"></i></button>
                                <footer *ngIf="displayTable" class="custella-form-container-footer hidePopup">
                                    <p-dialog header="Customer Details" [(visible)]="displayTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                        <app-custella-customers (selectCustomerEvent)="gotSelectedCustomer($event)" (closeCustomerEvent)="closeCustomerView()"></app-custella-customers>
                                    </p-dialog>
                                </footer>
                            </div>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['customer']?.touched && addIQCForm.controls['customer'].invalid ">
                                <span *ngIf=" addIQCForm.controls['customer']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>

                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate> {{ "subsidiary_repair_service"}}</span>
                            <div class="d-flex align-items-center">
                                <div class="p-input-icon-right w-100">
                                    <p-autoComplete
                                        (onSelect)="selectSubsidiaryValue($event)"
                                        field="name"
                                        [suggestions]="customers"
                                        (completeMethod)="getSubsidiary($event)"
                                        panelStyleClass="iqc-autocomplete"
                                        [(ngModel)]="selectedSubsidiary"
                                        [ngModelOptions]="{ standalone: true }"
                                        placeholder="Select Subsidiary"
                                    ></p-autoComplete>
                                    <i *ngIf="iqcData?.subsidiary?.id != null" (click)="clearValue(true)" class="p-autocomplete-clear-icon pi pi-times"></i>
                                </div>
                                <button type="button" class="lookup-button ms-2 me-0" (click)="showSubsidiaryTable()"><i class="pi pi-search"></i></button>
                                <footer *ngIf="displaySubsidiaryTable" class="custella-form-container-footer hidePopup">
                                    <p-dialog header="Subsidiary Details" [(visible)]="displaySubsidiaryTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                        <app-custella-customers (selectCustomerEvent)="gotSelectedSubsidiary($event)" (closeCustomerEvent)="closeSubsidiaryView()"></app-custella-customers>
                                    </p-dialog>
                                </footer>
                            </div>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['subsidiary']?.touched && addIQCForm.controls['subsidiary'].invalid ">
                                <span *ngIf=" addIQCForm.controls['subsidiary']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                    </div>

                    <!--  -->
                    <!-- <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{ "Customer (Damage claim)" }}
                            </span>
                            <p-dropdown [options]="customers" [showClear]="true" optionLabel="name" optionValue="id"
                                formControlName="customer" name="customer" [(ngModel)]="iqcData.customer.id"
                                placeholder="{{ 'select_customer' | translate }}" autoWidth="false"
                                [style]="{'width':'100%'}">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="p-error col px-0 py-0 text-left"
                                *ngIf=" addIQCForm.controls['customer']?.touched && addIQCForm.controls['customer'].invalid ">
                                <span *ngIf=" addIQCForm.controls['customer']?.errors?.required " translate>{{
                                    "required_field" }}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{ "Subsidiary (Repair service)" }}
                            </span>
                            <p-dropdown [options]="customers" [showClear]="true" optionLabel="name" optionValue="id"
                                formControlName="subsidiary" name="subsidiary" [(ngModel)]="iqcData.subsidiary.id"
                                placeholder="Select Subsidiary">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="p-error col px-0 py-0 text-left"
                                *ngIf=" addIQCForm.controls['subsidiary']?.touched && addIQCForm.controls['subsidiary'].invalid ">
                                <span *ngIf=" addIQCForm.controls['subsidiary']?.errors?.required " translate>{{
                                    "required_field" }}</span>
                            </div>
                        </div>
                    </div> -->
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{ "iqc_type" }} </span>
                            <p-dropdown [options]="iqcType" [showClear]="true" optionLabel="label" optionValue="name" formControlName="iqcType" name="iqcType" [(ngModel)]="iqcData.iqcType" placeholder="{{ 'select_iqc_type' | translate }}">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list">
                                        <div>{{ user.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['iqcType']?.touched && addIQCForm.controls['iqcType'].invalid ">
                                <span *ngIf=" addIQCForm.controls['iqcType']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{ "breakdown_service" }} </span>
                            <input type="number" pInputText autocomplete="off" class="w-100" formControlName="breakDownService" name="breakDownService" [(ngModel)]="iqcData.breakDownService" />
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['breakDownService']?.touched && addIQCForm.controls['breakDownService'].invalid ">
                                <span *ngIf=" addIQCForm.controls['breakDownService']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container>
                <div class="custella-form-container-title-two flex-between">
                    <span class="text-14 font-medium" translate>
                         {{'equipment_information'}}
                    </span>
                   
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3 pe-0">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" translate>{{'equipment_type'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.equipmentTypeName" formControlName="equipmentTypeName" name="equipmentTypeName" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['equipmentTypeName'].touched && addIQCForm.controls['equipmentTypeName'].invalid">
                                <span *ngIf="addIQCForm.controls['equipmentTypeName'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'equipment_number'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.equipmentNumber" formControlName="equipmentNumber" name="equipmentNumber" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['equipmentNumber'].touched && addIQCForm.controls['equipmentNumber'].invalid">
                                <span *ngIf="addIQCForm.controls['equipmentNumber'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'manufacturer'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.manufacturer" formControlName="manufacturer" name="manufacturer" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['manufacturer'].touched && addIQCForm.controls['manufacturer'].invalid">
                                <span *ngIf="addIQCForm.controls['manufacturer'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'make_model'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.model" formControlName="model" name="model" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['model'].touched && addIQCForm.controls['model'].invalid">
                                <span *ngIf="addIQCForm.controls['model'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'serial_number'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.serialNo" formControlName="serialNo" name="serialNo" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['serialNo'].touched && addIQCForm.controls['serialNo'].invalid">
                                <span *ngIf="addIQCForm.controls['serialNo'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'engine_number'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.engineNumber" formControlName="engineNumber" name="engineNumber" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['engineNumber'].touched && addIQCForm.controls['engineNumber'].invalid">
                                <span *ngIf="addIQCForm.controls['engineNumber'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'engine_number_two'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.engineNumber2" formControlName="engineNumber2" name="engineNumber2" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['engineNumber2'].touched && addIQCForm.controls['engineNumber2'].invalid">
                                <span *ngIf="addIQCForm.controls['engineNumber2'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'engine_make'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.engineNumber" formControlName="engineMake" name="engineMake" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['engineMake'].touched && addIQCForm.controls['engineMake'].invalid">
                                <span *ngIf="addIQCForm.controls['engineMake'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'engine_model'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.engineModel" formControlName="engineModel" name="engineModel" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['engineModel'].touched && addIQCForm.controls['engineModel'].invalid">
                                <span *ngIf="addIQCForm.controls['engineModel'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{'current_value_hours'}}</span>
                            <input type="text" pInputText autocomplete="off" class="w-100" [(ngModel)]="iqcData.equipmentMeterReading" formControlName="equipmentMeterReading" name="equipmentMeterReading" />
                            <div class="text-10 p-error" *ngIf="addIQCForm.controls['equipmentMeterReading'].touched && addIQCForm.controls['equipmentMeterReading'].invalid">
                                <span *ngIf="addIQCForm.controls['equipmentMeterReading'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title"></span>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- Hour Meter -->
            <ng-container>
                <div class="custella-form-container-title-two flex-between">
                    <span class="text-14 font-medium" translate>
                        {{'hour_meter'}}
                    </span>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{ "new_value_hours" }} </span>
                            <input type="number" pInputText autocomplete="off" class="w-100" formControlName="newMeterReading" onkeypress="return event.charCode >= 48" name="newMeterReading" [(ngModel)]="iqcData.newMeterReading" />
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['newMeterReading']?.touched && addIQCForm.controls['newMeterReading'].invalid ">
                                <span *ngIf=" addIQCForm.controls['newMeterReading']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate></span>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Equipment Checklist -->
            <ng-container>
                <div class="custella-form-container-title-two flex-between">
                    <span class="text-14 font-medium" translate>
                        {{'equipment_checklist'}}
                    </span>
                </div>
                <div *ngFor="let data of questionList;let i = index">
                    <div class="custella-form-container-title-two-grey" translate>{{data.name}}</div>
                    <div class="custella-form-container-content" *ngFor="let question of data.detail">
                        <div class="custella-content-row mb-3">
                            <div class="custella-content-row-input col-6">
                                <span class="custella-content-row-input-title" translate>{{question.question}}</span>
                                <div class="setup d-flex align-items-center">
                                    <button
                                        pButton
                                        type="button"
                                        class="rounded-default-button me-2 py-1"
                                        (click)="getAnswer(answer, question)"
                                        [ngClass]="{'rounded-default-button': question.selectedAnswer != answer._id, 'rounded-chosen-button': question.selectedAnswer == answer._id}"
                                        *ngFor="let answer of question.answers"
                                        translate
                                    >
                                        {{answer.answer}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- Remarks -->
            <ng-container>
                <div class="custella-form-container-title-two flex-between">
                    <span class="text-14 font-medium" translate>
                        {{'remarks'}}
                    </span>
                  
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3 pe-0">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" translate></span>
                            <textarea pInputTextarea autocomplete="off" area [(ngModel)]="iqcData.remark" formControlName="remark" name="remark" [maxlength]="320000"></textarea>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['remark']?.touched && addIQCForm.controls['remark'].invalid ">
                                <span *ngIf=" addIQCForm.controls['remark']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Container Footer -->
            <div class="custella-form-container-footer">
                <div class="d-flex align-items-center justify-content-end my-dialog">
                    <button class="custella-btn-white text-12 ms-2 h-32" (click)="goBack()">{{'cancel'|translate}}</button>
                    <p-button (click)="showModalDialog()" [label]="buttonLabel" styleClass="ms-2 h-32"></p-button>
                    <div class="select-time">
                        <p-dialog header="" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                            <ng-template pTemplate="header">
                                <span class="w-100" translate>{{'status_details'}}</span>
                                <span class="p-input-icon-right"> <i class="pi pi-times" (click)="displayModal=false"></i></span>
                            </ng-template>
                            <span class="text-12" translate>{{'status'}}</span>
                            <p-dropdown
                                [options]="statuses"
                                [showClear]="true"
                                optionLabel="label"
                                optionValue="name"
                                formControlName="status"
                                name="status"
                                [(ngModel)]="iqcData.status"
                                placeholder="{{ 'select_iqc_status' | translate }}"
                                (onChange)="changeStatus()"
                            >
                            </p-dropdown>
                            <div class="p-error col px-0 py-0 text-left" *ngIf=" addIQCForm.controls['status']?.touched && addIQCForm.controls['status'].invalid ">
                                <span *ngIf=" addIQCForm.controls['status']?.errors?.required " id="label_RequiredField" translate>{{ "required_field" }}</span>
                            </div>
                            <div class="row mt-2 mx-1">
                                <div class="col-md-12">
                                    <div *ngIf="statusMessage !=''" class="py-2 px-3 d-flex align-items-center" style="background-color: #efefef; border-radius: 8px;">
                                        <img class="mr-3" src="../../../../../assets/images/info_dark.png" style="height: 20px; margin-right: 3px;" alt="" />
                                        <p class="mb-0 text-1-5">{{statusMessage}}</p>
                                    </div>
                                </div>
                            </div>
                            <ng-template pTemplate="footer">
                                <button pButton pRipple class="p-button-secondary" (click)="displayModal=false" label="Cancel"></button>
                                <p-button (click)="onSubmitForm()" label="Proceed" class="p-button-text"></p-button>
                            </ng-template>
                        </p-dialog>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
