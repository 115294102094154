import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

export interface Sla {
  setFields(element: Sla): unknown;
  id?: Number;
  type?: String;
  initDateTime?: any;
  estimatedEndDateTime?: Number;
  atRiskDateTime?: any;
  violationDateTime?: any;
  status?: String;
  completedAt?: any;
  slaPackage?: {
    name: String;
    type: String;
    active: Boolean;
    createdDate: String;
    id: Number;
  };
  slaStepDuration?: Number;
  slaStepAtRiskDuration?: Number;
  slaStepViolationDuration?: any;
  slaStepName?: String;
  slaStepType?: String;
  slaPackageType?: String;
  slaPackageBusinessHourName?: String;
  lastPausedAt?: any;
  slaStatus?: String | any;
  violated?: Boolean;
  risked?: Boolean;
  completed?: Boolean;
  slaStatusData?: String | any;
  //extra
  clicked?: Boolean;
  diffMs?: any;
  percentage?: any;
  violationdays?: any;
  timeConverType?: any;
  statusTest?: any;
  colorCode?: String;
  isPaused?: boolean;
  //card
  isPassedRisk?: String;
  isViolated?: String;
  isCompletion?: String;
  passedRiskLbl?: String;
  violatedLbl?: String;
  completedLbl?: String;
  passedRiskText?: String;
  violatedText?: String;
  completedText1?: String;
  progrressText1?: String;
  progrressText2?: String;
  progrressVal?: String;
  completedText2?: String;
  progrressStatus?: any;

  isScenario1: Boolean;
  isScenario2: Boolean;
  isScenario3: Boolean;
  isScenario4: Boolean;
  isScenario5: Boolean;
  isScenario6: Boolean;
  isScenario7: Boolean;
  isScenario8: Boolean;

}

export class SlaForm implements Sla {
  now = new Date();
  id: Number;
  type: String;
  initDateTime: String;
  estimatedEndDateTime: Number;
  atRiskDateTime: String;
  violationDateTime: any;
  status: String;
  completedAt: string;
  slaPackage: {
    name: String;
    type: String;
    active: Boolean;
    createdDate: String;
    id: Number;
  };
  slaStepDuration: Number;
  slaStepAtRiskDuration: Number;
  slaStepViolationDuration: Number;
  slaStepName: String;
  slaStepType: String;
  slaPackageType: String;
  slaPackageBusinessHourName: String;
  lastPausedAt: null;
  slaStatus: String = "";
  violated: Boolean;
  risked: Boolean;
  completed: Boolean;
  slaStatusData: String | any;
  //extra
  clicked: Boolean;
  diffMs: any;
  percentage: any = 0;
  violationdays: any;
  timeConverType: any;
  statusTest: any;
  colorCode: String = '';
  isPaused: boolean = false;
  //card
  isPassedRisk: String = "";
  isViolated: String = "";
  isCompletion: String = "";
  passedRiskLbl: String = "";
  violatedLbl: String = "";
  completedLbl: String = "";
  passedRiskText: String = "";
  violatedText: String = "";
  completedText1: String = "";
  progrressText1: String = "";
  progrressText2: String = "";
  progrressVal: String = "";
  completedText2: String = "";
  progrressStatus: String = "";

  isScenario1: Boolean = false;
  isScenario2: Boolean = false;
  isScenario3: Boolean = false;
  isScenario4: Boolean = false;
  isScenario5: Boolean = false;
  isScenario6: Boolean = false;
  isScenario7: Boolean = false;
  isScenario8: Boolean = false;

  constructor(data: any, private util: UtilServiceService) {
    // console.log(data);

    this.id = data.id;
    this.type = data.type;
    this.initDateTime = data.initDateTime;
    this.estimatedEndDateTime = data.estimatedEndDateTime;
    this.atRiskDateTime = data.atRiskDateTime;
    this.violationDateTime = data.violationDateTime;
    this.status = data.status;

    this.completedAt = data.completedAt;
    this.slaPackage = data.slaPackage;
    this.slaStepDuration = data.slaStepDuration;
    this.slaStepAtRiskDuration = data.slaStepAtRiskDuration;
    this.slaStepViolationDuration = data.slaStepViolationDuration;
    this.slaStepName = data.slaStepName;
    this.slaStepType = data.slaStepType;
    this.slaPackageType = data.slaPackageType;
    this.slaPackageBusinessHourName = data.slaPackageBusinessHourName;

    this.lastPausedAt = data.lastPausedAt;
    this.slaStatus = data.slaStatus;
    this.violated = data.violated;
    this.risked = data.risked;
    this.completed = data.completed ? true : false;
    this.slaStatusData = data.slaStatusData;
    //extra
    this.clicked = data.clicked;
    this.diffMs = data.diffMs;
    this.percentage = data.percentage;

    data["completed"] = data.status == "ENDED";
    this.setFields(data);
  }

  setFields(data: any) {
    // console.log('data xxxxxxxxxxxxxx');
    this.now = new Date();
    this.isPassedRisk = data.risked ? "Yes" : "No";
    this.isViolated = data.violated ? "Yes" : "No";
    this.isCompletion = data.status == "ENDED" ? "Yes" : "No";
    this.progrressVal = this.duration(data.initDateTime, this.now);//todo set progress value

    switch (true) {
      case !data.risked && !data.violated && !data.completed && !data.isPaused: //case #1
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Time lapsed so far:";
        this.passedRiskText = "NO";
        this.violatedText = "NO";
        this.completedText1 = this.duration(data.initDateTime, this.now);
        this.completedText2 = `(violation in ${this.duration(this.now, data.violationDateTime)
          })`;
        this.progrressText1 = this.duration(this.now, data.violationDateTime);
        this.progrressText2 = 'before Violation';
        this.progrressStatus = "In Progress";
        this.isScenario1 = true; this.isScenario2 = false; this.isScenario3 = false;
        this.isScenario4 = false; this.isScenario5 = false; this.isScenario6 = false;
        this.isScenario7 = false; this.isScenario8 = false;
        break;
      case !data.risked && !data.violated && data.completed && !data.isPaused: //case #2
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Completed:";
        this.passedRiskText = "No";
        this.violatedText = "No";
        this.completedText1 = data.completedAt;
        this.completedText2 = `(within ${this.duration(data.initDateTime, data.completedAt)})`;
        this.progrressText1 = `Completed within`;
        this.progrressText2 = this.duration(data.initDateTime, data.completedAt);
        this.progrressStatus = "Completed";
        this.isScenario1 = false; this.isScenario2 = true; this.isScenario3 = false;
        this.isScenario4 = false; this.isScenario5 = false; this.isScenario6 = false;
        this.isScenario7 = false; this.isScenario8 = false;
        break;
      case data.risked && !data.violated && !data.completed && !data.isPaused: //case #3
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Time lapsed so far:";
        this.passedRiskText = "Yes";
        this.violatedText = "NO";
        this.completedText1 = this.duration(data.initDateTime, this.now);
        this.completedText2 = `(violation in ${this.duration(data.initDateTime, this.now)})`;
        this.progrressText1 = this.duration(this.now, data.violationDateTime);
        this.progrressText2 = `before Violation`;
        this.progrressStatus = "At Risk";
        this.isScenario1 = false; this.isScenario2 = false; this.isScenario3 = true;
        this.isScenario4 = false; this.isScenario5 = false; this.isScenario6 = false;
        this.isScenario7 = false; this.isScenario8 = false;
        break;
      case data.risked && !data.violated && data.completed && !data.isPaused: //case #4
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Completed:";
        this.passedRiskText = "Yes";
        this.violatedText = "No";
        this.completedText1 = data.completedAt;
        this.completedText2 = `(within ${this.duration(data.initDateTime, data.completedAt)})`;
        this.progrressText1 = `Completed within`;
        this.progrressText2 = this.duration(data.initDateTime, data.completedAt);
        this.progrressStatus = "Completed";
        this.isScenario1 = false; this.isScenario2 = false; this.isScenario3 = false;
        this.isScenario4 = true; this.isScenario5 = false; this.isScenario6 = false;
        this.isScenario7 = false; this.isScenario8 = false;
        break;
      case data.risked && data.violated && !data.completed && !data.isPaused: //case #5
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Time lapsed so far:";
        this.passedRiskText = "Yes";
        this.violatedText = "Yes";
        this.completedText1 = this.duration(data.initDateTime, this.now);
        this.completedText2 = `(${this.duration(data.violationDateTime, this.now)} delayed so far)`;
        this.progrressText1 = this.duration(data.violationDateTime, this.now);
        this.progrressText2 = 'since Violation';
        this.progrressStatus = "Violated";
        this.isScenario1 = false; this.isScenario2 = false; this.isScenario3 = false;
        this.isScenario4 = false; this.isScenario5 = true; this.isScenario6 = false;
        this.isScenario7 = false; this.isScenario8 = false;
        break;
      case data.risked && data.violated && data.completed && !data.isPaused: //case #6
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Completed:";
        this.passedRiskText = "Yes";
        this.violatedText = "Yes";
        this.completedText1 = data.completedAt;
        this.completedText2 = `(${this.duration(data.violationDateTime, data.completedAt)} after violation)`;
        this.progrressText1 = `Completed ${this.duration(data.violationDateTime, data.completedAt)}`;
        this.progrressText2 = `after Violation`;
        this.progrressStatus = "Violated";
        this.isScenario1 = false; this.isScenario2 = false; this.isScenario3 = false;
        this.isScenario4 = false; this.isScenario5 = false; this.isScenario6 = true;
        this.isScenario7 = false; this.isScenario8 = false;
        break;
      case !data.risked && !data.violated && !data.completed && data.isPaused: //case #7
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Paused at:";
        this.passedRiskText = "NO";
        this.violatedText = "NO";
        this.completedText1 = this.duration(data.initDateTime, data.lastPausedAt);
        this.completedText2 = `(${this.duration(data.lastPausedAt, data.violationDateTime)} remaining)`;
        this.progrressText1 = this.duration(data.lastPausedAt, data.violationDateTime);
        this.progrressText2 = `before Violation`;
        this.progrressStatus = "Paused";
        this.isScenario1 = false; this.isScenario2 = false; this.isScenario3 = false;
        this.isScenario4 = false; this.isScenario5 = false; this.isScenario6 = false;
        this.isScenario7 = true; this.isScenario8 = false;
        break;
      case data.risked && !data.violated && !data.completed && data.isPaused: //case #8
        this.passedRiskLbl = "At Risk:";
        this.violatedLbl = "Violated:";
        this.completedLbl = "Paused at:";
        this.passedRiskText = "Yes";
        this.violatedText = "NO";
        this.completedText1 = this.duration(data.initDateTime, data.lastPausedAt);
        this.completedText2 = `${this.duration(data.lastPausedAt, data.violationDateTime)} remaining)`;
        this.progrressText1 = this.duration(data.lastPausedAt, data.violationDateTime);
        this.progrressText2 = `before Violation`;
        this.progrressStatus = "Paused";
        this.isScenario1 = false; this.isScenario2 = false; this.isScenario3 = false;
        this.isScenario4 = false; this.isScenario5 = false; this.isScenario6 = false;
        this.isScenario7 = false; this.isScenario8 = true;
        break;
      default:
        break;
      // code block
    }
  }

  duration(s: any, e: any) {
    let startDateTime: any = new Date(s);
    let endDateTime: any = new Date(e);
    let diffMs = endDateTime - startDateTime;
    return this.time(diffMs < 0 ? startDateTime - endDateTime : diffMs);
  }
  time(milliseconds: number) {
    let timeConversion = this.util.timeConversion(milliseconds);
    let timeConverData = timeConversion.split("-");
    return timeConverData.length > 0 ? timeConverData[1] : "";
  }
  // {
  //   id: 3,
  //   type: "START",
  //   initDateTime: "2022-10-19T10:07:44Z",
  //   estimatedEndDateTime: "2022-10-19T10:07:44Z",
  //   atRiskDateTime: "2022-10-19T10:08:44Z",
  //   violationDateTime: "2022-10-19T10:09:44Z",
  //   status: "RUNNING", //for pause
  //   completedAt: null,
  //   slaPackage: {
  //     name: "Ticket SLA",
  //     type: "ticket",
  //     active: true,
  //     createdDate: "2022-07-06T07:42:19Z",
  //     id: 4,
  //   },
  //   slaStepDuration: 0,
  //   slaStepAtRiskDuration: 1,
  //   slaStepViolationDuration: 2,
  //   slaStepName: "Ticket Step 1",
  //   slaStepType: "ticket",
  //   slaPackageType: "ticket",
  //   slaPackageBusinessHourName: "24/7",
  //   lastPausedAt: null,
  //   slaStatus: "OPEN",
  //   violated: true,
  //   risked: true,
  //   slaStatusData: "VIOLATED",
  //   clicked: false,
  // }
}
