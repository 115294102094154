import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import * as moment from "moment-timezone";
import { ServerRequest } from "src/app/_models/global.data.model";
import { TicketsService } from "src/app/_services/tickets.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { Global } from "src/app/_utilities/global";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { AuthenticationService } from "src/app/auth/authentication.service";

@Component({
  selector: "app-ticket-table",
  templateUrl: "./ticket-table.component.html",
  styleUrls: ["./ticket-table.component.scss"],
})
export class TicketTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  @Input() searchValue = "";

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;

  allTickets: any[] = [];
  totalRecord = 0;
  ticketsData: boolean = false;
  overlayNoRowsTemplate: string;
  loadTable: number =0;
  sortBy: any;
  sortOrder: any;

  constructor(
    private ticketService: TicketsService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService: DynamicTableColumnsService,
    private auth: AuthenticationService,
  ) {
    super();

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    // this.columnDefs = [
    //   {
    //     headerName: "Ticket ID",
    //     field: "ticketNo",
    //     width: 200,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["font-weight-bold table_default_color"];
    //     },
    //   },
    //   {
    //     headerName: "Subject",
    //     field: "subject",
    //     width: 220,
    //     headerTooltip: "#",
    //   },
    //   {
    //     headerName: "Customer",
    //     field: "customer.name",
    //     width: 200,
    //     headerTooltip: "#",
    //     cellClass: function (params: any) {
    //       return ["font-weight-bold table_default_color"];
    //     },
    //   },

    //   {
    //     headerName: "Date & Time",
    //     field: "createdDate",
    //     width: 200,
    //     headerTooltip: "#",
    //     cellRenderer: function (params: any) {
    //       if (params.data) {
    //         return moment(params.data.createdDate).format(
    //           localStorage.getItem("date_format")!.toUpperCase() +
    //             ", " +
    //             "hh:mm A"
    //         );
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Type",
    //     field: "ticketType.name",
    //     width: 150,
    //     headerTooltip: "#",
    //   },
    //   {
    //     headerName: "Priority",
    //     field: "priority.name",
    //     width: 150,
    //     headerTooltip: "#",
    //   },
    //   {
    //     headerName: "Open Date & Time",
    //     field: "ticketOpenedDateTime",
    //     headerTooltip: "#",
    //     width: 200,
    //     cellRenderer: function (params: any) {
    //       if (params && params.data) {
    //         return params.data.ticketOpenedDateTime
    //           ? moment(params.data.ticketOpenedDateTime).format(
    //               localStorage.getItem("date_format")!.toUpperCase() +
    //                 ", " +
    //                 "hh:mm A"
    //             )
    //           : "";
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Status",
    //     width: 150,
    //     field: "ticketStatus.fieldLabel",
    //     headerTooltip: "#",
    //     cellRenderer: (param: any) => {
    //       let status = "";
    //       if (param.data && param.data.ticketStatus) {
    //         const colorCode = param.data.ticketStatus.colorCode
    //           ? param.data.ticketStatus.colorCode
    //           : "#6366F1";

    //         status =
    //           ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
    //           colorCode +
    //           "50 !important ;color:" +
    //           colorCode +
    //           '"><span class="p-tag-value text-dark">' +
    //           param.data.ticketStatus.fieldLabel +
    //           "</span></span>";
    //       }

    //       return status;
    //     },
    //   },
    // ];

    this.overlayNoRowsTemplate =
      "<div class='not-found'><span>Record not found</span> </div>";
  }

  ngOnInit(): void {
    this.getTableColumn()
  }
  /******************************************** Pre Init ******************************************/
 private getTableColumn() {
   
  this.push(
    this.dynamicFormService.getTableColumn("ticket")
      .subscribe((res: any) => {
        this.sortBy = res.sortBy
        this.sortOrder = res.sortOrder
       
          var columnDefs: any = this.gridOptions.columnDefs;
          res.tableColumnSetups.forEach((element: any) => {
            this.cellRenderer(element);
  
            //console.log(element)
            columnDefs.push(element);
            this.gridOptions.api!.setColumnDefs(columnDefs);
          });
          
          this.loadTable = 1;
          this.gridOptions.api!.setColumnDefs(columnDefs);
          this.onGridReady(this.gridParams);
      })
  );


}
/******************************************** Render Cell ******************************************/
private cellRenderer(element: any) {
  var dateFormat: any = localStorage.getItem("date_format");
  element.headerTooltip = "#";
  if (element.field === "ticketNo") {
    element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadTixNo'),
    element.cellRenderer = function (params: any) {
      if(params.data){
        return  Global.setCellRenderer(params,params.data.rowId, params.data.ticketNo)
      }
    };
  
  }
else if (element.field === "customerNumber") {
  element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadCusNo'),
  element.cellRenderer = function (params: any) {
    if(params.data){
      return  Global.setCellRenderer(params,params.data.rowId, params.data.customerNumber)
    }
  };

}
else if (element.field === "ticketStatus.fieldLabel") {
  element.cellRenderer = function (params: any) {
    let status = "";
          if (params.data && params.data.ticketStatus) {
            const colorCode = params.data.ticketStatus.colorCode ? params.data.ticketStatus.colorCode  : "#6366F1";

            status =
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              colorCode +
              "50 !important ;color:" +
              colorCode +
              '"><span class="p-tag-value text-dark">' +
              params.data.ticketStatus.fieldLabel +
              "</span></span>";
          }

          return status;
        
  }
}

 else if (element.field === "customer.name") {
  element.cellClass = function (params: any) {
    return ["text-1-5 font-weight-bold table_default_color"];
  };
} else  if (element.dataType == 'date'  || element.dataType == "text" || element.dataType == "datetime" || element.dataType == "boolean") {
  element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
 }


return element;
}


  ngOnChanges(changes: SimpleChanges): void {
    // if (
    //   changes.searchValue.previousValue !== changes.searchValue.currentValue
    // ) {
    console.log(changes, "====");
    this.onGridReady(this.gridParams);
    // }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  /******************************************** init ag-grid and fetch data ******************************************/
  public onGridReady(params: any) {
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable === 1) { this.getServerSideData(params);}
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** get server data ******************************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);

    this.push(
      this.ticketService
        .getAllTicket(
          "",
          "",
          null,
          null,
          null,
          null,
          this.searchValue,
          serverRequest
        )
        .subscribe(
          (data: any) => {
            this.allTickets = data.data;
            this.totalRecord = data.total;

            this.setGridData(
              agGridGetRowsParams,
              this.allTickets,
              data.total,
              data.status
            );
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }

  /******************************************** set grid data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    if (status === "NO_DATA") {
      this.ticketsData = false;
    } else {
      this.ticketsData = true;

      if (resultItems.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(resultItems, totalCount);
        this.gridApi.sizeColumnsToFit();
      }
    }
  }

  /******************************************** get request params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder }
      agGridRequest.sortModel.push(this.parameters);
    }

    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** view ticket and set as value ******************************************/
  public viewTicket($event: any) {
    this.ticketService.setSeletTicket($event.data);
  }

  /******************************************** table context menu row table ******************************************/
  public getContextMenuItems = (params: any) => {
    const col = params.column.colId;
    let url = "";
    let id = 0;
    // let result = [
    //   {
    //     name: "Open link in new tab",
    //     action: () => {
    //       this.util.resetTabView();

    //       this.util.openItemToNewTab(url)
    //     },
    //   },
    // ];

    // if (col === "ticketNo") {
    //   id = params.node.data.id;
    //   url = `/dashboard/tickets/view/${id}?id=${id}`;
    // }

    // if (col === "customer.name") {
    //   id = params.node.data.customer.id;
    //   url = `/dashboard/customers/view/${id}?id=${id}`;
    // }

    //return result;
    return this.dynamicTableColumnsService.rightClick(params);
  };
 
  push(obs:any) {
    super.push(obs);
  }
}
