<div class="custella-modal d-flex flex-column">
  <div class="custella-modal-header">
    <div class="custella-modal-header-text" id="title_JobCompletionForm"> {{ "completion_form" | translate }}</div>

    <i class="pi pi-times" [pTooltip]="'close'|translate" tooltipPosition="left" id="btn_JobCompletionFormClose" (click)="modalRef.hide()"></i>
  </div>

  <div class="custella-modal-body">
    <!-- NAME AND IC PASSPORT -->
    <ng-container *ngIf="data?.jobOrderCompletionRecordVm?.personName">
      <div
        class="custella-form-container-title flex-between rounded-top"
      >
        <div class="font-medium text-14" id="title_JobComplNameIc" translate>
          {{ "name_and_ic_passport" }}
        </div>
      </div>

      <div
        class="custella-form-container-content-border-bottom text-12 font-medium"
      >
        <div class="custella-content-row">
          <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_JobComplName" translate>
              {{ "name" }}
            </span>

            <p class="custella-content-row-input-title" id="value_JobComplName">
              {{ data?.jobOrderCompletionRecordVm?.personName }}
            </p>
          </div>

          <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_JobComplIcPassport" translate>
              {{ "ic_passport" }}
            </span>

            <p class="custella-content-row-input-title" id="value_JobComplIcPassport">
              {{ data?.jobOrderCompletionRecordVm?.icPassportNumber }}
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- PHOTO -->
    <ng-container
      *ngIf="
        data?.jobOrderCompletionRecordVm?.attachmentList &&
        data?.jobOrderCompletionRecordVm?.attachmentList.length
      "
    >
      <div
        class="custella-form-container-title flex-between rounded-top"
      >
        <div class="font-medium text-14" id="title_JobComplPhoto" translate>
          {{ "photo" }}
        </div>
      </div>

      <div
        class="custella-form-container-content-border-bottom text-12 font-medium"
      >
        <div class="custella-content-row">
          <div
            class="custella-content-row-input col"
            *ngFor="
              let attachment of data?.jobOrderCompletionRecordVm?.attachmentList
            "
          >
            <a
              [href]="attachment.attachmentUrl"
              target="_blank"
              class="pr-3 pt-1"
              id="link_JobComplAttach"
            >
              <img
                class=""
                style="
                  object-fit: cover;
                  object-position: center;
                  border-radius: 6px;
                  height: 65px;
                  width: 65px;
                "
                [src]="attachment.attachmentUrl"
                alt=""
                id="img_JobComplAttach"
              />
            </a>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- PDF -->
    <ng-container>
      <div
        class="custella-form-container-title flex-between rounded-top"
      >
        <div class="font-medium text-14" id="title_JobComplForm" translate>
          {{ "job_order_completion_form" }}
        </div>
      </div>

      <div
        class="custella-form-container-content-border-bottom text-12 font-medium"
      >
        <div class="custella-content-row">
          <div class="custella-content-row-input col-12">
            <p class="custella-content-row-input-title">
              <a
              class="text-secondary"
                [href]="
                  data?.jobOrderCompletionRecordVm?.jobOrderPdf?.attachmentUrl
                "
                target="_blank"
                id="link_JobComplFormPdf"
              >
                {{ data?.jobOrderCompletionRecordVm?.jobOrderPdf?.fileName }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Location -->
    <ng-container *ngIf="data?.locationLat">
      <div
        class="custella-form-container-title flex-between rounded-top"
      >
        <div class="font-medium text-14" id="title_JobComplLoc" translate>
          {{ "location" }}
        </div>
      </div>

      <div
        class="custella-form-container-content-border-bottom text-12 font-medium"
      >
        <div class="custella-content-row">
          <div class="custella-content-row-input col-12">
            <p-gmap
              [options]="options"
              [overlays]="overlays"
              [style]="{ width: '100%', height: '400px' }"
            ></p-gmap>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- USER RECORD -->
    <ng-container>
      <div
        class="custella-form-container-title flex-between rounded-top"
      >
        <div class="font-medium text-14" id="title_JobComplSysInfo" translate>
          {{ "system_information" }}
        </div>
      </div>

      <div
        class="custella-form-container-content-border-bottom text-12 font-medium"
      >
        <div class="custella-content-row">
          <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_JobComplCreatedBy" translate>
              {{ "created_by" }}
            </span>

            <div class="custella-vp-picture d-flex align-items-center">
              <p-avatar
                [image]="data.jobOrderCompletionRecordVm?.createdByUserImageUrl"
                styleClass="mr-2 mt-2"
                shape="circle"
                *ngIf="
                  data.jobOrderCompletionRecordVm?.createdByUserImageUrl &&
                  data.jobOrderCompletionRecordVm?.createdByUserImageUrl !== ''
                "
                id="img_JobComplCreatedByAvatar"
              ></p-avatar>
              <p-avatar
                image="assets/images/profile-pic.png"
                styleClass="mr-2 mt-2"
                shape="circle"
                *ngIf="
                  !data.jobOrderCompletionRecordVm?.createdByUserImageUrl ||
                  data.jobOrderCompletionRecordVm?.createdByUserImageUrl == ''
                "
                id="img_JobComplCreatedByAvatar"
              ></p-avatar>

              <p class="custella-content-row-input-title-three mx-2" id="value_JobComplCreatedByFullName">
                {{ data?.jobOrderCompletionRecordVm?.createdByUserName }}
                <span class="" id="value_JobComplCreatedByDateTime"
                  >,
                  {{
                    data?.jobOrderCompletionRecordVm?.createdDateTime
                      | momentDate: "hh:mm a"
                  }},
                  {{
                    data?.jobOrderCompletionRecordVm?.createdDateTime
                      | momentDate: dateFormat:timezone
                  }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
