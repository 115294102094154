<div>
    <div class="flex-between"> 
     <i class="pi pi-times w-24px dialog-close-icon" id="btn_CusClose"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="closeCustomer()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="title" id="label_CusSelectCustomerList">{{'select_a_customer_list' | translate}}</span>
      <div class="flex-align-center mt-2">
        <span class="p-input-icon-left w-100 py-2">
          <i class="pi pi-search"></i>
          <input type="text" pInputText autocomplete="off" id="input_CusSearchCustomerField"  [placeholder]="'type_customer_name' | translate"  [(ngModel)]="searchValue"/>
        </span>
      </div>
      <div class="height-42-stat border">
        <app-customer-table [lookupFilter]="lookupFilter" [searchValue]="searchValue"></app-customer-table>
      </div>
    </div>
  
  </div>