import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { DeliveryOrderService } from "src/app/_services/delivery-order.service";

declare var google: any;
import * as _ from "lodash";
import { BsModalRef } from "ngx-bootstrap/modal";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-map-view-track",
  templateUrl: "./map-view-track.component.html",
  styleUrls: ["./map-view-track.component.scss"],
})
export class MapViewTrackComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  map: any;

  data: any;

  options: any;
  overlays: any[] = [];

  showMap = false;

  public latitude: number = 3.100176;
  public longitude: number = 101.591085;
  tasks: any[] = [];
  markers: any[] = [];

  latlngBounds: any;

  private id: any;

  constructor(
    private route: ActivatedRoute,
    private deliveryOrderService: DeliveryOrderService,
    public modalRef: BsModalRef,
    private util: UtilServiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Init ******************************************/
  private async init() {
    const mapStyles = await this.util.getGoogleTheme().toPromise();

    this.options = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 12,
      styles: mapStyles.silver,
    };

    this.id = this.route.snapshot.queryParamMap.get("id") ?? this.data.id;

    setTimeout(() => {
      this.showMap = true;
      this.loadMap(this.id);
    }, 500);
  }

  /******************************************** On Map Ready ******************************************/
  public setGMap($event: any) {
    this.map = $event.map;
  }

  /******************************************** Load Map ******************************************/
  public loadMap(id: any): void {
    this.tasks = [];
    this.markers = [];
    let pickAddress;
    let dropAddress;

    this.push(
      this.deliveryOrderService
        .getDOpolyline(id)
        .subscribe((tasksResponse: any) => {
          try {
            this.tasks = tasksResponse.pickupLocation;

            if (tasksResponse.deleveryOrderLineItem.pickupBay) {
              pickAddress = tasksResponse.deleveryOrderLineItem.pickupBay.name;
            } else {
              pickAddress = tasksResponse.deleveryOrderLineItem.pickupAddress;
            }

            if (tasksResponse.deleveryOrderLineItem.dropOffBay) {
              dropAddress = tasksResponse.deleveryOrderLineItem.dropOffBay.name;
            } else {
              dropAddress = tasksResponse.deleveryOrderLineItem.dropOffAddress;
            }

            if (tasksResponse.polyline.length) {
              //Show only accepted tasks
              var marker: any = {
                latitude: tasksResponse.pickupLocation.location.lat,
                longitude: tasksResponse.pickupLocation.location.lon,
                location: pickAddress,
                icon: "../../../../assets/images/map-icons/0.png",
              };

              this.markers.push(marker);

              var marker: any = {
                latitude: tasksResponse.dropOffLocation.location.lat,
                longitude: tasksResponse.dropOffLocation.location.lon,
                location: dropAddress,
                icon: "../../../../assets/images/map-icons/1.png",
              };

              this.markers.push(marker);
            }

            var groupedMatchingLocations = _.groupBy(this.markers, "location");

            for (var key in groupedMatchingLocations) {
              if (
                groupedMatchingLocations.hasOwnProperty(key) &&
                groupedMatchingLocations[key].length > 1
              ) {
                for (var index in groupedMatchingLocations[key]) {
                  if (parseInt(index) > 0) {
                    groupedMatchingLocations[key][index].latitude =
                      groupedMatchingLocations[key][index].latitude +
                      (Math.random() - 0.00005) / 15000; // * (Math.random() * (max - min) + min);
                    groupedMatchingLocations[key][index].longitude =
                      groupedMatchingLocations[key][index].longitude +
                      (Math.random() - 0.00005) / 15000; // * (Math.random() * (max - min) + min);
                  }
                }
              }
            }

            this.processMap(this.markers, tasksResponse.polyline).then(() => {
              // this.spinner.hide();
            });
          } catch (e) {}
        })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Process Markers ******************************************/
  private processMarkers(markers: any): Promise<any[]> {
    return new Promise((resolve) => {
      const finalMarker: any[] = [];

      markers.forEach((marker: any) => {
        const gMarker = new google.maps.Marker({
          position: { lat: marker.latitude, lng: marker.longitude },
          title: "Location",
          icon: marker.icon,
        });

        finalMarker.push(gMarker);
      });

      resolve(finalMarker);
    });
  }

  /******************************************** Process Polyline ******************************************/
  private processPolyline(polyLines: any[]) {
    const fixedPoly: any[] = [];

    polyLines.forEach((pl: any) => {
      fixedPoly.push({ lat: pl.lat, lng: pl.lon });
    });

    const poly = new google.maps.Polyline({
      path: fixedPoly,
      strokeOpacity: 1,
      strokeWeight: 2,
      strokeColor: "blue",
      fillOpacity: 0,
    });

    return poly;
  }

  /******************************************** Process Map ******************************************/
  private processMap(markers: any[], polyLines: any[]) {
    return new Promise<void>(async (resolve) => {
      const finalMarkers = await this.processMarkers(markers);
      const finalPolyLine = this.processPolyline(polyLines);

      this.latlngBounds = new google.maps.LatLngBounds();

      finalMarkers.forEach((gMarker: any) => {
        this.latlngBounds.extend(gMarker.getPosition());
      });

      this.map.fitBounds(this.latlngBounds);

      this.overlays = [...finalMarkers];

      finalPolyLine.setMap(this.map);

      resolve();
    });
  }
  /******************************************** Add ALl the Subscription ******************************************/
 push(obs:any) {
  super.push(obs);
}
}
