<div class="custella-modal">
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_CheckProduct"> {{ "add_product" | translate }} </span>
            <i class="pi pi-times" (click)="modalRef.hide()" id="btn_CheckProductClose"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0">
                <form [formGroup]="addProductForm">
                    <div class="custella-form-container-title-two-grey fst-italic">
                        <div class="text-12 font-medium" id="subtitle_CheckProdAddProdQty">{{"add_prod_qty_under_jo" | translate}}</div>
                    </div>
                    <div class="custella-form-container-content my-2">
                        <div class="custella-content-row mb-3">
                            <div class="custella-content-row-input col-10">
                                <span class="custella-content-row-input-title mandatory" id="label_CheckProdDetailsName" translate>{{"product"}}</span>
                                <div class="d-flex align-items-center">
                                    <div class="p-input-icon-right w-100">
                                        <p-autoComplete
                                            (onSelect)="gotProduct($event)"
                                            (onKeyUp)="getProductList($event, 'products')"
                                            id="picklist_CheckProdDetailsNameField" 
                                            field="name"
                                            (onBlur)="onBlurProduct()"
                                            formControlName="name"
                                            [(ngModel)]="selectedProduct"
                                            [suggestions]="products"
                                        >
                                        </p-autoComplete>
                                        <i class="p-autocomplete-clear-icon pi pi-times" *ngIf="selectedProduct" id="btn_CheckProdDetailsNameClear" (click)="clearValue()"></i>
                                    </div>
          
                                    <button type="button" class="lookup-button ms-2 me-0" id="btn_CheckProdDetailsNameSearch" (click)="displayProductTable()">
                                        <i class="pi pi-search"></i>
                                    </button>
          
                                    <footer *ngIf="showProductTable" class="custella-form-container-footer hidePopup">
                                        <p-dialog header="Product Details" id="title_CheckProdDetails" [(visible)]="showProductTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true" [draggable]="false">
                                            <app-custella-products (closeProductView)="closeProductTable()" (productSelector)="gotProduct($event)"></app-custella-products>
                                        </p-dialog>
                                    </footer>
                                </div>
          
                                <div class="p-error text-10 col px-0 py-0 text-left" *ngIf="  addProductForm.controls['name'].touched && addProductForm.controls['name'].invalid " >
                                    <span *ngIf="addProductForm.controls['name'].errors?.required" id="label_CheckProdDetailsNameRequiredField" translate>{{ "required_field" }}</span>
                                </div>
                               </div>
                            <div class="custella-content-row-input col-2">
                                <span class="custella-content-row-input-title mandatory" id="label_CheckProdQty" translate> {{"qty"}} </span>
                               <input pInputText autocomplete="off"  id="input_CheckProdQtyField" type="number" class="form-control"  [(ngModel)]="product.quantity" formControlName="quantity" name="quantity" />
  
                        <div class="p-error text-10" *ngIf="addProductForm.controls['quantity'].touched && addProductForm.controls['quantity'].invalid">
                            <span *ngIf="addProductForm.controls['quantity'].errors?.required" id="label_CheckProdReqField" translate>{{ "required_field" }}</span>
                            <span *ngIf="addProductForm.controls['quantity'].errors?.pattern" id="label_CheckProdInvalidQty" translate>{{ "invalid_quantity" }}</span>
                        </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="custella-form-container-footer mb-0">
                    <div class="flex-align-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_CheckProdCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                        <p-button [label]="buttonLabel" styleClass="ms-2 h-32" id="btn_CheckProdSubmit"  (onClick)="onSubmit()"></p-button>
                    </div>
                </div>
            </div>
        </div>
</div>