import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ImportExportService } from 'src/app/_services/import-export.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { ModuleField } from '../import-data/import-data.component';

@Component({
  selector: 'app-import-upload-files',
  templateUrl: './import-upload-files.component.html'
})
export class ImportUploadFilesComponent implements OnInit {
  display: boolean = true;
  buttonLabel: string = 'Next';

  validFile: boolean = false;

  moduleFieldList: any = [];
  files: FileList | any;
  selectedFileName: string = "";
  remainingDdList: any = [];
  allList: any = [];


  fileHeaderValues: ModuleField[] | any[] = [];
  selectedFileHeaderValues: any = [];

  public modalRefRes: BsModalRef | any;
  @Output() action = new EventEmitter();

  appendData: any = {};

  constructor(public modalRef: BsModalRef, private util: UtilServiceService,
    private dataExportService: ImportExportService,
    private spinner: NgxSpinnerService,
    public options: ModalOptions,
    private modal: ModalServiceService,
    private cd: ChangeDetectorRef,
    private errorUtil: ErrorUtil) {

    this.appendData = options.initialState?.data;
    this.appendData = this.appendData ? this.appendData : {};
    this.selectedFileName = this.appendData.selectedFileName;
    this.validFile = this.appendData.validFile ?? false;
    this.fileHeaderValues = this.appendData.fileHeaderValues ?? [];
    this.allList = this.appendData.allList ?? [];
    this.moduleFieldList = this.appendData.moduleFieldList ?? [];
    this.selectedFileHeaderValues = this.appendData.selectedFileHeaderValues ?? [];
    this.remainingDdList = this.appendData.remainingDdList ?? [];
    this.files = this.appendData.files;
  }

  ngOnInit(): void {
  }

  uploadFileStep1(_files: FileList) {
    if (_files.length > 0) {
      this.spinner.show();
      this.files = _files;
      this.selectedFileName = this.files[0].name;
      const uploadfile: File | any = _files[0];
      const formData: FormData = new FormData();
      formData.append('file', uploadfile, uploadfile.name);
      this.dataExportService.uploadCSV(formData).subscribe((res: { status: number; _body: string; }) => {
        console.log(res);
        if (res.status == 200) {
          this.remainingDdList = [];
          this.fileHeaderValues = JSON.parse(res._body);

          this.moduleFieldList.forEach((mf: any, i: number) => {
            this.fileHeaderValues.forEach((fdv: any, j: number) => {
              if ((mf.name + '').toLowerCase().trim() == (fdv.csvHeaderName + '').toLowerCase().trim()
                || (mf.name + '').replace("_", ' ').trim() == (fdv.csvHeaderName + '').replace("_", ' ').trim()) {
                fdv["lookupData"] = mf.lookups;
                fdv["lookUpChk"] = false;
                fdv["deleteClick"] = false;
                fdv["custellaHeader"] = mf.name;
                fdv.moduleFieldName = mf.field;
                fdv.dataType = mf.type;

                this.selectedFileHeaderValues[j] = mf;
              }
            })
          });

          this.remainingDdList = _.difference(this.moduleFieldList, this.selectedFileHeaderValues);
          this.remainingDdList = this.remainingDdList.filter((l2: any) => !(typeof l2 == undefined || l2 == null));

          this.getData1();
          if (this.moduleFieldList.length < this.fileHeaderValues.length) {
            this.errorUtil.setErrorMessage(404, null, 'Number of file headers greater than number of module fields (' + this.moduleFieldList.length + '). ' +
              'Please select a valid file to upload.', 'error', 3000);
            this.validFile = false;
          } else this.validFile = true;

        } else {
          var resp = JSON.parse(res._body);
          this.validFile = false;
          this.errorUtil.setErrorMessage(404, null, resp.title, 'error', 3000);
        }
        this.spinner.hide();
      });
    } else {
      this.errorUtil.setErrorMessage(404, null, 'Please select a file to upload', 'error', 3000);
    }

  }

  getData1() {
    this.allList = [];
    if (this.selectedFileHeaderValues.length == 0) {
      this.allList = Array(this.fileHeaderValues.length).fill(_.cloneDeep(this.remainingDdList));
    } else {
      this.selectedFileHeaderValues.forEach((f: any, i: number) => {
        let list = [];
        if (!(typeof f == undefined || f == null)) list.push(f);
        list.push(...this.remainingDdList);
        this.allList[i] = _.cloneDeep(list);
      });
      // for (let i = 0; i <= this.selectedFileHeaderValues.length - 1; i++) {
      //   let list = [];
      //   if (!(typeof this.selectedFileHeaderValues[i] == undefined || this.selectedFileHeaderValues[i] == null))
      //     list.push(this.selectedFileHeaderValues[i]);
      //   list.push(...this.remainingDdList);
      //   if (list.length > 0) this.allList[i] = _.cloneDeep(list);
      // }

      if (this.fileHeaderValues.length != this.selectedFileHeaderValues.length) {
        for (let j = 1; j < this.selectedFileHeaderValues.length; j++) {
          let selectedFileHeaderValuesLength = this.selectedFileHeaderValues.length + j;
          if (this.fileHeaderValues.length >= selectedFileHeaderValuesLength) {
            this.allList[selectedFileHeaderValuesLength - 1] = _.cloneDeep(this.remainingDdList);
          } else {
            break;
          }
        }
      }
      let k: any[] = [];
      for (let index = 0; index < this.fileHeaderValues.length; index++) {
        k[index] = typeof this.allList[index] == undefined || this.allList[index] == null ? this.remainingDdList : this.allList[index];
      }
      this.allList = _.cloneDeep(k);
    }
  }

  next() {
    if (this.validFile || this.appendData.validFile) {
      this.appendData.validFile = this.validFile;
      this.appendData.allList = this.allList;
      this.appendData.fileHeaderValues = this.fileHeaderValues;
      this.appendData.selectedFileHeaderValues = this.selectedFileHeaderValues;
      this.appendData.selectedFileName = this.selectedFileName;
      this.appendData.remainingDdList = this.remainingDdList;
      this.appendData.files = this.files;
      this.appendData.moduleFieldList = this.moduleFieldList;


      this.modalRefRes = this.modal.openModal('listImports', this.appendData);
      // this.action.emit(this.appendData);
      this.modalRef.hide();
      // this.modalRefRes.content.action.subscribe((res: any) => {
      //   console.log(res)
      //   // this.itemList.push(res.data);
      //   // this.cd.detectChanges();
      //   // this.modal.openModal('listImports', null)
      // });
    }
  }

  goBack() {
    this.modalRefRes = this.modal.openModal('import', this.appendData);
    this.action.emit(this.appendData);
    this.modalRef.hide();
  }

}
