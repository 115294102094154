import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeliveryOrderSetupService } from 'src/app/_services/delivery-order-setup.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-delete-planning-hub',
  templateUrl: './delete-planning-hub.component.html',
  styleUrls: ['./delete-planning-hub.component.scss']
})
export class DeletePlanningHubComponent implements OnInit {
  title: any
  data: any
  formData :any = {
    dispatched:"DISPATCHED",
    inProgress:"IN_PROGRESS",
    onHold:"ON_HOLD",
    inComplete:"IN_COMPLETE",
    confirmed:"CONFIRMED"
  }
  statuses: any;
  dropDownData : any
  dropDownData2 : any
  dropDownData3 : any
  dropDownData4 : any
  dropDownData5 : any

  constructor(
    public modalRef : BsModalRef,
    private planningHubService: PlanningHubService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deliveryOrderSetupService : DeliveryOrderSetupService,
    private errorUtil: ErrorUtil
  ) { }

  ngOnInit() {
    console.log(this.data)
     

    if(!this.data.deliveryOrderStatusCountVM){
      this.spinner.show();
      this.planningHubService.getFleetRoutePlanById(this.data.draftPlanId,null).subscribe((data: any) => {
        if(data.status === 200 || data.status === 201){
          this.data = data.body;
          this.getDoStatus()
          this.spinner.hide();
        }
      })
    }else{
      this.getDoStatus()
    }
  }

  getDoStatus(){
    this.deliveryOrderSetupService.getAllStatus().subscribe((res:any) => {
      this.statuses = res;
      this.dropDownData = []
      this.dropDownData2 = []
      this.dropDownData3 = []
      this.dropDownData4 = []
      this.dropDownData5 = []

      res.forEach((element : any) => {

      if(this.data.planStatus==='DISPATCHED' || this.data.planStatus==='IN_PROGRESS'){
      
        console.log(this.data.deliveryOrderStatusCountVM,"===")
            if(this.data.deliveryOrderStatusCountVM.dispatchedCount != 0){
              if(element.name == 'CONFIRMED'){
                this.dropDownData.push(element)
              }else if(element.name == 'NEW'){
                this.dropDownData.push(element)
              }else if(element.name == 'DISPATCHED'){
                this.dropDownData.push(element)
              }else if(element.name == 'ON_HOLD'){
                this.dropDownData.push(element)
              }else if(element.name == 'IN_COMPLETE'){
                this.dropDownData.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData.push(element)
              }
            }
             if(this.data.deliveryOrderStatusCountVM.inCompletedCount != 0){
              //debugger
              if(element.name == 'IN_COMPLETE'){
                this.dropDownData2.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData2.push(element)
              }
            }
             if(this.data.deliveryOrderStatusCountVM.inprogressCount !=0 ){
              //debugger
              if(element.name == 'ON_HOLD'){
                this.dropDownData4.push(element)
              }else if(element.name == 'IN_COMPLETE'){
                this.dropDownData4.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData4.push(element)
              }
            }
             if(this.data.deliveryOrderStatusCountVM.onHoldCount != 0){
              
              if(element.name == 'ON_HOLD'){
                this.dropDownData3.push(element)
              }else if(element.name == 'IN_COMPLETE'){
                this.dropDownData3.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData3.push(element)
              }
            }


        }else if(this.data.planStatus==='CONFIRMED'){
            if(element.name == 'CONFIRMED'){
              this.dropDownData.push(element)
            }else if(element.name == 'NEW'){
              this.dropDownData.push(element)
            }else if(element.name == 'ON_HOLD'){
              this.dropDownData.push(element)
            }else if(element.name == 'IN_COMPLETE'){
              this.dropDownData.push(element)
            }else if(element.name == 'CANCELLED'){
              this.dropDownData.push(element)
            }
         
          }
            console.log( this.dropDownData2,"====",this.dropDownData4)

      })
     })
  }

  onSubmit() {
    this.planningHubService.deletePlanningHub(this.formData,this.data.draftPlanId).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, null ,  'Plan removed successfully', 'success',1000);
        this.router.navigate(['dashboard/planningHub/all']);
      }else if(res.status === 400){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        this.router.navigate(['dashboard/planningHub/all']);
      }else {
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    });
  }

}
