<div class="m-0">
    <div class="rounded-2">
        <div class="custella-form-container height-80 overflow-scroll position-relative d-block  m-0">
            <form [formGroup]="addJourneyForm">
                <!-- Container Content -->
                <div class="custella-form-container-title-two text-14 font-medium" id="title_TaskJourneyDet" translate>
                    {{"journey_details"}}
                </div>
                <div class="custella-form-container-content">
                    <!-- equipment_type -->
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyDateTime" translate> {{"date_time"}}</span>
                            <p-calendar [ngModelOptions]="{standalone: true}" readonly="true" id="picklist_TaskJourneyDateTimeField"
                                (onSelect)="onDateChange($event)" appendTo="body" pattern="hh:mm a"
                                dateFormat="dd/mm/yy" hourFormat="12" [(ngModel)]="addJourney.startDateTime"
                                [showTime]="true" [showSeconds]="false" class="me-1"
                                placeholder="{{'select_date'|translate}}" [yearNavigator]="true"
                                yearRange="2000:2030"appendTo="body"></p-calendar>
                            <div class="text-10 p-error"
                                *ngIf="addJourneyForm.controls['startDateTime'].touched && addJourneyForm.controls['startDateTime'].invalid">
                                <span *ngIf="addJourneyForm.controls['startDateTime'].errors?.required" id="label_TaskJourneyDateTimeReqField"
                                    translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyUser" translate> {{"user"}} </span>
                            <p-dropdown [options]="users" optionLabel="firstName" name="userId" id="picklist_TaskJourneyUserField"
                                placeholder="{{'select_user' | translate}}" formControlName="userId"
                                [(ngModel)]="addJourney.userId" optionValue="id" [showClear]="false" appendTo="body">
                                <ng-template let-user pTemplate="user">
                                    <div class="country-item" id="value_TaskJourneyUserField">
                                        <div>{{user.firstName}} {{user.lastName}}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="text-10 p-error"
                                *ngIf="addJourneyForm.controls['userId'].touched && addJourneyForm.controls['userId'].invalid">
                                <span *ngIf="addJourneyForm.controls['userId'].errors?.required" id="label_TaskJourneyUserReqField"
                                    translate>{{'required_field'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  Equipment -->
                <div class="custella-form-container-title-two text-14 font-medium" id="title_TaskJourneyStartEndLoc" translate> {{"start_end_location"}}
                </div>
                <div class="custella-form-container-content">
                    <!-- equipment_type -->
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyLocFrom" translate>{{"from"}}</span>
                            <div class="d-flex align-items-center">
                                <div class="col-10 col-xxl-10 d-flex p-0" *ngIf="addJourney.fromType == 'Task'">
                                    <p-dropdown [options]="locationByTaskData" name="fence" placeholder="Select Task"
                                        formControlName="fromTaskId" [(ngModel)]="addJourney.fromTaskId" id="picklist_TaskJourneyLocFromField"
                                        (onChange)="getTaskDetail('startLocation',addJourney.fromTaskId)"
                                        [showClear]="true" appendTo="body" optionLabel="customeName" optionValue="id"
                                        class="col-6 pl-0">
                                    </p-dropdown>
                                    <input pInputText autocomplete="off" readonly formControlName="startLocation" id="input_TaskJourneyLocFromField"
                                        [(ngModel)]="addJourney.startLocation.address" name="startLocation"
                                        placeholder="Select Task to Display Address" />

                                </div>
                                <div class="col d-flex p-0" *ngIf="addJourney.fromType != 'Task'">
                                    <input pInputText autocomplete="off" id="input_TaskJourneySearchLocField"
                                        placeholder="{{'search_for_location'|translate}}"
                                        (keyup)="getPlaceApiCall($event)" formControlName="startLocation"
                                        name="startLocation" autocorrect="off" autocapitalize="off" spellcheck="off"
                                        type="text" (change)="manualSearch('home')" #input_TaskJourneySearchLocField
                                        [(ngModel)]="addJourney.startLocation.address">
                                </div>
                                <div class="d-flex align-items-center justify-content-end ms-auto">
                                    <button class="custella-btn-purple p-1 ms-2" id="btn_TaskJourneyFromHome"
                                        (click)="setStartLocation('Home')"><img alt="logo" src="assets\images\home.png" id="img_TaskJourneyFromHome"
                                            class="w-24px " /></button>
                                    <button class="custella-btn-white-three p-1 ms-1" (click)="setStartLocation('Office')" id="btn_TaskJourneyFromOffice">
                                        <img alt="logo" src="assets\images\briefcase.png" class="w-24px " id="img_TaskJourneyFromOffice"/> </button>
                                    <button class="custella-btn-white-three p-1 ms-1" (click)="setStartLocation('Task')" id="btn_TaskJourneyFromTask"><img
                                            alt="logo" src="assets\svg\sidebar\task.svg" class="w-24px " id="img_TaskJourneyFromTask" /></button>
                                    <button class="custella-btn-white-three p-1 ms-1"
                                        (click)="setStartLocation('Current Location')" id="btn_TaskJourneyFromCurrent"> <img alt="logo"
                                            src="assets\images\map-marker.png" class="w-24px " id="img_TaskJourneyFromCurrent" /></button>
                                </div>
                            </div>
                            <div class="col-10 d-flex p-0">

                                <div class="col-6 pl-0" *ngIf="addJourney.fromType == 'Task'">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['fromTaskId'].touched && addJourneyForm.controls['fromTaskId'].invalid">
                                        <span *ngIf="addJourneyForm.controls['fromTaskId'].errors?.required" id="label_TaskJourneyLocFromReqField"
                                            translate>{{'required_field'}}</span>
                                    </div>
                                </div>
                                <div class="col-6 p-0" [ngClass]="{'col-md-12': addJourney.fromType != 'Task'}">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['startLocation'].touched && addJourneyForm.controls['startLocation'].invalid">
                                        <span *ngIf="addJourneyForm.controls['startLocation']?.errors?.required" id="label_TaskJourneyStartLocReqField"
                                            translate>{{'required_field'}}</span>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>
                    <div class="custella-content-row pe-0 mb-3">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title mandatory" id="label_TaskJourneyLocTo" translate>{{"to"}}</span>
                            <div class="d-flex align-items-center">
                                <div class="col-10 col-xxl-10 d-flex p-0" *ngIf="addJourney.toType == 'Task'">
                                    <p-dropdown [options]="locationByEndTaskData" name="fence" placeholder="Select Task" id="picklist_TaskJourneyLocToField"
                                        formControlName="toTaskId" [(ngModel)]="addJourney.toTaskId"
                                        (onChange)="getTaskDetail('endLocation',addJourney.toTaskId)" [showClear]="true"
                                        appendTo="body" optionLabel="customeName" optionValue="id" class="col-6 ps-0">

                                    </p-dropdown>
                                    <input pInputText autocomplete="off" readonly formControlName="endLocation" id="input_TaskJourneyLocToField"
                                        [(ngModel)]="addJourney.endLocation.address" name="endLocation"
                                        placeholder="Select Task to Display Address" />
                                </div>
                                <div class="col d-flex p-0" *ngIf="addJourney.toType != 'Task'">
                                    <input pInputText autocomplete="off" id="input_TaskJourneySearchLocToField"
                                        placeholder="{{'search_for_location'|translate}}"
                                        (keyup)="getPlaceApiCall1($event)" formControlName="endLocation"
                                        name="endLocation" autocorrect="off" autocapitalize="off" spellcheck="off"
                                        type="text" #officeAddressSearch (change)="manualSearch('office')"
                                        [(ngModel)]="addJourney.endLocation.address" id="officeAddressSearch1">
                                </div>
                                <div class="d-flex align-items-center justify-content-end ms-auto">
                                    <button class="custella-btn-purple p-1 ms-2" id="btn_TaskJourneyToHome" (click)="setEndLocation('Home')"><img
                                            alt="logo" src="assets\images\home.png" id="img_TaskJourneyToHome" class="w-24px " /></button>
                                    <button class="custella-btn-white-three p-1 ms-1" id="btn_TaskJourneyToOffice" (click)="setEndLocation('Office')">
                                        <img alt="logo" src="assets\images\briefcase.png" id="img_TaskJourneyToOffice" class="w-24px " /> </button>
                                    <button class="custella-btn-white-three p-1 ms-1" id="btn_TaskJourneyToTask" (click)="setEndLocation('Task')"><img
                                            alt="logo" src="assets\svg\sidebar\task.svg" id="img_TaskJourneyToTask" class="w-24px " /></button>
                                    <button class="custella-btn-white-three p-1 ms-1"
                                        (click)="setEndLocation('Current Location')" id="btn_TaskJourneyToCurrent"> <img alt="logo"
                                            src="assets\images\map-marker.png" id="img_TaskJourneyToCurrent" class="w-24px " /></button>
                                </div>
                            </div>
                            <div class="col-10 d-flex p-0">
                                <div class="col-6 pl-0" *ngIf="addJourney.toType == 'Task'">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['toTaskId'].touched && addJourneyForm.controls['toTaskId'].invalid">
                                        <span *ngIf="addJourneyForm.controls['toTaskId'].errors?.required" id="label_TaskJourneyLocToReqField"
                                            translate>{{'required_field'}}</span>
                                    </div>
                                </div>
                                <div class="col-6 p-0" [ngClass]="{'col-md-12': addJourney.toType != 'Task'}">
                                    <div class="text-10 p-error"
                                        *ngIf="addJourneyForm.controls['endLocation'].touched && addJourneyForm.controls['endLocation'].invalid">
                                        <span *ngIf="addJourneyForm.controls['endLocation']?.errors?.required" id="label_TaskJourneyEndLocReqField"
                                            translate>{{'required_field'}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- Duration & Distance -->
                <div class="custella-form-container-title-two flex-between"
                    translate>
                    <span class="font-medium text-14" id="title_TaskCalcDistTime">{{"calculated_distance_time" | translate}}</span>
                    <p-button label="Calculate" styleClass="p-button-secondary py-1" id="btn_TaskCalcDistTime"
                        (click)="calculateDistance()"></p-button>
                </div>
                <div class="custella-form-container-content">
                    <!-- equipment_type -->
                    <div class="d-flex mb-3">
                        <div class="custella-content-row-input me-5">
                            <span *ngIf="distanceData" class="custella-content-row-input-title" id="label_TaskCalcDuration"
                                translate>{{"duration"}}</span>
                            <span class="custella-content-row-input-title" id="value_TaskCalcDurationMsg"
                                style="color: black; font-size: 16px; font-weight: bold;"
                                translate>{{distanceData?.message}}</span>
                        </div>
                        <div class="custella-content-row-input">
                            <span *ngIf="distanceKM" class="custella-content-row-input-title" id="label_TaskCalcDistance"
                                translate>{{"distance"}}</span>
                            <span class="custella-content-row-input-title" id="value_TaskCalcDistanceMsg"
                                style="color: black; font-size: 16px; font-weight: bold;" translate>{{distanceKM}}
                                {{distanceKM ? 'KM':''}}</span>
                        </div>

                    </div>
                </div>
                <!-- Container Footer -->
                <div class="custella-form-container-footer-two">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_TaskJourneyCancel" (click)="goBackTable()">{{'cancel' | translate}}</button>
                        <p-button [label]="'Save'" [disabled]="submitTouched" id="btn_TaskJourneySubmit" (click)="onSubmitForm()" styleClass="ms-2 h-32"></p-button>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>