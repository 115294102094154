<form [formGroup]="splitDOForm" class="custella-custom-dialog" >
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 font-medium ms-3" id="title_DeliverSplitOrder">{{title}}</span>
        </div>
        <i class="pi pi-times w-24px" id="btn_DeliverSplitOrderClose" (click)="modalRef.hide()"></i>
    </div>
    <div class="custella-modal-body p-0">
        <!-- Container -->
        <div class="container px-4">
            <!-- Container Content -->

            <!-- Task name and Priority level -->
            <div class="custella-form-container-content m-0">
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title mandatory" id="label_DeliverDepot" translate>{{ "depot" }} </span>
                        <p-dropdown 
                        [options]="depots" 
                        optionLabel="name" 
                        id="picklist_DeliverDepotField"
                        placeholder="{{'all_depots' | translate}}" 
                        formControlName="depot" 
                        [(ngModel)]="splitDoData.depotId"
                        optionValue="id">
                        </p-dropdown>

                        <div class="text-10 p-error" *ngIf=" splitDOForm.controls['depot'].touched && splitDOForm.controls['depot'].invalid ">
                            <span *ngIf="splitDOForm.controls['depot'].errors?.required" id="label_DeliverDepotReqField" translate>{{ "required_field" }}</span>
                        </div>
                    </div>
                </div>
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title mandatory" id="label_DeliverDropoffDate" translate>Drop-off Date</span>
                        <span class="p-input-icon-right w-100">
                            <i class="pi pi-calendar" style="z-index: 1;"></i>
                            <p-calendar type="text" appendTo="body" [minDate]="maxDate" id="picklist_DeliverDropoffDateField" [(ngModel)]="splitDoData.secondLegPickupDate" formControlName="splitDate" name="splitDate" placeholder="{{'select_date' | translate}}" class="w-100" dateFormat="dd/mm/yy"   name="secondLegPickupDate" [yearNavigator]="true" yearRange="2000:2030"appendTo="body"></p-calendar>
                        </span>
                        <div class="text-10 p-error" *ngIf="splitDOForm.controls['splitDate'].touched && splitDOForm.controls['splitDate'].invalid">
                            <span *ngIf="splitDOForm.controls['splitDate'].errors?.required" id="label_DeliverDropoffDateReqField" translate>{{'required_field'}}</span>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer p-0">
        <button class="custella-btn-white mx-1 text-12" id="btn_DeliverSplitOrderCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
        <p-button type="button" (click)="onSubmitForm()" id="btn_DeliverSplitOrderSubmit"><p class="mb-0" translate>{{'Save'}}</p></p-button>
    </div>
</form>