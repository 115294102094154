import { Component, Input, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { HelpdeskService } from "src/app/_services/helpdesk.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-helpdesk-user-selection",
  templateUrl: "./helpdesk-user-selection.component.html",
  styleUrls: ["./helpdesk-user-selection.component.scss"],
})
export class HelpdeskUserSelectionComponent implements OnInit {
  @Input() genericData: any;

  public allUsers: any[] = [];
  private subscriptions: Subscription[] = [];
  public selectedUser = null;
  public isLoading = false;

  public elementStyle = {
    top: "0px",
    right: "0px",
  };

  constructor(
    private utilService: UtilServiceService,
    private helpDeskService: HelpdeskService,
    private authService: AuthenticationService
  ) {}

  ngOnInit() {
    const coordinates = this.genericData.coordinates;

    // set position of this component
    this.setPosition(coordinates.x, coordinates.y);
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /******************************************** set position of ui ******************************************/
  private setPosition(x: number, y: number) {
    let extraX = 130;
    let extraY = 60;

    this.elementStyle.right = `${x + extraX}px`;
    this.elementStyle.top = `${y - extraY}px`;
  }

  /******************************************** init ******************************************/
  private init(): void {
    this.isLoading = true;

    const userGroupId = this.genericData.rowData.data.userGroup
      ? this.genericData.rowData.data.userGroup.id
      : null;

    if (userGroupId !== null) {
      // get user of helpdesk access for assigning to ticket
      const sub = this.helpDeskService
        .getHelpdeskUsersByUserGroup(userGroupId)
        .subscribe(
          (resp) => {
            if (resp) {
              this.isLoading = false;
              this.allUsers = resp.filter(
                (user: any) => user.id !== Number(this.authService.getUserId())
              );
            }
          },
          (err) => {
            console.error(err);
            this.isLoading = false;
          }
        );

      this.subscriptions.push(sub);
    } else {
      this.isLoading = false;
    }
  }

  /******************************************** when user click the assign button ******************************************/
  public onTicketAssignee(): void {
    const dataToSend = {
      action: this.genericData.action,
      assigneeUser: this.allUsers.find(
        (user: any) => user.id === this.selectedUser
      ),
      coordinates: this.genericData.coordinates,
      rowData: this.genericData.rowData,
    };

    // call the toastr component
    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.ON_HELPDESK_TICKET_ASSIGNEE,
      data: dataToSend,
    });
  }

  /******************************************* close ******************************************/
  public close() {
    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.CLEAR_HELPDESK_COMPONENT,
      data: null,
    });
  }
}
