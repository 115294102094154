import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-custella-drivers",
  templateUrl: "./custella-drivers.component.html",
  styleUrls: ["./custella-drivers.component.scss"],
})
export class CustellaDriversComponent implements OnInit {
  public searchValue = "";

  @Input() unAssignedDrivers = false;

  @Output() itemSelector = new EventEmitter();
  @Output() closeView = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

   /******************************************** Close ******************************************/
  public onClose($event: any) {
    this.closeView.emit($event);
  }

  public close() {
    this.closeView.emit(false);
  }

  public itemSelected($event: any) {
    this.itemSelector.emit($event);
  }
}
