<div class="custella-form-container-content">  
  <!-- Billing Address -->
        <div class="custella-content-row p-0">
        <div class="custella-content-row-input col-12">
            <input type="text" pInputText autocomplete="off" id="input_SearGoogAddressField" style="width: calc(100% - 110px)" (keyup)="getPlaceApiCall($event)" (change)="manualSearch()" [ngModelOptions]="{standalone: true}" placeholder="Search Address" autocorrect="off" autocapitalize="off" spellcheck="off" #input_SearGoogAddressField [(ngModel)]="homeLocation">
          
            <p-accordion (onOpen)="toggle($event)" (onClose)="toggle($event)">
                <p-accordionTab [transitionOptions]="'0ms'" [(selected)]="activeState">
                    <p-header>
                        <span #header id="btn_SearGoogAddressMap">
                            Show Map
                        </span>
                    </p-header>
                    <p-gmap #gmap id="gmap"  (onOverlayDragEnd)="markerDragEnd($event)" (onMapClick)="mapClicked($event, 'home')"  [overlays]="overlays" [options]="options" [style]="{'width':'calc(100% - 3px)','height':'320px', 'margin-left':'3px', 'border': '1px solid #e2e2e2'}"></p-gmap>
                </p-accordionTab>
            </p-accordion>
        </div>
        </div>
        <div class="custella-content-row p-0">
        <div class="custella-content-row-input col-12">
            <p class="text-10 p-error" *ngIf="errorMessage" id="label_SearGoogStreetErrMsg">{{errorMessage}}</p>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogStreet">Street</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogStreetField" [(ngModel)]="loadingBay.street">
            </div>  
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogCity">City</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogCityField" [(ngModel)]="loadingBay.city"  class="w-100">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogState">State</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogStateField" [(ngModel)]="loadingBay.state" class="w-100">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogPostcode">Postcode</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogPostcodeField" [(ngModel)]="loadingBay.postCode">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogCountry">Country</span>
                <!-- <input type="text" pInputText autocomplete="off" id="input_SearGoogCountryField" [(ngModel)]="loadingBay.country" class="w-100"> -->
                <p-dropdown
                class="w-100"
                [(ngModel)]="loadingBay.country"
                name="country"
                [options]="countries"
                filterBy="name"
                optionLabel="name"
                optionValue="name"
                [filter]="true"
                [showClear]="true"
                placeholder="{{'select_country'|translate}}"
                id="input_SearGoogCountryField"
            ></p-dropdown>
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogLat">Latitude</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogLatField"  [(ngModel)]="loadingBay.locationLat" [readonly]="readOnlyFields.latitude">
            </div>
            <div class="custella-content-row-input mb-3 me-0">
                <span class="custella-content-row-input-title" id="label_SearGoogLong">Longitude</span>
                <input type="text" pInputText autocomplete="off" id="input_SearGoogLongField" [(ngModel)]="loadingBay.locationLong" [readonly]="readOnlyFields.longitude">
            </div>
            <div class="custella-content-row-input me-0" style="margin-top: 18px;" *ngIf="showLocateBtn">
                <div class="custella-content-row-input col-12">
                     <button type="button" class="custella-btn-long" id="btn_SearGoogLocate" (click)="manualAddressSearch()">
                        <span class="text-12" id="label_SearGoogLocate">Locate</span>
                    </button>
                </div>
            </div>
          </div>
     </div>
</div>

