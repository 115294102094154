
import {of as throwError,  Observable, Subject } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthenticationService } from '../auth/authentication.service';
import { Http, RequestOptions, Headers } from '@angular/http';
import { DeliveryOrderStatus } from '../_models/global.data.model';
@Injectable()
export class DeliveryOrderSetupService {
  public refresherStatusList$: EventEmitter<any>;
  public refresherFailureCodesList$: EventEmitter<any>;
  public refresherOrderTypeList$: EventEmitter<any>;
  public refresherServiceTypeList$: EventEmitter<any>;
  public refresherLoadingMethodsList$: EventEmitter<any>;
  public refresherChannelList$: EventEmitter<any>;
  public refresherShippingMethodeList$: EventEmitter<any>;

  // statuses
  private ENDPOINT_DELIVERY_ORDER_STATUSES="/delivery-order-statuses/paged";
  private ENDPOINT_CREATE_STATUS="/delivery-order-statuses";
  private ENDPOINT_CREATE_STATUS_BY_ID="/delivery-order-statuses/";

 // Failure Codes
 private ENDPOINT_DELIVERY_ORDER_FAILURE_CODES="/delivery-failure-codes/paged";
 private ENDPOINT_CREATE_FAILURE_CODES="/delivery-failure-codes";
 private ENDPOINT_CREATE_FAILURE_CODES_BY_ID="/delivery-failure-codes/";

 // Order types
 private ENDPOINT_DELIVERY_ORDER_TYPES="/delivery-order-types/paged";
 private ENDPOINT_CREATE_ORDER_TYPE="/delivery-order-types";
 private ENDPOINT_CREATE_ORDER_TYPE_BY_ID="/delivery-order-types/";

 // Service types
 private ENDPOINT_DELIVERY_SERVICE_TYPES="/service-types/paged";
 private ENDPOINT_CREATE_SERVICE_TYPE="/service-types";
 private ENDPOINT_CREATE_SERVICE_TYPE_BY_ID="/service-types/";


 // Loading Methods
 private ENDPOINT_DELIVERY_LOADING_METHODS="/loading-methods/paged";
 private ENDPOINT_CREATE_LOADING_METHOD="/loading-methods";
 private ENDPOINT_CREATE_LOADING_METHOD_BY_ID="/loading-methods/";
 

 public statusPickList: any

  constructor(
    private http: HttpClient,
    private auth: AuthenticationService,
    ) {
      this.refresherStatusList$ = new EventEmitter();
      this.refresherFailureCodesList$ = new EventEmitter();
      this.refresherOrderTypeList$ = new EventEmitter();
      this.refresherServiceTypeList$ = new EventEmitter();
      this.refresherLoadingMethodsList$ = new EventEmitter();
      this.refresherChannelList$ = new EventEmitter();
      this.refresherShippingMethodeList$ = new EventEmitter();
     }



public getStatusesGrid(search: any, parameters: any){
  let params = new HttpParams()
 
  if(search)
    params = params.set('search', search);
 
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    
  return this.http.get( environment.base_url+this.ENDPOINT_DELIVERY_ORDER_STATUSES, {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error.status );
  }),);
}

public createStatus(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_STATUS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public updateStatus(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_CREATE_STATUS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getStatusById(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_STATUS_BY_ID + id).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}
public deleteDoStatus(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_CREATE_STATUS_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherStatusList$.emit('Deleted Status');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}
public getAllStatus(){
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_STATUS+'?active=true').pipe(map( (resp:any) => {
    this.statusPickList = resp
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}


/////////////////////////////////////////// failure codes ////////////////////////////////////////

public getFailureCodesGrid(search: any, parameters: any){
  let params = new HttpParams()
 
  if(search)
    params = params.set('search', search);
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);

  return this.http.get( environment.base_url+this.ENDPOINT_DELIVERY_ORDER_FAILURE_CODES, {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public createFailureCodes(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_FAILURE_CODES, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public updateFailureCodes(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_CREATE_FAILURE_CODES, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getFailureCodesById(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_FAILURE_CODES_BY_ID + id).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public deleteDoFailureCodes(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_CREATE_FAILURE_CODES_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherFailureCodesList$.emit('Deleted Failure Code');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

/////////////////////////////////////////// order types ////////////////////////////////////////

public getOrderTypesGrid(search: any, parameters: any){
  let params = new HttpParams()
 
  if(search)
    params = params.set('search', search);
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( environment.base_url+this.ENDPOINT_DELIVERY_ORDER_TYPES, {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public createOrderType(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_ORDER_TYPE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public updateOrderType(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_CREATE_ORDER_TYPE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getOrderTypeById(id : any){
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_ORDER_TYPE_BY_ID + id).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getActiveOrderType(active: any = null){
  var active1=''
  if(active){ active1='?isActive=true'}
  return this.http.get( environment.base_url+'/delivery-order-types?isActive=true').pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public deleteDoOrderType(id : any){
  return this.http.delete( environment.base_url+this.ENDPOINT_CREATE_ORDER_TYPE_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherOrderTypeList$.emit('Deleted Order Type');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}


/////////////////////////////////////////// service types ////////////////////////////////////////

public getServiceTypesGrid(search: any, parameters: any){
  let params = new HttpParams()
 
  if(search)
    params = params.set('search', search);
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
 
    return this.http.get( environment.base_url+this.ENDPOINT_DELIVERY_SERVICE_TYPES, {observe: "response", params : params}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
    //console.log(error);
      return throwError( error );
    }),);
  }

public createServiceType(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_SERVICE_TYPE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public updateServiceType(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_CREATE_SERVICE_TYPE, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getServiceTypeById(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_SERVICE_TYPE_BY_ID + id).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public deleteDoServiceType(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_CREATE_SERVICE_TYPE_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherServiceTypeList$.emit('Deleted Service Type');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getAllServiceType(active: any = null){
  var active1=''
  if(active){ active1='?isActive=true'}
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_SERVICE_TYPE+active1).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

/////////////////////////////////////////// Loading Methods ////////////////////////////////////////

public getLoadingMethodsGrid(search: any, parameters: any){
  let params = new HttpParams()
 
  if(search)
    params = params.set('search', search);
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
 
  return this.http.get( environment.base_url+this.ENDPOINT_DELIVERY_LOADING_METHODS, {observe: "response", params : params}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public createLoadingMethod(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_CREATE_LOADING_METHOD, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public updateLoadingMethod(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_CREATE_LOADING_METHOD, body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getLoadingMethodById(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_LOADING_METHOD_BY_ID + id).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public deleteDoLoadingMethod(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_CREATE_LOADING_METHOD_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherLoadingMethodsList$.emit('Deleted Loading Method');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}
/*============================================ Get All Loading Methode ================================*/
public getAllLoadingMethode(active: any = null){
  var active1=''
  if(active){ active1='?isActive=true'}
  return this.http.get( environment.base_url+this.ENDPOINT_CREATE_LOADING_METHOD+active1).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}


/*============================================ Get All Channel ================================*/
public getAllChannel(){
  return this.http.get( environment.base_url+'/delivery-order-channels?active= true').pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public createChannel(body:any){
  return this.http.post( environment.base_url+'/delivery-order-channels', body, { observe: 'response' }).pipe(map( (resp:any) => {
   
      return resp;
    
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}


public getChannelById(channelId : any){
  return this.http.get( environment.base_url+'/delivery-order-channels/' + channelId, {observe: "response"}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public updateChannel(body:any){
  return this.http.put( environment.base_url+'/delivery-order-channels', body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}


public deleteChannel(id : any){
  return this.http.delete( environment.base_url+'/delivery-order-channels/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherChannelList$.emit('Deleted Channel');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

/*============================================ Get All Channel ================================*/
public getAllFailureCodes(){
  return this.http.get( environment.base_url+'/delivery-failure-codes?active=true').pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}


/*============================================ Get All shipping Methode ================================*/
public getAllShippingMethode(active: any = null){
  var active1=''
  if(active){ active1='?active=true'}
  return this.http.get( environment.base_url+'/shipping-methods'+active1).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public createShippingMethode(body:any){
  return this.http.post( environment.base_url+'/shipping-methods', body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public getShippingMethodeById(channelId: any){
  return this.http.get( environment.base_url+'/shipping-methods/' + channelId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}
public updateShippingMethode(body:any){
  return this.http.put( environment.base_url+'/shipping-methods', body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}

public deleteShippingMethode(id : any){
  return this.http.delete( environment.base_url+'/shipping-methods/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherShippingMethodeList$.emit('Deleted Shipping Method');
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}
}
