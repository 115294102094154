import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "src/app/auth/permission.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { GetContextMenuItemsParams, GridOptions, IServerSideGetRowsParams, MenuItemDef } from "ag-grid-community";
import "ag-grid-enterprise";
import { PERSISTANT_ITEM } from "src/app/_models/global.data.model";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { SettingService } from "src/app/_services/admin/setting.service";
import { ProductService } from "src/app/_services/product.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
var router: Router;
@Component({
  selector: "app-checklist-products",
  templateUrl: "./checklist-products.component.html",
  styleUrls: ["./checklist-products.component.scss"],
})
export class ChecklistProductsComponent   extends SubscriptionUtil implements OnInit, OnDestroy {
 
  @Input() checklistRecordId: any = null
  @Input() pmvId: any = null
  
  public searchValue:any
  searchValueData: boolean = false;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  products: any;
  currency: any;
  totalRecord: any;


  noProduct!: boolean;
  noProductIcon: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private perm: PermissionService,
    private util: UtilServiceService,
    private settingService: SettingService,
    private productService: ProductService,
    private deleteRecordService: DeleteRecordService,
  ) {
    super();
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "clientSide",
      paginationPageSize: 25,
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    this.columnDefs = [
      {headerName: "Product No.", field: "product.productNumber", width: 120,cellClass: function (params: any) {return ["table_default_color"]; }, headerTooltip: "#",  },
      {headerName: "Product Name",field: "product.name", width: 150, headerTooltip: "#" },
      {headerName: "SKU", field: "product.productSku",width: 140,headerTooltip: "#"},
       { headerName: "Status",field: "isActive",width: 80,headerTooltip: "#",
        cellRenderer: function (params: any) {
          if(params.data){
            if(params.data.product?.isActive){
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
            }else{
              return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
              }
          }
        },
      },
      {
        headerName: "Warranty",
        field: "warranty",
        width: 100,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.product?.warranty 
              ? params.data.product.warranty + " month(s)"
              : "0 month(s)";
          }
        },
      },
      {
        headerName: "Action",
        sortable: false,
        filter: false,
        width: 110,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "center" },
        cellRenderer: "buttonRenderer",
        cellRendererParams: {
          onClick: this.edit.bind(this),
          permissions: {
            update: this.perm.capable("products", "update"),
            remove: this.perm.capable("products", "remove"),
          },
          hidden: {
            update: !this.perm.capable("products", "update"),
            remove: !this.perm.capable("products", "remove"),
          },
        },
      },
    ];
    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
    this.overlayNoRowsTemplate =
      "<div class='not-found'><span>Record not found</span> </div>";
  }

  ngOnInit(): void {
    router = this.router;
    this.init();
  }

  /******************************************** init ******************************************/
  private init() {
    this.settingService.getOrgSettings(1).subscribe((res: any) => {
      this.currency = res.currency;
      this.getProductByCP()
    });
     }

  

  /******************************************** Format Currency ******************************************/
  private currencyFormatter(params: any) {
    var usdFormate = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
    });
    return `${this.currency}${usdFormate.format(params.value)}`;
  }

  /********************************** Click Cell Event / Edit - Delete *****************************/
  private edit(e: any) {
    if (e.action === "edit") {
      e.rowData.id= e.rowData.product.id
      this.util.setPersistantItem(PERSISTANT_ITEM.PRODUCT, e.rowData);

      this.router.navigate(["/dashboard/products/update"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      if(this.pmvId){
        e.rowData.deleteType = "PMV Product";
      }else{
        e.rowData.deleteType = "Checklist Product";
      }
     
      e.rowData.message=`Are you sure you want to delete ${e.rowData.product.productNumber} - ${e.rowData.product.name}?`,

       this.push(
          this.deleteRecordService.getItems(e.rowData).subscribe((data) => {
            if (data.status === 200) {
              this.onRefreshList();
            }
          })
        );

      
    }
  }

  /********************************** Refresh Data **************************/
  public onRefreshList() {
    this.getProductByCP();
    this.onGridReady(this.gridParams);
  }

  /**********************************Get Data By Checklist or pmvid **************************/
  getProductByCP() {
    this.products = [];

    this.push(
      this.productService.getAllProducttByChecklist(this.pmvId, this.checklistRecordId).subscribe((data: any) => {
        console.log(data)
          this.products = data.body;
          this.totalRecord = this.products.length;
          if (this.totalRecord === 0) {
            this.noProduct = false;
            this.noProductIcon = true;
          } else {
            this.noProduct = true;
            this.noProductIcon = false;
          }
        })
    );
  }

  /********************************** Search Data **************************/
  onSearch() {
    console.log(this.searchValue.length)
    if (this.searchValue.length > 1) {
      this.searchValueData = true
      this.gridApi.setQuickFilter(this.searchValue);
      this.totalRecord = this.gridApi.getDisplayedRowCount();
      if (this.totalRecord == 0) {
        this.gridParams.api.showNoRowsOverlay();
      } else {
        this.gridParams.api.hideOverlay();
      }
    }
  }
  
public onSearchCheck()
{
  if(this.searchValue.length == 0)
  {
    this.searchValueData = false
    this.searchValue = ''
    this.gridApi.setQuickFilter(this.searchValue);
    this.gridParams.api.hideOverlay();
  }
}
public onClear(){
  this.searchValueData = false
  this.searchValue = ''
  this.gridApi.setQuickFilter(this.searchValue);
  this.onRefreshList()
  this.gridParams.api.hideOverlay();

  
}

  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;

    this.gridApi = params.api;
  }
   /********************************** View Singal Record **************************/
   public viewProduct(e: any) {
    if (e.colDef.field === "product.productNumber" && e.colDef.cellClass !== null) {
      this.util.resetTabView();
        this.router.navigate(["dashboard/products/view/" + e.data.product.id], {
          queryParams: { id: e.data.product.id, serialNo: null },
        });
      }
  
  }

  /********************************** Get Context Menu **************************/
  public getContextMenuItems(
    params: GetContextMenuItemsParams
  ): (string | MenuItemDef)[] {
    this.util.resetTabView();

    var tabData: any | null = params;

    if (tabData.column.colId == "product.productNumber") {
      var result: (string | MenuItemDef)[] = [
        {
          // custom item
          name: "Open link in new tab",
          action: () => {
            this.util.resetTabView();
            var url: any = router.serializeUrl(
              router.createUrlTree(["/dashboard/products/view" + tabData.id], {
                queryParams: { id: tabData.id },
              })
            );
            url = url.replace("%23", "#");
            window.open(url, "_blank");
          },
          cssClasses: ["redFont", "bold"],
        },
      ];
      return result;
    } else {
      return [];
    }
  }
}
