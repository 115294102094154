import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-connect-google-calender-success',
  templateUrl: './connect-google-calender-success.component.html'
})
export class ConnectGoogleCalenderSuccessComponent implements OnInit {
  time : any = 5;
  page:any
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    if (this.route.snapshot.data['page']) {
      this.page = this.route.snapshot.data['page']
    }

    var timeleft = 5;
    var downloadTimer = setInterval(() => {
      timeleft -= 1;
      this.time = timeleft;
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    }, 1000);
  }

}