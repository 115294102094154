import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { InventoryDashboardComponent } from './inventory-dashboard/inventory-dashboard.component';
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { TabView } from "primeng/tabview";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { UsersService } from 'src/app/_services/admin/users.service';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, AfterViewInit {
  activeIndex: any;

  public toggleSection = {
    magnum: {
      jobOrderDashboard: false,
    },
    hills: {
      dashboard: false,
      national: false,
    },
    chubb: {
      journeyManagement: false,
      chubbDahsboard: false,
    },
    inventoryDashboard:false,
    pmDahsboard: false,
    myDashboard: false
  };

  public isHeaderValue = false;
  public headerValue = "";

  addonMagnum: any;
  addonHills: any;
  addonChubb: any;
  addonINVENTORY: any
  loadDataDashboard: any = 0

  @ViewChild(TabView, { static: false }) tabView!: TabView;

  constructor(
    private auth: AuthenticationService,
    private util: UtilServiceService,
    private spinner: NgxSpinnerService,
    private modalService: ModalServiceService,
    private userService : UsersService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
  }, 1000);
  }

  /******************************************** init ******************************************/
  private init() {
    

    this.userService.getWelcomeNotice(this.auth.getUserId()).subscribe((res: any) => {
      console.log(res,"----->>>>>>>")
      if(res.message == 'incompleted'){
       //    this.modalService.openModal('welcomeUpdate', null);
      }
    })
    this.loadDataDashboard = 1
    // delay for 1sec to let login load after registration
    this.loadAddon().then(() => {
      const activeTab = this.util.getTabActive();

      if (activeTab) {
        let foundTab = false;

        for (let t = 0; t < this.tabView?.tabs?.length; t++) {
          if (this.tabView?.tabs[t].header === activeTab) {
            this.activeIndex = t;
            foundTab = true;
          }
        }
  
        if (foundTab === false) {
          this.activeIndex = 0;
        }

        this.loadData(this.activeIndex, this.tabView);
      } else {
        this.activeIndex =
          localStorage.getItem("activeIndex") == undefined
            ? 0
            : Number(localStorage.getItem("activeIndex"));

        setTimeout(() => {
          this.loadData(this.activeIndex, this.tabView);
        }, 500);
      }
    });

  }

  /******************************************** Load Addon ******************************************/
  private loadAddon() {
    return new Promise<void>((resolve) => {
      this.addonMagnum = this.auth.getAddonFeatureLists().addonMAGNUM;
      this.addonHills = this.auth.getAddonFeatureLists().addonHILLS;
      this.addonChubb = this.auth.getAddonFeatureLists().addonCHUBB;
        this.addonINVENTORY = this.auth.getAddonFeatureLists().addonINVENTORY;
        console.log(this.addonINVENTORY,"=====")
        setTimeout(() => {
          resolve();
        }, 1000);
      });
   
  }

  /******************************************** pre set tab ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.setTabHeader(headerValue);
    } else {
      if (this.tabView) {
        const headerValue = this.tabView.tabs[0]
          ? this.tabView.tabs[0].header
          : null;
        if (headerValue !== null) {
          this.setTabHeader(headerValue);
        }
      }
    }
  }

  /******************************************** handle on tab change ******************************************/
  public handleChange(e: any, tabView: TabView) {
    localStorage.setItem("activeIndex", e.index);
    this.activeIndex = e.index;
    const headerValue = tabView.tabs[e.index].header;

    this.setTabHeader(headerValue);
  }

  /******************************************** display tab content ******************************************/
  private setTabHeader(headerValue: any) {
    if (headerValue == "Job Order")
      this.toggleSection.magnum.jobOrderDashboard = true;
    if (headerValue == "Hills")
      this.toggleSection.hills.dashboard = true;
    if (headerValue == "National")
      this.toggleSection.hills.national = true;
    if (headerValue == "Chubb")
      this.toggleSection.chubb.chubbDahsboard = true;
    if (headerValue == "Journey Management Plan")
      this.toggleSection.chubb.journeyManagement = true;
    if (headerValue == "Service")
      this.toggleSection.myDashboard = true;
    if (headerValue == "Inventory")
      this.toggleSection.inventoryDashboard = true;
    if (headerValue == "Preventative Maintenances")
      this.toggleSection.pmDahsboard = true;
  }
}
