import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AppComponent } from "src/app/app.component";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ProfilesService } from "src/app/_services/admin/profiles.service";
import { UsersService } from "src/app/_services/admin/users.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-profile-info",
  templateUrl: "./profile-info.component.html",
  styleUrls: ["./profile-info.component.scss"],
})
export class ProfileInfoComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  refreshPhoto!: Subscription;
  profileInfoForm!: UntypedFormGroup;
  profile: any = {};
  photoUrl: any = "assets/svg/Avatar.svg";
  submitTouched!: boolean;

  fieldTextType!: boolean;
  fieldTextType2!: boolean;

  constructor(
    private userService: UsersService,
    private router: Router,
    private auth: AuthenticationService,
    private modalService: ModalServiceService,
    private profileService: ProfilesService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.profileInfoForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [Validators.required]),
      lastName: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.emailPattern),
      ]),
      phone: new UntypedFormControl(null, [
        Validators.required,
        Validators.pattern(AppComponent.phonePattern),
      ]),
    });

    this.refreshPhoto = this.profileService.refreshProfilePhoto$.subscribe(
      (res: any) => {
        if (res.attachmentUrl) this.photoUrl = res.attachmentUrl;
      }
    );
  }

  /******************************************** Init ******************************************/
  private init() {
    this.spinner.show();
    this.profile.language = "en";

    this.auth.getUserId();

    this.push(
      this.userService
        .getUserProfile(this.auth.getUserId())
        .subscribe((res) => {
          this.profile.firstName = res.firstName;
          this.profile.lastName = res.lastName;
          this.profile.phone = res.phone;
          this.profile.email = res.email;
          this.profile.language = res.language;
          this.spinner.hide();
        })
    );
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** On Submit ******************************************/
  public onSubmit() {
    this.submitTouched = true;

    if (!this.profileInfoForm.valid) {
      for (var i in this.profileInfoForm.controls) {
        this.profileInfoForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }

    if (this.profile.password !== this.profile.cPassword) {
      this.errorUtil.setErrorMessage(
        "400",
        null,
        "Passwords do not match. Please try again",
        "warn",
        2000
      );
    } else {
      var formData = {
        id: this.auth.getUserId(),
        firstName: this.profile.firstName,
        lastName: this.profile.lastName,
        phone: this.profile.phone,
        email: this.profile.email,
        language: this.profile.language,
        password: this.profile.password,
      };

      console.log(formData);

      this.push(
        this.userService.adjustProfile(formData).subscribe((res: any) => {
          if (res.status === 200 || res.status === 201) {
            this.errorUtil.setErrorMessage(
              200,
              "Profile updated succesfully",
              null,
              "success",
              2000
            );

            this.router.navigate(["/dashboard/home"]);
          } else {
            this.submitTouched = false;

            this.errorUtil.setErrorMessage(
              400,
              "Looks like something went wrong, please try again",
              "Looks like something went wrong, please try again",
              "error",
              2000
            );
          }
        })
      );
    }
  }

  /******************************************** Toggle Password Show ******************************************/
  public toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  /******************************************** Password Length ******************************************/
  public getPassowrdLength(password: any) {
    const value = password.target.value;

    if (value.length < 9) {
      this.profileInfoForm.controls["password"].setValidators([
        Validators.required,
        Validators.pattern(AppComponent.passwordPattern),
      ]);
      this.profileInfoForm.controls["password"].updateValueAndValidity();
    } else {
      this.profileInfoForm.controls["password"].clearValidators();
      this.profileInfoForm.controls["password"].updateValueAndValidity();
    }
  }

  /******************************************** Toggle Confirm Password Show ******************************************/
  public toggleFieldTextType2() {
    this.fieldTextType2 = !this.fieldTextType2;
  }

  /******************************************** Confirm Password Length ******************************************/
  public getCPassowrdLength(cPassword: any) {
    const value = cPassword.target.value;

    if (value.length < 9) {
      this.profileInfoForm.controls["cPassword"].setValidators([
        Validators.required,
        Validators.pattern(AppComponent.passwordPattern),
      ]);
      this.profileInfoForm.controls["cPassword"].updateValueAndValidity();
    } else {
      this.profileInfoForm.controls["cPassword"].clearValidators();
      this.profileInfoForm.controls["cPassword"].updateValueAndValidity();
    }
  }

  /******************************************** Confirm Password Length ******************************************/
  public openModal(modalView: any, data: any) {
    const dataToSend = {
      id: this.auth.getUserId(),
      type: "user",
    };

    this.modalService.openModal(modalView, dataToSend);
  }
   /******************************************** Add ALl the Subscription ******************************************/
   push(obs:any) {
    super.push(obs);
  }
}
