<!-- <p>calendly-theme works!</p> -->
<div class="preview-calendly-ext h-100" *ngIf="calendlyData" [attr.style]="'background-color:' + calendlyData?.colorCode + ' !important;'">
    <div class="prev-cal-logo">
        <p-avatar  *ngIf="!calendlyData?.logoAttachment" image="../../../../assets/svg/table/Fieldex_Bg.svg"  size="large" styleClass="mr-2 mt-1"></p-avatar>
        <p-avatar  *ngIf="calendlyData?.logoAttachment" [image]="calendlyData.logoAttachment.attachmentUrl"  size="large" styleClass="mr-2 mt-1"></p-avatar>

        <!-- <img src="../../../../../assets/svg/table/calendly-logo.svg"> -->
        <div>
            <span class="text-20 ms-2 font-white font-semiBold" [attr.style]="'color:' + calendlyData?.titleColorCode + ' !important;'">{{calendlyData?.title}}</span>
            <span *ngIf="calendlyData.subTitle" [attr.style]="'color:' + calendlyData?.titleColorCode + ' !important;'" class="text-16 ms-2 d-block font-white font-medium">{{calendlyData?.subTitle}}</span>
        </div>
    </div>
    <div class="dp-plugin">
        <iframe [src]="urlSafe" class="calendlyFrame" frameborder="0" title="Select a Date &amp; Time - Calendly"></iframe>
       
    </div>
</div>


 