import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from "@angular/http";
import { param } from 'jquery';
import { throwError, of as observableOf, Subject, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  public refreshList$: EventEmitter<any>;
  public refreshListAfterUpdateStatus$: EventEmitter<any>;

  private ENDPOINT_VISITS = "ms/v1/visits/filter";
  private ENDPOIN_VISIT_TYPES = "ms/v1/visit-types"
  private ENDPOIN_VISIT_STATUS = "ms/v1/visit-statuses"
  private ENDPOIN_VISIT_APPROVAL_STATUS = "ms/v1/visit-approval-statuses"
  private ENDPOIN_GET_REQUEST_TO_USERS = "ms/v1/all-machine-sp-users"
  private ENDPOINT_VISIT = "ms/v1/visits"
  private ENDPOINT_VISITS_STATUS_UPDATE = "ms/v1/visits/status"
  private ENDPOINT_VISITS_APPROVAL_STATUS_UPDATE = "ms/v1/visits/approval-status?"
  private ENDPOINT_VISITS_REJECT_REASON_UPDATE = "ms/v1/visits/reason"
  private ENDPOINT_VISITS_GET_BY_ID = "ms/v1/visits/";
  private ENDPOINT_UPLOAD_ATTACHMENT = "attachments/upload";
  private selectSubject = new Subject();
  
  constructor(private http: HttpClient,
    private httpOnly: Http,
    private auth: AuthenticationService) {
    this.refreshList$ = new EventEmitter();
    this.refreshListAfterUpdateStatus$ = new EventEmitter();
  }
  public select(val: any) {
    this.selectSubject.next(val);
  }
  public getSelectItem() {
    return this.selectSubject.asObservable();
  }
  
  public getAllVisits(search: any, parameters: any, apiParams: any) {
    let params = new HttpParams();
    if (search !== "" && search != null) {
      params = params.set("search", search);
    }
    if (apiParams.userId) {
      params = params.set("userId", apiParams.userId);
    }
    if (apiParams.visitType) {
      params = params.set('visitType', apiParams.visitType);
    }
    if (apiParams.fromDate) {
      params = params.set('fromDate', apiParams.fromDate);
    }
    if (apiParams.toDate) {
      params = params.set('toDate', apiParams.toDate);
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }
    if (apiParams.siteId) {
      params = params.set('siteId', apiParams.siteId)
    }
    if (apiParams.approvalStatus) {
      params = params.set('approvalStatus', apiParams.approvalStatus)
    }
    if (apiParams.status) {
      params = params.set('status', apiParams.status)
    }

    if (parameters)
      params = params.set('startFrom', parameters.startRow)
        .set('perPage', '25')
        .set('columnName', parameters.sortModel[0].colId)
        .set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.ms_node_base_url + this.ENDPOINT_VISITS, { observe: 'response', params: params }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  public getAllVisitTypes() {
    //params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.ms_node_base_url + this.ENDPOIN_VISIT_TYPES, { observe: 'response', }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }
/****************************************************** Get Visit Status  ***************************************************/
  public getAllVisitStatus() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOIN_VISIT_STATUS, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }
/****************************************************** Get Visit Approval Status  ***************************************************/
  public getAllApprovalStatus() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOIN_VISIT_APPROVAL_STATUS, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }
/****************************************************** Get All Requested User  ***************************************************/
  public getAllRequestToUsers() {
    return this.http.get(environment.ms_node_base_url + this.ENDPOIN_GET_REQUEST_TO_USERS, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return throwError(error);
    }),);
  }

  /******************************************** create Visit  ******************************************/
  public createVisit(body: any) {
    return this.http
      .post(environment.ms_node_base_url + this.ENDPOINT_VISIT, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** edit visit ******************************************/
  public editVisit(body: any) {
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_VISIT, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /************************************* Get Visit Info By ID *************************************************************************/
  public getVisitsById(id: any) {
    return this.http
      .get(environment.ms_node_base_url + this.ENDPOINT_VISITS_GET_BY_ID + id)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }
  /************************************* Delete Visits *************************************************************************/
  public deleteVisit(id: any) {
    return this.http
      .delete(environment.ms_node_base_url + this.ENDPOINT_VISIT +'/'+ id, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          this.refreshList$.emit("Deleted Visit");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Update visits Aproval status *************************************************************************/
  public updateApprovalStatus(data: any,refresh : boolean) {

    // let params = new HttpParams();
    // params = params.set('id', id);
    // params = params.set('status', status)
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_VISITS_APPROVAL_STATUS_UPDATE, data, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          if(refresh){
            //debugger
        //  this.refreshListAfterUpdateStatus$.emit("Status Updated");
          }
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Update visits  status *************************************************************************/
  public updateVisitStatus(body: any,) {

    // let params = new HttpParams();
    // params = params.set('id', id);
    // params = params.set('status', status)
    return this.http
      .put(environment.ms_node_base_url + this.ENDPOINT_VISITS_STATUS_UPDATE, body, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
          
        })
      );
  }

  /************************************* Update visits reject status *************************************************************************/
  public updateVisitRejectReason(body: any) {
    return this.http.put(environment.ms_node_base_url + this.ENDPOINT_VISITS_REJECT_REASON_UPDATE, body, {observe: "response", }) .pipe(
        map((resp: any) => {
         // this.refreshListAfterUpdateStatus$.emit("Status Updated");
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }

  /************************************* Get Visit History By ID *************************************************************************/
  public getVisitsHistoryById(visitId: any) {
    return this.http
      .get(environment.ms_node_base_url + 'ms/v1/visit-audit-event-histories?visitId='+visitId)
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          ////console.log(error);
          return throwError(error);
        })
      );
  }


  //  /*****************************************Upload  Attchment *********************************************************************/
  //  public uploadAttachmentByCustomerId(id: any, formData: any) {
  //   const headers = new Headers({});
  //   headers.append("Authorization", "Bearer " + this.auth.getToken());
  //   let options = new RequestOptions({ headers: headers });
  //   return this.httpOnly
  //     .post(
  //       environment.base_url + this.endpo + id,
  //       formData,
  //       options
  //     )
  //     .pipe(
  //       map((resp: any) => {
  //         this.refreshList$.emit(resp);
  //         return resp;
  //       }),
  //       catchError((error) => {
  //         //console.log(error);
  //         return throwError(error);
  //       })
  //     );
  // }
  
  /*****************************************Upload Customer Attchment *********************************************************************/
  public uploadAttachment(id: any, formData: any) {
    const headers = new Headers({});
    headers.append("Authorization", "Bearer " + this.auth.getToken());
    let options = new RequestOptions({ headers: headers });
    return this.httpOnly
      .post(
        environment.base_url + '/attachments/upload?parentType=DYNAMIC_FORM',
        formData,
        options
      )
      .pipe(
        map((resp: any) => {
         
          return resp.json();
        }),
        catchError((error) => {
          //console.log(error);
          return throwError(error);
        })
      );
  }
  /******************************************** Add  task into JO Template ******************************************/
public uploadAttachmentByVisitId(body: any) {
  return this.http.patch(environment.ms_node_base_url + 'ms/v1/visits/attachments', body, { observe: "response" }).pipe(
      map((resp: any) => {
        this.refreshList$.emit(resp);
        return resp;
      }),
      catchError((error) => {
        //console.log(error);
        return observableOf(error);
      })
    );
}
/******************************************** Add  task into JO Template ******************************************/
public deleteVisitAttachment(id: any,attachmentId: any) {
  return this.http
    .delete(environment.base_url + 'ms/v1/visits/attachments?visitId=' + id+'&attachmentId='+attachmentId, {
      observe: "response",
    })
    .pipe(
      map((resp: any) => {
        this.refreshList$.emit(resp);
        return resp;
      }),
      catchError((error: any) => {
        return throwError(error);
      })
    );
}
}
