<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <span class="text-16 font-medium" translate>{{'modify_plan'}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body tix">
    <div class="d-flex align-items-start justify-content-between">
        <div class="w-50">
            <div class="custella-modify-left">
                <div class="custella-modify-left-card" *ngFor="let data of options" (click)="chooseOption(data)" [ngClass]="{'selected-card':data.id== SelectedOption.id}">
                    <div class="d-flex align-items-center">
                        <div class="border-right rounded-2 p-3 bg-white" style="width: 52px">
                            <img [src]="data.image" alt="Modify Plan" class="w-28px">
                        </div>
                        <div class="ms-3">
                            <span class="text-14 font-medium">{{data.name}}</span>
                            <span class="text-12 d-block">{{data.description}}</span>
                        </div>
                    </div>
                    <i class="pi pi-arrow-right"></i>
                </div>
               
            </div>
        </div>
        <div class="w-50">
            <!-- Add Order -->
            <div class="custella-modify-right" *ngIf="SelectedOption">
                <span class="text-16 font-medium ms-1">{{SelectedOption.name}}</span>
                <div class="custella-modify-right-card mt-2" *ngFor="let subOption of SelectedOption.subOption" (click)="selectClick(SelectedOption,subOption)">
                    <div class="">
                        <span class="text-14 font-medium">{{subOption.name}}</span>
                        <span class="text-12 d-block">{{subOption.description}}</span>
                    </div>
                </div>                
                          
            </div>
           
        </div>
    </div>

</div>