<div class="">
    <form (ngSubmit)="addNote()">
        <div class="custella-modal d-flex flex-column">
            <div class="custella-modal-header">
                <div class="text-14 font-medium">
                    <span id="title_TixConvAddComment"> {{ "add_comment" | translate }} </span>
                </div>
  
                <i class="pi pi-times pt-1" id="btn_TixConvAddCommentClose" [pTooltip]="'close'|translate" tooltipPosition="left" (click)="modalRef.hide()"></i>
            </div>
  
            <div class="custella-modal-body">
                <div class="custella-content-row-input mb-2">
                    <span class="custella-content-row-input-title" id="label_TixCommentMsg">{{ "message" | translate }}</span>
                    <textarea pInputTextarea autocomplete="off" rows="7" id="input_TixCommentMsgField" #addNotesInput [(ngModel)]="addComment.message" [ngModelOptions]="{ standalone: true }" placeholder="{{ 'type_your_message_here' | translate }}" [maxlength]="320000"></textarea>
                </div>
  
                <div class="custella-content-row-input">
                    <span class="custella-content-row-input-title" id="label_TixCommentFiles">{{ "files" | translate }}</span>
                    <div class="custella-content-row-input">
                        <p-fileUpload
                            #fileUpload
                            [chooseLabel]="'upload_file' | translate"
                            [chooseIcon]="'pi pi-upload'"
                            (uploadHandler)="uploadFile($event)"
                            [customUpload]="true"
                            [auto]="true"
                            [maxFileSize]="25000000"
                            invalidFileSizeMessageSummary="Invalid file size" 
                            invalidFileSizeMessageDetail="File size should be less than 25MB" 
                            id="btn_TixCommentFileUpload"
                        >
                            <ng-template pTemplate="content">
                                <div *ngIf="attachmentsArray.length">
                                    <div class="p-fileupload-files custella-files" *ngFor="let attachment of attachmentsArray">
                                        <div class="p-fileupload-row">
                                            <div class="custella-file-notification flex-between rounded-2 w-100">
                                                <div class="d-flex align-items-center">
                                                    <i class="pi pi-file"></i>
                                                    <span class="custella-content-row-input-title ms-2 pt-1 cursor-pointer" id="value_TixAttachmentFileName">{{ attachment.fileName }}</span>
                                                </div>
                                                <span class="custella-file-notification-remove color-primary text-12 cursor-pointer" id="btn_TixAttachmentDelete" (click)="deleteAttachment(attachment)">{{'remove' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-fileUpload>
                    </div>
                </div>
  
                <div class="custella-content-row m-0" [attr.data-disabled]="disableCheckbox"></div>
            </div>
  
            <div class="custella-modal-footer flex-between">
                <div class="">
                    <p-checkbox name="replyToCustomer" (onChange)="changeStatus($event)" id="check_TixReplyCust" [disabled]="disableCheckbox" [binary]="true"></p-checkbox>
                    <span class="ms-3 text-12" id="label_TixReplyCust">{{ "reply_this_to_customer" | translate }}</span>
                </div>
                <p-button [disabled]="submitTouched || !addComment?.message" type="submit" id="btn_TixSendComment" [label]="'send' | translate"></p-button>
            </div>
        </div>
    </form>
  </div>
  