<div class="modal-header flex-between border-0 pb-1">
    <div class="">
        <span class="text-16 font-medium">{{'set_up_calendly_events'|translate}}</span>
        <span class="text-12 pt-1 color-var-500 d-block" translate>{{'update_mappings_bet_jo_calendly'}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body tix pt-2">
    <div class="custella-form-container rounded-2 ms-0 me-2" style="box-shadow: none; border: 1px solid #dee2e6;">
        <!-- Container Content -->
        <div class="custella-form-container-title text-14 font-medium rounded-top" id="" translate>
            {{"connect_each_jo_calendly"}}
        </div>
        <div class="custella-form-container-title-two-grey fst-italic" id="" translate>
            <span class="text-12">1.&nbsp;<span
                    class="font-bold">&nbsp;{{'calendly_events'|translate}}</span>&nbsp;{{'calendly_setup_one'|translate}}</span>
            <span class="text-12 d-block">2.&nbsp;{{'calendly_setup_two'|translate}}&nbsp;<span
                    class="font-bold">{{'job_order_type'|translate}}</span>&nbsp;{{'calendly_setup_two_cont'|translate}}&nbsp;<span
                    class="font-bold">{{'calendly_event_id'|translate}}</span>&period;</span>
            <span class="text-12 d-block">3.&nbsp;{{'calendly_setup_three'|translate}}<span
                    class="left-14px">{{'calendly_setup_three_cont'|translate}}</span></span>
        </div>
        <div class="custella-form-container-content mb-2">
            <!-- Title -->
            <div class="custella-content-row">
                <div class="custella-content-row-input col-5 me-0">
                    <span class="text-14 font-medium color-56" id="">{{'job_order_type' | translate}}</span>
                </div>
                <div class="custella-content-row-input col-1 mx-2">
                    <!-- <i class="pi pi-arrow-right" style="font-size: 14px;"></i> -->
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="text-14 font-medium color-56" id="">{{'calendly_event_id' | translate}}</span>
                </div>
                <!-- <div class="custella-content-row-input me-0 col-1">
                    <i class="pi pi-arrow-right" style="font-size: 14px;"></i>
                </div> -->
            </div>
            <!-- Input fields -->
            <div class="scroll-content">
                <div class="custella-content-row" *ngFor="let calendlyEventJOT of calendlyEventJobOrderTypes;let i = index">
                    <div class="custella-content-row-input col-5 me-0">
                        <span class="custella-content-row-input-title-three">{{calendlyEventJOT.jobOrderType.name}}</span>
                        <!-- <p-dropdown 
                            [(ngModel)]="calendlyEventJOT.jobOrderType.id"
                            optionLabel="name"
                            formControlName="jobOrderType" 
                            [placeholder]="'select_order_type' | translate"
                            [options]="calendlyEventJOT.jobOrderTypes"
                            [showClear]="true"
                            optionLabel="name"
                            optionValue="id" (onChange)="selectJoType(calendlyEventJOT.jobOrderType)"></p-dropdown>
                            <div class="text-10 p-error" *ngIf="calendlyEventJOTForm[i].controls['jobOrderType'].touched && calendlyEventJOTForm[i].controls['jobOrderType'].invalid">
                                <span *ngIf="calendlyEventJOTForm[i].controls['jobOrderType'].errors?.required" translate>{{'required_field'}}</span>
                            </div> -->
                    </div>
                    <div class="custella-content-row-input text-center col-1 mx-2">
                        <i class="pi pi-arrow-right" style="font-size: 2rem; color: #AAA;"></i>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <input type="text" pInputText autocomplete="off" maxlength="255" style="max-width: 500px;" id=""
                            [(ngModel)]="calendlyEventJOT.calendlyEventId" [ngModelOptions]="{standalone: true}" />
                    </div>
                </div>
            </div>

            <!-- <div class="custella-content-row-input col-1 mt-1 text-center" (click)="removeRecord(i)">
                    <button style="border: none;" *ngIf="i !=0">
                        <img alt="logo" src="../../../../../assets/svg/table/exit_button.svg" />
                    </button>
                </div> -->
        </div>
        <!-- Input Fields Disabled -->
        <!-- <div class="custella-content-row">
                <div class="custella-content-row-input col-5">
                    <p-dropdown optionLabel="name" [disabled]="true"></p-dropdown>
                </div>
                <div class="custella-content-row-input text-center col-1">
                    <i class="pi pi-arrow-right" style="font-size: 2rem; color: #DDD;"></i>
                </div>
                <div class="custella-content-row-input col-5 me-0">
                    <input type="text" [disabled]="true" pInputText autocomplete="off" maxlength="255" style="max-width: 500px;" id="" />
                </div>
                <div class="custella-content-row-input col-1 mt-1 text-center">
                    <button style="border: none;" (click)="createNew()">
                        <img alt="logo" src="../../../../../assets/svg/table/add_button.svg" />
                    </button>
                </div>
            </div> -->


        <div class="custella-form-container-footer mb-0">
            <div class="flex-align-center justify-content-end">
                <button type="button" class="custella-btn-white text-12 ms-2" id="" (click)="modalRef.hide()">{{
                    "cancel" |
                    translate }}</button>
                <p-button type="button" styleClass="ms-2 h-32" id="" [disabled]="submitTouched"
                    [label]="'save' | translate" (click)="onSubmit()"></p-button>
            </div>
        </div>
    </div>
</div>