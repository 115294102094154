<div class="custella-modal">
  <form (ngSubmit)="onSubmit()">
    <div class="custella-modal-header align-items-center">
      <span class="custella-modal-header-text">{{title}}</span>
      <i class="pi pi-times"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
    </div>
    <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
      <div class="modal-body">
        <div class="container text-center">
          <p translate>{{'are_you_sure'}}</p>
        </div>
      </div>
      <div class="custella-form-container-footer mb-0">
        <div class="d-flex align-items-center justify-content-end">
          <button class="custella-btn-white text-12 ms-2 me-0" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
          <button pButton type="button" [label]="'submit'|translate" iconPos="left" class="p-button-danger ms-2 me-0 h-32"
            (click)="onSubmit()"></button>
        </div>
      </div>
    </div>
  </form>
</div>
