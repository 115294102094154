import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failure',
  templateUrl: './failure.component.html'
})
export class FailureComponent implements OnInit {
  time : any = 5;
  constructor() { }

  ngOnInit() {
    var timeleft = 5;
    var downloadTimer = setInterval(() => {
      timeleft -= 1;
      this.time = timeleft;
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    }, 1000);
  }

}