<div class="h-100-70 mt-2">
  <!-- Title -->
  <div class="flex-between mt-1 mx-2 mb-2">
      <div class="text-20 font-semiBold" id="title_TaskTeamSchedule">
         {{mode == 'all' ? 'All':mode == 'today' ? "Today's":mode == 'upcoming' ? 'Upcoming':'Completed'}} Tasks
      </div>
    <!-- Filter and New -->
    <div class="custella-dashboard-top-right d-flex ms-auto">
     
      <button *ngIf="toggle" class="btn-list-icon ms-2" pTooltip="Table View" tooltipPosition="bottom" id="btn_TaskTeamScheTableView" (click)="openMapLocation()">
          <i class="fa-regular fa-table"></i>
      </button>

      <button *ngIf="!toggle"  class="btn-list-icon ms-2" pTooltip="Calendar View" tooltipPosition="bottom"  (click)="openMapLocation()">
        <i class="fa-regular fa-calendar-lines"></i>
    </button>
      <button *ngIf="toggle"class="btn-list-icon ms-2" [pTooltip]="'refresh'|translate" tooltipPosition="bottom" (click)="getFilter()" id="btn_TaskTeamScheRefresh">  <i class="fa-regular fa-arrows-rotate"></i></button>
      <button   class="btn-list-icon ms-2" [pTooltip]="'filter'|translate" tooltipPosition="bottom" id="btn_TaskTeamScheFilter" (click)="onFilteruttonClick($event)"><i class="fa-regular fa-filter-list"></i> </button>
     
      <span class="p-input-icon-right w-100 ms-2">
          <i class="pi pi-calendar" style="z-index: 1;"></i>
          <p-calendar type="text" class="w-100" selectionMode="single" dateFormat="dd/mm/yy" [readonlyInput]="true" id="picklist_TaskTeamScheDateField" name="completion" (onSelect)="onCompletionDateChange($event)"
            [(ngModel)]="taskDateRange" placeholder="dd/mm/yyyy" [yearNavigator]="true" yearRange="2000:2030"appendTo="body"></p-calendar>
      </span>
      <p-dropdown class="ms-2"  (onChange)="getFilter()" id="picklist_TaskTeamScheSelStatusField"
      [(ngModel)]="filterByView" name="status" [options]="filterDay" optionLabel="label"
      optionValue="value" placeholder="Select Status"></p-dropdown>
     
      <p-button  [label]="'new'|translate" icon="fa-regular fa-plus" id="btn_TaskTeamScheNew" (click)="openModal('addEditTask', '')" styleClass="ms-2 h-32" *ngIf="createTask"></p-button>


    </div>
  </div>
  <!-- TabMenu -->
  <div class=" d-flex" style="height: calc(100% - 30px);">
  <div class="position-relative fw-normal display-table d-flex ag-grid-table-full h-100" [ngClass]="{' ag-grid-table': filterClick}" style="border: 1px #EBEBEB solid;">
    <div *ngIf="toggle" class="w-100">
      <bryntum-scheduler
      #scheduler
      [resources] = "users"
      [columns] = "schedulerConfig.columns"
      [events] = "events"
      [startDate] = "schedulerConfig.startDate!"
      [endDate] = "schedulerConfig.endDate!"
      [viewPreset] = "viewPreset"
      [rowHeight] = "schedulerConfig.rowHeight"
      [barMargin] = "schedulerConfig.barMargin"
      [treeFeature] = true
      [readOnly] = true
      [resourceTimeRangesFeature] = "schedulerConfig.features.resourceTimeRanges"
      [eventTooltipFeature] = "schedulerConfig.features.eventTooltip"
      (onCatchAll) = "onSchedulerEvents($event)"
      ></bryntum-scheduler>    
    </div>
    <div *ngIf="!toggle" class="w-100">
      <app-all-team-task [mode]="mode" [selectedTeam]="selectedTeam" [status]="status" [taskDateRange]="taskDateRange"></app-all-team-task>
    </div>
  </div>


  <div  class="custella-form-container-content-pane layout-filter-task" [ngClass]="{'layout-filter-active': filterActive}">
    <div class="custella-form-container-content-pane-title">
        <span class="text-14 font-medium" id="title_TaskTeamScheFilterBy">Filter by</span>
        <i class="fa-regular fa-arrow-right-from-line" [pTooltip]="'close_filter'|translate" tooltipPosition="left" id="btn_TaskTeamScheFilterClose" (click)="onFilteruttonClick($event)"></i>
    </div>
    <div class="custella-content my-2 px-2 py-2">
      
        <div class="custella-content-row-input col-12 mb-2">
            <span class="custella-content-row-input-title" id="label_TaskTeamScheTeams">Team</span>
            <p-multiSelect display="chip" (onChange)="filterTeam($event)" id="picklist_TaskTeamScheTeamsField" [(ngModel)]="selectedTeam" name="status" [options]="teams" optionLabel="teamName"  optionValue="id" placeholder="Select Teams"></p-multiSelect>
      
        </div>

        <div class="custella-content-row-input col-12 mb-2">
                <span class="custella-content-row-input-title" id="label_TaskTeamScheStatus"> Status</span>
                <p-dropdown
                    class="pe-2"
                    id="picklist_TaskTeamScheStatusField"
                     (onChange)="getFilter()"
                    [(ngModel)]="status"
                    name="status"
                    [options]="taskStatus"
                    optionLabel="label"
                    filterBy="label"
                    optionValue="value"
                    placeholder="Select Status"
                    [showClear]="true"
                    [filter]="true"
                ></p-dropdown>
            </div>

            <div class="custella-content-row-input col-12 mb-2 d-flex mt-4">
              <p-checkbox class="ms-2" [binary]="true" (onChange)="filterTaskByTeam(selectedTeam)" id="check_TaskTeamScheShowTeam"  [(ngModel)]="byTeamOnlyChk"  [ngModelOptions]="{ standalone: true }"></p-checkbox>
              <span class="custella-content-row-input-title ms-2 mb-0" id="label_TaskTeamScheShowTeam">Show team tasks only</span>
              
           </div>

        <div>
          <span href="#" class="clear" (click)="resetSearch()" id="subtitle_TaskTeamScheClear">{{ "clear_all" | translate }}</span>
        </div>
    </div>
 </div>
</div>
</div>