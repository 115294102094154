import { ɵNullViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AssetService } from 'src/app/_services/asset.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { locatns } from '../../IqcFqc';


@Component({
  selector: 'app-fqc-create',
  templateUrl: './fqc-create.component.html'
})
export class FqcCreateComponent implements OnInit, OnDestroy {
  buttonLabel: string = 'Save';

  _id: any;
  addFQCForm!: UntypedFormGroup;
  fqcData: any = {
    subsidiary: { id: null, name: null, customerExternalId: null },
    customer: { id: null, name: null, customerExternalId: null },
    location: null,
    assets: { id: null, name: null, assetsCode: null },
    status: 'NEW',
    newMeterReading: null,
    equipmentType: { _id: null },
    QCSetup: { _id: null },
    userResponse: [],
    remark: null,
  }
  displayAssetTable: any;
  assetName: any;
  selectedAsset: any = { name: '' };
  assetDetails: any;
  assets: any;
  customers: any;
  subsidiary: any;
  fqcType: any = [{ name: 'Off Hire', label: 'Off Hire' }, { name: 'Exchange', label: 'Exchange' }];
  questionForm: any;
  questionList: any = [];
  statusMessage = '';
  statuses = [{ name: 'NEW', label: 'New' }, { name: 'DRAFT', label: 'Draft' },
  { name: 'COMPLETED', label: 'Completed' }, { name: 'IN_PROGRESS', label: 'In Progress' }];
  locatns: any = [];
  fqc: any = {};
  displayModal: boolean = false;

  subscriber: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  subscriber2: Subscription = new Subscription();
  subscriber3: Subscription = new Subscription();
  subscriber4: Subscription = new Subscription();
  subscriber5: Subscription = new Subscription();
  subscriber6: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
    private router: Router,
    public util: UtilServiceService,
    private iqcFqcService: IqcFqcService,
    private spinner: NgxSpinnerService,
    private breadCrumb: BreadcrumbService,
    private assetsService: AssetService,
    private errorUtil: ErrorUtil,
    private customerService: CustomersService,
    private cd: ChangeDetectorRef,
    private primengConfig: PrimeNGConfig) {
    this.locatns = locatns;

    this.addFQCForm = new UntypedFormGroup({
      'assetsName': new UntypedFormControl(null, [Validators.required]),
      'location': new UntypedFormControl(null),
      'customer': new UntypedFormControl(null),
      'subsidiary': new UntypedFormControl(null),
      'iqcType': new UntypedFormControl(null),
      'breakDownService': new UntypedFormControl(null),
      'equipmentTypeName': new UntypedFormControl({ value: null, disabled: true }),
      'equipmentNumber': new UntypedFormControl({ value: null, disabled: true }),
      'manufacturer': new UntypedFormControl({ value: null, disabled: true }),
      'model': new UntypedFormControl({ value: null, disabled: true }),
      'serialNo': new UntypedFormControl({ value: null, disabled: true }),
      'engineNumber': new UntypedFormControl({ value: null, disabled: true }),
      'engineNumber2': new UntypedFormControl({ value: null, disabled: true }),
      'engineMake': new UntypedFormControl({ value: null, disabled: true }),
      'engineModel': new UntypedFormControl({ value: null, disabled: true }),
      'equipmentMeterReading': new UntypedFormControl({ value: null, disabled: true }),
      'newMeterReading': new UntypedFormControl(null),
      'remark': new UntypedFormControl(null),
      'status': new UntypedFormControl(null, [Validators.required]),
      'frequency': new UntypedFormControl(null),
      'operatingPressure': new UntypedFormControl(null),
      'alternatorReading': new UntypedFormControl(null),
      'vacuumPressure': new UntypedFormControl(null),
      'dischargePressure': new UntypedFormControl(null),
      'terminalVoltage': new UntypedFormControl(null),
    });

    // get all assets
    this.subscriber1 = this.assetsService.getAssetsPicklist().subscribe((res: any) => {
      this.assets = res.body;
    });
    // get all customers
    this.subscriber2 = this.customerService.getAllV2Picklist().subscribe((res: any) => {
      this.customers = res
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this._id = this.route.snapshot.paramMap.get('id');
    this.init();
  }

  ngOnDestroy(): void {
    if (this.subscriber) this.subscriber.unsubscribe();
    if (this.subscriber1) this.subscriber.unsubscribe();
    if (this.subscriber2) this.subscriber.unsubscribe();
    if (this.subscriber3) this.subscriber.unsubscribe();
    if (this.subscriber4) this.subscriber.unsubscribe();
    if (this.subscriber5) this.subscriber.unsubscribe();
    if (this.subscriber6) this.subscriber.unsubscribe();
  }

  loadBreadCrum() {
    this.breadCrumb.setItems([
      { label: 'FQC', routerLink: ["/dashboard/iqc-fqc"], queryParams: { activeIndex: 1 } },
      { label: 'Edit FQC' }
    ]);
  }

  /******************************************** start ******************************************/
  private init() {
    this.subscriber3 = this.iqcFqcService.getFqcById(this._id).subscribe((resp: any) => {
      if (resp.status) {
        this.fqcData = resp.data;
        if (this.fqcData.customer == null) {
          this.fqcData.customer = { id: null, name: null, customerExternalId: null }
        }
        if (this.fqcData.subsidiary == null) {
          this.fqcData.subsidiary = { id: null, name: null, customerExternalId: null }
        }

        this.addFQCForm.controls['customer'].setValue(this.fqcData.customer.name);
        this.addFQCForm.controls.customer.disable();
        this.addFQCForm.controls['subsidiary'].setValue(this.fqcData.subsidiary.name);
        this.addFQCForm.controls.subsidiary.disable();
        this.addFQCForm.controls['iqcType'].setValue(this.fqcData.iqcType);
        this.addFQCForm.controls.iqcType.disable();
        this.addFQCForm.controls['breakDownService'].setValue(this.fqcData.breakDownService);
        this.addFQCForm.controls.breakDownService.disable();
        this.addFQCForm.controls['location'].setValue(this.fqcData.location);
        this.addFQCForm.controls['newMeterReading'].setValue(this.fqcData.newMeterReading);
        this.addFQCForm.controls.location.disable();
        this.setTestReading(this.fqcData);
        this.gotSelectedAsset(this.fqcData.assets);
        this.getAssets();
        this.getQandA();
        // this.breadCrumb.setItems([
        //   { label: "FQC", routerLink: ["/dashboard/iqc-fqc"] },
        //   { label: resp.status ? 'Edit FQC - ' + resp.data.runningNumber : 'Edit FQC', routerLink: ["/dashboard/iqc-fqc/fqc-view/" + this._id] },
        // ]);
        this.loadBreadCrum();
      } else this.errorUtil.setErrorMessage(resp.status, null, resp.error.title, 'error',3000);
    }, (error: any) => {
      this.errorUtil.setErrorMessage(error.status, null, error.title, 'error',3000);
      this.loadBreadCrum();
    });
  }

  /******************************************** get assets ******************************************/
  getAssets() {
    // var d = this.assets.filter((asset:any) => asset.id === this.fqcData.assets.id);
    // this.fqcData.assets.name = d[0].name;
    // this.fqcData.assets.assetsCode = d[0].assetsCode;
    this.assetsService.getEditObj(this.fqcData.assets.id).subscribe((res: any) => {
      this.assetDetails = res.body;
      this.fqcData.equipmentTypeName = this.assetDetails.equipmentType;
      this.fqcData.equipmentNumber = this.assetDetails.equipmentNumber;
      this.fqcData.manufacturer = this.assetDetails.manufacturer;
      this.fqcData.model = this.assetDetails.model;
      this.fqcData.serialNo = this.assetDetails.serialNo;
      this.fqcData.engineNumber = this.assetDetails.engineNumber;
      this.fqcData.engineNumber2 = this.assetDetails.engineNumber2;
      this.fqcData.engineModel = this.assetDetails.engineModel;
      this.fqcData.equipmentMeterReading = this.assetDetails.equipmentMeterReading;
      this.fqcData.engineMake = this.assetDetails.engineMake;
      this.fqcData.engineNumber2 = this.assetDetails.engineNumber2;
      this.setfields(this.fqcData);
    })
  }

  setfields(val: any) {
    this.addFQCForm.controls['equipmentTypeName'].setValue(val.equipmentTypeName);
    this.addFQCForm.controls['equipmentNumber'].setValue(val.equipmentNumber);
    this.addFQCForm.controls['manufacturer'].setValue(val.manufacturer);
    this.addFQCForm.controls['model'].setValue(val.model);
    this.addFQCForm.controls['serialNo'].setValue(val.serialNo);
    this.addFQCForm.controls['engineNumber'].setValue(val.engineNumber);
    this.addFQCForm.controls['engineNumber2'].setValue(val.engineNumber2);
    this.addFQCForm.controls['engineMake'].setValue(val.engineMake);
    this.addFQCForm.controls['engineModel'].setValue(val.engineModel);
    this.addFQCForm.controls['equipmentMeterReading'].setValue(val.equipmentMeterReading);
    this.addFQCForm.controls.equipmentTypeName.disable();
    this.addFQCForm.controls.equipmentNumber.disable();
    this.addFQCForm.controls.manufacturer.disable();
    this.addFQCForm.controls.model.disable();
    this.addFQCForm.controls.serialNo.disable();
    this.addFQCForm.controls.engineNumber.disable();
    this.addFQCForm.controls.engineNumber2.disable();
    this.addFQCForm.controls.engineMake.disable();
    this.addFQCForm.controls.engineModel.disable();
    this.addFQCForm.controls.equipmentMeterReading.disable();
  }

  setTestReading(val: any) {
    this.addFQCForm.controls['remark'].setValue(val.remark);
    this.addFQCForm.controls['status'].setValue(val.status);
    this.addFQCForm.controls['frequency'].setValue(val.frequency);
    this.addFQCForm.controls['operatingPressure'].setValue(val.operatingPressure);
    this.addFQCForm.controls['alternatorReading'].setValue(val.alternatorReading);
    this.addFQCForm.controls['vacuumPressure'].setValue(val.vacuumPressure);
    this.addFQCForm.controls['dischargePressure'].setValue(val.dischargePressure);
    this.addFQCForm.controls['terminalVoltage'].setValue(val.terminalVoltage);
  }

  /******************************************** get Q & A ******************************************/

  getQandA() {
    this.subscriber4 = this.iqcFqcService.getQuestionForm(this.fqcData.equipmentTypeName, this.fqcData.location, 'FQC').subscribe((res: any) => {
      this.questionForm = res.data
      if (res.data.QCSetup != undefined) {

        this.fqcData.QCSetup._id = res.data.QCSetup._id
        this.fqcData.equipmentType._id = res.data.QCSetup.equipmentType._id
        this.fqc.equipmentType = res.data.QCSetup.equipmentType;

        this.questionForm.questions.forEach((element: any) => {
          var answerId = null
          element.selectedAnswer = ɵNullViewportScroller

          var userAnswers = _.filter(this.fqcData.userResponse, (elementUserAnswers) => {
            return elementUserAnswers.question._id == element._id;
          })

          element.answers.forEach((elementAnswers: any) => {
            if (!_.isEmpty(userAnswers)) {
              answerId = { _id: userAnswers[0].answer._id }
              element.selectedAnswer = userAnswers[0].answer._id
            } else {
              if (elementAnswers.default) {
                answerId = { _id: elementAnswers._id }
                element.selectedAnswer = elementAnswers._id
              }
            }
          })
          if (_.isEmpty(userAnswers)) {
            this.fqcData.userResponse.push({
              question: { _id: element._id },
              answer: answerId
            })
          }

        });

        const groupBy = (array: any, key: any) => {
          return array.reduce((result: any, currentValue: any) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
          }, {}); // empty object is the initial value for result object
        };
        this.questionList = [];
        const personGroupedByColor = groupBy(this.questionForm.questions, 'sectionName');
        for (var i in personGroupedByColor) {
          this.questionList.push({
            name: i,
            detail: personGroupedByColor[i],
          });
        }
      }
    });
  }

  waitingQA() {
    this.getQandA();
  }

  /******************************************** get asset by query ******************************************/
  public getAssetByQuery(event: any) {
    const query = event.query;
    if (query != null && query.length > 2) {
      this.subscriber5 = this.assetsService.getAssetsByName(query).subscribe(
        (data) => {
          this.assets = data.data;
        },
        (err) => this.errorUtil.setErrorMessage(err)
      );
    }
  }

  /******************************************** reset assets ******************************************/
  public onvalidAsset() {
    if (this.assetDetails == null || this.assetDetails.id == null) {
      this.assetName = null;
      this.selectedAsset = { name: '' };
    }
  }

  /************************************************** start(asset search component) ****************************************/
  // get selected asset
  public gotSelectedAsset(val: any) {
    // set selected asset
    this.selectedAsset = val;
    // get the asset
    this.selectValueAsset(val);
    this.fqcData.assets = val;
    this.getAssets();
    this.addFQCForm.controls['assetsName'].setValue(val.name);
    this.addFQCForm.controls.assetsName.disable();
    this.displayAssetTable = false;
    this.waitingQA();
  }

  /******************************************** on value selected ******************************************/
  public selectValueAsset(value: any) {
    this.fqcData.assets.id = value.id;
    this.fqcData.assets.name = value.name;
    this.assetName = value;
    // this.assets = [];
  }

  /******************************************** clear asset value ******************************************/
  public clearAssetValue() {
    this.fqcData.assets.id = null;
    this.assetName = null;
    this.assets = [];
    this.addFQCForm.controls['assetsName'].setValue(null);
  }

  /******************************************** close asset table ******************************************/
  public closeAssetView() {
    this.displayAssetTable = false;
  }

  /******************************************** toggle asset table ******************************************/
  public showAssetTable() {
    this.displayAssetTable = !this.displayAssetTable;
  }


  /**************************************** Submit Form ************************************/
  onSubmitForm() {
    if (this.addFQCForm.controls['status'].invalid) {
      this.addFQCForm.controls['status'].markAsTouched();
      return false;
    }
    this.displayModal = false;
    if (!this.addFQCForm.valid) {
      for (var i in this.addFQCForm.controls) {
        this.addFQCForm.controls[i].markAsTouched();
      }
      return false;
    }

    this.setupValues();
    // return false;
    this.spinner.show();
    this.subscriber6 = this.iqcFqcService.updateFqc(this.fqc).subscribe((res: any) => {
      console.log(res);
      this.spinner.hide();
      if (res.status === 200 || res.status === 201) {
        this.errorUtil.setErrorMessage(200, 'FQC updated successfully', null, 'success',1000);
        this.router.navigate(['dashboard/iqc-fqc/fqc-view/' + this._id], { queryParams: { activeIndex: 1 } });
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error',3000);
      }
    });
  }

  setupValues() {
    this.fqc = _.clone(this.fqcData);
    this.fqc.newMeterReading = this.addFQCForm.controls['newMeterReading'].value;

    this.fqc.frequency = this.addFQCForm.controls['frequency'].value;
    this.fqc.operatingPressure = this.addFQCForm.controls['operatingPressure'].value;
    this.fqc.alternatorReading = this.addFQCForm.controls['alternatorReading'].value;
    this.fqc.vacuumPressure = this.addFQCForm.controls['vacuumPressure'].value;
    this.fqc.dischargePressure = this.addFQCForm.controls['dischargePressure'].value;
    this.fqc.terminalVoltage = this.addFQCForm.controls['terminalVoltage'].value;

    this.fqc.remark = this.addFQCForm.controls['remark'].value;
    this.fqc.status = this.addFQCForm.controls['status'].value;
    this.fqc.userResponse = this.fqcData.userResponse;
    console.log(this.fqc);
  }

  getAnswer(userAnswer: any, question: any) {
    // console.log(userAnswer)
    this.fqcData.userResponse.forEach((element: any) => {
      if (element.question._id == userAnswer.question._id) {
        // console.log(element.question._id," == ",userAnswer.question._id)
        element.answer = { _id: userAnswer._id }
      }
    });
    question.selectedAnswer = userAnswer._id;
  }

  changeStatus() {
    console.log(this.fqcData.status)
    if (this.fqcData.status == "NEW") {
      this.statusMessage = ''
    } else if (this.fqcData.status == "DRAFT") {
      this.statusMessage = 'Please note that changes will be saved to be continued later'
    } else if (this.fqcData.status == "IN_PROGRESS") {
      this.statusMessage = ' Please note that once submitted, all relevant tasks will be created and actions will not reversible'
    } else if (this.fqcData.status == "COMPLETED") {
      this.statusMessage = ' Please note that once submitted, all relevant tasks will be created and actions will not reversible'
    }
    this.cd.detectChanges();
  }

  showModalDialog() {
    this.displayModal = true;
  }

  goBack() {
    this.util.goBack()
  }


}
