import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams, GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
var router: Router

@Component({
  selector: 'app-machines-specialist-table',
  templateUrl: './machines-specialist-table.component.html',
  styleUrls: ['./machines-specialist-table.component.scss']
})
export class MachinesSpecialistTableComponent extends SubscriptionUtil implements OnInit, OnDestroy, OnChanges {

  @Input() displayAction: any = false
  @Input() searchValue: any = null

  @Input() filterData: any
  @Output() noDataEvent = new EventEmitter();
  @Input() siteId: any = null

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;
  overlayNoRowsTemplate: string;

  msData: any = [];
  actionData: any;
  totalRecord: any;
  currentRecordNumber: any;
  refresher!: Subscription;
  noCustomers: boolean = false;
  noCustomersIcon: boolean = false;
  dateFormat: any;
  statuses: any;

  constructor(
    private auth: AuthenticationService,
    private checklistService: ChecklistService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private spinner: NgxSpinnerService,
    private perm: PermissionService,
  ) {

    super();
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'infinite',
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    }
    this.columnDefs = [
      { headerName: 'Running No', field: 'runningNo', width: 170, cellClass: 'table_default_color', headerTooltip: "#", },
      { headerName: 'Name', field: 'user.fullname', width: 170 },
      {
        headerName: 'User Type', field: 'userType', width: 210, headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.userType) {
              return params.data.userType == 'MACHINE_SP' ? 'Normal User' : 'Team Leader';
            }
          }
        }
      },
      { headerName: 'Default Primary Machine', field: 'defaultAssets.name', width: 210, headerTooltip: "#", cellClass: 'table_default_color'},
      { headerName: 'Default Estate', field: 'defaultSite.siteName', width: 210, headerTooltip: "#", cellClass: 'table_default_color'},
      {
        headerName: 'Status', field: 'status', width: 130, headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.status) {
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
            } else {
              return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
            }
          }
        }
      },
    ]

    this.defaultColDef = {
      enableRowGroup: false,//ag-Grid-Enterprise
      enablePivot: false,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";

    this.actionData = {
      headerName: 'Action', filter: false, sortable: false, width: 120, headerClass: 'marging-auto hide-action-border', cellStyle: { "text-align": "center" }, cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("machine specialist", "update"),
          remove: this.perm.capable("machine specialist", "remove"),
        },
        hidden: {
          update: !this.perm.capable("machine specialist", "update"),
          remove: !this.perm.capable("machine specialist", "remove"),
        },
      },
      headerTooltip: "#",
    }


  }

  ngOnInit() {
    router = this.router
    if (this.displayAction) {
      this.columnDefs.push(this.actionData)
    }

  }

  /********************************** Click Cell Event *****************************/
  edit(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["dashboard/machines/update"], { queryParams: { id: e.rowData.id }, });

    } else if (e.action === "delete") {
      e.rowData.deleteType = "Machines Specialist";
      e.rowData.message = "Are you sure you want to delete " + e.rowData.runningNo + "?";
      this.deleteRecordService.getItems(e.rowData);
    }
  }
  onRefreshList() {
    this.onGridReady(this.gridParams);

  }
  /********************************** Search Data **************************/
  quickSearch() {
   
    this.onGridReady(this.gridParams);
  }
  /********************************** View Singal Record **************************/
  onGridReady(params: any) {
    //console.log(params)
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          this.getServerSideData(params);
        }
      }

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    // //console.log('Making a server request: ', serverRequest);

    this.gridApi.hideOverlay();

    this.push(
      this.checklistService.getAllMachinesSpecialist(this.searchValue, serverRequest, this.siteId, this.filterData).subscribe((data: any) => {
        console.log(data)
        this.msData = data.body.data
        this.msData.forEach((element: any) => {
          element.user.fullname = element.user.firstName + ' ' + element.user.lastName
        });
        this.totalRecord = data.body.total
        this.setGridData(agGridGetRowsParams, this.msData, data.body.total, data.body.status);
        
      },
        (err) => {
        // console.log(err,"===>>>")
          this.errorUtil.setErrorMessage(err);

        })
    )
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
    console.log(resultItems, "===")
    this.spinner.hide()
    if (this.msData.length === 0) {
      this.noCustomers = false;
      this.noCustomersIcon = true
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noCustomers = true;
      this.noCustomersIcon = false
      agGridGetRowsParams.successCallback(this.msData, totalCount);
     ///CS-4758  this.gridApi.sizeColumnsToFit();
    }



  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {

    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "id", sort: "asc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel
    }
  }
  /********************************** View Singal Record **************************/

  viewAsset(e: any) {
    console.log(e.colDef, "===", this.filterData, "----", this.displayAction)
    if (this.displayAction) {
      if (e.colDef.field === "runningNo") {
        this.router.navigate(['dashboard/machines/view'], { queryParams: { id: e.data.id } });
      }else if (e.colDef.field === "defaultAssets.name") {
        this.router.navigate(['dashboard/assets/view/'+e.data.defaultAssets.id], { queryParams: { id: e.data.defaultAssets.id } });
  
      }else if (e.colDef.field === "defaultSite.siteName") {
        this.router.navigate(['dashboard/sites/view/'+e.data.defaultSite.id], { queryParams: { id: e.data.defaultSite.id } });
  
      }
    } else {
      this.noDataEvent.emit(e.data);
    }

  }

  /******************************************** Refresh Table ******************************************/
  public refresh() {
    //debugger;
      this.spinner.show()
    this.onGridReady(this.gridParams);
  }

  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let results = [];
    if (params.column.colId == 'runningNo') {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    } 
    if (params.column.colId == "defaultAssets.name") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }
    if (params.column.colId == "defaultSite.siteName") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }
    return results;
  }

  private redirectToNewTab(data: any) {
    let url;
    console.log(data)
    if (data.field === "runningNo") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["/dashboard/machines/view"],{queryParams: { id: data.id } } )
      );
    }
    if (data.field === "defaultAssets.name") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/assets/view/" + data.defaultAssets.id], {
          queryParams: { id: data.defaultAssets.id },
        })
      );
    }
    if (data.field === "defaultSite.siteName") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/sites/view/" + data.defaultSite.id], {
          queryParams: { id: data.defaultSite.id },
        })
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      window.open(url, "_blank", "noreferrer");
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if(this.displayAction){
      if (this.searchValue == "" || this.searchValue) {
        this.refresh()
      }
    }else{
       this.refresh()
      
    }

   

  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  push(obs: any) {
    super.push(obs);
  }
}

