import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { CalendlyIntService } from 'src/app/_services/calendly-int.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-set-up-events',
  templateUrl: './set-up-events.component.html'
})
export class SetUpEventsComponent implements OnInit {

  buttons: any;
  data: any
  jobOrderTypes: any[]= [];
  selectedTobOrderTypes: any[] =[];
  calendlyEventJobOrderTypes:any =[]
  calendlyEventJOTForm: UntypedFormGroup[] = [];
  submitTouched: boolean = false;
  CalendlJobOrderData: any;

  constructor(public modalRef: BsModalRef,
    private jobOrderService: JobOrdersService,
    private calendlyService: CalendlyIntService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private breadcrumbService: BreadcrumbService,) {

      this.calendlyEventJOTForm[0] = new UntypedFormGroup({
        'jobOrderType': new UntypedFormControl(null, [Validators.required])
      });
    }

  ngOnInit(): void {
    this.buttons = Array(2);
    this.breadcrumbService.setSetupItems([]);
    this.getJOType()
  }
  /*==================================Get Selected Data ============================== */
  getCalendlEeventJobOrderTypes(){
  
    this.calendlyService.getCalendlyJobOrderTypes().subscribe((res: any) => {
      this.spinner.hide()
     // this.calendlyEventJobOrderTypes = res
      if(res.calendlyEventJobOrderTypes.length >0){
        
       var calendlyEventJobOrderTypes = res.calendlyEventJobOrderTypes


        // this.calendlyEventJobOrderTypes.forEach((element: any, ind: any) => {
        //   element.jobOrderTypes = this.jobOrderTypes.filter((t:any)=> this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)<0 
        //   || (t.id==element.jobOrderType.id && this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)>-1));
        // });
        console.log(calendlyEventJobOrderTypes,"====",this.calendlyEventJobOrderTypes)

        this.jobOrderTypes.forEach((element: any, ind: any) => {
          var newData = _.find(calendlyEventJobOrderTypes,(t:any)=>{ return element.id==t.jobOrderType.id});
          console.log(newData)

          var calendlyEventJobOrder = {
            jobOrderType:element, 
            calendlyEventId: null,
            id: null
          }
          if(newData){
             calendlyEventJobOrder.id= newData.id 
             calendlyEventJobOrder.calendlyEventId= newData.calendlyEventId
          }
          this.calendlyEventJobOrderTypes.push(calendlyEventJobOrder)


        });

      }
    })
  
}
/*==================================Get Jo Type ============================== */
  getJOType(){
  this.spinner.show()
      this.jobOrderService.getJobOrderTypes(true).subscribe((res: any) => {
        
        this.jobOrderTypes = res?.filter((jot: any) => jot.active).sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });
        this.getCalendlEeventJobOrderTypes()
      })
    
  }


/*==================================Create new line item ============================== */
createNew()
{
//var s=document.getElementById("target"+index+"").style.display = 'none';

  var tempItem: any = {
    jobOrderType:{id:null},
    calendlyEventId:null,
    jobOrderTypes : this.jobOrderTypes.filter((t:any)=> this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)<0)
  }
  this.calendlyEventJOTForm[this.calendlyEventJobOrderTypes.length] = new UntypedFormGroup({
    'jobOrderType': new UntypedFormControl(null, [Validators.required])
  });
  this.calendlyEventJobOrderTypes.push(tempItem);
  
 
}
/*==================================Remove line item ============================== */

removeRecord(index: number){
  if(index>0){
    let tempItem: any = this.calendlyEventJobOrderTypes[index];
    // let jotIndx = this.jobOrderTypes_.findIndex((j:any)=> j.id==tempItem.jobOrderType.id);
    // if(jotIndx > 0) this.jobOrderTypes.push(this.jobOrderTypes_[jotIndx]);
    this.calendlyEventJOTForm.splice(index, 1);
    this.calendlyEventJobOrderTypes.splice(index, 1);
    let jotIndx = this.selectedTobOrderTypes.findIndex((j:any)=> j.id==tempItem.jobOrderType.id);
    if(jotIndx > 0) this.selectedTobOrderTypes.splice(jotIndx,1);
    this.calendlyEventJobOrderTypes.forEach((element: any, ind: any) => {
      element.jobOrderTypes = this.jobOrderTypes.filter((t:any)=> this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)<0 
      || (t.id==element.jobOrderType.id && this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)>-1));
  });
  }
}

selectJoType(type: any){
  // let jotIndx = this.jobOrderTypes.findIndex((j:any)=> j.id==id);
  // if(jotIndx > 0) this.jobOrderTypes.splice(jotIndx, 1);
  this.selectedTobOrderTypes.push(type);
  this.calendlyEventJobOrderTypes.forEach((element: any, ind: any) => {
    element.jobOrderTypes = this.jobOrderTypes.filter((t:any)=> this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)<0 
    || (t.id==element.jobOrderType.id && this.selectedTobOrderTypes.findIndex((j:any)=> j.id==t.id)>-1));
  });
}

onSubmit(){
  this.submitTouched = true;
  
  // var check = _.filter(this.calendlyEventJOTForm, (o) => {
  //   return o.status == 'INVALID';
  // })
  // console.log("====",check.length )
  // if(check.length > 0){
 
  //   this.calendlyEventJOTForm.forEach(form => {
  //     if(!form.valid){
  //       for (var i in form.controls) {
  //         form.controls[i].markAsTouched();
  //       }
  //     }
  //   });

  //   this.spinner.hide();
  //   this.submitTouched = false;
  //   return;
  // }
  var calendlyEventJobOrderTypes={
    calendlyEventJobOrderTypes:[...this.calendlyEventJobOrderTypes]
  }

      console.log(this.calendlyEventJobOrderTypes,"====",calendlyEventJobOrderTypes)
  this.spinner.show()

  this.calendlyService.updateCalendly(calendlyEventJobOrderTypes).subscribe((res:any) => {
    console.log(res.body)
    this.spinner.hide()
    if(res.status == 201 || res.status == 200){
      this.errorUtil.setErrorMessage(200, 'Set up Event updated successfully' ,  null, 'success',1000);
      this.modalRef.hide()
       
    } else {
      this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      this.submitTouched = false;
    }
  })

}
}
