import { PlanUpdateConfirmationComponent } from './../dashboard/planning-hub/plan-update-confirmation/plan-update-confirmation.component';
import { Injectable } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import * as _ from "lodash";
import { AuthenticationService } from "../auth/authentication.service";
import { AddConversationComponent } from "../dashboard/tickets/add-conversation/add-conversation.component";
import { ResetPasswordComponent } from "../dashboard/setup/users/reset-password/reset-password.component";
import { AddEditJobOrderComponent } from "../dashboard/job-orders/add-edit-job-order/add-edit-job-order.component";
import { CompletionFormComponent } from "../dashboard/job-orders/completion-form/completion-form.component";
import { VerificationRequisitionComponent } from "../dashboard/inventory/requisition/verification-requisition/verification-requisition.component";
import { AddEditTaskComponent } from "../dashboard/tasks/add-edit-task/add-edit-task.component";
import { ParentModalComponent } from "../shared/comman-component/parent-modal/parent-modal.component";
import { SmartAssignComponent } from "../dashboard/tasks/smart-assign/smart-assign.component";
import { ViewMapPinComponent } from "../shared/comman-component/view-map-pin/view-map-pin.component";
import { DownloadBarcodeComponent } from "../shared/comman-component/download-barcode/download-barcode.component";
import { AddEditSlaCriteriaComponent } from "../dashboard/setup/sla/sla-step/add-edit-sla-criteria/add-edit-sla-criteria.component";
import { AddEditHPFormComponent } from "../dashboard/job-orders/add-edit-hpform/add-edit-hpform.component";
import { CancelContractComponent } from "../dashboard/asset/contracts/cancel-contract/cancel-contract.component";
import { ImportDataComponent } from "../dashboard/setup/import-export/import-data/import-data.component";
import { ExportDataComponent } from "../dashboard/setup/import-export/export-data/export-data.component";
import { ImportUploadFilesComponent } from "../dashboard/setup/import-export/import-upload-files/import-upload-files.component";
import { ImportDataListComponent } from "../dashboard/setup/import-export/import-data-list/import-data-list.component";
import { DownloadExportDataComponent } from "../dashboard/setup/import-export/download-export-data/download-export-data.component";
import { TicketFormatComponent } from "../dashboard/setup/tickets/ticket-format/ticket-format.component";
import { AddTaskQuestionComponent } from "../dashboard/setup/checklist/equipment/iqc-fqc-question/add-task-question/add-task-question.component";
import { CheckAvailabilityComponent } from "../dashboard/tasks/check-availability/check-availability.component";
import { TaskCloneComponent } from "../dashboard/tasks/task-clone/task-clone.component";
import { AddEditLastLocationComponent } from "../dashboard/vehicles/add-edit-last-location/add-edit-last-location.component";
import { LinkTrackingComponent } from "../shared/comman-component/link-tracking/link-tracking.component";
import { SlaConditionComponent } from "../dashboard/setup/sla/sla-package/sla-condition/sla-condition.component";
import { DoFormatComponent } from "../dashboard/setup/delivery-orders/do-format/do-format.component";
import { UploadPhotoComponent } from "../dashboard/setup/users/upload-photo/upload-photo.component";
import { MapViewTrackComponent } from "../dashboard/shared/map-view-track/map-view-track.component";
import { CloneAccessComponent } from "../dashboard/setup/access/clone-access/clone-access.component";
import { ViewDoDetailsComponent } from "../dashboard/planning-hub/view-do-details/view-do-details.component";
import { ModifyPlanComponent } from "../dashboard/planning-hub/modify-plan/modify-plan.component";
import { ModifyAddOrderComponent } from "../dashboard/planning-hub/modify-add-order/modify-add-order.component";
import { SwitchOrderSameVehicleComponent } from "../dashboard/planning-hub/switch-order-same-vehicle/switch-order-same-vehicle.component";
import { SwitchOrderDiffVehicleComponent } from "../dashboard/planning-hub/switch-order-diff-vehicle/switch-order-diff-vehicle.component";
import { ModifyRemoveOrderComponent } from "../dashboard/planning-hub/modify-remove-order/modify-remove-order.component";
import { ModifyChangeVehicleDriverComponent } from "../dashboard/planning-hub/modify-change-vehicle-driver/modify-change-vehicle-driver.component";
import { InfoGuideComponent } from "../shared/comman-component/info-guide/info-guide.component";
import { ModifyBreakdownComponent } from "../dashboard/planning-hub/modify-breakdown/modify-breakdown.component";
import { MagnumDashboardTableComponent } from "../dashboard/home/magnum/magnum-dashboard-table/magnum-dashboard-table.component";
import { DeletePlanningHubComponent } from "../dashboard/planning-hub/delete-planning-hub/delete-planning-hub.component";
import { AcceptDeclineTaskComponent } from "../dashboard/tasks/accept-decline-task/accept-decline-task.component";
import { AddMemberComponent } from "../dashboard/tasks/add-member/add-member.component";
import { SplitOrderComponent } from "../dashboard/delivery-orders/split-order/split-order.component";
import { OptimizeDistanceComponent } from '../dashboard/tasks/optimize-distance/optimize-distance.component';
import { AddEditNotesComponent } from '../dashboard/tasks/view-tasks/add-edit-notes/add-edit-notes.component';
import { RequestAssistanceComponent } from '../dashboard/tasks/request-assistance/request-assistance.component';
import { CancelShiftsComponent } from '../dashboard/shifts/cancel-shifts/cancel-shifts.component';
import { AcceptRejectShiftComponent } from '../dashboard/shifts/accept-reject-shift/accept-reject-shift.component';
import { SalesOrderFormatComponent } from '../dashboard/setup/sales-order/sales-order-format/sales-order-format.component';
import { JobOrderFormatComponent } from '../dashboard/setup/job-orders/job-order-format/job-order-format.component';
import { ProjectFormatComponent } from '../dashboard/setup/projects/project-format/project-format.component';
import { SiteFormatComponent } from '../dashboard/setup/sites/site-format/site-format.component';
import { TaskJourneyComponent } from '../dashboard/tasks/task-journey/task-journey.component';
import { HazardControlComponent } from '../dashboard/tasks/hazard-control/hazard-control.component';
import { CustellaUpdateComponent } from '../dashboard/home/my-dashboard/custella-update/custella-update.component';
import { ExactAssignComponent } from '../dashboard/tasks/exact-assign/exact-assign.component';
import { ChecklistProductComponent } from '../shared/comman-component/checklist-product/checklist-product.component';
import { UpdateStatusComponent } from '../dashboard/shared/update-status/update-status.component';
import { AddJoTaskTemplateComponent } from '../dashboard/setup/job-orders/jo-templates/jo-task-template/add-jo-task-template/add-jo-task-template.component';
import { DefaultConfirmationDialogComponent } from '../dashboard/planning-hub/default-confirmation-dialog/default-confirmation-dialog.component';
import { CustomerFormatComponent } from '../dashboard/setup/customer/customer-format/customer-format.component';
import { AssetFormatComponent } from '../dashboard/setup/assets/asset-format/asset-format.component';
import { BehaviorSubject } from 'rxjs';
import { CalendlyLinkComponent } from '../dashboard/job-orders/calendly-link/calendly-link.component';
import { SetUpEventsComponent } from '../dashboard/setup/integrations/set-up-events/set-up-events.component';
import { UpdateThemeComponent } from '../dashboard/setup/integrations/update-theme/update-theme.component';
import { DisconnectConfmationComponent } from '../public/google-calendar/disconnect-confmation/disconnect-confmation.component';
import { SalesforceIntegrationComponent } from '../dashboard/setup/integrations/salesforce-integration/salesforce-integration.component';
import { SurveyLinkComponent } from '../dashboard/job-orders/survey-link/survey-link.component';
import { ViewSurveyFormComponent } from '../dashboard/job-orders/view-survey-form/view-survey-form.component';
import { ResetContactPasswordComponent } from '../dashboard/shared/reset-contact-password/reset-contact-password.component';
import { AddEditTeamMembersComponent } from '../dashboard/teams/view-team-schedules/team-members/add-edit-team-members/add-edit-team-members.component';

@Injectable()
export class ModalServiceService {
  selectedComponent: any;
  selectedConfig: any;
  modalState: any;
  modalRef!: BsModalRef;
  addonFeature: any;
  configMd: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };
  configMdCenter: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md modal-dialog-centered",
  };
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  configCustomLgNonScroll: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  }
  configSMCenter: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm modal-dialog-centered",
  };
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg modal-dialog-scrollable",
  };
  configCustomLgCenter: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg modal-dialog-scrollable modal-dialog-centered",
  };

  configCustomXl: any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
    class: "custom-modal-xl",
  };

  private allModalRefs: BsModalRef[] = [];

  constructor(
    public modalService: BsModalService,
    private auth: AuthenticationService
  ) {
    this.init();
  }

  private init() {
    if (this.modalRef) {
      this.modalRef.onHide?.subscribe((eve) => {
        this.allModalRefs.forEach((mf) => {
          mf.hide();
        });
      });
    }
  }


  openModal(modalView: any, data2: any, config?: any) {
    if (config) {
      this.configMd = Object.assign(this.configMd, config);
    }

    this.addonFeature = this.auth.addonFeature();
    var data = _.cloneDeep(data2);
    //console.log(data)
    this.selectedConfig = this.configMd;
    if (modalView === "resetPassword") {
      this.modalState = {
        title: "Reset Password",
        data: data.login,
        type: "self",
      };
      this.selectedConfig = this.configSM;
      // this.selectedComponent = DeletePaymentComponent;
      this.selectedComponent = ResetPasswordComponent;
    }else if (modalView === "resetContactPassword") {
      this.modalState = {
        title: "Reset Password",
        data: data.login,
        data1: data,
        type: "self",
      };
      this.selectedConfig = this.configSM;
      // this.selectedComponent = DeletePaymentComponent;
      this.selectedComponent = ResetContactPasswordComponent;
    } else if (modalView == "addComment") {
      this.modalState = {
        title: "Add Comment",
        mode: "add",
        data: data,
      };
      this.selectedComponent = AddConversationComponent;
    } else if (modalView === "completionForm") {
      this.modalState = {
        title: "Completion Form",
        data: data,
      };

      this.selectedComponent = CompletionFormComponent;
    } else if (modalView === "addEditTask") {
      this.modalState = {
        title: "Add/Edit Task",
        data: data,
        mode: data.mode,
      };

      this.selectedComponent = AddEditTaskComponent;
    } else if (modalView === "addEditTeamMembers") {
      this.modalState = {
        title: "New Members",
        data: data,
        mode: "add",
      };
      this.selectedConfig = this.configSMCenter;
      this.selectedComponent = AddEditTeamMembersComponent;
    } else if (modalView == "welcomeUpdate") {
      this.modalState = {
        title: "",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CustellaUpdateComponent;
    } else if (modalView === "editTeamMembers") {
      this.modalState = {
        title: "Update Member",
        data: data,
        mode: "edit",
      };
      this.selectedConfig = this.configSMCenter;
      this.selectedComponent = AddEditTeamMembersComponent;
    } else if (modalView === "smart-assign") {
      this.modalState = {
        title: "smart-assign",
        data: data,
        mode: "add",
      };

      this.selectedComponent = SmartAssignComponent;
    } else if (modalView === "confirmContactResetPassword") {
      this.modalState = {
        title: "Reset Password",
        data: data.login,
        type: "others",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetContactPasswordComponent;
    } else if (modalView === "confirmResetPassword") {
      this.modalState = {
        title: "Reset Password",
        data: data.login,
        data1:data,
        type: "others",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetPasswordComponent;
    } else if (modalView === "resendActivationEmail") {
      this.modalState = {
        title: "Resend Activation Email",
        data: data.id,
        type: "resendCustomerUserEmail",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetPasswordComponent;
    } else if (modalView === "resendActivationEmailUser") {
      this.modalState = {
        title: "Resend Activation Email",
        data: data.id,
        data1: data,
        type: "resendEmail",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = ResetPasswordComponent;
    } else if (modalView == "verificationRequisition") {
      this.modalState = {
        title: "Verification",
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = VerificationRequisitionComponent;
    } else if (modalView == "viewMapPin") {
      this.modalState = {
        title: "View Map Pin",
        data: data,
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = ViewMapPinComponent;
    } else if (modalView == "barcode") {
      this.modalState = {
        data: data,
      };
      this.selectedComponent = DownloadBarcodeComponent;
    } else if (modalView == "addEditCriteria") {
      this.modalState = {
        title: "Add Criteria",
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = AddEditSlaCriteriaComponent;
    } else if (modalView == "addProduct") {
      this.modalState = {
        title: "Add Products",
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configMdCenter;
      this.selectedComponent = ChecklistProductComponent;
    } else if (modalView == "updateStatus") {
      this.modalState = {
        title: "Update Status",
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configCustomLgNonScroll;
      this.selectedComponent = UpdateStatusComponent;
    } else if (modalView == "createCondition") {
      this.modalState = {
        title: "Create Condition",
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SlaConditionComponent;
    } else if (modalView == "optimizeDistance") {
      this.modalState = {
        title: "Optimize Distance",
        mode: "create",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = OptimizeDistanceComponent;
    } else if (modalView == "editNotes") {
      this.modalState = {
        title: "Edit Notes",
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AddEditNotesComponent;
    } else if (modalView == "addTaskQuestion") {
      this.modalState = {
        title: "Add Task",
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = AddTaskQuestionComponent;
    } else if (modalView === "addEditHpForm") {
      this.modalState = {
        title: "Edit HP Form",
        mode: "edit",
        data: data,
      };
      this.selectedComponent = AddEditHPFormComponent;
    } else if (modalView === "cancelContract") {
      this.modalState = {
        title: "Cancel Contract",
        data: data,
      };
      this.selectedComponent = CancelContractComponent;
    } else if (modalView == "import") {
      this.modalState = {
        title: "Import Data",
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomXl;
      this.selectedComponent = ImportDataComponent;
    } else if (modalView == "importUpload") {
      this.modalState = {
        title: "Import Data",
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomXl;
      this.selectedComponent = ImportUploadFilesComponent;
    } else if (modalView == "listImports") {
      this.modalState = {
        title: "Import Data",
        mode: "edit",
        data: data,
      };
      this.selectedConfig = this.configCustomXl;
      this.selectedComponent = ImportDataListComponent;
    } else if (modalView == "export") {
      this.modalState = {
        title: "Export Data",
        mode: "edit",
        data: data,
      };
      this.selectedComponent = ExportDataComponent;
    } else if (modalView == "listExport") {
      this.modalState = {
        title: "Export Data",
        mode: "edit",
        data: data,
      };
      this.selectedComponent = DownloadExportDataComponent;

    } else if (modalView == "viewCustomerFormat") {
      this.modalState = {
        title: "Customer Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CustomerFormatComponent;
    } else if (modalView == "viewAssetFormat") {
      this.modalState = {
        title: "Asset Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = AssetFormatComponent;
    } else if (modalView == "viewTicketFormat") {
      this.modalState = {
        title: "Ticket Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = TicketFormatComponent;
    } else if (modalView == "viewSalesOrderFormat") {
      this.modalState = {
        title: "Sales Order Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SalesOrderFormatComponent;
    } else if (modalView == "viewJobOrderFormat") {
      this.modalState = {
        title: "Job Order Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = JobOrderFormatComponent;
    } else if (modalView == "viewDeliveryOrderFormat") {
      this.modalState = {
        title: "Delivery Order Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = DoFormatComponent;
    } else if (modalView == "checkAvailability") {
      this.modalState = {
        title: "Check Availability",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CheckAvailabilityComponent;
    } else if (modalView == "taskClone") {
      this.modalState = {
        title: "Task Clone",
        mode: "add",
        data: data,
      };
      this.selectedComponent = TaskCloneComponent;
    } else if (modalView == "addLastLocation") {
      this.modalState = {
        title: "Last Location",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = AddEditLastLocationComponent;
    } else if (modalView == "linkTrack") {
      this.modalState = {
        title: "Link Tracking",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = LinkTrackingComponent;
    } else if (modalView == "uploadPhoto") {
      this.modalState = {
        title: "Upload Photo",
        data: data,
        type: "user",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = UploadPhotoComponent;
    } else if (modalView == "uploadTeamPhoto") {
      this.modalState = {
        title: "Upload Photo",
        data: data,
        type: "team",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = UploadPhotoComponent;
    } else if (modalView == "viewOrderDetails") {
      this.modalState = {
        title: "View Order Details",
        data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ViewDoDetailsComponent;
      //Planning Hub//
    } else if (modalView == "modifyPlan") {
      this.modalState = {
        title: "Modify Plan",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyPlanComponent;
    } else if (modalView == "infoGuide") {
      this.modalState = {
        title: "Guide",
        data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = InfoGuideComponent;
      /********************************************** planing HUb Start ****************************************************************/
    } else if (modalView == "addOrderSpecificVehicle") {
      this.modalState = {
        title: "Add Order to Specific Vehicle",
        mode: "add",
        data: data,
        optionType: "addOrderSpecificVehicle",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyAddOrderComponent;
    } else if (modalView == "addOrderCurrentVehicle") {
      this.modalState = {
        title: "Add Order to any current plan vehicle",
        mode: "add",
        data: data,
        optionType: "addOrderCurrentVehicle",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyAddOrderComponent;
    } else if (modalView == "proceedAddOrderSpeVeh") {
      this.modalState = {
        title: "Add Order to Specific Vehicle",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    } else if (modalView == "proceedAddOrderCurrVeh") {
      this.modalState = {
        title: "Add order to any current plan vehicle",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    } else if (modalView == "switchOrderSameVehicle") {
      this.modalState = {
        title: "Switch order between same vehicle",
        mode: "add",
        data: data,
        optionType: "switchOrderSameVehicle",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SwitchOrderSameVehicleComponent;
    } else if (modalView == "switchOrderDiffVehicle") {
      this.modalState = {
        title: "Switch Order between different vehicle",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SwitchOrderDiffVehicleComponent;
    } else if (modalView == "removeOrder") {
      this.modalState = {
        title: "Remove order from plan",
        mode: "add",
        data: data,
        optionType: "removeOrder",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyRemoveOrderComponent;
    } else if (modalView == "removeVehicle") {
      this.modalState = {
        title: "Remove vehicle from plan",
        data: data,
        optionType: "removeVehicle",
      };
      this.selectedComponent = ModifyRemoveOrderComponent;
    } else if (modalView == "cancelOrder") {
      this.modalState = {
        title: "Cancel Single Order",
        data: data,
        optionType: "cancelOrder",
      };
      this.selectedComponent = ModifyRemoveOrderComponent;
    } else if (modalView == "changeVehicle") {
      this.modalState = {
        title: "Switch Vehicle",
        data: data,
        optionType: "changeVehicle",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyChangeVehicleDriverComponent;
    } else if (modalView == "breakdown") {
      this.modalState = {
        title: "Return All Orders in a Vehicle",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyBreakdownComponent;
    } else if (modalView == "adjustReplan") {
      this.modalState = {
        title: "Replan unlocked vehicles",
        data: data,
        optionType: "adjustReplan",
      };
      //this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyRemoveOrderComponent;
    } else if (modalView == "returnOrder") {
      this.modalState = {
        title: "Return Single Order",
        data: data,
        optionType: "returnOrder",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SwitchOrderSameVehicleComponent;

      /********************************************** planing HUb END ****************************************************************/
    } else if (modalView == "hierarchyHelp") {
      this.modalState = {
        title: "Hierarchy Guide",
        data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = InfoGuideComponent;
    } else if (modalView == "deliveryOrderMap") {
      this.modalState = {
        title: "Delivery Order Map View",
        data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = MapViewTrackComponent;
    } else if (modalView == "cloneAssign") {
      this.modalState = {
        title: "Clone Assign",
        data,
      };
      this.selectedComponent = CloneAccessComponent;
    } else if (modalView == "magnumDashboardTable") {
      this.modalState = {
        title: data.title,
        data,
      };
      this.selectedComponent = MagnumDashboardTableComponent;
    } else if (modalView == "deletePlan") {
      this.modalState = {
        title: "Delete Plan",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DeletePlanningHubComponent;
    } else if (modalView === "acceptTask") {
      console.log(data);
      let mode = "accept";
      if (data.groupId) {
        mode = "acceptGroup";
      }
      this.modalState = {
        mode: mode,
        data: data,
        userId: this.auth.getUserId(),
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AcceptDeclineTaskComponent;
    } else if (modalView === "declineTask") {
      let mode = "decline";
      if (data.groupId) {
        mode = "declineGroup";
      }
      this.modalState = {
        mode: mode,
        data: data,
        userId: this.auth.getUserId(),
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AcceptDeclineTaskComponent;
    } else if (modalView === "addMember") {
      this.modalState = {
        mode: "add",
        data: data,
        title: "Add User for your task",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AddMemberComponent;
    } else if (modalView == "splitOrder") {
      this.modalState = {
        title: "Split Order",
        data: data,
        mode: "",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = SplitOrderComponent;
    } else if (modalView == "confirmUpdatePlan") {
      this.modalState = {
        title: data.title,
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    } else if (modalView === "requestAssistance") {
      this.modalState = {
        title: "Request Assistance",
        mode: "add",
        data: data,
      };
      this.selectedComponent = RequestAssistanceComponent;
      this.selectedConfig = this.configSM;
    } else if (modalView == "cancelShift") {
      this.modalState = {
        title: "Shift Cancellation",
        data: data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = AcceptRejectShiftComponent;
    } else if (modalView == "viewProjectFormat") {
      this.modalState = {
        title: "Project Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ProjectFormatComponent;
    } else if (modalView == "viewSiteFormat") {
      this.modalState = {
        title: "Site Format",
        mode: "add",
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SiteFormatComponent;
    } else if (modalView == "taskJourney") {
      this.modalState = {
        title: "Task Journey",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = TaskJourneyComponent;
    } else if (modalView == "hazardControl") {
      this.modalState = {
        title: "Hazard Control",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = HazardControlComponent;
    } else if (modalView == "exact-assign") {
      this.modalState = {
        title: "Assign",
        mode: "add",
        data: data,
      };

      this.selectedComponent = ExactAssignComponent;
    }else if (modalView == "taskTrigger") {
        this.modalState = {
          title: "Task List",
          mode: "add",
          data: data,
          fromPage: "taskTrigger",
        };
        // this.selectedConfig = this.configCustomLg;
        this.selectedComponent = AddJoTaskTemplateComponent;
    }else if (modalView == "sendCalendlyLink") {
      this.modalState = {
        title: "Send Calendly Link",
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = CalendlyLinkComponent;
    }else if (modalView == "setUpEvents") {
      this.modalState = {
        title: "Set Up Events",
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SetUpEventsComponent;
    }else if (modalView == "updateTheme") {
      this.modalState = {
        title: "Update Theme",
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = UpdateThemeComponent;
    }else if (modalView == "salesforceIntegrate") {
      this.modalState = {
        title: "Salesforce Integration",
        mode: "add",
        data: data
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = SalesforceIntegrationComponent;

    }else if (modalView == "ConfirmDialog") {
      this.modalState = {
        ...data,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DefaultConfirmationDialogComponent;
      //Planning Hub// 
    } else if (modalView == "DisconnectGoogleCalenderConfmationComponent") {
      this.modalState = {
        title: "Disconnect Google Calendar",
        data: data,
        page:'Google'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DisconnectConfmationComponent;
    } else if (modalView == "DisconnectMicrosoftCalenderConfmationComponent") {
      this.modalState = {
        title: "Disconnect Microsoft Calendar",
        data: data,
        page:'Microsoft'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DisconnectConfmationComponent;
    } else if (modalView == "DisconnectCalendlyConfmationComponent") {
      this.modalState = {
        title: "Disconnect Calendly Calendar",
        data: data,
        page:'Calendly'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = DisconnectConfmationComponent;
  
      //sendSurvey Link//
    } else if (modalView == "sendSurveyLink") {
      this.modalState = {
        title: "Send Survey Link",
        mode: "add",
        data: data,
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = SurveyLinkComponent;
    } else if (modalView === "viewSurveyForm") {
      this.modalState = {
        title: "View Survey Form",
        data: data,
        mode: data.mode,
      };

      this.selectedComponent = ViewSurveyFormComponent;
    }  else if (modalView == "uploadCalendlyLogo") {
      this.modalState = {
        title: "Upload Logo",
        data: data,
        type: "CalendlyLogo",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = UploadPhotoComponent;
    }else {
      return;
    }

    this.selectedConfig.initialState = this.modalState;
    this.modalRef = this.modalService.show(
      this.selectedComponent,
      this.selectedConfig
    );

    this.allModalRefs.push(this.modalRef);

    return this.modalRef;
  }
}
