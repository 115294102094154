import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  data: any;
  message:any;
  icon: any;
  title: any;
  mode : any;
  assignMode : any;
  // type : string;
  submitTouched : Boolean = false;
  type : any;
  user: any;

  constructor(public modalRef : BsModalRef,
   private router: Router,
    private taskService : TasksService,
    private auth : AuthenticationService,
    private util: UtilServiceService,
    private errorUtil: ErrorUtil) { }

  ngOnInit() {
    if(this.mode == 'taskCheckout')
    {
      this.message = 'Would you like to mark this task as complete?';
      this.icon = 'checkout_icon.png';
    }  
    
    else if(this.mode == 'assignTask')
    {
      this.message = 'Are you sure?';
      this.icon = 'assign_icon.png';
    }  
    
    //console.log(this.data);
  }

  onSubmit(val: any) {
    this.submitTouched = true;
    if(this.mode == 'taskCheckout'){
      let param;
      if(val){
        param = 'COMPLETED'
      } else {
        param = null;
      }
      this.taskService.checkOutTask(this.data.id, param, this.user.assignedTo.id).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          // this.data = res.body;
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, null ,  'Checked out successfully', 'success',1000);
        } else {
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
      });
    } else if(this.mode == 'assignTask'){
      if(val){
        this.modalRef.hide();
        this.util.sendAssignTaskConfirmation(this.data, this.assignMode, this.type);
      }
      else 
        this.modalRef.hide();
    }
  }

}
