import { Component, Input, OnInit } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { AppEvents } from "src/app/_models/global.data.model";
import { ConfirmActionsService } from "src/app/_services/confirm-actions.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-confirm-action",
  templateUrl: "./confirm-action.component.html",
  styleUrls: ["./confirm-action.component.scss"],
  providers: [ConfirmationService],
})
export class ConfirmActionComponent implements OnInit {
  constructor(
    private confirmationService: ConfirmationService,
    private confirmActionService: ConfirmActionsService,
    private util: UtilServiceService
  ) {}

  @Input() data: any;
  message = "Are you sure";

  ngOnInit(): void {
    this.init();
  }

  private init() {
    console.log(this.data);
    this.confirmationService.confirm({
      key: "confirm2",
      message: this.data.message ? this.data.message : this.message,
      accept: () => {
        this.confirm();
        //Actual logic to perform a confirmation
      },
      reject: () => {
        this.reject();
      },
    });
  }

  private confirm() {
    console.log(this.data);

    if (this.data.mode) {
      if (this.data.mode === "assignTask") {
        console.log("HERE");

        this.util.sendData({
          action: AppEvents.ASSIGN_TASK,
          data: this.data,
        });
      }
    }
    this.reject() 
  }

  public reject() {
    this.confirmActionService.passItem();
  }
}
