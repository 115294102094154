import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { DndDropEvent, DropEffect, EffectAllowed } from 'src/app/shared/dnd/src/public-api';
import { SwitchOrderDiffVehicleConfirmationComponent } from '../switch-order-diff-vehicle-confirmation/switch-order-diff-vehicle-confirmation.component';
import { ModifyPlanComponent } from '../modify-plan/modify-plan.component';
@Component({
  selector: 'app-switch-order-diff-vehicle',
  templateUrl: './switch-order-diff-vehicle.component.html',
  styleUrls: ['./switch-order-diff-vehicle.componen.scss']
})
export class SwitchOrderDiffVehicleComponent implements OnInit {
  title:any;
  data: any;
  search1: any;
  search2: any;
  filteredObj: any;
  tripsData: any;

  note: any;
  relatedVehicleEvents: any;
  resources: any;
  //events: any;
  orgResources: any;
  dragableDataList: any;
  dragableDataList2: any;
  formData: any;

  modalState : any;
  selectedConfig : any;
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  };
  filteredRoutes: any;
  type: any = 'preDispatch';
  selectedComponent: any;
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  refreshAfterCancelList: Subscription;
  refreshAfterCancel: Subscription;
  private currentDraggableEvent?:DragEvent;
  private currentDragEffectMsg?:string;
  vehiclesData: any = []

  disableData: boolean= true
  constructor(public modalRef : BsModalRef,
    public modalRef1 : BsModalRef,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private planningHubService: PlanningHubService,
    private router: Router,
    private modalService: BsModalService,
    ) { 

    this.refreshAfterCancel = this.planningHubService.refreshAfterCancel$.subscribe(res => {
      console.log(res,"=-----=-=-")
        if(res== 'cancel'){
          this.loadData();
          
          console.log(this.modalRef)
        }
      });
      this.refreshAfterCancelList = this.planningHubService.refreshAfterCancelList$.subscribe(res => {
        console.log(res,"=-----=-=-")
            if (res === 200 || res === 201) {
            this.loadData2();
          }
          else{
            this.loadData();
          }
      });
  }

ngOnInit() {
  //debugger
  console.log(this.modalRef)
  //console.log(this.data)
  if(this.data.planStatus=="DISPATCHED" || this.data.planStatus=="IN_PROGRESS"){
    this.type = 'postDispatch';
  }
  this.filteredRoutes = this.data.routes.filter((item : any)=> (!item.locked));
  for(var i=0;i<=this.data.routes.length-1;i++){
    if(!this.data.routes[i].locked){
      this.vehiclesData.push(this.data.routes[i].vehicleData.vehicle);
    }
  }
  this.loadData();
}
ngOnDestroy(){
  this.refreshAfterCancel.unsubscribe();
  this.refreshAfterCancelList.unsubscribe();
}

loadData2(){
  this.planningHubService.getFleetRoutePlanById(this.data.draftPlanId,null).subscribe((data: any) => {
    if(data.status === 400){
      this.router.navigate(['/dashboard/planningHub']);
    }
    else{
      this.data.routes = data.body.routes;
      this.loadData();
    }
  })
}

loadData(){
  var resource: any[] = [];
  var events: any[] = [];
  var relatedEvents: any[] = [];
  this.data.routes.forEach((routes :any)=> {
    if(!routes.locked){
      var resource1 = {
        id: routes.vehicleData.vehicle.id,
        vehicleNumber: routes.vehicleData.vehicle.vehicleNumber,
        vehicleName : routes.vehicleData.vehicle.name,
        vehicleType: routes.vehicleData.vehicle.vehicleType.name,
        distance: routes.statistic.distance,
        duration:routes.statistic.duration,
        totalUnits: routes.statistic.totalUnits,
        capacity: routes.statistic.capacity,
        imageUrl: routes.vehicleData.vehicle.icon,
        routeId : routes.id,
        driverName: (routes.vehicleData.vehicle.defaultDriver && routes.vehicleData.vehicle.defaultDriver.user) ? routes.vehicleData.vehicle.defaultDriver.user.fullName : '',
        driverImage: (routes.vehicleData.vehicle.defaultDriver && routes.vehicleData.vehicle.defaultDriver.user) ? routes.vehicleData.vehicle.defaultDriver.user.imageUrl : '',
        vehicleImage : routes.vehicleData.vehicle.vehicleImage ? routes.vehicleData.vehicle.vehicleImage.attachmentUrl : '',
        milkRun: routes.name,
        vehicleData : routes.vehicleData,
        relatedVehicleEvents: null,
        capacityChecked : routes.capacityChecked,
      }
      routes.trips.forEach((trips :any) => {
      if(trips.type!="departure" && trips.type!="arrival"){
        var events1 = {
          type: trips.type,
          tripId: trips.id,//for reorder schedular
          departureTripId:null,
          arrivalTripId:null,
          resourceId: routes.vehicleData.vehicle.id,
          //startDate : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          //endDate : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm'),
          //startDateTime : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('YYYY-MM-DD hh:mm A'),
          //endDateTime : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('YYYY-MM-DD hh:mm A'),
          startTime : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('hh:mm A'),
          endTime : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('hh:mm A'),
          routeId : routes.id,
          orderNo: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.orderNumber : "",
          orderNumber : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? (trips.order.orderNumber +'-'+ moment(trips.order.orderDateTime).format('DDMM')) : "",
          orderName : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.name : '',
          orderStatus : trips.orderLineItem ? trips.order.orderStatus : '',
          orderId : trips.order.id,
          deliveryOrderWay : null,//order type(pickup or drop off)
          orderCustomer : null,
          quantity: (trips.type!="break") ? trips.quantity : '',
          address : null,
          orderEvent : false,
          vehicleData : routes.vehicleData,
          note: null,
          status:(trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.orderLineItem.deleveryOrderLineItem.status : "",
          lineItemId : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.orderLineItem.deleveryOrderLineItem.id : ''
        }
        this.data.assignedOrders.forEach((assignedOrdersData :any) => {
          if(assignedOrdersData.deliveryOrder.orderNumber === events1.orderNo){
            assignedOrdersData.deliveryOrderLineItemVMs.forEach((element :any) => {
              this.note = [];
              // element.deleveryOrderLineItemPackages.forEach((pack :any) => {
              //   this.note.push('('+pack.quantity+')' + '-' + pack.name );
              // })
              events1.note = this.note;
            })
          }
        })
        routes.deliveryOrders.forEach((order :any) => {
          if(order.deliveryOrderLite.id === events1.orderId){
            events1.orderCustomer = order.deliveryOrderLite.customer ? order.deliveryOrderLite.customer.name : null;
            events1.deliveryOrderWay = order.deliveryOrderLite.deliveryOrderWay;
          }
        })
        events.push(events1);
        relatedEvents.push(events1);
      }
      })
      this.relatedVehicleEvents = relatedEvents;
      relatedEvents = [];
      resource1.relatedVehicleEvents = this.relatedVehicleEvents;
      resource.push(resource1);
    }
  })
  this.orgResources = _.cloneDeep(resource);
  this.resources = resource;
  this.dragableDataList =  _.cloneDeep(this.orgResources);
  //this.events = events;
  if(this.search2){
    this.filterResults2();//after drag and drop call
  }
  //else if(this.selectedCard){
  //   this.nameClick(this.selectedCard);
  // }
}

filterResults1(){
  // this.selectedCard = '';
  if(this.search1 == this.search2){
    this.search2 = null;
    this.dragableDataList2 = [];
  }
   if(this.search1){
     for(var i=0;i<=this.orgResources.length-1;i++){
       if(this.orgResources[i].id == this.search1){
        // this.resources[i].selectedCard = true;
         this.dragableDataList = [];
         this.dragableDataList.push(this.orgResources[i]);
       }
      //  else{
      //    this.resources[i].selectedCard = false;
      //  }
     }
   }
   else{
    this.dragableDataList = _.cloneDeep(this.orgResources);
    if(this.dragableDataList2 && this.dragableDataList2.length>=1){
      var index = _.findIndex(this.dragableDataList, (row:any) => { //check duplicates
        return row.id == this.dragableDataList2[0].id;
      });
      if(index > -1) {
        this.dragableDataList.splice(index,1);
      }
    }
   }
 }

 filterResults2(){
   ////debugger
  if(this.search2 == this.search1){
    this.search1 = null;
    this.dragableDataList = _.cloneDeep(this.orgResources);
    var index = _.findIndex(this.dragableDataList, (row:any) => { //check duplicates
      return row.id == this.search2;
    });
    if(index > -1) {
      this.dragableDataList.splice(index,1);
    }
  }
  if(this.search2){
     for(var i=0;i<=this.orgResources.length-1;i++){
      if(this.orgResources[i].id == this.search2){
        if(this.dragableDataList2 && this.dragableDataList2.length>=1){
          var index = _.findIndex(this.dragableDataList, (row:any) => { //check duplicates
            return row.id == this.dragableDataList2[0].id;
          });
          if(index <= -1) {
            this.dragableDataList.push(this.dragableDataList2[0]);
          }
        }
        var index1 = _.findIndex(this.dragableDataList, (row:any) => {
          return row.id == this.search2;
        });
        if(index1 > -1){
          this.dragableDataList.splice(index1,1);
        }
        this.dragableDataList2 = [];
        this.dragableDataList2.push(this.orgResources[i]);
        return;
      }
    }
  }
  else{
    this.dragableDataList2 = [];
    if(!this.search1){
      this.dragableDataList = _.cloneDeep(this.orgResources);
    }
    else{
      for(var i=0;i<=this.orgResources.length-1;i++){
        if(this.orgResources[i].id == this.search1){
          this.dragableDataList = [];
          this.dragableDataList.push(this.orgResources[i]);
        }
      }
    }
  }
 }

 headerClick(list :any,ind :any){
   //debugger
  this.search2 = list.vehicleData.vehicle.id;
  if(this.dragableDataList2 && this.dragableDataList2.length>=1){
    var index = _.findIndex(this.dragableDataList, (row:any) => { //check duplicates
      return row.id == this.dragableDataList2[0].id;
    });
    if(index <= -1) {
      this.dragableDataList.push(this.dragableDataList2[0]);
    }
  }
  this.dragableDataList2 = [];
  this.dragableDataList2.push(list);
  this.dragableDataList.splice(ind,1);
 }


 save(){
  //debugger
  this.spinner.show();
  console.log(this.formData)
  
  // this.planningHubService.swithOrderDiffVeh(this.formData).subscribe((res:any)=>{
  //   this.spinner.hide();
  //   if (res.status === 200 || res.status === 201) {
  //     // this.modalRef.hide();
  //     this.loadData2();
  //     this.toaster.success('', 'Changes updated successfully');
  //   }
  //   else{
  //     this.toaster.error('', res.error.title);
  //     this.loadData();
  //   }
  // })
 }

 log(event :any) {
  //debugger
  console.log(this.modalRef)
  console.log("eventtttttttttttt ..................... ", event);
  console.log(this.dragableDataList2)
    this.formData = {
      "planId": '',
      "vehicleId": '',
      "deliveryOrderId": '',
      "indexTripId": null,
      "subIndexTripId": null, //no need to send this value 
      "deliveryOrderNumber": null,
      "vehicleNumber": null, //no need to send this value 
      "orderName": event.data.orderName //no need to send this value 
    }
    this.formData.planId = this.data.draftPlanId;
    // this.formData.vehicleId = event.data.vehicleData.vehicle.id;
    this.formData.vehicleId = this.dragableDataList2[0].vehicleData.vehicle.id;
    this.formData.vehicleNumber = this.dragableDataList2[0].vehicleData.vehicle.vehicleNumber;
    this.formData.deliveryOrderId = event.data.orderId;
    this.formData.deliveryOrderNumber = event.data.orderNo;
    console.log(this.formData)
    var vehicleIndex = _.findIndex(this.data.routes, (row:any) => {
      return row.vehicleData.vehicle.id == this.formData.vehicleId;
    });
    var tripPlacedIndex = _.findIndex(this.dragableDataList2[0].relatedVehicleEvents, (row:any) => { 
      console.log(row.orderId," == ",this.formData.deliveryOrderId," && ",row.deliveryOrderWay," == ",event.data.deliveryOrderWay)
      return row.orderId == this.formData.deliveryOrderId && row.deliveryOrderWay == event.data.deliveryOrderWay;
    });
    console.log(tripPlacedIndex)
    
    console.log(this.dragableDataList2[0].relatedVehicleEvents[tripPlacedIndex-1])
    //debugger
    if(tripPlacedIndex == 0){//index=0 very first departure trip id have to send
      if(vehicleIndex > -1) {
        if(this.data.routes[vehicleIndex].trips[0].type == "departure"){
          this.formData.indexTripId = this.data.routes[vehicleIndex].trips[0].id;
          
        }
      }
    }
    else if(tripPlacedIndex == this.dragableDataList2[0].relatedVehicleEvents.length){ //index=last very last arrival trip id have to send
      if(vehicleIndex > -1) {
        var tripLength = this.data.routes[vehicleIndex].trips.length-1;
        if(this.data.routes[vehicleIndex].trips[tripLength].type == "arrival"){
          this.formData.indexTripId = this.data.routes[vehicleIndex].trips[tripLength].id;
        
        }
      }
    }
    else{
      this.formData.indexTripId = this.dragableDataList2[0].relatedVehicleEvents[tripPlacedIndex-1].tripId;
    }
    
    console.log(this.dragableDataList2)
    this.openModal('switchConfirm',this.formData)
   
}

openModal(modalView :any,data :any) {
  this.modalRef.hide()
 if(modalView == 'switchConfirm'){
    this.modalState = {
      title: 'Switch Order between different vehicle',
      mode: 'confirm',
      data : this.formData
    };
    this.selectedComponent = SwitchOrderDiffVehicleConfirmationComponent
    this.selectedConfig = this.configSM
  }else if (modalView == "modifyPlan") {
    this.modalState = {
      title: "Modify Plan",
      mode: "add",
      data : data
    };
    this.selectedConfig = this.configCustomLg;
    this.selectedComponent = ModifyPlanComponent;
  }
  this.selectedConfig.initialState = this.modalState;
  
  this.modalRef1 = this.modalService.show( this.selectedComponent, this.selectedConfig);    
  // this.modalRef = this.modalService.show(ModifyOptionComponent, this.config);
}


onDragged(index: number, item:any, list:any[], effect:DropEffect ) {
  const removeIndex = list.indexOf(item)
  console.log(`onDragged ngFor-index=${index}, item=${item}, removeIndex=${removeIndex}, list=${list.length}`)
  list.splice( removeIndex, 1 );
  
}


onDrop( event:DndDropEvent, list:any[] ) {
  console.log("onDrop", event, list.length);
  if( list && (event.dropEffect === "copy" || event.dropEffect === "move") ) {
     
    let index = event.index;

    if( typeof index === "undefined" ) {

      index = list.length;
    }
    console.log(index,"====",list,"=====",event.data)
    list.splice( index, 0, event.data );
    this.log(event)
   
  }
}


}

