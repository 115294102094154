<div class="d-flex">
  <div class="ms-2" style="width: 110px;">
     <p-dropdown [options]="allSearchFilters" [showClear]="false" optionLabel="label" optionValue="filter"
          placeholder="{{ 'search_by' | translate }}" [(ngModel)]="currentSelectOption" (onChange)="onSearchOptionChange()" id="picklist_HelpSearchField">
    </p-dropdown>
  </div>
   

    <div class="searchBox-container">
      <div class="p-inputgroup search ms-2">
          <span class="p-input-icon-right ">
            <input pInputText class="w-100" autocomplete="off" type="text" (keyup.enter)="onKeyDownEnter()" #helpDeskInput id="input_HelpSearchField" [placeholder]="'search' | translate" />
              <!-- <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_AssetListSearchClear" (click)="onClear()"></i> -->
          </span>   
          <button type="button" pButton icon="fa-regular fa-magnifying-glass"  [disabled]="helpDeskInput.value === ''" (click)="onClickSearchResult(helpDeskInput)" id="btn_HelpSearch"></button>
      </div>
    </div>

    <div class="searchbox-outer" *ngIf="showSearchBox">
      <ng-container *ngIf="noResultsAtAll !== true; else showNoResults">
        <div class="top-search">
          <div class="search-box-container">
            <div class="root-wrapper pb-1" *ngFor="
                let result of searchResults;
                let last = last;
                let first = first
              ">
              <div class="search-wrapper ps-3 pt-3 pe-3 pb-3" *ngIf="result.results.length !== 0">
                <div class="header ps-3 d-flex" id="value_HelpSearchResult">
                  <span>
                    {{ result.parent | translate | uppercase }}
                  </span>

                  <span class="ms-auto me-3" id="value_HelpSearchResultHeader">
                    {{ result.metaHeader | translate | uppercase }}
                  </span>
                </div>

                <div class="inner-wrapper" *ngFor="let r of result.results">
                  <div class="box mt-1 mb-1 cursor-pointer" (click)="onClickResult(r)" id="btn_HelpResult">
                    <div class="data d-flex align-items-center">
                      <span class="item flex-fill elips" style="width: 100px; padding-right: 25px;" id="label_HelpSearchResultItems" [innerHTML]="
                          r.item | highlightText: searchText:'partial'
                        "></span>
                      <span class="meta" id="label_HelpSearchResultHeader" [innerHTML]="
                          r.meta | highlightText: searchText:'partial'
                        "></span>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="result.results.length !== 0">
                <div class="line" *ngIf="!last && onlyOneOfResult === false"></div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="bottom-search" *ngIf="hideSeeAll === false && seeAllHasBeenClicked === false">
          <div class="option-all">
            <div class="form-check d-flex align-items-center" style="height: 2.5rem;">
              <label class="form-check-label mauto" for="exampleCheck1" id="link_HelpSeeAllResults" (click)="seeAllResults($event)">
                <span class="cursor-pointer custella-content-row-input-title-three-link" id="label_HelpSeeAllResults">{{ "see_all_results" |
                  translate }}</span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #showNoResults>
        <span class="no-results" id="label_HelpNoResults">{{ "no_results_found" | translate }}</span>
      </ng-template>
    </div>
</div>