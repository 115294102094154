import { Component, OnInit } from '@angular/core';
import { BsModalRef } from "ngx-bootstrap/modal";
import { SettingService } from 'src/app/_services/admin/setting.service';
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-modify-plan',
  templateUrl: './modify-plan.component.html'
})
export class ModifyPlanComponent implements OnInit {
  title:any;
  data: any;
  options: any;

  //post dispatch
  optionName1=[{
    'id':'1',
    'image':'assets/svg/table/briefcase.svg',
   'name':'Add Order',
    'description':'Add additional orders to this plan',
    'subOption':[{
      'id':'1',
      'name':'To specific vehicle',
      'description':'Know which vehicle you want to assign to?',
    },
   
  ]
  },
  {
    'id':'2',
    'image':'assets/images/planning-hub/Remove Grey.png',
    'name':'Remove,Cancel and Return Order',
    'description':'Remove orders or vehicles from this plan',
    'subOption':[{
      'id':'1',
      'name':'Remove order from plan',
      'description':'Want to remove orders from this plan?',
    },
    {
      'id':'2',
      'name':'Remove vehicle from plan',
      'description':'Want to remove a vehicle from this plan?',
    },
    {
      'id':'3',
      'name':'Cancel Single Order',
      'description':'Want to cancel an order in this plan?',
    },
    {
      'id':'4',
      'name':'Return Single Order',
      'description':'Want to return an order in this plan?	',
    }]
  },
  {
    'id':'3',
    'image':'assets/images/planning-hub/Switch Grey.png',
    'name':'Switch Order',
    'description':'Move orders around to suit your preferences',
    'subOption':[{
      'id':'1',
      'name':'Between same vehicle',
      'description':'Need to change the sequence of orders in a vehicle?',
    },
    {
      'id':'2',
      'name':'Between different vehicles',
      'description':'Want to move orders from one vehicle to another?',
    }]
  },
  {
    'id':'4',
    'image':'assets/images/planning-hub/Change Grey.png',
    'name':'Change Vehicle',
    'description':'Change vehicle or driver for this plan',
    'subOption':[
      {
        'id':'1',
        'name':'To switch vehicle',
        'description':'Need your driver to use a different vehicle?',
      }]
  },
  {
    'id':'5',
    'image':'assets/images/planning-hub/Breakdown Grey.png',
    'name':'Breakdown',
    'description':'Actions to take when a vehicle breaks down',
    'subOption':[{
      'id':'1',
      'name':'Return All Orders To Depot',
      'description':'Need to send all orders in a vehicle back to the depot?',
    }]
  }]

  //pre dispatch
  optionName=[{
    'id':'1',
    'image':'assets/svg/table/briefcase.svg',
    'name':'Add Order',
    'description':'Add additional orders to this plan',
    'subOption':[{
      'id':'1',
      'name':'To specific vehicle',
      'description':'Know which vehicle you want to assign to?',
    },
    {
      'id':'2',
      'name':'To any current plan vehicle',
      'description':'Want FieldEx to decide which vehicle to add your order to?',
    }]
  },
  {
    'id':'2',
    'image':'assets/svg/table/pi-trash.svg',
    'name':'Remove Order',
    'description':'Remove orders or vehicles from this plan',
    'subOption':[{
      'id':'1',
      'name':'Remove order from plan',
      'description':'Want to remove orders from this plan?',
    },
    {
      'id':'2',
      'name':'Remove vehicle from plan',
      'description':'Want to remove a vehicle from this plan?',
    }]
  },
  {
    'id':'3',
    'image':'assets/images/planning-hub/Switch Grey.png',
    'name':'Switch Order',
    'description':'Move orders around to suit your preferences',
    'subOption':[{
      'id':'1',
      'name':'Between same vehicle',
      'description':'Need to change the sequence of orders in a vehicle?',
    },
    {
      'id':'2',
      'name':'Between different vehicles',
      'description':'Want to move orders from one vehicle to another?',
    }]
  },
  {
    'id':'4',
    'image':'assets/images/planning-hub/Change Grey.png',
    'name':'Change Vehicle',
    'description':'Change vehicle or driver for this plan',
    'subOption':[
    {
      'id':'1',
      'name':'To switch vehicle',
      'description':'Need your driver to use a different vehicle?',
    }]
  },
  {
    'id':'5',
    'image':'assets/images/planning-hub/Replan Grey.png',
    'name':'Adjust/Replan Vehicle',
    'description':'Make adjustments and replan',
    'subOption':[{
      'id':'1',
      'name':'Replan',
      'description':'Made some changes and want FieldEx to reoptimize your plan?',
    }
   
  ]
  }]

  SelectedOption:any = {
    'id':'1',
    'image':'../../../../assets/images/4.3/PlanningHub/Add Orders White.png',
    'srcOut':'../../../../assets/images/4.3/PlanningHub/Add Orders Grey.png',
    'srcOn':'../../../../assets/images/4.3/PlanningHub/Add Orders White.png',
    'name':'Add Order',
    'description':'Add additional orders to this plan',
    'subOption':[{
      'id':'1',
      'name':'To specific vehicle',
      'description':'Know which vehicle you want to assign to?',
    },
    {
      'id':'2',
      'name':'To any current plan vehicle',
      'description':'Want FieldEx to decide which vehicle to add your order to?',
    }]
  }

  SelectedOption1:any = {
    'id':'1',
    'image':'../../../../assets/images/4.3/PlanningHub/Add Orders White.png',
    'srcOut':'../../../../assets/images/4.3/PlanningHub/Add Orders Grey.png',
    'srcOn':'../../../../assets/images/4.3/PlanningHub/Add Orders White.png',
    'name':'Add Order',
    'description':'Add additional orders to this plan',
    'subOption':[{
      'id':'1',
      'name':'To specific vehicle',
      'description':'Know which vehicle you want to assign to?',
    },
    
  ]
  }

  constructor(public modalRef: BsModalRef, private modal: ModalServiceService, private settingService: SettingService,
    private errorUtil: ErrorUtil,) { }

  ngOnInit(): void {
    
    console.log('modifyyyy data',this.data)
    if(this.data.planStatus=="DISPATCHED" || this.data.planStatus=="IN_PROGRESS"){
      this.options = this.optionName1;
      this.SelectedOption = this.SelectedOption1;
    }
    else{
      this.options = this.optionName;
    }
  }

  chooseOption(data: any){
    this.SelectedOption = data
   
  }
  selectClick(SelectedOption :any,subOption: any){
    let fleetConfigSetup: any = localStorage.getItem('fleetConfigSetup');
    fleetConfigSetup = fleetConfigSetup ? JSON.parse(fleetConfigSetup): {};
    if(SelectedOption.name == 'Add Order' && subOption.name.toLowerCase().includes('specific')) {
      if (fleetConfigSetup && fleetConfigSetup.noOfStopsMax) {
        if(this.modalRef.content.data?.assignedOrders?.length <= fleetConfigSetup.noOfStopsMax)
        this.clickTrigger(SelectedOption, subOption, fleetConfigSetup.noOfStopsMax, fleetConfigSetup.noOfVehicleMax);
        else {
          if(fleetConfigSetup.noOfStopsMax == 1000){
            this.errorUtil.setErrorMessage(400, null , 'Each plan can only take a maximum of 1000 delivery orders', 'warn',3000);
          }else{
            this.errorUtil.setErrorMessage(400, null , 'You can only add up to '+fleetConfigSetup.noOfStopsMax+' delivery orders per plan. If you require a bigger plan, please consider upgrading to a higher tier', 'warn',3000);
          }
        }
      } else
        this.settingService.getFleetConfig().subscribe((res: any) => {
          fleetConfigSetup = res?.body;

          if (fleetConfigSetup && fleetConfigSetup.noOfStopsMax) {
            if(this.modalRef.content.data?.assignedOrders?.length <= fleetConfigSetup.noOfStopsMax)
              this.clickTrigger(SelectedOption, subOption, fleetConfigSetup.noOfStopsMax, fleetConfigSetup.noOfVehicleMax);
            else {
              if(fleetConfigSetup.noOfStopsMax == 1000){
                this.errorUtil.setErrorMessage(400, null , 'Each plan can only take a maximum of 1000 delivery orders', 'warn',3000);
              }else{
                this.errorUtil.setErrorMessage(400, null , 'You can only add up to '+fleetConfigSetup.noOfStopsMax+' delivery orders per plan. If you require a bigger plan, please consider upgrading to a higher tier', 'warn',3000);
              }
            }
          } else this.errorUtil.setErrorMessage(400, null ,  'Contact admin', 'error',3000);
        })
    } else this.clickTrigger(SelectedOption, subOption, fleetConfigSetup.noOfStopsMax, fleetConfigSetup.noOfVehicleMax);
  }

  private clickTrigger(SelectedOption :any,subOption: any, noOfStopsMax:number, noOfVehicleMax: number){
    this.modalRef.hide();
    console.log(SelectedOption,"---",SelectedOption.name.toLowerCase().includes('remove')," && ",subOption.name.toLowerCase(),"===",subOption.name.toLowerCase().includes('remove order'))
    if(SelectedOption.name == 'Add Order' && subOption.name.toLowerCase().includes('specific')){
      this.modal.openModal('addOrderSpecificVehicle', {...this.data, fleetConfigSetup: {noOfStopsMax: noOfStopsMax, noOfVehicleMax: noOfVehicleMax}});
    }
    else if(SelectedOption.name == 'Add Order' && subOption.name.toLowerCase().includes('current')){
      this.modal.openModal('addOrderCurrentVehicle', this.data);
    }
    else if((SelectedOption.name == 'Remove Order'|| SelectedOption.name.toLowerCase().includes('remove')) && subOption.name.toLowerCase().includes('remove order')){
      this.modal.openModal('removeOrder', this.data);
    }
    else if((SelectedOption.name == 'Remove Order'|| SelectedOption.name.toLowerCase().includes('remove')) && subOption.name.toLowerCase().includes('remove vehicle')){
      this.modal.openModal('removeVehicle', this.data);
    }
    else if((SelectedOption.name == 'Remove Order'|| SelectedOption.name.toLowerCase().includes('remove')) && subOption.name.toLowerCase().includes('cancel')){
      this.modal.openModal('cancelOrder', this.data);
    }
    else if((SelectedOption.name == 'Remove Order'|| SelectedOption.name.toLowerCase().includes('remove')) && subOption.name.toLowerCase().includes('return')){
      this.modal.openModal('returnOrder', this.data);
    }
    else if(SelectedOption.name == 'Switch Order' && subOption.name.toLowerCase().includes('same')){
      this.modal.openModal('switchOrderSameVehicle', this.data);
    }
    else if(SelectedOption.name == 'Switch Order' && subOption.name.toLowerCase().includes('different')){
      this.modal.openModal('switchOrderDiffVehicle', this.data);
    }
    else if(SelectedOption.name.toLowerCase().includes('change vehicle') && subOption.name.toLowerCase().includes('vehicle')){
      this.modal.openModal('changeVehicle', this.data);
    }
    else if(SelectedOption.name == 'Adjust/Replan Vehicle' && subOption.name.toLowerCase().includes('replan')){
      this.modal.openModal('adjustReplan', this.data);
    }
    else if(SelectedOption.name == 'Adjust/Replan Vehicle' && subOption.name=='Add New Vehicle'){
      this.modal.openModal('adjustNewVehicle', this.data);
    }
    else if(SelectedOption.name == 'Breakdown' && subOption.name.toLowerCase().includes('order')){
      this.modal.openModal('breakdown', this.data);
    }
  }

  
  cancelOrder(){
    this.modal.openModal("cancelSingleOrder", null);
  }
  
  returnOrder(){
    this.modal.openModal("returnSingleOrder", null);
  }
  
  switchOrderSame(){
    this.modal.openModal("switchOrderSameVehicle", null);
  }

  switchOrderDiff(){
    this.modal.openModal("switchOrderDiffVehicle", null);
  }

  removeOrder(){
    this.modal.openModal("modifyRemoveOrder", null);
  }
 
  switchVehicleDriver(){
    this.modal.openModal("modifySwitch", null);
  }
 
  breakdown(){
    this.modal.openModal("modifyBreakdown", null);
  }
}
