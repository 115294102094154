<div class="h-100-70 mt-2">
  <!-- Title -->
  <div class="flex-between mt-1 mx-2 mb-2">
    <div class="text-20 font-semiBold" id="title_TaskStaffSchedule">
      {{mode == 'all' ? 'All':mode == 'today' ? "Today's":mode == 'upcoming' ? 'Upcoming':'Completed'}} Tasks
    </div>
    <!-- Filter and New -->
    <div class="custella-dashboard-top-right d-flex ms-auto">
      <button *ngIf="toggle" class="btn-list-icon ms-2" pTooltip="Table View" tooltipPosition="bottom"
        id="btn_TaskStaffScheTableView" (click)="openMapLocation()">
        <i class="fa-regular fa-table"></i>
      </button>

      <button *ngIf="!toggle" class="btn-list-icon ms-2" pTooltip="Calendar View" tooltipPosition="bottom"
        (click)="openMapLocation()">
        <i class="fa-regular fa-calendar-lines"></i>
      </button>
      <button *ngIf="toggle" class="btn-list-icon ms-2" pTooltip="Map View" tooltipPosition="bottom"
        (click)="showDirections()">
        <i class="fa-regular fa-map-location-dot"></i>
      </button>
      <button type="button" *ngIf="toggle" class="btn-list-icon ms-2" [pTooltip]="'refresh'|translate"
        tooltipPosition="bottom" (click)="getFilter()" id="btn_TaskStaffScheRefresh"> <i
          class="fa-regular fa-arrows-rotate"></i></button>
      <button type="button" class="btn-list-icon ms-2" [pTooltip]="'filter'|translate" tooltipPosition="bottom"
        id="btn_TaskStaffScheFilter" (click)="onFilteruttonClick($event)"> <i class="fa-regular fa-filter-list"></i>
      </button>

      <span class="p-input-icon-right w-100 ms-2">
        <i class="pi pi-calendar" style="z-index: 1;"></i>
        <p-calendar type="text" class="w-100" selectionMode="single" id="picklist_TaskStaffScheDateField"
          dateFormat="dd/mm/yy" [readonlyInput]="true" name="completion" (onSelect)="onCompletionDateChange($event)"
          [(ngModel)]="taskDateRange" placeholder="dd/mm/yyyy" [yearNavigator]="true" yearRange="2000:2030"
          appendTo="body"></p-calendar>
      </span>
      <p-dropdown class="ms-2" (onChange)="getFilter()" id="picklist_TaskStaffScheSelStatusField"
        [(ngModel)]="filterByView" name="status" [options]="filterDay" optionLabel="label"
        optionValue="value"></p-dropdown>

      <p-button [label]="'new'|translate" icon="fa-regular fa-plus" id="btn_TaskStaffScheNew"
        (click)="openModal('addEditTask', '')" styleClass="ms-2 h-32" *ngIf="createTask"></p-button>


    </div>
  </div>
  <!-- TabMenu -->
  <div class=" d-flex" style="height: calc(100% - 30px);">
    <div class="position-relative fw-normal display-table d-flex ag-grid-table-full h-100"
      [ngClass]="{' ag-grid-table': filterClick}" style="border: 1px #EBEBEB solid;">
      <div *ngIf="toggle" class="w-100">
        <bryntum-scheduler #scheduler [resources]="resources" [columns]="schedulerConfig.columns" [events]="events"
          [startDate]="schedulerConfig.startDate!" [endDate]="schedulerConfig.endDate!" [viewPreset]="viewPreset"
          [rowHeight]="schedulerConfig.rowHeight" [barMargin]="schedulerConfig.barMargin"
          [resourceTimeRangesFeature]="schedulerConfig.features.resourceTimeRanges"
          [eventTooltipFeature]="schedulerConfig.features.eventTooltip" [readOnly]=true
          (onCatchAll)="onSchedulerEvents($event)"></bryntum-scheduler>
      </div>
      <div *ngIf="!toggle" class="w-100">
        <app-all-staff-task [mode]="mode" [onlineUsers]="filter.onlineUsers" [typeOfUsers]="filter.typeOfUsers"
          [status]="filter.status" [taskDateRange]="taskDateRange"></app-all-staff-task>
      </div>
    </div>


    <div class="custella-form-container-content-pane layout-filter-task" [ngClass]="{'layout-filter-active': filterActive}">
      <div class="custella-form-container-content-pane-title">
        <span class="text-14 font-medium" id="title_TaskStaffScheFilterBy">Filter by</span>
        <i class="pi pi-arrow-right" id="btn_TaskStaffScheFilterClose" (click)="onFilteruttonClick($event)"></i>
      </div>
      <div class="custella-content">


        <div class="custella-content-row-input col-12 mb-2">
          <span class="custella-content-row-input-title" id="label_TaskStaffScheUserStatus">User Status</span>
          <p-dropdown class="me-2" (onChange)="getFilter()" id="picklist_TaskStaffScheUserStatusField"
            [(ngModel)]="filter.onlineUsers" name="status" [options]="onlineUsersList" optionLabel="label"
            optionValue="value" placeholder="Select Status"></p-dropdown>
        </div>

        <div class="custella-content-row-input col-12 mb-2">
          <span class="custella-content-row-input-title" id="label_TaskStaffScheStaff">Staff</span>
          <p-dropdown class="me-2" (onChange)="getFilter()" id="picklist_TaskStaffScheStaffField"
            [(ngModel)]="filter.typeOfUsers" name="status" [options]="typeOfUsersList" optionLabel="label"
            optionValue="value" placeholder="Select Status"></p-dropdown>
        </div>

        <div class="custella-content-row-input col-12 mb-2">
          <span class="custella-content-row-input-title" id="label_TaskStaffScheStatus"> Status</span>
          <p-dropdown class="pe-2" id="picklist_TaskStaffScheStatusField" (onChange)="getFilter()"
            [(ngModel)]="filter.status" name="status" [options]="taskStatus" optionLabel="label" filterBy="label"
            optionValue="value" placeholder="Select Status" [showClear]="filter.status ? true : false"
            [filter]="true"></p-dropdown>
        </div>

        <div>
          <a class="text-decoration-underline font-black" (click)="resetSearch()"><small
              id="subtitle_TaskStaffScheClear" class="d-flex align-items-center text-nowrap text-10">Clear
              All</small></a>
        </div>
      </div>
    </div>
  </div>
</div>