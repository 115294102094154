<div class="left-right-space h-100-70 my-3">
    <div class="flex-between my-3">
        <!-- Filter and New -->
        <div class="d-flex align-items-center">
            <button class="custella-btn-white text-12 me-3 pb-1 h-35" id="btn_TaskOptBack" (click)="onClickBack()"><i class="pi pi-angle-left"></i></button>
            <span class="text-20 font-semiBold" id="title_TaskOptimize">Optimize your Tasks</span>
        </div>
        <div class="custella-dashboard-top-right d-flex align-items-center">
            <p-button label="Today" (onClick)="goToToday()" id="btn_TaskOptToday" styleClass="custella-btn-icon ms-1 me-3 text-nowrap h-35"></p-button>
            
            <i class="pi pi-angle-left me-1"  (click)="prev()" id="btn_TaskOptPrevious"></i>
            <p class="mb-0" id="label_TaskOptDate">{{currentDay.format('ddd DD MMM YYYY') | uppercase}} </p>
            <i class="pi pi-angle-right ms-1" id="btn_TaskOptNext" (click)="next()"></i>
            
            <div class="dropdown ms-3">
                <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                    <button class="custella-btn-icon rounded-1 px-2 py-2" id="btn_TaskOptDropdown" style="border: 1px solid #d6d6d6;"> <i class="pi pi-ellipsis-v pt-04"></i></button>
                </div>
                <ul class="dropdown-menu dropdown-collaps p-0">
                    <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_TaskOptTime" (click)="onChange('Time')" translate>{{'time'}}</li>
                    <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_TaskOptDistance" (click)="onChange('Distance')" translate>{{'distance'}}</li>
                    <li class="dropdown-item px-3 py-3 cursor-pointer" id="btn_TaskOptPriorityDistance" (click)="onChange('priorityNDistance')" translate>{{'priority_and_distance'}}</li>
                </ul>
            </div>
            <button type="button" class="custella-btn-icon border rounded-1 p-2 ms-2" id="btn_TaskOptRefresh" *ngIf="changesMade" (click)="onChange('Time')">
                <i class="pi pi-refresh"></i>
            </button>
        </div>
    </div>

   
    <div class="col-12" style="height: 74vh; overflow: scroll;">
        <ng-fullcalendar #ucCalendar (viewRender)="viewRender($event)" [options]="calendarOptionsAgendaDay" (eventClick)="eventClick($event)"  (eventDrop)="eventDrop($event)" (eventResize)="eventResize($event)"  (eventRender)="eventRender($event.detail.event, $event.detail.element)"></ng-fullcalendar>
    </div>
    <div class="col-12">

        <div class="custella-form-container-footer mb-0">
            <div class="d-flex align-items-center justify-content-end">
                <button class="custella-btn-white text-12 mx-2" id="btn_TaskOptViewMap" (click)="viewMap()">{{'view_map' | translate }}</button>
                <p-button label="Save Plan" id="btn_TaskOptSavePlan" (onClick)="saveTasksButton()"></p-button>
            </div>
        </div>

     </div>
</div>