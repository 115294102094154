import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  ErrorHandler,
  EventEmitter,
  HostListener,
  OnInit,
  QueryList,
} from "@angular/core";
import { BsModalRef, ModalOptions } from "ngx-bootstrap/modal";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { ImportExportComponent } from "../import-export.component";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import * as _ from "lodash";
import { UntypedFormControl, UntypedFormGroup, NgModel, Validators } from "@angular/forms";
import { ImportExportService } from "src/app/_services/import-export.service";
import * as moment from "moment";
import * as FileSaver from "file-saver";
import { ErrorUtil } from "src/app/_utilities/error";

export class ModuleField {
  public csvHeaderName: any;
  public csvHeaderIndex: any;
  public csvSampleValue: any;
  public moduleFieldName: any;
  public dataType: any;
}

@Component({
  selector: "app-import-data",
  templateUrl: "./import-data.component.html",
})
export class ImportDataComponent implements OnInit {
  buttonLabel: string = "Next";

  first: boolean = true;
  second: boolean = false;
  third: boolean = false;
  fourth: boolean = false;
  validFile: boolean = false;
  keysByModules: any;
  selectedKey: any;
  isUpdate: any;

  data: any;

  uploadCSVForm: UntypedFormGroup;
  moduleList: any = [];
  selectedModule: any = { key: null, value: null };
  type: any;
  moduleFieldList: any = [];
  selectedModuleFieldList: any = Array();
  selectedFileName: string = "";
  files: FileList | any;

  public modalRefRes: BsModalRef | any;
  salePer: boolean = false;
  servicePer: boolean = false;
  fleetPer: boolean = false;
  actions = [
    { name: "create", label: "Create" },
    { name: "update", label: "Update" },
  ];

  appendData: any = { type: "", selectedModule: null, moduleFieldList: null };

  constructor(
    public modalRef: BsModalRef,
    private util: UtilServiceService,
    private modal: ModalServiceService,
    private dataExportService: ImportExportService,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
    private cd: ChangeDetectorRef,
    public options: ModalOptions,
    private errorUtil: ErrorUtil,
    private el: ElementRef
  ) {
    this.uploadCSVForm = new UntypedFormGroup({
      selectedModule: new UntypedFormControl(null, [Validators.required]),
      action: new UntypedFormControl(null, [Validators.required]),
    });

    let int: any = this.options.initialState;
    if (int.data) {
      int = int.data;
      this.uploadCSVForm.controls["selectedModule"].setValue(
        int.selectedModule.key
      );
      this.uploadCSVForm.controls["action"].setValue(int.type);
      this.selectedModule.key = int.selectedModule.key;
      this.type = int.type;
      this.keysByModules = int.keysByModules;
      this.isUpdate = int.isUpdate;
      this.appendData = int;
    }
  }

  ngOnInit(): void {
    let a: any = localStorage.getItem("addonFeatureList");
    var addonFeatureLists = JSON.parse(a);

    var profileInfo = JSON.parse(this.auth.getProfileInfo());
    this.findMenuPermissions(profileInfo.tenantCategories);
    this.dataExportService.getModuleList().subscribe((res: any) => {
      this.moduleList = res;
      var orgModuleList = _.cloneDeep(res);

      var index = this.moduleList.findIndex(
        (list: { value: string }) => list.value === "HP Form"
      );
      if (index > -1) {
        this.moduleList.splice(index, 1);
      }
      var index1 = this.moduleList.findIndex(
        (list: { value: string }) => list.value === "Part Order"
      );
      if (index1 > -1) {
        this.moduleList.splice(index1, 1);
      }

      if (!this.salePer && !this.servicePer && this.fleetPer) {
        var index2 = orgModuleList.findIndex(
          (list: { value: string }) => list.value === "Customer"
        );
        var index3 = orgModuleList.findIndex(
          (list: { value: string }) => list.value === "Contact"
        );
        this.moduleList = [];
        if (index2 >= 1) {
          this.moduleList.push(orgModuleList[index2]);
        }
        if (index3 >= 1) {
          this.moduleList.push(orgModuleList[index3]);
        }
      }
    });
  }

  findMenuPermissions(tenantCategories: any) {
    var saleIndex = tenantCategories.indexOf("SALES");
    if (saleIndex != -1) {
      this.salePer = true;
    }
    var serviceIndex = tenantCategories.indexOf("SERVICE");
    if (serviceIndex != -1) {
      this.servicePer = true;
    }
    var fleetIndex = tenantCategories.indexOf("FLEET");
    if (fleetIndex != -1) {
      this.fleetPer = true;
    }
  }

  actionChange() {
    this.appendData = {};
    this.isUpdate = this.type == "update" ? true : false;
    if (this.type == "update") {
      this.dataExportService
        .getKeysByModule(this.selectedModule.key)
        .subscribe((res: any) => {
          this.keysByModules = res;
        });
    }
  }

  moduleSelected() {
    if (this.selectedModule != null && this.selectedModule.key != null) {
      this.first = false;
      this.second = true;
    } else {
      this.errorUtil.setErrorMessage(
        "Error",
        null,
        "Please select a module to import",
        "error",
        3000
      );
    }
  }

  onModuleChange(e: any) {
    if (e.value) {
      this.files = null;
      this.selectedFileName = "";
      this.appendData = {};
      if (this.selectedModule != null) {
        this.spinner.show();
        this.dataExportService
          .getModuleFieldList(this.selectedModule.key, false)
          .subscribe((res: any) => {
            this.moduleFieldList = res;
            this.spinner.hide();
          });
      }
    }
    this.actionChange();
  }

  next() {
    for (var i in this.uploadCSVForm.controls) {
      this.uploadCSVForm.controls[i].markAsTouched();
      if (this.uploadCSVForm.controls[i].invalid) {
        // console.log('res')
      }
    }
    if (this.uploadCSVForm.valid) {
      this.appendData.type = this.type;
      this.appendData.selectedModule = this.selectedModule;
      this.appendData.moduleFieldList = this.moduleFieldList;
      this.appendData.keysByModules = this.keysByModules;
      this.appendData.moduleList = this.moduleList;
      this.appendData.isUpdate = this.isUpdate;

      this.modalRefRes = this.modal.openModal("importUpload", this.appendData);
      this.modalRef.hide();
      this.modalRefRes.content.action.subscribe((res: any) => {
        console.log(res);
        // this.itemList.push(res.data);
        this.cd.detectChanges();
        // this.modal.openModal('listImports', null)
      });
    }
  }
}
