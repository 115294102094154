import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { PmvService } from 'src/app/_services/pmv.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.scss']
})
export class UpdateStatusComponent implements OnInit {
  buttonLabel: string = "Save";
  data: any
  satatus: any = [
    { label: "New", value: "NEW" },
    { label: "Draft", value: "DRAFT" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Canceled", value: "CANCELED" },
  ]
  status: any = 'NEW'
  constructor(public modalRef: BsModalRef,
    private checklistService: ChecklistService,
    private errorUtil: ErrorUtil,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private pmvService: PmvService) { }

  ngOnInit(): void {
    console.log(this.data)
    if (this.data.mode === "edit") {
      this.status = this.data.status
    }
  }


  onSubmitData() {
    if (this.data.page == 'PMV') {
      this.savePMVData()
    } else {
      this.saveChecklistData()
    }

  }
  /******************************************* Save PMV Data *****************************************/
  savePMVData() {
    this.spinner.show()
    //delete addVisit.requestedToUser
    // any form stuff for edit
    if (this.data.mode === "add") {
      let checklistData: any = {

        tmplChecklist: this.data.checklistRecord.tmplChecklist,
        status: this.status,
        assets: this.data.asset,
        checklistQuestions: this.data.checklistQuestions

      }

      console.log(checklistData);
      //debugger
      this.spinner.show()
      this.pmvService.createPMV(checklistData).subscribe((res: any) => {
        console.log("res", res)

        if (res.status == 200 || res.status == 201) {


          this.modalRef.hide()
          if (!this.data.tmplChecklistData.displayAssetStatusAtEnd && !this.data.tmplChecklistData.displayEquipmentReadingAtEnd) {
            this.checklistService.refreshViewRoute(null);
            this.router.navigate(['dashboard/pmv/view'], { queryParams: { id: res.body.pmv.id } })
          } else {
            this.checklistService.refreshViewRoute(res.body);

          }
        } else {
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, "error", 3000);
        }
        this.spinner.hide()
      })


    }
    // any form stuff for edit
    if (this.data.mode === "edit") {
      let checklistData: any = {
        id: this.data.id,
        tmplChecklist: this.data.tmplChecklist,
        status: this.status,
        assets: this.data.asset,
        checklistQuestions: this.data.checklistQuestions,
        pmv: {
          id: this.data.id,
          status: this.status
        }

      }

      console.log(checklistData);

      this.pmvService.editPMV(checklistData).subscribe((res: any) => {
        console.log("res", res)
        if (res.status == 200 || res.status == 201) {

          this.router.navigate(['dashboard/pmv/view'], { queryParams: { id: this.data.id } })
          this.modalRef.hide()
        } else {
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, "error", 3000);
        }
        this.spinner.hide()
      })

    }

  }
  /******************************************* Save Checklist Data *****************************************/
  saveChecklistData() {

    //delete addVisit.requestedToUser
    // any form stuff for edit
    if (this.data.mode === "add") {
      let checklistData: any = {
        checklistRecord: {
          tmplChecklist: this.data.checklistRecord.tmplChecklist,
          status: this.status,
          assets: this.data.asset
        },
        checklistQuestions: this.data.checklistQuestions

      }

      console.log(checklistData);
      //debugger
      this.spinner.show()
      this.checklistService.createCheckListGlobal(checklistData).subscribe((res: any) => {
        console.log("res", res)

        if (res.status == 200 || res.status == 201) {


          this.modalRef.hide()
          if (!this.data.tmplChecklistData.displayAssetStatusAtEnd && !this.data.tmplChecklistData.displayEquipmentReadingAtEnd) {
            this.checklistService.refreshViewRoute(null);
            this.router.navigate(['dashboard/checklist/view'], { queryParams: { id: res.body.checklistRecord.id } })
          } else {
            this.checklistService.refreshViewRoute(res.body);

          }
        } else {
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, "error", 3000);
        }
        this.spinner.hide()
      })


    }
    // any form stuff for edit
    if (this.data.mode === "edit") {
      let checklistData: any = {
        checklistRecord: {
          id: this.data.id,
          tmplChecklist: this.data.tmplChecklist,
          status: this.status,
          assets: this.data.asset
        },
        checklistQuestions: this.data.checklistQuestions

      }

      console.log(checklistData);
      //debugger
      //  return;
      this.checklistService.editCheckListGlobal(checklistData).subscribe((res: any) => {
        console.log("res", res)
        if (res.status == 200 || res.status == 201) {
          this.modalRef.hide()
          if (this.data.tmplChecklistData.displayAssetStatusAtEnd && this.data.tmplChecklistData.displayEquipmentReadingAtEnd) {
            this.checklistService.refreshViewRoute({ isUpdated: true, ...res.body, assets: this.data.asset });
          } else {
            this.checklistService.refreshViewRoute(null);
            this.router.navigate(['dashboard/checklist/view'], { queryParams: { id: this.data.id } });
          }
        } else {
          this.errorUtil.setErrorMessage(res.status, null, res.error.title, "error", 3000);
        }
        this.spinner.hide()
      })

    }

  }

}
