import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fqc-iqc-main',
  template:`<router-outlet></router-outlet>`,
})
export class IqcFqcMainComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
