import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';


@Component({
  selector: 'app-task-clone',
  templateUrl: './task-clone.component.html'
})
export class TaskCloneComponent implements OnInit {
  buttonLabel : string = 'Save';
  data: any
  tasks: any;
  cloneTaskForm: UntypedFormGroup;
  cloneTask: any = {};

  startDate: any
  endEnd: any
  taskCloneAll : boolean = false;
  timezone: any
  constructor(
    public modalRef: BsModalRef, 
    private util: UtilServiceService,
    private spinner : NgxSpinnerService,
    private taskService: TasksService,
    private auth : AuthenticationService,
    private errorUtil: ErrorUtil,
    private router : Router) { 
      this.cloneTaskForm = new UntypedFormGroup({
        'dateFrom': new UntypedFormControl(null, [Validators.required]),
        'date': new UntypedFormControl(null, [Validators.required]),
      });
    }

  ngOnInit(): void {
    this.timezone = this.auth.getUserTimezone();
    console.log(this.data)
    if(this.data.fromDateTime != undefined){
     this.startDate = moment(this.data.fromDateTime).format("YYYY-MM-DD");
        this.endEnd  = moment(this.data.toDateTime).format("YYYY-MM-DD");
        this.cloneTask.dateFrom = new Date(this.data.toDateTime)
        this.loadTasks()
    }
  }

  loadTasks(){
    this.spinner.show();
    this.taskService.getAllMyTasks(this.auth.getUserId(),this.startDate,this.endEnd).subscribe(tasks => {
      this.tasks = tasks;
      this.spinner.hide();
    });
  }

  selectAllTasks(val: any){
    if(val){
      this.tasks.forEach((element : any)=> {
        element.checked = true;
      });
    } else {
      this.tasks.forEach((element: any) => {
        element.checked = false;
      });
    }
  }
  goBack(){
    this.util.goBack()
   }

   onPickDateChange(newDate: Date) {
    console.log(newDate)
    this.startDate = moment(newDate).format('YYYY-MM-DD');
    this.endEnd = moment(newDate).format('YYYY-MM-DD');
  this.loadTasks()

  }
  onSubmitForm(val: any) {
    if(!this.cloneTaskForm.valid){
      for (var i in this.cloneTaskForm.controls) {
        this.cloneTaskForm.controls[i].markAsTouched();
      }
      return false;
    }
    if(val === 'clone'){
      let tempArray = Object.assign([],this.tasks);
      let selectedArray: any[] = [];
      let cloneDate = moment(this.cloneTask.date).format('YYYY-MM-DD');
      tempArray.forEach((element : any)=> {
        if(element.checked){
          var startDateTime: any = moment(element.startDateTime)
          var endDateTime: any = moment(element.endDateTime)
          var convertedStartTime = moment.tz(startDateTime, "YYYY-MM-DDTHH:mm:ssZ", this.auth.getUserTimezone()).format('YYYY-MM-DDTHH:mm:ss');
          var convertedEndTime = moment.tz(endDateTime, "YYYY-MM-DDTHH:mm:ssZ", this.auth.getUserTimezone()).format('YYYY-MM-DDTHH:mm:ss');
          //let tempStartDateSplit = element.startDateTime.split('T');
          //let tempEndDateSplit = element.endDateTime.split('T');
          let tempStartDateSplit = convertedStartTime.split('T');
          let tempEndDateSplit = convertedEndTime.split('T');
          let newStartDate = cloneDate + 'T' + tempStartDateSplit[1];
          let newEndDate = cloneDate + 'T' + tempEndDateSplit[1];
          let finalStartDate = moment.utc(moment(newStartDate)).format();
          let finalEndDate = moment.utc(moment(newEndDate)).format();
          let tempObj = {
            taskId : element.id,
           // startDate : newStartDate,
           // endtDate : newEndDate
            startDate : finalStartDate,
            endtDate : finalEndDate,
          }
          selectedArray.push(tempObj);
        }
      });

      if(selectedArray.length){
        this.taskService.bulkCloneTasks(selectedArray).subscribe((res : any) => {
          //console.log("Response ", res);
          if(res.status === 200 || res.status === 201){
            this.modalRef.hide();
            this.errorUtil.setErrorMessage(200, 'Task(s) cloned successfully' ,  null, 'success',1000);
          }
        })
      } else {
        this.errorUtil.setErrorMessage(400, null , 'Please select at least one task to clone', 'error',3000);
         }
    } else if(val === 'move'){
      let tempArray = Object.assign([],this.tasks);
      let selectedArray : any[] = [];
      let cloneDate = moment(this.cloneTask.date).format('YYYY-MM-DD');
      tempArray.forEach((element : any)=> {
        if(element.checked){
          var startDateTime: any = moment(element.startDateTime)
          var endDateTime: any = moment(element.endDateTime)
          var convertedStartTime = moment.tz(startDateTime, "YYYY-MM-DDTHH:mm:ssZ", this.auth.getUserTimezone()).format('YYYY-MM-DDTHH:mm:ss');
          var convertedEndTime = moment.tz(endDateTime, "YYYY-MM-DDTHH:mm:ssZ", this.auth.getUserTimezone()).format('YYYY-MM-DDTHH:mm:ss');
          let tempStartDateSplit = convertedStartTime.split('T');
          let tempEndDateSplit = convertedEndTime.split('T');
          let newStartDate = cloneDate + 'T' + tempStartDateSplit[1];
          let newEndDate = cloneDate + 'T' + tempEndDateSplit[1];
          let finalStartDate = moment.utc(moment(newStartDate)).format();
          let finalEndDate = moment.utc(moment(newEndDate)).format();
          let tempObj = {
            id : element.id,
            startDateTime : finalStartDate,
            endDateTime : finalEndDate,
          }
          selectedArray.push(tempObj);
        }
      });

      if(selectedArray.length){
        var req : any = {};
        req.tasks = selectedArray;
        this.taskService.bulkEditTask(req).subscribe((res : any) => {
          //console.log("Response ", res);
          if(res.status === 200 || res.status === 201){
            this.modalRef.hide();
            this.errorUtil.setErrorMessage(200, 'Task(s) moved successfully' ,  null, 'success',1000);
          }
        })
      } else {
        this.errorUtil.setErrorMessage(400, null , 'Please select at least one task to move', 'error',3000);
      }
    }

  }
  viewTaskDetail(data: any){
    // this.router.navigate(["/dasdashboard/tasks/view"], { queryParams: {id: data.taskId}});
    this.util.openTaskVIewPage(data.taskId)
    this.modalRef.hide();
  }
}
