<div class="custella-modal">
    <form [formGroup]="startLocationForm">
        <div class="custella-modal-header align-items-center">
            <div class="custella-modal-header-text" id="title_TaskSelLocTimes" translate> {{'select_start_location_and_times'}} </div>
            <i class="pi pi-times" id="btn_TaskSelLocTimesClose"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>
        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
            <!-- Container Content -->
            <div class="custella-form-container-content m-0">
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12 me-0">
                        <span class="custella-content-row-input-title mandatory" id="label_TaskSelLoc" translate>{{ "location" }} </span>
                        <div class="d-flex w-100 col">
                            <div class="d-flex align-items-start col">
                                <div class="col">
                                    <input
                                    pInputText autocomplete="off"
                                    placeholder="Search for Location"
                                    autocorrect="off"
                                    autocapitalize="off"
                                    id="input_TaskSelLocField"
                                    spellcheck="off"
                                    type="text"
                                    (keyup)="getPlaceApiCall($event)" (change)="manualSearch()" formControlName="location" name="location"
                                    #input_TaskSelLocField [(ngModel)]="startLocation.location"
                                />
                                    <div class="p-error text-10" *ngIf=" startLocationForm.controls['location'].touched && startLocationForm.controls['location'].invalid " >
                                        <span *ngIf=" startLocationForm.controls['location'].errors?.required " id="label_TaskSelLocReqField" translate > {{ "required_field" }}</span>
                                    </div>
                                </div>
                                <button class="custella-btn-white px-2 ms-2 py-1" (click)="setStartLocation('home')" id="btn_TaskSelLocHome" [pTooltip]="'home' | translate" tooltipPosition="top">
                                    <img alt="logo" src="assets\svg\sidebar\home.svg" class="w-24px" id="img_TaskSelLocHome" />
                                </button>
                                <button class="custella-btn-white px-2 ms-2 py-1" (click)="setStartLocation('office')" id="btn_TaskSelLocOffice" [pTooltip]="'office' | translate" tooltipPosition="top">
                                    <img alt="logo" src="assets\svg\table\briefcase-black.svg" class="w-24px" id="img_TaskSelLocOffice" />
                                </button>
                                <button class="custella-btn-white px-2 ms-2 py-1" (click)="setStartLocation('current')" id="btn_TaskSelLocCurrent" [pTooltip]="'current_location' | translate" tooltipPosition="top">
                                    <img alt="logo" src="assets\svg\table\map-marker-black.svg" class="w-24px" id="img_TaskSelLocCurrent" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12 me-0" id="label_TaskSelLocStartTime">{{startLocation.startTime}}
                        <span class="custella-content-row-input-title mandatory" style="margin-bottom: 3px;" id="value_TaskSelLocStartTime" translate>{{'start_time'}}</span>
                        <p-calendar [(ngModel)]="startLocation.startTime" formControlName="startTime" name="startTime" id="picklist_TaskSelLocStartTimeField" [timeOnly]="true" inputId="timeonly" hourFormat="12" placeholder="12:00"appendTo="body"></p-calendar>
                        <div class="p-error text-10" *ngIf="startLocationForm.controls['startTime'].touched && startLocationForm.controls['startTime'].invalid">
                            <span *ngIf="startLocationForm.controls['startTime'].errors.required" id="label_TaskSelLocStartTimeReqField" translate>{{'required_field'}}</span>
                        </div>
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <div class="d-flex align-items-center">
                            <p-checkbox [(ngModel)]="startLocation.traffic" id="check_TaskPredictiveTraffic" [ngModelOptions]="{standalone: true}" class="my-2 me-2" [binary]="true"></p-checkbox>
                            <span class="custella-content-row-input-title mt-1" id="label_TaskPredictiveTraffic" translate>{{'predictive_traffic'}}</span>                            
                        </div>
                    </div>
                </div>
            </div>
             <!-- Container Footer -->
             <div class="custella-form-container-footer mb-0">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 mx-2" id="btn_TaskSelLocCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                    <p-button label="Save" (onClick)="onSubmit()" id="btn_TaskSelLocSubmit"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>