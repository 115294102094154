import { Component, OnInit } from '@angular/core';
import { Router ,NavigationEnd} from '@angular/router';
import { MenuService } from 'src/app/_services/menu.service';

@Component({
  selector: 'app-task-frame',
  templateUrl: './task-frame.component.html'
})
export class TaskFrameComponent implements OnInit {
  initialData: any[] = [];

  constructor(private menuItemService: MenuService,
    public router: Router
    ) { 
    this.initialData = this.menuItemService.getMenuTask();
   }

  ngOnInit(): void {
    
  }

  isExpandable(node: any) {
    return node.data.length == 0 ? false : true;
  }


}
