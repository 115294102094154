<div>
  <div class="flex-between">
    <i class="pi pi-times w-24px dialog-close-icon" (click)="closeTicketTable()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="mb-0 mt-2 text-14 font-light color-var-500">{{
      "select_ticket_from_list" | translate
    }}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input
          type="text"
          pInputText autocomplete="off"
          
          [placeholder]="'type_ticket' | translate"
          [(ngModel)]="searchValue"
        />
      </span>
    </div>
    <div class="table-space" style="height: 40vh;">
        <app-ticket-table [searchValue]="searchValue"></app-ticket-table>
    </div>
  </div>
</div>
