<div>
    <div class="flex-between"> 
    
     <i class="pi pi-times w-24px dialog-close-icon" (click)="closeTable()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="mb-0 mt-2 text-14 font-light color-var-500">Select an User from the list</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off"  placeholder="Type User Name" (keyup)="quickSearch()"  [(ngModel)]="searchValue"/>
      </span>
    </div>
      <div  class="table-space"  style="height: 42vh;">
        <ag-grid-angular
        #agGrid
        style="height: 100%;width: 100%;"
        class="ag-theme-balham"
        
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [defaultColDef]="defaultColDef"
        [maxConcurrentDatasourceRequests]="-1"
        [suppressDragLeaveHidesColumns]="true"
        [allowContextMenuWithControlKey]="true"
        (gridReady)="onGridReady($event)"
        [overlayNoRowsTemplate]="overlayNoRowsTemplate"
        (cellClicked)="viewUser($event)"
        ></ag-grid-angular>
        <div class="col-12"><p class="ag-record-row-summary-panel padds text-end">{{totalRecord}} Records</p></div>
        
      </div>
    </div>
  
  </div>