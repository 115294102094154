import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { DeliveryOrderStatus } from 'src/app/_models/global.data.model';
import { DeliveryOrderSetupService } from 'src/app/_services/delivery-order-setup.service';
import { DeliveryOrderService } from 'src/app/_services/delivery-order.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { VehicleService } from 'src/app/_services/vehicle.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-modify-add-order',
  templateUrl: './modify-add-order.component.html'
})
export class ModifyAddOrderComponent implements OnInit {
  toggle1: boolean = false;
  // i: any=[];
  title: any;
  data: any;
  optionType: any

showDoDropDown = false;
showVehicleDropDown = false;
displayDoTable: boolean = false;
displayTable1: boolean = false;
vehicleName: any;
orderNumber: any;
deliveryOrderId: any;
vehicleId: any;
deliveryOrder: any;
vehicles: any;
addOrderForm !: UntypedFormGroup;
selectedVehicleData: any

config: any;
notInOrderIds: any = [];
notInVehicleIds: any = [];
dragableDataList: any;
vehicleData: any;
orderData: any;
dateFormat: any;
vehicles2: any = [];
searchVehicle: any;
originalDragableDataList: any;
vehicleType: any;
refreshAfterCancelList!: Subscription;
  orderNumberData: any;
  doStatuses: any

  constructor(public modalRef: BsModalRef,
    private planningHubService: PlanningHubService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private auth: AuthenticationService,
    private modal: ModalServiceService,
    private errorUtil: ErrorUtil,
    private deliveryOrderService: DeliveryOrderService,
    private deliveryOrderSetupService: DeliveryOrderSetupService,
    private vehicleService: VehicleService) { 
      this.addOrderForm = new UntypedFormGroup({
        'order': new UntypedFormControl(null, [Validators.required]),
        'vehicle': new UntypedFormControl(null, [Validators.required]),
      });
    }

    ngOnInit() {
      this.data.optionType = this.optionType
      //this.deliveryOrderSetupService.setDoStatusDynamically()
      this.doStatuses = DeliveryOrderStatus.statusList;

      
      console.log(this.data)
      this.dateFormat = localStorage.getItem('date_format');
      if(this.data.optionType == 'addOrderSpecificVehicle'){
        this.vehicleType = 'current';
      }
      if(this.data.assignedOrders.length>=1){
        for(var i=0;i<=this.data.assignedOrders.length-1;i++){
          this.notInOrderIds.push(this.data.assignedOrders[i].deliveryOrder.id)
        }
      }
      console.log('notInOrderIdsssssss',this.notInOrderIds)
      for(var i=0;i<=this.data.routes.length-1;i++){
        this.notInVehicleIds.push(this.data.routes[i].vehicleData.vehicle.id)
      }
      console.log('notInVehicleIds',this.notInVehicleIds)
        this.loadData();
      for(var i=0;i<=this.data.routes.length-1;i++){
        if(!this.data.routes[i].locked){
          this.vehicles2.push(this.data.routes[i].vehicleData);
        }
      }
    }
  
    loadData(){
      var resource = [];
      var events = [];
      var relatedEvents: any [] = [];
      this.dragableDataList = [] ;
      this.data.routes.forEach((routes : any) => {
       if(!routes.locked){
        var resource1 : any= {
          id: routes.vehicleData.vehicle.id,
          vehicleNumber: routes.vehicleData.vehicle.vehicleNumber,
          vehicleName: routes.vehicleData.vehicle.name,
          distance: routes.statistic.distance,
          duration:routes.statistic.duration,
          capacity: routes.statistic.capacity,
          imageUrl: routes.vehicleData.vehicle.icon,
          relatedVehicleEvents: null,
          vehicleImage : routes.vehicleData.vehicle.vehicleImage ? routes.vehicleData.vehicle.vehicleImage.attachmentUrl : '',
          vehicleData : routes.vehicleData
        }
        routes.trips.forEach((trips : any)=> {
          if(trips.type!="departure" && trips.type!="arrival"){
          var events1: any = {
            type: trips.type,
            startTime : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('hh:mm A'),
            endTime : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('hh:mm A'),
            orderNo: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.name : "",
            deliveryOrderWay: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.deliveryOrderWay : "",
            orderId : trips.order.id,
            orderCustomer : null,
            note: null,
            status:(trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.orderLineItem.deleveryOrderLineItem.status : "",
          }
          this.data.assignedOrders.forEach((assignedOrdersData: any) => {
            if(assignedOrdersData.deliveryOrder.orderNumber === events1.orderNo){
              assignedOrdersData.deliveryOrderLineItemVMs.forEach((element: any) => {
                var note: any[] = [];
                element.deleveryOrderLineItemPackages.forEach((pack: any) => {
                  note.push('('+pack.quantity+')' + '-' + pack.name );
                })
                events1.note = note;
              })
            }
          })
          routes.deliveryOrders.forEach((order : any)=> {
            if(order.deliveryOrderLite.id === events1.orderId){
              events1.orderCustomer = order.deliveryOrderLite.customer ? order.deliveryOrderLite.customer.name : null;
            }
          })
          events.push(events1);
          relatedEvents.push(events1);
        }
        })
        var relatedVehicleEvents = relatedEvents;
        relatedEvents = [];
        resource1.relatedVehicleEvents = relatedVehicleEvents;
        resource.push(resource1);
        this.dragableDataList.push(resource1);
        this.originalDragableDataList = _.cloneDeep(this.dragableDataList);
       }
      })
    }
  
    proceed(){
      
      if(this.data.optionType == 'addOrderCurrentVehicle'){
          this.addOrderForm.controls['vehicle'].clearValidators();
          this.addOrderForm.controls['vehicle'].updateValueAndValidity();
      }
      if(this.data.optionType == 'adjustNewVehicle'){
        this.addOrderForm.controls['order'].clearValidators();
        this.addOrderForm.controls['order'].updateValueAndValidity();
      }
      if(!this.addOrderForm.valid){
        for (var i in this.addOrderForm.controls) {
          this.addOrderForm.controls[i].markAsTouched();
        }
        return false;
      }
      else{
        //debugger
        if(this.data.optionType == 'addOrderSpecificVehicle'){
          this.openModal('proceedAddOrderSpeVeh','');
        }
        else if(this.data.optionType == 'addOrderCurrentVehicle'){
          //debugger
          this.vehicleData = {id:this.data.routes[0].vehicleData.vehicle.id,name:this.data.routes[0].vehicleData.vehicle.name}
          this.openModal('proceedAddOrderCurrVeh','');
        }
        else {
          this.proceedAdjustNewVehicle();
        }
      }
    }
  
    proceedAdjustNewVehicle(){
      this.spinner.show();
      var formData = {planId: this.data.draftPlanId,vehicleId: this.vehicleData.id,includeUnlockedVehicles: true}
      this.planningHubService.adjustNewVehicle(formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          if(res.body.serverError){
            this.spinner.hide();
            this.errorUtil.setErrorMessage(400, null , res.body.serverError.cause, 'error',3000);
           
          }
          else if(res.body.errors){
            this.spinner.hide();
            this.errorUtil.setErrorMessage(400, null , res.body.errors.messages, 'error',3000);
           
          }
          else{
            this.modalRef.hide();
            this.spinner.hide();
            this.errorUtil.setErrorMessage(200, null , 'Vehicle added successfully', 'success',1000);
            
          }
        }
        else if (res.status === 400) {
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null , res.error.title, 'error',3000);
        }
        else {
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null , res.error.title, 'error',3000);
         
        }
      })
    }
    openModal(modalView : any,data: any) {
      this.modalRef.hide();
      console.log(modalView,"===",this.vehicleData)
     
     if (modalView == "proceedAddOrderCurrVeh") {//any current vehicle
        var data1 = {btnName:'proceedAddOrderCurrVeh',orderNumber:this.orderNumber,planId:this.data.draftPlanId,vehicleId:null,vehicleName:this.vehicleData.name,deliveryOrderId:this.orderData.id,planNumber:this.data.planNumber}
        data = data1
      }
      else if (modalView == "proceedAddOrderSpeVeh") {//specific vehicle
        var type =  (this.data.planStatus=="DRAFT" || this.data.planStatus=="CONFIRMED") ? 'preDispatch' : 'postDispatch';
        var data2 = {btnName:'proceedAddOrderSpeVeh',orderNumber:this.orderNumber,planId:this.data.draftPlanId,vehicleId:this.vehicleData.id,vehicleName:this.vehicleData.name,deliveryOrderId:this.orderData.id,type:type,planNumber:this.data.planNumber}
        data = data2
      }
      console.log(modalView,"----",data)
      this.modal.openModal(modalView, data);
    }

 ///////////////////////////////////// start(DO search component) /////////////////////
openDoDropDown() {
  this.showDoDropDown = true;
}
closeDoDropDown() {
  this.showDoDropDown = !this.showDoDropDown;
}
gotSelectedOrder(val: any){
  this.selectDoNumber(val);
  this.displayDoTable =false
}
closeOrderView(){
  this.displayDoTable =false
}
showDoTable(){
  this.displayDoTable = !this.displayDoTable;
}
selectDoNumber(value: any){
  this.deliveryOrderId = value.id;
  this.orderNumber = value.name;
  this.orderNumberData = value;
  // this.filterVehicle(value);
  this.orderData = value;
  this.orderData.newStataus = this.displayStatus(this.orderData.orderStatus)
  console.log(this.orderData)
  this.showDoDropDown = false;
  this.deliveryOrder=[];
}
clearDoValue() {
  this.deliveryOrderId = null;
  this.orderNumber = null;
  this.orderNumberData = null
  this.showDoDropDown = false;
  this.deliveryOrder = [];
  this.orderData = null;
}
onValidDo(){
 if(this.deliveryOrderId==null){
   this.orderNumber = null;
   this.orderNumberData = null;
  }
}
getOrderIdsFirstWay(event : any) {
  var k = event.keyCode
  if (k == 8) {
    this.clearDoValue();
  }
  else {
     if (this.orderNumberData != null && this.orderNumberData.length > 2) {
      this.deliveryOrderService.getSearchDO(this.orderNumberData,this.notInOrderIds).subscribe((res: any) => {
        this.deliveryOrder = res.data;
        this.openDoDropDown();
      })
     }
  }
}
// end (DO search component)

vehicleTypeChange(vehicleType: any){
  if(vehicleType != 'new'){
    this.vehicleTypeChange2(vehicleType);
  } else  if(this.data.routes?.length <= this.data.fleetConfigSetup.noOfVehicleMax) {      
    this.vehicleTypeChange2(vehicleType);
  } else {
    if(this.data.fleetConfigSetup.noOfVehicleMax == 150){
      this.errorUtil.setErrorMessage(400, null , 'Each plan can only take a maximum of 150 vehicles', 'warn',3000);
    }else{
      this.errorUtil.setErrorMessage(400, null , 'You can add only up to '+this.data.fleetConfigSetup.noOfVehicleMax+' vehicles per plan. If you require a bigger plan, please consider upgrading to a higher tier.', 'warn',3000);
    }
  }
}
private vehicleTypeChange2(vehicleType: any){   
    this.vehicleType = vehicleType
    this.vehicleId = '';
    this.vehicleName = '';
    this.searchVehicle = null;
    this.vehicleData = null;
    this.selectedVehicleData = null;
}
 //vehicle search add order(addOrderSpecificVehicle)
 search2($event : any){
  console.log($event.value)
  if( $event.value){
  this.searchVehicle = $event.value.vehicle.vehicleNumber;
  console.log(this.searchVehicle)
  if(this.searchVehicle){
    var arr = this.originalDragableDataList.filter((item : any)=> (item.vehicleNumber==this.searchVehicle));
    this.dragableDataList = arr;
    this.vehicleData = {id:arr[0].vehicleData.vehicle.id,name:arr[0].vehicleData.vehicle.name}
  }
  else{
    this.dragableDataList = _.cloneDeep(this.originalDragableDataList);
  }
}else{
  this.dragableDataList=null
}
}

///////////////////////////////////// start(Vehicle search component) /////////////////////

closeVehicleDropDown() {
  this.showVehicleDropDown = !this.showVehicleDropDown;
}
gotSelectedVehicle(val: any){
  this.selectVehicleValue(val);
  this.displayTable1 =false
}
closeVehicleView(){
  this.displayTable1 =false
}
showVehicleTable(){
  this.displayTable1 = !this.displayTable1;
}
selectVehicleValue(value: any) {
  
  this.vehicleId = value.id;
  this.vehicleName = value.name;
  this.vehicleData = value;
  this.showVehicleDropDown = false;
  this.vehicles=[];
}
clearVehicleValue() {
  this.vehicleId = null;
  this.vehicleName = null;
  this.vehicleData = null;
  this.showVehicleDropDown = false;
  this.vehicles = [];
}
onValidVehicle(){
 if(this.vehicleId==null){
   this.vehicleName = null;
 }
}
getVehicleIdsFirstWay(event: any) {
  var k = event.keyCode
  if (k == 8) {
    this.clearVehicleValue();
  }
  else {
    if (this.vehicleName != null && this.vehicleName.length > 2) {
      var params= { 
        sortModel :[{'colId': 'vehicleNumber', 'sort': 'asc'}],
        startRow:0 }
    this.vehicleService .getVehiclesList(
              null,
              this.vehicleName,
              null,
              null,
              params,
              this.notInVehicleIds,
              null,
              null
            )
            .subscribe((data: any) => {
             
              this.vehicles = data.data;
               this.spinner.hide();
            });

      
    }
  }
}
// end (Vehicle search component)

displayStatus(orderStatus : any){
  console.log(this.doStatuses,"----->>>")
  for (let i = 0; i < this.doStatuses.length; i++) {
    if (this.doStatuses[i].name === orderStatus) {
      return this.doStatuses[i];
    }
  }
}
/******************************************** Toggle ******************************************/
public toggle(element: any) {
  const contains = element.classList.contains("collapsed");

  if (contains) {
    element.className = "pi pi-caret-down mt-1 ms-3 collapsed";
  } else {
    element.className = "pi pi-caret-up mt-1 ms-3";
  }
}
}
