<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img src="assets\svg\dialog\Confirmation-w.svg">
            <span class="text-16 font-medium ms-3">{{title}}</span>
        </div>
        <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
    
    <!---------------- CONFRIM, DISPATCH, COMPLETE or INCOMPLETE BUTTTON -------------------------->
    <form *ngIf="(data.btnName!='confirmCancelBtn')&&(data.btnName!='dispatchedCancelBtn')&&(data.btnName !='inprogressCancelBtn')&&(data.btnName!='proceedAddOrderSpeVeh')&&(data.btnName!='proceedAddOrderCurrVeh')&&(data.btnName!='removeOrder')&&(data.btnName!='removeVehicle')&&(data.btnName!='breakdown')&&(data.btnName!='cancelOrder')&&(data.btnName!='returnOrder')&&(data.btnName!='changeVehicle')">
     
      <div class="modal-body pt-0">
        <div class="container text-center px-0" style="padding: 20px;">
          <!-- CONFIRM BUTTON (if draft) -->
          <p class="mb-0 text-4 pb-2 px-3">{{data?.orderName}} will be moved to {{data?.vehicleNumber}}</p>
          <p class="mb-0 text-4 pb-2 px-3">Do you wish to continue?</p>
        </div>
      </div>
      <div class="modal-footer p-0">
        <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
        <p-button type="button" (click)="confirmModel()"  label="{{'confirm' | translate}}"></p-button>
    </div>
    
    </form>
   
    
    
    