<div class="">
  <div class="custella-modal d-flex flex-column">
      <div class="custella-modal-header">
          <div class="custella-modal-header-text" id="title_MapDeliverLoc">{{ "delivery_order_location" | translate }} </div>

          <i class="pi pi-times pt-1"  [pTooltip]="'close'|translate" tooltipPosition="left"  id="btn_MapDeliverLocClose" (click)="modalRef.hide()"></i>
      </div>

      <div class="custella-modal-body">
          <div class="custella-content-row-input">
              <div style="height: 70vh;">
                  <p-gmap [options]="options" [overlays]="overlays" [style]="{ width: '100%', height: '100%' }" (onMapReady)="setGMap($event)" *ngIf="showMap"></p-gmap>
              </div>
          </div>
      </div>
  </div>
</div>
