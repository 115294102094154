import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { PublicRoutingModule } from './public/public-routing.module';
import { SharedModule } from './shared/shared.module';

import { AuthGuardService } from './auth/auth-guard.service';
import { PermissionService } from './auth/permission.service';
import { PublicModule } from './public/public.module';
import { UtilServiceService } from './_services/utilService/util-service.service';
import { ProfilesService } from './_services/admin/profiles.service';
import { AuthenticationService } from './auth/authentication.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiService } from './_services/api.service';
import { ProfileFeaturesService } from './_services/profile-features.service';
import { UsersService } from './_services/admin/users.service';
import { ErrorUtil } from './_utilities/error';
import { TokenInterceptor } from './auth/token-interceptor';

import { AgGridModule } from 'ag-grid-angular';

import { DashboardRoutingModule } from './dashboard/dashboard-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { CustomersService } from './_services/customers.service';
import { WebSocketsService } from './_services/web-sockets.service';
import { NotificationsService } from './_services/notifications.service';
import { SettingService } from './_services/admin/setting.service';
import { ButtonRendererComponent } from './renderer/button-renderer.component';
import { HeaderTitleService } from './_services/header-title.service';
import { MenuService } from './_services/menu.service';
import { BreadcrumbService } from './_services/breadcrumb.service';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { InventoryService } from './_services/inventory.service';
import { ProductService } from './_services/product.service';
import { ModalServiceService } from './_services/modal-service.service';
import { TasksService } from './_services/tasks.service';
import { DeleteRecordService } from './_services/delete-record.service';
import { AssetService } from './_services/asset.service';
import { DynamicFormService } from './_services/dynamic-form.service';
import { SignUpService } from './_services/sign-up.service';
import { LoadingBayService } from './_services/loading-bay.service';
import { VehicleService } from './_services/vehicle.service';
import { SalesOrdersService } from './_services/sales-orders.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { MagnumFormService } from './_services/magnum-form.service';
import { DynamicElementModule } from './dashboard/dynamic-element/dynamic-element.module';
import { TimezonePickerService } from './_services/timezone-picker.service';
import { DeliveryOrderSetupService } from './_services/delivery-order-setup.service';
import { RolesService } from './_services/admin/roles.service';
import { AccessButtonRendererComponent } from './dashboard/setup/access-button-renderer/access-button-renderer.component';
import { PlanningHubService } from './_services/planning-hub.service';
import { IconRendererComponent } from './renderer/icon-renderer.component';
import { LeaveService } from './_services/leave.service';
import { ScheduleService } from './_services/schedule.service';
import { ChubbServicesService } from './_services/chubb-services.service';
import { TeamService } from './_services/team.service';
import { LockRendererComponent } from './renderer/lock-renderer.component';
import { PlaninghubTooltip } from './renderer/planinhhub-tooltip.component';
import { ChecklistService } from './_services/checklist.service';
import { VisitsService } from './_services/visits.service';
import { SelectAllComponent } from './renderer/select-all.component';
import { PmvService } from './_services/pmv.service';
import { CustomerSurveyService } from './_services/customer-survey.service';
import { SterraFormService } from './_services/sterra-form.service';
import { ResetPasswordComponent } from './dashboard/setup/users/reset-password/reset-password.component';
import { DynamicTableColumnsService } from './_services/dynamic-table-column.service';
import { UsetifulService } from './_services/usetiful.service';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ButtonRendererComponent,
    AccessButtonRendererComponent,
    IconRendererComponent,
    LockRendererComponent,
    PlaninghubTooltip,
    SelectAllComponent,
    ResetPasswordComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    PublicModule,
    PublicRoutingModule,
    DashboardRoutingModule,
    DashboardModule,
    AgGridModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  }),
  DynamicElementModule
  ],
  providers: [
    AuthGuardService,
    ApiService,
    AuthenticationService,
    PermissionService,
    ProfileFeaturesService,
    UsersService,
    UtilServiceService,
    ProfilesService,
    ErrorUtil,
    WebSocketsService,
    SettingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    NotificationsService,
    HeaderTitleService,
    MenuService,
    BreadcrumbService,
    CustomersService,
    InventoryService,
    ProductService,
    ModalServiceService,
    TasksService,
    DeleteRecordService,
    AssetService,
    DynamicFormService,
    SignUpService,
    LoadingBayService,
    VehicleService,
    SalesOrdersService,
    MagnumFormService,
    TimezonePickerService,
    DeliveryOrderSetupService,
    RolesService,
    PlanningHubService,
    LeaveService,
    ScheduleService,
    ChubbServicesService,
    TeamService,
    VisitsService,
    ChecklistService,
    PmvService,
    CustomerSurveyService,
    SterraFormService,
    DynamicTableColumnsService,
    UsetifulService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
