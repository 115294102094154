import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

import { saveAs } from "file-saver";

@Component({
  selector: "app-download-barcode",
  templateUrl: "./download-barcode.component.html",
  styleUrls: ["./download-barcode.component.scss"],
})
export class DownloadBarcodeComponent implements OnInit {
  data: any;
  barCodeType: any;

  constructor(public modalRef: BsModalRef) {}

  ngOnInit() {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
   
    if (this.data && this.data.qrCodeType || this.data.barCodeType) {
      this.barCodeType = this.data.qrCodeType ?? this.data.barCodeType;
    } else {
      this.barCodeType = localStorage.getItem("qrCodeType");
    }
  }

  /******************************************** Download QR ******************************************/
  public downloadQRCode() {
    let canvas = document.querySelector("canvas")!;
    canvas.toBlob((blob: any) => {
      saveAs(blob, this.data.serialNo + ".png");
    });
  }
}
