import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { CustomersService } from "src/app/_services/customers.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-custella-customers",
  templateUrl: "./custella-customers.component.html",
  styleUrls: ["./custella-customers.component.scss"],
})
export class CustellaCustomersComponent
  extends SubscriptionUtil
  implements OnInit
{
  searchValue: string = "";

  @Output() closeCustomerEvent = new EventEmitter();
  @Output() selectCustomerEvent = new EventEmitter();
  @Input() lookupFilter: any =''
  constructor(private customerService: CustomersService) {
    super();

    this.push(
      this.customerService.getCustomer().subscribe((data) => {
        console.log("cus data", data)
        if (data) {
          this.selectCustomer(data);
        }
      })
    );
  }

  ngOnInit(): void {}

  selectCustomer(val: any) {
    this.selectCustomerEvent.emit(val);
  }

  closeCustomer() {
    this.closeCustomerEvent.emit(false);
  }
}
