import { NgModule } from "@angular/core";
import { LeftMenuComponent } from "./layout/left-menu/left-menu.component";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { TopHeaderComponent } from "./layout/top-header/top-header.component";
import { SharedModule } from "../shared/shared.module";
import { DashboardComponent } from "./dashboard.component";
import { InventoryComponent } from "./inventory/inventory.component";
import { TicketsModule } from "./tickets/tickets.module";
import { AssetComponent } from "./asset/asset.component";
import { ProductComponent } from "./product/product.component";
import { SetupComponent } from "./setup/setup.component";
import { SalesOrderComponent } from "./sales-order/sales-order.component";
import { TasksModule } from "./tasks/tasks.module";
import { HelpdeskModule } from "./helpdesk/helpdesk.module";
import { CommonModule } from "@angular/common";
import { SetupFrameComponent } from "./setup/layout/setup-frame/setup-frame.component";
import { SetupMenuItemComponent } from "./setup/layout/setup-menu-item/setup-menu-item.component";
import { FqcIqcModule } from "./iqc-fqc/fqc-iqc.module";
import { VehiclesModule } from "./vehicles/vehicles.module";
import { DeliveryOrdersComponent } from './delivery-orders/delivery-orders.component';
import { ShiftsComponent } from './shifts/shifts.component';
import { PlanningHubComponent } from './planning-hub/planning-hub.component';
import { MessageModule } from "primeng/message";
import { PackagesComponent } from './packages/packages.component';
import { PackagesModule } from "./packages/packages.module";
import { NotFound404Component } from './not-found404/not-found404.component';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { ChubbDashboardModule } from "./home/chubb/chubb-dashboard/chubb-dashboard.module";
import { ProjectComponent } from './project/project.component';
import { SitesComponent } from './sites/sites.component';
import { ActiveReportsComponent } from './active-reports/active-reports.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { VisitsComponent } from './visits/visits.component';
import { ProductivityComponent } from './productivity/productivity.component';
import { PmvComponent } from './pmv/pmv.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { OvertimeComponent } from './overtime/overtime.component';
import { MachinesSpecialistComponent } from './machines-specialist/machines-specialist.component';

@NgModule({
  declarations: [
    DashboardComponent,
    LeftMenuComponent,
    TopHeaderComponent,
    InventoryComponent,
    AssetComponent,
    ProductComponent,
    SetupFrameComponent,
    SetupMenuItemComponent,
    SetupComponent,
    SalesOrderComponent,
    DeliveryOrdersComponent,
    ShiftsComponent,
    PlanningHubComponent,
    PackagesComponent,
    NotFound404Component,
    NoPermissionComponent,
    ProjectComponent,
    SitesComponent,
    ActiveReportsComponent,
    ChecklistComponent,
    VisitsComponent,
    ProductivityComponent,
    PmvComponent,
    AttendanceComponent,
    OvertimeComponent,
    MachinesSpecialistComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    DashboardRoutingModule,
    TasksModule,
    HelpdeskModule,
    FqcIqcModule,
    MessageModule,
  ],

  exports: [LeftMenuComponent, TopHeaderComponent,SetupFrameComponent,SetupMenuItemComponent],
})
export class DashboardModule {
  constructor() {}
}
