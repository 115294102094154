import { AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef, GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { StatusRendererComponent } from 'src/app/renderer/status-renderer.component';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DataService, locatns, ServerRequest, TableDef } from '../IqcFqc';
var router: Router;
@Component({
  selector: 'app-iqc',
  templateUrl: './iqc.component.html',
  styleUrls: ['./iqc.component.scss']
})
export class IqcComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  title = 'IQC';
  public searchValue: any = "";
  @ViewChild("iqcSearch") searchInput!: ElementRef;
  activeIndex = 0;
  isFilterToggleOn: boolean = false;

  dateRange!: any;
  filter: ServerRequest = {
    location: '',
    fromDate: '',
    toDate: '',
    startFrom: 0,
    perPage: 25,
    columnName: 'runningNumber',
    sortOrder: 'desc'
  };
  filterOld: any;

  create: boolean = true;

  gridApi: any;
  columnDefs: any = [];
  columnDefsTmp: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  displayIcon: boolean = false;
  currentRecordNumber: any;
  totalRecord: any;
  noIqcs!: boolean;
  iqcs: any;
  refresher: Subscription = new Subscription();
  subscriber: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  locations: any = locatns;
  dateFormateChange: any;

  constructor(
    private iqcFqcService: IqcFqcService,
    private errorUtil: ErrorUtil,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private auth: AuthenticationService,
    private dataService: DataService
  ) {
    this.refresher = iqcFqcService.refreshIqc$.subscribe(() => {
      this.spinner.show();
      this.onGridReady(this.gridParams);
    });
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      statusRenderer: StatusRendererComponent,
    };
    this.preInit();
    this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";
    this.breadcrumbService.setItems([]);

  }
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngOnInit(): void {
    router = this.router;
    this.dateFormateChange = this.auth.getUserTimezone();
  }

  ngAfterViewInit(): void {
    this.gridOptions.api!.setColumnDefs(this.columnDefs);
    this.subscriber1 = this.dataService.currentMessage
      .subscribe(message => {
        console.log(message);
        this.activeIndex = Number(message);
        if (this.gridApi)
          this.onGridReady(this.gridParams);
      }, (err) => { console.log(err) });
    if (this.gridApi)
      this.onGridReady(this.gridParams);
  }

  ngOnDestroy(): void {
    if (this.refresher)
      this.refresher.unsubscribe();
    if (this.subscriber) this.subscriber.unsubscribe();
    if (this.subscriber1) this.subscriber.unsubscribe();
  }
  /******************************************** pre-load variables ******************************************/
  private preInit() {
    let tblOption = new TableDef(true);
    this.gridOptions = tblOption.gridOptions;
    this.defaultColDef = tblOption.defaultColDef;
    this.columnDefsTmp = tblOption.columnDefs;

    this.columnDefsTmp.forEach((element: any) => {
      const modifiedColumn = this.modifyColumnTable(element);
      this.columnDefs.push(modifiedColumn);
    });
    tblOption.action.cellRendererParams = {
      onClick: this.editBin.bind(this),
      hidden: { update: true, remove: true },
      permissions: { update: true, remove: true },
      // permissions: {
      //   update: this.perm.capable("IQC", "update"),
      //   remove: this.perm.capable("IQC", "remove"),
      // },
    };
    this.columnDefs.push(tblOption.action);
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    console.log("Step 2")
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    console.log('Making a server request: ', serverRequest);
    // if (this.isValidReq(serverRequest)) {
    this.subscriber = this.iqcFqcService.getAllIqc(this.searchValue, serverRequest).subscribe((data: any) => {
      console.log(data)
      this.iqcs = data.data;
      this.totalRecord = data.total
      this.spinner.hide();
      this.iqcs.forEach((e:any)=>e.page="IQC");
      this.setGridData(agGridGetRowsParams, this.iqcs, data.total, data.status);
    },
      (err) => {
        this.errorUtil.setErrorMessage(err);

      })
    // }

  }
  isValidReq(serverRequest: ServerRequest): boolean {
    if (this.filterOld == null) {
      this.filterOld = {
        location: serverRequest.location,
        fromDate: serverRequest.fromDate,
        toDate: serverRequest.toDate,
        startFrom: serverRequest.startFrom,
        perPage: serverRequest.perPage,
        columnName: serverRequest.columnName,
        sortOrder: serverRequest.sortOrder,
        searchValue: this.searchValue
      };;
      return true;
    } else if (this.filterOld.location != serverRequest.location || this.filterOld.fromDate != serverRequest.fromDate ||
      this.filterOld.toDate != serverRequest.toDate || this.filterOld.startFrom != serverRequest.startFrom ||
      this.filterOld.searchValue != this.searchValue) {
      this.filterOld.location = serverRequest.location;
      this.filterOld.fromDate = serverRequest.fromDate;
      this.filterOld.toDate = serverRequest.toDate;
      this.filterOld.startFrom = serverRequest.startFrom;
      this.filterOld.searchValue = this.searchValue
      return true;
    }
    else return false;
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
    //if (resultItems) {

    if (status === 'NO_DATA') {
      this.noIqcs = true;
      this.displayIcon = true;
    } else {
      this.noIqcs = false;
      this.displayIcon = false;
    }
    if (this.iqcs.length === 0) {
      this.gridParams.api.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    this.currentRecordNumber = agGridRequest.startRow
    // this.filter.location = '';
    this.filter.fromDate = this.dateRange ? this.date(this.dateRange[0]) : '';
    this.filter.toDate = this.dateRange ? this.date(this.dateRange[1]) : '';
    this.filter.startFrom = agGridRequest.startRow;
    return this.filter;
  }

  private date(d: any) {
    return moment.tz(d, this.dateFormateChange).format('YYYY-MM-DD');
  }

  /******************************************** modify column to fit new ag-grid ******************************************/
  public modifyColumnTable(element: any) {
    // base
    const column: ColDef = {
      field: element.field,
      headerName: this.util.formatText(element.headerName, "titlecase", {
        seperator: " ",
      }),
      hide: element.hide,
      width: 30,
      colId: element.field,
      cellClass: [],
      headerTooltip: "#",
    };

    if (element.field === "runningNumber") {
      column.headerName = "IQC No."
      column.cellClass = function () {
        return ["table_default_color"];
      };
    }

    if (element.field === "created_at") {
      column.cellRenderer = (params: any) => {
        if (params.data) {
          if (
            params.data.created_at !== null &&
            params.data.created_at !== undefined &&
            params.data.created_at !== ""
          ) {
            return moment
              .tz(params.data.created_at, this.dateFormateChange)
              .format(localStorage.getItem("date_format")!.toUpperCase());
          } else {
            return "";
          }
        }
      };
    }

    if (element.field === "status") {
      column.cellRenderer = (params: any) => {
        let status: any;
        if (params.data && params.data) {
          if (params.data.status) {
            const currentStatus = params.data.status;
            let satusObj: any;
            satusObj = statusIqc.find((status: any) => status.name === currentStatus);
            status =
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              satusObj.colorCode +
              "50 !important ;color:" +
              satusObj.colorCode +
              '"><span class="p-tag-value text-dark">' +
              satusObj.fieldLabel +
              "</span></span>";
          }
        }
        return status;
      };
      column.width = 20;
    }
    return column;
  }

  /******************************************** let job order table refresh ******************************************/
  public callLoadData() {
    this.rerender();
  }
  /******************************************** reload table ******************************************/
  public rerender() {
    this.onGridReady(this.gridParams);
  }
  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    //console.log(params)
    this.gridParams = params;

    this.gridApi = params.api;
    // if (this.activeIndex++ < 3) {
    //   this.gridApi.sizeColumnsToFit();
    // }

    console.log("Step 1")
    var dataSource = this.serverSideDatasource()
    console.log(dataSource)

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
  private serverSideDatasource() {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      }
    }
  }

  /******************************************** let job order table toggle filter ******************************************/
  public callFilterToggle($event: any) {
    this.filterToggle($event);
  }

  /******************************************** toggle the filter sidebar ******************************************/
  public filterToggle($event: any) {
    this.isFilterToggleOn = !this.isFilterToggleOn;
  }

  /******************************************** search input ******************************************/

  onSearchChange(searchValue: any): void {
    this.setup();
    console.log(searchValue.value);
  }

  onSearchChange2(searchValue: any): void {
    if (searchValue.value?.length == 0) this.setup();
    console.log(searchValue.value);
  }

  onSearchClear(): void {
    this.searchValue = null;
    this.searchInput.nativeElement.value = null;
    this.onGridReady(this.gridParams);
    console.log(this.searchInput.nativeElement.value);
  }

  /******************************************** find and filter customers ******************************************/
  private setup() {
    if (this.searchInput) {
      fromEvent(this.searchInput.nativeElement, "input")
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe((value: any) => {
          if (value && value.target.value.length > 3 || value.target.value.length == 0) {
            this.searchValue = value.target.value;
            this.onGridReady(this.gridParams);
            console.log(this.searchValue);
          }
        });
    }
  }

  public onChangeDate() {
    if (this.dateRange[1] !== null)
      this.onGridReady(this.gridParams);
  }

  public filterResults(e:any) {
    // if (this.filter.location != null)
    this.onGridReady(this.gridParams);
  }

  /******************************************** reset filter ******************************************/
  public resetSearch() {
    this.dateRange = undefined;

    this.filter = {
      location: '',
      fromDate: '',
      toDate: '',
      startFrom: 0,
      perPage: 25,
      columnName: 'runningNumber',
      sortOrder: 'desc'
    };

    this.onGridReady(this.gridParams);
  }

  /******************************************** one item view ******************************************/

  viewData(e: any) {
    if (e.colDef.field === "runningNumber")
      this.router.navigate(['dashboard/iqc-fqc/iqc-view/' + e.data._id]);
  }

  openCreateIqc() {
    this.router.navigate(["dashboard/iqc-fqc/iqc-create/0"], { queryParams: { isEdit: false } });
  }


  /******************************************** right click cell context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;

    let results: any = [];

    if (params.column.colId == "runningNumber") {//todo enable
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });

      // results.push({
      //   name: "Export to Excel",
      //   action: () => this.onBtExport(data),
      // });
    }

    return results;
  };

  /******************************************** redirect to ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    if (data.field === "runningNumber") {
       localStorage.setItem("activeIndex",'0');
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/iqc-fqc/iqc-view/" + data._id], {
          queryParams: { jobOrderId: data.id },
        })
      );
      url = url.replace("%23", "#");

      this.util.openItemToNewTab(url);
    }
  }

  /******************************************** export job order data ******************************************/
  public onBtExport(params: any) {
    if (params.field === "runningNumber") {
      this.gridApi.exportDataAsExcel();
    }
  }

  editBin(e: any) {
    if (e.action === 'edit') {
      this.router.navigate(["dashboard/iqc-fqc/iqc-create/" + e.rowData._id], { queryParams: { isEdit: true } });
    }
    else if (e.action === 'delete') {
      e.rowData.deleteType = 'IQC'
      e.rowData.message = "Are you sure you want to delete " + e.rowData?.runningNumber + " ?"
      e.rowData.id = e.rowData._id;
      console.log(e.rowData)
      this.deleteRecordService.getItems(e.rowData);
    }
  }



}

const statusIqc = [
  {
    "id": 1,
    "name": "OPEN",
    "fieldLabel": "Open",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#B2B2B2",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 1,
    "name": "NEW",
    "fieldLabel": "New",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#B2B2B2",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 2,
    "name": "IN_PROGRESS",
    "fieldLabel": "In Progress",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#FDA31A",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 3,
    "name": "COMPLETED",
    "fieldLabel": "Completed",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#4ED15E",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 4,
    "name": "PENDING",
    "fieldLabel": "Pending",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#8C34B0",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 5,
    "name": "CANCELLED",
    "fieldLabel": "Cancelled",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#ED3636",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 6,
    "name": "IN_COMPLETED",
    "fieldLabel": "In Completed",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#975E50",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "arfan2@custella.com",
    "createdDate": "2022-08-09T01:37:29Z",
    "lastModifiedBy": "arfan2@custella.com",
    "lastModifiedDate": "2022-08-09T01:37:29Z",
    "id": 7,
    "name": "CLOSED_ESCALATED",
    "fieldLabel": "Closed Escalated",
    "description": null,
    "active": true,
    "order": 6,
    "colorCode": "#1F9EB5",
    "isDefault": null,
    "systemValue": false
  },
  {
    "createdBy": "arfan2@custella.com",
    "createdDate": "2022-08-09T01:37:29Z",
    "lastModifiedBy": "arfan2@custella.com",
    "lastModifiedDate": "2022-08-09T01:37:29Z",
    "id": 7,
    "name": "DRAFT",
    "fieldLabel": "Draft",
    "description": null,
    "active": true,
    "order": 6,
    "colorCode": "#1F9EB5",
    "isDefault": null,
    "systemValue": false
  }
];