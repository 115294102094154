import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { CustomerSurveyService } from 'src/app/_services/customer-survey.service';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import * as moment from 'moment';
var router: Router
var dateFormateChange: any;

@Component({
  selector: 'app-custella-survey',
  templateUrl: './custella-survey.component.html',
  styleUrls: ['./custella-survey.component.scss']
})
export class CustellaSurveyComponent  extends SubscriptionUtil
implements OnInit, OnChanges, OnDestroy {

  @Input() displayAction: any = false
  @Input() searchValue: any = null
  @Input() isActive: any = null

  @Output() customerSurveyConfigSelector = new EventEmitter();
  @Output() closecustomerSurveyConfigView = new EventEmitter();

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;
  overlayNoRowsTemplate: string;

  msData: any = [];
  actionData: any;
  totalRecord: any;
  currentRecordNumber: any;
  refresher!: Subscription;
  noCustomers: boolean = false;
  noCustomersIcon: boolean = false;
  dateFormat: any;
  statuses: any;

  constructor(
    private auth: AuthenticationService,
    private customerSurveyService: CustomerSurveyService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private spinner: NgxSpinnerService,
    private perm: PermissionService,
  ) {

    super();
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    }

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'infinite',
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    }
    this.columnDefs = [
      { headerName: 'Running No', field: 'runningNo', width: 170, cellClass: 'table_default_color', headerTooltip: "#", },
      { headerName: 'Survey Name', field: 'name', width: 100, headerTooltip: "#", },
      { headerName: 'Create Date', field: 'createdDate', width: 200, headerTooltip: "#",
      cellRenderer: function (params: any) {
        if (params.data && params.data.createdDate) {
          return ( moment.tz(params.data.createdDate, dateFormateChange) .format("hh:mm A") +
            ", " + moment.tz(params.data.createdDate, dateFormateChange) .format(localStorage.getItem("date_format")!.toUpperCase()) );
        }
      }, },
      { headerName: 'Create By', field: 'createdByUser.fullName', width: 200, headerTooltip: "#", },
      { headerName: 'Active', field: 'active', width: 100,
      headerTooltip: "#",
      cellRenderer: function (params: any) {
        if(params.data){
          if(params.data.active){
            return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
          }else{
            return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
            }
        }
      }
    }
    ]

    this.defaultColDef = {
      enableRowGroup: false,//ag-Grid-Enterprise
      enablePivot: false,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";

    this.actionData = {
      headerName: 'Action', filter: false, sortable: false, width: 120, headerClass: 'marging-auto hide-action-border', cellStyle: { "text-align": "center" }, cellRenderer: 'buttonRenderer',
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("machine specialist", "update"),
          remove: this.perm.capable("machine specialist", "remove"),
        },
        hidden: {
          update: !this.perm.capable("machine specialist", "update"),
          remove: !this.perm.capable("machine specialist", "remove"),
        },
      },
      headerTooltip: "#",
    }


  }

  ngOnInit() {
    dateFormateChange = this.auth.getUserTimezone();
    router = this.router
    if (this.displayAction) {
      this.columnDefs.push(this.actionData)
    }

  }

  /********************************** Click Cell Event *****************************/
  edit(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["dashboard/machines/update"], { queryParams: { id: e.rowData.id }, });

    } else if (e.action === "delete") {
      e.rowData.deleteType = "Machines Specialist";
      e.rowData.message = "Are you sure you want to delete " + e.rowData.runningNo + "?";
      this.deleteRecordService.getItems(e.rowData);
    }
  }
  onRefreshList() {
    this.onGridReady(this.gridParams);

  }
  /********************************** Search Data **************************/
  quickSearch() {
    this.onGridReady(this.gridParams);
  }
  /********************************** View Singal Record **************************/
  onGridReady(params: any) {
    //console.log(params)
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          this.getServerSideData(params);
        }
      }

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest: any = this.getRequestParams(agGridGetRowsParams);
    // //console.log('Making a server request: ', serverRequest);
    if(this.isActive != null) serverRequest.isActive = this.isActive;

    this.gridApi.hideOverlay();

    this.push(
      this.customerSurveyService.getAllCS(this.searchValue, serverRequest).subscribe((data: any) => {
        console.log(data)
        this.msData = data.body.data
      
        this.totalRecord = data.body.total
        this.setGridData(agGridGetRowsParams, this.msData, data.body.total, data.body.status);
        this.spinner.hide()
      },
        (err) => {
          //console.log(err,"===>>>")
          this.errorUtil.setErrorMessage(err);

        })
    )
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(agGridGetRowsParams: IServerSideGetRowsParams, resultItems: any[], totalCount: number, status: string) {
    console.log(resultItems, "===")

    if (this.msData.length === 0) {
      this.noCustomers = false;
      this.noCustomersIcon = true
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noCustomers = true;
      this.noCustomersIcon = false
      agGridGetRowsParams.successCallback(this.msData, totalCount);
      ///CS-4758 this.gridApi.sizeColumnsToFit();
    }



  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {

    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "id", sort: "asc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel
    }
  }
  /********************************** View Singal Record **************************/

 

  /******************************************** Refresh Table ******************************************/
  public refresh() {
    //debugger;
    this.onGridReady(this.gridParams);
  }



  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if(this.displayAction){
      if (this.searchValue == "" || this.searchValue) {
        this.refresh()
      }
    }else{
       this.refresh()
      
    }

   

  }
  closeCS() {
    this.closecustomerSurveyConfigView.emit(false);
  }
  viewCS(e: any) {
    console.log(e.colDef, "===----", this.displayAction)
      this.customerSurveyConfigSelector.emit(e.data);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  push(obs: any) {
    super.push(obs);
  }
}


