import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ScheduleService } from 'src/app/_services/schedule.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DashboardComponent } from '../../dashboard.component';
declare var google: any;
@Component({
  selector: 'app-start-location',
  templateUrl: './start-location.component.html',
  styleUrls: ['./start-location.component.scss']
})
export class StartLocationComponent implements OnInit ,AfterViewChecked{
  options!: { fields: string[]; };
  startLocationForm: any;
  title: any;
  data: any;
  startLocation: any = {location : ''};
  setGooglePlace !: boolean;

  autocomplete: any;   
  autocompleteLsr: any;
  flag: boolean = true;
  homePlaceChanged: boolean = false;
  @ViewChild("input_TaskSelLocField")
  public homeAddressSearchElementRef!: ElementRef;

  constructor(public modalRef: BsModalRef,
    private cdRef: ChangeDetectorRef,
    private scheduleService : ScheduleService,
    private auth : AuthenticationService,
    private ngZone: NgZone,
    private util: UtilServiceService,
    private errorUtil: ErrorUtil
    ) { 
    this.startLocationForm = new UntypedFormGroup({
      'location': new UntypedFormControl(null, [Validators.required]),
      'startTime': new UntypedFormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.options = this.util.getAutocompleteOptions();
    var startLocationCache = localStorage.getItem('start_location');
    var currentTime = new Date()
    // var startTime = moment(currentTime).tz(this.auth.getUserTimezone()).format('hh:mm A')
    // this.startLocation.startTime =new Date(startTime);
    if(startLocationCache){
      this.setGooglePlace = true;
      this.startLocation = JSON.parse(startLocationCache);
      this.startLocation.startTime =new Date(this.startLocation.startTime);
      setTimeout(() => {
        this.setGooglePlace = false
      }, 500);
    }
  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }


  manualSearch(){
    setTimeout(() =>{
        if(!this.homePlaceChanged){
          var geocoder = new google.maps.Geocoder;
          var address = this.startLocation.location;
          var self = this;
          geocoder.geocode({'address': address}, function(results: any, status: any) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                self.startLocation.location = results[0].formatted_address;
                self.startLocation.placeId = results[0].place_id;
                self.cdRef.detectChanges();                
              } else {
              }
            } else {
            }
          });
        }
    }, 1000);
  }

  initLisner() {
    if (this.autocompleteLsr)
      google.maps.event.removeListener(this.autocompleteLsr);
    this.autocompleteLsr = this.autocomplete.addListener("place_changed",() => {
        this.homePlaceChanged = true;
        this.startLocation.location = "";
        this.ngZone.run(() => {
          //get the place result
          let place = this.autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.startLocation.location = place.formatted_address;
          this.startLocation.placeId = place.place_id;
          this.cdRef.detectChanges();
        });
        setTimeout(() => {
          this.homePlaceChanged = false;
        }, 2000);
      }
    );
  }

  callGoogleAutocomplete() {
    //load Places Autocomplete(search time calling)
      if (this.autocomplete)
        google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = new google.maps.places.Autocomplete(
        this.homeAddressSearchElementRef.nativeElement,
        {
          // Specify only the fields you need
          fields: ['place_id', 'geometry', 'name', 'formatted_address','types'],
        }
      );
      this.initLisner();
   
  }

  getPlaceApiCall(event: any) {
    let field: any = document.getElementById("input_TaskSelLocField");
    let val = event.target.value;
    if (val.length < 3) {
      google.maps.event.removeListener(this.autocompleteLsr);
      google.maps.event.clearInstanceListeners(field);
      if (this.autocomplete)
        google.maps.event.clearInstanceListeners(this.autocomplete);
      $(".pac-container").remove();
      this.flag = true;
    } else if (this.flag) {
      this.callGoogleAutocomplete();
      this.flag = false;
    }
  }

  onSubmit(){
 
    if(!this.startLocationForm.valid){
      for (var i in this.startLocationForm.controls) {
        console.log( this.startLocationForm.controls[i])
        this.startLocationForm.controls[i].markAsTouched();
      }
      return false;
    }

    if(!this.startLocation.placeId){
      this.errorUtil.setErrorMessage(400, null, "Invalid Start Location. Please select a start location from the autocomplete list before trying to optimize","error",3000);
      return false;
    }
    
    if(this.startLocation.startTime){
      var startTime = this.startLocation.startTime;
      console.log(startTime,"====",moment(this.startLocation.startTimedayStart).format("hh:mm a"))
      //var timeSplit = this.startLocation.startTime.split(' ');
      // if(timeSplit[1] == 'AM'){
      //   var timeSplit2 = timeSplit[0].split(':');
      //   if(timeSplit2[0] < 10 && timeSplit2[0].length < 2){
      //     timeSplit2[0] = '0'+timeSplit2[0];
      //   }
      //   this.startLocation.startTime = timeSplit2[0]+':'+timeSplit2[1];
      // } else {
      //   var timeSplit2 = timeSplit[0].split(':');
      //   var time = parseInt(timeSplit2[0]);
      //   if(time < 10){
      //     time = time + 12;
      //   }
      //   // if(time < 10 && timeSplit2[0].length < 2){
      //   //   timeSplit2[0] = '0'+timeSplit2[0];
      //   // }
      //   this.startLocation.startTime = time+':'+timeSplit2[1];
      // }
    }
    this.modalRef.hide();
    localStorage.setItem('start_location', JSON.stringify({"location":this.startLocation.location,"startTime":startTime, "placeId" : this.startLocation.placeId}));
    this.scheduleService.sendModalDataToParent(this.startLocation);
  }

  hideModal(){
    this.modalRef.hide();
    this.scheduleService.cancelModal();
  }

  setStartLocation(val: any){
    //console.log("Start ", this.startLocation);
    var geocoder = new google.maps.Geocoder;
    var self = this;
    if(val == 'current'){
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          //console.log(position);
          var latlng = {lat: latitude, lng: longitude};
          geocoder.geocode({'location': latlng}, (results: any, status:any) => {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                results = _.remove(results, function(n : any) {
                  return n.geometry.location_type === 'APPROXIMATE';
                });
                this.setGooglePlace = true;
                this.startLocation.location = results[0].formatted_address;
                this.startLocation.placeId = results[0].place_id;
                setTimeout(() => {
                  this.setGooglePlace = false;
                }, 500);
              } else {
                //console.log('No results found');
              }
            } else {
              //console.log('Geocoder failed due to: ' + status);
            }
          });
        });
      }
    } else if(val == 'home'){
      var homeAddress : any = this.auth.getHomeAddress();
      if(homeAddress){
        if(homeAddress.placeId){
          this.setGooglePlace = true;
          this.startLocation.location = homeAddress.address;
          this.startLocation.placeId = homeAddress.placeId;
          setTimeout(() => {
            this.setGooglePlace = false;
          }, 500);
        } else {
          this.errorUtil.setErrorMessage(400, null, "Invalid home address. Please use the search to find the address","warn",3000);
     }

      } else {
        this.errorUtil.setErrorMessage(400, null, "Please set your Home Address in your profile","warn",3000);
        }
    } else if(val == 'office'){
      var officeAddress : any = this.auth.getOfficeAddress();
      if(officeAddress){
        if(officeAddress.placeId){
          this.setGooglePlace = true;
          this.startLocation.location = officeAddress.address;
          this.startLocation.placeId = officeAddress.placeId;
          setTimeout(() => {
            this.setGooglePlace = false;
          }, 500);
        } else {
          this.errorUtil.setErrorMessage(400, null, "Invalid office address. Please use the search to find the address","warn",3000);
         }

      
      } else {
        this.errorUtil.setErrorMessage(400, null, "Please set your Office Address in your profile","warn",3000);
       }
    }
  }

  onSwitchChange(val: boolean) {
    this.startLocation.traffic = val;
    //console.log(this.startLocation.traffic);
  }

}

