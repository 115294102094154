<div *ngIf="noRoutes" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
        <p class="text-6 my-1 font-weight-bold" translate>
            {{ "no_records_found" }}
        </p>
    </div>
</div>

<!-- TabMenu -->
<div [hidden]="noRoutes" class="position-relative fw-normal" style="height: 100%;">
    <div class="table-space ag-grid-table-full h-100" [hidden]="noRoutes">
        <ag-grid-angular
            #agGrid
            style="height: 100%;"
            class="ag-theme-balham"
            [cacheOverflowSize]="2"
            [suppressDragLeaveHidesColumns]="true"
            [maxConcurrentDatasourceRequests]="-1"

            [gridOptions]="gridOptionsRoutes"
            [columnDefs]="columnDefsRoutes"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            (cellClicked)="openRoute($event)"
            (gridReady)="onGridReadyRoutes($event)"
            [getContextMenuItems]="getContextMenuItems"
        ></ag-grid-angular>

        <div class="col-12">
            <p class="ag-record-row-summary-panel padds text-end" id="label_VehTripTableRec">
                {{ totalRecord }} {{ "records" | translate }}
            </p>
        </div>
    </div>
</div>
