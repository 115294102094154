import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { PlanUpdateConfirmationComponent } from '../plan-update-confirmation/plan-update-confirmation.component';

@Component({
  selector: 'app-modify-breakdown',
  templateUrl: './modify-breakdown.component.html'
})
export class ModifyBreakdownComponent implements OnInit {
  title:any;
  data: any;
  vehicleId: any;
  note: any;
  relatedVehicleEvents: any;
  orgResources: any;
  dragableDataList: any;
  modalState : any;
  selectedConfig : any;
  filteredRoutes: any;
  selectedComponent : any;
  breakDownForm!: UntypedFormGroup;
  orderStatuses: any = [];
  vehicles: any = [];
  vehiclesData: any = []
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };

  constructor(
    public modalRef: BsModalRef,
    private planningHubService: PlanningHubService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    private modal: ModalServiceService,
    private errorUtil: ErrorUtil,
    private auth: AuthenticationService)
     { 
      this.breakDownForm = new UntypedFormGroup({
        'vehicle': new UntypedFormControl(null, [Validators.required])
      })
    }

  ngOnInit(): void {
    console.log('dataaaa',this.data)
    this.filteredRoutes = this.data.routes.filter((item : any) => (!item.locked));
    for(var i=0;i<=this.data.routes.length-1;i++){
      if(!this.data.routes[i].locked){
        this.vehicles.push(this.data.routes[i].vehicleData);
        this.vehiclesData.push(this.data.routes[i].vehicleData.vehicle);
      }
    }
    this.loadData();
  }
  loadData(){
    var resource: { id: any; vehicleNumber: any; vehicleName: any; capacity: any; distance: any; duration: any; vehicleImage: any; relatedVehicleEvents: null; vehicleDepot: any; }[] = [];
    var events = [];
    var relatedEvents: { type: any; startTime: string; endTime: string; orderNo: any; orderNumber: string; orderStatus: any; orderId: any; orderCustomer: null; quantity: any; orderEvent: boolean; note: null; }[] = [];
    this.data.routes.forEach((routes : any) => {
      ////////debugger
      var resource1 = {
        id: routes.vehicleData.vehicle.id,
        vehicleNumber: routes.vehicleData.vehicle.vehicleNumber,
        vehicleName: routes.vehicleData.vehicle.name,
        capacity: routes.statistic.capacity,
        distance: routes.statistic.distance,
        duration:routes.statistic.duration,
        vehicleImage : routes.vehicleData.vehicle.vehicleImage ? routes.vehicleData.vehicle.vehicleImage.attachmentUrl : '',
        relatedVehicleEvents: null,
        vehicleDepot : routes.vehicleData.vehicle.depot
      }
      routes.trips.forEach((trips : any) => {
        if(trips.type!="departure" && trips.type!="arrival"){
        var events1 = {
          type: trips.type,
          startTime : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('hh:mm A'),
          endTime : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('hh:mm A'),
          orderNo: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.orderNumber : "",
          orderNumber : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? (trips.order.orderNumber +'-'+ moment(trips.order.orderDateTime).format('DDMM')) : "",
          orderStatus : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? (trips.order.orderStatus) : "",
          orderId : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.id:'',
          orderCustomer : null,
          quantity: (trips.type!="break") ? trips.quantity : '',
          orderEvent : false,
          note: null,
        }
        this.data.assignedOrders.forEach((assignedOrdersData : any) => {
          if(assignedOrdersData.deliveryOrder.orderNumber === events1.orderNo){
            assignedOrdersData.deliveryOrderLineItemVMs.forEach((element : any) => {
              this.note = [];
              // element.deleveryOrderLineItemPackages.forEach((pack : any) => {
              //   this.note.push('('+pack.quantity+')' + '-' + pack.name );
              // })
              events1.note = this.note;
            })
          }
        })
        routes.deliveryOrders.forEach((order : any) => {
          if(order.deliveryOrderLite.id === events1.orderId){
            events1.orderCustomer = order.deliveryOrderLite.customer ? order.deliveryOrderLite.customer.name : null;
          }
        })
        events.push(events1);
        relatedEvents.push(events1);
      }
      })
      this.relatedVehicleEvents = relatedEvents;
      relatedEvents = [];
      resource1.relatedVehicleEvents = this.relatedVehicleEvents;
      resource.push(resource1);
    })
    this.orgResources = resource;
  }

  filterResults2(){
    for(var i=0;i<=this.orgResources.length-1;i++){
      if(this.orgResources[i].id == this.vehicleId){
        this.dragableDataList = _.cloneDeep(this.orgResources[i]);
      }
    }
    console.log(this.dragableDataList)
    //debugger
    for(var j=0;j<=this.dragableDataList.relatedVehicleEvents.length-1;j++){
      var index = _.findIndex(this.orderStatuses, (row:any) => { //check customer duplicates
        return row == this.dragableDataList.relatedVehicleEvents[j].orderStatus;
      });
      if(index <= -1) {
        this.orderStatuses.push(this.dragableDataList.relatedVehicleEvents[j].orderStatus);
      }
    }
  }

  proceed(){
    // if(!this.vehicleId){
    //   this.toaster.warn('', 'select vehicle');
    // }
    if(!this.breakDownForm.valid){
      for (var i in this.breakDownForm.controls) {
        this.breakDownForm.controls[i].markAsTouched();
      }
      return false;
    }
    else{
      this.openModal('breakdown','');
    }
  }

  openModal(modalView: any, data: any) {
    this.modalRef.hide();
      if (modalView == "breakdown") {
      var data1 = {btnName:'breakdown',planId:this.data.draftPlanId,vehicleId:this.vehicleId,vehicleNumber:this.dragableDataList.vehicleNumber,vehicleName:this.dragableDataList.vehicleName,vehicleDepot:this.dragableDataList.vehicleDepot,orderStatuses:this.orderStatuses}
      this.modalState = {
        data: data1,
        title:'Breakdown'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    }
    else{
      return;
    }
    this.selectedConfig.initialState = this.modalState;
    return this.modalRef = this.modalService.show(this.selectedComponent, this.selectedConfig);
  }

}
