import { AfterViewInit, Component, DoCheck, IterableDiffer, IterableDiffers, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { ModalServiceService } from 'src/app/_services/modal-service.service';
import { ScheduleService } from 'src/app/_services/schedule.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { CalendarComponent } from 'src/app/shared/custella-fullcalendar/calendar.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OptimizeDistanceComponent } from '../optimize-distance/optimize-distance.component';
import { StartLocationComponent } from '../start-location/start-location.component';

@Component({
  selector: 'app-task-optimize',
  templateUrl: './task-optimize.component.html'
})
export class TaskOptimizeComponent implements  OnInit, OnDestroy, DoCheck {

  firstRender : Boolean = true;
  watch: any;
  changesMade2: boolean = false;
  routeOptimized: boolean = false;
  currentDay: any;
  eventsLoaded: boolean = false;
  changesMade: boolean = false;
  differ!: IterableDiffer<{}>;
  objDiffer: {} | undefined;
  googleArray: any;
  highTasks!: any[];
  endBuffer: any;
  startBuffer: any;
  buffer!: string;
  filteredTasks!: any[];
  notFixedTasks: any;
  sortEndLocation!: string;
  sortStartLocation!: string;
  fixedTasks!: any[];
  refresher2: any;
  finalTasks: any = [];
  tempArray!: any[];
  updatedArray : any[] = [];
  excludeIndexes: any = [];
  refresher: any;
  sortType: any;
  captureData: any;
  index!: number;
  findItemIndex!: number;
  startLocation : any = {};
  startTime: any;
  originsArray : any[] = [];
  destinationsArray : any[] = [];
  orginalEventsArray: any[] = [];
  alteredEventsArray : any[] = [];
  distanceSortedArray : any;
  modalState: any = {};
  date: any;
  @ViewChild(CalendarComponent) ucCalendar!: CalendarComponent;
  events: any = [];

   configMd:any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard : false,
    class: 'modal-sm'
  };
  configLg:any = {
    backdrop: true,
    ignoreBackdropClick: false,
    class: 'modal-lg poi-modal'
  };
  modalRef! : BsModalRef;
  showBack : boolean = false;
 
calendarOptionsAgendaDay: any
  constructor(
    private modal: ModalServiceService,
    private auth: AuthenticationService,
    private utilService : UtilServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private scheduleService: ScheduleService,
    private taskService : TasksService,
    private spinner: NgxSpinnerService,
    private differs: IterableDiffers,
    private errorUtil: ErrorUtil,
    private modalService: BsModalService
    ) { 
      this.captureData = this.scheduleService.passData$.subscribe((obj : any) => {
        if(obj == 'modalClosed'){
          this.sortType = 'Time'
          return;
        }
        this.startLocation = obj;
        // //debugger
        if(this.sortType == 'Distance'){
          this.events = this.orginalEventsArray.map(x => Object.assign({}, x));
          this.events = _.remove(this.events, (o:any) => {
            return o.type !== 'startBuffer' && o.type !== 'endBuffer';
          })
          this.sortScheduleByDistanceNew();
        } else if(this.sortType == 'priorityNDistance'){
          this.events = this.orginalEventsArray.map(x => Object.assign({}, x));
          this.events = _.remove(this.events, (o:any) => {
            return o.type !== 'startBuffer' && o.type !== 'endBuffer';
          })
          this.sortScheduleByPriorityAndDistanceNew();
        }
      });
  
      this.refresher = this.taskService.refreshList$.subscribe((obj : any) => {
        this.loadTasks(this.date);
      });
  
      this.refresher2 = this.taskService.PoiTask$.subscribe((obj : any) => {
        this.addTask(obj);
      });
  
      this.differ = differs.find([]).create();

    }

  ngOnInit(): void {
    this.currentDay = moment().endOf('day')
    var userBuffer: any = this.auth.getUserBuffer()
    var buffer = JSON.parse(userBuffer);
    this.startBuffer = buffer.start;
    this.endBuffer = buffer.end;

    var windowHeight : any = '855px';
    windowHeight = windowHeight.split('px');
    windowHeight = parseFloat(windowHeight[0])-2;

    this.calendarOptionsAgendaDay = {
      schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
       editable: true,
       eventLimit: false,
       defaultView: 'agendaDay',
       allDaySlot : false,
       scrollTime : '09:00:00',
       header: false,
      height: windowHeight,
      events: this.events,
      snapDuration : '00:05:00'
    };
  }
  ngOnDestroy(){
    this.captureData.unsubscribe();
    this.refresher.unsubscribe();
    this.refresher2.unsubscribe();
  }
  ngDoCheck() {
    const change = this.differ.diff(this.events);
    if(change != null && this.eventsLoaded){
      console.log("changes made ", change);
      this.changesMade = true;
    }
  }
  

  viewRender(event: any) {
    this.spinner.show();
    event.detail.element.find('.fc-day-header').html('');
    this.sortType = 'Time';
    this.events = [];
    this.date = event.detail.view.start.format('YYYY-MM-DD');
    console.log("View Rendered Date", this.date);
    setTimeout(() =>{
      this.spinner.hide();
     // if(!this.firstRender){
        this.loadTasks(this.date);
      //}
    }, 1000);
  }

  eventRender( event: any, element: any ) {
    console.log(event,"---")
    var elementExists = element[0].getElementsByClassName("fc-title");
    var elementTimeExists = element[0].getElementsByClassName("fc-time");
    if(elementExists.length){
      if(event.location){
        elementExists[0].insertAdjacentHTML('beforeend', '<div style="font-size: 12px;font-weight: 100;" class="text-truncate pr-2">'+moment.tz(event.startDateTime, this.auth.getUserTimezone()).format('hh:mm a')+ ' - '+moment.tz(event.endDateTime, this.auth.getUserTimezone()).format('hh:mm a')+'</div>');
        if(event.locationName){
          elementExists[0].insertAdjacentHTML('beforeend', '<div style="font-size: 12px;font-weight: 100;" class="text-truncate pr-2">'+event.locationName + ', ' + event.location+'</div>');
        } else {
          elementExists[0].insertAdjacentHTML('beforeend', '<div style="font-size: 12px;font-weight: 100;" class="text-truncate pr-2">'+event.location+'</div>');
        }
      }
      if(event.type == 'startBuffer' || event.type == 'endBuffer'){
        elementExists[0].classList.add('text-center', 'col');
        elementExists[0].insertAdjacentHTML('beforeend', '<div style="font-size: 12px;font-weight: 100;color:black"><i class="fas fa-walking"></i></div>');
      }

      // if(event.weatherIcon){
      //   var skillExists = element[0].getElementsByClassName("skill-container");
      //   if(skillExists.length){
      //     skillExists[0].insertAdjacentHTML('beforeend', '<div title="'+ event.weatherDesc + ' ' + event.temperature.toFixed(1) +'&#x2103" class="weather-container" style="position: absolute;left: -30px;top: 0px"><img style="width: 25px;padding-right: 5px;" src="'+event.weatherIcon+'"></div>');
      //   } else{
      //     elementExists[0].insertAdjacentHTML('beforeend', '<div title="'+ event.weatherDesc + ' ' + event.temperature.toFixed(1) +'&#x2103" class="weather-container" style="position: absolute;right: 1%;top: 0px"><img style="width: 25px;padding-right: 5px;" src="'+event.weatherIcon+'"></div>');
      //   }
      // }

      // if(event.fixed){
      //   var weatherExists = element[0].getElementsByClassName("weather-container");
      //   if(weatherExists.length){
      //     weatherExists[0].insertAdjacentHTML('beforeend', '<div title="Fixed Time" class="fixed-container" style="position: absolute;left: -25px;top: 0px"><img style="width: 15px;padding-right: 5px;" src="../../../../assets/images/skillsIcons/lockedTask.png"></div>');
      //   } else{
      //     elementExists[0].insertAdjacentHTML('beforeend', '<div title="Fixed Time" class="fixed-container" style="position: absolute;right: 1%;top: 0px"><img style="width: 15px;padding-right: 5px;" src="../../../../assets/images/skillsIcons/lockedTask.png"></div>');
      //   }
      // }
      elementExists[0].parentNode.removeChild(elementTimeExists[0]);
    }
  }

  loadTasks(date: any){
    this.spinner.show();
    this.changesMade = false;
    this.eventsLoaded = false;
    this.sortType = 'Time';
    this.taskService.getTodaysTasks(this.auth.getUserId(), date, this.auth.getUserTimezone()).subscribe((res:any) => {
      this.events = [];
      res.forEach((event: any) => {
        if(event.assignStatus == 'ACCEPTED'){
          if(!event.hasBuffer){
            event.startBuffer = this.startBuffer;
            event.endBuffer = this.endBuffer;
          }
          var startTime = moment(event.startDateTime);
          var endTime = moment(event.endDateTime);
          event.difference = this.utilService.durationFormatter(event.startDateTime, event.endDateTime);
          event.title = event.taskName;
          event.start = startTime.tz(this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
          event.end = endTime.tz(this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
          event.textColor = '#ffffff';
          event.borderColor  = "#ffffff";
          if(event.fixed){
            event.editable = false;
          }
          
          if(event.priority == 'Low'){
            event.color = '#6FBC60';
            event.textColor = '#ffffff';
          }  else if(event.priority == 'Medium'){
            event.color = '#E88901';
            event.textColor = '#ffffff';
          }  else if(event.priority == 'High'){
            event.color = '#D34747';
          } else {
            event.color = '#887C9A';
          }
          this.events.push(event);
        }
      });
      this.orginalEventsArray = this.events.map((x: any) => Object.assign({}, x));
      console.log("Tasks ", this.events);

    
      if(this.events.length){
        this.spinner.hide();
        this.addBufferForTasks(this.events);
       // this.getWeatherForecastForTask(this.events, 0);
      } else {
        this.spinner.hide();
      }
    });
  }

  addTask(event: any){
    event.difference = this.utilService.durationFormatter(event.startDateTime, event.endDateTime);
    event.title = event.taskName;
    event.start = moment.tz(event.startDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
    event.end = moment.tz(event.endDateTime, this.auth.getUserTimezone()).format('YYYY-MM-DD HH:mm');
    event.textColor = '#ffffff';
    if(event.fixed){
      event.editable = false;
      event.color = '#D66F9A';
    } else {
      event.color = '#6B6179';
    }
    this.events.push(event);
    //console.log("Tasks ", this.events);
    this.refreshCalendar(this.events);
  }

  viewMap(){
    if(!this.changesMade)
      this.router.navigate(['/dashboard/tasks/map'], { queryParams: {'teamView' : false, 'date' : this.date}});
    else
    this.errorUtil.setErrorMessage(  200, null, "Changes have been made to your schedule. Please save or discard the changes before viewing the map", "warn",3000);
  }

  saveTasksButton(){
    if(this.events.length){
      var req: any = {};
      var reqArray: any[] = [];
      this.events.forEach((event: any) => {
        if(event.type !== 'startBuffer' && event.type !== 'endBuffer'){
          var formData = 
          {
            id : event.id,
            startDateTime : moment.tz(event.startDateTime, this.auth.getUserTimezone()),
            endDateTime : moment.tz(event.endDateTime, this.auth.getUserTimezone()),
            nextTaskDistance : event.nextTaskDistance,
            nextTaskTravelTime : event.nextTaskTravelTime
          }
          reqArray.push(formData);
        }
      });
      req.tasks = reqArray;
      this.taskService.bulkEditTask(req).subscribe((res:any) => {
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          if(this.routeOptimized){
            var req = {
              routeDate : this.date,
              isOptimized : true,
              user : {
                id : this.auth.getUserId()
              }
            }
            this.taskService.createRouteOptimization(req).subscribe((res:any)=>{
              //console.log("Response ", res);
            });
          } else {
            var req = {
              routeDate : this.date,
              isOptimized : false,
              user : {
                id : this.auth.getUserId()
              }
            }
            this.taskService.createRouteOptimization(req).subscribe((res:any)=>{
              //console.log("Response ", res);
            });
          }
        //  this.modalRef.hide();
        this.errorUtil.setErrorMessage(  200, null, "Task(s) updated successfully", "success",3000);
          this.changesMade = false;
        }
      });
    }
  }

  

  eventDrop(event: any){
    //this.watch(this.events, (prop: any, action: any, newvalue: any, oldvalue: any) => {
      this.changesMade = true;
  //  });
    var tempEvent = event.detail.event;
    var matchItem = _.filter(this.events, function(o) { 
      return tempEvent.id == o.id; 
    });
    if(matchItem){
      matchItem[0].start = tempEvent.start.format('YYYY-MM-DD HH:mm');
      matchItem[0].end = tempEvent.end.format('YYYY-MM-DD HH:mm');
      matchItem[0].startDateTime = moment.tz(matchItem[0].start, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
      matchItem[0].endDateTime = moment.tz(matchItem[0].end, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
     // this.getWeatherForecastForTask([matchItem[0]], 0);
      this.reArrangeBuffer(matchItem[0]);
      this.routeOptimized = false;
       this.refreshCalendar(this.events);
    }
    //console.log("Event Drop Time ", tempEvent.start.format('YYYY-MM-DD HH:mm'), tempEvent.end.format('YYYY-MM-DD HH:mm'), matchItem[0]);
  }

  eventResize(event: any){
    console.log(event,"=====")
    //this.watch(this.events, (prop: any, action: any, newvalue: any, oldvalue: any) => {
      this.changesMade = true;
   // });
    var tempEvent = event.detail.event;
    var matchItem = _.filter(this.events, function(o) { 
      return tempEvent.id == o.id; 
    });
    console.log(matchItem,"=====",tempEvent.start)
    if(matchItem){
      matchItem[0].start = moment(tempEvent.start).format('YYYY-MM-DD HH:mm');
      matchItem[0].end = moment(tempEvent.end).format('YYYY-MM-DD HH:mm');
      matchItem[0].startDateTime = moment.tz(matchItem[0].start, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
      matchItem[0].endDateTime = moment.tz(matchItem[0].end, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
      this.reArrangeBuffer(matchItem[0]);
      this.routeOptimized = false;
      // this.getWeatherForecastForTask([matchItem[0]], 0);
      this.refreshCalendar(this.events);
    }
    //console.log("Event Resize Time ", tempEvent.start.format('YYYY-MM-DD HH:mm'), tempEvent.end.format('YYYY-MM-DD HH:mm'), matchItem[0]);
  }

  eventClick(event: any) {
    //console.log("Event Clicked ", event);
    var tempEvent = event.detail.event;
    if(!this.changesMade){
      if(tempEvent.type != 'startBuffer' && tempEvent.type != 'endBuffer' && tempEvent.taskType != 'PITSTOP'){
        var matchItem = _.filter(this.events, function(o) { 
          return tempEvent.id == o.id; 
        });
        // this.modalState = {
        //   data : matchItem[0]
        // };
        // this.configMd.initialState = this.modalState;
        // this.modalRef = this.modalService.show(QuickTaskDetailsComponent, this.configMd);
        this.viewTaskDetail(matchItem[0]);

      }
    } else {
      this.errorUtil.setErrorMessage(  200, null, "Changes have been made to your schedule. Please save or discard them before proceeding", "warn",3000);
    }
  }

  viewTaskDetail(data: any){
    // this.router.navigate(["/ddashboard/tasks/view"], { queryParams: {id: data.id}});
    this.utilService.openTaskVIewPage(data.id)
  }

  

  findClosestTime( startDate: any, tasks: any ) {
    var startTime = +startDate;
    var nearestTask, nearestDiff = Infinity;
    for( var i = 0, n = tasks.length;  i < n;  ++i ) {
      var tempDate: any;
      tempDate = parseInt(moment.utc(tasks[i].start).format('x'));
      var diff = tempDate - startTime;
      if(diff > 0 && diff < nearestDiff ) {
          nearestDiff = diff;
          nearestTask = tasks[i];
      }
    }
    return nearestTask;
  }  

  onChange(val: any){
    this.sortType = val;
    this.distanceSortedArray = [];
    var updateLocationsArray: any[] = [];
    var events = [];
    //console.log("Changed sort ", val);
    if(val == 'Time'){
      this.loadTasks(this.date);
    } else if(val == 'Distance'){
      events = _.filter(this.events, (o) => {
        return o.type !== 'startBuffer' && o.type !== 'endBuffer';
      });
      events.forEach(element => {
        if(!element.placeId){
          updateLocationsArray.push(element);
        }
      });
      if(updateLocationsArray.length > 0){
        this.modalState = {
          title: 'Update Location of the Following Tasks',
          data : updateLocationsArray
        };
        this.configMd.initialState = this.modalState;

        this.modalRef = this.modalService.show(OptimizeDistanceComponent, this.configMd);
        return;
      }

      this.modalState = {
        title : 'Select start location and time',
        mode : 'add'
      };
      this.configMd.initialState = this.modalState;
      this.modalRef = this.modalService.show(StartLocationComponent, this.configMd);
    } else if(val == 'priorityNDistance'){
      events = _.filter(this.events, (o) => {
        return o.type !== 'startBuffer' && o.type !== 'endBuffer';
      });
      events.forEach(element => {
        if(!element.placeId){
          updateLocationsArray.push(element);
        }
      });
      if(updateLocationsArray.length > 0){
        this.modalState = {
          title: 'Update Location of the Following Tasks',
          data : updateLocationsArray
        };
        this.configMd.initialState = this.modalState;

        this.modalRef = this.modalService.show(OptimizeDistanceComponent, this.configMd);
        return;
      }

      this.modalState = {
        title : 'Select start location and time',
        mode : 'add'
      };
      this.configMd.initialState = this.modalState;
      this.modalRef = this.modalService.show(StartLocationComponent, this.configMd);
    }
  }

  reArrangeBuffer(event: any){
    var matchingStartBuffer = this.events.filter((e: any) => {
      return e.bufferFor == event.id && e.type == 'startBuffer';
    });
    var matchingEndBuffer = this.events.filter((e: any) => {
      return e.bufferFor == event.id && e.type == 'endBuffer';
    });
    if(matchingStartBuffer.length){
      var startBufferStartTime = moment(event.start).subtract(event.startBuffer, 'm').format('YYYY-MM-DD HH:mm');
      var startBufferEndTime = event.start;
      matchingStartBuffer[0].start = startBufferStartTime;
      matchingStartBuffer[0].end = startBufferEndTime;
    }
    
    if(matchingEndBuffer.length){
      var endBufferStartTime = event.end;
      var endBufferEndTime = moment(event.end).add(event.endBuffer, 'm').format('YYYY-MM-DD HH:mm');
      matchingEndBuffer[0].start = endBufferStartTime;
      matchingEndBuffer[0].end = endBufferEndTime;
    }
  }

  addBufferForTasks(events: any){
   events.forEach((event: any) => {
      var startTime = moment(event.start).subtract(event.startBuffer, 'm').format('YYYY-MM-DD HH:mm');
      var endTime = moment(event.end).add(event.endBuffer, 'm').format('YYYY-MM-DD HH:mm');
      var startBuffer : any = {
        start : startTime,
        end : event.start,
        textColor : '#ffffff',
        borderColor : "#ffffff",
        editable : false,
        type : 'startBuffer',
        title : ' ',
        bufferFor : event.id
      }
      var endBuffer : any = {
        start : event.end,
        end : endTime,
        textColor : '#ffffff',
        borderColor : "#ffffff",
        editable : false,
        type : 'endBuffer',
        title : ' ',
        bufferFor : event.id
      }
     
      startBuffer.color = '#8faabf80';
      endBuffer.color = '#8faabf80';
      
      if(event.startBuffer !== 0){
        this.events.push(startBuffer);
      }

      if(event.endBuffer !== 0){
        this.events.push(endBuffer);
      }
      this.refreshCalendar(this.events);
    });
  }

  sortScheduleByPriorityAndDistanceNew(){
    this.spinner.show();
    this.filteredTasks = [];
    this.excludeIndexes = [];
    this.finalTasks = [];
    var highTasks = _.filter(this.events, (o) => {
      return o.priority == 'High';
    });
    this.fixedTasks = _.filter(this.events, function(o:any) {
      return o.fixed;
    });
    this.tempArray = highTasks;
    //this.watch(this.events, (prop: any, action: any, newvalue: any, oldvalue: any) => {
      this.changesMade = true;
   // });
    this.getSortedTasksV5(this.tempArray, 0, 'High');
    this.routeOptimized = true;
  }

  getSortedTasksV5(events: any, index: any, priority: any){
    var self = this;
    if(this.excludeIndexes.length == this.events.length){
      this.addBufferForTasks(this.events);
      console.log(this.events)
      // this.refreshCalendar(this.events);
       this.spinner.hide();
      return;
    }
    if(index == 0){
      if(priority == 'High'){
        this.notFixedTasks = _.filter(events, (o) => {
          return o.priority == 'High' && !o.fixed;
        });
      } else if(priority == 'Medium'){
        this.notFixedTasks = _.filter(events, (o) => {
          return o.priority == 'Medium' && !o.fixed;
        });
      } else if(priority == 'Low'){
        this.notFixedTasks = _.filter(events, (o) => {
          return o.priority == 'Low' && !o.fixed;
        });
      }
    }
    // //debugger
    if(!this.notFixedTasks.length){
      this.notFixedTasks = [];
      if(index == 0){
        index = 0;
      } else {
        index = index+1;
      }
      if(priority == 'High'){
        this.notFixedTasks = _.filter(this.events, (o) => {
          return o.priority == 'Medium' && !o.fixed;
        });
        priority = 'Medium';
        // //debugger
        this.getSortedTasksV5(this.notFixedTasks, index, priority);
        return;
      } else if(priority == 'Medium'){
        this.notFixedTasks = _.filter(this.events, (o) => {
          return o.priority == 'Low' && !o.fixed;
        });
        priority = 'Low';
        // //debugger
        this.getSortedTasksV5(this.notFixedTasks, index, priority);
        return;
      } else if(priority == 'Low'){
        this.notFixedTasks = _.filter(this.events, (o) => {
          return o.priority == null && !o.fixed;
        });
        priority = null;
        this.getSortedTasksV5(this.notFixedTasks, index, priority);
        return;
      }
    }

    if(this.notFixedTasks.length && this.fixedTasks.length){
      if(index == 0){
        this.googleArray = this.notFixedTasks.map((x: any) => Object.assign({}, x));
        this.fixedTasks.forEach(e => {
          this.googleArray.push(e);
        });
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.startLocation, this.googleArray, this.startLocation.traffic, moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          if(res == 'INVALID_REQUEST'){
            this.spinner.hide();
            if(moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).isBefore(moment())){
              this.errorUtil.setErrorMessage(  200, null, "Traffic information is only available for future and current times", "warn",3000);
            }
            return
          }
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o: any) => {
            var filter = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment(moment(matchFound[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime);
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }
          var lockedTaskGoogleResp = this.getGoogleFilteredResponseNew2(closestSortedArr[0].originPlaceId, this.fixedTasks[0].placeId, res.array);
          if(lockedTaskGoogleResp){
            var canFit = this.fitTaskNew(finalEndTime, lockedTaskGoogleResp[0].durationValue, this.fixedTasks[0].startDateTime, matchFound[0].startBuffer, matchFound[0].endBuffer);
            if(canFit){
              events[matchingIndex].start = finalStartTime;
              events[matchingIndex].end = finalEndTime;
              events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              this.excludeIndexes.push(events[matchingIndex].id);
              var matchingEventIndex = _.findIndex(this.events, function(o : any){
                return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
              });
              this.events[matchingEventIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
              this.events[matchingEventIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
              var temp = {
                id: events[matchingIndex].id,
                location: events[matchingIndex].location
              }
              this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
                return o.id != events[matchingIndex].id
              });
            }  else {
              var matchingIndex = _.findIndex(this.googleArray, function(o: any) { return  o.id == self.fixedTasks[0].id });
              this.excludeIndexes.push(this.googleArray[matchingIndex].id);
              var matchingEventIndex = _.findIndex(this.events, function(o : any){
                return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
              });
              var closestSortedArr = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, this.googleArray[matchingIndex].placeId, res.array);
              this.events[matchingEventIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
              this.events[matchingEventIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
              var temp = {
                id: this.googleArray[matchingIndex].id,
                location: this.googleArray[matchingIndex].location
              }
              this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
                return o.id != self.googleArray[matchingIndex].id
              });
            }
            this.filteredTasks.push(temp);
          }
          this.getSortedTasksV5(this.notFixedTasks, index+1, priority);
        });
      } else {
        var findLastEvent = _.filter(this.events, function(o){
          return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
        });
        this.googleArray = this.notFixedTasks.map((x: any) => Object.assign({}, x));
        this.fixedTasks.forEach(e => {
          this.googleArray.push(e);
        });
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(findLastEvent[0], this.googleArray, this.startLocation.traffic, moment(findLastEvent[0].endDateTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o: any) => {
            var filter = this.getGoogleFilteredResponseNew2(findLastEvent[0].placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          // //debugger;
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment.tz(findLastEvent[0].endDateTime, this.auth.getUserTimezone());
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60)+(findLastEvent[0].endBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }
          var lockedTaskGoogleResp = this.getGoogleFilteredResponseNew2(closestSortedArr[0].originPlaceId, this.fixedTasks[0].placeId, res.array);
          if(lockedTaskGoogleResp){
            var canFit = this.fitTaskNew(finalEndTime, lockedTaskGoogleResp[0].durationValue, this.fixedTasks[0].startDateTime, matchFound[0].startBuffer, findLastEvent[0].endBuffer);
            if(canFit){    
              events[matchingIndex].start = finalStartTime;
              events[matchingIndex].end = finalEndTime;
              events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              this.excludeIndexes.push(events[matchingIndex].id);
              var matchingEventIndex = _.findIndex(this.events, function(o : any){
                return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
              });
              // //debugger
              this.events[matchingEventIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
              this.events[matchingEventIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
              var temp = {
                id: events[matchingIndex].id,
                location: events[matchingIndex].location
              }
              this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
                return o.id != events[matchingIndex].id
              });
            } else {
              var matchingIndex = _.findIndex(this.googleArray, function(o: any) { return  o.id == self.fixedTasks[0].id });
              this.excludeIndexes.push(this.googleArray[matchingIndex].id);
              var matchingEventIndex = _.findIndex(this.events, function(o : any){
                return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
              });
              // //debugger
              var closestSortedArr = this.getGoogleFilteredResponseNew2(findLastEvent[0].placeId, this.googleArray[matchingIndex].placeId, res.array);
              this.events[matchingEventIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
              this.events[matchingEventIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
              var temp = {
                id: this.googleArray[matchingIndex].id,
                location: this.googleArray[matchingIndex].location
              }
              this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
                return o.id != self.googleArray[matchingIndex].id
              });
            }
            this.filteredTasks.push(temp);
          }
          this.getSortedTasksV5(this.notFixedTasks, index+1, priority);
        });
      }
    } else if(!this.fixedTasks.length && this.notFixedTasks.length){
      if(index == 0){
        this.googleArray = this.notFixedTasks.map((x: any) => Object.assign({}, x));
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.startLocation, this.googleArray, this.startLocation.traffic, moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          if(res == 'INVALID_REQUEST'){
            this.spinner.hide();
            if(moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).isBefore(moment())){
              this.errorUtil.setErrorMessage(  200, null, "Traffic information is only available for future and current times", "warn",3000);
            }
            return
          }
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o: any) => {
            var filter = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment(moment(matchFound[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime);
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }   
          events[matchingIndex].start = finalStartTime;
          events[matchingIndex].end = finalEndTime;
          events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          this.excludeIndexes.push(events[matchingIndex].id);var matchingEventIndex = _.findIndex(this.events, function(o : any){
            return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
          });
          events[matchingIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
          events[matchingIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var temp = {
            id: events[matchingIndex].id,
            location: events[matchingIndex].location
          }
          this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
            return o.id != events[matchingIndex].id
          });
          this.filteredTasks.push(temp);
          this.getSortedTasksV5(this.notFixedTasks, index+1, priority);
        });
      } else {
        var findLastEvent = _.filter(this.events, function(o){
          return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
        });
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(findLastEvent[0], events, this.startLocation.traffic, moment(findLastEvent[0].endDateTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o: any) => {
            var filter = this.getGoogleFilteredResponseNew2(findLastEvent[0].placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          // //debugger
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment.tz(findLastEvent[0].endDateTime, this.auth.getUserTimezone());
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60)+(findLastEvent[0].endBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }  
          events[matchingIndex].start = finalStartTime;
          events[matchingIndex].end = finalEndTime;
          events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          this.excludeIndexes.push(events[matchingIndex].id);
          var matchingEventIndex = _.findIndex(this.events, function(o : any){
            return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
          });
          // //debugger
          this.events[matchingEventIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
          this.events[matchingEventIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var temp = {
            id: events[matchingIndex].id,
            location: events[matchingIndex].location
          }
          this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
            return o.id != events[matchingIndex].id
          });
          this.filteredTasks.push(temp);
          this.getSortedTasksV5(this.notFixedTasks, index+1, priority);
        });
      }
    } else if(!this.notFixedTasks.length && this.fixedTasks.length){
      var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, this.fixedTasks);
      var matchingIndex = _.findIndex(this.events, function(o: any) { return  o.id == self.fixedTasks[0].id });
      if(index !== 0){
        var matchingEventIndex = _.findIndex(this.events, function(o : any){
          return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
        });
        // //debugger
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.events[matchingEventIndex], this.fixedTasks, this.startLocation.traffic, moment(this.fixedTasks[0].startDateTime).format())
        .subscribe((res:any) => {
          this.excludeIndexes.push(this.fixedTasks[0].id);
          var closestSortedArr = this.getGoogleFilteredResponseNew2(this.events[matchingEventIndex].placeId, this.fixedTasks[0].placeId, res.array);
          this.events[matchingIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
          this.events[matchingIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var temp = {
            id: this.fixedTasks[0].id,
            location: this.fixedTasks[0].location
          }
          this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
            return o.id != self.fixedTasks[0].id
          });
          this.filteredTasks.push(temp);
          this.getSortedTasksV5(events, index+1, priority);
        });
      } else {
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.startLocation, this.fixedTasks, this.startLocation.traffic, moment(this.fixedTasks[0].startDateTime).format())
        .subscribe((res:any) => {
          var matchingEventIndex = _.findIndex(this.events, function(o : any){
            return o.id == self.excludeIndexes[self.excludeIndexes.length-1];
          });
          this.excludeIndexes.push(this.fixedTasks[0].id);
          var closestSortedArr = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, this.fixedTasks[0].placeId, res.array);
          this.events[matchingIndex].nextTaskDistance = closestSortedArr[0].distanceValue;
          this.events[matchingIndex].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var temp = {
            id: this.fixedTasks[0].id,
            location: this.fixedTasks[0].location
          }
          this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
            return o.id != self.fixedTasks[0].id
          });
          this.filteredTasks.push(temp);
          this.getSortedTasksV5(events, index+1, priority);
        });
      }
    }
  }

  sortScheduleByDistanceNew(){
    this.tempArray = [];
    this.fixedTasks = _.filter(this.events, function(o:any) {
      return o.fixed;
    });
    this.notFixedTasks = this.events.filter((w: any) => {
      return !w.fixed;
    });
    this.excludeIndexes = [];
    this.filteredTasks = [];
    this.watch(this.events, (prop: any, action: any, newvalue: any, oldvalue: any) => {
      this.changesMade = true;
    });
    this.spinner.show();
    this.getSortedTasksV4(this.events, 0);
    this.routeOptimized = true;
  }
  
  refreshCalendar(events: any){
    this.ucCalendar.fullCalendar('removeEvents');
    this.ucCalendar.fullCalendar('renderEvents', events);
    this.ucCalendar.fullCalendar('rerenderEvents');
  }

  getSortedTasksV4(events: any, index: any){
    var self = this;
    if(this.excludeIndexes.length == events.length){
      //this.getWeatherForecastForTask(this.events, 0);
      this.addBufferForTasks(events);
    }
    if(this.notFixedTasks.length && this.fixedTasks.length){
      if(index == 0){
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.startLocation, events, this.startLocation.traffic, moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          if(res == 'INVALID_REQUEST'){
            this.spinner.hide();
            if(moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).isBefore(moment())){
              
              this.errorUtil.setErrorMessage(  200, null, "Traffic information is only available for future and current times", "warn",3000);
            }
            return
          }
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o: any) => {
            var filter = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment(moment(matchFound[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime);
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }
          var lockedTaskGoogleResp = this.getGoogleFilteredResponseNew2(closestSortedArr[0].originPlaceId, this.fixedTasks[0].placeId, res.array);
          if(lockedTaskGoogleResp){
            var canFit = this.fitTaskNew(finalEndTime, lockedTaskGoogleResp[0].durationValue, this.fixedTasks[0].startDateTime, matchFound[0].startBuffer, matchFound[0].endBuffer);
            if(canFit){    
              events[matchingIndex].start = finalStartTime;
              events[matchingIndex].end = finalEndTime;
              events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
                return o.id != events[matchingIndex].id
              });
              this.excludeIndexes.push(matchingIndex);
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
            } else {
              var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == self.fixedTasks[0].id });
              this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
                return o.id != events[matchingIndex].id
              });
              this.excludeIndexes.push(matchingIndex);
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
            }
            var temp = {
              id: events[matchingIndex].id,
              location: events[matchingIndex].location
            }
            this.filteredTasks.push(temp);
          }
          this.getSortedTasksV4(events, index+1);
        });
      } else {
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(events[this.excludeIndexes[this.excludeIndexes.length-1]], events, this.startLocation.traffic, moment(events[this.excludeIndexes[this.excludeIndexes.length-1]].endDateTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o:any) => {
            var filter = this.getGoogleFilteredResponseNew2(events[this.excludeIndexes[this.excludeIndexes.length-1]].placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment.tz(events[this.excludeIndexes[this.excludeIndexes.length-1]].endDateTime, this.auth.getUserTimezone());
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60)+(events[this.excludeIndexes[this.excludeIndexes.length-1]].endBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }
          var lockedTaskGoogleResp = this.getGoogleFilteredResponseNew2(closestSortedArr[0].originPlaceId, this.fixedTasks[0].placeId, res.array);
          if(lockedTaskGoogleResp){
            var canFit = this.fitTaskNew(finalEndTime, lockedTaskGoogleResp[0].durationValue, this.fixedTasks[0].startDateTime, matchFound[0].startBuffer, events[this.excludeIndexes[this.excludeIndexes.length-1]].endBuffer);
            if(canFit){    
              events[matchingIndex].start = finalStartTime;
              events[matchingIndex].end = finalEndTime;
              events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
              this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
                return o.id != events[matchingIndex].id
              });
              this.excludeIndexes.push(matchingIndex);
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
            } else {
              var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == self.fixedTasks[0].id });
              this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
                return o.id != events[matchingIndex].id
              });
              var closestSortedArr = this.getGoogleFilteredResponseNew2(events[this.excludeIndexes[this.excludeIndexes.length-1]].placeId, events[matchingIndex].placeId, res.array);
              this.excludeIndexes.push(matchingIndex);
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
              events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
            }
            var temp = {
              id: events[matchingIndex].id,
              location: events[matchingIndex].location
            }
            this.filteredTasks.push(temp);
          }
          this.getSortedTasksV4(events, index+1);
        });
      }
    } else if(!this.fixedTasks.length && this.notFixedTasks.length){
      if(index == 0){
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.startLocation, events, this.startLocation.traffic, moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          if(res == 'INVALID_REQUEST'){
            this.spinner.hide();
            if(moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).isBefore(moment())){
              this.errorUtil.setErrorMessage(  200, null, "Traffic information is only available for future and current times", "warn",3000);
            }
            return
          }
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray : any[]= [];
          remainingTasks.forEach((o: { placeId: any; }) => {
            var filter = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment(moment(matchFound[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime);
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }   
          events[matchingIndex].start = finalStartTime;
          events[matchingIndex].end = finalEndTime;
          events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
            return o.id != events[matchingIndex].id
          });
          this.excludeIndexes.push(matchingIndex);
          events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
          events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var temp = {
            id: events[matchingIndex].id,
            location: events[matchingIndex].location
          }
          this.filteredTasks.push(temp);
          this.getSortedTasksV4(events, index+1);
        });
      } else {
        this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(events[this.excludeIndexes[this.excludeIndexes.length-1]], events, this.startLocation.traffic, moment(events[this.excludeIndexes[this.excludeIndexes.length-1]].endDateTime).format())
        .subscribe((res:any) => {
          //console.log(res);
          var remainingTasks : any = this.findRemainingTasks(this.filteredTasks, events);
          remainingTasks = _.remove(remainingTasks, function(o : any){
            return !o.fixed;
          });
          var tempArray: any[] = [];
          remainingTasks.forEach((o:any) => {
            var filter = this.getGoogleFilteredResponseNew2(events[this.excludeIndexes[this.excludeIndexes.length-1]].placeId, o.placeId, res.array);
            filter.forEach(e => {
              tempArray.push(e);
            });
          });
          var closestSortedArr = _.sortBy(tempArray, ['distanceValue']);
          var matchFound = this.findMatchingTasksNew(events, closestSortedArr);
          var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == matchFound[0].id });
          var travelDuration : any= closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var tempStartTime = moment.tz(events[this.excludeIndexes[this.excludeIndexes.length-1]].endDateTime, this.auth.getUserTimezone());
          var withBuffer = parseInt(travelDuration+(matchFound[0].startBuffer*60)+(events[this.excludeIndexes[this.excludeIndexes.length-1]].endBuffer*60));
          tempStartTime.add(withBuffer, 's');
          tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
          var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          var differenceSplit = matchFound[0].difference.split(' ');
          var tempEndTime = moment(finalStartTime);
          if(differenceSplit.length == 2){
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }  
          events[matchingIndex].start = finalStartTime;
          events[matchingIndex].end = finalEndTime;
          events[matchingIndex].startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          events[matchingIndex].endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          this.notFixedTasks = _.remove(this.notFixedTasks, function(o: any) {
            return o.id != events[matchingIndex].id
          });
          this.excludeIndexes.push(matchingIndex);
          events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
          events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
          var temp = {
            id: events[matchingIndex].id,
            location: events[matchingIndex].location
          }
          this.filteredTasks.push(temp);
          this.getSortedTasksV4(events, index+1);
        });
      }
    } else if(!this.notFixedTasks.length && this.fixedTasks.length){
      this.fixedTasks.forEach((e) => {
        var matchingIndex = _.findIndex(events, function(o: any) { return  o.id == e.id });
        this.fixedTasks = _.remove(this.fixedTasks, function(o: any) {
          return o.id != events[matchingIndex].id
        });
        this.excludeIndexes.push(matchingIndex);
        if(index !== 0){
          this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(events[this.excludeIndexes[this.excludeIndexes.length-2]], events, this.startLocation.traffic, moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).format())
          .subscribe((res:any) => {
            var closestSortedArr = this.getGoogleFilteredResponseNew2(events[this.excludeIndexes[this.excludeIndexes.length-2]].placeId, events[matchingIndex].placeId, res.array);
            events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
            events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
            var temp = {
              id: events[this.excludeIndexes[this.excludeIndexes.length-1]].id,
              location: events[this.excludeIndexes[this.excludeIndexes.length-1]].location
            }
            this.filteredTasks.push(temp);
            this.getSortedTasksV4(events, index+1);
          });
        } else {
          this.utilService.fetchDistanceMatrixFromLocationAsObservableNew(this.startLocation, events, this.startLocation.traffic, moment(moment(events[0].startDateTime).format('YYYY-MM-DD') + ' ' + this.startLocation.startTime).format())
          .subscribe((res:any) => {
            var closestSortedArr = this.getGoogleFilteredResponseNew2(this.startLocation.placeId, events[matchingIndex].placeId, res.array);
            events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskDistance = closestSortedArr[0].distanceValue;
            events[this.excludeIndexes[this.excludeIndexes.length-1]].nextTaskTravelTime = closestSortedArr[0].durationValue + closestSortedArr[0].trafficValue;
            var temp = {
              id: events[this.excludeIndexes[this.excludeIndexes.length-1]].id,
              location: events[this.excludeIndexes[this.excludeIndexes.length-1]].location
            }
            this.filteredTasks.push(temp);
            this.getSortedTasksV4(events, index+1);
          });
        }
      });
    }
  }

  findRemainingTasks(filteredTasks: any, events: any){
    var result1 = events;      
    var result2 = filteredTasks;
    var props = ['id', 'location', 'fixed', 'placeId'];

    var result = result1.filter(function(o1: any){
        return !result2.some(function(o2: any){
            return o1.id === o2.id
        });
    }).map(function(o: any){
        return props.reduce(function(newo: any, name: any){
            newo[name] = o[name];
            return newo;
        }, {});
    });
    return result;
  }

  fitTaskNew(lastEndTime: any, duration: any, lockedTaskStartTime: any, startBuffer: any, endBuffer: any){
    // //debugger;
    var tempStartTime = moment(lastEndTime);
      var withBuffer = duration+(startBuffer*60)+(endBuffer*60);
      tempStartTime.add(withBuffer, 's');
      var finalStartTime = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
    if(moment(finalStartTime).isSameOrBefore(moment.tz(lockedTaskStartTime, this.auth.getUserTimezone()))){
      return true;
    } else {
      return false;
    }
  }

  getGoogleFilteredResponseNew2(start: any, end: any, rows: any){
    var matchingStart : any[] = _.filter(rows, function(o) { 
      if(!end){
        return o.originPlaceId === start;
      }

      if(end){
        return o.originPlaceId === start && o.destinationPlaceId === end;
      }
    });
    return matchingStart;
  }

  findMatchingTasksNew(events: any, sortedArray: any){
    var self = this;
    if(sortedArray){
      var closestLocation = sortedArray[0].destinationPlaceId;
      var matchTasks: any = _.filter(events, function(o : any) {
        return o.placeId === closestLocation;
      });
      self.filteredTasks.forEach(e => {
        matchTasks = _.remove(matchTasks, function(o : any ){
          return e.id !== o.id;
        });
      });
    }
    return matchTasks;
  }

  nearestFutureMinutes(interval: any, someMoment: any){
    const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
    return someMoment.clone().minute(roundedMinutes).second(0);
  }


  addDurationToTasks(taskArray: any){
    var startBuffer = parseInt(this.startBuffer);
    var endBuffer = parseInt(this.endBuffer);
    for (var i=0;i<taskArray.length;i++){
      var nearestMatch = taskArray[i];
      if(!taskArray[i].fixed){
        if(i == 0){
          var fullDate = nearestMatch.start.split(' ');
          var date = fullDate[0];
          var nextDuration = nearestMatch.travelDurationText;
          var nextDurationSplit = nextDuration.split(' ');
          if(nextDurationSplit.length == 2){
            if(nextDurationSplit[1] == 'mins' || nextDurationSplit[1] == 'min'){
              nextDurationSplit[1] = 'minutes';
            }
            var tempStartTime : any= moment(date + ' ' + this.startLocation.startTime);
            var withBuffer = parseInt(nextDurationSplit[0])+startBuffer;
            tempStartTime.add(withBuffer, nextDurationSplit[1]);
            tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
            var finalStartTime: any = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          } else if(nextDurationSplit.length == 4){
            var withBuffer = parseInt(nextDurationSplit[2])+startBuffer;
            var tempStartTime: any = moment(date + ' ' + this.startLocation.startTime);
            tempStartTime.add({hours:nextDurationSplit[0],minutes:withBuffer});
            tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
            var finalStartTime: any = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          }
          nearestMatch.start = finalStartTime;
          var differenceSplit = nearestMatch.difference.split(' ');
          if(differenceSplit.length == 2){
            var tempEndTime = moment(finalStartTime);
            tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            var tempEndTime = moment(date + ' ' + tempStartTime);
            tempEndTime.add({hours:differenceSplit[0],minutes:differenceSplit[2]});
            var finalEndTime : any= moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }
          nearestMatch.end = finalEndTime;
          nearestMatch.startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          nearestMatch.endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          // nearestMatch.startDateTime = moment.utc(finalStartTime).format();
          // nearestMatch.endDateTime = moment.utc(finalEndTime).format();
          // //debugger
        } else {
          var fullDate = nearestMatch.start.split(' ');
          var date = fullDate[0];
          var lastEndTime = taskArray[i-1].end;
          var lastMatch = taskArray[i];
          var nextDuration = lastMatch.travelDurationText;
          var nextDurationSplit = nextDuration.split(' ');
          if(nextDurationSplit.length == 2){
            var tempStartTime : any= moment(lastEndTime);
            if(nextDurationSplit[1] == 'mins' || nextDurationSplit[1] == 'min'){
              nextDurationSplit[1] = 'minutes';
            }
            var withBuffer = parseInt(nextDurationSplit[0])+startBuffer+endBuffer;
            tempStartTime.add(withBuffer, nextDurationSplit[1]);
            tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
            var finalStartTime : any= moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          } else if(nextDurationSplit.length == 4){
            var tempStartTime: any = moment(lastEndTime);
            var withBuffer = parseInt(nextDurationSplit[2])+startBuffer+endBuffer;
            tempStartTime.add({hours:nextDurationSplit[0],minutes:withBuffer});
            tempStartTime = this.nearestFutureMinutes(5, tempStartTime);
            var finalStartTime: any = moment(tempStartTime).format('YYYY-MM-DD HH:mm');
          }
          nearestMatch.start = finalStartTime;
          var differenceSplit = nearestMatch.difference.split(' ');
          if(differenceSplit.length == 2){
            var tempEndTime = moment(nearestMatch.start);
            tempEndTime = tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          } else if(differenceSplit.length == 4){
            var tempEndTime = moment(nearestMatch.start);
            tempEndTime = tempEndTime.add(differenceSplit[0], differenceSplit[1]);
            var finalEndTime: any = moment(tempEndTime).format('YYYY-MM-DD HH:mm');
          }
          nearestMatch.end = finalEndTime;
          nearestMatch.startDateTime = moment.tz(finalStartTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
          nearestMatch.endDateTime = moment.tz(finalEndTime, 'YYYY-MM-DD HH:mm', this.auth.getUserTimezone()).utc().format();
        }
      }
    }
    this.events = taskArray;
    //console.log("Sorted Events ", this.events);
  }

  // getWeatherForecastForTask(events: any, index: any){
  // //  this.watch = WatchJS.watch;
  // this.spinner.hide();
  //   if(events[index].type !== 'startBuffer' && events[index].type !== 'endBuffer'){
  //     if(events[index].latitude && events[index].longitude){
  //       this.scheduleService.getWeatherByLatLng(events[index].latitude, events[index].longitude).subscribe((res:any) => {
  //         if(res.status == 200){
  //           var body = JSON.parse(res._body);
  //           //console.log(body);
  //           var array: any[] = [];
  //           body.list.forEach((date: any) => {
  //             array.push(date.dt);
  //           });
  //           var closestTime = (this.findClosestTimestamp(moment(events[index].startDateTime).format('X'), array));
  //           var matchingTime = body.list.filter(function(o: any){
  //             return o.dt == closestTime;
  //           });
  //           //console.log("Matching Time ", matchingTime);
  //           if(matchingTime.length){
  //             events[index].weatherIcon = '../../../assets/images/weatherIcons/'+matchingTime[0].weather[0].icon+'.png';
  //             // events[index].weatherIcon = 'http://openweathermap.org/img/w/'+matchingTime[0].weather[0].icon+".png";
  //             events[index].temperature =  matchingTime[0].main.temp;
  //             events[index].weatherDesc =  matchingTime[0].weather[0].description.charAt(0).toUpperCase() + matchingTime[0].weather[0].description.slice(1);
  //           }
  //           // //debugger
  //           if(index < events.length-1){
  //             this.getWeatherForecastForTask(events, index+1);
  //           } else {
  //             // this.watch(this.events, (prop, action, newvalue, oldvalue) => {
  //             //   this.changesMade = true;
  //             // });
  //             this.eventsLoaded = true;
  //             this.refreshCalendar(this.events);
  //             this.spinner.hide();
  //           }
  //         } else {
  //           this.refreshCalendar(this.events);
  //           this.spinner.hide();
  //           return;
  //         }
  //       });
  //     }
  //   } else {
  //     this.refreshCalendar(this.events);
  //     this.spinner.hide();
  //     return
  //   }
  // }
  
  findClosestTimestamp( startDate: any, dates: any ) {
    var startTime = startDate;
    var nearestDate, nearestDiff = Infinity;
    for( var i = 0, n = dates.length;  i < n;  ++i ) {
        var diff = dates[i] - startTime;
        if( diff > 0  &&  diff < nearestDiff ) {
            nearestDiff = diff;
            nearestDate = dates[i];
        }
    }
    return nearestDate;
  }

  goToToday(){
    this.currentDay = moment().endOf('day');
    this.ucCalendar.fullCalendar('gotoDate', this.currentDay);
  }

  next() {
    this.currentDay = this.currentDay.add(1, 'day');
    this.ucCalendar.fullCalendar('gotoDate', this.currentDay);
    // this.refreshCalendar();
  }

  prev() {
    this.currentDay = this.currentDay.subtract(1, 'day');
    this.ucCalendar.fullCalendar('gotoDate', this.currentDay);
    // this.refreshCalendar();
  }

  onClickBack()
  {
    this.utilService.goBack();
  }
}


