import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PERSISTANT_ITEM } from 'src/app/_models/global.data.model';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { MenuService } from 'src/app/_services/menu.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-setup-frame',
  templateUrl: './setup-frame.component.html'
})
export class SetupFrameComponent implements OnInit, AfterViewInit {

  general: any;
  users: any;
  module: any;
  sla: any;
  inventory: any;
  vehicles: any;
  toggleSetup: boolean = false;
  initialData: any[] = [];

  constructor(
    private menuItemService: MenuService,
    public router: Router,
    private breadcrumbService: BreadcrumbService,
    private cdr: ChangeDetectorRef,
    private util: UtilServiceService
  ) {
    this.initialData = this.menuItemService.getMenuNode();
    // debugger
    console.log("Admin Initial Data", this.initialData);
    //this.getUrl()
   }

  ngOnInit(): void {
    this.clearBreadcrumb()
   
  }

  collapseMenu() {
    this.toggleSetup = !this.toggleSetup

  }
  isExpandable(node: any) {
    return node.data.length == 0 ? false : true;
  }

  openurl(node: any) {
   
    if (node.routerLink) {
      this.router.navigate([node.routerLink]);
    }
  }
  changeData(name: any,element: any, nodeData : any){ 
    setTimeout(() => {
      console.log("-----We are in "+name)
      $('.'+name+'').attr('aria-expanded','true'); 
      $('.accordion-button').removeClass('collapsed'); 
      const contains = element.classList.contains("pi-angle-right");
      var collapsSubMenu  = false
      if (contains) {
        collapsSubMenu = true
      } 
      var data = {name: name,collapsSubMenu: collapsSubMenu}
      this.menuItemService.onMenuStateChange(data);
    },2000)
   
   // $('.setup-sub-manu-two').removeClass('show'); 
  }

  clearBreadcrumb(){  
    this.breadcrumbService.setItems([])
    this.breadcrumbService.setSetupItems([])
}

 /******************************************** Toggle ******************************************/
 public toggle(element: any, nodeData: any) {
  console.log(nodeData)
  // $('.main-menu').removeClass('pi-angle-down'); 
  // $('.main-menu').addClass('pi-angle-right'); 
  const contains = element.classList.contains("pi-angle-right");

  if (contains) {
    //element.className = "pi pi-angle-down";
    
   $('.'+nodeData+'-main').removeClass('pi-angle-right'); 
    $('.'+nodeData+'-main').addClass('pi-angle-down');

  } else {
  //  element.className = "pi pi-angle-right";
  $('.'+nodeData+'-main').removeClass('pi-angle-down'); 
  $('.'+nodeData+'-main').addClass('pi-angle-right'); 

  }
}

  getUrl(){
    
    // this.router.events.subscribe((event :any)=> {
    //     if (event instanceof NavigationEnd) {
    //       this.clearBreadcrumb()
    //         console.log(event.url,"-----init",event.url.indexOf('/dashboard/setup/ticket'))
    //       if(event.url.indexOf('/dashboard/setup/inventory') >= 0){
    //         this.changeData('inventory')
    //       }else if(event.url.indexOf('/dashboard/setup/asset') >= 0){
    //         //this.changeData('module')
    //       }else if(event.url.indexOf('/dashboard/setup/ticket') >= 0 || event.url.indexOf('/dashboard/setup/deliveryOrders') >= 0){
    //           this.changeData('module')
    //       }else if(event.url.indexOf('/dashboard/setup/sla') >= 0){
    //         this.changeData('sla')
    //       }else if(event.url.indexOf('/dashboard/setup/general') >= 0){
    //         this.changeData('general')
    //       }else if(event.url.indexOf('/dashboard/setup/allUser') >= 0 || event.url.indexOf('/dashboard/setup/drivers') >= 0){
    //         this.changeData('users')
    //       }else if(event.url.indexOf('/dashboard/setup/checklist') >= 0){
    //         this.changeData('checklist')
    //       }
    //      }
    //   });
}
ngAfterViewInit() {
 // this.breadcrumbService.setSetupItems([])
  this.cdr.detectChanges();
}
}
