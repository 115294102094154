<div class="h-95" *ngIf="loadDataDashboard == 1">
    <p-tabView class="px-0" (onChange)="handleChange($event, tabView)" [(activeIndex)]="activeIndex">
        
        <!-- Magnum -->
        <p-tabPanel [header]="'job_order' | translate" *ngIf="addonMagnum">
            <app-job-order-dashboard *ngIf="toggleSection.magnum.jobOrderDashboard"></app-job-order-dashboard>
        </p-tabPanel>

        <!-- Hills -->
        <p-tabPanel [header]="'hills' | translate" *ngIf="addonHills">
            <app-hills-dashboard *ngIf="toggleSection.hills.dashboard"></app-hills-dashboard>
        </p-tabPanel>
        <p-tabPanel [header]="'national' | translate" *ngIf="addonHills">
            <app-national-dashboard *ngIf="toggleSection.hills.national"></app-national-dashboard>
        </p-tabPanel>

        <!-- Chubb -->
        <p-tabPanel [header]="'journey_management_plan' | translate" *ngIf="addonChubb">
            <app-journey-management-plan *ngIf="toggleSection.chubb.journeyManagement"></app-journey-management-plan>
        </p-tabPanel>
        <p-tabPanel [header]="'chubb' | translate" *ngIf="addonChubb">
            <app-chubb-dashboard *ngIf="toggleSection.chubb.chubbDahsboard"></app-chubb-dashboard>
        </p-tabPanel>

        <p-tabPanel [header]="'service'|translate">
            <app-my-dashboard *ngIf="toggleSection.myDashboard" dashboardURL="/dashboards/view/9f362146-d3a0-40fc-aa7f-5ff6ae18b592/"></app-my-dashboard>
        </p-tabPanel>
         <!-- addonINVENTORY -->
        <p-tabPanel [header]="'inventory' | translate" *ngIf="addonINVENTORY">
            <app-my-dashboard *ngIf="toggleSection.inventoryDashboard" dashboardURL="/dashboards/view/56c40816-4543-45e9-a5bb-c279519335c5/"></app-my-dashboard>
        </p-tabPanel>
        <p-tabPanel [header]="'preventative_maintenance' | translate" *ngIf="addonINVENTORY">
            <app-my-dashboard *ngIf="toggleSection.pmDahsboard" dashboardURL="/dashboards/view/c4587b19-f61e-43e6-8276-bb2de5f09be0/"></app-my-dashboard>
        </p-tabPanel>
    </p-tabView>
  </div>
  