import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { AssetService } from 'src/app/_services/asset.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-verification-requisition',
  templateUrl: './verification-requisition.component.html',
  styleUrls: ['./verification-requisition.component.scss']
})
export class VerificationRequisitionComponent implements OnInit {
  title: any;
  data: any;
  submitToHod:boolean = false;
  comments: any = '';
  submitBtnDisabled: any = false;
  picToNotify:any
    users: any;
  constructor(
    public modalRef : BsModalRef,
    private assetService: AssetService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private auth: AuthenticationService,
  ) { }

  
  ngOnInit() {
    console.log(this.data)
    this.assetService.getActiveUsers().subscribe((users : any) => {
      this.users = users;
      this.users = _.remove(this.users, (a : any) => {
        return a.id != this.auth.getUserId()
      });
    })

    if(this.data.verifiedBy){
      if(this.auth.getUserId() == this.data.verifiedBy.id){
        this.submitBtnDisabled = true;
      }else{
        this.submitBtnDisabled = false;
      }
    }
  }

  approve(){
    
    var picToNotifyUser=this.picToNotify
    if(picToNotifyUser == null){
      picToNotifyUser= null
    }

    if (this.comments && (this.comments === "" || this.comments.length === 0)) {
      this.comments = null;
    }


    this.assetService.getVerifiedApprove(this.data.id,this.comments,this.submitToHod, picToNotifyUser).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, 'Requisition approved successfully' ,  null, 'success',1000);
       }else {
       // this.modalRef.hide();
       this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    });
  }

  reject(){
    if (this.comments && (this.comments === "" || this.comments.length === 0)) {
      this.comments = null;
    }

    this.assetService.getVerifiedReject(this.data.id,this.comments,this.submitToHod).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, 'Requisition rejected successfully' ,  null, 'success',1000);
       }else {
       // this.modalRef.hide();
       this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    });
  }

  approve1(){
    if (this.comments && (this.comments === "" || this.comments.length === 0)) {
      this.comments = null;
    }

    this.assetService.getHODApprove(this.data.id,this.comments).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, 'Requisition approved successfully' ,  null, 'success',1000);
        }else {
        // this.modalRef.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
       }
    });
  }

  reject1(){
    if (this.comments && (this.comments === "" || this.comments.length === 0)) {
      this.comments = null;
    }

    this.assetService.getHODReject(this.data.id,this.comments).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, 'Requisition rejected successfully' ,  null, 'success',1000);
      }else {
      // this.modalRef.hide();
      this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
     }
    });
  }

  approve2(){
    if (this.comments && (this.comments === "" || this.comments.length === 0)) {
      this.comments = null;
    }

    this.spinner.show();
    this.assetService.approveConsignment(this.data.id,this.comments).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, 'Acknowledged successfully' ,  null, 'success',1000);
      }else {
      // this.modalRef.hide();
      this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
     }
      this.spinner.hide();
    })
  }

  reject2(){
    if (this.comments && (this.comments === "" || this.comments.length === 0)) {
      this.comments = null;
    }
    
    this.spinner.show();
    this.assetService.rejectConsignment(this.data.id,this.comments).subscribe((res:any)=>{
      if(res.status === 200 || res.status === 201){
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, 'Rejected successfully' ,  null, 'success',1000);
        }else {
        // this.modalRef.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
       }
      this.spinner.hide();
    })
  }

  onSubmit(){

  }

}
