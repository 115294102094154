<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img  *ngIf="option == 'Decline'" src="assets\svg\dialog\Danger-w.svg" id="img_TaskDecline">
            <img  *ngIf="option == 'Accept'" src="assets\svg\dialog\Confirmation-w.svg" id="img_TaskAccept"> 
            <span *ngIf="option == 'Decline'" class="text-16 font-medium ms-3" id="title_TaskDecline">Confirm Task Decline</span>
            <span *ngIf="option == 'Accept'" class="text-16 font-medium ms-3" id="title_TaskAccept">Confirm Task Acceptance</span>
        </div>
        <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
      
                <div class="modal-body pt-0">
                <div class="container text-left px-0" style="padding-top: 14px;">
                    <p class="mb-0 text-4" id="label_TaskOptionThisTask">{{'you_sure_you_wan_to' | translate}} {{ option }} {{'this_task' | translate}}</p>
                    <div class="d-flex justify-content-between" *ngIf="option == 'Decline'">
                        <div class="d-block position-relative me-3 col-12 mt-2">
                            <textarea pInputTextarea autocomplete="off" id="input_TaskOptionThisTaskField" [(ngModel)]="reason" [ngModelOptions]="{standalone: true}" placeholder="{{'reason_optional' | translate}}" [maxlength]="320000"></textarea>
                                       
                        </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer p-0">
                    <button type="button" id="btn_TaskOptionDecline" class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
                    <p-button type="button" id="btn_TaskOptionAccept" (click)="onSubmit()"  label="{{'yes' | translate}}"></p-button>
                </div>

          
            
        

    <!-- </div> -->