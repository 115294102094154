import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingService } from 'src/app/_services/admin/setting.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss']
})
export class ConnectComponent implements OnInit {
  connect : any = {};
  connectForm: UntypedFormGroup;
  submitTouched:boolean=false;

  constructor(private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private settingService : SettingService) { 
    this.connectForm = new UntypedFormGroup({
      'clientId': new UntypedFormControl(null, [Validators.required]),
      'clientSecret': new UntypedFormControl(null, [Validators.required])
    });
  }

  ngOnInit() {
    // console.log("SalesForce Connect")
  }

  connectSalesforce(){
    // this.spinner.show();
    this.submitTouched=true;
    if(!this.connectForm.valid){
      for (var i in this.connectForm.controls) {
        this.connectForm.controls[i].markAsTouched();
      }
      // this.spinner.hide();
      return false;
    }
    
    var data = {
      "clientId": this.connectForm.value.clientId,
      "clientSecret": this.connectForm.value.clientSecret,
    }

    this.settingService.connectToSalesforce(data).subscribe(res=>{
      // console.log("SF Resp ", res);
      if(res.status == 200){
        window.location.href = res.body.webRedirectUrl;
      }
    });
  }

}
