import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { VehicleService } from "src/app/_services/vehicle.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-vehicle-types-table",
  templateUrl: "./vehicle-types-table.component.html",
  styleUrls: ["./vehicle-types-table.component.scss"],
})
export class VehicleTypesTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  @Input() searchValue: any;
  @Output() itemSelector = new EventEmitter();
  @Output() noDataEvent = new EventEmitter();

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  vehicles: any;
  parameters: any;
  public gridOptions!: Partial<GridOptions>;
  noVehicleTypes: boolean = false;
  overlayNoRowsTemplate!: string;

  public totalRecord: any;

  constructor(
    private router: Router,
    private vehicleService: VehicleService, // private spinner : NgxSpinnerService,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length > 2 &&
      changes.searchValue.currentValue !== ""
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length === 0 &&
      changes.searchValue.currentValue === ""
    ) {
      this.onGridReady(this.gridParams);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      tooltipShowDelay: 100,
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
    };

    this.columnDefs = [
      {
        headerName: "Name",
        field: "vehicleType.name",
        width: 190,
        headerTooltip: "#",
        
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.name;
          }
        },
      },
      {
        headerName: "Category",
        field: "vehicleCategory.name",
        width: 173,
        headerTooltip: "#",
      },
      {
        headerName: "Model",
        field: "vehicleModelValue",
        width: 194,
        headerTooltip: "#",
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Grid Ready ******************************************/
  public onGridReady(params: any) {
    // this.spinner.show();
    var count: any= 0;
    if (params) {
      this.gridParams = params;
      this.searchValue = this.searchValue ? this.searchValue : "";
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var dataSource = {
        getRows: (params: any) => {
          if (params.sortModel.length <= 0) {
            this.parameters = { colId: "vehicleType.id", sort: "desc" };
            params.sortModel.push(this.parameters);
          }

          this.push(
            this.vehicleService
              .getVehicleTypeList(this.searchValue, params)
              .subscribe((data: any) => {
                this.vehicles = data.data;
                
                this.totalRecord = data.total;

                if (data.status === "NO_DATA") {
                  this.noVehicleTypes = true;
                  this.noDataEvent.emit(this.noVehicleTypes);
                } else {
                  this.noVehicleTypes = false;

                  if (this.vehicles.length === 0) {
                    this.gridApi.showNoRowsOverlay();

                    params.successCallback([], 0);
                  } else {
                    params.successCallback(this.vehicles, data.total);
                  }
                }

                // this.spinner.hide();
              })
          );
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** On Click Cell Item Select ******************************************/
  public onClick(e: any) {
    if (e.colDef.field === "vehicleType.name") {
      this.itemSelector.emit(e.data);
    }
  }

    /******************************************** Right Click Context Menu ******************************************/
    public getContextMenuItems = (params: any) => {
      params.node.data.field = params.column.colId;
      let url = "";
      let data = "";
  
      let results = [];
  
      if (params.column.colId == "vehicleType.name") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }
  
      return results;
    };
  
    /******************************************** Redirect To ******************************************/
    private redirectToNewTab(data: any) {
      let url;
      if (data.field === "vehicleType.name") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["dashboard/setup/fleetSetup/vehicleType/view/" + data.id],
            {
              queryParams: { id: data.id },
            }
          )
        );
      }
  
      if (url) {
        url = url.replace("%23", "#");
        this.util.openItemToNewTab(url);
      }
    }

    push(obs:any) {
      super.push(obs);
    }
}
