<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <span class="text-16 font-medium">{{title}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<form [formGroup]="addOrderForm">
    <div class="modal-body tix pb-0 pt-2" style="height: 430px; overflow-y:unset;">
            <div class="d-flex align-items-start justify-content-between">
                <div class="w-50">
                    <div class="custella-form-container rounded-top-left" style="border: 1px solid #dee2e6; box-shadow:none; margin: 0px;">
                        <!-- Container Content -->
                        <div *ngIf="data.optionType=='addOrderSpecificVehicle'||data.optionType == 'addOrderCurrentVehicle'" class="custella-form-container-title text-14 font-medium" style="border-top-right-radius: 0px;" translate>  {{"select_order_to_add"}}</div>
                        <div *ngIf="data.optionType=='adjustNewVehicle'"  class="custella-form-container-title text-14 font-medium"  style="border-top-right-radius: 0px;" translate>{{'search_vehicle_to_add'}}</div>
                        <!-- ---------------------------------------Left side - addOrderCurrentVehicle -------------------------------------------------- -->
                        <div *ngIf="data.optionType=='addOrderSpecificVehicle'||data.optionType=='addOrderCurrentVehicle'" class="custella-form-container-content" style="height: 336px; overflow: auto">
                            <div class="custella-content-row-input col-12">
                                <div class="d-flex align-items-center">
                                    <div class="p-input-icon-right w-100 d-flex">
                                        <div class="p-input-icon-right w-100">
                                            <p-autoComplete [(ngModel)]="orderNumberData" placeholder="Search Order" (onBlur)="onValidDo()" (onSelect)="selectDoNumber($event)"
                                                field="name" formControlName="order" [suggestions]="deliveryOrder"
                                                (completeMethod)="getOrderIdsFirstWay($event)">
                                                </p-autoComplete>
                                            <i *ngIf="orderNumber" (click)="clearDoValue()"
                                                class="p-autocomplete-clear-icon pi pi-times "></i>
                                            </div>
                                
                                            <button class="lookup-button me-0" (click)="showDoTable()"> <i class="pi pi-search"></i> </button>
            
                                            <footer *ngIf="displayDoTable" class="custella-form-container-footer hidePopup">
                                                <p-dialog header="Delivery Orders" [(visible)]="displayDoTable" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false">
                                                    <app-custella-do (doSelector)="gotSelectedOrder($event)" [notInOrderIds]="notInOrderIds" [statuses]="doStatuses" (closeDOView)="closeOrderView()"></app-custella-do>
                                                </p-dialog>
                                            </footer>
                                    </div>
                                </div>
                                <div class="p-error text-10" *ngIf="addOrderForm.controls['order'].touched && addOrderForm.controls['order'].invalid">
                                    <span *ngIf="addOrderForm.controls['order'].errors?.required" translate>{{'required_field'}}</span>
                                </div>
                            </div>
                            <div class="custella-content-row mb-3" *ngIf="!orderNumber" style="height: 280px">
                                <div class="custella-content-row-input col-12">                               
                                    <div class="d-flex align-items-center justify-content-center mt-4">
                                        <img src="assets\svg\table\select_order.svg" alt="Add New Order by Selecting">
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <span class="text-14 font-semiBold d-block m-3" translate>{{'add_new_order_select'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-grey my-3 py-1 rounded-2" style="border: 1px solid #dee2e6;" *ngIf="orderNumber && orderData">
                                <div class="custella-form-container-content">
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'order_name'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.name ? orderData?.name:'-'}}</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'status'}}</span>
                                            <span class="custella-content-row-input-title-three"> 
                                                <span class="p-mr-2 p-tag p-component" [attr.style]="'background-color:' + orderData?.newStataus.colorCode + '50 !important;'">
                                                <span class="p-tag-value text-dark">{{orderData?.newStataus?.label}}</span>
                                            </span>
                                        </span>
                                        </div>
                                    </div>
                                    <!-- <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'contact'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.owner?.fullName ? orderData?.owner?.fullName:'-'}}     {{orderData?.owner?.phone ? orderData?.owner?.phone: '-'}}</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'pic_name'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.picName ? orderData?.picName:'-' }}</span>
                                        </div>
                                    </div> -->
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'customer'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.customer?.name ? orderData?.customer?.name:'-'}}</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'order_type'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.deleveryType ? orderData?.deleveryType:'-'}}</span>
                                            <!-- <span class="custella-content-row-input-title-two"
                                                translate>{{'pic_contact'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.picContact ? orderData?.picContact:'-'}}</span> -->
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <!-- <span class="custella-content-row-input-title-two" translate>{{'priority'}}</span>
                                            <span class="custella-content-row-input-title-three">
                                                {{orderData?.priority ? orderData?.priority:'-'}}
                                            </span> -->
                                            <span class="custella-content-row-input-title-two" translate>{{'geofence'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.geoFence?.geoFenceName ? orderData?.geoFence?.geoFenceName: '-'}}</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two"
                                                translate>{{'preferred_vehicle_group'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.vehicleTypeGroup?.name ? orderData?.vehicleTypeGroup?.name: '-'}}</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-12">
                                            <span class="custella-content-row-input-title-two" translate>{{'remarks'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.description ? orderData?.description:'-'}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="custella-content-row">
                                        <div class="custella-content-row-input col-12">
                                            <span class="custella-content-row-input-title-two"
                                                translate>{{'note_for_driver'}}</span>
                                            <span class="custella-content-row-input-title-three">{{orderData?.notes ? orderData?.notes:'-'}}</span>
                                        </div>
                                    </div> -->
                                </div>

                            </div>
                        </div>

                        <!-- ---------------------------------------Left side (adjustNewVehicle) -------------------------------------------------- -->
                        <div *ngIf="data.optionType=='adjustNewVehicle'" class="custella-form-container-content">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title" translate>{{'order'}}</span>
                                <div class="d-flex align-items-center">
                                    <div class="p-input-icon-right w-100">
                                        <p-autoComplete placeholder="Search Order"></p-autoComplete>
                                    </div>
                                    <button class="lookup-button" style="padding-top: 7px;"> <i class="pi pi-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="custella-content-row mb-3" *ngIf="!toggle1">
                                <div class="custella-content-row-input col-12">                               
                                    <div class="d-flex align-items-center justify-content-center mt-4">
                                        <img src="assets\svg\table\select_order.svg" alt="Add New Order by Selecting">
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                        <span class="text-14 font-semiBold d-block m-3" translate>{{'add_new_order_select'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-grey my-3 py-1 rounded-2" *ngIf="toggle1">
                                <div class="custella-form-container-content">
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'contact'}}</span>
                                            <span class="custella-content-row-input-title-three">+601-142 5252</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'pic_name'}}</span>
                                            <span class="custella-content-row-input-title-three">Alex</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'customer'}}</span>
                                            <span class="custella-content-row-input-title-three">Kevin Lerry</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two"
                                                translate>{{'pic_contact'}}</span>
                                            <span class="custella-content-row-input-title-three">+601-142 5252</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'priority'}}</span>
                                            <span class="custella-content-row-input-title-three">
                                                <span class="p-mr-2 p-tag p-component"
                                                    style="background-color: #c8e6c9 !important ;">
                                                    <span class="p-tag-value text-dark">Completed</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two"
                                                translate>{{'preferred_vehicle_group'}}</span>
                                            <span class="custella-content-row-input-title-three">Fernleaf</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'order_type'}}</span>
                                            <span class="custella-content-row-input-title-three">Mark</span>
                                        </div>
                                        <div class="custella-content-row-input col-6">
                                            <span class="custella-content-row-input-title-two" translate>{{'geofence'}}</span>
                                            <span class="custella-content-row-input-title-three">Rover</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-12">
                                            <span class="custella-content-row-input-title-two" translate>{{'remarks'}}</span>
                                            <span class="custella-content-row-input-title-three">Lorem ipsum dolor sit amet
                                                consectetur adipisicing elit. Velit voluptatibus provident nostrum, dicta
                                                tenetur enim nesciunt. Quaerat, harum repudiandae dolorum eum doloribus, rem,
                                                expedita recusandae optio nostrum accusamus quidem in?</span>
                                        </div>
                                    </div>
                                    <div class="custella-content-row">
                                        <div class="custella-content-row-input col-12">
                                            <span class="custella-content-row-input-title-two"
                                                translate>{{'note_for_driver'}}</span>
                                            <span class="custella-content-row-input-title-three">Lorem ipsum dolor sit amet
                                                consectetur, adipisicing elit. Cupiditate, sit consequuntur corrupti voluptates
                                                laborum quasi, et, obcaecati quibusdam molestias mollitia velit vitae
                                                exercitationem qui rerum odio itaque dolore! Eligendi, porro?</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <!--------------------------------- Right Side - SPECIFIC VEHICLE ---------------------------------------------------->
                <div class="w-50" *ngIf="data.optionType == 'addOrderSpecificVehicle'">
                    <div class="custella-form-container rounded-top-right" style="border: 1px solid #dee2e6; box-shadow:none; margin: 0px;" >
                        <div class="custella-form-container-title text-14 font-medium"  style="border-top-left-radius: 0px;" translate> {{"select_vehicle_to_add"}}</div>
                        
                        <div class="custella-form-container-content" style="height: 336px;">
                            <div class="custella-content-row-input col-12" *ngIf="data.optionType == 'addOrderSpecificVehicle'">
                                <div class="d-flex align-items-center mb-1">
                                    <button pButton type="button" class="rounded-default-button py-1 me-1" [ngClass]="{'rounded-chosen-button':vehicleType == 'current'}" (click)="vehicleTypeChange('current')"translate>{{"current_vehicle"}}</button>
                                    <button pButton type="button" class="rounded-default-button py-1 me-1" [ngClass]="{'rounded-chosen-button':vehicleType == 'new'}" (click)="vehicleTypeChange('new')" translate>{{"new_vehicle"}}</button>
                                </div>
                                <div *ngIf="vehicleType == 'current'">
                                        <p-dropdown [(ngModel)]="selectedVehicleData" formControlName="vehicle" name="status" (onChange)="search2($event)"
                                        [options]="vehicles2" [showClear]="true" placeholder="Select Vehicle">
                                        <ng-template pTemplate="selectedItem">
                                            <div class="flex d-flex" *ngIf="selectedVehicleData">
                                                <div class="me-2" [ngClass]="selectedVehicleData.vehicle.vehicleNumber"></div>
                                                    <span> {{ selectedVehicleData.vehicle.name }} </span>
                                            </div>
                                        </ng-template>
                                            <ng-template let-row pTemplate="item">
                                                <div class="flex d-flex">
                                                    <div class="me-2" [ngClass]="row.vehicle.vehicleNumber"></div>
                                                    <span> {{row.vehicle.name }} </span>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>

                                        <div class="p-error text-10" *ngIf="vehicleType== 'current' && addOrderForm.controls['vehicle'].touched && addOrderForm.controls['vehicle'].invalid">
                                            <span *ngIf="addOrderForm.controls['vehicle'].errors?.required" translate>{{'required_field'}}</span>
                                        </div>
                                </div>
                                <div class="custella-content-row-input col-12" *ngIf="data.optionType == 'addOrderSpecificVehicle'&&vehicleType == 'new'">
                                    <div class="d-flex align-items-center">
                                        <div class="p-input-icon-right w-100 d-flex">
                                            <div class="p-input-icon-right w-100">
                                                <p-autoComplete [(ngModel)]="vehicleData" placeholder="Search Vehicle" [readonly]="vehicleId" (onBlur)="onValidVehicle()" (onSelect)="selectVehicleValue($event)"
                                                    field="name" formControlName="vehicle" [suggestions]="vehicles"
                                                    (completeMethod)="getVehicleIdsFirstWay($event)">
                                                    </p-autoComplete>
                                                <i *ngIf="vehicleName" (click)="clearVehicleValue()"
                                                    class="p-autocomplete-clear-icon pi pi-times "></i>
                                                </div>
                                    
                                                <button class="lookup-button me-0" (click)="showVehicleTable()"> <i class="pi pi-search"></i> </button>
                
                                                <footer *ngIf="displayTable1" class="custella-form-container-footer hidePopup">
                                                    <p-dialog header="Vehicle Details" [(visible)]="displayTable1" showEffect="fade" [style]="{width: '70vw'}" [modal]="true" [draggable]="false" [resizable]="false">
                                                        <app-custella-vehicle  (vehicleSelector)="gotSelectedVehicle($event)" [notInVehicleIds]="notInVehicleIds" (closeVehicleView)="closeVehicleView()"></app-custella-vehicle>
                                                    </p-dialog>
                                                </footer>
                                        </div>
                                    </div>
                                    <div class="p-error text-10" *ngIf="addOrderForm.controls['vehicle'].touched && addOrderForm.controls['vehicle'].invalid">
                                            <span *ngIf="addOrderForm.controls['vehicle'].errors?.required" translate>{{'required_field'}}</span>
                                    </div>
                                </div>

                            </div>
                            <div class="custella-content-row mb-3" *ngIf="!vehicleName && !searchVehicle && data.optionType == 'addOrderSpecificVehicle'" style="height: 253px;">
                                <div class="custella-content-row-input col-12">                               
                                    <div class="d-flex align-items-center justify-content-center mt-4">
                                        <img src="assets/images/truck.png" alt="Choose vehicle to add order to">
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center pb-5">
                                        <span class="text-14 font-semiBold d-block m-3" translate>{{'choose_vehicle_to_add_order'}}</span>
                                    </div>
                                </div>
                            </div>
                            <!--------------------------------- vehicle cards (1 option specific vehicle) SPECIFIC VEHICLE ---------------------------------------------------->
                            <div *ngIf="data.optionType == 'addOrderSpecificVehicle'&&vehicleType == 'current'&&dragableDataList&&searchVehicle">
                                <div *ngFor="let list of dragableDataList">
                                    <div class="custella-form-container-title-two-grey d-flex align-items-center" >
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                                            <span class="text-12 font-black font-semiBold d-block" translate>{{list?.vehicleName}}</span>
                                        </div>
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                                            <span *ngIf="list?.capacity < 50" class="text-12 font-black font-semiBold d-block lowCapacity">{{list?.capacity}}%</span>
                                            <span *ngIf="list?.capacity > 50 && list?.capacity < 80" class="text-12 font-black font-semiBold d-block mediumCapacity">{{list?.capacity}}%</span>
                                            <span *ngIf="list?.capacity > 80" class="text-12 font-black font-semiBold d-block highCapacity">{{list?.capacity}}%</span>
                                        </div>
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                                            <span class="text-12 font-black font-semiBold d-block" translate>{{list?.duration | minutesSeconds}}</span>
                                        </div>
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                                            <span class="text-12 font-black font-semiBold d-block" translate>{{list?.distance | distanceToKm2}}</span>
                                        </div>
                                    </div>
                                    <div class="custella-form-container-content px-0" style="height: 230px; overflow-y: auto; margin-bottom: 0px; margin-top:5px;">
                                        <div class="overflow-scroll height-addMod">
                                            <div *ngFor="let item of list?.relatedVehicleEvents;index as i" class="border rounded-1 flex-between mb-2 py-2 px-3">
                                                <div *ngIf="item" class="col p-0">
                                                    <div *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')" class="flex-between" [ngClass]="{'fade-opacity-04' : (item.type=='pickup'&&item.status=='PICKED_UP'|| item.type=='pickup'&&item.status=='DELIVERED' || item.type=='delivery'&&item.status=='DELIVERED')}"> 
                                                        <div class="d-flex align-items-center col-8">
                                                            <img *ngIf="item?.deliveryOrderWay=='pickup'" src="assets/svg/table/ph_arrow_green.svg" alt="Delivered Planning Hub" class="me-2 w-19px">
                                                            <img *ngIf="item?.deliveryOrderWay=='delivery'" src="assets/svg/table/ph_arrow.svg" alt="Delivered Planning Hub" class="me-2 w-19px">
                                                            <img *ngIf="item?.deliveryOrderWay=='pickup_and_delivery'" src="assets/svg/table/dropoff-pickup.svg" alt="Delivered Planning Hub" class="me-2 w-19px">
                                                            <span class="text-10">{{item.orderCustomer}}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center col-4" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                            <img src="assets\svg\table\clock.svg" alt="Available Vehicle" class="w-19px me-1">
                                                            <span class="text-10 color-var-500">{{item.startTime}} - {{item.endTime}}</span>
                                                        </div>
                                                        <!-- <div  class="d-flex align-items-center col-4" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                            <span class="text-10 color-var-500">{{item.note}}</span>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                
                                            </div>
    
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <!-- New Vehicle -->
                            <div *ngIf="vehicleName && vehicleData" class="custella-form-container-content pb-3 mt-1 px-0" style="height: 260px;">
                                
                                <div class="bg-grey mt-3 mb-1 py-1 rounded-2 col-12" style="border: 1px solid #dee2e6;" >
                                    <div class="custella-form-container-content">
                                        <div class="d-flex">
                                            <div class="ms-2 me-4 col-2">
                                                <p-avatar *ngIf="!vehicleData?.vehicleImage?.attachmentUrl"image="assets/images/truck-avatar.png"  styleClass="wh-80px ms-2" size="xlarge" shape="circle"> </p-avatar>
                                                <p-avatar *ngIf="vehicleData?.vehicleImage?.attachmentUrl" styleClass="wh-80px ms-2" size="xlarge" [image]="vehicleData?.vehicleImage?.attachmentUrl" alt="Profile Picture" shape="circle"></p-avatar>
                                                <span class="d-block ms-3">
                                                    <span *ngIf="vehicleData?.vehicleStatus === 'DEPOT'" class="p-tag p-component" style="background-color: #c8e6c9 !important ;">
                                                        <span class="p-tag-value text-dark">Depot</span>
                                                    </span>
                                                    <span *ngIf="vehicleData?.vehicleStatus === 'ENROUTE'" class="p-tag p-component" style="background-color: #edc054 !important ;">
                                                        <span class="p-tag-value text-dark">Enroute</span>
                                                    </span>
                                                    <span *ngIf="vehicleData?.vehicleStatus === 'MAINTENANCE'" class="p-tag p-component" style="background-color: #ce4343 !important ;">
                                                        <span class="p-tag-value text-dark">Maintenance</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="col-10">
                                                <span class="text-20 font-semiBold">{{vehicleData?.vehicleType?.name}}</span>
                                                <div class="custella-content-row">
                                                    <div class="custella-content-row-input col-4">
                                                        <span class="custella-content-row-input-title-two" translate>{{'vehicle_no'}}</span>
                                                        <span class="custella-content-row-input-title-three">{{vehicleData?.vehicleNumber}}</span>
                                                    </div>
                                                    <div class="custella-content-row-input col-7">
                                                        <span class="custella-content-row-input-title-two" translate>{{'depot'}}</span>
                                                        <span class="custella-content-row-input-title-three text-wrap">{{vehicleData?.depot?.name}}</span>
                                                    </div>
                                                </div>
                                                <div class="custella-content-row">
                                                    <div class="custella-content-row-input col-10">
                                                        <span class="custella-content-row-input-title-two" translate>{{'driver'}}</span>
                                                        <span class="custella-content-row-input-title-three">{{vehicleData?.defaultDriver?.user?.fullName}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                
            </div>
                </div>
                <!-------------------------------------------- Right side - vehicle cards (add order(addOrderCurrentVehicle) - 2nd option) ---------------------------------------------------->
                <div class="w-50" *ngIf="data.optionType == 'addOrderCurrentVehicle'||data.optionType == 'adjustNewVehicle'">
                    <div class="custella-form-container rounded-top-right" style="border: 1px solid #dee2e6; box-shadow:none; margin: 0px;">
                        <div class="custella-form-container-title text-14 font-medium" style="border-top-left-radius: 0px;" >Step 2: Click 'Proceed' to add it to the optimal vehicle</div>
                        <div class="custella-form-container-content overflow-scroll" style="height: 336px; overflow-y: hidden;" *ngIf="data.optionType == 'addOrderCurrentVehicle'">
                            <div class="custella-form-container-two m-0 rounded-2" style="box-shadow: none;">
                                <div class="px-2 py-1">
                                    <div class="flex-between mb-2">
                                        <div>
                                            <div class="text-20 font-medium">{{data?.planNumber}}</div>
                                            <span class="text-12 font-medium d-block">{{data?.planDateTime | momentDate : dateFormat}}</span>
                                        </div>
                                        <div class="justify-content-end">
                                            <span *ngIf="data?.planStatus === 'COMPLETED'" class="p-mr-2  p-tag p-component p-tag-completed" ><span class="p-tag-value text-dark">Completed</span></span>
                                            <span  *ngIf="data?.planStatus === 'DRAFT'" class="p-mr-2  p-tag p-component p-tag-draft" ><span class="p-tag-value text-dark">Draft</span></span>
                                            <span *ngIf="data?.planStatus === 'CONFIRMED'" class="p-mr-2  p-tag p-component p-tag-confirmed" ><span class="p-tag-value text-dark">Confirmed</span></span>
                                            <span *ngIf="data?.planStatus === 'DISPATCHED'" class="p-mr-2  p-tag p-component p-tag-dispatched" ><span class="p-tag-value text-dark">Dispatched</span></span>
                                            <span *ngIf="data?.planStatus === 'IN_COMPLETED' || data?.planStatus === 'INCOMPLETE'" class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">Incomplete</span></span>
                                            <span *ngIf="data?.planStatus === 'CANCELLED'" class="p-mr-2  p-tag p-component p-tag-cancel" ><span class="p-tag-value text-dark">Cancelled</span></span>
                                            <span *ngIf="data?.planStatus === 'IN_PROGRESS'" class="p-mr-2  p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">In Progress</span></span>
                                            <div class="d-block">
                                                <div class="d-flex mt-1">
                                                    <img src="assets\svg\table\truck-black.svg" alt="Truck" class="w-14px me-2">
                                                    <span class="text-12">{{dragableDataList?.length}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="row">
                                        <div class="d-flex align-items-center justify-content-start">
                                            <div class="col me-1">
                                                <span class="text-12 font-medium color-var-500" translate>{{'total_duration'}}</span>
                                                <span class="text-12 font-medium d-block">{{data?.statistic?.duration | minutesSeconds}}</span>
                                            </div>
                                            <div class="col me-1">
                                                <span class="text-12 font-medium color-var-500" translate>{{'total_volume'}}</span>
                                                <span class="text-12 font-medium d-block">{{data?.statistic?.massCapacity | number:'.2-2'}} m³</span>
                                            </div>
                                            <div class="col me-1">
                                                <span class="text-12 font-medium color-var-500" translate>{{'total_cost'}}</span>
                                                <span class="text-12 font-medium d-block">{{data?.statistic?.cost | number:'.2-2' }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="overflow-hidden mb-2" *ngFor="let list of dragableDataList; index as i">
                                    <div class="custella-form-container-title-two-grey d-flex align-items-center py-2" >
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                                            <span class="text-12 font-black font-semiBold d-block" translate>{{list?.vehicleName}}</span>
                                        </div>
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                                            <span *ngIf="list?.capacity < 50" class="text-12 font-black font-semiBold d-block lowCapacity">{{list?.capacity}}%</span>
                                            <span *ngIf="list?.capacity > 50 && list?.capacity < 80" class="text-12 font-black font-semiBold d-block mediumCapacity">{{list?.capacity}}%</span>
                                            <span *ngIf="list?.capacity > 80" class="text-12 font-black font-semiBold d-block highCapacity">{{list?.capacity}}%</span>
                                        </div>
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                                            <span class="text-12 font-black font-semiBold d-block" translate>{{list?.duration | minutesSeconds}}</span>
                                        </div>
                                        <div class="col">
                                            <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                                            <span class="text-12 font-black font-semiBold d-block" translate>{{list?.distance | distanceToKm2}}</span>
                                        </div>
                                        <div class="text-end ms-auto">
                                            <i class="pi pi-caret-up mt-1 ms-3" #wfcElem (click)="toggle(wfcElem)" data-bs-toggle="collapse" [attr.href]="'#collapseBox' + i"></i>
                    
                                        </div>
                                    </div>
                                    <div class="custella-form-container-content px-0 collapse show" [id]="'collapseBox' + i" style="margin-bottom: 0px; margin-top:5px;overflow-y: auto;">
                                        <div class="pb-1">
                                            <div *ngFor="let item of list?.relatedVehicleEvents;index as i" class="border rounded-1 flex-between mb-2 py-2 px-3">
                                                <div *ngIf="item" class="col p-0">
                                                    <div *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')" class="flex-between" [ngClass]="{'fade-opacity-04' : (item.type=='pickup'&&item.status=='PICKED_UP'|| item.type=='pickup'&&item.status=='DELIVERED' || item.type=='delivery'&&item.status=='DELIVERED')}"> 
                                                        <div class="d-flex align-items-center col-8">
                                                            <img *ngIf="item?.deliveryOrderWay=='pickup'" src="assets/svg/table/ph_arrow_green.svg" alt="Delivered Planning Hub" class="me-2 w-19px">
                                                            <img *ngIf="item?.deliveryOrderWay=='delivery'" src="assets/svg/table/ph_arrow.svg" alt="Delivered Planning Hub" class="me-2 w-19px">
                                                            <img *ngIf="item?.deliveryOrderWay=='pickup_and_delivery'" src="assets/svg/table/dropoff-pickup.svg" alt="Delivered Planning Hub" class="me-2 w-19px">
                                                            <span class="text-10">{{item.orderCustomer}}</span>
                                                        </div>
                                                        <div class="d-flex align-items-center col-4" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                            <img src="assets\svg\table\clock.svg" alt="Available Vehicle" class="w-19px me-1">
                                                            <span class="text-10 color-var-500">{{item.startTime}} - {{item.endTime}}</span>
                                                        </div>
                                                        <!-- <div  class="d-flex align-items-center col-4" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                                            <span class="text-10 color-var-500">{{item.note}}</span>
                                                        </div> -->
                                                    </div>
                                                </div>
                                                
                                            </div>
    
                                        
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

    </div>
</div>
</form>
<div class="modal-footer d-flex align-items-center justify-content-end">
    <button class="custella-btn-white mx-1 text-12" (click)="openModal('modifyPlan',data)" translate>{{'back'}}</button>
    <p-button type="button" icon="pi pi-arrow-right" (click)="proceed()" label="{{'proceed' | translate}}"></p-button>

</div>



