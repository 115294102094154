import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { VehicleService } from "src/app/_services/vehicle.service";

import * as moment from "moment-timezone";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";

@Component({
  selector: "app-vehicle-history",
  templateUrl: "./vehicle-history.component.html",
  styleUrls: ["./vehicle-history.component.scss"],
})
export class VehicleHistoryComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public gridOptions!: Partial<GridOptions>;
  gridApi: any;
  logGridParams: any;
  rowLogData: any[] = [];
  noHistory: any;

  columnDefsLogs: any[] = [];
  defaultColDef: any;

  vehicleId: any;

  totalRecord: any;

  searchValue = "";

  constructor(
    private vehicleService: VehicleService,
    private route: ActivatedRoute
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "clientSide",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.columnDefsLogs = [
      {
        headerName: "Time & Date",
        field: "modifiedDate",
        width: 200,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            return moment(params.data.modifiedDate).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: "Field",
        field: "fieldName",
        width: 150,
        headerTooltip: "#",
      },
      {
        headerName: "Modified By",
        field: "modifiedByUser.fullName",
        width: 270,
        headerTooltip: "#",
      },
      {
        headerName: "Old Value",
        field: "oldValue",
        width: 270,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data.oldValue) {
            if (params.data.fieldName.includes("Date")) {
              return moment(params.data.oldValue).format(
                localStorage.getItem("date_format")!.toUpperCase()
              );
            } else {
              return params.data.oldValue;
            }
          }
        },
      },
      {
        headerName: "New Value",
        field: "newValue",
        width: 270,
        headerTooltip: "#",
        headerClass: "marging-auto hide-action-border-left",
        cellRenderer: function (params: any) {
          if (params.data.newValue) {
            if (params.data.fieldName.includes("Date")) {
              return moment(params.data.newValue).format(
                localStorage.getItem("date_format")!.toUpperCase()
              );
            } else {
              return params.data.newValue;
            }
          }
        },
      },
    ];
  }

  /******************************************** Init ******************************************/
  private init() {
    this.vehicleId = this.route.snapshot.queryParamMap.get("id");
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Grid Ready Logs ******************************************/
  public onGridReadyLogs(params: any) {
    this.gridApi = params.api;
    this.logGridParams = params;

    this.push(
      this.vehicleService
        .getAllVehicleLogs(this.vehicleId)
        .subscribe((data: any) => {
          if (data.status == 400) {
          } else {
            this.rowLogData = data.reverse(); //to show data in descending order

            if (!this.rowLogData.length) {
              //show like this only bcoz this is not pagination call
              this.noHistory = true;
            } else {
              this.noHistory = false;
            }

            this.totalRecord = this.rowLogData.length;
          }
        })
    );

    this.gridApi.sizeColumnsToFit();
  }
   push(obs:any) {
    super.push(obs);
  }
}
