<div class="modal-header p-dialog-header flex-between">
    <div class="">
        <span class="text-16 font-medium">{{'salesforce_integration'|translate}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body">
    <div class="container">
        <div class="flex-align-center justify-content-center">
            <img src="../../../../../assets/svg/salesforce.svg" class="w-40">
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" translate>{{'sync_customers'}}</span>
            <input pInputText autocomplete="off" name="username" [(ngModel)]="company.syncCustomerToSf">
           
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" translate>{{'sync_contacts'}}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncConstactToSf" name="syncConstactToSf"></p-checkbox>
            </div>
          
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" translate>{{'sync_job_orders'}}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncJoToSf" name="syncJoToSf"></p-checkbox>
            </div>
          
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" translate>{{'sync_sales_orders'}}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncSoToSf" name="syncSoToSf"></p-checkbox>
            </div>
          
        </div>
        <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" translate>{{'sync_products'}}</span>
            <div class="p-field-checkbox">
                <p-checkbox [binary]="true" [(ngModel)]="company.syncProductToSf" name="syncProductToSf"></p-checkbox>
            </div>
          
        </div>
    </div>
</div>
<div class="modal-footer p-0">
    <button class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
    <p-button label="{{'save' | translate}}" styleClass="mx-1" (click)="onSubmit()"></p-button>
</div>