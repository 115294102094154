<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_SetExportData"> {{ "export_data" | translate }} </span>
            <i class="pi pi-times" id="btn_SetExportDataClose"  (click)="modalRef.hide()"></i>
        </div>

        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 pb-3 m-0"
                style="height: fit-content;">
                <!--  -->
                <div class="px-4 py-2">
                    <div class="d-flex align-items-center justify-content-center m-4">
                        <div class="custella-import-checkpoint" id="label_SetExportDataCheck"></div>
                        <div class="custella-import-path-dash-export" id="label_SetExportDataGap"></div>
                        <div class="custella-import-uncheckpoint" id="label_SetExportDataUncheck"></div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center m-4">
                        <span class="text-12 font-medium ms-3" id="label_SetExportDataSelect" translate>{{'select'}}</span>
                        <div class="custella-import-path-none-export" id="label_SetExportDataNon"></div>
                        <span class="text-12 font-medium ms-3" id="label_SetExportDataDownload" translate>{{'download'}}</span>
                    </div>
                </div>
                <!-- Match Any -->
                <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center"
                    *ngIf="!isExport">
                    <span class="text-14 font-medium" id="title_SetExportModuleFields" translate>Module and Fields</span>
                </div>
                <form *ngIf="!isExport">
                    <div class="custella-form-container-content">
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                                <span class="custella-content-row-input-title" id="label_SetExportSelectModule" translate>{{'select_a_module'}}</span>
                                <!-- <p-dropdown optionLabel="name" placeholder="{{'select_a_module'|translate}}"></p-dropdown> -->
                                <p-dropdown [options]="moduleList" [showClear]="true" optionLabel="value"
                                    [ngModelOptions]="{standalone: true}" name="selectedModule"
                                    [(ngModel)]="selectedModule" (onChange)="onModuleChange()"
                                    placeholder="{{'select_a_module'|translate}}" id="picklist_SetExportSelectModuleField">
                                    <ng-template let-user pTemplate="item">
                                        <div class="ticket-owner-user-list" id="value_SetExportSelectModuleField">
                                            <div>{{ user.value }}</div>
                                        </div>
                                    </ng-template>
                                </p-dropdown>
                            </div>
                        </div>
                        
                    </div>
                    <div class="custella-form-container-content">
                        <div *ngIf="moduleFieldList?.length>0" class="custella-content-row mt-3">
                            <div class="custella-content-row-input col-12">
                                <div class="flex-between">
                                    <div class="custella-content-row-input-title" id="label_SetExportSelectModuleToExport"
                                        translate>Select fields to export
                                    </div>
                                    <div class="py-2 d-flex align-items-center " *ngIf="moduleFieldList?.length>0">
                                        <div class="p-field-checkbox">
                                            <p-checkbox name="selectAllFields" [value]="selectAllFields" id="check_SetExportSelectModuleAll"
                                                [(ngModel)]="selectAllFields" inputId="la" [binary]="true"
                                                (onChange)="onSelectedAllFieldChange($event)"
                                                [ngModelOptions]="{standalone: true}"></p-checkbox>
                                            <label for="la" class="p-chkbox-label" id="label_SetExportSelectModuleAll" style="margin-left: 5px;"
                                                translate>{{'select_all'}}</label>
                                        </div>
                                    </div>
                                </div>

                                <!-- <p-dropdown optionLabel="name" placeholder="{{'select_a_field_list'|translate}}">
                            </p-dropdown> -->
                                <div *ngFor="let field of moduleFieldList;let i = index;"
                                    class="py-2 d-flex align-items-center border-bottom">
                                    <div class="p-field-checkbox">
                                        <p-checkbox [(ngModel)]="field.checked" [name]="selectedModuleFieldList[i]"
                                            [value]="field.field" [binary]="true" inputId="ny"
                                            [ngModelOptions]="{standalone: true}" id="check_SetExportSelectField">
                                        </p-checkbox>
                                        <label for="ny" id="label_SetExportSelectField" style="margin-left: 5px;">{{field?.name}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="custella-form-container-footer-two">
                        <div class="d-flex align-items-center justify-content-end">
                            <button class="custella-btn-white text-12 ms-2" id="btn_SetHierExportModFieCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                            <p-button [label]="buttonLabel" (click)="next()" id="btn_SetHierExportModFieSubmit" styleClass="ms-2 h-32"></p-button>
                        </div>
                    </div>
                </form>

                <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center"
                    *ngIf="isExport">
                    <span class="text-14 font-medium" id="title_SetHierExportDownload" translate>{{"download"}}</span>
                </div>
                <div class="custella-form-container-content" *ngIf="isExport">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-12">
                            <div class="flex-between border-bottom"
                                style="margin-top: 10px; padding-bottom: 10px;">
                                <div>
                                    <i class="pi pi-file"></i>
                                    <span
                                        class="text-12 padding-left-10px padding-right-10px" id="value_SetHierExportDownloadFileName">{{downloadFileName}}</span>
                                </div>
                                <button pButton label="Download" icon="pi pi-download"
                                    class="p-button-outlined p-mr-2 p-mb-2" id="btn_SetHierExportDownload" (click)="exportData()"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-footer-two" *ngIf="isExport">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12 ms-2" id="btn_SetHierExportCancel" (click)="goBack()">Back</button>
                        <!-- <p-button [label]="buttonLabel"></p-button> -->
                    </div>
                </div>

            </div>
        </div>
    </form>
</div>