import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { DeliveryOrderService } from 'src/app/_services/delivery-order.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-split-order',
  templateUrl: './split-order.component.html',
  styleUrls: ['./split-order.component.scss']
})
export class SplitOrderComponent implements OnInit {
  title: any;
  data: any;
  depots: any;
  splitDOForm: UntypedFormGroup;

  dateConfig: any;
  modalState : any;
  selectedConfig : any;
  config:any = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true,
    class: 'custom-modal-xl-PlanningHub'
  };
  timezone: any;
  splitDoData = {deliveryOrderId: null,
    "depotId": null,
    "secondLegPickupDate": null,
    "timeZone":null
  }
  maxDate: any;

   constructor(public modalRef: BsModalRef,
      private modalService: BsModalService,
      private customersService: CustomersService,
      private deliveryOrderService : DeliveryOrderService, 
      private spinner : NgxSpinnerService,
      private auth: AuthenticationService,
      private router: Router,
      private errorUtil: ErrorUtil
      ) {
        this.splitDOForm = new UntypedFormGroup({
          'splitDate': new UntypedFormControl(null, [Validators.required]),
          'depot': new UntypedFormControl(null, [Validators.required])
        });
       }


  ngOnInit(): void {
  
  this.maxDate = new Date(this.data.deliveryOrder.pickupDate);

      console.log(this.data)
      this.timezone = this.auth.getUserTimezone();
      this.splitDoData.timeZone = this.timezone
      this.splitDoData.deliveryOrderId = this.data.deliveryOrder.id
      this.dateConfig = localStorage.getItem('date_format')
      this.customersService.getDeport().subscribe((res:any) => {
        console.log(res)
        this.depots = res; //depots dd
      })
    }
  
    onSubmitForm(){
      if(!this.splitDOForm.valid) {
        for (const i in this.splitDOForm.controls) {
          this.splitDOForm.controls[i].markAsTouched();
        }
        return false;
      }

     // this.spinner.show();
      var req: any = _.clone(this.splitDoData);
      req.secondLegPickupDate = moment(req.secondLegPickupDate).format('YYYY-MM-DD');
     console.log(req)
     try {
      this.deliveryOrderService.splitDo(req).subscribe((res:any)=>{
        this.spinner.hide();
        console.log(res,"=====")
          if(res.status === 200 || res.status === 201){
            this.modalRef.hide();
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate(['dashboard/deliveryOrders/view/'+this.data.deliveryOrder.id], { queryParams : {'doId' : this.data.deliveryOrder.id}});
            this.errorUtil.setErrorMessage(  200, null, 'Order '+this.data.deliveryOrder.orderNumber+' split successfully"',"success", 3000);

          }else {
            this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
          }
          
        });
      } catch (error) {
        console.error(error);
        //this.errorUtil.setErrorMessage(error.status, null ,  error.error.title, 'error',3000);
    }

    }

  }

