<div class="custella-modal">
    <form [formGroup]="cloneTaskForm">
        <div class="custella-modal-header align-items-center">
            <div class="d-flex align-items-center">
                <span class="text-14 font-medium" id="title_TaskClone">Clone Tasks</span>
                <!-- <span class="text-14 font-medium ms-2"> 08/11/2022 </span>     -->
            </div>
            <i class="pi pi-times" (click)="modalRef.hide()" [pTooltip]="'close'|translate" tooltipPosition="left" id="btn_TaskCloneClose"></i>
        </div>
        <div class="">
            <!-- Container -->
            <div class="custella-form-container position-relative d-block w-100 rounded-2 pb-3 m-0"
                style="height: fit-content">
                <div class="custella-form-container-title-two-grey">
                    <span class="text-14 font-medium" id="title_TaskCloneSelDates" translate>Select Clone Dates</span>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3 pe-0">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" id="label_TaskCloneFromDate" translate>Date to Clone Tasks from:</span>
                            <span class="p-input-icon-left w-100">
                                <i class="pi pi-calendar me-3"></i>
                                <p-calendar type="text" appendTo="body" id="picklist_TaskCloneFromDateField" placeholder="{{'select_date' | translate}}" (onSelect)="onPickDateChange($event)" [(ngModel)]="cloneTask.dateFrom" formControlName="dateFrom"  class="w-100" dateFormat="dd/mm/yy"  name="dateFrom" [yearNavigator]="true" yearRange="2000:2030"appendTo="body"></p-calendar>
                            </span>
                            <div class="text-10 p-error" *ngIf="cloneTaskForm.controls['dateFrom'].touched && cloneTaskForm.controls['dateFrom'].invalid">
                                <span *ngIf="cloneTaskForm.controls['dateFrom'].errors?.required" id="label_TaskCloneFromDateReqField" translate>{{'required_field'}}</span>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row mb-3 pe-0">
                        <div class="custella-content-row-input col-12">
                            <span class="custella-content-row-input-title" id="label_TaskCloneToDate" translate>Date to Clone Tasks to:</span>
                            <span class="p-input-icon-left w-100">
                                <i class="pi pi-calendar me-3"></i>
                                <p-calendar type="text" appendTo="body" id="picklist_TaskCloneToDateField" placeholder="{{'select_date' | translate}}" [(ngModel)]="cloneTask.date" formControlName="date"  class="w-100" dateFormat="dd/mm/yy"  name="orderDate" [yearNavigator]="true" yearRange="2000:2030"appendTo="body"></p-calendar>
                            </span>
                            <div class="text-10 p-error" *ngIf="cloneTaskForm.controls['date'].touched && cloneTaskForm.controls['date'].invalid">
                                <span *ngIf="cloneTaskForm.controls['date'].errors?.required" id="label_TaskCloneToDateReqField" translate>{{'required_field'}}</span>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center py-1">
                    <span class="text-14 font-medium" id="label_TaskCloneSelTask" translate>{{"select_tasks_to_clone"}}</span>
                    <div class="d-flex align-items-center p-2">
                        <div class="p-field-checkbox">
                            <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_TaskCloneSelTask" [(ngModel)]="taskCloneAll" (onChange)="selectAllTasks(taskCloneAll)"></p-checkbox>
                        </div>
                        <div class="text-left mx-2">
                            <span class="text-12 font-medium color-var-500" id="label_TaskCloneAllTask" translate>{{'all_tasks'}}</span>
                        </div>
                    </div>

                </div>
                <div class="custella-form-container-content">
                    <div class="custella-content-row" *ngFor="let event of tasks">
                        <div class="custella-content-row-input col-12">
                            <div class="d-flex align-items-center p-2">
                                <div class="p-field-checkbox">
                                    <p-checkbox [binary]="true" [ngModelOptions]="{standalone: true}" id="check_TaskCloneEventTask" [(ngModel)]="event.checked"></p-checkbox>
                                </div>
                                <div class="text-left mx-2" (click)="viewTaskDetail(event)">
                                    <span class="text-12 font-medium color-var-500" id="value_TaskCloneEventTaskName" translate>{{event.taskName}}</span>
                                    <span class="d-block text-12 text-wrap" id="value_TaskCloneEventTaskDateTime" translate>{{event.startDateTime |  momentDate : 'shortTime': timezone}} - {{event.endDateTime   |  momentDate : 'shortTime': timezone}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-footer-two">
                    <div class="d-flex align-items-center justify-content-end">
                        <button class="custella-btn-white text-12" id="btn_TaskCloneCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                        <p-button (click)="onSubmitForm('move')" id="btn_TaskCloneMove" styleClass="ms-2 h-32"  label="Move"></p-button>
                        <p-button (click)="onSubmitForm('clone')" id="btn_TaskClone" styleClass="ms-2 h-32" label="Clone"></p-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>