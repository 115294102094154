import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { RolesService } from 'src/app/_services/admin/roles.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { TeamService } from 'src/app/_services/team.service';
import { ErrorUtil } from 'src/app/_utilities/error';


@Component({
  selector: 'app-add-edit-team-members',
  templateUrl: './add-edit-team-members.component.html'
})
export class AddEditTeamMembersComponent implements OnInit {


  formData: any = {};
  submitTouched: boolean = false;
  users: any;
  addTeamMember: any = {role: null};
  addTeamUserForm: any;
  userSelect : any = [];
  mode: any;
  config = {
    displayKey:"name",
    search:true,
  };
  team: any;
  title: any;
  roles=[
    {label:'Manager',value:'MANAGER'},
    {label:'Member',value:'MEMBER'},
    {label:'View Only Access',value:'VIEW_ONLY'}
  ]
  data: any;
  constructor(public modalRef: BsModalRef,
     private userService: UsersService,
    private auth: AuthenticationService,
    private rolesService: RolesService,
    private teamService: TeamService,
    private errorUtil: ErrorUtil
    ) { 
  this.addTeamUserForm = new UntypedFormGroup({
      'members': new UntypedFormControl(null, [Validators.required]),
      'role': new UntypedFormControl(null, [Validators.required])
    });}

  ngOnInit(): void {
    console.log(this.data)
    this.team={id:this.data.teamId }
    this.getUsers(this.auth.getUserId());
  }

   getUsers(id : any) {
    this.userService.getAllUsers().subscribe((users:any)=>{
      for(var i=0;i<users.length;i++){
        users[i].name = users[i].firstName + ' ' + users[i].lastName;
      }
      this.users = users;
      //console.log('Users API', this.users);

      if(this.mode == 'add'){
        this.data.teamMembers.forEach((element : any)=> {
          this.users = _.remove(this.users, (o:any) => { 
            return o.id != element.userId; 
          });
        });
      }

      if(this.mode === 'edit'){
        this.userSelect = [this.data.userId]
        if(this.userSelect){
          this.addTeamUserForm.controls['members'].clearValidators();
          this.addTeamUserForm.controls['members'].updateValueAndValidity();
        }
        this.addTeamMember.role = this.data.poistion;
      } else {
        this.addTeamMember.role = null;
      }
    });
  }

  onSubmitForm() {
    this.submitTouched = true;
    if (!this.addTeamUserForm.valid) {
      for (const i in this.addTeamUserForm.controls) {
        this.addTeamUserForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      return false;
    }
    console.log(this.userSelect)
   
    if(this.mode === 'add'){
      this.formData =  {
        teamId: this.team.id,
        userIdList: this.userSelect,
        poistion: this.addTeamMember.role
      }

      this.teamService.addTeamMember(this.formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, 'Team member(s) added successfully' ,  null, 'success',1000);
       
        } else if(res.status === 400){
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
      });
    } else if(this.mode === 'edit'){
      this.formData =  {
        id : this.data.id,
        teamId: this.data.teamId,
        userId: this.userSelect[0],
        poistion: this.addTeamMember.role
      }

      this.teamService.editTeamMember(this.formData).subscribe((res:any)=>{
        //console.log("Response ", res);
        if(res.status === 200 || res.status === 201){
          this.modalRef.hide();
          this.errorUtil.setErrorMessage(200, 'Team member updated successfully' ,  null, 'success',1000);
         } else if(res.status === 400){
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
      });
    }
    //console.log('Form Submit ', this.team);
  }


}
