<form [formGroup]="breakDownForm">
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <span class="text-16 font-medium" translate>{{title}}</span>
            </div>
        <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
    <div class="modal-body tix pb-0 height-65 pt-2">
        <div class="rounded-2 mx-2">
            <div class="custella-form-container-content p-0 m-0">
                <div class="custella-content-row-input col-12 pb-3">
                    <span class="custella-content-row-input-title" translate>{{'vehicles'}}</span>
                    <div class="d-flex align-items-center">
                        <div class="p-input-icon-right w-100">
                            <p-dropdown  placeholder="Vehicles"  optionLabel="vehicleNumber" 
                            [filter]="false" 
                            filterBy="vehicleNumber" 
                            optionValue="id" 
                            [(ngModel)]="vehicleId" (onChange)="filterResults2()" formControlName="vehicle"
                            [options]="vehiclesData"  [showClear]="false">
                            </p-dropdown>
                        <div class="text-10 p-error" *ngIf="breakDownForm.controls['vehicle'].touched && breakDownForm.controls['vehicle'].invalid">
                            <span *ngIf="breakDownForm.controls['vehicle'].errors?.required" id="label_RequiredField" translate>{{'required_field'}}</span>
                        </div>
                        </div>
                      
                    </div>
                    
                </div>
            </div>
            <div *ngIf="dragableDataList"  class="custella-form-container mx-0" style="box-shadow: none; background: none;">
                <div class="custella-form-container-title-two-grey d-flex align-items-center rounded mt-1 py-1 px-3" >
                    <div class="col-3">
                        <span class="text-12 color-var-500" translate>{{'vehicle_name'}}</span>
                        <span class="text-12 font-black font-semiBold d-block" translate>{{dragableDataList?.vehicleName}}</span>
                    </div>
                    <div class="col-3">
                        <span class="text-12 color-var-500" translate>{{'utilization'}}</span>
                        <span *ngIf="dragableDataList?.capacity < 50" class="text-12 font-black font-semiBold d-block lowCapacity">{{dragableDataList?.capacity}}%</span>
                        <span *ngIf="dragableDataList?.capacity > 50 && dragableDataList?.capacity < 80" class="text-12 font-black font-semiBold d-block mediumCapacity">{{dragableDataList?.capacity}}%</span>
                        <span *ngIf="dragableDataList?.capacity > 80" class="text-12 font-black font-semiBold d-block highCapacity">{{dragableDataList?.capacity}}%</span>
                    </div>
                    <div class="col-3">
                        <span class="text-12 color-var-500" translate>{{'duration'}}</span>
                        <span class="text-12 font-black font-semiBold d-block" translate>{{dragableDataList?.duration | minutesSeconds}}</span>
                    </div>
                    <div class="col-3">
                        <span class="text-12 color-var-500" translate>{{'distance'}}</span>
                        <span class="text-12 font-black font-semiBold d-block" translate>{{dragableDataList?.distance | distanceToKm2}}</span>
                    </div>
                </div>
                <div class="custella-form-container-content px-0 mt-2 mb-1">
                    <div class="overflow-scroll height-45">
                        <div *ngFor="let item of dragableDataList?.relatedVehicleEvents;index as i" class="border rounded-1 flex-between mb-2 py-2 px-3">
                            
                            <div *ngIf="item" class="col p-0">
                                <div *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')" class="flex-between" [ngClass]="{'fade-opacity-04' : (item.type=='pickup'&&item.status=='PICKED_UP'|| item.type=='pickup'&&item.status=='DELIVERED' || item.type=='delivery'&&item.status=='DELIVERED')}"> 
                                    <div class="d-flex align-items-center col-4">
                                        <img *ngIf="item?.deliveryOrderWay=='pickup'" src="assets/svg/table/ph_arrow_green.svg" alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <img *ngIf="item?.deliveryOrderWay=='delivery'" src="assets/svg/table/ph_arrow.svg" alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <img *ngIf="item?.deliveryOrderWay=='pickup_and_delivery'" src="assets/svg/table/dropoff-pickup.svg" alt="Delivered Planning Hub" style="height: 20px;" class="me-2">
                                        <span class="text-12">{{item.orderCustomer}}</span>
                                    </div>
                                    <div class="d-flex align-items-center col-3" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                        <img src="assets\svg\table\clock.svg" alt="Available Vehicle" class="w-19px me-1">
                                        <span class="text-12 color-var-500">{{item.startTime}} - {{item.endTime}}</span>
                                    </div>
                                    <div  class="d-flex align-items-center col-5" *ngIf="item.type=='pickup' || (item.type=='delivery') || (item.type=='break')">
                                        <span class="text-12 color-var-500">{{item.note}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex align-items-center justify-content-end">
        <button class="custella-btn-white mx-1 text-12" (click)="openModal('modifyPlan',data)"translate>{{'back'}}</button>
        <p-button type="button" (onClick)="proceed()" icon="pi pi-arrow-right" label="{{'proceed' | translate}}"></p-button>

    </div>
</form>