import {
  AfterViewInit,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TabView } from "primeng/tabview";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import {
  COMPONENT_CREATION,
  HELPDESK_APP_EVENTS,
} from "src/app/_models/global.data.model";
import { BreadcrumbService } from "src/app/_services/breadcrumb.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { HeaderTitleService } from "src/app/_services/header-title.service";
import { MenuService } from "src/app/_services/menu.service";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { HelpdeskToastComponent } from "../../helpdesk/helpdesk-toast/helpdesk-toast.component";

@Component({
  selector: "app-view-ticket",
  templateUrl: "./view-ticket.component.html",
  styleUrls: ["./view-ticket.component.scss"],
})
export class ViewTicketComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, AfterViewInit
{
  activeIndex: any;
  public loadDetail = false;
  public loadConversation = false;
  public loadJobOrder = false;
  public loadTicketHistory = false;
  public loadSLA = false;
  public loadTicketAttachment = false;

  public items: any[] = [];

  public id: any;

  public ticketData: any;

  // data will be add dynamically on dynamic ticket view - via helpdesk ticket click
  public genericData: any;

  public isLoadedDynamically = false;

  public isHeaderValue = false;
  public headerValue = "";
  public toggleEditVisibility = false;

  @ViewChild(TabView, { static: false }) tabView!: TabView;

  menuType: any;
  refreshMenuType!: Subscription;
  salePer: boolean = false;
  servicePer: boolean = false;
  jobsAccess: any;

  update: any;
  delete: any;

  canClaim = false;

  @ViewChild("componentContainer", { read: ViewContainerRef }) container: any;

  private componentRef!: ComponentRef<any>;

  constructor(
    private breadCrumb: BreadcrumbService,
    private deleteRecordService: DeleteRecordService,
    private route: ActivatedRoute,
    private router: Router,
    private ticketService: TicketsService,
    private util: UtilServiceService,
    private headerTitleService: HeaderTitleService,
    private menuService: MenuService,
    private auth: AuthenticationService,
    private perm: PermissionService,
    private resolver: ComponentFactoryResolver
  ) {
    super();
    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.afterInit();
  }

  ngOnDestroy(): void {
    this.onDestory();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.refreshMenuType = this.headerTitleService.refreshMenuType$.subscribe(
      (res: any) => {
        this.menuType = res;
      }
    );

    this.delete = this.perm.capable("tickets", "remove");

    this.items = [
      {
        id: "btn_TixDelete",
        label: "Delete",
        command: (e: any) => {
          this.menuAction("delete");
        },
        visible: this.delete,
      },
    ];

    const sub = this.util.getData().subscribe((data: any) => {
      if (data) {
        if (data.action === HELPDESK_APP_EVENTS.CLEAR_HELPDESK_COMPONENT) {
          this.clearComponent();
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_CLAIM_SUCCESS) {
          this.getData(this.id).then(() => {
            this.processClaim();
          })
        }
      }
    });
  }

  /******************************************** Init ******************************************/
  private init() {
    this.update = this.perm.capable("tickets", "update");

    let profileInfo = JSON.parse(this.auth.getProfileInfo());

    this.salePer = this.menuService.findMenuPermissionsSALES(
      profileInfo.tenantCategories
    );
    this.servicePer = this.menuService.findMenuPermissionsSERVICE(
      profileInfo.tenantCategories
    );

    this.jobsAccess = this.perm.capable("job order", "read");

    this.id =
      this.route.snapshot.queryParamMap.get("id") ?? this.genericData.id;
    console.log(this.genericData);
    debugger
    if (this.genericData) {
      this.isLoadedDynamically = true;
      debugger
    }

    this.getData(this.id).then(() => {
      if (this.isLoadedDynamically === false) {
        setTimeout(() => {
          this.breadCrumb.setItems([
            {
              label: "Tickets",
              routerLink: ["/dashboard/tickets"],
              meta: { navigation: { root: true } },
            },
            {
              label: this.ticketData.ticketNo,
              meta: { navigation: { include: true } },
            },
          ]);
        }, 400);
      }

      setTimeout(() => {
        this.processClaim();
      }, 500);
    });

    this.headerValue = "Details";

    this.activeIndex =
      localStorage.getItem("activeIndex") == undefined
        ? 0
        : Number(localStorage.getItem("activeIndex"));

    setTimeout(() => {
      this.loadData(this.activeIndex, this.tabView);
    }, 500);
  }

  private afterInit() {
    this.menuType = this.headerTitleService.getCurrentMenuType();
  }

  /******************************************** Get Data ******************************************/
  private getData(id: any) {
    return new Promise<void>((resolve, reject) => {
      // get ticket data
      this.push(
        this.ticketService.getTicketById(this.id).subscribe((resp: any) => {
          this.ticketData = resp;
          resolve();
        })
      );
    });
  }

  /******************************************** Process Claim ******************************************/
  private processClaim() {
    if (this.ticketData) {
      if (this.ticketData.ticketOwner && this.ticketData.ticketOwner.id) {
        this.canClaim = false;
      } else {
        this.canClaim = true;
      }
    }
  }

  /******************************************** On Destroy ******************************************/
  private onDestory() {
    this.breadCrumb.setItems([]);

    if (this.refreshMenuType) {
      this.refreshMenuType.unsubscribe();
    }

    super.ngOnDestroy();
  }

  /******************************************** On Load Tab Header ******************************************/
  private loadData(e: any, tabView: TabView) {
    if (this.tabView.tabs[e]) {
      const headerValue = this.tabView.tabs[e].header;
      this.setTabHeader(headerValue);
    } else {
      const headerValue = this.tabView.tabs[0].header;
      this.setTabHeader(headerValue);
    }
  }

  /******************************************** Handle Tab Change ******************************************/
  public handleChange(e: any, tabView: TabView) {
    localStorage.setItem("activeIndex", e.index);
    this.activeIndex = e.index;
    const headerValue = tabView.tabs[e.index].header;

    this.setTabHeader(headerValue);
  }

  /******************************************** Set Tab Header ******************************************/
  private setTabHeader(headerValue: any) {
    this.headerValue = headerValue;

    if (this.headerValue === "Details") {
      this.loadDetail = true;
    }

    if (this.headerValue === "Conversation") {
      this.loadConversation = true;
    }

    if (this.headerValue === "Job Order") {
      this.loadJobOrder = true;
    }

    if (this.headerValue === "History") {
      this.loadTicketHistory = true;
    }

    if (this.headerValue === "SLA") {
      this.loadSLA = true;
    }

    if (this.headerValue === "Attachments") {
      this.loadTicketAttachment = true;
    }

    this.handleEditVisibility();
  }

  /******************************************** Handle Edit Visibility ******************************************/
  private handleEditVisibility() {
    if (this.headerValue === "Details") {
      this.toggleEditVisibility = true;
    } else {
      this.toggleEditVisibility = false;
    }
  }

  /******************************************** Three Dot Action ******************************************/
  public menuAction(action: string) {
    if (action === "delete") {
      const data = {
        deleteType: "Ticket",
        id: this.id,
        message: `Are you sure you want to delete ${this.ticketData.ticketNo} - ${this.ticketData.subject}?`,
      };

      this.push(
        this.deleteRecordService.getItems(data).subscribe(() => {
          if (this.isLoadedDynamically) {
            this.util.sendData({
              action: HELPDESK_APP_EVENTS.ON_DELETE_TICKET,
              data: {
                id: data.id,
              },
            });
          } else {
            this.router.navigate(["dashboard/tickets"]);
          }
        })
      );
    }
  }

  /******************************************** Edit ******************************************/
  public editTicket() {
    this.router.navigate(["dashboard/tickets/update/" + this.id], {
      queryParams: { id: this.id },
    });
  }
  push(obs: any) {
    super.push(obs);
  }

  /******************************************** Claim Ticket ******************************************/
  public claimTicket($event: any) {
    console.log($event);

    const dataToast = {
      coordinates: { x: 5, y: 55 },
      assigneeUser: this.auth.getUserId(),
      action: "Claim",
      rowData: {
        data: { id: this.ticketData.id },
      },
    };

    this.clearComponent().then(() => {
      this.createComponent(COMPONENT_CREATION.HELPDESK_TOAST, dataToast);
    });
  }

  private clearComponent() {
    return new Promise<void>((resolve, reject) => {
      if (this.container) {
        this.container.clear();

        if (this.componentRef) {
          this.componentRef.destroy();
        }
      }

      resolve();
    });
  }

  // pass any data to insert into component
  private createComponent(createWhat: COMPONENT_CREATION, genericData: any) {
    let factory: ComponentFactory<any>;

    if (createWhat === COMPONENT_CREATION.HELPDESK_TOAST) {
      factory = this.resolver.resolveComponentFactory(HelpdeskToastComponent);
      this.componentRef = this.container.createComponent(factory);
      console.log(this.componentRef);
      this.componentRef.instance.genericData = genericData;
      this.componentRef.instance.asIsComponent = true;
    }
  }
}
