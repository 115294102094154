<div class="custella-form-container left-right-space" (click)="onClickBackground()">
    <ng-container>
        <app-sla-response [id]="ticketsId"></app-sla-response>
    </ng-container>

    <div class="custella-form-container-title flex-between rounded-top">
        <div class="font-medium text-14" id="title_TixCustDet">{{ "general_details" | translate }}</div>
    </div>

    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixCust" translate>{{ "customer" }}</span>
                <a *ngIf="ticketDetails?.customer?.name" id="link_TixCust" class="custella-content-row-input-title-three-link" (click)="viewPage(ticketDetails, 'customer')" (contextmenu)="rightClickItem($event, 'customer')">
                    {{ ticketDetails?.customer?.name }}
                </a>
                <span *ngIf="!ticketDetails?.customer?.name" id="link_TixCust" class="custella-content-row-input-title-three">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixStatus" translate>{{ "status" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixStatus">
                    {{ ticketDetails?.ticketStatus?.fieldLabel ? ticketDetails?.ticketStatus?.fieldLabel : "-" }}
                    <span *ngIf=" ticketDetails?.ticketStatus?.name != 'Closed' &&  ticketDetails?.ticketStatus?.name != 'Canceled'" id="value_TixStatusTimeName" class="mb-0">
                        - {{ ticketDetails?.summaryHoursSinceStatusUpdate }} since {{ ticketDetails?.ticketStatus?.name | titlecase }}
                    </span>
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixOwner" translate>{{ "ticket_owner" }}</span>
                <a *ngIf="ticketDetails?.ticketOwner" id="link_TixOwner" class="custella-content-row-input-title-three-link" (click)="viewUser(ticketDetails?.ticketOwner?.id, false)" (contextmenu)="rightClickItem($event, 'ticketOwner')">
                    {{ ticketDetails?.ticketOwner?.fullName }}
                </a>
                <span class="custella-content-row-input-title-three" id="link_TixOwner" *ngIf="!ticketDetails?.ticketOwner">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixOpenDateTime" translate>{{ "open_date_and_time" }}</span>

                <p class="custella-content-row-input-title-three" id="value_TixOpenDateTime" *ngIf="ticketDetails?.ticketOpenedDateTime">
                {{ ticketDetails?.ticketOpenedDateTime | momentDate: "hh:mm a":timezone }} {{ ticketDetails?.ticketOpenedDateTime | momentDate: dateFormat:timezone }}
                </p>

                <p *ngIf="!ticketDetails?.ticketOpenedDateTime" id="value_TixOpenDateTime" class="custella-content-row-input-title-three">
                    -
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixType" translate>{{ "type" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixType">
                    {{ ticketDetails?.ticketType?.name ? ticketDetails?.ticketType?.name : "-" }}
                </p>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixDueDateTime" translate>{{ "due_date_due_time" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixDueDateTime" *ngIf="ticketDetails?.dueDate">
                    {{ ticketDetails?.dueDate | momentDate: "hh:mm a":timezone }} {{ ticketDetails?.dueDate | momentDate: dateFormat:timezone }}
                </p>

                <p *ngIf="!ticketDetails?.dueDate" id="value_TixDueDateTime" class="custella-content-row-input-title-three">
                    -
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixPriority" translate>{{ "priority" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixPriority">
                    {{ ticketDetails?.priority?.name ? ticketDetails?.priority?.name : "-" }}
                </p>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixCloseDateTime" translate>{{ "close_date_and_time" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixCloseDateTime" *ngIf="ticketDetails?.ticketCompletedDateTime">
                    {{ ticketDetails?.ticketCompletedDateTime | momentDate: "hh:mm a":timezone }} {{ ticketDetails?.ticketCompletedDateTime | momentDate: dateFormat:timezone }}
                </p>

                <p *ngIf="!ticketDetails?.ticketCompletedDateTime" id="value_TixCloseDateTime" class="custella-content-row-input-title-three">
                  -
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_TixDesc">{{ "ticket_description" | translate }}</div>
        <span *ngIf="ticketDetails?.summaryHoursSinceUpdated" id="value_TixUpdatedHours" class="mb-0 mr-2">{{ ticketDetails?.summaryHoursSinceUpdated }} since updated</span>
        <span *ngIf="!ticketDetails?.summaryHoursSinceUpdated" id="value_TixUpdatedHours" class="mb-0 mr-2">-</span>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-12">
                <span class="custella-content-row-input-title-two" id="label_TixSubject" translate>{{ "subject" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixSubject">
                    {{ ticketDetails?.subject ? ticketDetails?.subject : "-" }}
                </p>
            </div>
        </div>
    </div>

    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-12">
                <span class="custella-content-row-input-title-two" id="label_TixDesc" translate>{{ "description" }}</span>
                <div class="custella-content-row-input-title-three" id="value_TixDesc" *ngIf="ticketDetails?.description" [innerHTML]="ticketDetails?.description | safeHtml"></div>

                <p class="custella-content-row-input-title-three" *ngIf="!ticketDetails?.description" id="value_TixDesc">
                    -
                </p>
            </div>
        </div>
    </div>
    <!-- Asset Details -->
    <ng-container *ngIf="addonFeatureINVENTORY == 'INVENTORY'">
        <div class="custella-form-container-title-two flex-between" id="title_TixAsset" *ngIf="addonFeatureINVENTORY == 'INVENTORY'">
            <div class="font-medium text-14">{{ "asset_details" | translate }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_TixAsset" translate>{{ "asset" }}</span>
                    <div class="flex-align-center"  *ngIf="ticketDetails?.assets">
                        <span  *ngIf="ticketDetails?.assets" class="custella-content-row-input-title-three-link" id="link_TixAsset" (click)="viewPage(ticketDetails, 'assets')" (contextmenu)="rightClickItem($event, 'asset')">
                            {{ ticketDetails?.assets?.runningNo }} - {{ ticketDetails?.assets?.name }}
                        </span>
                        <span class="ms-2" pTooltip="Asset Summary" tooltipPosition="right" (click)="openRightNav(); $event.stopPropagation()">
                            <i class="fa-regular fa-square-arrow-up-right hvr"></i>
                        </span>
                    </div>
                    <span *ngIf="!ticketDetails?.assets" id="link_TixAsset">-</span>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_TixFaultType" translate>{{ "fault_type" }}</span>
                    <p class="custella-content-row-input-title-three" id="value_TixFaultType">
                        {{ ticketDetails?.faultType?.name ? ticketDetails?.faultType?.name : "-" }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Warranty, Resolution Type -->
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_TixWarranty" translate>{{ "warranty" }}</span>
                    <ng-container *ngIf="ticketDetails?.assets?.warrantyStartDate">
                        <p class="custella-content-row-input-title-three" id="value_TixWarranty">
                            {{ ticketDetails?.assets?.warrantyStartDate | momentDate: dateFormat:timezone }} - {{ ticketDetails?.assets?.warrantyEndDate | momentDate: dateFormat:timezone }}
                            <span class="p-error" *ngIf="expiredWarranty" style="padding-left: 5px;" id="label_TixWarrantyExpired"> ({{ "expired" | translate }})</span>
                        </p>
                    </ng-container>
    
                    <ng-container *ngIf="!ticketDetails?.assets?.warrantyStartDate">
                        <p class="custella-content-row-input-title-three" id="value_TixWarranty">-</p>
                    </ng-container>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_TixResolutionType" translate>{{ "resolution_type" }}</span>
                    <p class="custella-content-row-input-title-three" id="value_TixResolutionType">
                        {{ ticketDetails?.resolutionType?.name ? ticketDetails?.resolutionType?.name : "-" }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Resolution -->
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_TixResolution" translate>{{ "resolution" }}</span>
                    <p class="custella-content-row-input-title-three" id="value_TixResolution">
                        {{ ticketDetails?.resolution ? ticketDetails?.resolution : "-" }}
                    </p>
                </div>
            </div>
        </div>
    </ng-container>
    <!-- Contact Details -->
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_TixContaDet">
            {{ "contact_details" | translate }}
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixContaName" translate>{{ "contact_name" }}</span>
                <!-- <ng-template *ngIf="ticketDetails?.contact;">
                    <a *ngIf="ticketDetails?.contact && ticketDetails.contact.fullName && ticketDetails.contact.fullName !== null" class="custella-content-row-input-title-three-link" (click)="viewPage(ticketDetails, 'contact')">
                        {{ ticketDetails.contact.fullName }}
                    </a>
                    <span *ngIf="!ticketDetails?.contact || !ticketDetails.contact.fullName" class="custella-content-row-input-title-three">
                        -
                    </span>
                </ng-template> -->

                <p class="mb-1 normal-font custella-content-row-input-title-three" id="value_TixContaName">
                    {{ ticketDetails?.contact?.fullName ?? '-' }}
                </p>


            </div>

            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixContaPhone" translate>{{ "contact_phone" }}</span>
                <p class="mb-1 normal-font custella-content-row-input-title-three" id="value_TixContaPhone">
                    {{ ticketDetails?.contactPhone ? ticketDetails?.contactPhone : "-" }}
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixContaEmail" translate>{{ "contact_email" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixContaEmail" translate *ngIf="ticketDetails?.contactEmail">
                    {{ ticketDetails?.contactEmail }}
                </p>

                <p class="custella-content-row-input-title-three" id="value_TixContaEmail" *ngIf="!ticketDetails?.contactEmail">
                    -
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixContaRecipEmail" translate>{{ "recipient_cc_email" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixContaRecipEmail" translate>
                    {{ ticketDetails?.recipientCCEmail ? ticketDetails?.recipientCCEmail : "-" }}
                </p>
            </div>
        </div>
    </div>
    <!-- Additional Information -->
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_TixAddDet">
            {{ "additional_information" | translate }}
        </div>
    </div>

    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixChannel" translate>{{ "channel" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixChannel">
                    {{ ticketDetails?.channel?.name ? ticketDetails?.channel?.name : "-" }}
                </p>
            </div>

            <div class="custella-content-row-input col-6" *ngIf="permissions.projectShow && addonFeature.addonPROJECT == 'PROJECT'">
                <span class="custella-content-row-input-title-two" id="label_TixProj" translate>{{ "project" }}</span>
                <span class="custella-content-row-input-title-three-link" id="link_TixProj" translate *ngIf="ticketDetails?.project" (click)="view('project', ticketDetails?.project)">{{ticketDetails?.project.name}}</span>
                <span class="custella-content-row-input-title-three" id="link_TixProj" translate *ngIf="!ticketDetails?.project">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixDept" translate>{{ "department" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixDept">
                    {{ ticketDetails?.department?.name ? ticketDetails?.department?.name : "-" }}
                </p>
            </div>
            <div class="custella-content-row-input col-6" *ngIf="permissions.siteShow">
                <span class="custella-content-row-input-title-two" id="label_TixSite" translate>{{ "site" }}</span>
                <span class="custella-content-row-input-title-three-link" id="link_TixSite" translate *ngIf="ticketDetails?.site" (click)="view('site', ticketDetails?.site)">{{ticketDetails?.site.siteName}}</span>
                <span class="custella-content-row-input-title-three" id="link_TixSite" translate *ngIf="!ticketDetails?.site">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixGroup" translate>{{ "ticket_group" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixGroup" (click)="viewGroup(ticketDetails?.userGroup?.id)">
                    {{ ticketDetails?.userGroup?.name ? ticketDetails?.userGroup?.name : "-" }}
                </p>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixSla" translate>{{ "sla_package" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixSla" *ngIf="slaData && slaData.slaPackage">
                    {{ slaData?.slaPackage?.name }}
                </p>
                <p class="custella-content-row-input-title-three" id="value_TixSla" *ngIf="!slaData && slaSteps == 0">
                    -
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixDuplicate" translate>{{ "duplicate_ticket" }}</span>
                <a *ngIf="ticketDetails?.duplicateTicket?.ticketNo" id="link_TixDuplicate" class="custella-content-row-input-title-three-link" (click)="viewTicket(ticketDetails?.duplicateTicket?.id, false)">{{ ticketDetails?.duplicateTicket?.ticketNo }}</a>
                <span class="custella-content-row-input-title-three" id="link_TixDuplicate" *ngIf="!ticketDetails?.duplicateTicket?.ticketNo">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixCancelReason" translate>{{ "cancel_reason" }}</span>
                <p class="custella-content-row-input-title-three" id="value_TixCancelReason">
                    {{ ticketDetails?.cancelReason ? ticketDetails?.cancelReason : "-" }}
                </p>
            </div>
        </div>
    </div>
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_TixLatestEscJob">{{ "latest_escalated_job_order" | translate }}</div>

        <p *ngIf="ticketDetails?.summaryHoursSinceAssigned" class="mb-0 mr-2" id="label_TixLatestEscJobSinceAssign">
            {{ ticketDetails?.summaryHoursSinceAssigned }} since assigned
        </p>
    </div>

    <div class="custella-form-container-content-border-bottom text-12 font-medium">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixJob" translate>{{ "job_order" }}</span>
                <a *ngIf="ticketDetails?.firstJobOrder" id="link_TixJob" class="custella-content-row-input-title-three-link" (click)="viewPage(ticketDetails, 'jobOrderNumber')" (contextmenu)="rightClickItem($event, 'jobOrder')">
                    {{ ticketDetails?.firstJobOrder?.jobOrderNumber }}
                </a>
                <span *ngIf="!ticketDetails?.firstJobOrder" id="link_TixJob">-</span>
            </div>

            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_TixFieldAgent" translate>{{ "field_agent" }}</span>
                <a
                    *ngIf="ticketDetails?.firstJobOrder"
                    class="custella-content-row-input-title-three-link"
                    (click)="
                      viewUser(ticketDetails?.firstJobOrder?.orderOwner?.id, false)
                    "
                    (contextmenu)="rightClickItem($event, 'firstAgent')"
                    id="link_TixFieldAgent"
                >
                    {{ ticketDetails?.firstJobOrder?.orderOwner?.fullName }}
                </a>
                <span class="custella-content-row-input-title-three" id="link_TixFieldAgent" *ngIf="!ticketDetails?.firstJobOrder">-</span>
            </div>
        </div>
    </div>

    <app-system-information
        [createdByUser]="ticketDetails?.createdByUser"
        [createdDate]="ticketDetails?.createdDate"
        [modifiedByUser]="ticketDetails?.lastModifiedByUser"
        [lastModifiedDate]="ticketDetails?.lastModifiedDate"
    ></app-system-information>
</div>



<!-- Asset Summary -->
<!-- Asset Summary -->
<div class="right-pane-fr-summ">
    <app-summary-slider (onChageAssetStatus)="onChageAssetStatus($event)" (onClosetAsset)="closeRightNav()" [assetsSummary]="assetsSummary" (onAcceptAsset)="onAcceptAssetStatus($event)"></app-summary-slider>
</div>


<app-common-dialog-box (visibleOut)="visibleOut($event)" [data]="dialogStatusData" (onClickYes)="onClickDialogYes($event)"   [visible]="showStatusDialog"></app-common-dialog-box>