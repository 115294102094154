
import {of as observableOf,  Observable, throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';

@Injectable()
export class LoadingBayService {
public refreshList$: EventEmitter<any>;
public refreshLoadingBayList$: EventEmitter<any>;
public refreshDepotList$: EventEmitter<any>;
public refreshCheckList$: EventEmitter<any>;
public refresherCommissionList$: EventEmitter<any>;
public refresherCommissionRateList$: EventEmitter<any>;

private ENDPOINT_VEHICLETYPES = "/vehicle-types";
private ENDPOINT_LOADING_BAYS = "/loading-bays";
private ENDPOINT_CREATE_LOADING_BAYS = "/loading-bays";
private ENDPOINT_CREATE_LOADING_BAYS_V4 = "/v4/loading-bays";
private ENDPOINT_CONTACTS_BY_CUSTOMERS = "/v4/contacts/";
private ENDPOINT_DELETE_LOADING_BAYS = "/loading-bays/";
private ENDPOINT_CONSIGNOR_CONSIGNEES_BY_CUSTOMER = "/consignor-consignees/by-customer/";
//depot
private ENDPOINT_DEPOTS_FILTER = "/depots/filter";
private ENDPOINT_DELETE_DEPOTS = "/depots/";
private ENDPOINT_DEPOTS = "/depots";
private ENDPOINT_DEPOTS_BY_ID = "/depots/";
//sub-contractors
private ENDPOINT_SUB_CONTRACTOR_FILTER = "/sub-contractors/paged";
private ENDPOINT_DELETE_SUB_CONTRACTOR = "/sub-contractors/";
private ENDPOINT_SUB_CONTRACTOR = "/sub-contractors";
private ENDPOINT_SUB_CONTRACTOR_BY_ID = "/sub-contractors/";
//fleet configuration
private ENDPOINT_FLEET_CONFIG_ORG = "/fleet-configs/org";
private ENDPOINT_FLEET_CONFIG = "/fleet-configs";
//vehicle checklist
private ENDPOINT_VEHICLE_CHECKLIST = "/vehicle-checklists";
private ENDPOINT_VEHICLE_CHECKLIST_BY_ID  = "/vehicle-checklists/";

private _googleThemeJsonURL = '/assets/google-theme-data.json'; 



constructor(private http: HttpClient,) {
    this.refreshList$ = new EventEmitter();
    this.refreshLoadingBayList$ = new EventEmitter();
    this.refreshDepotList$ = new EventEmitter();
    this.refreshCheckList$ = new EventEmitter();
    this.refresherCommissionList$ = new EventEmitter();
    this.refresherCommissionRateList$ = new EventEmitter();
  }
/************************************* Get Customer Payment History *************************************************************************/

public getLoaingBaysByCustomer(searchValue :any, customerId:any, parameters:any){
  let params = new HttpParams();
 
   params = params.set('origin', 'customer');
   
   if(searchValue !== '' && searchValue != null){
    params = params.set('search', searchValue);
  }
    if(customerId !== '' && customerId != null){
      params = params.set('customerId', customerId);
    }
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    
  return this.http.get( environment.base_url+'/loading-bays/paged', {params : params} ).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
  //console.log(error);
    return throwError( error );
  }),);
}
  // sub-contractor 
  public getAllSubContractors(startFrom: any,perPage: any,search: any,columnName: any,sortOrder: any){
    let params = new HttpParams()
    if(startFrom)
      params = params.set('startFrom', startFrom);
    if(perPage)
      params = params.set('perPage', perPage);
    if(search)
      params = params.set('search', search);
    if(columnName)
      params = params.set('columnName', columnName);
    if(sortOrder)
      params = params.set('sortOrder', sortOrder);
    return this.http.get( environment.base_url+this.ENDPOINT_SUB_CONTRACTOR_FILTER, {observe: "response", params : params}).pipe(map( (resp:any) => {
      if(resp.status == 204){
        return false;
      } else {
        return resp.body;
      }
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  public getVehiclersList(vehicleTypeId: any,searchValue: any,status: any,parameters: any,subContractorId: any){
    let params = new HttpParams();

    if(vehicleTypeId !== ''){
      params = params.set('vehicleTypeId', vehicleTypeId);
    }
    if(status !== ''){
      params = params.set('vehicleStatus', status);
    }
    if(searchValue !== '' && searchValue != null){
      params = params.set('search', searchValue);
    }
    if(parameters)
      params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    if(subContractorId ){
      params = params.set('subContractorId', subContractorId);
    }
    return this.http.get( `${environment.base_url}/vehicles`, {params : params} ).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return observableOf( error );
    }),);
  }


  public deleteSubContractors(id: any){
    return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_SUB_CONTRACTOR+id, {observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
//add save 
public createSubContractors(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_SUB_CONTRACTOR, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//edit save 
public editSubContractors(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_SUB_CONTRACTOR, body, { observe: 'response' }).pipe(map( (resp:any) => {
  this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
  
//edit data call
public getSubcontractorById(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_SUB_CONTRACTOR_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
  }
  ),catchError( error => {
      return observableOf( error );
  }),);
}

  // --------------------------------------------------------------------------------------------------

  //depot grid
public getAllDepots(startFrom: any,perPage: any,search: any,columnName: any,sortOrder: any){
  let params = new HttpParams()
  if(startFrom)
    params = params.set('startFrom', startFrom);
  if(perPage)
    params = params.set('perPage', perPage);
  if(search)
    params = params.set('search', search);
  if(columnName)
    params = params.set('columnName', columnName);
  if(sortOrder)
    params = params.set('sortOrder', sortOrder);
  return this.http.get( environment.base_url+this.ENDPOINT_DEPOTS_FILTER, {observe: "response", params : params}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

public deleteDepot(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_DEPOTS+id, {observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshDepotList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//add save 
public createDepot(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_DEPOTS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshDepotList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//edit save 
public editDepot(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_DEPOTS, body, { observe: 'response' }).pipe(map( (resp:any) => {
  //this.refreshList$.emit(resp);
  this.refreshDepotList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//edit data call
public getEditDepot(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_DEPOTS_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
  }
  ),catchError( error => {
      return observableOf( error );
  }),);
}
// --------------------------------------------------------------------------------------------------
//dd
public getConsignorConsignee(customerId: any){
  return this.http.get( environment.base_url+this.ENDPOINT_CONSIGNOR_CONSIGNEES_BY_CUSTOMER+customerId, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
  //types dd
  public getVehicleTypes(){
    return this.http.get( environment.base_url+this.ENDPOINT_VEHICLETYPES, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }
  //names dd
  public getContacts(customerId: any){
    let params = new HttpParams();
    params = params.set('customerId', customerId);
    return this.http.get( environment.base_url+this.ENDPOINT_CONTACTS_BY_CUSTOMERS, { observe: 'response', params : params }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  public deleteLoadingBay(id: any){
    return this.http.delete( environment.base_url+this.ENDPOINT_DELETE_LOADING_BAYS+id, {observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshLoadingBayList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

//add save 
public createLoadingBay(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_CREATE_LOADING_BAYS_V4, body, { observe: 'response' }).pipe(map( (resp:any) => {
      this.refreshLoadingBayList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  //edit save 
  public editLoadingBay(body:any){
    return this.http.put( environment.base_url+this.ENDPOINT_LOADING_BAYS, body, { observe: 'response' }).pipe(map( (resp:any) => {
    //this.refreshList$.emit(resp);
    this.refreshLoadingBayList$.emit(resp);
      return resp;
    }
  ),catchError( error => {
        return observableOf( error );
    }),);
  }

  //edit data call
  public getEditLoadingBay(id: any){
    return this.http.get( environment.base_url+this.ENDPOINT_DELETE_LOADING_BAYS+id, { observe: 'response' }).pipe(map( (resp:any) => {
        return resp;
    }
    ),catchError( error => {
        return observableOf( error );
    }),);
}

// -------------------------------------------fleet configuration-----------------------------------
public getFleetConfig(){
  return this.http.get( environment.base_url+this.ENDPOINT_FLEET_CONFIG_ORG, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
  }
  ),catchError( error => {
      return observableOf( error );
  }),);
}

public editFleetConfig(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_FLEET_CONFIG, body, { observe: 'response' }).pipe(map( (resp:any) => {
  this.refreshList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
// -------------------------------------------Vehicle checklist-----------------------------------

public getAllVehicleChecklist(){
  return this.http.get( environment.base_url+this.ENDPOINT_VEHICLE_CHECKLIST, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//edit data call
public getEditCheckList(id: any){
  return this.http.get( environment.base_url+this.ENDPOINT_VEHICLE_CHECKLIST_BY_ID+id, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
  }
  ),catchError( error => {
      return observableOf( error );
  }),);
}

//add save 
public createCheckList(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_VEHICLE_CHECKLIST, body, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshCheckList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//edit save 
public editCheckList(body:any){
  return this.http.put( environment.base_url+this.ENDPOINT_VEHICLE_CHECKLIST, body, { observe: 'response' }).pipe(map( (resp:any) => {
  this.refreshCheckList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}

//delete
public deleteCheckList(id: any){
  return this.http.delete( environment.base_url+this.ENDPOINT_VEHICLE_CHECKLIST_BY_ID+id, {observe: 'response' }).pipe(map( (resp:any) => {
    this.refreshCheckList$.emit(resp);
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}


public getAllLoadingBaylist(customerId: any){

  return this.http.get( environment.base_url+'/loading-bays/and-working-hours?customerId='+customerId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}


public getGoogleTheme(){
  return this.http.get(this._googleThemeJsonURL).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}


/*============================================ Get All Commission ================================*/
public getAllCommission(){
  return this.http.get( environment.base_url+'/do-commission-rates').pipe(map( (resp:any) => {
   return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
public createCommission(body:any){
  return this.http.post( environment.base_url+'/do-commission-rates', body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherCommissionList$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return observableOf( error );
  }),);
}

public getCommissionById(CommissionId: any){
  return this.http.get( environment.base_url+'/do-commission-rates/' + CommissionId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
public updateCommission(body:any){
  return this.http.put( environment.base_url+'/do-commission-rates', body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherCommissionList$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return observableOf( error );
  }),);
}

public deleteCommission(id: any){
  return this.http.delete( environment.base_url+'/do-commission-rates/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCommissionList$.emit('Deleted Commission');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return observableOf( error );
  }),);
} 

/** ===== */
public getAllCommissionRate(id: any){
  return this.http.get( environment.base_url+'/depot-rates-by-commission-rate/'+id).pipe(map( (resp:any) => {
   return resp;
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
public createCommissionRate(body:any){
  return this.http.post( environment.base_url+'/depot-rates', body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherCommissionRateList$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return observableOf( error );
  }),);
}

public getCommissionRateById(CommissionId: any){
  return this.http.get( environment.base_url+'/depot-rates/' + CommissionId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return observableOf( error );
  }),);
}
public updateCommissionRate(body:any){
  return this.http.put( environment.base_url+'/depot-rates', body, { observe: 'response' }).pipe(map( (resp:any) => {
    console.log(resp)
    this.refresherCommissionRateList$.emit(body);
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return observableOf( error );
  }),);
}

public deleteCommissionRate(id: any){
  return this.http.delete( environment.base_url+'/depot-rates/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    this.refresherCommissionRateList$.emit('Deleted Deport Rate');
    return resp;
  }
),catchError( error => {
  //console.log(error);
      return observableOf( error );
  }),);
}

}