import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { VehicleService } from "src/app/_services/vehicle.service";

import * as moment from "moment-timezone";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { CustomTooltip } from "src/app/renderer/custom-tooltip.component";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-vehicle-activity",
  templateUrl: "./vehicle-activity.component.html",
  styleUrls: ["./vehicle-activity.component.scss"],
})
export class VehicleActivityComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public totalRecord: any;
  public searchValue = "";

  activityList = [];
  public gridOptionsActivity!: Partial<GridOptions>;
  gridApiActivity: any;
  gridColumnApiActivity: any;
  columnDefsActivity: any;
  defaultColDefActivity: any;
  pageSizeActivity: any = 25;
  gridParamsActivity: any;
  overlayNoRowsTemplateActivity: any;
  parametersActivity: any;
  startFromActivity: any;
  noActivity: boolean = false;
  orgSettings: any;
  addonFeature!: string;
  frameworkComponents: any;

  public vehicleId: any;

  constructor(
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalServiceService,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      // customTooltip: CustomTooltip,
    };

    this.overlayNoRowsTemplateActivity =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

    this.gridOptionsActivity = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefsActivity = [
      {
        headerName: "Type",
        field: "type",
        width: 200,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.type) {
            return (
              params.data.type.charAt(0).toUpperCase() +
              params.data.type.slice(1).toLowerCase()
            );
          }
        },
      },
      {
        headerName: "Time & Date",
        field: "lastModifiedDate",
        width: 200,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params && params.data) {
            return moment(params.data.lastModifiedDate).format(
              "hh:mm A" +
                ", " +
                localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: "Description",
        width: 200,
        field: "note",
        tooltipField: "note",
        headerTooltip: "#",
      },
      {
        headerName: "Driver",
        field: "driver.user.fullName",
        width: 220,
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        headerTooltip: "#",
      },
      {
        headerName: "Order",
        field: "deleveryOrder.orderNumber",
        width: 220,
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        headerTooltip: "#",
      },
      {
        headerName: "Map",
        field: "map",
        width: 170,
        sortable: false,
        headerClass: "marging-auto hide-action-border",
        cellStyle: { "text-align": "center" },
        cellRenderer: function (params: any) {
          if (
            params.data &&
            params.data.locationLat !== null &&
            params.data.locationLong !== null
          ) {
            return ' <i class="pi pi-map-marker"></i>';
          }
        },
        headerTooltip: "#",
      },
    ];

    this.defaultColDefActivity = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      // tooltipComponent: "customTooltip",
      tooltipComponent: TableHeaderToolTipComponent,
    };
  }

  /******************************************** Init ******************************************/
  private init() {
    this.vehicleId = this.route.snapshot.queryParamMap.get("id");
  }

  /******************************************** Init ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Grid Ready ******************************************/
  public onGridReadyActivity(params: any) {
    this.gridParamsActivity = params;
    this.gridApiActivity = params.api;
    this.gridColumnApiActivity = params.columnApi;

    var dataSource = {
      getRows: (params: any) => {
        this.startFromActivity = params.startRow;

        if (params.sortModel.length <= 0) {
          this.parametersActivity = { colId: "lastModifiedDate", sort: "desc" };
          params.sortModel.push(this.parametersActivity);
        }

        this.push(
          this.vehicleService
            .getActivityList("", this.vehicleId, this.searchValue, params)
            .subscribe((data: any) => {
              this.gridApiActivity.hideOverlay();

              this.activityList = data.data;

              this.totalRecord = data.total;

              if (data.status === "NO_DATA") {
                this.noActivity = true;
              }

              if (this.activityList.length === 0) {
                this.gridApiActivity.showNoRowsOverlay();

                params.successCallback([], 0);
              } else {
                this.activityList.forEach((element: any) => {
                  element.type = element.type.replace("_", " ");
                  element.subType = element.subType.replace("_", " ");
                });

                params.successCallback(this.activityList, data.total);
              }
            })
        );
      },
    };

    if (this.gridApiActivity) {
      this.gridApiActivity.sizeColumnsToFit();
      this.gridApiActivity.setDatasource(dataSource);
    }
  }

  /******************************************** On Click Cell ******************************************/
  public goToViewmap(e: any) {
    if (
      e.colDef.field === "map" &&
      e.data.locationLat !== null &&
      e.data.locationLong !== null
    ) {
      const data = {
        lat: e.data.locationLat,
        lng: e.data.locationLong,
      };

      this.modalService.openModal("viewMapPin", data);
    } else if (e.colDef.field === "deleveryOrder.orderNumber") {
      this.router.navigate(
        ["dashboard/deliveryOrders/view/" + e.data.deleveryOrder.id],
        {
          queryParams: { doId: e.data.deleveryOrder.id },
        }
      );
    } else if (e.colDef.field === "driver.user.fullName") {
      this.router.navigate(["dashboard/setup/drivers/view"], {
        queryParams: { id: e.data.driver.user.id ,edit:false},
      });
    }
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    this.onGridReadyActivity(this.gridParamsActivity);
  }

  /******************************************** Right Click Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";

    let results = [];

    if (params.column.colId == "driver.user.fullName") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    if (params.column.colId == "deleveryOrder.orderNumber") {
      const data = params.node.data;

      results.push({
        name: "Open link in new tab",
        action: () => this.redirectToNewTab(data),
      });
    }

    return results;
  };

  /******************************************** Redirect To ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    if (data.field === "driver.user.fullName") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(["dashboard/setup/drivers/view"], {
          queryParams: { id: data.driver.user.id ,edit:false},
        })
      );
    }

    if (data.field === "deleveryOrder.orderNumber") {
      url = this.router.serializeUrl(
        this.router.createUrlTree(
          ["dashboard/deliveryOrders/view/" + data.deleveryOrder.id],
          {
            queryParams: { doId: data.deleveryOrder.id },
          }
        )
      );
    }

    if (url) {
      url = url.replace("%23", "#");
      this.util.openItemToNewTab(url);
    }
  }
   push(obs:any) {
    super.push(obs);
  }
}
