<div class="custella-setup-body pt-0">
    <div class="custella-form-container left-right-space mt-56px rounded-2">
      <form  [formGroup]="connectForm">
        <!-- Container Content -->
        <div class="custella-form-container-title text-14 font-medium rounded-top" id="title_SetInvLocDets" translate>{{"location_details"}}</div>
        <div class="custella-form-container-content">
          <div class="custella-content-row mb-3">
            <div class="custella-content-row-input col-6">
              <span class="custella-content-row-input-title mandatory" id="label_SetInvLocName" translate> {{"client_id"}} </span>
              <input type="text" pInputText autocomplete="off"  [(ngModel)]="connect.clientId" id="input_SetInvLocNameField" formControlName="clientId" />
              <div *ngIf="connectForm.controls['clientId'].touched && connectForm.controls['clientId'].invalid">
                <span class="text-10 p-error" *ngIf="connectForm.controls['clientId'].errors?.required" id="label_SetInvLocNameReqField" translate>{{'required_field'}}</span>
              </div>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title mandatory" id="label_SetInvLocName" translate> {{"client_secret"}} </span>
                <input type="text" pInputText autocomplete="off" placeholder="{{'client_id' | translate}}" [(ngModel)]="connect.clientSecret" id="input_SetInvLocNameField" formControlName="clientSecret" />
                <div *ngIf="connectForm.controls['clientSecret'].touched && connectForm.controls['clientId'].invalid">
                  <span class="text-10 p-error" *ngIf="connectForm.controls['clientSecret'].errors?.required" id="label_SetInvLocNameReqField" translate>{{'required_field'}}</span>
                </div>
              </div>
          </div>
         
        </div>
      </form>
  
      <!-- Container Footer -->
      <div class="custella-form-container-footer">
        <div class="d-flex align-items-center justify-content-end">
          <p-button [disabled]="submitTouched" [label]="'Save'" styleClass="ms-2 h-32" id="btn_SetInvLocSubmit" (click)="connectSalesforce()"></p-button>
        </div>
      </div>
    </div>
  </div>
  