<!-- <div class="modal-content modal-fullscreen p-0"> -->
<div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">
        <span class="text-16 font-medium ms-3" id="title_TaskAddMember">{{title}}</span>
    </div>
    <i class="pi pi-times w-24px" id="btn_TaskAddMemberClose" (click)="modalRef.hide()"></i>
</div>

<div class="modal-body pt-0">
    <div class="container text-center px-0" style="padding: 20px;">
        <p-autoComplete (onSelect)="addUser($event)" field="fullName" [suggestions]="users"
            (completeMethod)="getSearchedUserList($event)" placeholder="Enter User Name" id="picklist_TaskAddMemberSearchUser"
            [(ngModel)]="searchUserVal" (onClear)="removeUser()">
            <ng-template let-user pTemplate="item">
                <div class="country-item d-flex">
                    <p-avatar id="img_TaskMemberAvatar" *ngIf="!user.imageUrl || user.imageUrl == ''"
                        image="assets/svg/Avatar.svg" styleClass="mr-2 mt-1" shape="circle"> </p-avatar>
                    <p-avatar id="img_TaskMemberAvatar" *ngIf="user.imageUrl && user.imageUrl != ''"
                        [image]="user.imageUrl" styleClass="mr-2 mt-1" shape="circle"> </p-avatar>
                    <div class="mx-2 mt-2">{{user.fullName}}</div>
                </div>
            </ng-template>
        </p-autoComplete>

    </div>
</div>
<div class="modal-footer p-0">
    <button type="button" id="btn_TaskAddMemberCancel" class="custella-btn-white mx-1 text-12"
        (click)="modalRef.hide()">{{'cancel' | translate}}</button>
    <p-button type="button" id="btn_TaskAddMemberSubmit" [disabled]="submitTouched || selectedUsers.length == 0"
        (click)="onSubmit()" label="{{'save' | translate}}"></p-button>
</div>



<!-- </div> -->