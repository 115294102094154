import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { HelpdeskService } from "src/app/_services/helpdesk.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-helpdesk-toast",
  templateUrl: "./helpdesk-toast.component.html",
  styleUrls: ["./helpdesk-toast.component.scss"],
})
export class HelpdeskToastComponent implements OnInit {
  @Output() emitCloseToast = new EventEmitter();
  @Input() genericData: any;
  @Input() asIsComponent: any;

  public barWidth: number = 100;
  private intervalTimer: any;
  private userId = null;

  public operation!: string;
  public userfullname!: string;

  public element = {
    coordinates: {
      top: "0px",
      right: "0px",
    },
  };

  private hasUndo = false;

  private allSubs: Subscription[] = [];

  constructor(
    private helpDeskService: HelpdeskService,
    private utilService: UtilServiceService
  ) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }

  /******************************************** init ******************************************/
  private init() {
    // listen to route changes - if got during assign or claim - STOP
    const sub = this.utilService.getData().subscribe((data: any) => {
      if (
        data &&
        data.action === HELPDESK_APP_EVENTS.ON_HELPDESK_ROUTE_CHANGE
      ) {
        this.hasUndo = true;
      }
    });

    this.allSubs.push(sub);

    console.log(this.genericData);
    // console.log(this.getToastWidth());

    // call to remove row
    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.REMOVE_HELPDESK_TABLE_ROW,
      data: this.genericData.rowData,
    });

    // set position of element
    // x/y - extra width cut off
    const extraY = 70;
    const extraX = 130;

    let top = 0;
    let right = 0;

    if (!this.asIsComponent || this.asIsComponent === false) {
      top = this.genericData.coordinates.y - extraY;
      right = this.genericData.coordinates.x + extraX;
    } else {
      top = this.genericData.coordinates.y;
      right = this.genericData.coordinates.x;
    }

    // console.log(this.genericData);
    // console.log(this.genericData);

    // console.log("x", right, "y", top);

    this.element.coordinates["top"] = `${top}px`;
    this.element.coordinates["right"] = `${right}px`;

    // console.log('INIT HELPDESK TOAST', this.element);

    // set toast action
    this.operation = this.genericData.action;
    // set user details
    this.userId = this.genericData.assigneeUser.id;

    if (this.genericData.action === "assign") {
      this.userfullname =
        this.genericData.assigneeUser.firstName +
        " " +
        this.genericData.assigneeUser.lastName;
    } else {
      this.userfullname = "you";
    }

    this.intervalTimer = setInterval(() => {
      this.barWidth -= 1;

      if (this.barWidth <= 0) {
        if (this.intervalTimer) {
          clearInterval(this.intervalTimer);
          // console.log(this.intervalTimer, this.hasUndo, "cleared Timer");
          if (this.hasUndo === false) {
            this.submitChanges();
          }
        }
      }
    }, 50);
  }

  /******************************************** loop until we get width ******************************************/
  private getToastWidth(): number {
    let width = 0;

    while (width === 0) {
      let element = document.querySelector(
        ".helpdesk-toast-container"
      ) as HTMLDivElement;

      if (element && "clientWidth" in element) {
        width = element.clientWidth;
      }
    }

    return width;
  }

  /******************************************** on close toast ******************************************/
  public closeToast(): void {
    this.cleanUp();

    // this.emitCloseToast.emit({
    //   isToastClosed: true
    // });

    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.CLEAR_HELPDESK_COMPONENT,
      data: {},
    });
  }

  public onUndo() {
    this.hasUndo = true;

    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.UNDO_TICKET,
      data: {},
    });

    // revert changes
    this.closeToast();
  }

  /******************************************** submit changes ******************************************/
  public submitChanges() {
    const rowData = this.genericData.rowData.data;
    // submit the changes
    // console.log(this.genericData);

    if (this.userfullname !== "you") {
      // assign
      const params = {
        id: rowData.id,
        userId: this.userId,
      };
      const sub = this.helpDeskService
        .setAssignClaimToTickets(params)
        .subscribe((resp) => {
          if (resp) {
            console.log("RESPONSE ASSIGN ", resp);
            
            this.closeToast();
          }
        });

      this.allSubs.push(sub);
    } else {
      // claim
      const params = {
        id: rowData.id,
      };

      const sub2 = this.helpDeskService
        .setAssignClaimToTickets(params)
        .subscribe((resp) => {
          console.log("RESPONSE CLAIM ", resp);
          if (resp) {
            this.utilService.sendData({
              action: HELPDESK_APP_EVENTS.ON_CLAIM_SUCCESS,
              data: null
            })
            this.closeToast();
          }
        });

      this.allSubs.push(sub2);
    }
  }

  /******************************************** clean up ******************************************/
  private cleanUp(): void {
    this.allSubs.forEach((sub) => {
      if (sub) {
        sub.unsubscribe();
      }
    });
  }
}
