import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridOptions } from 'ag-grid-community';
import * as _ from 'lodash';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { locatns } from 'src/app/dashboard/iqc-fqc/IqcFqc';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { ErrorUtil } from 'src/app/_utilities/error';


@Component({
  selector: 'app-add-task-question',
  templateUrl: './add-task-question.component.html',
  styleUrls: ['./add-task-question.component.scss']
})
export class AddTaskQuestionComponent implements OnInit {
  display: boolean = true;
  chipValue!: string[];
  buttonLabel: string = 'Save';

  skipTaskListIds: any = [];
  id: any;
  _id: any;
  mode: any = 'IQC';
  equipmentList: any = [];
  makeList: any = [];
  manufacturerList: any = [];
  departmentList: any = [];
  filter: any = { equipmentType: null, manufacturer: null, makeModel: null, taskType: null, location: null, departmentId: null };
  taskTriggers: any = {
    questionId: null,
    answerId: null,
    maintenanceTaskId: null,
    tasks: []
  }
  taskTriggersIqcFqc: any = {
    jobOrderId: null,
    currentUserId: null,
    taskListIds: []
  }
  taskForm!: UntypedFormGroup;
  locations = locatns;
  taskTypes = [{ name: 'Preventive Maintenance Minor', label: 'Preventive Maintenance Minor' },
  { name: 'Preventive Maintenance Major', label: 'Preventive Maintenance Major' },
  { name: 'IQC', label: 'IQC' },
  { name: 'FQC', label: 'FQC' }];


  subscriber: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  subscriber2: Subscription = new Subscription();
  subscriber3: Subscription = new Subscription();
  subscriber4: Subscription = new Subscription();
  subscriber5: Subscription = new Subscription();
  subscriber6: Subscription = new Subscription();

  /*======================  ===================*/
  gridApi: any;
  logGridParams: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  searchValue: any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  pageSize: any = 25;
  taskTypeList = [];
  public gridOptions: Partial<GridOptions> | any;
  rowSelection: any;
  selectAllChecked: boolean = false;
  formData: any = { tasks: [] };
  orderGridTotalLength: any;
  noTaskType: boolean = false;
  displayTaskicon: boolean = false;
  overlayNoRowsTemplate: any;
  submitTouched: boolean = true;
  fromPage: any;
  userId: string = '';
  taskTrigerList: any;

  @ViewChild("myDropDown", { static: false }) myDropDown: Dropdown | any;
  @Output() action = new EventEmitter();
  initialState: any = {};

  data:any

  constructor(public modalRef: BsModalRef,
    public options: ModalOptions,
    private iqcfqcService: IqcFqcService,
    private errorUtil: ErrorUtil,
    private cd: ChangeDetectorRef,
    private auth: AuthenticationService) {
    this.taskForm = new UntypedFormGroup({
      'equipment': new UntypedFormControl(null),
      'taskType': new UntypedFormControl(null),
      'location': new UntypedFormControl(null),
      'makeModel': new UntypedFormControl(null),
      'manufacturer': new UntypedFormControl(null),
      'departmentId': new UntypedFormControl(null),
      'searchValue': new UntypedFormControl(null)
    });

    this.initialState = options.initialState;
    this.loadParm();

    this.iqcfqcService.getEquipment().subscribe((res: any) => {
      console.log(res)
      this.equipmentList = res.data;
    });

    this.iqcfqcService.getMakeModel().subscribe((res: any) => {
      console.log(res)
      this.makeList = res.data;
    });
    this.iqcfqcService.getManufacturer().subscribe((res: any) => {
      console.log(res)
      this.manufacturerList = res.data;
    });
    this.iqcfqcService.getDepartment().subscribe((res: any) => {
      console.log(res)
      this.departmentList = res.data;
    });
    this.gridInit();
  }

  ngOnInit(): void {
    console.log(this.data)
    this.loadParm();
    this.userId = this.auth.getUserId();
    this.taskTriggersIqcFqc.currentUserId = this.userId;
    this.cd.detectChanges();
  }

  loadParm() {
    this.taskTriggers.questionId = this.initialState.data?._id;
    this.taskTriggers.answerId = this.initialState.data?.selectedAnswerId;
    this.search()
  }

  gridInit() {
    this.rowSelection = "multiple";
    this.gridOptions = {
      suppressDragLeaveHidesColumns: true,
      suppressMakeColumnVisibleAfterUnGroup: true,
      enableRangeSelection: true,
      enableCharts: true,
      sortingOrder: ["desc", "asc"],
      popupParent: document.body,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    }
    this.columnDefs = [
      { headerName: 'Task Name', field: 'taskName', width: 100, headerTooltip: "#", },
      { headerName: 'Task Type', field: 'taskType', width: 80, headerTooltip: "#", },
      { headerName: 'Equipment Type', field: 'equipmentType', width: 120, headerTooltip: "#", },
      { headerName: 'Manufacturer', field: 'manufacturer', width: 100, headerTooltip: "#", },
      { headerName: "Make/Model", field: "makeModel", width: 100, headerTooltip: "#", },
      { headerName: "Location", field: "location", width: 80, headerTooltip: "#", },
      { headerName: "Department", field: "department.name", width: 100, headerTooltip: "#", },
      {
        headerName: "Select", field: "", width: 80,
        headerTooltip: "#",
        cellStyle: (params: any) => {
          if (params.data) {
            return params.data.disabled ? { 'pointer-events': 'none', opacity: '0.4' } : '';
          }
        },
        checkboxSelection: true,
        cellRenderer: (params: any) => {
          if (this.selectAllChecked || params.data.disabled) {
            params.node.setSelected(true);
          }
          else if (!this.selectAllChecked && this.formData.tasks.length >= 1) {
            for (var i = 0; i <= this.formData.tasks.length - 1; i++) {
              if (this.formData.tasks[i]._id === params.data._id) {
                params.node.setSelected(true);
                return;
              }
              else {
                params.node.setSelected(false);
              }
            }
          }
          else {
            params.node.setSelected(false);
          }
        }
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipComponent: TableHeaderToolTipComponent
    };

    this.overlayNoRowsTemplate = "<span style=\"padding: 10px; font-size: 14px;\">Record not found</span>";
  }

  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  filterResults(vent: any) {
    this.gridApi.setQuickFilter(this.searchValue);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.logGridParams = params;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.showNoRowsOverlay();
  }

  //order table inside checkbox checked
  onSelectionChanged(event: any) {
    console.log(event)
    var index = _.findIndex(this.taskTriggers.tasks, (row: any) => {
      return row._id == event.data._id;
    });
    if (event.node.selected) {
      if (index <= -1) {
        var user = { _id: event.data._id }
        this.taskTriggers.tasks.push(user);

        // this.taskTriggers.tasks.push(event.data);
        if (this.taskTriggers.tasks.length == this.orderGridTotalLength) {
          this.selectAllChecked = true;
          $('#checkboxCustella').removeClass('indeterminate');
        } else if (this.taskTriggers.tasks.length < this.orderGridTotalLength) {
          this.selectAllChecked = false;
          $('#checkboxCustella').addClass('indeterminate');
        }
      }
    } else {
      if (index > -1) {
        console.log(index, "-----")
        // this.selectAllChecked = false;
        //this.allCheckedDataLength = null;
        this.taskTriggers.tasks.splice(index, 1);

        if (this.taskTriggers.tasks.length <= 0) {
          this.selectAllChecked = false;
          $('#checkboxCustella').removeClass('indeterminate');
        }
        else if (this.taskTriggers.tasks.length >= 1 && this.taskTriggers.tasks.length < this.orderGridTotalLength) {
          this.selectAllChecked = false;
          $('#checkboxCustella').addClass('indeterminate');
        }
      }
    }
    console.log(this.taskTriggers.tasks.length, "====")
    if (this.taskTriggers.tasks.length == 0) {
      this.submitTouched = true

    } else {
      this.submitTouched = false
    }
  }

  addTask() {
    if (!this.taskTriggers.tasks || this.taskTriggers.tasks.length == 0) return false;
    var formData = _.cloneDeep(this.taskTriggers);
    delete formData.maintenanceTaskId;
    this.iqcfqcService.saveTaskToAnswer(formData).subscribe((res: any) => {
      console.log(res)
      if (res.status == 201 || res.status == 200) {
        this.errorUtil.setErrorMessage(200, 'Task created successfully', null, 'success',1000);
      } else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error',3000);
        this.submitTouched = false;
      }
    });
    this.action.emit(formData);
    this.modalRef.hide();
  }

  search() {
    this.taskTriggers.tasks = []
    var skipTaskListIds = null
    console.log(this.data)
    if(this.data != undefined){
      skipTaskListIds = this.data.skipTaskListIds
    }
    this.iqcfqcService.getAllTaskTrigger(this.filter.equipmentType, this.filter.manufacturer,
      this.filter.makeModel, this.filter.taskType, this.filter.location,
      this.filter.departmentId,skipTaskListIds).subscribe((res: any) => {
        console.log(res)
        if (res.status == false) {
          this.gridApi.showNoRowsOverlay();
        } else {
          this.taskTrigerList = res.data;//to show data in descending order
          console.log(this.taskTrigerList);
        }
      })
  }

  onSearchClear(): void {
    this.searchValue = null;
    this.gridApi.resetQuickFilter();
    this.search();
    this.filterResults('');
  }

  resetSearch() {
    this.filter.equipmentType = null
    this.filter.manufacturer = null
    this.filter.makeModel = null
    this.filter.taskType = null
    this.filter.location = null
    this.filter.departmentId = null
    if (this.myDropDown) this.myDropDown.clear(null);
    this.search();
  }

  goBack() {
    this.modalRef.hide();
  }
}
