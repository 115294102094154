<div>
    <div class="flex-between">
      <i class="pi pi-times w-24px dialog-close-icon" id="btn_ProjectsClose" [pTooltip]="'close'|translate" tooltipPosition="left" (click)="closeTable()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="title" id="label_ProjectsSelectList">{{"select_project_from_list" | translate}}</span>
      <div class="d-flex align-items-center mt-2">
        <span class="p-input-icon-left w-100 py-2">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText autocomplete="off"
            id="input_ProjectsSelectListField"
            [placeholder]="'type_project_name' | translate"
            [(ngModel)]="searchValue"
          />
        </span>
      </div>
      <div class="height-42-stat border">
          <app-projects-table [searchValue]="searchValue"></app-projects-table>
      </div>
    </div>
  </div>