<div class="left-right-space h-100">
    <div class="flex-between mb-2" *ngIf="!noActivity">
        <div class="custella-dashboard-top-right d-flex ms-auto" *ngIf="!noActivity">
            <button class="border rounded-1 px-04 ms-2 btn-32" id="btn_VehActivityRefresh" (click)="refresh()">
                <img alt="logo" src="assets\svg\refresh.svg" id="img_VehActivityRefresh"/>
            </button>
        </div>
    </div>

    <div *ngIf="noActivity" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" translate>
                {{ "no_records_found" }}
            </p>
        </div>
    </div>

    <div [hidden]="noActivity" class="position-relative fw-normal d-flex display-table" style="height: calc(100% - 73px);">
        <div class="table-space ag-grid-table-full" [hidden]="noActivity">
            <ag-grid-angular
                #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [columnDefs]="columnDefsActivity"
                [gridOptions]="gridOptionsActivity"
                [defaultColDef]="defaultColDefActivity"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onGridReadyActivity($event)"
                [overlayNoRowsTemplate]="overlayNoRowsTemplateActivity"
                (cellClicked)="goToViewmap($event)"
                [getContextMenuItems]="getContextMenuItems"

            ></ag-grid-angular>
            <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end" id="btn_VehActTableRec">
                    {{ totalRecord }} {{'records' | translate}}
                </p>
            </div>
        </div>
    </div>
</div>
