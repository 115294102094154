import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataService } from './IqcFqc';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-iqc-fqc',
  templateUrl: './iqc-fqc.component.html',
  styleUrls: ['./iqc-fqc.component.scss']
})
export class IqcFqcComponent implements OnInit, OnDestroy {
  items!: MenuItem[];

  activeIndex = 0;
  refresherIqc: Subscription
  constructor(private route: ActivatedRoute, private cd: ChangeDetectorRef,
    private dataService: DataService, private breadcrumbService: BreadcrumbService) {
    this.refresherIqc = this.route.queryParams.subscribe(params => {
      this.activeIndex = params['activeIndex'] == '1' ? 1 : 0;
    });
    this.breadcrumbService.setItems([]);
  }
  ngOnDestroy(): void {
    if (this.refresherIqc)
      this.refresherIqc.unsubscribe();
  }

  ngOnInit(): void {
    this.items=[
      { label: 'IQC/FQC'}
    ]
    this.cd.detectChanges();
  }

  newTab(v: any) {
    this.dataService.changeMessage(v.index);
  }

}
