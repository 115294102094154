<div class="modal-header p-dialog-header flex-between">
  <div *ngIf="loadList == 0" class="d-flex align-items-center">
      <span class="text-16 font-medium ms-3" id="title_TaskJourney">{{title}}</span>
  </div>
  <div *ngIf="loadList == 1" class="d-flex align-items-center">
      <i class="pi pi-arrow-left me-2" (click)="goBackTablePage()"></i>
      <span class="text-16 font-medium ms-3" id="title_TaskJourneyReturn">{{title}}</span>
  </div>
  <div *ngIf="loadList == 2" class="d-flex align-items-center">
      <i class="pi pi-arrow-left me-2" (click)="goBackTablePage()"></i>
      <span class="text-16 font-medium ms-3" id="title_TaskViewJourney">View Task Journey</span>
  </div>
  <i class="pi pi-times w-24px" id="btn_TaskJourneyClose" (click)="modalRef.hide()"></i>
</div>

<div *ngIf="loadList == 0" class="modal-body pt-0">
  <div class="container text-center px-0" style="padding: 10px;">
      <div class="h-100">
          <!-- Title -->
          <div class="flex-between mt-1 ms-2 mb-2">
              <!-- Filter and New -->
              <div class="custella-dashboard-top-right d-flex ms-auto">
                  <button *ngIf="noJourney" class="border rounded-1 px-04 ms-2 btn-32" id="btn_TaskJourneyRefresh" (click)="getFilter()">
                      <img alt="logo" src="assets\svg\refresh.svg" id="img_TaskJourneyRefresh" />
                  </button>

                  <span *ngIf="noJourney" class="p-input-icon-right w-100 ms-2" >
                    <input type="text" (keydown.enter)="quickSearch()"  pInputText autocomplete="off" [placeholder]="'search' | translate" [(ngModel)]="searchValue" (keyup)="onSearch()" id="input_TeamSearchField" />
                    <i *ngIf="searchValueData" id="btn_JobProductSearchClear" class="p-autocomplete-clear-icon pi pi-times" (click)="onClear()"></i>
                </span>
                  <button *ngIf="noJourney"  type="button" class="border rounded-1 px-04 ms-2 btn-32" id="btn_TeamSearch" (click)="quickSearch()">
                    <img alt="logo" src="assets\svg\search-dark.svg" />
                </button>
                  <p-button label="New" icon="pi pi-plus" (click)="openNewForm()" id="btn_TaskJourneyNew" styleClass="ms-2 h-32" *ngIf="create"></p-button>
              </div>
          </div>
          <div *ngIf="noJourneyIcon" class="col-12 mb-5 py-4">
              <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                  <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
                  <p class="text-6 my-1 font-weight-bold" translate>{{'no_records_found'}}</p>
              </div>
          </div>

          <!-- TabMenu -->
          <div [hidden]="!noJourney" class="position-relative fw-normal d-flex display-table">
              <div [hidden]="!noJourney" class="border ag-grid-table-full" style="height: 400px;">
                  <ag-grid-angular
                      #agGrid
                      style="height: 100%;"
                      class="ag-theme-balham"
                      [columnDefs]="columnDefs"
                      [gridOptions]="gridOptions"
                      [defaultColDef]="defaultColDef"
                      [maxConcurrentDatasourceRequests]="-1"
                      [suppressDragLeaveHidesColumns]="true"
                      [paginationPageSize]="25"
                      [pagination]="false"
                      [paginationPageSize]="25"
                      [rowData]="taskJourneys"
                      [frameworkComponents]="frameworkComponents"
                      (gridReady)="onGridReady($event)"
                      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                      (cellClicked)="viewJourney($event)"
                  ></ag-grid-angular>

                  <div class="col-12">
                      <p class="ag-record-row-summary-panel padds text-end" id="label_TaskJourTableRec">{{totalRecord}} Records</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div *ngIf="loadList == 1" class="modal-body p-0"><app-add-edit-journey [data]="data" [mode]="mode" [journeyId]="jId" (goBackTablePage)="goBackTablePage()"></app-add-edit-journey></div>
<div *ngIf="loadList == 2" class="modal-body p-0"><app-view-journey [data]="data" [journeyId]="jId"></app-view-journey></div>
