import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  GetContextMenuItemsParams,
  GridOptions,
  IGetRowsParams,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  MenuItemDef,
} from "ag-grid-community";
import "ag-grid-enterprise";
import * as _ from "lodash";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { ServerRequest } from "src/app/_models/global.data.model";
import { CustomersService } from "src/app/_services/customers.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "../../table-header-tool-tip/table-header-tool-tip.component";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import * as moment from "moment";
import { Global } from "src/app/_utilities/global";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
var router: Router;
var dateFormateChange: any;
@Component({
  selector: "app-cust-customer",
  templateUrl: "./cust-customer.component.html",
  styleUrls: ["./cust-customer.component.scss"],
})
export class CustCustomerComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, OnChanges
{
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any=[];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: GridOptions;
  overlayNoRowsTemplate: string;
  @Input() searchValue: any;
  customers: any;
  actionData: {
    headerName: string;
    filter: boolean;
    sortable: boolean;
    width: number;
    headerClass: string;
    cellStyle: { "text-align": string };
    cellRenderer: string;
    cellRendererParams: {
      onClick: any;
      permissions: { update: any; remove: any };
      hidden: { update: any; remove: any };
    };
    headerTooltip: string;
  };
  totalRecord: any;
  currentRecordNumber: any;
  refresher: Subscription;
  noCustomers: boolean = false;
  noCustomersIcon: boolean = false;
  loadTable: number =0;
  sortBy: any;
  sortOrder: any;

  constructor(
    private customerService: CustomersService,
    private auth: AuthenticationService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private perm: PermissionService,
    private dynamicFormService: DynamicFormService,
    private dynamicTableColumnsService: DynamicTableColumnsService
  ) {
    super();
    this.refresher = customerService.refreshList$.subscribe((item) =>
      this.onRefreshList()
    );
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    // this.columnDefs = [
    //   {
    //     headerName: "Customer No.",
    //     field: "customerNumber",
    //     width: 100,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.getAddonFeatureLists();
    //       return addonFeature.addonMS == 'MACHINE_SPECIALIST' ? "SOU No." : "Customer No.";
    //     },
    //     headerComponentParams: {
    //       template:
    //           '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadCusNo">' +
    //       '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
    //       '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
    //       '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
    //       '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
    //       '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
    //       '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
    //       '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
    //       '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
    //       '  </div>' +
    //       '</div>'
    //   },
    //     cellClass: function (params: any) {
    //       return ["table_default_color-top"];
    //     },
    //     cellRenderer: function (params: any) {
    //       if(params.data){
    //         return '<p id="'+params.data.rowId+'">'+params.data.customerNumber+'</p>';
    //       }
    //     },
    //   },
    //   {
    //     headerName: "Name",
    //     field: "name",
    //     width: 220,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.addonFeature();
    //       return addonFeature === "TOUCHANDGO" ? "Account Name" : "Name";
    //     },
    //    },
    //   {
    //     headerName: "Billing Address",
    //     field: "billingAddress",
    //     width: 220,
    //     sortable: true,
    //     headerTooltip: "#",
    //     cellRenderer: function (params: any) {
    //       return params.data && params.data.billingAddress ? params.data.billingAddress : "-";

    //     },
    //   },
    //   {
    //     headerName: "Customer Reference ID",
    //     field: "customerReferenceNo",
    //     width: 120,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.getAddonFeatureLists();
    //       return addonFeature.addonMS == 'MACHINE_SPECIALIST' ? "SOU Reference ID" : "Customer Reference ID";
    //     },
    //   },
    //   {
    //     headerName: "phone",
    //     field: "phone",
    //     width: 130,
    //     headerTooltip: "#",
    //     headerValueGetter: (params: any) => {
    //       const addonFeature = this.auth.addonFeature();
    //       return addonFeature === "TOUCHANDGO" ? "Case Contact Phone" : "Phone";
    //     },
    //   },
    //   { headerName: "Email", field: "email", width: 200, headerTooltip: "#" },
    //   {
    //     headerName: "Category",
    //     field: "categoryType.name",
    //     width: 150,
    //     headerTooltip: "#",
    //   },
    // ];

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      "<div class='not-found'><span>Record not found</span> </div>";

    this.actionData = {
      headerName: "Action",
      filter: false,
      sortable: false,
      width: 150,
      headerTooltip: "#",
      headerClass: "marging-auto hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("customers", "update"),
          remove: this.perm.capable("customers", "remove"),
        },
        hidden: {
          update: !this.perm.capable("customers", "update"),
          remove: !this.perm.capable("customers", "remove"),
        },
      },
    };
    
    
  }

  ngOnInit() {
    router = this.router;
    this.preInit();
    //console.log(this.gridOptions.columnDefs)
  }
  /******************************************** Pre Init ******************************************/
  private preInit() {
   
      this.push(
        this.dynamicFormService.getTableColumn("customer")
          .subscribe((res: any) => {
            this.sortBy = res.sortBy
            this.sortOrder = res.sortOrder
           
              var columnDefs: any = this.gridOptions.columnDefs;
              res.tableColumnSetups.forEach((element: any) => {
                this.cellRenderer(element);
      
                //console.log(element)
                columnDefs.push(element);
                this.gridOptions.api!.setColumnDefs(columnDefs);
              });
               columnDefs.push(this.actionData);
              
              this.loadTable = 1;
              this.gridOptions.api!.setColumnDefs(columnDefs);
              this.onGridReady(this.gridParams);
          })
      );
  

  }
   /******************************************** Render Cell ******************************************/
   private cellRenderer(element: any) {
    console.log(element,"===",element.dataType)
    
    var dateFormat: any = localStorage.getItem("date_format");
    element.headerTooltip = "#";
    if (element.field === "customerNumber") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadCusNo'),
      element.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId, params.data.customerNumber)
        }
      };
    }else if (element.dataType == 'date'  || element.dataType == "datetime" || element.dataType == "text") {
      element = Global.setColumnByDataType(element,this.auth.getUserTimezone(),dateFormat)
     }
  
    return element;
  }
  /********************************** Click Cell Event *****************************/
  edit(e: any) {
    if (e.action === "edit") {
      this.router.navigate(["dashboard/customers/update"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      e.rowData.deleteType = "Customer";
      e.rowData.message =
        "By deleting this customer, you will also be removing all associated information such as orders, contacts, and other related data. You will not be able to undo this. Do you wish to proceed?";
      console.log(e.rowData);

      this.deleteRecordService.getItems(e.rowData);
    }
  }
  onRefreshList() {
    this.onGridReady(this.gridParams);
  }
  /********************************** Search Data **************************/
  quickSearch() {
    this.onGridReady(this.gridParams);
  }
  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    //console.log(params)
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable == 1) {this.getServerSideData(params);}
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    var count: any=serverRequest.startRow
    console.log('Making a server request: ', serverRequest.startRow);
    
    this.push(
      this.customerService
        .getCustomerList(this.searchValue, serverRequest)
        .subscribe(
          (data: any) => {
            console.log("cust cust",data.body);
            this.customers = data.body.data;
            this.customers.forEach((element: any) => {
              count++
              element.rowId='Tbl_RecCusNo'+count
              element.buttonEdit='Btn_CusRowEdit'+count
              element.buttonDelete='Btn_CusRowDel'+count
            });
            this.totalRecord = data.body.total;
            this.setGridData(
              agGridGetRowsParams,
              this.customers,
              data.body.total,
              data.body.status
            );
            //this.spinner.hide()
          },
          (err) => {
            //console.log(err,"===>>>")
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    console.log(resultItems, "===");
    // if (resultItems) {
    //   agGridGetRowsParams.successCallback(resultItems, totalCount);
    this.customerService.setItems(status);

    if (status === "NO_DATA") {
      this.noCustomers = false;
      this.noCustomersIcon = true;
    } else {
      this.noCustomers = true;
      this.noCustomersIcon = false;

      if (this.customers.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(this.customers, totalCount);
      }
    }
    // if(this.customers.length === 0){
    //   this.gridParams.api.showNoRowsOverlay();
    // }else{
    //   agGridGetRowsParams.successCallback(resultItems, totalCount);
    // }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: this.sortBy, sort: this.sortOrder };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }
  /********************************** View Singal Record **************************/

  viewCustomer(e: any) {
    if (e.colDef.field === "customerNumber") {
      this.router.navigate(["dashboard/customers/view/" + e.data.id], {
        queryParams: { id: e.data.id },
      });
    }
    //this.selectCustomer(e.data)
  }

  selectCustomer(val: any) {
    // this.customerSelector.emit(val);
  }

  closeCustomer() {
    //this.closeCustomerView.emit(false)
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes,"======")
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue
    ) {
      this.onGridReady(this.gridParams);
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue === ""
    ) {
      this.onGridReady(this.gridParams);
    }
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  

  /******************************************** right click cell context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;
    let url = "";
    let data = "";
   

    return this.dynamicTableColumnsService.rightClick(params);
  };

  push(obs: any) {
    super.push(obs);
  }
}
