import { Injectable } from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { BehaviorSubject } from "rxjs";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";

export class ServerRequest {
  location: any = '';
  fromDate: any = null;
  toDate: any = null;
  startFrom: any = 0;
  perPage: any = 25;
  columnName: any = 'runningNumber';
  sortOrder: any = 'desc';
}


export class TableDef {
  isFqc = false;
  constructor(isFqc: boolean) {
    this.isFqc = isFqc;
    this.setColDef();
  }

  gridOptions = <GridOptions>{
    context: { parentComponent: this },
    cacheBlockSize: 25,
    rowModelType: 'infinite',
    sortingOrder: ["desc", "asc"],
    // onGridSizeChanged: (params) => {
    //   if (params.api)
    //     params.api.sizeColumnsToFit();
    // },
    // columnDefs: this.columnDefs(),
    tooltipShowDelay: 100,
  }
  defaultColDef = {
    enableRowGroup: true, //ag-Grid-Enterprise
    enablePivot: true, //ag-Grid-Enterprise
    enableValue: true, //ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent
  };
  columnDefs: any = [];
  setColDef() {
    this.columnDefs = this.isFqc ? [
      {
        headerName: 'IQC ID', field: 'runningNumber', width: 150,
        headerTooltip: "#",
        cellClass: function (params: any) { return ['text-1-5 font-weight-bold table_default_color']; }
      },
      { headerName: 'Created Date', field: 'created_at', width: 100, headerTooltip: "#", },
      { headerName: 'Status', field: 'status', width: 100, headerTooltip: "#", },
      { headerName: 'Offhire/Exchange', field: 'iqcType', width: 100, headerTooltip: "#", },
      { headerName: 'Equipment Number', field: 'equipmentNumber', width: 100, headerTooltip: "#", },
      { headerName: 'Equipment Type', field: 'equipmentType.name', width: 100, headerTooltip: "#", },
      { headerName: 'Location', field: 'location', width: 100, headerTooltip: "#", },
      { headerName: 'Remark', field: 'remark', width: 100, headerTooltip: "#", },
      { headerName: 'Hour meter', field: 'equipmentMeterReading', width: 100, headerTooltip: "#",},
    ] : [
      {
        headerName: 'FQC ID', field: 'runningNumber', width: 150,
        headerTooltip: "#",
        cellClass: function (params: any) { return ['text-1-5 font-weight-bold table_default_color']; }
      },
      { headerName: 'Created Date', field: 'created_at', width: 100, headerTooltip: "#", },
      { headerName: 'Status', field: 'status', width: 100, headerTooltip: "#", },
      { headerName: 'Offhire/Exchange', field: 'iqcType', width: 100, headerTooltip: "#", },
      { headerName: 'Equipment Number', field: 'equipmentNumber', width: 100, headerTooltip: "#", },
      { headerName: 'Equipment Type', field: 'equipmentType.name', width: 100, headerTooltip: "#", },
      { headerName: 'Location', field: 'location', width: 100, headerTooltip: "#", },
      { headerName: 'Remark', field: 'remark', width: 100, headerTooltip: "#", },
      { headerName: 'Hour meter', field: 'equipmentMeterReading', width: 100, headerTooltip: "#", },
    ];
  }


  action = {
    headerName: "Action",
    filter: false,
    sortable: false,
    width: 30,
    headerTooltip: "#",
    headerClass: "marging-auto hide-action-border",
    cellStyle: { "text-align": "center" },
    cellRenderer: "buttonRenderer",
    cellRendererParams: {},
  };
}


export const locatns = [
  { 'name': 'BTU In-House', "fieldLabel": "BTU In-House" },
  { 'name': 'JB In-House', "fieldLabel": "JB In-House" },
  { 'name': 'KL In-House', "fieldLabel": "KL In-House" },
  { 'name': 'KC In-House', "fieldLabel": "KC In-House" },
  { 'name': 'KM In-House', "fieldLabel": "KM In-House" },
  { 'name': 'PG In-House', "fieldLabel": "PG In-House" },
];


@Injectable()
export class DataService {
  private messageSource = new BehaviorSubject('0');
  currentMessage = this.messageSource.asObservable();
  constructor() { }
  changeMessage(message: string) {
    this.messageSource.next(message)
  }
}