<div class="modal-header p-dialog-header flex-between border-0 pb-1">
    <div class="">
        <span class="text-16 font-medium">{{'send_calendly_link'|translate}}</span>
        <span class="text-12 font-medium color-9e d-block" translate>{{'select_to_send_calendly_link'}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>

<div class="modal-body tix pt-1">
    <form [formGroup]="calendlyLinkForm">
    <div class="custella-form-container rounded-2 ms-0 me-2" style="box-shadow: none; border: 1px solid #dee2e6;">
        <!-- Container Content -->
        <div class="custella-form-container-title rounded-top" id="title_JobCalendlyDets">
            <span class="text-14 font-semiBold">{{'survey_step_one_number'|translate}}&colon;&nbsp;</span>
            <span class="text-14">{{'calendly_step_one'|translate}}</span>
        </div>
        <div class="custella-form-container-content my-3">
            <div class="mb-3 col-12"> 
                <span class="custella-content-row-input-title mandatory" id="label_JobCalendlyFrom">{{'whose_calendar_sent' | translate}}</span>
                <div class="flex-align-center">
                    <input *ngIf="calendlyLinkData.fromType =='JOB_ORDER_OWNER'" type="text" readonly  [(ngModel)]="calendlyLinkData.orderOwner.fullName" class="mx-500px" 
                    formControlName="fromUser" pInputText autocomplete="off" maxlength="255" id="input_JobCalendlyFromField" [attr.disabled]="true"/>
                 
                    <p-dropdown *ngIf="calendlyLinkData.fromType =='CURRENT_USER_DOWNLINE'" class="w-54"
                    [placeholder]="'user' | translate" 
                       id="picklist_TaskSmartNoUsersField" 
                       [(ngModel)]="userId"
                       [options]="users" 
                       [showClear]="true" 
                       formControlName="fromUser" 
                       optionLabel="label" 
                       dataKey="id"
                       [autoDisplayFirst]="false"
                       filterBy="label" (onChange)="onchangeUser($event)" optionValue="id"></p-dropdown>

                    
                    <div class="flex-align-center">
                        <button pButton type="button" id="btn_JobCalendlyFromOrderOwner" class="p-button text-nowrap ms-2 w-jo-own" [label]="'jo_owner'|translate" [ngClass]="{'p-button-secondary':calendlyLinkData.fromType !='JOB_ORDER_OWNER'}"  (click)="fromTypeTo('JOB_ORDER_OWNER')"></button>
                        <button pButton type="button" id="btn_JobCalendlyFromMyDownline" class="p-button text-nowrap p-button-secondary ms-2" [label]="'from_my_downline'|translate"  [ngClass]="{'p-button-secondary':calendlyLinkData.fromType !='CURRENT_USER_DOWNLINE'}"  (click)="fromTypeTo('CURRENT_USER_DOWNLINE')"></button>
                    </div>
                </div>
                <div *ngIf="calendlyLinkForm.controls['fromUser'].touched && calendlyLinkForm.controls['fromUser'].invalid">
                    <small class="text-10 p-error" *ngIf="calendlyLinkForm.controls['fromUser'].errors?.required" id="label_LeaveTypeReqField" translate>{{'user_calendly_no_integrate'}}</small>
                </div>
            </div>
            <div class="mb-3 col-12">
                <span class="custella-content-row-input-title" id="label_JobCalendlySendTo">{{'who_calendly_sent_to' | translate}}</span>
                <div class="flex-align-center">
                    <input type="text" #toEmails pInputText autocomplete="off"  [(ngModel)]="calendlyLinkData.toEmails"
                    class="mx-500px" formControlName="toEmails" maxlength="255" id="input_JobCalendlySendToField" *ngIf="sendTo !='CUSTOMER_CONTACT' && sendTo =='CUSTOM'"/>
                    <input type="text" #toEmails pInputText autocomplete="off"  [(ngModel)]="calendlyLinkData.toEmails" [readonly]="sendTo !='CUSTOM'" 
                    class="mx-500px" formControlName="toEmails" maxlength="255" id="input_JobCalendlySendToField" *ngIf="sendTo !='CUSTOMER_CONTACT' && sendTo !='CUSTOM'" [attr.disabled]="true" />
                    <p-dropdown *ngIf="sendTo =='CUSTOMER_CONTACT' && !isContactsDisabled" class="w-54" placeholder="" id="picklist_TaskSmartNoUsersField" #toEmails2
                       [(ngModel)]="calendlyLinkData.toEmails"
                       [options]="contacts" 
                       [showClear]="true" 
                       formControlName="toEmails" 
                       optionLabel="email"
                       dataKey="id"
                       [autoDisplayFirst]="false"
                       filterBy="email" (onChange)="onchangeContacts($event)" optionValue="email" [disabled]="isContactsDisabled"></p-dropdown>
                       <input type="text" #toEmails pInputText autocomplete="off"  [(ngModel)]="calendlyLinkData.toEmails" 
                    class="mx-500px" formControlName="toEmails" maxlength="255" id="input_JobCalendlySendToField" *ngIf="sendTo =='CUSTOMER_CONTACT' && isContactsDisabled" [attr.disabled]="true" />
                    
                    <div class="flex-align-center">
                        <button pButton type="button" id="btn_JobCalendlySendToOrderCont" class="p-button text-nowrap ms-2" [label]="'jo_contact'|translate" [ngClass]="{'p-button-secondary':sendTo !='ORDER_CONTACT'}" (click)="sendEmailTo('ORDER_CONTACT')"></button>
                        <button pButton type="button" id="btn_JobCalendlySendToCustCont" class="p-button text-nowrap p-button-secondary ms-2" [label]="'cust_contact'|translate" [ngClass]="{'p-button-secondary':sendTo !='CUSTOMER_CONTACT'}"  (click)="sendEmailTo('CUSTOMER_CONTACT')"></button>
                        <button pButton type="button" id="btn_JobCalendlySendToCustom" class="p-button text-nowrap p-button-secondary ms-2" [label]="'custom'|translate" [ngClass]="{'p-button-secondary':sendTo !='CUSTOM'}"  (click)="sendEmailTo('CUSTOM')"></button>
                    </div>
                </div>
                <div *ngIf="calendlyLinkForm.controls['toEmails'].touched && calendlyLinkForm.controls['toEmails'].invalid && isSubmited">
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.required && sendTo =='ORDER_CONTACT'" id="label_SetInvLocAddrReqField" translate>{{'no_contact_found_for_this_job_Order'}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.pattern && sendTo =='ORDER_CONTACT'" translate>{{'invalid_email_format'}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.required && sendTo =='CUSTOMER_CONTACT'" id="label_SetInvLocAddrReqField" translate>{{'no_contact_found_for_the_Job_Orders_Customer'}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.pattern && sendTo =='CUSTOMER_CONTACT'" translate>{{'invalid_email_format'}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.required && sendTo =='CUSTOM'" id="label_SetInvLocAddrReqField" translate>{{'invalid_emails'}}</span>
                    <span class="text-10 p-error" *ngIf="calendlyLinkForm.controls['toEmails'].errors?.pattern && sendTo =='CUSTOM'" translate>{{'invalid_email_format'}}</span>
                </div>
            </div>
        </div>

        <div class="custella-form-container-title-two" id="title_JobCalendlyBookTrigg" translate>
            <span class="text-14 font-semiBold">{{'survey_step_two_number'|translate}}&colon;&nbsp;</span>
            <span class="text-14">{{'calendly_step_two'|translate}}</span>
        </div>
        <div class="custella-form-container-title-two-grey text-12 fst-italic ps-4" id="subtitle_JobCalendlyBookTrigg" translate>
            {{"what_happen_cust_booking"}}
        </div>
        <div class="custella-form-container-content my-3">
            <div class="mb-3 flex-align-center col-12">
                <div>
                    <span class="text-12 font-bold color-56" id="label_JobCalendlyUpdTimeDate">{{'update_jo_time_date' | translate}}</span>
                    <span class="text-10 font-medium d-block color-86" id="label_JobCalendlyUpdTimeDate">{{'will_update_slot_customer' | translate}}</span>
                </div>
                <div class="p-field-checkbox ms-auto">
                    <p-checkbox [binary]="true" id="check_JobCalendlyUpdTimeDate" (onChange)="detectChanges()" [(ngModel)]="calendlyLinkData.updateJoDateTime" [ngModelOptions]="{ standalone: true }"></p-checkbox>
                </div>
            </div>
            <div class="mb-3 flex-align-center mt-2 col-12">
                <div>
                    <span class="text-12 font-bold color-56" id="label_JobCalendlyUpdTimeDate">{{'create_task_this_jo' | translate}}</span>
                    <span class="text-10 font-medium d-block color-86" id="label_JobCalendlyUpdTimeDate">{{'new_task_created_jo' | translate}}</span>
                </div>
                <div class="p-field-checkbox ms-auto">
                    <p-checkbox [binary]="true" id="check_JobCalendlyCreateTaskJob" (onChange)="detectChanges()"  [(ngModel)]="calendlyLinkData.generateTask" [ngModelOptions]="{ standalone: true }"></p-checkbox>
                </div>
            </div>
        </div>

        <!-- Condition: JO Created, Generate Link to be copied -->
        <div class="custella-form-container-footer mb-0 row">
            <div class="flex-align-center justify-content-start col">
                <!-- <button type="button" (click)="copyLink()" class="custella-btn-white text-12 ms-2"  id="btn_JobCalendlyCancel">{{ "cancel" | translate }}</button> -->
                <!-- <button type="button" class="d-flex custella-btn-white text-12 ms-2" id="btn_DeliverCopyLink" >
                    <i class="pi pi-link d-flex"> <p translate class="ml-1"> copy_link </p></i>
                </button> -->
                <button  type="button" *ngIf="isSend" class="custella-btn-white flex-align-center" id="btn_JobSurveyCopyLink" (click)="copyLink()">
                    <img src="../../../../assets/svg/table/copy-link.svg" style="width: 12px">
                    <span class="text-12 ms-2">{{ btnTextCopy | translate }}</span>
                </button>
            </div>
            <div class="flex-align-center justify-content-end col">
                <button type="button" (click)="modalRef.hide()" class="custella-btn-white text-12 ms-2" id="btn_JobCalendlyCancel">{{ "cancel" | translate }}</button>
                <p-button type="button" *ngIf="!isSend"  (click)="generateLink()" styleClass="ms-2 h-32" id="btn_JobCalendlyGenLink" [label]="'generate_link' | translate"></p-button>
                <p-button type="button" *ngIf="isSend" (click)="onSubmitForm()" styleClass="ms-2 h-32" id="btn_JobCalendlyGenLink" [label]="'send' | translate"></p-button>
            </div>
        </div>

        
    </div>
    </form>
</div>
