<div class="custella-login">
    <div class="container">
        <div class="row">
            <div class="mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body py-4 px-3 px-sm-3 py-sm-4">
                    <span>
                        <img src="../../../assets/svg/FieldEx_L_T.svg" alt="FieldEx Logo">
                    </span>
                    <h3 class="card-title font-bold pt-5 pb-4">{{'create_your_profile'|translate}}</h3>
                    <form [formGroup]="profileInfoForm" autocomplete="off" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <p-avatar [image]="photoUrl" styleClass="me-2" size="xlarge" shape="circle" id="img_ProfileCreateAvatar"></p-avatar>
                            <!-- <p-button [label]="'upload_image'|translate" icon="pi pi-upload" type="button" class="p-button-secondary p-button-outlined ms-2" id="btn_ProfileCreateImgUpload" (click)="openModal('uploadPhoto',profile)"></p-button> -->
                            <button pButton type="button" class="p-button-secondary p-button-outlined ms-2" id="btn_ProfileCreateImgUpload" (click)="openModal('uploadPhoto',profile)">
                                <i id="icon_ProfileCreateImgUpload" class="pi pi-upload"></i>
                                <span class="ps-2" id="label_ProfileCreateImgUpload" translate>{{'upload_image'}}</span>
                            </button>
                        </div>
                        <div class="form-group">
                            <label id="label_ProfileCreateFirstName">{{'first_name'|translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="fa-regular fa-id-card pe-none"></i>
                                <input pInputText [(ngModel)]="profile.firstName" type="text" id="input_ProfileCreateFirstNameField" formControlName="firstName" name="firstName" autocomplete="off" />
                            </span>
                            <div *ngIf="profileInfoForm.controls['firstName'].touched && profileInfoForm.controls['firstName'].invalid">
                                <span class="p-error text-10" id="label_ProfileCreateFirstNameReqField" *ngIf="profileInfoForm.controls['firstName'].errors?.required" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label id="label_ProfileCreateLastName">{{'last_name' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="fa-regular fa-id-card pe-none"></i>
                                <input pInputText [(ngModel)]="profile.lastName" id="input_ProfileCreateLastNameField" type="text" formControlName="lastName" name="lastName" autocomplete="off" />
                            </span>
                            <div class="alert col-6 px-0 py-0 text-right custella-content-row-input" *ngIf="profileInfoForm.controls['lastName'].touched && profileInfoForm.controls['lastName'].invalid">
                                <span class="p-error text-10" id="label_ProfileCreateLastNameReqField" *ngIf="profileInfoForm.controls['lastName'].errors?.required" translate>{{'required_field'}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label id="label_ProfileCreateEmail">{{'email' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="fa-regular fa-at pe-none"></i>
                                <input pInputText [(ngModel)]="profile.email" id="input_ProfileCreateEmailField" type="text" formControlName="email" name="email" autocomplete="off" />
                            </span>
                            <div *ngIf="profileInfoForm.controls['email'].touched && profileInfoForm.controls['email'].invalid">
                                <span class="p-error text-10" id="label_ProfileCreateEmailReqField" *ngIf="profileInfoForm.controls['email'].errors?.required" translate>{{'required_field'}}</span>
                                <span class="p-error text-10" id="label_ProfileCreateEmailInvalid" *ngIf="profileInfoForm.controls['email'].errors?.pattern" translate>{{'invalid_email_format'}}</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <label id="label_ProfileCreatePhone">{{'phone' | translate}}</label>
                            <span class="p-input-icon-left">
                                <i class="fa-regular fa-phone pe-none"></i>
                                <input pInputText [(ngModel)]="profile.phone" id="input_ProfileCreatePhoneField" type="text" formControlName="phone" name="phone"/>
                            </span>
                            <div *ngIf="profileInfoForm.controls['phone'].touched && profileInfoForm.controls['phone'].invalid">
                                <span class="p-error text-10" id="label_ProfileCreatePhoneReqField" *ngIf="profileInfoForm.controls['phone'].errors?.required" translate>{{'required_field'}}</span>
                                <span class="p-error text-10" id="label_ProfileCreatePhoneInvalid" *ngIf="profileInfoForm.controls['phone'].errors?.pattern" translate>{{'invalid_phone_number'}}</span>
                            </div>
                        </div>

                    <div class="my-4">
                        <button type="submit" class="custella-btn-long"  id="btn_CreateProfileSubmit" [disabled]="submitTouched">
                            <span class="text-12">{{'create'|translate}}</span>
                        </button>
                    </div>
                </form>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>







<!-- <div class="vh-100 overflow-hidden">
    <div class="d-flex vh-100">
        <div class="left-form">
            <form [formGroup]="profileInfoForm" autocomplete="off" (ngSubmit)="onSubmit()">
                <div class="container">
                    <div class="p-5">
                        <img src="../../../assets/svg/FieldEx_L_T.svg" alt="FieldEx Logo">
                        <div class="text-20 font-medium" id="title_ProfileCreate" translate>{{'create_your_profile'}}</div>
                        <div class="custella-login-form">
                            <div class="d-flex align-items-center mb-3">
                                <p-avatar [image]="photoUrl" styleClass="me-2" size="xlarge" shape="circle" id="img_ProfileCreateAvatar"></p-avatar>
                                <button type="button" class="custella-btn-grey-outlined mx-3 flex-between" id="btn_ProfileCreateImgUpload" (click)="openModal('uploadPhoto',profile)">
                                    <i id="icon_ProfileCreateImgUpload" class="pi pi-upload"></i>
                                    <label class="ps-2" id="label_ProfileCreateImgUpload" translate>{{'upload_image'}}</label>
                                </button>
                            </div>
                            <div class="d-flex align-items-center col-12">
                                <div class="col-6 pe-2">
                                    <span class="mb-2 text-12 font-medium" id="label_ProfileCreateFirstName" translate>{{'first_name'}}</span>
                                    <span class="w-100">
                                        <input pInputText [(ngModel)]="profile.firstName" type="text" id="input_ProfileCreateFirstNameField" formControlName="firstName" name="firstName" autocomplete="off" placeholder="First Name" />
                                    </span>

                                    <div class="alert col-6 px-0 py-0 text-right custella-content-row-input" *ngIf="profileInfoForm.controls['firstName'].touched && profileInfoForm.controls['firstName'].invalid">
                                        <small class="custella-content-row-input-sub p-error" id="label_ProfileCreateFirstNameReqField" *ngIf="profileInfoForm.controls['firstName'].errors?.required" translate>{{'required_field'}}</small>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <span class="mb-2 text-12 font-medium" id="label_ProfileCreateLastName" translate>{{'last_name'}}</span>
                                    <span class="w-100">
                                        <input pInputText [(ngModel)]="profile.lastName" id="input_ProfileCreateLastNameField" type="text" formControlName="lastName" name="lastName" autocomplete="off" placeholder="Last Name" />
                                    </span>

                                    <div class="alert col-6 px-0 py-0 text-right custella-content-row-input" *ngIf="profileInfoForm.controls['lastName'].touched && profileInfoForm.controls['lastName'].invalid">
                                        <small class="custella-content-row-input-sub p-error" id="label_ProfileCreateLastNameReqField" *ngIf="profileInfoForm.controls['lastName'].errors?.required" translate>{{'required_field'}}</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class="mb-2 text-12 font-medium" id="label_ProfileCreateEmail" translate>{{'email'}}</span>
                                <span class="w-100">
                                    <input pInputText [(ngModel)]="profile.email" id="input_ProfileCreateEmailField" type="text" formControlName="email" name="email" autocomplete="off" placeholder="Email" />
                                </span>

                                <div class="alert col-12 px-0 py-0 text-right custella-content-row-input" *ngIf="profileInfoForm.controls['email'].touched && profileInfoForm.controls['email'].invalid">
                                    <small class="custella-content-row-input-sub p-error" id="label_ProfileCreateEmailReqField" *ngIf="profileInfoForm.controls['email'].errors?.required" translate>{{'required_field'}}</small>
                                    <small class="custella-content-row-input-sub p-error" id="label_ProfileCreateEmailInvalid" *ngIf="profileInfoForm.controls['email'].errors?.pattern" translate>{{'invalid_email_format'}}</small>
                                </div>
                            </div>
                            <div class="col-12">
                                <span class="mb-2 text-12 font-medium" id="label_ProfileCreatePhone"  translate>{{'phone'}}</span>
                                <span class="w-100">
                                    <input pInputText [(ngModel)]="profile.phone" id="input_ProfileCreatePhoneField" type="text" formControlName="phone" name="phone" placeholder="Phone" />
                                </span>

                                <div class="alert col-12 px-0 py-0 text-right custella-content-row-input" *ngIf="profileInfoForm.controls['phone'].touched && profileInfoForm.controls['phone'].invalid">
                                    <small class="custella-content-row-input-sub p-error" id="label_ProfileCreatePhoneReqField" *ngIf="profileInfoForm.controls['phone'].errors?.required" translate>{{'required_field'}}</small>
                                    <small class="custella-content-row-input-sub p-error" id="label_ProfileCreatePhoneInvalid" *ngIf="profileInfoForm.controls['phone'].errors?.pattern" translate>{{'invalid_phone_number'}}</small>
                                </div>
                            </div>

                        </div>
                        <div class="mt-5">
                            <button type="submit" class="custella-btn-long" id="btn_CreateProfileSubmit" [disabled]="submitTouched">
                                <label translate>{{'create'}}</label>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="w-75" [ngStyle]="{background:'var(--surface-300)'}">
            <div class="right-form">
                <div class="custella-quote">
                    <div class="custella-quote-text">
                        “<span class="custella-quote-text-bold">Refine</span> the way your <span class="custella-quote-text-bold">Time</span> is spent, to <span class="custella-quote-text-bold">Achieve</span> more towards a greater
                        <span class="custella-quote-text-bold">Purpose”</span>
                        <br />
                        <span class="custella-quote-text-small"> – Custella Team</span>
                    </div>
                    <div class="custella-quote-img">
                        <img src="assets\images\login_view.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
