<div *ngIf="fields" >
<ng-container *ngFor="let section of fields;let p = index">
  <div *ngIf="!section.hidden">
      <div  *ngIf="section.labelType== 'Header'"  [ngClass]="{'custella-form-container-title text-14 font-medium rounded-top': p ==0 }" class="custella-form-container-title-two">
        {{section.label}}
      </div>

      <div class="custella-form-container-title-two-grey w-100" *ngIf="section.labelType== 'Sub-Header'">
        {{section.label}}</div>
      <div class="custella-form-container-content-border-bottom">
          <div class="row px-2">
              <div class="custella-content-row-input col-6 my-2 p-0"  *ngFor="let field of section.fields;let c = index" [ngClass]="{'d-none': field.componentType == 'Hidden'}">
                <div *ngIf="field.componentType != 'Hidden' || field.componentType != 'Signature'">
                
                  <span class="custella-content-row-input-title color-var-500">{{field.label}}</span>
                  <span *ngIf="field.specialProviderSelected == 'DatePicker' && field.value" class="custella-content-row-input-title-three"  style="min-height: 18px;">{{field.value | momentDate : dateFormat : timezone}}</span>
                
                  <span *ngIf="field.specialProviderSelected == 'DateTimePicker' && !field.value" class="custella-content-row-input-title-three" style="min-height: 18px;" >-</span>

                  <span *ngIf="field.specialProviderSelected == 'DateTimePicker' && field.value" class="custella-content-row-input-title-three" style="min-height: 18px;" >{{field.value | momentDate : dateTimeFormat : timezone}}</span>

                  <span *ngIf="field.specialProviderSelected == 'String'" class="custella-content-row-input-title-three"  style="min-height: 18px;">{{field.value ? field.value :'-'}}</span>

                  <span *ngIf="field.specialProviderSelected == 'Object' && field?.value && (field.componentType !='PickList' && field.componentType !='Signature')" class="custella-content-row-input-title-three{{field?.redirectPage != null ? '-link':''}}" style="min-height: 18px;" (click)="redirectPage(field,field?.value?.id)">{{field?.value?.name ? field?.value?.name:field?.value?.label}}</span>
                  
                  <span *ngIf="field.specialProviderSelected == 'Object' && !field?.value && (field.componentType !='PickList' && field.componentType !='Signature')" class="custella-content-row-input-title-three" style="min-height: 18px;" >-</span>
                  
                  <span *ngIf="field.specialProviderSelected == 'PlaceAutoComplete'" class="custella-content-row-input-title-three"  style="min-height: 18px;">{{field?.value?.description ? field?.value?.description :'-'}}</span>

                  <span *ngIf="field.specialProviderSelected == 'Object' && field.componentType =='PickList'" class="custella-content-row-input-title-three"  style="min-height: 18px;">{{field?.value?.value ? field?.value?.value :'-'}}</span>
                
                  <p *ngIf="field.specialProviderSelected == 'Array' && field.componentType =='PickList'" class="custella-content-row-input-title-three" >
                    <span class="" *ngFor="let value of field.value; let i = index;">
                      {{value?.label ? value?.label : value?.value}}
                      <span *ngIf="field.value.length > 1 && i < field.value.length -1">,</span>
                    </span>
                  </p>

                    
                  <p *ngIf="field.specialProviderSelected == 'Array' && field.componentType !='FileUpload' && field.componentType !='PickList'" class="custella-content-row-input-title-three" >
                    <span class="" *ngFor="let value of field.value; let i = index;">
                      {{value?.name ? value?.name:value?.label}}
                      <span *ngIf="field.value.length > 1 && i < field.value.length -1">,</span>
                    </span>
                  </p>

                  <div *ngIf="field.specialProviderSelected == 'Array' && field.componentType =='FileUpload'">
                    <div class="custella-form-container-content-border-bottom text-12 font-medium" *ngIf="field?.value?.length>0">
                      <div class="row">
                          <div class="custella-content-row-input col-12" *ngFor="let attachment of field?.value;let j = index">
                              <div class="d-block position-relative me-3 col-12">
                                  <div class="flex-between border-bottom"
                                      style="margin-top: 10px; padding-bottom: 10px;align-items: start !important;">
                                      <div class="d-flex aligm-items-center" style="width: 92%">
                                        <div *ngIf="attachment.contentContentType.indexOf('image') >= 0">
                                          <a class="custella-link" [href]="attachment.attachmentUrl" target="_blank">
                                              <img style="width: 100px;" [src]="attachment.attachmentUrl" />
                                          </a>
                                      </div>
                                      <div *ngIf="attachment.contentContentType.indexOf('video') >= 0" class="video-container">
                                          <video #media id="singleVideo" class="video-player" preload="auto" controls>
                                              <source src="{{attachment?.attachmentUrl}}" type="{{attachment?.contentContentType}}" />
                                          </video>
                                      </div>
                                      <div class="d-flex" *ngIf="attachment.contentContentType.indexOf('application') >= 0 || attachment.contentContentType.indexOf('text') >= 0">
                                        <img class="me-3" style="width: 25px;" src="../../../../assets/svg/fileTypes/general file.svg" />
                                      </div>
                                          <div class="elips w-100">
                                              <span class="text-12 font-medium px-3">{{attachment.fileName}}</span>
                                              <span class="text-10 font-medium color-var-500 d-block text-wrap mt-1 px-3">
                                                  {{ attachment.createdDate | momentDate: " hh:mm a":timezone }}
                                                  {{ attachment.createdDate | momentDate: dateFormat:timezone }}
                                                </span>
                                          </div>
                                    
                                      </div>
                                      <div class="flex-between">
                                          <button pButton icon="pi pi-download" class="p-button-outlined attachment-delete" (click)="downloadAttachment(attachment,field,j)"></button>
                                          
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>

                  <div *ngIf="field.componentType == 'Signature'">
                    <div class="custella-form-container-content-border-bottom text-12 font-medium" >
                      <div class="row">
                          <div class="custella-content-row-input col-12">
                              <div class="d-block position-relative me-3 col-12">
                                  <div class="flex-between border-bottom"
                                      style="margin-top: 10px; padding-bottom: 10px;">
                                      <div class="d-flex aligm-items-center" style="width: 92%">
                                        <div> <img style="width: 150px;" [src]="field.value" /></div>
                                    
                                    
                                      </div>
                                      <!-- <div class="flex-between">
                                          <button pButton icon="pi pi-download" class="p-button-outlined attachment-delete" (click)="downloadAttachment(field.value,field,'')"></button>
                                          
                                      </div> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
                </div>
              </div>
              
          </div>
      </div>
  </div>
</ng-container>
</div>