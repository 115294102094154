<!-- <div class="left-right-space">
    <div class="flex-between mb-2">
        <div class="main-list-header" translate>
            {{ "my_dashboard" }}
        </div>
    </div>
</div> -->
<div class="d-flex align-items-baseline left-right-space">
    <div class="w-40">
        <div class="todays-order-container">
            <!-- Title -->
            <div class="todays-order-container-title flex-between">
                <div class="d-flex align-items-center col-8">
                    <span class="text-14 font-medium" id="label_FleetDashOrdersFor">Orders for</span>
                    <p-calendar
                        name="startDate"
                        styleClass="ms-2 width-90"
                        [placeholder]="'select_date' | translate"
                        dateFormat="mm/dd/yy"
                        yearRange="2000:2030"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        (ngModelChange)="onDateChange($event)"
                        [(ngModel)]="todaysDate"
                        name="date"
                        id="picklist_FleetDashOrdersForField"
                    appendTo="body"></p-calendar>
                </div>
                <div class="col-4">
                    <p-dropdown [placeholder]="'all_plans' | translate" id="label_FleetDashAllPlan" optionLabel="planNumber" optionValue="id" [(ngModel)]="plan" (onChange)="getFleetByPlanId()" [options]="planingList" [filter]="true" [showClear]="plan ? true : false"></p-dropdown>
                </div>
            </div>

            <!-- Content -->
            <ng-container *ngIf="loadingProgress.boxOrderFor === false">
            
            <div class="todays-order-container-content">
                <div *ngIf="fleetDo?.length === 0" class="col-12 mt-5 py-4">
                    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                        <img src="../../../../assets/svg/no_delivery_orders.svg" style="width: 250px" id="img_NoRecordsFound" alt="empty-state" />
                        <p class="text-6 my-1 font-weight-bold" translate>
                            {{ "get_start_create_order" }}
                        </p>
                        <p-button [label]="'new' | translate" icon="pi pi-plus" styleClass="mt-2 h-32" id="btn_FleetOrderNew" (onClick)="newDo()" *ngIf="create"></p-button>
                    </div>
                </div>

                <div *ngIf="fleetDo" class="height-65 pb-32px">
                    <div class="">
                        <div class="col-12" *ngFor="let fleetDoDetail of fleetDo" id="btn_FleetOrderView" (click)="viewDeliveryOrder(fleetDoDetail)">
                            <!-- Order Card -->
                            <div class="order-card">
                                <div class="flex-between px-2 py-2">
                                    <!-- Icon, DO Name, Status -->
                                    <div class="flex-between">
                                        <div class="d-flex align-items-center">
                                            <img *ngIf="fleetDoDetail?.deliveryOrderWay=='pickup'"  id="img_FleetOrderPickup" src="assets/svg/table/ph_arrow_green.svg" alt="Pickup" class="w-19px">
                                            <img *ngIf="fleetDoDetail?.deliveryOrderWay=='delivery'" id="img_FleetOrderDelivery" src="assets/svg/table/ph_arrow.svg" alt="Delivery" class="w-19px">
                                            <img *ngIf="fleetDoDetail?.deliveryOrderWay=='pickup_and_delivery'" id="img_FleetOrderDropPickup" src="assets/svg/table/dropoff-pickup.svg" alt="Pickup and Delivery" class="w-19px">

                                            <span tooltipPosition="top" [pTooltip]="fleetDoDetail?.name" id="value_FleetOrderName" class="text-12 font-semiBold ph-peek" style="margin-left: 0.3rem;">{{fleetDoDetail?.name}}</span>
                                        </div>
                                    </div>
                                    <div class="ms-auto">
                                        <span class="p-mr-2 p-tag p-component ms-2 text-center d-block" id="tag_FleetOrderStatus" [attr.style]="'background-color:' + returnItem('colorCodeDoStatus', fleetDoDetail?.orderStatus) + '50'">
                                            <span class="p-tag-value text-dark text-10" id="value_FleetOrderStatus">{{returnItem('doStatus', fleetDoDetail?.orderStatus)}}</span>
                                        </span>
                                    </div>
                                </div>

                                <!-- Customer Name, Driver Profile Picture, Vehicle No  -->
                                <div class="flex-between pb-1 px-2">
                                    <span class="text-10 font-medium me-0 color-var-500 ph-peek-cust d-block" id="value_FleetOrderCustName">{{fleetDoDetail?.customer?.name}}</span>
                                </div>
                                <!--Driver Profile Picture, Vehicle No  -->
                                <div class="flex-between px-2" style="background: #f0f0f0;">
                                    <div class="d-flex align-items-center justify-content-start col-6">
                                        <span tooltipPosition="top" [pTooltip]="fleetDoDetail?.vehicleLite?.vehicleNumber">
                                            <p-avatar image="../../../../assets/svg/Planing-hub/Vehicle/car_fill-black.svg" id="img_FleetOrderCustNameAvatar" styleClass="mt-1 justify-content-start" shape="circle"></p-avatar>
                                        </span>
                                        <span class="text-10 font-medium ph-peek-icon-detail" id="value_FleetOrderVehicleNum">{{fleetDoDetail?.vehicleLite?.vehicleNumber}}</span>
                                    </div>
                                    <!-- P-AVATAR REQUIRES INTEGRATION -->
                                    <div class="d-flex align-items-center justify-content-end col">
                                        <span tooltipPosition="top" [pTooltip]="fleetDoDetail?.driverLite?.user?.fullName">
                                            <p-avatar *ngIf="!fleetDoDetail?.driverLite?.user?.imageUrl" id="img_FleetOrderDriverAvatar" image="assets/svg/Avatar.svg" styleClass="mt-1" shape="circle"></p-avatar>
                                            <p-avatar *ngIf="fleetDoDetail?.driverLite?.user?.imageUrl" id="img_FleetOrderDriverAvatar" [image]="fleetDoDetail?.driverLite?.user?.imageUrl" styleClass="mt-1" shape="circle"></p-avatar>
                                        </span>
                                        <span class="text-10 font-medium ph-peek-icon-detail" id="value_FleetOrderDriverFullName">{{fleetDoDetail?.driverLite?.user?.fullName}}</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </ng-container>


            <p-progressSpinner class="d-flex justify-content-center" styleClass="spinner-fleet" *ngIf="loadingProgress.boxOrderFor"> </p-progressSpinner>


        </div>
    </div>

    <div class="w-60 ms-2">
        <!-- no of orders -->
        <div class="todays-order-container mb-2">
            <!-- Title -->
            <div class="todays-order-container-title flex-between">
                <div class="d-flex align-items-center col-8">
                    <span class="text-14 font-medium" id="label_FleetDashNoOrders">No. of Orders 
                        ({{totalOrder}})
                    </span>
                </div>
                <div class="col-4 d-flex flex-row-reverse">
                    <!-- <p-button label="Welcome!" (click)="showPopup('welcomeUpdate','')" styleClass="ms-2 h-32"></p-button> -->
                    <button type="button" class="custella-btn-white text-12 ms-2" (click)="viewDeliveryOrderPage()" id="btn_FleetDashNoOrdersView">
                        {{ "view_all" | translate }}
                    </button>
                </div>
            </div>
            <!-- Content -->
            <ng-container *ngIf="loadingProgress.chart === false">

            <div class="todays-order-container-content-graph">
                <div *ngIf="totalOrder === 0" class="col-12 mt-1 pt-0 pb-4">
                    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                        <img src="../../../../assets/svg/search_no_data.svg" id="img_NoRecordsFound" alt="empty-state" class="w-172px" />
                        <p class="text-6 my-1 font-weight-bold" translate>
                            {{ "no_stats_to_report" }}
                        </p>
                    </div>
                </div>

                <ng-container *ngIf="totalOrder !== 0">
                    <ngx-charts-bar-vertical
                        [view]="view"
                        [scheme]="colorScheme"
                        [results]="single"
                        [gradient]="gradient"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="showLegend"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxisLabel]="xAxisLabel"
                        [yAxisLabel]="yAxisLabel"
                        [barPadding]="barPadding"
                        [legendTitle]="legendTitle"
                        [tooltipDisabled]="false"
                    >
                    </ngx-charts-bar-vertical>
                </ng-container>
            </div>

            </ng-container>


            <p-progressSpinner class="d-flex justify-content-center" styleClass="spinner-fleet" *ngIf="loadingProgress.chart"> </p-progressSpinner>

        </div>
        <!-- below no of orders -->
        <div class="d-flex align-items-baseline">
            <!-- Unscheduled Orders -->
            <div class="w-50">
                <div class="todays-order-container">
                    <!-- Title -->
                    <div class="todays-order-container-title">
                        <div class="d-flex align-items-center col-8">
                            <span class="text-14 font-medium" id="label_FleetDashUnscheOrders">Unscheduled Orders ({{deliveryOrder?.length}})</span>
                        </div>
                    </div>
                    <!-- Content -->
                    <ng-container *ngIf="loadingProgress.unscheduledOrder === false">
                    
                    <div class="todays-order-container-content-short">
                        <div class="height-40">
                            <div class="" *ngIf="deliveryOrder?.length > 0">
                                <div class="col-12" *ngFor="let DO of deliveryOrder">
                                    <!-- Order Card -->
                                    <div class="order-card" (click)="viewDeliveryOrder(DO)" id="card_FleetDashUnscheOrdersView">
                                        <div class="flex-between px-2 pt-2">
                                            <!-- Icon, DO Name, Status -->
                                            <div class="flex-between">
                                                <div class="d-flex align-items-center">
                                                    <img *ngIf="DO?.deliveryOrderWay=='pickup'" id="img_FleetDashUnschePickup" src="assets/svg/table/ph_arrow_green.svg" alt="Pickup" class="w-19px">
                                                    <img *ngIf="DO?.deliveryOrderWay=='delivery'" id="img_FleetDashUnscheDelivery" src="assets/svg/table/ph_arrow.svg" alt="Delivery" class="w-19px">
                                                    <img *ngIf="DO?.deliveryOrderWay=='pickup_and_delivery'" id="img_FleetDashUnscheDropPickup" src="assets/svg/table/dropoff-pickup.svg" alt="Pickup and Delivery" class="w-19px">

                                                    <span tooltipPosition="top" [pTooltip]="DO?.name" class="text-12 font-semiBold ph-peek" style="margin-left: 0.3rem;" id="value_FleetDashUnscheOrdName">{{DO?.name}}</span>
                                                </div>
                                            </div>
                                            <div class="ms-auto">
                                                <span class="p-mr-2 p-tag p-component ms-2 text-center d-block" id="tag_FleetDashUnscheOrdStatus" [attr.style]="'background-color:' + returnItem('colorCodeDoStatus', DO?.orderStatus) + '50'">
                                                    <span class="p-tag-value text-dark text-10" id="value_FleetDashUnscheOrdStatus">{{returnItem('doStatus', DO?.orderStatus)}}</span>
                                                </span>
                                            </div>
                                        </div>

                                        <!-- Customer Name, Driver Profile Picture, Vehicle No  -->
                                        <div class="d-flex justify-content-between px-2">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <span tooltipPosition="top" [pTooltip]="DO?.customer?.name">
                                                    <p-avatar image="../../../../assets/svg/sidebar/customer.svg" id="img_FleetDashUnscheOrdNameAvatar" styleClass="mt-1 justify-content-start" shape="circle"></p-avatar>
                                                </span>
                                                <span class="text-10 font-medium ph-peek-icon-detail" id="value_FleetDashUnscheOrdName" style="margin-left: 0.3rem;">{{DO?.customer?.name}}</span>
                                            </div>
                                            <div class="d-flex align-items-center ms-3">
                                                <i class="pi pi-calendar color-var-500"></i>
                                                <span class="text-10 font-medium ph-peek-icon-detail" style="margin-left: 0.3rem;" id="value_FleetDashUnscheOrdDateTime">{{DO?.createdDate | momentDate : dateFormat : timezone}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="deliveryOrder?.length === 0" class="col-12 mt-1 pt-0 pb-4">
                                <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                                    <img src="assets\svg\empty-state.svg" alt="empty-state" class="w-172px" id="img_NoRecordsFound" />
                                    <p class="text-6 my-1 font-weight-bold" translate>
                                        {{ "have_scheduled_all_orders" }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>

                    <p-progressSpinner class="d-flex justify-content-center" styleClass="spinner-fleet" *ngIf="loadingProgress.unscheduledOrder"> </p-progressSpinner>

                </div>
            </div>
            <!-- Recent Activity -->
            <div class="w-50 ms-2">
                <div class="todays-order-container">
                    <!-- Title -->
                    <div class="todays-order-container-title">
                        <div class="d-flex align-items-center col-8">
                            <span class="text-14 font-medium" id="label_FleetRecentActiv">Recent Activity</span>
                        </div>
                    </div>
                    <!-- Content -->
                    <ng-container *ngIf="loadingProgress.activity === false">

                    <div class="todays-order-container-content-short">
                        <div *ngIf="notifications.length == 0" class="col-12 mt-1 pt-0 pb-4">
                            <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
                                <img src="../../../../assets/svg/search_no_data.svg" id="img_NoRecordsFound" alt="empty-state" class="w-172px mb-1" />
                                <p class="text-6 my-1 font-weight-bold" translate>
                                    {{ "no_activity" }}
                                </p>
                            </div>
                        </div>
                        <div *ngIf="notifications?.length > 0" class="height-40">
                            <div class="">
                                <div class="col-12" *ngFor="let notification of notifications">
                                    <!-- Order Card -->
                                    <div class="order-card" (click)="viewNotification(notification)" id="card_FleetRecentActivView">
                                        <div class="flex-between px-2 py-2">
                                            <!-- Icon, DO Name, Status -->
                                            <div class="flex-between">
                                                <div class="d-flex align-items-center">
                                                        <img *ngIf="notification.type == 'CHECK_IN'" id="img_FleetRecentCheckIn" class="w-19px" src="assets/svg/table/checkout.svg" alt="">
                                                        <img *ngIf="notification.type == 'CHECK_OUT'" id="img_FleetRecentCheckOut" src="assets/svg/table/checkout.svg" alt="">
                                                        <img *ngIf="notification.type == 'PICKED'" id="img_FleetRecentPick" class="w-19px" src="assets/svg/table/ph_arrow_green.svg" alt="">
                                                        <img *ngIf="notification.type == 'DROPPED'" id="img_FleetRecentDrop" class="w-19px" src="assets/svg/table/ph_arrow.svg" alt="">
                                                        <img *ngIf="notification.type == 'WAITING'" id="img_FleetRecentWait" class="w-19px" src="assets/svg/misc/wait-ic.svg" alt="">
                                                        <img *ngIf="notification.type == 'BREAK'" id="img_FleetRecentBreak" class="w-19px" src="assets/svg/misc/break-ic.svg" alt="">
                                                        <img *ngIf="notification.type == 'SOS'" id="img_FleetRecentSos" class="w-19px" src="assets/svg/table/checkout.svg" alt="">

                                                    <span tooltipPosition="top" id="value_FleetRecentActivNotify" [pTooltip]="notification?.note" class="text-12 font-semiBold ph-peek" style="margin-left: 0.3rem;">{{notification?.note}}</span>
                                                </div>
                                            </div>
                                            <div class="ms-auto" *ngIf="notification.type ==='SOS'">
                                                 <ng-container *ngIf="notification.description !=='-'">
                                                     <img src="assets/svg/sidebar/comments.svg" alt="Orders" class="w-19px" id="value_FleetRecentActivDesc" [pTooltip]="notification?.description" tooltipPosition="top"/>
                                                </ng-container>

                                            </div>

                                            <div class="ms-auto" *ngIf="notification.type !=='SOS'">
                                                <img *ngIf="notification?.subType=='PICKUP'" id="img_FleetRecentSosPickup" src="assets/svg/table/ph_arrow_green.svg" alt="Orders" class="w-19px" />
                                                <img *ngIf="notification?.subType=='DROPOFF'" id="img_FleetRecentSosDropoff" src="assets/svg/table/ph_arrow.svg"  alt="Orders" class="w-19px" />
                                            </div>
                                        </div>

                                        <!-- Customer Name, Driver Profile Picture, Vehicle No  -->
                                        <div class="flex-between pb-1 px-2">
                                            <p class="text-10 font-medium me-0 color-var-500 ph-peek-cust d-block" id="value_FleetRecentSeenHrs" *ngIf="notification?.hours != 0">{{notification?.hours}} {{'hours_ago' | translate}}</p>
                                            <p class="text-10 font-medium me-0 color-var-500 ph-peek-cust d-block" id="value_FleetRecentSeenMin" *ngIf="notification?.minute != 0">{{notification?.minute}} {{'minutes_ago' | translate}}</p>

                                            <div class="ms-auto" *ngIf="notification?.locationLat && notification?.locationLong " id="btn_FleetRecentViewMap" (click)="openModal($event, 'viewMapPin', notification)">
                                                <button class="border rounded-1 px-04 ms-2 btn-32">
                                                    <img alt="logo" class="w-24px" src="assets\svg\sidebar\sites.svg" />
                                                </button>
                                            </div>
                                        </div>
                                        <!--Driver Profile Picture, Vehicle No  -->
                                        <div class="flex-between px-2" style="background: #f0f0f0;">
                                            <div class="d-flex align-items-center justify-content-start col-6">
                                                <span tooltipPosition="top" [pTooltip]="notification?.vehicle?.vehicleNumber">
                                                    <p-avatar image="../../../../assets/svg/Planing-hub/Vehicle/car_fill-black.svg" id="img_FleetRecentVehicleNumAvatar" styleClass="mt-1 justify-content-start" shape="circle"></p-avatar>
                                                </span>
                                                <span class="text-10 font-medium ph-peek-icon-detail" id="value_FleetRecentVehicleNum">{{notification?.vehicle?.vehicleNumber}}</span>
                                            </div>
                                            <!-- P-AVATAR REQUIRES INTEGRATION -->
                                            <div class="d-flex align-items-center justify-content-end col">
                                                <span tooltipPosition="top" pTooltip="Driver name">
                                                    <p-avatar *ngIf="notification?.driver?.user?.imageUrl" id="img_FleetRecentDriverAvatar" [image]="notification?.driver?.user?.imageUrl" styleClass="mt-1" shape="circle"></p-avatar>
                                                    <p-avatar *ngIf="!notification?.driver?.user?.imageUrl" image="assets/svg/Avatar.svg" id="img_FleetRecentDriverAvatar" styleClass="mt-1" shape="circle"></p-avatar>
                                                </span>
                                                <span class="text-10 font-medium ph-peek-icon-detail" id="value_FleetRecentFullName">{{notification?.driver?.user?.fullName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    </ng-container>


                    <p-progressSpinner class="d-flex justify-content-center" styleClass="spinner-fleet" *ngIf="loadingProgress.activity"> </p-progressSpinner>

                </div>
            </div>
        </div>
    </div>
</div>
