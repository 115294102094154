import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthenticationService } from "src/app/auth/authentication.service";

declare var google: any;

@Component({
  selector: "app-completion-form",
  templateUrl: "./completion-form.component.html",
  styleUrls: ["./completion-form.component.scss"],
})
export class CompletionFormComponent implements OnInit {
  data: any;
  dateFormat!: string;
  timezone!: string;
  options: any;

  lat: number = 51.678418;
  lng: number = 7.809007;
  styleValue: any;

  overlays: any = [];

  constructor(
    public modalRef: BsModalRef,
    private auth: AuthenticationService
  ) {}

  ngOnInit() {
    console.log("DATA", this.data);
    this.dateFormat = localStorage.getItem("date_format")!;
    this.timezone = this.auth.getUserTimezone();

    if (
      this.data &&
      this.data.jobOrderCompletionRecordVm &&
      this.data.jobOrderCompletionRecordVm.locationLat &&
      this.data.jobOrderCompletionRecordVm.locationLon
    ) {
      this.options = {
        center: { lat: this.lat, lng: this.lng },
        zoom: 12,
      };

      const lat = this.data.jobOrderCompletionRecordVm.locationLat;
      const lng = this.data.jobOrderCompletionRecordVm.locationLon;

      this.overlays = [
        new google.maps.Marker({
          position: { lat: lat, lng: lng },
          title: "location",
        }),
      ];
    }
  }
}
