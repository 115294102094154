<ng-container *ngFor="let slaForm of slaForms">
  <div class="custella-sla-progress" *ngIf="slaForm?.isCompletion != 'Yes'">
      <!-- SLA Bar -->
      <div>
          <div class="flex-between p-4 pb-0 mb-2">
              <div class="d-flex align-items-center">
                  <span class="text-20 font-medium" id="title_SlaStepName">{{slaForm?.slaStepName}}</span>
                  <span class="text-20 color-var-500 mx-2" id="title_SlaStepViolationDuration"> {{ slaForm.slaStepViolationDuration }} </span>
              </div>
              <div class="d-flex align-items-center justify-content-evenly pe-3">
                  <span class="text-16 font-medium font-orange mx-1" [ngStyle]="{color:slaForm?.colorCode}" *ngIf="!slaForm.isScenario2 && !slaForm.isScenario4" id="label_SlaProgressText1">{{ slaForm?.progrressText1 }}</span>
                  <span class="text-16 font-medium mx-1" *ngIf="!slaForm.isScenario2 && !slaForm.isScenario4" id="label_SlaProgressText2">{{ slaForm?.progrressText2 }}</span>
                  <!-- // -->
                  <span class="text-16 font-medium mx-1" *ngIf="slaForm.isScenario2 || slaForm.isScenario4" id="label_SlaProgressText1">{{ slaForm?.progrressText1 }}</span>
                  <span class="text-16 font-medium font-orange mx-1" [ngStyle]="{color:slaForm?.colorCode}" *ngIf="slaForm.isScenario2 || slaForm.isScenario4" id="label_SlaProgressText2">{{ slaForm?.progrressText2 }}</span>
                  <p-tag class="mx-3" [ngClass]="'p-tag-bac-'+slaForm.slaStatusData" styleClass="p-tag-progress" id="tag_SlaProgressStatus" severity="danger" [value]="slaForm?.progrressStatus"></p-tag>
                  <i class="pi pi-info-circle" style="font-size: 15px;" (click)="op.toggle($event)" id="btn_SlaDropdown" data-bs-toggle="dropdown"></i>
              </div>
          </div>
          <div class="custella-bar ps-4 pb-4">
              <div class="d-flex p-progressbar" style="height: 15px; margin-bottom: -15px;">
                  <div class="sla-time2" [id]="'sla-time2'+slaForm.id" id="label_SlaProgressPercentage">{{ slaForm?.percentage>0?slaForm.percentage>100? '100%': slaForm.percentage+'%':'' }}</div>
              </div>
              <p-progressBar [ngClass]="progressBarClass(slaForm)" id="progressBar_SlaProgressPercentage" [value]="slaForm.percentage" [showValue]="false" class="customProgress"></p-progressBar>
          </div>
      </div>
  </div>
  <!-- <i class="pi pi-info-circle position-absolute" style="right:0; top: 1rem; padding-bottom: 15px; font-size: 15px; margin-right: 25px; padding-top: 15px;" (click)="op.toggle($event)" data-bs-toggle="dropdown"></i> -->
  <div>
      <p-overlayPanel #op [dismissable]="true" [showCloseIcon]="true">
          <ng-template pTemplate>
              <div class="sla-popup">
                  <div class="d-flex align-items-center bg-header px-4 py-2" style="border-top-left-radius: 2px; border-top-right-radius: 2px;">
                      <div class="d-flex align-items-center">
                          <span class="text-14 font-medium font-white elips" id="label_SlaStepName" style="max-width: 210px;">{{slaForm?.slaStepName}}</span>
                          <span class="text-14 font-medium font-white ms-2" id="label_SlaStepViolationDuration"> {{ slaForm.slaStepViolationDuration }} </span>
                      </div>
                  </div>
                  <div class="d-flex align-items-center mx-3 mt-3 mb-2">
                      <span><img class="w-24px" src="assets\svg\table\started.svg" /></span>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressStart" style="width: 100px;">Started:</span>
                          <span class="d-block text-14 font-medium" id="value_SlaProgressStartDateTime">{{ slaForm?.initDateTime | momentDate: "hh:mm a":timezone }}, {{ slaForm?.initDateTime | momentDate: dateFormat:timezone }}</span>
                      </div>
                  </div>
                  <div class="d-flex align-items-center mx-3 my-2">
                      <span><img class="w-24px" src="assets\svg\table\at rissk.svg" /></span>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressAtRisk" style="width: 100px;">At Risk:</span>
                          <span class="d-block text-14 font-medium" id="value_SlaProgressAtRiskDateTime"> {{ slaForm?.atRiskDateTime | momentDate: "hh:mm a":timezone }}, {{ slaForm?.atRiskDateTime | momentDate: dateFormat:timezone }}</span>
                      </div>
                  </div>
                  <div class="d-flex align-items-center mx-3 mt-2 mb-4">
                      <span><img class="w-24px" src="assets\svg\table\violation.svg" /></span>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressViolation" style="width: 100px;">Violation:</span>
                          <span class="d-block text-14 font-medium" id="value_SlaProgressViolationDateTime"> {{ slaForm?.violationDateTime | momentDate: "hh:mm a":timezone }}, {{ slaForm?.violationDateTime | momentDate: dateFormat:timezone }}</span>
                      </div>
                  </div>
                  <div class="d-flex align-items-center mx-3 my-2">
                      <span><img class="w-24px" src="assets\svg\table\exclamation-circle.svg " /></span>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressPassedRisk" style="width: 100px;">{{slaForm.passedRiskLbl}}</span>
                          <span class="d-block text-14 font-medium" id="value_SlaProgressPassedRisk">{{slaForm.passedRiskText}}</span>
                      </div>
                  </div>
                  <div class="d-flex align-items-center mx-3 mt-2 mb-4">
                      <span><img class="w-24px" src="assets\svg\table\exclamation-triangle.svg" /></span>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressViolated" style="width: 100px;">{{slaForm.violatedLbl}}</span>
                          <span class="d-block text-14 font-medium" id="value_SlaProgressViolated">{{slaForm.violatedText}}</span>
                      </div>
                  </div>

                  <!-- #Scenario 1 - No Risk, No Violation, No Completion -->
                  <!-- #Scenario 3 - Yes Risk, No Violation, No Completion -->
                  <div class="d-flex align-items-center mx-3 mb-3 mt-2" *ngIf="slaForm.isScenario1 || slaForm.isScenario3">
                      <i class="pi pi-clock color-var-500" style="font-size: 16px; padding-left: 3px; padding-right: 4px;"></i>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressComplete">{{slaForm.completedLbl}}</span>
                          <span class="mx-3 text-14 font-medium" id="value_SlaProgressComplete1">{{slaForm.completedText1}} <span class="mx-1 text-12 font-medium" style="color: #f57c00;" id="value_SlaProgressComplete2">{{slaForm.completedText2}}</span></span>
                      </div>
                  </div>
                  <!-- #Scenario 2 - No Risk, No Violation, Yes Completion -->
                  <!-- #Scenario 4 - Yes Risk, No Violation, Yes Completion -->
                  <div class="d-flex align-items-center mx-3 mb-3 mt-2" *ngIf="slaForm.isScenario2 || slaForm.isScenario4">
                      <i class="pi pi-check-circle" style="color: #4caf50; font-size: 16px; padding-left: 3px; padding-right: 4px;"></i>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressComplete" style="width: 85px;">{{slaForm.completedLbl}}</span>
                          <span class="mx-3 text-14 font-medium" id="value_SlaProgressComplete1">
                              {{ slaForm?.completedText1 | momentDate: "hh:mm a":timezone }}, {{ slaForm?.completedText1 | momentDate: dateFormat:timezone }}
                              <span class="mx-1 text-12 font-medium" style="color: #4caf50;" id="value_SlaProgressComplete2">{{slaForm.completedText2}}</span>
                          </span>
                      </div>
                  </div>
                  <!-- #Scenario 5 - Yes Risk, Yes Violation, No Completion -->
                  <div class="d-flex align-items-center mx-3 mb-3 mt-2" *ngIf="slaForm.isScenario5">
                      <i class="pi pi-clock color-var-500" style="font-size: 16px; padding-left: 3px; padding-right: 4px;"></i>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" id="label_SlaProgressComplete">{{slaForm.completedLbl}}</span>
                          <span class="mx-3 text-14 font-medium" id="value_SlaProgressComplete1">{{slaForm.completedText1}} <span class="mx-1 text-12 font-medium" style="color: #d34747;" id="value_SlaProgressComplete2">{{slaForm.completedText2}}</span></span>
                      </div>
                  </div>
                  <!-- #Scenario 6 - Yes Risk, Yes Violation, Yes Completion -->
                  <div class="d-flex align-items-center mx-3 mb-3 mt-2" *ngIf="slaForm.isScenario6">
                      <i class="pi pi-check-circle" style="color: #4caf50; font-size: 16px; padding-left: 3px; padding-right: 4px;"></i>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" style="width: 85px;" id="label_SlaProgressComplete">{{slaForm.completedLbl}}</span>
                          <span class="mx-3 text-14 font-medium" id="value_SlaProgressComplete1">
                              {{ slaForm?.completedText1 | momentDate: "hh:mm a":timezone }}, {{ slaForm?.completedText1 | momentDate: dateFormat:timezone }}
                              <span class="mx-1 text-12 font-medium" style="color: #d34747;" id="value_SlaProgressComplete2">{{slaForm.completedText2}}</span>
                          </span>
                      </div>
                  </div>
                  <!-- #Scenario 7 & 8 - PAUSED SLA -->
                  <div class="d-flex align-items-center mx-3 mb-3 mt-2" *ngIf="slaForm.isScenario7 || slaForm.isScenario8">
                      <i class="pi pi-pause" style="color: #f57c00; font-size: 16px; padding-left: 3px; padding-right: 4px;"></i>
                      <div class="d-flex align-items-center mx-2">
                          <span class="text-14 font-medium color-var-500" style="width: 85px;" id="label_SlaProgressComplete">{{slaForm.completedLbl}}</span>
                          <span class="mx-3 text-14 font-medium" id="value_SlaProgressComplete1">
                              {{slaForm.completedText1}}
                              <span class="mx-1 text-12 font-medium" style="color: #f57c00;" id="value_SlaProgressComplete2">{{slaForm.completedText2}}</span>
                          </span>
                      </div>
                  </div>
              </div>
          </ng-template>
      </p-overlayPanel>
  </div>
</ng-container>

<!-- <span class="custella-prog-tag prog-risk p-1" *ngIf="firstResponce?.slaStatus == 'Paused'">
{{ firstResponce?.lastPausedAt | momentDate: "hh:mm a":timezone }},
{{ firstResponce?.lastPausedAt | momentDate: dateFormat:timezone }}
</span> -->
