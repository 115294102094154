import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomersService } from 'src/app/_services/customers.service';
import { DeliveryOrderSetupService } from 'src/app/_services/delivery-order-setup.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-plan-update-confirmation',
  templateUrl: './plan-update-confirmation.component.html',
  styleUrls: ['./plan-update-confirmation.component.scss']
})
export class PlanUpdateConfirmationComponent implements OnInit {
  title: any
  data : any;
  doStatuses : any;
  formData :any = {
    dispatched:"DISPATCHED",
    inProgress:"WITH_DRIVER",
    onHold:"ON_HOLD",
    inComplete:"IN_COMPLETE",
    confirmed:"CONFIRMED"
  }
  removeOrderStatus: any;
  breakdownStatus: any ="RETURN_TO_SENDER";
  dropDownData: any
  dropDownData2 : any
  dropDownData3 : any
  dropDownData4 : any
  dropDownData5 : any

  orderStatusName: any;
  orderStatusColor: any;
  constructor(public modalRef : BsModalRef,
    private customerService: CustomersService,
    private spinner : NgxSpinnerService,
    private planningHubService: PlanningHubService,
    private router: Router,
    private deliveryOrderSetupService : DeliveryOrderSetupService,
    private errorUtil: ErrorUtil
    ) { }

  ngOnInit() {
    
    console.log(this.data,"=====",this.title)
    
    if(this.data.btnName=='removeOrder'){
      this.removeOrderStatus = this.data.deliveryOrderStatus;
    }
    this.deliveryOrderSetupService.getAllStatus().subscribe((res:any) => {
      this.doStatuses = res;
      this.dropDownData = []
      this.dropDownData2 = []
      this.dropDownData3 = []
      this.dropDownData4 = []
      this.dropDownData5 = []
       res.forEach((element : any)=> {
        if(this.data.planStatus==='DISPATCHED' || this.data.planStatus==='IN_PROGRESS'){
          if(this.data.deliveryOrderStatusCountVM){
            if(this.data.deliveryOrderStatusCountVM.dispatchedCount != 0){
              if(element.name == 'CONFIRMED'){
                this.dropDownData.push(element)
              }else if(element.name == 'NEW'){
                this.dropDownData.push(element)
              }else if(element.name == 'DISPATCHED'){
                this.dropDownData.push(element)
              }else if(element.name == 'ON_HOLD'){
                this.dropDownData.push(element)
              }else if(element.name == 'IN_COMPLETE'){
                this.dropDownData.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData.push(element)
              }
            }
            if(this.data.deliveryOrderStatusCountVM.inCompletedCount != 0){
              
              if(element.name == 'IN_COMPLETE'){
                this.dropDownData2.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData2.push(element)
              }
            }
            if(this.data.deliveryOrderStatusCountVM.inprogressCount !=0 ){
              
              if(element.name == 'ON_HOLD'){
                this.dropDownData4.push(element)
              }else if(element.name == 'IN_COMPLETE'){
                this.dropDownData4.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData4.push(element)
              }
            }
            if(this.data.deliveryOrderStatusCountVM.onHoldCount != 0){
              
              if(element.name == 'ON_HOLD'){
                this.dropDownData3.push(element)
              }else if(element.name == 'IN_COMPLETE'){
                this.dropDownData3.push(element)
              }else if(element.name == 'CANCELLED'){
                this.dropDownData3.push(element)
              }
            }
          }

      }else if(this.data.planStatus==='CONFIRMED'){
        if(element.name == 'CONFIRMED'){
          this.dropDownData.push(element)
        }else if(element.name == 'NEW'){
          this.dropDownData.push(element)
        }else if(element.name == 'ON_HOLD'){
          this.dropDownData.push(element)
        }else if(element.name == 'IN_COMPLETE'){
          this.dropDownData.push(element)
        }else if(element.name == 'CANCELLED'){
          this.dropDownData.push(element)
        }
     
      }
       if( this.data.btnName == 'breakdown'){
        if (element.name == this.data.orderStatuses[0]) {
          this.orderStatusColor = element.colorCode;
           this.orderStatusName = element.label;
        }
        console.log( this.data.orderStatuses[0],"-----",this.orderStatusName,"===",this.orderStatusColor)
       
      }
            console.log( this.dropDownData)
          })
    })
  }

  onSubmit(){
    this.spinner.show()
    if(this.data.btnName==='confirmBtn'){
      this.confirm();
    }else if(this.data.btnName==='dispatchBtn'){
      this.dispatch();
    }else{
      var status;
      if(this.data.btnName==='completeBtn'){
        status = "COMPLETED";
      }else if(this.data.btnName==='inCompleteBtn'){
        status = "IN_COMPLETE";
      }
      this.cancelPlan(status);
    }
  }

  onSubmit1(){
    this.cancelPlan('CANCELLED');
  }

  dispatch(){
    this.planningHubService.dispatch(this.data.draftPlanId).subscribe((data: any) => {
      this.spinner.hide()

      if (data.status === 200 || data.status === 201) {
        this.errorUtil.setErrorMessage(data.status, null , 'Dispatched Successfully', 'success',1000);
        this.modalRef.hide();
      }else if(data.status === 400){
        this.errorUtil.setErrorMessage(data.status, null , data.error.title, 'warn',3000);
      }else{
        this.errorUtil.setErrorMessage(data.status, null , data.error.title, 'error',3000);;
      }
    })
  }

  confirm(){
    this.planningHubService.confirm(this.data.draftPlanId).subscribe((data: any) => {
      this.spinner.hide()

      if (data.status === 200 || data.status === 201) {
        this.errorUtil.setErrorMessage(data.status, null , 'Confirmed Successfully', 'success',1000);
        this.modalRef.hide();
      }else if(data.status === 400){
        this.errorUtil.setErrorMessage(data.status, null , data.error.title, 'warn',3000);
      }else{
        this.errorUtil.setErrorMessage(data.status, null , data.error.title, 'error',3000);;
      }
    })
  }
  cancelPlan(status: any){
    var msg: any;
    if(status === 'CANCELLED'){
       msg = "Cancelled" ;
    }else if(status === 'IN_COMPLETE'){
       msg = "Incompleted" ;
       status = 'INCOMPLETE';
    }if(status === 'COMPLETED'){
       msg = "Completed" ;
    }
    if(this.formData.inComplete) this.formData.inComplete = 'INCOMPLETE';
    this.planningHubService.cancelPlan(this.formData,this.data.draftPlanId,status).subscribe((data: any) => {
      this.spinner.hide()

      if (data.status === 200 || data.status === 201) {
        this.errorUtil.setErrorMessage(data.status, null , msg + ' Successfully', 'success',1000);
        this.modalRef.hide();
      }else if(data.status === 400){
        this.errorUtil.setErrorMessage(data.status, null , data.error.title, 'warn',3000);
      }else{
        this.errorUtil.setErrorMessage(data.status, null , data.error.title, 'error',3000);;
      }
    })
  }

  //plan - view - modify - conformation
  confirmModel(){
    this.spinner.show();
    //debugger
    if(this.data.btnName=='proceedAddOrderSpeVeh' && this.data.type == 'postDispatch'){
      this.addOrderPostDispatch();
    }
    else if(this.data.btnName=='removeOrder'){
      this.removeOrder();
    }
    else if(this.data.btnName=='removeVehicle'){
      this.removeVehicle();
    }
    else if(this.data.btnName=='cancelOrder'){
      this.cancelOrder();
    }
    else if(this.data.btnName=='returnOrder'){
      this.returnOrder();
    }
    else if(this.data.btnName=='changeVehicle'){
      this.changeVehicle();
    }
    else if(this.data.btnName=='breakdown'){
      this.breakdown();
    }
    else{//proceedAddOrderSpeVeh - pre dispatch , proceedAddOrderCurrVeh
      this.planningHubService.addOrderConfirm(this.data).subscribe((res: any) => {
       // this.modalRef.hide();
        if (res.status === 200 || res.status === 201) {
          if(res.body.serverError){
            this.spinner.hide();
            this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
          }
          else if(res.body.errors){
            this.spinner.hide();
            this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
          }
          else{
            this.modalRef.hide();
            this.spinner.hide();
            if(this.data.vehicleName){
              this.errorUtil.setErrorMessage(200, null , this.data.orderNumber + ' assigned to ' + this.data.vehicleName, 'success',1000);
            }else{
              this.errorUtil.setErrorMessage(200, null , this.data.orderNumber + ' added successfully', 'success',1000);
            }


          }
        }
        else if (res.status === 400) {
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
        else {
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
        }
      })
    }
  }

  addOrderPostDispatch(){
    this.planningHubService.addOrderPostDispatchConfirm(this.data).subscribe((res: any) => {
     // this.modalRef.hide();
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
         
          this.errorUtil.setErrorMessage(200, null ,'Order added successfully', 'success',1000);
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }

  removeOrder(){
    //debugger
    this.data.status = this.removeOrderStatus;
    this.planningHubService.removeOrderConfirm(this.data).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          // this.toaster.success('', 'Order removed successfully');
          this.errorUtil.setErrorMessage(200, null ,this.data.deliveryOrderName + ' removed successfully ', 'success',1000);
          if(!res.body.newRecordId){
            this.router.navigate(['dashboard/planningHub']);
          }
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }

  removeVehicle(){
    this.planningHubService.removeVehicleConfirm(this.data).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
          
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          // this.toaster.success('', 'Vehicle(s) removed successfully');
          this.errorUtil.setErrorMessage(200, null ,this.data.vehicleNames + ' removed successfully', 'success',1000);
          if(!res.body.newRecordId){
            this.router.navigate(['dashboard/planningHub']);
          }
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }

  cancelOrder(){
    this.planningHubService.cancelOrderConfirm(this.data).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          this.errorUtil.setErrorMessage(200, null ,'Order cancelled', 'success',1000);
          if(!res.body.newRecordId){
            this.router.navigate(['dashboard/planningHub']);
          }
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }

  returnOrder(){
    this.planningHubService.returnOrderConfirm(this.data).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          this.errorUtil.setErrorMessage(200, null ,'Order return confirmed', 'success',1000);
          
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }

  changeVehicle(){
    this.planningHubService.changeVehicleConfirm(this.data).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          this.errorUtil.setErrorMessage(200, null ,'Vehicle updated successfully', 'success',1000);
          if(!res.body.newRecordId){
            this.router.navigate(['dashboard/planningHub']);
          }
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }

  breakdown(){
    //debugger
    this.data.status = this.breakdownStatus;
    this.planningHubService.breakDownConfirm(this.data).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          //this.toaster.success('', 'Vehicle return confirmed');
          this.errorUtil.setErrorMessage(200, null ,  'Vehicle '+this.data.vehicleName+' return confirmed', 'success',1000);
          if(!res.body.newRecordId){
            this.router.navigate(['dashboard/planningHub']);
          }
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
    })
  }



}
