<form class="custella-custom-dialog" [formGroup]="passwordForm" *ngIf="type == 'self'">
    <div class="modal-header p-dialog-header flex-between">
      <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 font-medium ms-3">{{'reset_password' | translate}}</span>
      </div>
      <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
      <div class="modal-body">
      <div class="container">   
          <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" translate>{{'old_password'}}</span>
             <input pInputText autocomplete="off" formControlName="oldPassword" [(ngModel)]="password.oldPassword" name="oldPassword" type="password" >
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['oldPassword'].touched && passwordForm.controls['oldPassword'].invalid">
                    <span *ngIf="passwordForm.controls['oldPassword'].errors?.required" class="text-10 p-error" translate>{{'required_field'}}</span>
                    <span *ngIf="passwordForm.controls['oldPassword'].errors?.pattern" class="text-10 p-error" translate>{{'password_must_be'}}</span>
                  </div>  
          </div>                 
          <div class="custella-content-row-input col-12 mb-3">
              <span class="custella-content-row-input-title" translate>{{'new_password'}}</span>
                <input pInputText autocomplete="off" formControlName="password" [(ngModel)]="password.password" name="password" type="password" >
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['password'].touched && passwordForm.controls['password'].invalid">
                    <span *ngIf="passwordForm.controls['password'].errors?.required" class="text-10 p-error" translate>{{'required_field'}}</span>
                    <span *ngIf="passwordForm.controls['password'].errors?.pattern" class="text-10 p-error" translate>{{'password_must_be'}}</span>
                  </div>   
          </div>
          <div class="custella-content-row-input col-12">
              <span class="custella-content-row-input-title" translate>{{'confirm_new_password'}}</span>
              <input pInputText autocomplete="off" formControlName="cPassword" [(ngModel)]="password.cPassword" name="cPassword" type="password" >
                <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['cPassword'].touched && passwordForm.controls['cPassword'].invalid">
                  <span *ngIf="passwordForm.controls['cPassword'].errors?.required" class="text-10 p-error" translate>{{'required_field'}}</span>
                  <span *ngIf="passwordForm.controls['cPassword'].errors?.pattern" class="text-10 p-error" translate>{{'password_must_be'}}</span>
                </div>    
          </div>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
        <p-button  (click)="changePassword()" [disabled]="submitTouched" label="{{'reset' | translate}}" styleClass="mx-1"></p-button>
    </div>
  </form>
  
  <form class="custella-custom-dialog"  *ngIf="type == 'others' || type == 'resendCustomerUserEmail'">
    <div class="modal-header p-dialog-header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3">{{'reset_password' | translate}}</span>
      </div>
      <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
    <div class="modal-body">
      <div class="container text-center my-4">       
        <p translate>An email will be sent to {{data1?.email}}. Do you wish to proceed password reset?</p>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" (click)="modalRef.hide()">No</button>
        <p-button  [disabled]="submitTouched"  (click)="onSubmit('reset')" [disabled]="submitTouched" label="Yes" styleClass=""></p-button>
    </div>

  </form>
  
  
  <form class="custella-custom-dialog" *ngIf="type == 'resendEmail'">
    <div class="modal-header p-dialog-header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3">{{'resend_email' | translate}}</span>
      </div>
      <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
      <div class="modal-body">
        <div class="container text-center my-4">       
          <p >An email will be sent to {{data1?.email}}. Do you wish to proceed?</p>
        </div>
      </div>
      
      <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" (click)="modalRef.hide()">No</button>
        <p-button  [disabled]="submitTouched"  (click)="onSubmit('email')" [disabled]="submitTouched" label="Yes" styleClass=""></p-button>
    </div>
    </form>


   
      