<div>
  <div class="flex-between">
    <i class="pi pi-times w-24px dialog-close-icon" id="btn_SitesClose" [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="closeTable()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="title" id="label_SitesSelectList">{{"select_site_from_list" | translate}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off" id="input_SitesSelectListField" [placeholder]="'type_site_name' | translate" [(ngModel)]="searchValue" />
      </span>
    </div>
    <div class="height-42-stat border">
      <app-custella-sites-table [customerId]="customerId" [searchValue]="searchValue"></app-custella-sites-table>
    </div>
  </div>
</div>