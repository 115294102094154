import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChecklistService } from 'src/app/_services/checklist.service';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ProductService } from 'src/app/_services/product.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-checklist-product',
  templateUrl: './checklist-product.component.html'
})
export class ChecklistProductComponent extends SubscriptionUtil implements OnInit, OnDestroy {
  buttonLabel: string = 'Save';
  public products: any[] = [];
  addProductForm!: UntypedFormGroup;
  public product: any = {
    product: {},
     quantity: 1,
  };

  selectedProduct: any = null;
  submitTouched!: boolean;
  public showProductTable = false;
  dateConfig: any
  data: any


  constructor(public modalRef : BsModalRef,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    private jobOrderServices: JobOrdersService,
    private errorUtil: ErrorUtil,
    private checklistService : ChecklistService) {
    super();
   }

  ngOnInit(): void {
    console.log(this.data)
    if(this.data.comingFrom == undefined){
     this.product.tmplJobOrder ={id: this.data.tmplJobOrderId}
    }else{
      
      this.product.tmplChecklistAns ={id: this.data.selectedAnswerId}
    }
    // get date format
    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

   
    // set form
    this.addProductForm = new UntypedFormGroup({
        name: new UntypedFormControl(null, [Validators.required]),
        quantity: new UntypedFormControl(null, [ Validators.required, Validators.pattern(AppComponent.validPricePattern),
      ]),
      });
  }

  /******************************************** get product list ******************************************/
  public getProductList(event: any, type: string) {
    let k = event.keyCode;

    const query = event.target.value as string;

    if (type === "products") {
      if (k == 8) {
        this.clearValue();
      } else {
        if (query != null && query.length > 2) {
          this.push(
            this.productService
              .getProductPickListSearchable(query)
              .subscribe((data) => {
                this.products = data;
              })
          );
        }
      }
    }

  }

  /******************************************** on get product via autocomplete select ******************************************/
  public gotProduct(value: any) {
    this.selectedProduct = value;
    this.product.product.id = value.id;
    this.closeProductTable();
    this.products = [];
  }
  /******************************************** show product table ******************************************/
  public displayProductTable() {
    this.showProductTable = true;
  }

  /******************************************** close product table ******************************************/
  public closeProductTable() {
    this.showProductTable = false;
  }
    /******************************************** clear product ******************************************/
    public clearValue() {
      this.product.product.id = null;
      this.products = [];
  
      this.selectedProduct = null;
    }
  
  /******************************************** Add ALl the Subscription ******************************************/
  push(obs: any) {
    super.push(obs);
  }
    /******************************************** On Destroy ******************************************/
    ngOnDestroy(): void {
      super.ngOnDestroy();
    }

     /******************************************** submit data ******************************************/
  public onSubmit() {
    this.spinner.show();
    this.submitTouched = true;

    if (!this.addProductForm.valid) {
      console.log(this.addProductForm);

      for (let i in this.addProductForm.controls) {
        this.addProductForm.controls[i].markAsTouched();
      }
      this.submitTouched = false;
      this.spinner.hide();
      return false;
    }

    let formData: any = {
      quantity: this.product.quantity,
      product: {
        id: this.product.product.id,
      },
      tmplJobOrder: this.product.tmplJobOrder,

    };
   console.log(formData,"===",this.product);

   if(this.data.comingFrom== undefined){
   this.addJoTaskTemplate(formData)
   }else{
    this.addChecklistQuestionTaskTemplate(formData)
   }
    
  }
  addJoTaskTemplate(formData: any){
    this.push(
      this.jobOrderServices.createJobOrderTemplateProduct(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status === 200 || res.status === 201) {
            this.errorUtil.setErrorMessage(200, "Product created successfully", null,"success",  3000 );
            this.modalRef.hide();
          }
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.errorUtil.setErrorMessage( error.status, null, error.error.title, "error", 3000);
        }
      )
    );
  }
  addChecklistQuestionTaskTemplate(formData: any){
    this.push(
      this.checklistService.createAnswerByProduct(this.product).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status === 200 || res.status === 201) {
            this.errorUtil.setErrorMessage(200, "Product added successfully", null,"success",  3000 );
            this.modalRef.hide();
          }
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
          this.errorUtil.setErrorMessage( error.status, null, error.error.title, "error", 3000);
        }
      )
    );
  }
   /******************************************** on cancel product table or product sku table inputs ******************************************/
   public onBlurProduct() {
    if (this.product.product.id == null) {
      this.selectedProduct = null;
      
    }
  }
}
