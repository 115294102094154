import { HttpClient, HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../auth/authentication.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { UtilServiceService } from "./utilService/util-service.service";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";
var dateFormateChange: any;
var router: Router;

@Injectable({
  providedIn: "root",
})
export class DynamicTableColumnsService {
  static rightClick(params: GetContextMenuItemsParams<any>) {
    throw new Error("Method not implemented.");
  }
  public refreshList$: EventEmitter<any>;
  public refreshTemplateList$: EventEmitter<any>;
  public refreshJOTemplateProductList$: EventEmitter<any>;

  private ENDPOINT_ALL_DTC = "/table-column-setup-modules/paged";
  private ENDPOINT_GET_CREATE_EDIT_DTC = "/v2/table-column-setups/config";


  private jobSelectSubject = new Subject<any>();
  public refresherPickList$: EventEmitter<any>;

  private externalJODATA: any;

  constructor(
    private http: HttpClient,
    private httpOld: Http,
    private auth: AuthenticationService,
    private router: Router,
    private util: UtilServiceService,
  ) {
    this.refresherPickList$ = new EventEmitter();
    this.refreshList$ = new EventEmitter();
    this.refreshTemplateList$ = new EventEmitter();
    this.refreshJOTemplateProductList$ = new EventEmitter();
  }

  /******************************************** set item select ******************************************/
  public setSelectItem(val: any) {
    this.jobSelectSubject.next(val);
  }

  /******************************************** get job order after select ******************************************/
  public getSelectDTC() {
    return this.jobSelectSubject.asObservable();
  }


  /******************************************** get All Template for dynamic Column ******************************************/
  public getAllDTC(searchValue: any, parameters: any, apiModule: any) {
    let params = new HttpParams();
  
    if(searchValue !== '' && searchValue != null){
      params = params.set('search', searchValue);
    }
    params = params.set('apiModule', apiModule);
    console.log(parameters)
    if(parameters)
  
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get(environment.base_url + this.ENDPOINT_ALL_DTC, { observe: 'response',params: params,})
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  
  /******************************************** create Template for dynamic Column ******************************************/
  public createDTC(body: any) {
    return this.http
      .post(environment.base_url + this.ENDPOINT_GET_CREATE_EDIT_DTC, body, {
        observe: "response",
      })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** edit Template for dynamic Column ******************************************/
  public editDTC(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_GET_CREATE_EDIT_DTC, body, { observe: "response" })
      .pipe(
        map(
          (resp: any) => {
            return resp;
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }

  /******************************************** delete Template for dynamic Columnr ******************************************/
  public deleteDTC(id: any) {
    return this.http
      .delete(environment.base_url + '/table-column-setups/' + id, { observe: "response" }).pipe(map( (resp: any) => {
        this.refreshList$.emit('Deleted Dynamic Table Template');    
        return resp;
          
          },
          catchError((error) => {
            //console.log(error);
            return throwError(error);
          })
        )
      );
  }
 /******************************************** Get Single Record for Template for dynamic Columnr ******************************************/
  public getDTCById(templateId: any){
    return this.http.get( environment.base_url+this.ENDPOINT_GET_CREATE_EDIT_DTC +'/'+ templateId, {observe: "response"}).pipe(map( (resp:any) => {
      if(resp.status == 204){
        return false;
      } else {
        return resp.body;
      }
    }
  ),catchError( error => {
        return throwError( error );
    }),);
  }
/******************************************** Get Picklist ******************************************/
public getFieldPicklist(apiModuleName: any){
  return this.http.get( environment.base_url +'/table-column-setups/field-picklist?apiModule='+ apiModuleName, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}

/******************************************** Get Picklist base on Access******************************************/
public getAccessTableColumnSetups(profileId: any){
  return this.http.get( environment.base_url +'/profile-table-column-setups?profileId='+ profileId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}



/******************************************** Save setting base on user profile ******************************************/
public saveNewUserOnly(body: any){
  return this.http.put( environment.base_url +'/profile-table-column-setups', body, {observe: "response"}).pipe(map( (resp:any) => {
   
      return resp;
    
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/******************************************** Get Picklist base on Access******************************************/
public getProfileTableColumnSetups(userId: any){
  return this.http.get( environment.base_url +'/user-table-column-setups?userId='+ userId, {observe: "response"}).pipe(map( (resp:any) => {
    if(resp.status == 204){
      return false;
    } else {
      return resp.body;
    }
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/******************************************** Save Access Permission Base on All Sales Manager Users ******************************************/
public saveUsersTableTemplate(body: any){
  return this.http.put( environment.base_url +'/user-table-column-setups',body, {observe: "response"}).pipe(map( (resp:any) => {
    return resp;
    
  }
),catchError( error => {
      return throwError( error );
  }),);
}
/****************************************** Redirect URL *********************************/
redirectURL(e: any){
  console.log(e.colDef.field,"===",e.colDef.cellClass,"===",e.data.customer)
  this.util.resetTabView();
  if (e.colDef.field === "runningNo" && e.colDef.cellClass) {
    this.router.navigate(["dashboard/assets/view/" + e.data.id], { queryParams: { id: e.data.id, serialNo: null } });
  }

  else if (e.colDef.field == "customer.name" && e.colDef.cellClass && e.data.customer) {
    this.router.navigate(["dashboard/customers/view/" + e.data.customer.id], {queryParams: { id: e.data.customer.id }});
  }
  else if (e.colDef.field == "project.name" && e.colDef.cellClass && e.data.project) {
    this.router.navigate(["dashboard/projects/view/" + e.data.project.id], {queryParams: { id: e.data.project.id }});
  }
  else if (e.colDef.field == "site.siteName" && e.colDef.cellClass && e.data.site) {
    this.router.navigate(["dashboard/sites/view/" + e.data.site.id], {queryParams: { id: e.data.site.id }});
  }
  else if ((e.colDef.field == "taskNumber" && e.colDef.cellClass) ||  (e.colDef.field == "taskName" && e.colDef.cellClass)  && e.data.id) {
    this.util.openTaskVIewPage(e.data.id)
    // this.router.navigate(['dashboard/tasks/view'], { queryParams: { id: e.data.id}});
  }
  else if (e.colDef.field == "assets.name" && e.colDef.cellClass && e.data.assets) {
    this.router.navigate(["dashboard/assets/view/" + e.data.assets.id], {queryParams: { id: e.data.assets.id }});
  }
  else if (e.colDef.field == "scheduleNumber"  && e.colDef.cellClass) {
    this.router.navigate(["/dashboard/assets/preventative-maintenance/view/" + e.data.id],{ queryParams: { id: e.data.id } });
  }
  else if (e.colDef.field == "jobOrder.jobOrderNumber"  && e.colDef.cellClass && e.data.jobOrder) {
    this.router.navigate(["/dashboard/jobOrders/view/" + e.data.jobOrder.id],{ queryParams: { jobOrderId: e.data.jobOrder.id } });
  }
  else  if (e.colDef.field == "jobOrder.salesOrder.salesOrderNumber" && e.colDef.cellClass && e.data.assets) {
    this.router.navigate(["dashboard/assets/view/" + e.data.assets.id], {queryParams: { customerId: e.data.jobOrder.customer.id,salesOrderId: e.data.jobOrder.salesOrder.id, }});
  }else if (e.colDef.field == "jobOrder.ticket.ticketNo" && e.colDef.cellClass && e.data.ticket) {
    this.router.navigate( ["dashboard/tickets/view/" + e.data.ticket.id],{queryParams: { id: e.data.ticket.id } } )
  
  }else if (e.colDef.field == "duplicateTicket.ticketNo" && e.colDef.cellClass && e.data.duplicateTicket) {
    this.router.navigate( ["dashboard/tickets/view/" + e.data.duplicateTicket.id],{queryParams: { id: e.data.duplicateTicket.id } } )
  }
}
/****************************************** Right Click URL *********************************/
rightClick(params: any){
  params.node.data.field = params.column.colId;
  let url = "";
  let data = "";

  let results = [];
console.log(params.value,"===",params.column.colDef.cellClass,"===",params.column.colId)
  if ( (params.column.colId == "customer.name" || params.column.colId == "customerNumber") && params.column.colDef.cellClass && params.value) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  if (params.column.colDef.cellClass && params.value &&  (params.column.colId == "salesOrder.salesOrderNumber" || params.column.colId=="salesOrder")) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  if (params.column.colDef.cellClass && params.value && params.column.colId == "assets.name") {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }if (params.column.colDef.cellClass && params.value && params.column.colId == "jobOrder.jobOrderNumber") {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  if (params.column.colDef.cellClass && params.value && (params.column.colId == "ticketNo" || params.column.colId == "ticket.ticketNo")) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }
  if (params.column.colDef.cellClass && params.value && (params.column.colId == "siteNumber" || params.column.colId == "site.siteName")) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }
  if (params.column.colDef.cellClass && params.value && (params.column.colId == "projectNumber" || params.column.colId =="project.name")) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }
  if (params.column.colDef.cellClass && params.value && (params.column.colId == "duplicateTicket.ticketNo")) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

return results;
}
 /******************************************** redirect to ******************************************/
 private redirectToNewTab(data: any) {
  let url;
  this.util.resetTabView();
  
  if (data.field === "jobOrder.jobOrderNumber") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(["dashboard/jobOrders/view/" + data.id], {
        queryParams: { jobOrderId: data.id },
      })
    );
  }

  if (data.field === "jobOrder.customer.name" || data.field === "customer.name") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/customers/view/" + data.customer.id], {queryParams: { id: data.customer.id },} )
    );
  }
  if (data.field == "customerNumber") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/customers/view/" + data.id], {queryParams: { id: data.id },})
    );
  }
  if (data.field == "projectNumber"||  data.field =="project.name") {
    var projectId= data.project ? data.project.id:data.id
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/projects/view/"+projectId], {queryParams: { id: projectId },})
    );
  }

  if (data.field === "salesOrder" ||data.field === "salesOrder.salesOrderNumber") {
    var salesOrderId= data.salesOrder ? data.salesOrder.id:data.salesOrder.id
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/salesOrders/view/" + salesOrderId],
        {
          queryParams: {
            customerId: data.customer.id,
            salesOrderId: salesOrderId,
          },
        }
      )
    );
  }

  if (data.field === "jobOrder.assets.name" || data.field === "assets.name") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(["dashboard/assets/view/" + data.assets.id], {
        queryParams: { id: data.assets.id, serialNo: null },
      })
    );
  }

  if (data.field === "ticketNo" || data.field === "ticket.ticketNo") {
    var ticketNoId= data.ticket ? data.ticket.id:data.id
    url = this.router.serializeUrl(
      this.router.createUrlTree( ["dashboard/tickets/view/" + ticketNoId],{queryParams: { id: ticketNoId } } )
    );
  }
  if (data.field === "site.siteName" || data.field === "siteNumber") {
    var siteId= data.site ? data.site.id:data.id
    url = this.router.serializeUrl(
      this.router.createUrlTree( ["dashboard/sites/view/" + siteId],{queryParams: { id: siteId } } )
    );
   
  }
  if (data.field === "duplicateTicket.ticketNo" ) {
    var ticketNoId= data.duplicateTicket.id
    url = this.router.serializeUrl(
      this.router.createUrlTree( ["dashboard/tickets/view/" + ticketNoId],{queryParams: { id: ticketNoId } } )
    );
  }

  if (url) {
    url = url.replace("%23", "#");
    this.util.openItemToNewTab(url);
  }
}

}
