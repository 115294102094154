import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment-timezone';
import { AuthenticationService } from '../auth/authentication.service';
import { RequestOptions, Headers, Http } from '@angular/http';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import {Observable, of as throwError } from 'rxjs';

import {catchError, map} from 'rxjs/operators';

@Injectable()
export class PlanningHubService {
public refreshList$: EventEmitter<any>;
public refreshReorderSchedularList$: EventEmitter<any>;
public refreshAllMap$: EventEmitter<any>;
public refreshSelectedMap$: EventEmitter<any>;
public refreshZoomMap$: EventEmitter<any>;
public openMarkerInfo$: EventEmitter<any>;

public openCards$: EventEmitter<any>;
public openVehicleCards$: EventEmitter<any>;
public refreshAfterCancelList$: EventEmitter<any>;
public refreshAfterCancel$: EventEmitter<any>;
public refreshAfterRemove$: EventEmitter<any>;
public refreshAfterRePlan$: EventEmitter<any>;
public refreshAfterVehicleLock$: EventEmitter<any>;

private ENDPOINT_CUSTOMERS_PICK_LIST = "/v2/customers/pick-list";
private ENDPOINT_FLEET_DRAFT_ROUTE_PLANS = "/fleet-draft-route-plans";
private ENDPOINT_ROUTE_PLANNER = "/route-planner";
private ENDPOINT_ROUTE_PLANNER_Vehicle_allocation = "/route-planner/vehicle-allocation";
private ENDPOINT_DELIVERY_ORDERS = "/v4/delivery-orders";
private ENDPOINT_DELIVERY_ORDERS_BY_ID = "/delivery-orders/";
private ENDPOINT_VEHICLES_BY_ID = "/vehicles/";
private ENDPOINT_VEHICLES = "/vehicles";
private ENDPOINT_GEOFENCES = "/geo-fences";
private ENDPOINT_SUB_CONTRACTORS = "/sub-contractors";
private ENDPOINT_VEHICLETYPES = "/vehicle-types";
private ENDPOINT_ORDERTYPES = "/delivery-order-ways";
private ENDPOINT_FLEET_DRAFT_ROUTE_PLANS_FILTER = "/fleet-draft-route-plans/filter";
private ENDPOINT_DISPATCH = "/fleet-draft-route-plans/dispatch";
private ENDPOINT_CONFIRM = "/fleet-draft-route-plans/confirm";
private ENDPOINT_GEOFENCE_PICK_LIST = "/geo-fences/pick-list";
private ENDPOINT_DEPOTS_PICK_LIST = "/depots";
private ENDPOINT_VEHICLE_PICK_LIST = "/vehicles-pick-list";
private ENDPOINT_RETURN_TO_DEPOT = "/route-planner/return-order-to-depot";
private ENDPOINT_ORG_SETTINGS_HOURS = "/get-org-settings/working-hours/by-date?dateTime=";
private ENDPOINT_FLEET_DRAFT_UPDATE_STATUS = "/fleet-draft-route-plans/update-status";
private ENDPOINT_VEHICLE_SELECT_ALL = "/vehicles/select-all";
private ENDPOINT_DELIVERY_ORDERS_UPDATE_ETA = "/delivery-orders/update-eta";
private ENDPOINT_ROUTE_SHIFT = "/route-shift";

private ENDPOINT_FLEET_DELETE_PLANS_BY_ID = "/fleet-draft-route-plans/";
private ENDPOINT_FLEET_DRAFT_ROUTE_PLANS_BY_ID = "/v2/fleet-draft-route-plans/";
private ENDPOINT_FLEET_DRAFT_ROUTE_PLANS_BY_ID_ACTUAL = "/fleet-draft-route-plans/actual/";

private ENDPOINT_GROUP_BY_ORDERS_BY_ID = "/fleet-draft-route-plans/group-by-orders/";
private ENDPOINT_GROUP_BY_ORDERS_BY_ID_ACTUAL = "/fleet-draft-route-plans/group-by-orders/actual/";

private ENDPOINT_ROUTE_PLAN_VEHICLE_TRIP = "/fleet-draft-route-plans/vehicle-trip";
private ENDPOINT_ROUTE_PLAN_VEHICLE_TRIP_ACTUAL = "/fleet-draft-route-plans/vehicle-trip/actual";

private ENDPOINT_VEHICLE_TRIP_ALL = "/fleet-draft-route-plans/vehicle-trip/all";
private ENDPOINT_VEHICLE_TRIP_ALL_ACTUAL = "/fleet-draft-route-plans/vehicle-trip/all/actual";

private ENDPOINT_REMOVE_ORDER = "/route-planner/remove/step-1?deliveryOrderId=";
private ENDPOINT_MANUAL_ROUTE_SHIFT = "/manual-route-shift";
private ENDPOINT_MANUAL_ROUTE = "/route-manual";
private ENDPOINT_ROUTE_PLANNER_ADD_ORDER = "/route-planner/add-order";
private ENDPOINT_ROUTE_PLANNER_MODIFY_ADD_ORDER = "/route-planner/modify/add-order";
private ENDPOINT_ROUTE_PLANNER_REMOVE_ORDER = "/route-planner/remove-order";
private ENDPOINT_ROUTE_PLANNER_REMOVE_VEHICLE = "/route-planner/remove-vehicle";
private ENDPOINT_ROUTE_PLANNER_REPLAN_ORDERS = "/route-planner/re-plan-orders";
private ENDPOINT_ROUTE_PLANNER_ADJUST_PLAN_ORDERS = "/route-planner/adjust-plan-orders";
private ENDPOINT_ROUTE_PLANNER_BREAKDOWN = "/route-planner/break-down";
private ENDPOINT_ROUTE_PLANNER_CANCEL_ORDER = "/route-planner/cancel-order";
private ENDPOINT_ROUTE_PLANNER_CHANGE_VEHICLE = "/route-planner/change-vehicle";
private ENDPOINT_ROUTE_PLANNER_RETURN_ORDER = "/route-planner/return-order";


constructor(private http: HttpClient,
  private auth: AuthenticationService,
  private httpOnly: Http,
  private router : Router) {
      this.refreshList$ = new EventEmitter();
      this.refreshReorderSchedularList$ = new EventEmitter();
      this.refreshAllMap$ = new EventEmitter();
      this.refreshSelectedMap$ = new EventEmitter();
      this.refreshZoomMap$ = new EventEmitter();
      this.openCards$ = new EventEmitter();
      this.openVehicleCards$ = new EventEmitter();
      this.refreshAfterCancelList$ = new EventEmitter();
      this.refreshAfterCancel$ = new EventEmitter();
      this.refreshAfterRemove$ = new EventEmitter();
      this.refreshZoomMap$ = new EventEmitter();
      this.refreshAfterRePlan$ = new EventEmitter();
      this.openMarkerInfo$ = new EventEmitter();
      this.refreshAfterVehicleLock$ = new EventEmitter();
}

public zoomInSelectedOrder(data: any){
  
  this.refreshZoomMap$.emit(data);
}

public mapToolTipClick(orderNumber: any){
  this.openCards$.emit(orderNumber);
}

public vehicleClick(vehicleId : any){
  this.openVehicleCards$.emit(vehicleId);
}
/********************************************************** Delete Plan **************************************************/
public deletePlanningHub(body:any,id: any){
  let options:any = {}
  options.observe = 'response'
  options.body = body
  return this.http.delete( environment.base_url+this.ENDPOINT_FLEET_DELETE_PLANS_BY_ID+id, options).pipe(map( (resp:any) => {
    this.refreshList$.emit('Deleted Planning Hub');
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/********************************************************** Get All Route of Plan (Actual/ Planned)**************************************************/
public getAllRoutes(planId : any,planType : any){
  let params = new HttpParams();
  var url;
    if(planType){
      url = environment.base_url+this.ENDPOINT_VEHICLE_TRIP_ALL_ACTUAL;
    }else{
      url = environment.base_url+this.ENDPOINT_VEHICLE_TRIP_ALL;
    }
  if(planId)
  params = params.set('planId', planId);
  //return this.http.get( environment.base_url+this.ENDPOINT_VEHICLE_TRIP_ALL, { observe: 'response', params : params }).map( (resp:any) => {
  return this.http.get( url, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**********************************************************  Plan Dispatch**************************************************/
public dispatch(planId : any){
  let params = new HttpParams();
  if(planId)
  params = params.set('planId', planId);
  return this.http.post( environment.base_url+this.ENDPOINT_DISPATCH,null, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

// public confirm(planId){
//   let params = new HttpParams();
//   if(planId)
//   params = params.set('planId', planId);
//   return this.http.post( environment.base_url+this.ENDPOINT_CONFIRM,null, { observe: 'response', params : params }).map( (resp:any) => {
//     this.refreshAfterCancelList$.emit();
//     return resp;
//   }
// ).catch( error => {
//       return Observable.of( error );
//   });
// }
// public cancelPlan(body:any,planId,status){
//   let params = new HttpParams();
//   if(planId)
//     params = params.set('planId', planId);
//   if(status)
//     params = params.set('status', status);
//   return this.http.put( environment.base_url+this.ENDPOINT_FLEET_DRAFT_UPDATE_STATUS,body, { observe: 'response', params : params }).map( (resp:any) => {
//     this.refreshAfterCancelList$.emit();
//     return resp;
//   }
// ).catch( error => {
//       return Observable.of( error );
//   });
// }

public getRoutePlanVehicleTrip(routeId: any,planType: any){
  let params = new HttpParams();
  if(routeId)
    params = params.set('routeId', routeId);
    var url;
    if(planType){
      url = environment.base_url+this.ENDPOINT_ROUTE_PLAN_VEHICLE_TRIP_ACTUAL;
    }else{
      url = environment.base_url+this.ENDPOINT_ROUTE_PLAN_VEHICLE_TRIP;
    }
  //return this.http.get( environment.base_url+this.ENDPOINT_ROUTE_PLAN_VEHICLE_TRIP, { observe: 'response', params : params }).map( (resp:any) => {
  return this.http.get(url, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** get  Order By Id( Group By) ************************************************************/
public getGroupByOrdersById(id : any,planType: any){
  var url;
    if(planType){
      url = environment.base_url+this.ENDPOINT_GROUP_BY_ORDERS_BY_ID_ACTUAL + id;
    }else{
      url = environment.base_url+this.ENDPOINT_GROUP_BY_ORDERS_BY_ID + id;
    }
  return this.http.get(url).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** get  Plan List ************************************************************/
  public getAllPlans(search: any,parameters: any,startDateTime: any,endDateTime: any, status: any){
    let params = new HttpParams();
    
    if(status)
      params = params.set('status', status);
    if(search)
      params = params.set('search', search);
    if(startDateTime)
      params = params.set('startDateTime', startDateTime);
    if(endDateTime)
      params = params.set('endDateTime', endDateTime);
    if(parameters)
    params = params.set('startFrom', parameters.startRow).set('perPage', '25').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get( environment.base_url+this.ENDPOINT_FLEET_DRAFT_ROUTE_PLANS, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** get Fleet Route Plan By Id*******************************************************/
public getFleetRoutePlanById(id : any,planType: any){
  var url;
  if(planType){
    url = environment.base_url+this.ENDPOINT_FLEET_DRAFT_ROUTE_PLANS_BY_ID_ACTUAL + id;
  }else{
    url = environment.base_url+this.ENDPOINT_FLEET_DRAFT_ROUTE_PLANS_BY_ID + id;
  }
    return this.http.get(url,{ observe: 'response'}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }
/************************************************** Search in planinhub*******************************************************/
  public search(planId : any,tab : any,orderStatus : any,search : any,isDeliveryOrderFilter : any,vehicleStatus : any){
    let params = new HttpParams();
    if(planId)
      params = params.set('planId', planId);
    if(tab){
      if(tab === "planned") {
        tab = "ASSIGNED";
      }
      else if(tab === "unplanned") {
        tab = "UNASSIGNED";
      }
      else if(tab === "all") {
        tab = "ALL";
      }
      params = params.set('tab', tab);
    }
    if(orderStatus)
      params = params.set('orderStatus', orderStatus);
    if(search)
      params = params.set('search', search);
    isDeliveryOrderFilter = isDeliveryOrderFilter ? isDeliveryOrderFilter : false;
      params = params.set('isDeliveryOrderFilter', isDeliveryOrderFilter);
    if(vehicleStatus)
      params = params.set('vehicleStatus', vehicleStatus);
    return this.http.get( environment.base_url+this.ENDPOINT_FLEET_DRAFT_ROUTE_PLANS_FILTER, { observe: 'response', params : params }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

  public getOrgSettingsWorkingHours(date: any){
    return this.http.get( environment.base_url+this.ENDPOINT_ORG_SETTINGS_HOURS+date, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

   /************************************************** Depot List ************************************************************/
  public getDepots(geoFenceList: any){
    let params = new HttpParams();
    if(geoFenceList)
      params = params.set('geoFenceList', geoFenceList);
    return this.http.get( environment.base_url+this.ENDPOINT_DEPOTS_PICK_LIST, { observe: 'response', params : params }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

/************************************************** Add Order Confirmation ************************************************************/
public addOrderConfirm(body:any){
  //debugger
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_ADD_ORDER, body, { observe: 'response'}).pipe(map( (resp:any) => {
    if (resp.status === 200 || resp.status === 201) {
      if(!resp.body.serverError && !resp.body.errors){
        this.refreshAfterCancelList$.emit();
      }
    }
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Add Order Post Dispatch Confirmation ************************************************************/
public addOrderPostDispatchConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_MODIFY_ADD_ORDER, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** BreakDown Confirmation ************************************************************/
public breakDownConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_BREAKDOWN, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Remove Order Confirmation ************************************************************/
public removeOrderConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_REMOVE_ORDER, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterRemove$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Remove VehicleConfirmation ************************************************************/
public removeVehicleConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_REMOVE_VEHICLE, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterRemove$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Cancel Order Confirmation ************************************************************/
public cancelOrderConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_CANCEL_ORDER, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterRemove$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Return Order Confirmation ************************************************************/
public returnOrderConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_RETURN_ORDER, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterRemove$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Change Vehicle Confirmation ************************************************************/
public changeVehicleConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_CHANGE_VEHICLE, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit(resp);
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Adjuct Replan Confirmation ************************************************************/
public adjustReplanConfirm(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_REPLAN_ORDERS, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Cancel PLan ************************************************************/
public cancelPlan(body:any,planId : any,status: any){
  let params = new HttpParams();
  if(planId)
    params = params.set('planId', planId);
  if(status)
    params = params.set('status', status);
  return this.http.put( environment.base_url+this.ENDPOINT_FLEET_DRAFT_UPDATE_STATUS,body, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/************************************************** Confirm plan ************************************************************/
public confirm(planId:any){
  let params = new HttpParams();
  if(planId)
  params = params.set('planId', planId);
  return this.http.post( environment.base_url+this.ENDPOINT_CONFIRM,null, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/******************************** Adjust New Vehicle *************************************/
public adjustNewVehicle(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_ADJUST_PLAN_ORDERS, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/******************************** Lock / Unlock Vehicle *************************************/
public lockVehicle(locked : any,routeId: any,refreshStatus : any){
  return this.http.get( environment.base_url+'/fleet-draft-route-plans/lock/' + locked + '/by-id/'+ routeId ).pipe(map( (resp:any) => {
    var sendData = {locked: locked,routeId:routeId,refreshStatus: refreshStatus}
    this.refreshAfterVehicleLock$.emit(sendData)
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/************************************************** get  Plan List ************************************************************/
  public getAllOrderByDate(search: any,orderWayList: any,orderStatusList: any, geoFenceIds: any ,depotIds: any,startPickupDate: any,endPickupDate: any,forPlaningHub: any,limit: any,splitType: any){
    let params = new HttpParams();
    
    if(orderStatusList)
      params = params.set('orderStatusList', orderStatusList);
    if(search)
      params = params.set('search', search);
      if(orderWayList)
      params = params.set('orderWayList', orderWayList);
    if(geoFenceIds)
      params = params.set('geoFenceIds', geoFenceIds);
    if(depotIds)
      params = params.set('depotIds', depotIds);
    if(startPickupDate)
      params = params.set('startPickupDate', startPickupDate);
    if(endPickupDate)
      params = params.set('endPickupDate', endPickupDate);
    if(forPlaningHub)
      params = params.set('forPlaningHub', forPlaningHub);
      if(limit)
      params = params.set('limit', limit);
      if(splitType)
      params = params.set('splitType', splitType);
  
      params = params.set('columnName', 'orderNumber').set('sortOrder', 'asc');
    return this.http.get( environment.base_url+'/delivery-orders/select-all', { observe: 'response', params : params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

//vehicles dd
public getVehiclesPickList(){
  return this.http.get( environment.base_url+this.ENDPOINT_VEHICLE_PICK_LIST, { observe: 'response'}).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}


//new plan - Delivery Order tab - grid call
public getNewPlansForDO(startRow: any,parameters:any, search:any,startOrderDate:any,endOrderDate:any,depotIds:any,orderWayList:any,geoFenceIds:any,OrderStatus:any, splitType:any){
  let params = new HttpParams();
  console.log(parameters)
  if(search)
    params = params.set('search', search);
   if(startOrderDate)
    params = params.set('startPickupDate', startOrderDate);
  if(endOrderDate)
    params = params.set('endPickupDate', endOrderDate);
  if(depotIds)
    params = params.set('depotIds', depotIds);
  if(orderWayList)
    params = params.set('orderWayList', orderWayList);
  if(geoFenceIds)
    params = params.set('geoFenceIds', geoFenceIds);
    params = params.set('forPlaningHub', 'true');
  if(OrderStatus)
    params = params.set('orderStatusList', OrderStatus);
    if(splitType)
    params = params.set('splitType', splitType);
  console.log(parameters)
  
    params = params.set('startFrom', parameters.startRow).set('perPage', parameters.pageSize).set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
  return this.http.get( environment.base_url+this.ENDPOINT_DELIVERY_ORDERS, { observe: 'response', params : params }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

// Vehicles tab - select call
public getSelectAllVehicles(vehicleTypeId : any,vehicleStatus : any,vehicleCategoryId : any,active : any,forPlaningHub : any,planDate : any,planStartTime : any,planEndTime : any,deportList : any){
  let params = new HttpParams();
  
  if(vehicleStatus)
    params = params.set('vehicleStatus', vehicleStatus);
  if(vehicleCategoryId)
    params = params.set('vehicleCategoryId', vehicleCategoryId);
    params = params.set('active', active);
    params = params.set('forPlaningHub', forPlaningHub);
  if(vehicleTypeId)
    params = params.set('vehicleTypeIdList', vehicleTypeId);
  if(planDate)
    params = params.set('planDate', planDate);
  if(planStartTime)
    params = params.set('planStartTime', planStartTime);
  if(planEndTime)
    params = params.set('planEndTime', planEndTime);
  if(deportList)
    params = params.set('deportList', deportList);
  params = params.set('driverRequired', 'true');//hide inactive or suspended driver

  params = params.set('columnName', 'vehicleNumber').set('sortOrder', 'asc');
    return this.http.get( environment.base_url+this.ENDPOINT_VEHICLE_SELECT_ALL, { observe: 'response', params : params }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

  //subContractors dd
  public getSubContractors(){
    return this.http.get( environment.base_url+this.ENDPOINT_SUB_CONTRACTORS, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }
  //GeoFences dd
  public getGeoFences(){
    return this.http.get( environment.base_url+this.ENDPOINT_GEOFENCE_PICK_LIST, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }
  //vehicles dd
  public getVehiclesDd(){
    return this.http.get( environment.base_url+this.ENDPOINT_VEHICLE_PICK_LIST, { observe: 'response'}).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

  //vehicle types dd
  public getVehicleTypes(){
    return this.http.get( environment.base_url+this.ENDPOINT_VEHICLETYPES, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

  //order types dd
  public getOrderTypes(){
    return this.http.get( environment.base_url+this.ENDPOINT_ORDERTYPES, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

  //new plan - Vehicles tab - grid call
public getVehicles1(search:any,startDateTime:any,endDateTime:any,vehicleStatus:any,vehicleTypeId:any,geoFenceId:any,subContractorId:any,planDate:any,deportList:any,planStartTime:any,planEndTime:any,parameters:any){
  let params = new HttpParams();
 
  if(search)
    params = params.set('search', search);
  if(startDateTime)
    params = params.set('startDateTime', startDateTime);
  if(endDateTime)
    params = params.set('endDateTime', endDateTime);
  if(vehicleStatus)
    params = params.set('vehicleStatusList', vehicleStatus);
  if(vehicleTypeId)
    params = params.set('vehicleTypeIdList', vehicleTypeId);
  if(geoFenceId)
    //params = params.set('geoFenceId', geoFenceId);
    params = params.set('geoFenceIdList', geoFenceId);
  if(subContractorId)
    params = params.set('subContractorIdList', subContractorId);
  if(planDate)
    params = params.set('planDate', planDate);
  if(deportList)
    params = params.set('deportList', deportList);
    params = params.set('forPlaningHub', 'true');
  if(planStartTime)
    params = params.set('planStartTime', planStartTime);
  if(planEndTime)
    params = params.set('planEndTime', planEndTime);
  params = params.set('active', 'true');
  params = params.set('driverRequired', 'true');

   params = params.set('startFrom', parameters.startRow).set('perPage', '50').set('columnName', parameters.sortModel[0].colId).set('sortOrder', parameters.sortModel[0].sort);
    return this.http.get( environment.base_url+this.ENDPOINT_VEHICLES, { observe: 'response', params : params }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }

  //optimize
  public optimize(body:any,capacityPrio: any){
    let params = new HttpParams();
    params = params.set('capacityPrio', capacityPrio);

    return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER, body, { observe: 'response', params : params }).pipe(map( (resp:any) => {
      if (resp.status === 200 || resp.status === 201) {
        if(resp.body.draftPlanId){
          this.refreshList$.emit(resp);
        }
      }
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }
  public optimizeVehicle(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_PLANNER_Vehicle_allocation, body, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }


 public rePlan(planId: any,body: any){
  return this.http.post( environment.base_url+'/mark-small-track-pickup?planId='+planId,body, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public swithOrderSameVeh(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_MANUAL_ROUTE_SHIFT, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

public swithOrderDiffVeh(body:any){
  return this.http.post( environment.base_url+this.ENDPOINT_MANUAL_ROUTE, body, { observe: 'response'}).pipe(map( (resp:any) => {
    this.refreshAfterCancelList$.emit();
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
public swithOrderDiffVehCancel(){
  this.refreshAfterCancel$.emit('cancel');
}

public removeOrderFromPlan(orderLineId : any,planId : any){

    var plan="";
    if(planId !== null){
     plan="&planId="+planId
    }
    return this.http.post( environment.base_url+this.ENDPOINT_REMOVE_ORDER+orderLineId+plan,null, { observe: 'response' }).pipe(map( (resp:any) => {
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }
  public refreshViewPlanFromRoute(){
    this.refreshAllMap$.emit('refresh Map');
  }
  public openMapMarkerTooltip(event: any){
    this.openMarkerInfo$.emit(event);
  }
  public viewSelectedMap(){
    this.refreshSelectedMap$.emit();
  }
  public reorderSchedular(body:any){
    return this.http.post( environment.base_url+this.ENDPOINT_ROUTE_SHIFT, body, { observe: 'response'}).pipe(map( (resp:any) => {
      this.refreshReorderSchedularList$.emit();
      return resp;
    }
  ),catchError( error => {
      return throwError( error );
    }),);
  }



/***************************************** Call get Status api from List ******************************************/
public getPlaningHubAsyncStatus(id: any){
  return this.http.get( environment.base_url+'/route-planner/get-status/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/******************************* Generate Route from List ***************************/
public getPlaningHubGenerateRoute(id: any){
  return this.http.get( environment.base_url+'/route-planner/generate-routes/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/***************************************** Call get Status api from List ******************************************/
public getPlaningHubFromViewAsyncStatus(id: any){
  return this.http.get( environment.base_url+'/route-planner/async/get-status/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}

/**************************************** Generate Route from List *********************************/
public getPlaningHubFromViewGenerateRoute(id: any){
  return this.http.get( environment.base_url+'/route-planner/async/generate-routes/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
/**************************************** Generate Route from List *********************************/
public getCancelPlaningHub(id: any){
  return this.http.get( environment.base_url+'/route-planner/un-lock-plan/'+id, { observe: 'response' }).pipe(map( (resp:any) => {
    return resp;
  }
),catchError( error => {
    return throwError( error );
  }),);
}
}