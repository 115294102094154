
import {tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor ( public auth: AuthenticationService, private router: Router ) { }

    intercept ( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        var req = request.clone( {
            setHeaders: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ this.auth.getToken() }`
            }
        } );

        return next.handle( req ).pipe(tap(( event: HttpEvent<any> ) => {
            if ( event instanceof HttpResponse ) {
                // do stuff with response if you want
            }
        }, ( err: any ) => {
            if ( err instanceof HttpErrorResponse ) {
                // if ( err.status === 401 || err.status === 403 ) {
                if ( err.status === 401) {
                    // if(this.auth.getAuthRole()==btoa(this.auth.STRADM))
                    //     this.router.navigateByUrl("/admin/login");
                    // else
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                }
            }
        } ));
    }
}