<div class="bin mt-5 h-95">
    <div class="d-flex button-box-top-right">
        <ng-container *ngIf="toggleEditVisibility">
            <p-button type="button" [label]="'edit' | translate" styleClass="ms-2 py-2 px-4 text-nowrap h-28" id="btn_VehEdit" (onClick)="edit()" *ngIf="update"></p-button>
            <button type="button" class="no-border btn-28 rounded-1 ms-2" *ngIf="remove" id="btn_VehDropdown">
                <i class="pi pi-ellipsis-v pt-04" (click)="menu.toggle($event)"></i>
            </button>
            <p-slideMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true" class="options2"></p-slideMenu>
        </ng-container>
    </div>
    <p-tabView class="px-0" (onChange)="handleChange($event, tabView)" [(activeIndex)]="activeIndex">
        <p-tabPanel [header]="'details' | translate" id="title_VehDet" styleClass="pt-0 mt-0">
            <app-vehicle-details *ngIf="toggleSection.details" [vehicleDetail]="vehicleData"></app-vehicle-details>
        </p-tabPanel>

        <p-tabPanel [header]="'trips' | translate" id="title_VehTrips">
            <app-vehicle-trips *ngIf="toggleSection.trips" [vehicleDetail]="vehicleData"></app-vehicle-trips>
        </p-tabPanel>

        <p-tabPanel [header]="'fuel' | translate" id="title_VehFuel">
            <app-vehicle-fuel *ngIf="toggleSection.fuel" [vehicleDetail]="vehicleData"></app-vehicle-fuel>
        </p-tabPanel>

        <p-tabPanel [header]="'activity' | translate" id="title_VehActivity">
            <app-vehicle-activity *ngIf="toggleSection.activity"></app-vehicle-activity>
        </p-tabPanel>

        <p-tabPanel [header]="'history' | translate" id="title_VehHistory">
            <app-vehicle-history *ngIf="toggleSection.history"></app-vehicle-history>
        </p-tabPanel>
    </p-tabView>
</div>
