import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SalesOrdersService } from 'src/app/_services/sales-orders.service';

@Component({
  selector: 'app-custella-sales-orders',
  templateUrl: './custella-sales-orders.component.html',
  styleUrls: ['./custella-sales-orders.component.scss']
})
export class CustellaSalesOrdersComponent implements OnInit {


  @Output() salesOrderSelector = new EventEmitter();
  @Output() closeSalesOrderView = new EventEmitter();
  @Input() customerId:any = null
   searchValue: any
 
   products: any;
   currentRecordNumber: any;
   noCustomers: boolean = false;
   noCustomersIcon: boolean = false;
   filter: any = {productStatuses : '',productTypes : '',fromDate: '', toDate : ''}
   dateFormat: any;
   soitemsHandlerSubscription: Subscription
   filterData: boolean = true
  constructor(
    private router : Router,
    private salesOrdersService: SalesOrdersService
  ) { 

    this.soitemsHandlerSubscription = salesOrdersService.soitemsHandler.subscribe((response: any) => {
      console.log(response)
      this.selectSO( response);
  });
  }

  ngOnInit() {
    this.dateFormat = localStorage.getItem('date_format')
   
  }
 

  selectSO(val:any){
    this.salesOrderSelector.emit(val);
  }

  closeProduct(){
    this.closeSalesOrderView.emit(false)
  }
  ngOnDestroy() {
    
    if (this.soitemsHandlerSubscription) {
      this.soitemsHandlerSubscription.unsubscribe();
  }
  }
}
