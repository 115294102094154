import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router ,ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { TasksService } from 'src/app/_services/tasks.service';
declare var google: any;
@Component({
  selector: 'app-view-journey',
  templateUrl: './view-journey.component.html'
})
export class ViewJourneyComponent implements OnInit {

  @Input() data: any
  @Input() journeyId: any
  title: any;
  taskJourney: any;
  tripList : any[] = [];
  startLocation : any = {};
  endLocation : any = {};
  markers : any[] = [];
  infoWindowOpened = null;
  dateTimeFormat : any;
  timezone: any;
  polygonPaths : any[] = [];
  zoom: number = 10;

  map: any;


  options: any;
  overlays: any[] = [];

  showMap = false;

  public latitude: number = 3.100176;
  public longitude: number = 101.591085;
  tasks: any[] = [];

  private id: any;
  latlngBounds: any;
  taskId: any
  userId: any
  refresher!: Subscription;
  
  constructor(
    private taskService : TasksService,
    private auth : AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private deleteRecordService: DeleteRecordService,
    private breadcrumbService: BreadcrumbService) { 
      this.refresher = this.taskService.refreshList$.subscribe((item:any) => {
      console.log(item)
      if(item == 'Deleted Journey'){
        this.router.navigate(['/dashboard/tasks/journeys'], {queryParams: {'taskId' : this.taskId,'userId':this.userId}});
      }
    });
    }

  ngOnInit() {
    this.spinner.show()
    this.taskId = this.data.id;
    this.userId = this.data.assignedTo.id
    this.loadData()

    // this.route.queryParams.subscribe(params => {
    //   if (params.id) {
    //     this.journeyId=params.id
    //     this.taskId = params.taskId
    //     this.userId  = params.userId
    //     this.loadData()
       
    //   }
    // });

    this.options = {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: 14,
    };

    this.dateTimeFormat = `${localStorage.getItem('date_format')}`;
    this.timezone = this.auth.getUserTimezone();
   
  }
  /****************************************** Load BreadCrumb *****************************/
  loadBreadCrum() {
   this.breadcrumbService.setSetupItems([
     { label: 'Task', routerLink: ["/dashboard/tasks"], meta: {navigation: {root: true}} },
     { label: this.taskJourney.taskInfo.taskName,command: e => {
       this.router.navigate(['/dashboard/tasks/view'], {queryParams: {'id' : this.taskId}});
     } 
    },
     { label: 'Journeys',command: e => {
      this.router.navigate(['/dashboard/tasks/journeys'], {queryParams: {'taskId' : this.taskId,'userId':this.userId}});
    } },
    { label: 'View Journeys', meta: {navigation: {include: true}} }
   ]);
 }
  loadData(){
    this.taskService.getJourneyTripsById(this.journeyId).subscribe((res:any) => {
      this.spinner.hide()
      this.taskJourney = res.userLocationRouteTable;
      this.loadBreadCrum()
      this.tripList = res.tripList;
      this.tripList.forEach((element:any) => {
        element.strokeColor = 'red';
      });
      //console.log(res)

      this.startLocation = {lat : res.tripList[0].latitude, lng : res.tripList[0].longitude, markerUrl : '../../../../assets/images/mapIcons/current_location_marker.png', address : this.taskJourney.startLocationAddress};
      this.endLocation = {lat : res.userLocationRouteTable.endLocationUserDetails.latitude, lng : res.userLocationRouteTable.endLocationUserDetails.longitude, markerUrl : '../../../../assets/images/map-icons/1.png', address : this.taskJourney.endLocationAddress};
      this.markers.push(this.startLocation);
      this.markers.push(this.endLocation);
      

      if(this.taskJourney.estimateDistance){
       this.displayRoute(this.markers)
      }else{
        this.processMap(this.markers, this.tripList).then(() => {
          // this.spinner.hide();
        });
      }
    });
  }
  
  /******************************************** On Map Ready ******************************************/
  public setGMap($event: any) {
    //console.log("MAP", $event);

    this.map = $event.map;
  }

  /******************************************** Process Map ******************************************/
  private processMap(markers: any[], polyLines: any[]) {
    return new Promise<void>(async (resolve) => {
      const finalMarkers = await this.processMarkers(markers);
      const finalPolyLine = this.processPolyline(polyLines);

      this.latlngBounds = new google.maps.LatLngBounds();
      finalMarkers.forEach((gMarker: any) => {
        this.latlngBounds.extend(gMarker.getPosition());
      });

      this.map.fitBounds(this.latlngBounds);

      this.overlays = [...finalMarkers];

      finalPolyLine.setMap(this.map);

      resolve();
    });
  }
  
  /******************************************** Process Markers ******************************************/
  private processMarkers(markers: any): Promise<any[]> {
    return new Promise((resolve) => {
      const finalMarker: any[] = [];

      markers.forEach((marker: any) => {
        const gMarker = new google.maps.Marker({
          position: { lat: marker.lat, lng: marker.lng },
          title: marker.address,
          icon: marker.icon,
        });

        finalMarker.push(gMarker);
      });

      resolve(finalMarker);
    });
  }

/******************************************** Process Polyline ******************************************/
private processPolyline(polyLines: any[]) {
  const fixedPoly: any[] = [];

  polyLines.map((pl: any) => {
    fixedPoly.push({ lat: pl.lat, lng: pl.lon });
  });

  const poly = new google.maps.Polyline({
    paths: fixedPoly,
    strokeOpacity: 0,
    strokeWeight: 2,
    strokeColor: "#6366F1",
    fillOpacity: 0,
    geodesic: true
  });

  return poly;
}
/******************************************** generate Polyline using Lat and lon ******************************************/
  displayRoute(markers : any) {
    //console.log(this.taskJourney.startLocationUserDetails.latitude,"===",this.taskJourney.startLocationUserDetails.longitude)
   
     var start : any= new google.maps.LatLng(this.taskJourney.startLocationUserDetails.latitude, this.taskJourney.startLocationUserDetails.longitude);
     var end : any= new google.maps.LatLng(this.taskJourney.endLocationUserDetails.latitude, this.taskJourney.endLocationUserDetails.longitude);

    var directionsDisplay = new google.maps.DirectionsRenderer();// also, constructor can get "DirectionsRendererOptions" object
    directionsDisplay.setMap(this.map); // map should be already initialized.

    var request : any = {
        origin : start,
        destination : end,
        travelMode : google.maps.TravelMode.DRIVING
    };
    var directionsService = new google.maps.DirectionsService(); 
    directionsService.route(request,(response:any, status: any)=> {
      //console.log(response)

      // //console.log(response.routes[0].overview_path)
        if (status == google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
            const fixedPoly: any[] = [];

            response.routes[0].overview_path.map((pl: any) => {
              //console.log(pl)
              fixedPoly.push({ lat: pl.lat(), lng: pl.lng() });
            });
          
            const poly = new google.maps.Polyline({
              paths: fixedPoly,
              strokeOpacity: 0,
              strokeWeight: 2,
              strokeColor: "#6366F1",
              fillOpacity: 0,
              geodesic: true,
            });
           
            let finalMarker: any[] = [];
            markers.forEach((marker: any) => {
              //console.log(marker)
              const gMarker = new google.maps.Marker({
                position: { lat: marker.lat, lng: marker.lng },
                title: marker.address,
                icon: marker.icon,
              });
      
              finalMarker.push(gMarker);
            });
            
            this.latlngBounds = new google.maps.LatLngBounds();

            finalMarker.forEach((gMarker) => {
              this.latlngBounds.extend(gMarker.getPosition());
            });
        
            setTimeout(()=> { // map will need some time to load
              this.map.fitBounds(this.latlngBounds); // Map object used directly
          }, 1000);

             this.overlays = [...finalMarker];
             poly.setMap(this.map);
        }
    });
}


edit(e:any) {
  if (e === 'edit') {
    this.router.navigate(['dashboard/tasks/journeys/update'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId,'id': this.taskJourney.id,'name':this.taskJourney.taskInfo.taskName}})
   
   }
  else if (e === 'delete') {
    this.taskJourney.deleteType='Task Journey'
    this.taskJourney.message="Are you sure you want to delete ?" 
    //console.log(this.taskJourney)
    this.deleteRecordService.getItems(this.taskJourney);    

  }
 }
}


