import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Tooltip } from "primeng/tooltip";

@Component({
  selector: "app-link-tracking",
  templateUrl: "./link-tracking.component.html",
  styleUrls: ["./link-tracking.component.scss"],
})
export class LinkTrackingComponent implements OnInit {
  data: any;
  tooltipText!: string;
  url: any;

  @ViewChild(Tooltip) tooltip!: Tooltip;


  constructor(public modalRef: BsModalRef) {}

  ngOnInit() {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.tooltipText = "Copy Link";
    this.url = this.data.link;
  }

  /******************************************** Open URL ******************************************/
  public openURL() {
    window.open(this.url, "_blank");
  }

  /******************************************** Copy URL Link ******************************************/
  public copyURL() {
    this.tooltipText = "Link Copied";
    this.tooltip.activate();
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    console.log("changd to " + this.tooltipText);

    setTimeout(() => {
      this.tooltip.deactivate();
    }, 3000);


    setTimeout(() => {
      this.tooltipText = "Copy Link";
    }, 1500);
  }
}
