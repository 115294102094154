<div class="custella-form-container-title-two text-14 font-medium" id="title_SystemInformation" translate>{{'system_information'}}</div>
<div class="custella-form-container-content-last-row">
    <div class="custella-content-row">
        <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_CreatedBy">{{'created_by' | translate}}</span>
            <div class="custella-vp-picture d-flex align-items-center">
                <p-avatar *ngIf="createdByUser?.imageUrl && createdByUser?.imageUrl !== ''" id="img_CreatedByAvatar" [image]="createdByUser?.imageUrl" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                <p-avatar *ngIf="!createdByUser?.imageUrl || createdByUser?.imageUrl === ''" id="img_CreatedByAvatar" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
           
                
                <span class="custella-content-row-input-title-three ms-2" id="value_CreatedByFullName">{{createdByUser?.fullName}},&nbsp;</span>
                <span class="custella-content-row-input-title-three" id="value_CreatedByDateTime">{{createdDate | momentDate : ' hh:mm a' :  timezone}},&nbsp;{{createdDate | momentDate : dateFormat :  timezone}}</span>
            </div>
        </div>
        <div class="custella-content-row-input col-6">
            <span class="custella-content-row-input-title-two" id="label_ModifiedBy">{{'last_modified_by' | translate}}</span>
            <div class="custella-vp-picture d-flex align-items-center">
                <p-avatar *ngIf="modifiedByUser?.imageUrl && modifiedByUser?.imageUrl !== ''" id="img_ModifiedByAvatar" [image]="modifiedByUser?.imageUrl" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                <p-avatar *ngIf="!modifiedByUser?.imageUrl || modifiedByUser?.imageUrl === ''" id="img_ModifiedByAvatar" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
           
                <span class="custella-content-row-input-title-three ms-2" id="value_ModifiedByFullName">{{modifiedByUser?.fullName}},&nbsp;</span>
                <span class="custella-content-row-input-title-three" id="value_ModifiedByDateTime">{{lastModifiedDate | momentDate : ' hh:mm a' :  timezone}},&nbsp;{{lastModifiedDate | momentDate : dateFormat : timezone}}</span>
            </div>
        </div>
    </div>
</div>