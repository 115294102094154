<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img src="assets\svg\dialog\Confirmation-w.svg">
            <span class="text-16 font-medium ms-3">{{title}}</span>
        </div>
        <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
    </div>
   
            <!-- CONFRIM, DISPATCH, COMPLETE or INCOMPLETE BUTTTON-->
            <form *ngIf="(data.planStatus==='DRAFT') || (data.planStatus==='COMPLETED') || (data.planStatus==='INCOMPLETE') || (data.planStatus==='CANCELLED')">
                
                <div class="modal-body pt-0">
                <div class="container text-center px-0" style="padding: 20px;">
                    <p class="mb-0 text-4 pb-2 px-3">{{data.planNumber}} will be deleted.</p>
                    <p class="mb-0 text-4 pb-2 px-3">Do you wish to continue?</p>
                </div>
                </div>
                <div class="modal-footer p-0">
                    <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
                    <p-button type="button" (click)="onSubmit()"  label="{{'yes' | translate}}"></p-button>
                </div>

            </form>
            
            <!-- CANCEL PLAN BUTTTON -->
            <form *ngIf="(data.planStatus==='CONFIRMED') || (data.planStatus==='DISPATCHED') || (data.planStatus==='IN_PROGRESS')">
               
                <div class="modal-body pt-0">
                    <!-- CANCELLING WHEN CONFIRMED  -->
                    <div *ngIf="data.planStatus==='CONFIRMED'" class="container text-center px-0 pb-0" style="padding: 20px;">
                    <p class="mb-0 text-4 pb-2 px-3">By deleting {{data.planNumber}}, the status of ALL its orders will be changed from</p>
                    <div class="d-flex align-items-center justify-content-center px-3">
                        <span  class="me-2 p-tag p-component p-tag-confirmed" ><span class="p-tag-value font-white">Confirmed</span></span>
                        <i class="pi pi-arrow-right me-3"></i>
                        <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: auto;">
                        <p-dropdown [(ngModel)]="formData.confirmed" [ngModelOptions]="{standalone: true}"  name="status"
                        [options]="dropDownData" optionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

                        </div>
                    </div>
                    <p class="mb-0 text-1 pt-2 pb-4 px-3 f-grey">(Keep as default for no status change)</p>
                    <p class="mb-0 text-4 pb-2 px-3">Are you sure you want to delete {{data.planNumber}}?</p>
                    </div>
                    <!-- CANCELLING WHEN DISPATCHED -->
                    <div *ngIf="(data.planStatus==='DISPATCHED') || (data.planStatus==='IN_PROGRESS')" class="container text-center px-0 pb-0" style="padding: 20px;">
                    <p class="mb-0 text-4 pb-2 px-3">By deleting {{data.planNumber}}, the status of its orders will be changed as follows:</p>
                    <div *ngIf="data.deliveryOrderStatusCountVM?.dispatchedCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
                        <span class="me-2 p-tag p-component p-tag-dispatched" ><span class="p-tag-value font-white">Dispatched</span></span>
                        <i class="pi pi-arrow-right me-3"></i>
                        <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: auto;">
                        <!-- <select [(ngModel)]="formData.dispatched" [ngModelOptions]="{standalone: true}" class="form-control" name="" id="">
                        
                            <option *ngFor="let statusData of dropDownData" [ngValue]="statusData.name">{{statusData.label}}</option>
                        </select> -->
                        <p-dropdown [(ngModel)]="formData.dispatched" [ngModelOptions]="{standalone: true}"  name="status"
                        [options]="dropDownData" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

                        </div>
                    </div>
                    <div *ngIf="data.deliveryOrderStatusCountVM?.inprogressCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
                        <span class="me-2 p-tag p-component p-tag-in-progress" ><span class="p-tag-value font-white">In Progress</span></span>
                        <i class="pi pi-arrow-right me-3"></i>
                        <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: auto;">
                        <p-dropdown [(ngModel)]="formData.inProgress" [ngModelOptions]="{standalone: true}"  name="status"
                        [options]="dropDownData4" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>


                        </div>
                    </div>
                    <div *ngIf="data.deliveryOrderStatusCountVM?.onHoldCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
                        <div class="p-2" style="border-radius: 6px; background-color: #D96E12; width: 80px;">
                        <div class="d-flex align-items-center">
                            <div class="m-auto">
                            <p class="mb-0 text-12 font-medium font-white">On Hold</p>
                            </div>
                        </div>
                        </div>
                        <i class="pi pi-arrow-right me-3"></i>
                        <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: auto;">
                        <!-- <select [(ngModel)]="formData.onHold" [ngModelOptions]="{standalone: true}" class="form-control" name="" id="">
                        
                            <option *ngFor="let statusData of dropDownData" [ngValue]="statusData.name">{{statusData.label}}</option>
                        </select> -->
                        <p-dropdown [(ngModel)]="formData.onHold" [ngModelOptions]="{standalone: true}"  name="status"
                        [options]="dropDownData3" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

                        </div>
                    </div>
                    <div *ngIf="data.deliveryOrderStatusCountVM?.inCompletedCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
                        <span class="me-2 p-tag p-component p-tag-incomplete" ><span class="p-tag-value font-white">Incomplete</span></span>
                        <i class="pi pi-arrow-right me-3"></i>
                        <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: auto;">
                        <!-- <select [(ngModel)]="formData.inComplete" [ngModelOptions]="{standalone: true}" class="form-control" name="" id="">
                        <option *ngFor="let statusData of dropDownData" [ngValue]="statusData.name">{{statusData.label}}</option>
                        </select> -->
                        <p-dropdown [(ngModel)]="formData.inComplete" [ngModelOptions]="{standalone: true}"  name="status"
                        [options]="dropDownData2" optionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>


                        </div>
                    </div>
                    <p class="mb-0 pt-2 pb-4 px-3 f-grey" style="font-size: 12px;">(Keep as default for no status change)</p>
                    <p class="mb-0 text-4 pb-2 px-3">Are you sure you want to delete {{data.planNumber}}?</p>
                    </div>
                </div>
                <div class="modal-footer p-0">
                    <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
                    <p-button type="button" (click)="onSubmit()"  label="{{'yes' | translate}}"></p-button>
                   
                </div>
            </form>


    <!-- </div> -->