import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable()
export class ErrorUtil {
    protected subscriptions: any[] = [];
    subjectOpenMessageModal : Subject<any> = new Subject();
    subjectOpenMessageModal$ = this.subjectOpenMessageModal.asObservable();

    subjectOpenToastModal : Subject<any> = new Subject();
    subjectOpenToastModal$ = this.subjectOpenToastModal.asObservable();
    constructor(private router: Router) { }

    openMessageModal(showWelcomeModal :any, type :any, localeHeader:any, localeMsg:any, messageElement:any,life:any) {
        let modalObj = {
            "showWelcomeModal": showWelcomeModal,
            "severity": type,
            "summary": localeHeader,
            "detail": localeMsg,
            "messageElement":messageElement,
            "life":life,
            "escape":false
        }
        console.log(modalObj)
        if(messageElement == 'message'){
            this.subjectOpenMessageModal.next(modalObj);
        }else{
            this.subjectOpenToastModal.next(modalObj);
        }
       
    }
    setErrorMessage(errorCode:any, body:any = null, message:any = null, errorType:any = 'error',life:any = 300000 ) {
        //  body = JSON.parse(body);
        //console.log(errorCode, body)
         console.log(errorCode)
        if (errorCode == 0) {
            this.openMessageModal(true, errorType , message, body,"message",life)
        }else if (errorCode == 200 || errorCode == 201) {
                this.openMessageModal(true, errorType , message, body,"toster",life)
        }else if (errorCode == 404 || errorCode == 400) {
            this.openMessageModal(true, errorType , "Error",message,"toster",life)
        } else {
            this.openMessageModal(true, "error", "Error Code "+ errorCode, "Oops.... Something went wrong, please try again.","toster",life)
        }
        //TODO : create a generic and custom error msg per error code
    }

   
    
}