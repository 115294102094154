<div class="custella-modal">
    <!-- Container -->
    <form  [formGroup]="addTeamUserForm" >
        <div class="custella-modal-header align-items-center">
            <div class="custella-modal-header-text" id="title_TeamMember"> {{ title }} </div>
            <i class="pi pi-times" id="btn_TeamMemberClose"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>
        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
            <!-- Container Content -->
            <div class="custella-form-container-content m-0">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6"  *ngIf="mode !== 'edit'" >
                        <span class="custella-content-row-input-title mandatory" style="margin-bottom: 3px;" id="label_TeamMemberUser" translate>{{'users'}}</span>
                        <p-multiSelect style="font-size: 12px !important;" [placeholder]="'select_member' | translate" 
                        [options]="users" 
                        optionLabel="name" 
                        optionValue="id" 
                        name="members" 
                        id="picklist_TeamMemberUserField"
                        [(ngModel)]="userSelect" 
                        formControlName="members"
                       ></p-multiSelect>
                       <div *ngIf="addTeamUserForm.controls['members'].touched && addTeamUserForm.controls['members'].invalid">
                          <span class="text-10 p-error" *ngIf="addTeamUserForm.controls['members'].errors.required" id="label_TeamMemberUserReqField" translate>{{'required_field'}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row-input col-6" [ngClass]="{'col-12' : mode == 'edit'}">
                        <span class="custella-content-row-input-title mandatory" id="label_TeamMemberRole" translate>{{'role'}}</span>
                        <p-dropdown class="w-100" [(ngModel)]="addTeamMember.role" formControlName="role" name="role"
                            [options]="roles" optionLabel="label" [filter]="false" filterBy="label" id="picklist_TeamMemberRoleField"
                            optionValue="value" [showClear]="true" placeholder="{{'select_role' | translate}}"></p-dropdown>
                            <div *ngIf="addTeamUserForm.controls['role'].touched && addTeamUserForm.controls['role'].invalid">
                                <span class="text-10 p-error" *ngIf="addTeamUserForm.controls['role'].errors.required" id="label_TeamMemberRoleReqField" translate>{{'required_field'}}</span>
                            </div>
                    </div>
                </div>
            </div>
            <!-- Container Footer -->
            <div class="custella-form-container-footer mb-0">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_TeamMemberCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                    <p-button [label]="'save'|translate" [disabled]="submitTouched" id="btn_TeamMemberSubmit" (click)="onSubmitForm()" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>