<div class="left-right-space h-100">
    <!-- Title -->
    <div class="flex-between mb-2">
        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex ms-auto">
            <button type="button" id="btn_VehFuelRefresh" *ngIf="!noFuelHistory" class="border rounded-1 px-04 ms-2 btn-32" (click)="refresh()">
                <img alt="logo" src="assets\svg\refresh.svg" id="img_VehFuelRefresh" />
            </button>

            <p-button [label]="'new' | translate" icon="pi pi-plus" id="btn_VehFuelNew" (onClick)="new()" styleClass="ms-2 h-32" *ngIf="create"></p-button>
        </div>
    </div>
    <div *ngIf="noFuelHistory" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" translate>{{'no_records_found'}}</p>
        </div>
    </div>

    <!-- TabMenu -->
    <div [hidden]="noFuelHistory" class="position-relative fw-normal d-flex display-table" style="height: calc(100% - 73px);">
        <div [hidden]="noFuelHistory" class="table-space ag-grid-table-full">
            <ag-grid-angular
                #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                [columnDefs]="columnDefs"
                [gridOptions]="gridOptions"
                [defaultColDef]="defaultColDef"
                [frameworkComponents]="frameworkComponents"
                (gridReady)="onGridReady($event)"
                (cellClicked)="goToViewFuel($event)"
                [getContextMenuItems]="getContextMenuItems"

            ></ag-grid-angular>

            <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end" id="label_VehFuelTableRec">{{totalRecord}} {{'records' | translate}}</p>
            </div>
        </div>
    </div>
</div>
