import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import { ServerRequest } from 'src/app/_models/global.data.model';
import { JobOrdersService } from 'src/app/_services/job-orders.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
import { TableHeaderToolTipComponent } from '../../table-header-tool-tip/table-header-tool-tip.component';
import { UsersService } from 'src/app/_services/admin/users.service';

@Component({
  selector: 'app-custella-users',
  templateUrl: './custella-users.component.html',
  styleUrls: ['./custella-users.component.scss']
})
export class CustellaUsersComponent extends SubscriptionUtil
implements OnInit, OnDestroy
{
@Output() itemSelector = new EventEmitter();
@Output() closeView = new EventEmitter();


gridApi: any;
gridColumnApi: any;
columnDefs: any;
defaultColDef: any;
frameworkComponents: any;
gridParams : any;
startFrom: any;
product: any;
pageSize: any = 25;
parameters: any;
gridOptions: GridOptions;
overlayNoRowsTemplate: string;
productsFamilies: any;
productsType: any;
currentRecordNumber: any;
totalRecord: any;

 searchValue: any=null
  checklistData: any;

constructor(private usersService : UsersService,
  private errorUtil: ErrorUtil,) {
  super();

  this.gridOptions = {
    cacheBlockSize: 25,
    rowModelType: "infinite",
    sortingOrder: ["desc", "asc"],
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    tooltipShowDelay: 100,
  };
  this.columnDefs =  [
    {
      headerName: "Full Name",field: "fullName",  width: 150,  sortable: true, cellClass: function (params: any) { return ["table_default_color"]; },
      headerTooltip: "#",
      headerComponentParams: {
        template:
            '<div class="ag-cell-label-container" role="presentation" id="Tbl_HeadSetUserNo">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>'
    },
      cellRenderer: function (params: any) {
        if (params.data) {
          return '<p id="' + params.data.rowId+'">' + params.data.fullName + '</p>';
        }
      },
    },
    { headerName: "Username",
      field: "login", 
      width: 150, 
      sortable: true, 
      headerTooltip: "#", 
    },
    {headerName: "Email",  field: "email",  width: 150, sortable: true, headerTooltip: "#", },
     { headerName: "Manager", field: "superVisorName", width: 80, sortable: true, headerTooltip: "#",},
     { headerName: "Access", field: "profileName", width: 80, sortable: true, headerTooltip: "#",},
     { headerName: "Hierarchy", field: "roleName", width: 100, sortable: true, headerTooltip: "#",},
     { headerName: 'Status', field: 'status', width: 100,
     headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            console.log(params.data.status)
            if(params.data.status == 'ACTIVE'){
              return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
            }else  if(params.data.status == 'DEACTIVE'){
              return '<span class="p-mr-2  p-tag p-component p-tag-Deactivate" ><span class="p-tag-value text-dark">Deactivated</span></span>';
             }else  if(params.data.status == 'PENDING_ACTIVATION'){
                return '<span class="p-mr-2  p-tag p-component p-tag-Pending-Activation" ><span class="p-tag-value text-dark">Pending Activation</span></span>';
             }else  if(params.data.status == 'INACTIVE'){
                  return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
             }else{
              return '<span class="p-mr-2  p-tag p-component  p-tag-Suspended" ><span class="p-tag-value text-dark">Suspended</span></span>';
            }
        }
       }
    },
   
  ];
  this.defaultColDef = {
    enableRowGroup: true,//ag-Grid-Enterprise
    enablePivot: true,//ag-Grid-Enterprise
    enableValue: true,//ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent
  };
  this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";

  
}

ngOnInit(): void {}

ngOnDestroy(): void {
  super.ngOnDestroy();
}
public viewUser($event: any) {
  const field = $event.colDef.field;

  if (field === "fullName") {
    this.selectItem($event.data);
  }
}
selectItem(val: any) {
  this.itemSelector.emit(val);
}

public closeTable() {
  this.closeView.emit(false);
}
 
push(obs:any) {
  super.push(obs);
}



/************************************************ Search Data ****************************************/
quickSearch() {
  this.onGridReady(this.gridParams);
  
}
/***************************************** Initilize Grid Table ****************************************/
onGridReady(params: any) {
  //console.log(params)
  this.gridParams = params;

  this.gridApi = params.api;
  this.gridApi.sizeColumnsToFit();
  var dataSource = {
    getRows: (params: IServerSideGetRowsParams) => {
      this.getServerSideData(params);
    },
  };

  this.gridApi.sizeColumnsToFit();
  this.gridApi.setDatasource(dataSource);
}

/********************************** Call Customer API to Display data *********************************/
getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  // this.spinner.show()
  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);
  console.log('Making a server request: ', serverRequest);

  this.push(this.usersService.getAllUserForMachinesSpecialist(this.searchValue, serverRequest) .subscribe(
        (data: any) => {
          console.log(data.body);
          this.checklistData = data.body.data;
          this.totalRecord = data.body.total;
          this.setGridData(
            agGridGetRowsParams,
            this.checklistData,
            data.body.total,
            data.body.status
          );
          //this.spinner.hide()
        },
        (err) => {
          //console.log(err,"===>>>")
          this.errorUtil.setErrorMessage(err);
        }
      )
  );
}
/**************************************** Set Data for Grid Table ****************************************/
private setGridData( agGridGetRowsParams: IServerSideGetRowsParams,resultItems: any[],totalCount: number, status: string ) {
  console.log(resultItems, "===");
  
 
    if (this.checklistData.length === 0) {
      this.gridApi.showNoRowsOverlay();
       } else {
       agGridGetRowsParams.successCallback(this.checklistData, totalCount);
    }
  
 
}
/********************************** Request Parameter for api ****************************************/

getRequestParams(agGridRequest: any): ServerRequest {
  if (agGridRequest.sortModel.length <= 0) {
    this.parameters = { colId: "superVisor", sort: "desc" };
    agGridRequest.sortModel.push(this.parameters);
  }

  //console.log(agGridRequest)
  this.currentRecordNumber = agGridRequest.startRow;
  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel,
  };
}


}