import {
  of as observableOf,
  BehaviorSubject,
  Observable,
  throwError,
  Subject,
} from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import * as moment from "moment-timezone";
import { HttpClient } from "@angular/common/http";
import * as _ from "lodash";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { IContextMenuItem, IPosition } from "src/app/shared/dataModels/general";
import * as FileSaver from "file-saver";
import { AbstractControl, ValidationErrors } from "@angular/forms";

declare var google: any;
@Injectable()
export class UtilServiceService {
  private _googleThemeJsonURL = "/assets/json/google-theme-data.json";

  refreshHeight$: EventEmitter<{}>;
  refreshUsers$: EventEmitter<{}>;
  refreshCollapse$: EventEmitter<{}>;
  refreshCollapse1$: EventEmitter<{}>;

  refreshTasks$: EventEmitter<{}>;
  submitTaskConfirmation$: EventEmitter<{}>;
  calendarViewEmitter$: EventEmitter<{}>;
  commonDataEmitter$: EventEmitter<{}>;
  refreshOredrCollapse$: EventEmitter<{}>;

  reOpenTaskViewPage$: EventEmitter<{}>;

  private joDataChangedSubject = new Subject<any>();
  isjoDataChanged = this.joDataChangedSubject.asObservable();


  public getGoogleTheme() {
    return this.http.get(this._googleThemeJsonURL).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError((error) => {
        return observableOf(error);
      })
    );
  }

  public goBack() {
    window.history.back();
  }

  public dataPassBehSub = new BehaviorSubject(null);

  private autoCompleteOptions = {
    fields: [
      "address_component",
      "formatted_address",
      "geometry",
      "name",
      "place_id",
      "type",
    ],
  };
  statusList = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];
  msStatusList = [
    { value: 'MACHINE_SP', label: "Normal User" },
    { value: 'TEAM_LEAD', label: "Team Leader" },
  ];

  private currentContextFunction: any;

  constructor(private http: HttpClient, private auth: AuthenticationService) {
    this.refreshHeight$ = new EventEmitter();
    this.refreshUsers$ = new EventEmitter();
    this.refreshCollapse$ = new EventEmitter();
    this.refreshCollapse1$ = new EventEmitter();

    this.refreshTasks$ = new EventEmitter();
    this.calendarViewEmitter$ = new EventEmitter();
    this.commonDataEmitter$ = new EventEmitter();
    // UtilServiceService.tableDataEmitter$ = new EventEmitter();
    this.submitTaskConfirmation$ = new EventEmitter();
    this.refreshOredrCollapse$ = new EventEmitter();
    this.reOpenTaskViewPage$ = new EventEmitter();
  }

  public setNavCollapseStatus(val: any) {
    this.refreshCollapse$.emit(val);
    localStorage.setItem("collapse-nav", val.toString());
    
  }
  public setNavCollapseStatus1(val: any) {
    this.refreshCollapse1$.emit(val);
    
  }
  public getNavCollapseStatus() {
    return localStorage.getItem("collapse-nav") === "true";
  }

  public durationFormatter(startDate: Date, endDate: Date) {
    var a = moment(startDate).utc();
    var b = moment(endDate).utc();
    var diff = b.diff(a, "minutes", true);
    var hours = Math.floor(diff / 60);
    var minutes = diff % 60;
    ////console.log("Difference ", hours, minutes);
    if (hours == 0) {
      var difference: any = minutes + " minutes";
    } else if (hours == 1 && minutes == 0) {
      var difference: any = hours + " hour";
    } else if (hours > 0 && minutes == 0) {
      var difference: any = hours + " hours";
    } else if (hours > 0 && minutes > 1) {
      var difference: any = hours + " hour " + minutes + " minutes";
    } else if (hours > 1 && minutes > 1) {
      var difference: any = hours + " hours " + minutes + " minutes";
    }
    return difference;
  }

  public pastFinder(date: any) {
    var a = moment(date);
    var b = moment();
    var past = b.isAfter(a);
    return past;
  }

  public pastFinder2(startDate: any, endDate: any) {
    var a = moment(startDate);
    var b = moment(endDate);
    var past = b.isAfter(a);
    return past;
  }

  // public extractData(res: Response) {
  //   const body = res.json();
  //   return body.data || {};
  // }

  public timeFormatter(time: any, date: any) {
    var timeString = time.split(":");
    ////console.log('start ', timeString);
    var timeMinutesTemp = timeString[1].split(" ");
    if (timeMinutesTemp[1] === "AM" && timeString[0] === "12") {
      var timeHours = 0;
    } else if (timeMinutesTemp[1] === "AM" && timeString[0] !== "12") {
      var timeHours = parseInt(timeString[0]);
    } else if (timeMinutesTemp[1] === "PM" && parseInt(timeString[0]) !== 12) {
      var timeHours = parseInt(timeString[0]) + 12;
    } else {
      var timeHours = parseInt(timeString[0]);
    }
    var timeMinutes = timeMinutesTemp[0];
    var formattedTime = moment
      .tz(date, this.auth.getUserTimezone())
      .set({ h: timeHours, m: timeMinutes });
    ////console.log("Formatted Time ", formattedTime);
    return formattedTime;
  }

  public dateStripper(str: any) {
    if (str != null && str.length) {
      str = str.substring(0, str.length - 1);
    }
    return str;
  }

  public getCompanySizeList() {
    var CompanySize = [
      "0 - 20 Employees",
      "20 - 50 Employees",
      "50 - 100 Employees",
      "100 - 500 Employees",
      "500+ Employees",
    ];
    return CompanySize;
  }

  public getStateList() {
    var CompanySize = ["NSW", "QLD", "SA", "TAS", "VIC", "WA", "NT"];
    return CompanySize;
  }

  public getSystemUsed() {
    var CompanySize = ["HTR", "Hostel", "LINCOR", "Getwell"];
    return CompanySize;
  }

  public getRandomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public getCountryList() {
    var countries = [
      { name: "Afghanistan" },
      { name: "Albania" },
      { name: "Algeria" },
      { name: "Andorra" },
      { name: "Angola" },
      { name: "Anguilla" },
      { name: "Antigua & Barbuda" },
      { name: "Argentina" },
      { name: "Armenia" },
      { name: "Australia" },
      { name: "Austria" },
      { name: "Azerbaijan" },
      { name: "Bahamas" },
      { name: "Bahrain" },
      { name: "Bangladesh" },
      { name: "Barbados" },
      { name: "Belarus" },
      { name: "Belgium" },
      { name: "Belize" },
      { name: "Benin" },
      { name: "Bermuda" },
      { name: "Bhutan" },
      { name: "Bolivia" },
      { name: "Bosnia & Herzegovina" },
      { name: "Botswana" },
      { name: "Brazil" },
      { name: "Brunei Darussalam" },
      { name: "Bulgaria" },
      { name: "Burkina Faso" },
      { name: "Myanmar/Burma" },
      { name: "Burundi" },
      { name: "Cambodia" },
      { name: "Cameroon" },
      { name: "Canada" },
      { name: "Cape Verde" },
      { name: "Cayman Islands" },
      { name: "Central African Republic" },
      { name: "Chad" },
      { name: "Chile" },
      { name: "China" },
      { name: "Colombia" },
      { name: "Comoros" },
      { name: "Congo" },
      { name: "Costa Rica" },
      { name: "Croatia" },
      { name: "Cuba" },
      { name: "Cyprus" },
      { name: "Czech Republic" },
      { name: "Democratic Republic of the Congo" },
      { name: "Denmark" },
      { name: "Djibouti" },
      { name: "Dominica" },
      { name: "Dominican Republic" },
      { name: "Ecuador" },
      { name: "Egypt" },
      { name: "El Salvador" },
      { name: "Equatorial Guinea" },
      { name: "Eritrea" },
      { name: "Estonia" },
      { name: "Ethiopia" },
      { name: "Fiji" },
      { name: "Finland" },
      { name: "France" },
      { name: "French Guiana" },
      { name: "Gabon" },
      { name: "Gambia" },
      { name: "Georgia" },
      { name: "Germany" },
      { name: "Ghana" },
      { name: "Great Britain" },
      { name: "Greece" },
      { name: "Grenada" },
      { name: "Guadeloupe" },
      { name: "Guatemala" },
      { name: "Guinea" },
      { name: "Guinea-Bissau" },
      { name: "Guyana" },
      { name: "Haiti" },
      { name: "Honduras" },
      { name: "Hungary" },
      { name: "Iceland" },
      { name: "India" },
      { name: "Indonesia" },
      { name: "Iran" },
      { name: "Iraq" },
      { name: "Israel and the Occupied Territories" },
      { name: "Italy" },
      { name: "Ivory Coast (Cote d'Ivoire)" },
      { name: "Jamaica" },
      { name: "Japan" },
      { name: "Jordan" },
      { name: "Kazakhstan" },
      { name: "Kenya" },
      { name: "Kosovo" },
      { name: "Kuwait" },
      { name: "Kyrgyz Republic (Kyrgyzstan)" },
      { name: "Laos" },
      { name: "Latvia" },
      { name: "Lebanon" },
      { name: "Lesotho" },
      { name: "Liberia" },
      { name: "Libya" },
      { name: "Liechtenstein" },
      { name: "Lithuania" },
      { name: "Luxembourg" },
      { name: "Republic of Macedonia" },
      { name: "Madagascar" },
      { name: "Malawi" },
      { name: "Malaysia" },
      { name: "Maldives" },
      { name: "Mali" },
      { name: "Malta" },
      { name: "Martinique" },
      { name: "Mauritania" },
      { name: "Mauritius" },
      { name: "Mayotte" },
      { name: "Mexico" },
      { name: "Moldova, Republic of" },
      { name: "Monaco" },
      { name: "Mongolia" },
      { name: "Montenegro" },
      { name: "Montserrat" },
      { name: "Morocco" },
      { name: "Mozambique" },
      { name: "Namibia" },
      { name: "Nepal" },
      { name: "Netherlands" },
      { name: "New Zealand" },
      { name: "Nicaragua" },
      { name: "Niger" },
      { name: "Nigeria" },
      { name: "Korea, Democratic Republic of (North Korea)" },
      { name: "Norway" },
      { name: "Oman" },
      { name: "Pacific Islands" },
      { name: "Pakistan" },
      { name: "Panama" },
      { name: "Papua New Guinea" },
      { name: "Paraguay" },
      { name: "Peru" },
      { name: "Philippines" },
      { name: "Poland" },
      { name: "Portugal" },
      { name: "Puerto Rico" },
      { name: "Qatar" },
      { name: "Reunion" },
      { name: "Romania" },
      { name: "Russian Federation" },
      { name: "Rwanda" },
      { name: "Saint Kitts and Nevis" },
      { name: "Saint Lucia" },
      { name: "Saint Vincent's & Grenadines" },
      { name: "Samoa" },
      { name: "Sao Tome and Principe" },
      { name: "Saudi Arabia" },
      { name: "Senegal" },
      { name: "Serbia" },
      { name: "Seychelles" },
      { name: "Sierra Leone" },
      { name: "Singapore" },
      { name: "Slovak Republic (Slovakia)" },
      { name: "Slovenia" },
      { name: "Solomon Islands" },
      { name: "Somalia" },
      { name: "South Africa" },
      { name: "Korea, Republic of (South Korea)" },
      { name: "South Sudan" },
      { name: "Spain" },
      { name: "Sri Lanka" },
      { name: "Sudan" },
      { name: "Suriname" },
      { name: "Swaziland" },
      { name: "Sweden" },
      { name: "Switzerland" },
      { name: "Syria" },
      { name: "Tajikistan" },
      { name: "Tanzania" },
      { name: "Thailand" },
      { name: "Timor Leste" },
      { name: "Togo" },
      { name: "Trinidad & Tobago" },
      { name: "Tunisia" },
      { name: "Turkey" },
      { name: "Turkmenistan" },
      { name: "Turks & Caicos Islands" },
      { name: "Uganda" },
      { name: "Ukraine" },
      { name: "United Arab Emirates" },
      { name: "United States of America (USA)" },
      { name: "Uruguay" },
      { name: "Uzbekistan" },
      { name: "Venezuela" },
      { name: "Vietnam" },
      { name: "Virgin Islands (UK)" },
      { name: "Virgin Islands (US)" },
      { name: "Yemen" },
      { name: "Zambia" },
      { name: "Zimbabwe" },
    ];
    return countries;
  }

  public getIPInfo() {
    return this.http.get("https://ipinfo.io/geo").pipe(
      map((resp: any) => {
        if (resp.status == 200) {
          var body = JSON.parse(resp._body);
          return body;
        } else {
          return observableOf(false);
        }
      }),
      catchError((error) => {
        ////console.log(error);
        return observableOf(error);
      })
    );
  }

  public refreshRightSideBarHeight() {
    this.refreshHeight$.emit("calculate height");
  }

  public setCompanyName(name: any) {
    localStorage.setItem("org_home", name);
  }

  /** Open Item Link to New Tab
   * Pass in whatever url you have and it will open it in new tab
   * Example URL `/#/dashboard/planningHub/viewPlan/789?id=899`
   * So what you give will open to new tab, so before passing it in, construct it properly before passing
   *
   * @param {string} link
   */
  public openItemToNewTab(link: string): void {
    let url = link;

    window.open(url, "_blank", "noreferrer");
  }

  public timeConversion(ms: number): string {
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms = ms % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);
    // console.log("Day-" + days + "d " + hours + "h " + minutes + "min");
    var day = days < 9 ? "0" + days + "d " : days + "d ";
    var hour = hours < 9 ? "0" + hours + "h " : hours + "h ";
    var minute = minutes < 9 ? "0" + minutes + "min" : minutes + "min";

    if (days < 1) {
      if (hours <= 0) {
        return "Min-" + minute;
      } else {
        return "Hr-" + hour + minute;
      }
    } else {
      return "Day-" + day + hour + minute;
    }
  }

  public returnCalendarFormat() {
    return {
      lastDay: "[Yesterday]",
      sameDay: "[]",
      nextDay: "[Tomorrow]",
      lastWeek: "DD/MM/YYYY",
      nextWeek: "dddd",
      sameElse: "L",
    };
  }

  // reference https://www.sitepoint.com/building-custom-right-click-context-menu-javascript/
  // Build custom context menu
  // somewhat hacky
  // supports one level but can
  public rightClick(
    selector: string = "body",
    $event: any,
    contextMenuItems: IContextMenuItem[]
  ) {
    // console.log($event);
    // prevent right click default behaviour
    $event.preventDefault();

    // get event position
    const position = this.getPosition$Event($event);
    // build the menu item
    const containerElem = this.buildContextMenu(position, contextMenuItems);
    // the element where we hook the context menu
    const hookElem = document.querySelector(selector) as any;

    // check if cleanup function has referenced
    if (this.currentContextFunction) {
      // if yes remove it from the hook element
      hookElem.removeEventListener("click", this.currentContextFunction);
      hookElem.removeEventListener("wheel", this.currentContextFunction);

      // remove old context menu
      const contextMenuElem = document.querySelector(".context-menu");

      if (contextMenuElem) {
        hookElem.removeChild(contextMenuElem);
      }
    }

    this.currentContextFunction = this.hasBeenClickedOutside.bind(hookElem, {
      thisContext: this,
      hookElement: hookElem,
      containerElement: containerElem,
    });

    // // get the cleanup function and set it as a reference for hook to use

    hookElem.addEventListener("click", this.currentContextFunction);
    hookElem.addEventListener("wheel", this.currentContextFunction);

    // append the context menu to the hook element
    hookElem.appendChild(containerElem);
  }

  // idk why bind is reversing the order of the parameter
  private hasBeenClickedOutside(param1: any, param2: any) {
    // console.log('CLICKED INSIDE PARAM 1', param1);
    // console.log('CLICKED INSIDE PARAM 2', param2);
    // handle with caution
    let event = null;
    let hookElem = null;
    let containerElem = null;
    let thisContext = null;

    if (param1.target) {
      event = param1;
    }

    if (param2.target) {
      event = param2;
    }

    if (
      param1.hasOwnProperty("hookElement") ||
      param1.hasOwnProperty("containerElement") ||
      param1.hasOwnProperty("thisContext")
    ) {
      hookElem = param1.hookElement;
      containerElem = param1.containerElement;
      thisContext = param1.thisContext;
    }

    if (
      param2.hasOwnProperty("hookElement") ||
      param2.hasOwnProperty("containerElement") ||
      param2.hasOwnProperty("thisContext")
    ) {
      hookElem = param2.hookElement;
      containerElem = param2.containerElement;
      thisContext = param2.thisContext;
    }

    if (!thisContext.contextMenuHasClickInside(event)) {
      thisContext.removeContextMenu(hookElem, containerElem);
    }
  }

  // DO NOT REMOVE USED WITHIN THE CONTEXT MENU
  private removeContextMenu(hookElem: any, containerElem: any) {
    // if not inside remove the context menu from the hook element
    if (hookElem.contains(containerElem)) {
      hookElem.removeChild(containerElem);
    }

    // remove the event from hook element
    hookElem.removeEventListener("click", this.currentContextFunction, false);
    hookElem.removeEventListener("wheel", this.currentContextFunction, false);
  }

  // build the context menu
  // const contextMenuItems = [
  //   {
  //     name: 'Open In New Tab Test',
  //     command: ($event) => {
  //     },
  //     icon: 'fa fa-arrows-h'
  //   },
  //   {
  //     name: 'Console Log Hello',
  //     command: this.openNewTab,
  //     icon: 'fa fa-arrows'
  //   }
  // ];
  private buildContextMenu(
    position: IPosition,
    contextMenuItems: IContextMenuItem[]
  ) {
    const container = document.createElement("ul");
    container.setAttribute("class", "context-menu");

    contextMenuItems.forEach((menuItem) => {
      // each item create and set the name
      const li = document.createElement("li");
      li.setAttribute("class", "context-menu-item");

      // name
      const menuItemElem = document.createElement("span");
      menuItemElem.textContent = menuItem.name;

      // set icon
      const icon = document.createElement("i");
      icon.setAttribute("class", menuItem.icon);

      li.appendChild(icon);
      li.appendChild(menuItemElem);

      // set the click event
      li.addEventListener("click", menuItem.command);

      container.appendChild(li);
    });

    // set the position of context menu
    container.style.left = position.x + "px";
    container.style.top = position.y + "px";

    return container;
  }

  // return $event object position
  private getPosition$Event($eventObject: any) {
    let posx = 0;
    let posy = 0;

    const e = $eventObject;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    return {
      x: posx,
      y: posy,
    };
  }

  // DO NOT REMOVE USED BY CONTEXT MENU
  // checks if event was made inside or outside
  private contextMenuHasClickInside($event: any) {
    let el = $event;
    const path = el.path ?? el.composedPath();
    let isInside = false;

    // console.log('CLICK INSIDE', el, path);

    for (let p = 0; p < path.length; p++) {
      if (path[p].classList && path[p].classList.contains("context-menu")) {
        isInside = true;
        break;
      }
    }

    return isInside;
  }

  public downloadAttachment(attachment: any) {
    let fileName = "";
    let fileType = "";
    let url = "";
    try {
      fileName = attachment.fileName;
      if (fileName)
        fileType = (attachment.fileName as string)
          ? (attachment.fileName as string).split(".")[
              (attachment.fileName as string).split(".").length - 1
            ]
          : "";
      url = attachment.attachmentUrl;

      if (fileName && fileType) {
        FileSaver.saveAs(url, fileName);
      } else {
        throw new Error("Cannot find filename and filetype");
      }
    } catch (e) {
      url = attachment.attachmentUrl;
      fileName = (url as string)
        ? (url as string).split("/")[(url as string).split("/").length - 1]
        : "";
      fileType = (fileName as string)
        ? (fileName as string).split(".")[(url as string).split(".").length - 1]
        : "";

      FileSaver.saveAs(url, fileName);
    }
  }

  /** Format Text
   *
   * @param {string} text the value you want to format
   * @param {string} option the type of operation of format
   * @param {any} dataOption the extra options for operation of format to work -
   * @returns {string} returns the modified string
   */
  public formatText(text: string, option: string, dataOption: any): string {
    let modifiedValue = "";

    if (option === "titlecase") {
      const allWords = text.split(dataOption.seperator ?? " ");

      for (let a = 0; a < allWords.length; a++) {
        const firstChar = allWords[a].charAt(0).toUpperCase();

        modifiedValue += firstChar + allWords[a].slice(1) + " ";
      }
    }

    if (option === "replace") {
      const replaceFrom = dataOption.replaceFrom;

      modifiedValue = text
        .replace(new RegExp(replaceFrom, "g"), dataOption.replaceTo)
        .toString();
    }

    return modifiedValue;
  }

  /** Send Data
   * send data to components that subscribe to this behavior subject
   *
   * == Sample Data to Send ==
   * {
   *   action:'event_name',
   *     data: null
   * }
   * @param data
   */
  public sendData(data: any) {
    this.dataPassBehSub.next(data);

    // clear it
    setTimeout(() => {
      this.dataPassBehSub.next(null);
    }, 200);
  }

  public getData() {
    return this.dataPassBehSub.asObservable();
  }

  /******************************************** get auto complete options for google ******************************************/
  public getStatusOptions() {
    return this.statusList;
  }
   /******************************************** get auto complete options for google ******************************************/
   public getMSStatusOptions() {
    return this.msStatusList;
  }

  /******************************************** get auto complete options for google ******************************************/
  public getAutocompleteOptions() {
    return this.autoCompleteOptions;
  }

  /******************************************** converts simple object key value pairs to param string ******************************************/
  /** Get Param Url Text
   * Returns param url in version of text
   * Example:
   *  - Pass this
   * {
   *  startFrom: 0,
   *  sortOrder: 'asc'
   * }
   *
   * - and get this ?startFrom=0&sortOrder=asc
   *
   * @param {any}paramObj
   * @returns string
   */
  public returnGeneratedParams(paramObj: any): string {
    let paramString = "?";
    if (paramObj) {
      const paramKeys = Object.keys(paramObj);

      for (let pk = 0; pk < paramKeys.length; pk++) {
        if (pk < paramKeys.length && pk !== paramKeys.length - 1) {
          if (paramObj[paramKeys[pk]]) {
            paramString += `${paramKeys[pk]}=${paramObj[paramKeys[pk]]}&`;
          }
        } else {
          if (paramObj[paramKeys[pk]]) {
            paramString += `${paramKeys[pk]}=${paramObj[paramKeys[pk]]}`;
          }
        }
      }
    }

    return paramString;
  }

  public setPersistantItem(key: string, data = {}) {
    let encodeData = "";

    if (typeof data === "string") {
      encodeData = data;

      sessionStorage.setItem(key, data);
    } else {
      encodeData = JSON.stringify(data);

      sessionStorage.setItem(key, encodeData);
    }
  }

  public getPersistantItem(key: string) {
    const data = sessionStorage.getItem(key)!;
    if (sessionStorage.getItem(key) == null) {
      return sessionStorage.getItem(key);
    } else {
      if (data?.includes("{")) {
        return JSON.parse(data);
      } else {
        return data;
      }
    }
  }

  public clearPersistantItem(key: string) {
    if (key) {
      sessionStorage.removeItem(key);
    } else {
      throw new Error("Invalid Key for Clearing Persistant Item")
    }
  }

  public removePersistantItem() {
    sessionStorage.clear()
  }

  getCurrentList() {
    var currencyList = [
      { label: "MYR", value: "RM" },
      { label: "USD", value: "USD" },
    ];
    return currencyList;
  }

  // -- Encodes/Decode Encrypt/Decrypt data --
  // - @action: string = the action done towards the data
  // - @data: string = the data itself
  public cryptItem(action: string, data: any) {
    if ("encode") {
      return btoa(data);
    }
  }

  private totalWeightUnits(title: any, val: any) {
    if (title == "MG") {
      return val * 0.000001;
    } else if (title == "G") {
      return val * 0.001;
    } else if (title == "T") {
      return val * 1000000;
    } else {
      return val;
    }
  }

  private totalLengthUnits(title: any, val: any) {
    if (title == "KM") {
      return val * 1000000;
    } else if (title == "MI") {
      return val * 1609.34;
    } else if (title == "MM") {
      return val * 0.001;
    } else if (title == "CM") {
      return val * 0.01;
    } else if (title == "FT") {
      return val * 0.3048;
    } else {
      return val;
    }
  }

  public returnPackageInfo(packages: any[], option: any, measurements: string) {
    let totalOf = 0;

    if (option === "totalWeight") {
      packages.forEach((thePackage) => {
        totalOf += thePackage.weight * thePackage.quantity;
      });

      totalOf = this.totalWeightUnits(measurements, totalOf);
    }

    if (option === "totalVolume") {
      packages.forEach((thePackage) => {
        thePackage.lenghtUnit =
          thePackage.packageSubType.lenghtUnit.toUpperCase();

        totalOf += Number(
          this.totalLengthUnits(thePackage.lenghtUnit, thePackage.width) *
            this.totalLengthUnits(thePackage.lenghtUnit, thePackage.height) *
            this.totalLengthUnits(thePackage.lenghtUnit, thePackage.length) *
            thePackage.quantity
        );
      });
    }

    if (option === "totalUnitCapacity") {
      packages.forEach((thePackage) => {
        totalOf += thePackage.unitCapacity * thePackage.quantity;
      });
    }

    return totalOf;
  }

  public conversion(toWhat: string, value: number) {
    let newValue = 0;
    if (toWhat === "KM" && value !== null) {
      newValue = value / 1000;
    }

    return newValue;
  }

  /******************************************** round down to standardize placement for x and y ******************************************/
  public rounding(value: number) {
    const staticVal = String(value);
    let newVal = value;

    if (staticVal.length >= 4) {
      newVal = Math.round(value / 100) * 100;
    } else if (staticVal.length < 4 && staticVal.length >= 3) {
      newVal = Math.round(value / 10) * 10;
    } else {
      newVal = value;
    }

    return newVal;
  }

  public withinViewport(rect: any, viewHeight: any, viewWidth: any) {
    let isInViewport = true;
    // rect.top >= 0 &&
    // rect.left >= 0 &&
    // rect.bottom <= viewHeight &&
    // rect.right <= viewWidth;

    let viewPortMeta: any = {};

    if (!(rect.top >= 0)) {
      isInViewport = false;

      viewPortMeta = Object.assign(viewPortMeta, { rectTop: isInViewport });
    }

    if (!(rect.left >= 0)) {
      isInViewport = false;

      viewPortMeta = Object.assign(viewPortMeta, { rectLeft: isInViewport });
    }

    if (!(rect.bottom <= viewHeight)) {
      isInViewport = false;

      viewPortMeta = Object.assign(viewPortMeta, { rectBottom: isInViewport });
    }

    if (!(rect.right <= viewWidth)) {
      isInViewport = false;

      viewPortMeta = Object.assign(viewPortMeta, { rectRight: isInViewport });
    }

    return { isInViewport, viewPortMeta };
  }

  /**
   * Register only number keystrokes by return true / false
   * Use it with inputs of event:
   *
   *  - keypress
   *  - and other similar
   *
   * @param evt
   * @returns
   */
  public onlyNumberKey(evt: any) {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) return false;
    return true;
  }
  // Returns an array of dates between two dates
  public getDates(startDate: any, endDate: any) {
    var dates: any[] = [];
      var currentDate = startDate
      var self: any = this
     var addDays = function (days : any) {
        var date = new Date(self.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  // just reset tab view
  public resetTabView() {
    localStorage.setItem("activeIndex", "0");
  }

  public setTabActive(nameOfTab: string) {
    localStorage.setItem("currentTabActive", nameOfTab);
  }

  public getTabActive() {
    return localStorage.getItem("currentTabActive");
  }

  public sendAssignTaskConfirmation(assignValue: any, mode: any, type: any) {
    this.submitTaskConfirmation$.emit({
      value: assignValue,
      mode: mode,
      type: type,
    });
  }
  public openTaskVIewPage(data : any){
    this.reOpenTaskViewPage$.emit(data)
  }

  public formatNumber(num: any) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public emitCommonData(data: any) {
    this.commonDataEmitter$.emit(data);
  }
  public fetchDistanceMatrixFromLocationAsObservableNew(
    start: any,
    tasks: any,
    traffic: any,
    departureTime: any
  ) {
    var service = new google.maps.DistanceMatrixService();
    var originArray: any[] = [];
    var destinationArray: any[] = [];
    originArray.push({ placeId: start.placeId });
    tasks.forEach((event: any) => {
      // originArray.push(event.location);
      destinationArray.push({ placeId: event.placeId });
    });
    if (!destinationArray.length) {
      return throwError(false);
    }
    if (traffic) {
      var request: any = {
        origins: originArray,
        destinations: destinationArray,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        drivingOptions: {
          // departureTime: new Date(Date.UTC(moment(departureTime).year(), moment(departureTime).month(), moment(departureTime).date(), moment(departureTime).hour(), moment(departureTime).minute())),
          departureTime: new Date(departureTime),
        },
      };
    } else {
      var request: any = {
        origins: originArray,
        destinations: destinationArray,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
      };
    }
    return Observable.create((observer: any) => {
      service.getDistanceMatrix(request, callback);
      function callback(response: any, status: any) {
        if (status == "OK") {
          var tempArray = [];
          var trafficDuration = 0;
          var trafficValue = 0;
          var origins = response.originAddresses;
          var destinations = response.destinationAddresses;
          for (var i = 0; i < origins.length; i++) {
            var results = response.rows[i].elements;
            for (var j = 0; j < results.length; j++) {
              var element = results[j];
              var distance = element.distance.text;
              var distanceValue = element.distance.value;
              var durationValue = element.duration.value;
              var duration = element.duration.text;
              var from = origins[i];
              var to = destinations[j];
              if (traffic) {
                trafficDuration = element.duration_in_traffic.text;
                trafficValue = element.duration_in_traffic.value;
              }
              var temp = {
                origin: from,
                destination: to,
                distance: distance,
                distanceValue: distanceValue,
                durationValue: durationValue,
                duration: duration,
                trafficDuration: trafficDuration,
                trafficValue: trafficValue,
                originPlaceId: originArray[i].placeId,
                destinationPlaceId: destinationArray[j].placeId,
              };
              tempArray.push(temp);
            }
          }
          var finalResponse = {
            response: response,
            array: tempArray,
          };
          observer.next(finalResponse);
        } else {
          // console.log("Google error ", status);
          observer.next(status);
        }
      }
    });
  }

  public downloadDataTo(
    type: string,
    data: any[],
    filename: string,
    options: any
  ) {
    return new Promise<void>((resolve, reject) => {
      if (data.length < 0) {
        reject();
      }

      if (type.toLowerCase() === "csv") {
        let keys = Object.keys(data[0]);
        let customHeaders = [];

        if (options.hasOwnProperty("customHeaders")) {
          customHeaders = options.customHeaders;
        }

        let result = "";

        if (options.hasOwnProperty("customHeaders")) {
          result += customHeaders.join(",");

          console.log("RESULT", result);
        } else {
          result += keys.join(",");
        }

        result += "\n";
        data.forEach(function (item) {
          keys.forEach(function (key) {
            result += item[key] + ",";
          });
          result += "\n";
        });
        let csv = "data:text/csv;charset=utf-8," + result;
        let excel = encodeURI(csv);
        let link = document.createElement("a");
        link.setAttribute("href", excel);
        link.setAttribute("download", `${filename}.csv`);
        link.click();
      }

      resolve();
    });
  }

  public dateFormatProcessing(
    dateFormat: string,
    action: string,
    whatPart: string,
    replaceTo: string
  ) {
    const allDividers = ["-", "/"];
    let splitArrays: any[] = [];
    let currentDivider: string | null = null;

    for (let divider of allDividers) {
      if (dateFormat.includes(divider)) {
        currentDivider = divider;
        splitArrays = dateFormat.split(currentDivider);
        break;
      }
    }

    if (action === "replace") {
      for (let sa = 0; sa < splitArrays.length; sa++) {
        if (splitArrays[sa] === whatPart) {
          splitArrays[sa] = replaceTo;
        }
      }
    }

    // after done return fomatted date
    if (currentDivider === null) {
      throwError("No dividers to join arrays...");
    } else {
      splitArrays.join(currentDivider);
    }

    return splitArrays.join(currentDivider!);
  }



  OnAssetJoChanged(message: any) {
    this.joDataChangedSubject.next(message);
  }

  noWhitespace(control: AbstractControl): ValidationErrors | null {
    if (control.value && control.value.trim() === '') {
      return { noWhitespace: true };
    }
    return null;
  }

}
