
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/auth/auth-guard.service';
import { ListTasksComponent } from './list-tasks/list-tasks.component';
import { TaskCalendarViewComponent } from './task-calendar-view/task-calendar-view.component';
import { TaskOptimizeComponent } from './task-optimize/task-optimize.component';
import { AddEditJourneyComponent } from './task-journey/add-edit-journey/add-edit-journey.component';
import { ViewJourneyComponent } from './task-journey/view-journey/view-journey.component';
import { AllMyTaskComponent } from './all-my-task/all-my-task.component';
import { AllStaffTaskComponent } from './all-staff-task/all-staff-task.component';
import { AllTeamTaskComponent } from './all-team-task/all-team-task.component';
import { ViewTaskSchedulesComponent } from './view-task-schedules/view-task-schedules.component';
import { ViewStaffTaskSchedulesComponent } from './view-staff-task-schedules/view-staff-task-schedules.component';
import { TaskMapComponent } from './task-map/task-map.component';
import { OptimizePlannedActualComponent } from './optimize-planned-actual/optimize-planned-actual.component';
import { ViewVehicleComponent } from '../vehicles/view-vehicle/view-vehicle.component';
import { OpenNewTabViewTaskComponent } from './open-new-tab-view-task/open-new-tab-view-task.component';

const taskRoutes: Routes = [
  {
    path: '',
    redirectTo: 'todayStaffTaskMapView',
    pathMatch: 'full'
  },
  {
    path: 'all',
    component: ListTasksComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" , navigation: {root: true}}
  },
  {
    path: 'allMyTask',
    component: AllMyTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'all', navigation: {root: true} }
  },
  {
    path: 'todayMyTask',
    component: AllMyTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'today' , navigation: {root: true}}
  },
  {
    path: 'upcomingMyTask',
    component: AllMyTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'upcoming' , navigation: {root: true}}
  },
  {
    path: 'completedMyTask',
    component: AllMyTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'completed' }
  },
  // {
  //   path: 'allStaffTask',
  //   component: AllStaffTaskComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read",mode:'all' }
  // },
  // {
  //   path: 'todayStaffTask',
  //   component: AllStaffTaskComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read",mode:'today' }
  // },
  // {
  //   path: 'upcomingStaffTask',
  //   component: AllStaffTaskComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read",mode:'upcoming' }
  // },
  // {
  //   path: 'completedStaffTask',
  //   component: AllStaffTaskComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read",mode:'completed' }
  // },
  {
    path: 'allTeamTask',
    component: AllTeamTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'all' }
  },
  {
    path: 'todayTeamTask',
    component: AllTeamTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'today' }
  },
  {
    path: 'upcomingTeamTask',
    component: AllTeamTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'upcoming' }
  },
  {
    path: 'completedTeamTask',
    component: AllTeamTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'completed' }
  },
  {
    path: 'view',
    component: OpenNewTabViewTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  },
  // {
  //   path: 'check-availability',
  //   component: ViewTasksComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read" }
  // },
  {
    path: 'my-tasks',
    component: TaskCalendarViewComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  },
  // {
  //   path: 'team-tasks',
  //   component: TeamTaskComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read" }
  // },
  {
    path: 'optimize',
   component: TaskOptimizeComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  }, 
  {
    path: 'optimize/schedule',
   component: OptimizePlannedActualComponent,
    // canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  }, 
  {
    path: 'vehicle/view/:id',
   component: ViewVehicleComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  }, 
  // {
  //   path: 'journeys',
  //   component: TaskJourneyComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read" }
  // },
  {
    path: 'journeys/create',
    component: AddEditJourneyComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "create", mode: 'add' }
  },
  {
    path: 'journeys/update',
    component: AddEditJourneyComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "update", mode: 'edit' }
  },
  {
    path: 'journeys/view',
    component: ViewJourneyComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read", mode: 'edit' }
  },
  {
    path: 'teamTaskMapView',
    component: ViewTaskSchedulesComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'all', navigation: {root: true} }
  },
  {
    path: 'todayTeamTaskMapView',
    component: ViewTaskSchedulesComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'today' }
  },
  {
    path: 'upcomingTeamTaskMapView',
    component: ViewTaskSchedulesComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'upcoming' }
  },
  {
    path: 'staffTaskMapView',
    component: ViewStaffTaskSchedulesComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'all' }
  },
   {
    path: 'todayStaffTaskMapView',
    component: ViewStaffTaskSchedulesComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'today', navigation: {root: true} }
  },
  {
    path: 'upcomingStaffTaskMapView',
    component: ViewStaffTaskSchedulesComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read",mode:'upcoming' }
  },
  {
    path:'map',
    component: TaskMapComponent,
    canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  },
  {
    path: 'dailyRouteMaps',
   component: OptimizePlannedActualComponent,
     canActivate: [AuthGuardService],
    data: { module: "tasks", "action": "read" }
  },
  // {
  //   path:'hazardControl',
  //   component: HazardControlComponent,
  //   canActivate: [AuthGuardService],
  //   data: { module: "tasks", "action": "read" }
  // },
]

@NgModule({
  imports: [RouterModule.forChild(taskRoutes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
