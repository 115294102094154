import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { TicketsService } from "src/app/_services/tickets.service";
import * as moment from "moment-timezone";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { ServerRequest } from "src/app/_models/global.data.model";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { NgxSpinnerService } from "ngx-spinner";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";

@Component({
  selector: "app-ticket-history",
  templateUrl: "./ticket-history.component.html",
  styleUrls: ["./ticket-history.component.scss"],
})
export class TicketHistoryComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Input() loadedDymanically: boolean = false;
  @Input() ticketId: any;
  dateFormateChange: any;

  searchValue = "";

  gridApiTicketHistory: any;
  logGridParamsTicketHistory: any;
  gridColumnApiTicketHistory: any;
  columnDefsTicketHistory: any;
  defaultColDefTicketHistory: any;
  gridParamsTicketHistory: any;
  startFromTicketHistory: any;
  parametersTicketHistory: any;
  pageSizeTicketHistory: any = 25;
  ticketHistorys: any;
  public gridOptionsTicketHistory!: Partial<GridOptions>;
  noTicketHistory: boolean | null = null;
  overlayNoRowsTemplate!: string;

  currentRecordNumber!: number;
  totalRecord: any;

  constructor(
    private ticketsService: TicketsService,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService
  ) {
    super();

    this.preInit();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** start variable init ******************************************/
  private preInit() {
    this.dateFormateChange = this.auth.getUserTimezone();

    this.gridOptionsTicketHistory = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDefTicketHistory = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

    this.columnDefsTicketHistory = [
      {
        headerName: "Time & Date",
        field: "modifiedDate",
        width: 200,
        headerTooltip: "#",
        cellRenderer: (params: any) => {
          if (params.data) {
            return moment
              .tz(params.data.modifiedDate, this.dateFormateChange)
              .format(
                "hh:mm A" +
                  ", " +
                  localStorage.getItem("date_format")!.toUpperCase()
              );
          }
        },
      },
      { headerName: "Field", field: "fieldName", width: 150, headerTooltip: "#", },
      {
        headerName: "Modified By",
        field: "modifiedByUser.fullName",
        width: 270,
        headerTooltip: "#",
      },
      {
        headerName: "Old Value",
        field: "oldValue",
        width: 270,
        headerTooltip: "#",
      },
      {
        headerName: "New Value",
        field: "newValue",
        width: 270,
        headerClass: "marging-auto hide-action-border",
        headerTooltip: "#",
      },
    ];
  }

  /******************************************** on init ******************************************/
  private init() {
    if (!this.ticketId) {
      this.ticketId = this.route.snapshot.params["id"];
    }
  }

  /******************************************** set ticket history ******************************************/
  private filterResultsTicketHistory() {
    this.getServerSideData(this.gridApiTicketHistory);
  }

  /******************************************** table on ready ******************************************/
  public onGridReady(params: any) {
    this.gridParamsTicketHistory = params;

    this.gridApiTicketHistory = params.api;
    this.gridApiTicketHistory.sizeColumnsToFit();

    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        this.getServerSideData(params);
      },
    };

    this.gridApiTicketHistory.sizeColumnsToFit();
    this.gridApiTicketHistory.setDatasource(dataSource);
  }

  /******************************************** get data ******************************************/
  private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    this.spinner.show();
    this.gridApiTicketHistory.hideOverlay();
    let serverRequest = this.getRequestParams(agGridGetRowsParams);

    this.push(
      this.ticketsService
        .getAllTicketHistory(this.ticketId, this.searchValue, serverRequest)
        .subscribe(
          (data: any) => {
            this.ticketHistorys = data.data;
            this.totalRecord = data.total;
            this.setGridData(
              agGridGetRowsParams,
              this.ticketHistorys,
              this.totalRecord
            );

            this.spinner.hide();
          },
          (err) => {
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }

  /******************************************** set data ******************************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number
  ) {
    if (resultItems.length === 0) {
      this.gridApiTicketHistory.showNoRowsOverlay();
      this.noTicketHistory = true;
    } else {
      this.noTicketHistory = false;
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }
  }

  /******************************************** request params ******************************************/
  private getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parametersTicketHistory = { colId: "lastModifiedDate", sort: "asc" };
      agGridRequest.sortModel.push(this.parametersTicketHistory);
    }

    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }

  /******************************************** refresh data ******************************************/
  public refresh() {
    this.onGridReady(this.gridParamsTicketHistory);
  }
  push(obs:any) {
    super.push(obs);
  }
}
