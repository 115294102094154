<div class="modal-header p-dialog-header flex-between">
    <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg" />
        <span class="text-16 font-medium ms-3" id="title_SetSiteFormat">Site Order Format</span>
    </div>
    <i class="pi pi-times w-24px" [pTooltip]="'close'|translate" tooltipPosition="left" (click)="modalRef.hide()" id="btn_SetSiteClose"></i>
</div>
<div class="modal-body tix height-80">
    <div class="m-0 pb-3 px-2">
        <span class="text-12 d-block font-semiBold" id="label_SetSiteFormatNo" translate>{{ "what_is_site_format" }}</span>
        <span class="text-12" id="value_SetiteFormatNoDesc" translate>{{ "site_format_allows_to_customize" }}</span>
    </div>
    <div>
        <p-table [value]="tix" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
                <tr class="">
                    <th class="col-2" id="label_SetSiteVar">{{'variable'|translate}}</th>
                    <th class="col-2" id="label_SetSiteNec">{{'necessity'|translate}}</th>
                    <th class="col-2" id="label_SetSiteDesc">{{'description'|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tix>
                <tr class="text-12 font-medium">
                    <td class="col-2" id="value_SetSiteVar">{{ tix.variable }}</td>
                    <td class="col-2 text-left" id="value_SetSiteNec">{{ tix.necessity }}</td>
                    <td class="col-2 text-left" id="value_SetSiteDesc">{{ tix.desc }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="pt-2">
        <div class="m-1">
            <span class="text-12 font-medium" id="label_SetSiteFormatEg">{{'here_examples'|translate}}</span>
        </div>
        <p-table [value]="tix2" styleClass="p-datatable-sm">
            <ng-template pTemplate="header">
                <tr class="">
                    <th class="col-2" id="label_SetSiteFormat">Site Format</th>
                    <th class="col-2" id="label_SetSiteSeqNo">{{'seq_no'|translate}}</th>
                    <th class="col-2" id="label_SetSiteFormatNo">{{'formatted_no'|translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-tix2>
                <tr class="text-12 font-medium">
                    <td id="value_SetSiteFormat">{{ tix2.format }}</td>
                    <td class="col-2 text-left">
                        <tr id="value_SetSiteSeqNo1">
                            {{ tix2.seq1 }}
                        </tr>
                        <tr id="value_SetSiteSeqNo2">
                            {{ tix2.seq2 }}
                        </tr>
                    </td>
                    <td class="col-2 text-left">
                        <tr id="value_SetSiteFormatNo1">
                            {{ tix2.number1 }}
                        </tr>
                        <tr id="value_SetSiteFormatNo2">
                            {{ tix2.number2 }}
                        </tr>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
