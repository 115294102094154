import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UsersService } from 'src/app/_services/admin/users.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { SubscriptionUtil } from 'src/app/_utilities/subscription';
declare var google: any;

@Component({
  selector: 'app-add-edit-journey',
  templateUrl: './add-edit-journey.component.html'
})
export class AddEditJourneyComponent extends SubscriptionUtil implements OnInit , AfterViewChecked{
  @Output() goBackTablePage = new EventEmitter();
  @Input() data: any
  @Input() mode: any;
  addJourney:any ={
    endLocation: { address: null,coordinate: {latitude: null, longitude: null} },
    startDateTime: null,
    startLocation: {address: null ,coordinate: { latitude: null,longitude: null} },
    taskId: null,
    userId: null,
    fromTaskId:null,
    toTaskId: null,
    startTime: null,
    toType:null ,
    fromType:null
  }
  addJourneyForm : UntypedFormGroup;
  taskId:any;
  users: any;
  distanceData: any;
  dateConfig: any;
 
  submitTouched!: boolean;
  distanceKM:any =''
  title:any
  fromType ='current'
  toType = 'current'
  locationByTaskData: any[]=[];
  customerData: any;
  homeAddress: any = null;
  officeAddress: any = null;
  locationNotSelected: boolean = false;
  deleteUserId: any;

  locationByEndTaskData: any[]=[];


  @ViewChild("input_TaskJourneySearchLocField", { static: false })
  public homeAddressSearchElementRef!: ElementRef;
  @ViewChild("officeAddressSearch", { static: false })
  public officeAddressSearchElementRef!: ElementRef;
  autocomplete: any;
  autocomplete1: any;
  autocompleteLsr: any;
  autocompleteLsr1: any;
  flag: boolean = true;
  flag1: boolean = true;
  homePlaceChanged: boolean = false;
  officePlaceChanged: boolean = false;
  userId: any
  @Input() journeyId: any;
  taskName:any

  constructor(
    private taskService : TasksService,
    private spinner : NgxSpinnerService,
    private auth: AuthenticationService,
    private cdRef: ChangeDetectorRef,
    private utilService: UtilServiceService,
    private userService : UsersService,
    private ngZone: NgZone,
    private errorUtil: ErrorUtil,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private router: Router
    ) {

      super();
      this.addJourneyForm = new UntypedFormGroup({
        'startLocation': new UntypedFormControl(null, [Validators.required]),
        'endLocation': new UntypedFormControl(null, [Validators.required]),
        'userId': new UntypedFormControl(null, [Validators.required]),
        'startDateTime': new UntypedFormControl(null),
        'fromTaskId': new UntypedFormControl(null),
        'toTaskId':  new UntypedFormControl(null)
      });

    }

  ngOnInit() {
    console.log(this.data,"========")
    this.dateConfig = localStorage.getItem('date_format')
    // if (this.route.snapshot.data['mode']) {
    //   this.mode = this.route.snapshot.data['mode']
    // }
    
    this.taskId = this.data.id;
    this.userId = this.data.assignedTo.id
    // this.journeyId = this.data.id
    this.getJourneyByMode()
    this.spinner.hide()
  }
getJourneyByMode(){
  if(this.mode == 'add'){
    var task = this.data;

    this.addJourney.taskId = this.taskId
    task.assignedUsersList.forEach((element : any) => {
      if(element.owner){
        this.addJourney.userId =parseInt(element.assignedTo.id)
      }

    });
    if(task.startDateTime!=null){
    this.addJourney.startTime = moment(task.startDateTime).tz(this.auth.getUserTimezone()).format('hh:mm A')
     this.addJourney.startDateTime=new Date(task.startDateTime);
     }

    this.getUsersByTask(this.addJourney.taskId)
    this.spinner.hide()
  
  }

  if(this.mode == 'edit'){
    this.taskService.getJourneyTripsById(this.journeyId).subscribe((res:any) => {
      //console.log(res)

     var modifyData={
      endLocation: { address: res.userLocationRouteTable.endLocationUserDetails.address,
        coordinate:
          {latitude: res.userLocationRouteTable.endLocationUserDetails.latitude,
           longitude: res.userLocationRouteTable.endLocationUserDetails.longitude} ,
           id:res.userLocationRouteTable.startLocationUserDetails.id
          },

      startLocation:{ address: res.userLocationRouteTable.startLocationUserDetails.address,
        coordinate:
        {latitude: res.userLocationRouteTable.startLocationUserDetails.latitude,
           longitude: res.userLocationRouteTable.startLocationUserDetails.longitude},
           id:res.userLocationRouteTable.endLocationUserDetails.id
           },
        taskId:res.userLocationRouteTable.taskInfo.id
    }

       let output = Object.assign(res.userLocationRouteTable, modifyData);

        //console.log(output,"===========After : " + modifyData );
        this.addJourney = output;
        this.addJourney.startDateTime = new Date(res.userLocationRouteTable.startDateTime);

          this.addJourney.startTime = moment(res.userLocationRouteTable.startDateTime).tz(this.auth.getUserTimezone()).format('hh:mm A')
          this.addJourney.userId =parseInt(this.addJourney.userId)
          
          this.addJourney.fromTaskId = this.addJourney.fromTaskId ?  parseInt(this.addJourney.fromTaskId): null
          this.addJourney.toTaskId = this.addJourney.toTaskId ?  parseInt(this.addJourney.toTaskId) : null
          
        console.log("After : " + this.addJourney );
          this.getUsersByTask(this.taskId)

          if(this.addJourney.estimateDistance){
            // this.initialize()
            this.addJourneyForm.controls.startDateTime.enable()
           }else{
            this.addJourneyForm.controls.startDateTime.disable()
           }
          this.getLocationByTask()
    })
  }
}
//   /****************************************** Load BreadCrumb *****************************/
//  loadBreadCrum() {
//   this.breadcrumbService.setSetupItems([
//     { label: 'Task', routerLink: ["/dashboard/tasks"] },
//      { label: this.taskName,command: e => {
//       this.router.navigate(['/dashboard/tasks/view'], {queryParams: {'id' : this.taskId}});
//     } 
//    },
//     { label: 'Journeys', command: e => {
//       this.router.navigate(['dashboard/tasks/journeys'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId}});
//     } 
//    },
//     { label: this.mode == 'add' ? 'Add Journey':'Edit Journey'}
//   ]);
// }
getDeletedTaskId(taskType : any,selectedTask : any){

    //console.log(taskType,"====",selectedTask)
if(taskType == 'startTask'){

  var s= _.filter(this.locationByTaskData, (o) => {
    return o.id == selectedTask;
  });
  console.log(s.length,"======")

var taskDeleted = s.length == 0  ? true : false

     if(taskDeleted){
        var newData={
            id:selectedTask,
            taskName: 'Task Deleted',
            location :null,
            latitude: null,
            longitude: null,
            disabled : true,
            customeName : 'Task Deleted'

          }
          this.locationByTaskData.push(newData)
    }

    }else{

      var s= _.filter(this.locationByEndTaskData, (o) => {
        return o.id == selectedTask;
      });
      console.log(s.length,"======",selectedTask)

    var  taskDeleted = s.length == 0  ? true : false
        if(taskDeleted){
            var newData={
                id:selectedTask,
                taskName: 'Task Deleted',
                location :null,
                latitude: null,
                longitude: null,
                disabled : true,
                customeName : 'Task Deleted'
              }
              this.locationByEndTaskData.push(newData)
        }
        console.log(this.locationByEndTaskData)
    }


    console.log(this.addJourney)

  }

  ngAfterViewChecked()
  {
    this.cdRef.detectChanges();
  }

  getUsersByTask(taskId : any){
    this.taskService.getUsersByTask(taskId).subscribe((res:any)=>{
      this.users=res

      this.customerData= _.filter(this.users, (o) => {
        return o.id == this.addJourney.userId;
      });
      if(this.mode == 'edit'){
       this.deleteUserId = this.customerData[0]== undefined ? true : false
       if(this.deleteUserId){
        this.getDeletedUserProfile()
       }
      }

      console.log(this.customerData[0])
      this.setAddressByUserSelected()

    })
  }
  getDeletedUserProfile(){
    this.userService.getUserProfile(this.addJourney.userId).subscribe((userProfileResponse:any) => {
      console.log(userProfileResponse)
      if(userProfileResponse){
        this.users.push(userProfileResponse)
      }
    });

  }
  getLocationByTask(){

    this.locationByTaskData = []
    this.locationByEndTaskData = []
    if(this.addJourney.fromType == 'Task' || this.addJourney.toType == 'Task'){

      var startDate = moment(this.addJourney.startDateTime).format('YYYY-MM-DD');
    console.log(startDate)
    this.taskService.getLocationByTask(this.addJourney.userId,startDate).subscribe((res:any)=>{

          res.forEach((element  : any)=> {
            element.disabled = false
            element.customeName = element.taskName+' - '+element.location

            this.locationByTaskData.push(element)
          });
          res.forEach((element : any) => {
            element.disabled = false
            element.customeName = element.taskName+' - '+element.location

            this.locationByEndTaskData.push(element)
          });

              if(this.mode == 'edit'){

              if(this.addJourney.fromType == 'Task'){
                this.addJourneyForm.controls['fromTaskId'].setValidators([Validators.required]);
                this.addJourneyForm.controls['fromTaskId'].updateValueAndValidity();
                this.getDeletedTaskId('startTask',this.addJourney.fromTaskId)
              }
              if(this.addJourney.toType == 'Task'){

                this.addJourneyForm.controls['toTaskId'].setValidators([Validators.required]);
                this.addJourneyForm.controls['toTaskId'].updateValueAndValidity();
                this.getDeletedTaskId('endTask',this.addJourney.toTaskId)

              }
            }
    })
  }
  }

  setStartLocation(val : any){
    console.log(val)
    this.fromType = val
    this.addJourney.fromType = val
    var geocoder = new google.maps.Geocoder;
    var self = this;
    this.addJourneyForm.controls['fromTaskId'].clearValidators();
    this.addJourneyForm.controls['fromTaskId'].updateValueAndValidity();

    if(val == 'Current Location'){
      if ("geolocation" in navigator) {
        console.log(navigator)
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          //console.log(position);
          var latlng = {lat: latitude, lng: longitude};
          geocoder.geocode({'location': latlng}, function(results: any, status: any) {
            console.log(results)
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                results = _.remove(results, function(n : any) {
                  return n.geometry.location_type === 'APPROXIMATE';
                });
                console.log(results[0])
               // self.addJourney.startLocation.address = null;
                self.addJourney.startLocation.address = results[0].formatted_address;
                self.addJourney.startLocation.coordinate.latitude = latitude;
                self.addJourney.startLocation.coordinate.longitude = longitude;
                self.addJourney.fromTaskId = null
              } else {
                console.log('No results found');
              }
            } else {
              console.log('Geocoder failed due to: ' + status);
            }
          });
        });
      }
    } else if(val == 'Home'){
      if(this.homeAddress){
       // this.addJourney.locationName = null;
        this.addJourney.startLocation.address = this.homeAddress.address;
        this.addJourney.startLocation.coordinate.latitude = this.homeAddress.lat;
        this.addJourney.startLocation.coordinate.longitude = this.homeAddress.lng;
        this.addJourney.fromTaskId = null
      } else {
        this.addJourney.startLocation.address = null;
        this.addJourney.startLocation.coordinate.latitude = null;
        this.addJourney.startLocation.coordinate.longitude = null;
        this.addJourney.fromTaskId = null
        this.errorUtil.setErrorMessage(200, this.customerData[0].firstName +" "+ this.customerData[0].lastName +"'s Home address is not set.",  null, 'warn',3000);
       }
    } else if(val == 'Office'){
      console.log(this.officeAddress)

      if(this.officeAddress){
        this.addJourney.startLocation.address = this.officeAddress.address;
        this.addJourney.startLocation.coordinate.latitude = this.officeAddress.lat;
        this.addJourney.startLocation.coordinate.longitude = this.officeAddress.lng;
        this.addJourney.fromTaskId = null
      } else {
        this.addJourney.startLocation.address = null;
        this.addJourney.startLocation.coordinate.latitude = null;
        this.addJourney.startLocation.coordinate.longitude = null;
        this.addJourney.fromTaskId = null
        this.errorUtil.setErrorMessage(200, this.customerData[0].firstName +" "+ this.customerData[0].lastName +"'s Office address is not set",  null, 'warn',3000);
      
      }
    } else if(val == 'Task'){

        this.addJourney.startLocation.address = null;
        this.addJourney.startLocation.coordinate.latitude = null;
        this.addJourney.startLocation.coordinate.longitude = null;
        this.addJourneyForm.controls['fromTaskId'].setValidators([Validators.required]);
        this.addJourneyForm.controls['fromTaskId'].updateValueAndValidity();

        this.getLocationByTask()


    }

    console.log(this.addJourney)
  }

  setEndLocation(val : any){
    this.toType = val
    this.addJourney.toType = val
     this.addJourneyForm.controls['toTaskId'].clearValidators();
    this.addJourneyForm.controls['toTaskId'].updateValueAndValidity();

    var geocoder = new google.maps.Geocoder;
    var self = this;
    if(val == 'Current Location'){
      console.log("sjdhfgjdhfg")
      if ("geolocation" in navigator) {
        console.log(navigator.geolocation.getCurrentPosition)
        navigator.geolocation.getCurrentPosition((position) => {
          console.log("sjdhfsjhdghasdhkjh")
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          console.log(position);
          var latlng = {lat: latitude, lng: longitude};
          geocoder.geocode({'location': latlng}, function(results: any, status: any) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                results = _.remove(results, function(n : any) {
                  return n.geometry.location_type === 'APPROXIMATE';
                });
                console.log('Results from Google', results[0]);
                self.addJourney.endLocation.address = results[0].formatted_address;
                self.addJourney.endLocation.coordinate.latitude = latitude;
                self.addJourney.endLocation.coordinate.longitude = longitude;
              } else {
                console.log('No results found');
              }
            } else {
              console.log('Geocoder failed due to: ' + status);
            }
          });
        });
      }else{
        console.log('No results found');
      }
    } else if(val == 'Home'){
      if(this.homeAddress){
       // this.addJourney.locationName = null;
        this.addJourney.endLocation.address = this.homeAddress.address;
        this.addJourney.endLocation.coordinate.latitude = this.homeAddress.lat;
        this.addJourney.endLocation.coordinate.longitude = this.homeAddress.lng;
        this.addJourney.toTaskId = null
      } else {
        this.addJourney.endLocation.address = null;
        this.addJourney.endLocation.coordinate.latitude = null;
        this.addJourney.endLocation.coordinate.longitude = null;
        this.addJourney.fromTaskId = null
        this.errorUtil.setErrorMessage(200, this.customerData[0].firstName +" "+ this.customerData[0].lastName +"'s Home address is not set." ,  null, 'warn',3000);
       }
    } else if(val == 'Office'){
      if(this.officeAddress){
        this.addJourney.endLocation.address = this.officeAddress.address;
        this.addJourney.endLocation.coordinate.latitude = this.officeAddress.lat;
        this.addJourney.endLocation.coordinate.longitude = this.officeAddress.lng;
        this.addJourney.fromTaskId = null
      } else {
        this.addJourney.endLocation.address = null;
         this.addJourney.endLocation.coordinate.latitude = null;
        this.addJourney.endLocation.coordinate.longitude = null;
        this.addJourney.toTaskId = null
        this.errorUtil.setErrorMessage(200, this.customerData[0].firstName +" "+ this.customerData[0].lastName +"'s Office address is not set" ,  null, 'warn',3000);
       
      }
    }else if(val == 'Task'){

      this.addJourney.endLocation.address = null;
      this.addJourney.endLocation.coordinate.latitude = null;
      this.addJourney.endLocation.coordinate.longitude = null;
     this.addJourney.toTaskId = null
     this.addJourneyForm.controls['toTaskId'].setValidators([Validators.required]);

      this.getLocationByTask()

  }
    console.log(this.addJourney)
  }


  resetStartAddress(val : any){
    this.addJourney.startLocation.address = val;
    // this.addJourney.startLocation.coordinate.latitude = null;
    // this.addJourney.startLocation.coordinate.longitude = null;
  }
  resetEndAddress(val : any){
     this.addJourney.endLocation.address = val;
    // this.addJourney.endLocation.coordinate.latitude = null;
    // this.addJourney.endLocation.coordinate.longitude = null;
  }


  initLisner() {
    if (this.autocompleteLsr)
      google.maps.event.removeListener(this.autocompleteLsr);
    this.autocompleteLsr = this.autocomplete.addListener("place_changed",() => {
        this.homePlaceChanged = true;
        this.addJourney.startLocation.coordinate.latitude = "";
        this.addJourney.startLocation.coordinate.longitude = "";
        this.ngZone.run(() => {
          //get the place result
          let place = this.autocomplete.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          
          //this.addJourney.placeId = place.place_id;
          this.addJourney.startLocation.address = place.formatted_address;

          if(place.types.includes('establishment')){
            this.addJourney.startLocation.address = place.name + ', ' + place.formatted_address;
          } 
          else {
            this.addJourney.startLocation.address = place.formatted_address;
          }
          this.addJourney.startLocation.coordinate.latitude = place.geometry.location.lat();
          this.addJourney.startLocation.coordinate.longitude = place.geometry.location.lng();
          this.distanceData=''
          this.distanceKM = ''
          this.locationNotSelected =false;
          this.addJourney.fromTaskId = null;
          this.cdRef.detectChanges();
        });
        setTimeout(() => {
          this.homePlaceChanged = false;
        }, 2000);
      }
    );
  }

  initLisner1() {//office
    if (this.autocompleteLsr1)
      google.maps.event.removeListener(this.autocompleteLsr1);
    this.autocompleteLsr1 = this.autocomplete1.addListener("place_changed",() => {
        this.officePlaceChanged = true;
         this.addJourney.endLocation.coordinate.latitude = "";
         this.addJourney.endLocation.coordinate.longitude = "";
         this.ngZone.run(() => {
          //get the place result
          let place = this.autocomplete1.getPlace();
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          //this.addJourney.placeId = place.place_id;
          this.addJourney.endLocation.address = place.formatted_address;

          if(place.types.includes('establishment')){
            this.addJourney.endLocation.address = place.name + ', ' + place.formatted_address;
          } 
          else {
            this.addJourney.endLocation.address = place.formatted_address;
          }
          this.addJourney.endLocation.coordinate.latitude = place.geometry.location.lat();
          this.addJourney.endLocation.coordinate.longitude = place.geometry.location.lng();
          this.distanceData=''
          this.distanceKM = ''
          this.locationNotSelected =false;
          this.addJourney.toTaskId = null;
          this.cdRef.detectChanges();
        });
        setTimeout(() => {
          this.officePlaceChanged = false;
        }, 2000);
      }
    );
  }

  callGoogleAutocomplete() {
    //load Places Autocomplete(search time calling)
    
      if (this.autocomplete)
        google.maps.event.clearInstanceListeners(this.autocomplete);
      this.autocomplete = new google.maps.places.Autocomplete(
        this.homeAddressSearchElementRef.nativeElement,
        {
          // Specify only the fields you need
          fields: ['place_id', 'geometry', 'name', 'formatted_address','types'],
        }
      );
      this.initLisner();
   
  }

  callGoogleAutocomplete1() {//office
    //load Places Autocomplete(search time calling)
    
      if (this.autocomplete1)
        google.maps.event.clearInstanceListeners(this.autocomplete1);
      this.autocomplete1 = new google.maps.places.Autocomplete(
        this.officeAddressSearchElementRef.nativeElement,
        {
          // Specify only the fields you need
          fields: ['place_id', 'geometry', 'name', 'formatted_address','types'],
        }
      );
      this.initLisner1();
    
  }

  getPlaceApiCall(event : any) {
    let field = document.getElementById("input_TaskJourneySearchLocField") as any;
    let val = event.target.value;
    if (val.length < 3) {
      google.maps.event.removeListener(this.autocompleteLsr);
      google.maps.event.clearInstanceListeners(field);
      if (this.autocomplete)
        google.maps.event.clearInstanceListeners(this.autocomplete);
      $(".pac-container").remove();
      this.flag = true;
    } else if (this.flag) {
      this.callGoogleAutocomplete();
      this.flag = false;
    }
  }

  getPlaceApiCall1(event : any) {//office
    let field1 = document.getElementById("officeAddressSearch1")as any;
    let val = event.target.value;
    if (val.length < 3) {
      google.maps.event.removeListener(this.autocompleteLsr1);
      google.maps.event.clearInstanceListeners(field1);
      if (this.autocomplete1)
        google.maps.event.clearInstanceListeners(this.autocomplete1);
      $(".pac-container").remove();
      this.flag1 = true;
    } else if (this.flag1) {
      this.callGoogleAutocomplete1();
      this.flag1 = false;
    }
  }

  calculateDistance() {
    console.log(this.addJourney)
  if(this.addJourney.startLocation.coordinate.latitude != null && this.addJourney.startLocation.coordinate.longitude !=null && this.addJourney.endLocation.coordinate.latitude !=null && this.addJourney.endLocation.coordinate.longitude !=null){
    this.locationNotSelected = false
    this.taskService.getCalculateDistance( this.addJourney).subscribe((resp:any)=>{
      this.distanceData=resp
      console.log(this.distanceData)
      this.distanceKM = (this.distanceData.newRecordId / 1000).toFixed(2);
      console.log(this.distanceKM + " Kilometers");

    })
  }else{
    this.locationNotSelected = true
  }
  }
  getTaskDetail(taskType : any,selectedTask : any){
    console.log(taskType)
    if(taskType == 'startLocation'){
      var data= _.filter(this.locationByTaskData, (o) => {
        return o.id == selectedTask;
       });
       console.log(data)
      this.addJourney.startLocation.address = data[0].location;
      this.addJourney.startLocation.coordinate.latitude = data[0].latitude;
      this.addJourney.startLocation.coordinate.longitude = data[0].longitude;

      this.distanceData=''
      this.distanceKM = ''


    }else{
       var data= _.filter(this.locationByEndTaskData, (o) => {
       return o.id == selectedTask;
     });
     console.log(data)
    this.addJourney.endLocation.address = data[0].location;
    this.addJourney.endLocation.coordinate.latitude = data[0].latitude;
    this.addJourney.endLocation.coordinate.longitude = data[0].longitude;

    this.distanceData=''
    this.distanceKM = ''
    }
    console.log(this.addJourney)

  }
  onSubmitForm(){
    this.submitTouched = true;
    console.log(this.addJourneyForm)
    if(!this.addJourneyForm.valid){
      for (var i in this.addJourneyForm.controls) {
        console.log(this.addJourneyForm.controls[i])
        this.addJourneyForm.controls[i].markAsTouched();
      }
      this.submitTouched=false;
      return false;
    }
    var formatDate =_.cloneDeep(this.addJourney)

    formatDate.startTime = moment(formatDate.startDateTime).tz(this.auth.getUserTimezone()).format('hh:mm A')

console.log(formatDate)

if(formatDate.startTime && formatDate.startDateTime){
  var finalStartTime = this.utilService.timeFormatter(formatDate.startTime, formatDate.startDateTime);
  formatDate.startDateTime = finalStartTime
}
console.log(formatDate)
    if(this.mode == 'add'){
      this.taskService.createJourney(formatDate).subscribe((res:any) => {
        console.log(res.body)
        if(res.status == 200 || res.status == 201){
          this.errorUtil.setErrorMessage(200, 'Journey created successfully' ,  null, 'success',1000);
          this.goBackTable()
          //this.router.navigate(['dashboard/tasks/journeys'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId}})
        } else {
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
          this.submitTouched = false;
        }
      })
    }else  if(this.mode === 'edit'){
      this.taskService.updateJourney(formatDate).subscribe((res:any) => {
        console.log("Response ", res);
        if(res.status == 200 || res.status == 201){
          this.errorUtil.setErrorMessage(200, 'Journey updated successfully' ,  null, 'success',1000);
          //this.router.navigate(['dashboard/tasks/journeys'], {queryParams: {'taskId' : this.taskId, 'userId' : this.userId}})
         this.goBackTable()
        } else {
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
          this.submitTouched = false;
        }
      })
    }


  }

  resetLocation(){

    this.getLocationByTask()
    this.customerData = _.filter(this.users, (o) => {
      return o.id == this.addJourney.userId;
     });
     console.log(this.customerData[0])
     this.setAddressByUserSelected()
    this.addJourney.startLocation.address = null;
    this.addJourney.startLocation.coordinate.latitude = null
    this.addJourney.startLocation.coordinate.longitude = null
    this.addJourney.fromTaskId = null

    this.addJourney.endLocation.address = null
    this.addJourney.endLocation.coordinate.latitude = null
    this.addJourney.endLocation.coordinate.longitude = null
    this.addJourney.toTaskId = null
    this.distanceData=''
    this.distanceKM = ''
    this.fromType ='current'
    this.toType = 'current'


  }
  setAddressByUserSelected(){
    this.homeAddress = null
    this.officeAddress = null

    if(this.customerData[0]){
      var homeAddress = '';
      var officeAddress = '';
      for (let key in this.customerData[0]) {
        if(key == 'homeStreet' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          homeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'homeCity' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          homeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'homeState' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          homeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'homePostcode' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          homeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'homeCountry' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          homeAddress += this.customerData[0][key]
        }

        if(key == 'officeStreet' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          officeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'officeCity' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          officeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'officeState' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          officeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'officePostcode' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          officeAddress += this.customerData[0][key] + ', '
        }
        if(key == 'officeCountry' && (this.customerData[0][key] !== null && this.customerData[0][key] !== '')){
          officeAddress += this.customerData[0][key]
        }
      }
      if(homeAddress != ''){
        var homeAdd = {address : homeAddress, placeId : this.customerData[0].homePlaceId, lat : this.customerData[0].homeLatitude, lng : this.customerData[0].homeLongitude}
       this.homeAddress =homeAdd
      }
      if(officeAddress != ''){
        var officeAdd = {address : officeAddress, placeId : this.customerData[0].officePlaceId, lat : this.customerData[0].officeLatitude, lng : this.customerData[0].officeLongitude}
        this.officeAddress = officeAdd
      }
    //console.log("officeAddress: ", this.officeAddress)
    //console.log("homeAddress: ", this.homeAddress)
    }

  }
  manualSearch(type :any){
    console.log(type)
    setTimeout(() =>{
      var addressFormatForm : any= {
        street_number: 'short_name',
        route: 'long_name',
        locality : 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
      };
      if(type == 'home'){
        if(!this.homePlaceChanged){
          this.addJourney.billingStreet = '';
          this.addJourney.billingCity = '';
          this.addJourney.billingState = '';
          this.addJourney.billingPostcode = '';
          this.addJourney.billingCountry = '';
          var geocoder = new google.maps.Geocoder;
          var address = this.addJourney.billingLocation;
          var self = this;
          geocoder.geocode({'address': address}, function(results: any, status : any) {
            if (status == google.maps.GeocoderStatus.OK) {
              console.log(results[0])
              if (results[0]) {
                self.addJourney.startLocation.address = results[0].formatted_address;
                self.addJourney.startLocation.coordinate.latitude = results[0].geometry.location.lat();;
                self.addJourney.startLocation.coordinate.longitude = results[0].geometry.location.lng()
                self.addJourney.fromTaskId = null
               
               // self.addJourney.billingPlaceId = results[0].place_id;
               // self.cdr.detectChanges();
              } else {
                //console.log('No results found');
              }
            } else {
              //console.log('Geocoder failed due to: ' + status);
            }
          });
        }
      } else if(type == 'office'){
        console.log(this.officePlaceChanged)
        if(!this.officePlaceChanged){
          this.addJourney.endLocation.address = null
          this.addJourney.endLocation.coordinate.latitude = '';
          this.addJourney.endLocation.coordinate.longitude = '';
         
          var geocoder = new google.maps.Geocoder;
          var address = this.addJourney.shippingLocation;
          var self = this;
          console.log(address,"---->>>>")
          geocoder.geocode({'address': address}, function(results : any , status : any) {
            if (status == google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                console.log('Results from Google', results[0]);
                self.addJourney.endLocation.address = results[0].formatted_address;
                self.addJourney.endLocation.coordinate.latitude = results[0].geometry.location.lat();;
                self.addJourney.endLocation.coordinate.longitude = results[0].geometry.location.lng();;

//                self.addJourney.shippingPlaceId = results[0].place_id;
                // self.cdr.detectChanges();
              } else {
                //console.log('No results found');
              }
            } else {
              //console.log('Geocoder failed due to: ' + status);
            }
          });
        }
      }
       console.log(this.addJourney)
    }, 1000);
  }

  counter =0;
  onDateChange(newDate: Date) {
    this.addJourney.startDateTime=newDate
   
      this.resetLocation()
      console.log(this.addJourney.startDateTime)
      this.getLocationByTask()

     
  }
 
   goBackTable(){
    this.goBackTablePage.emit();
   }
}
