import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class BreadcrumbService {

    private itemsSource = new Subject<MenuItemPlus[]>();
    private itemsUpadte = new Subject<MenuItemPlus[]>();
    private setupItemsSource = new Subject<MenuItem[]>();

    itemsHandler = this.itemsSource.asObservable();
    itemsPush = this.itemsUpadte.asObservable();
    setupItemsHandler = this.setupItemsSource.asObservable();


    setItems(items: MenuItemPlus[]) {
        
        this.itemsSource.next(items);
    }

    updateItems(items: MenuItemPlus[]) {
        this.itemsUpadte.next(items);
    }
    
    setSetupItems(items: MenuItemPlus[]) {
        this.setupItemsSource.next(items);
    }
}

export interface MenuItemPlus extends MenuItem {
    meta?: any
}
