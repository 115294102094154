<div class="left-right-space h-100">
    <div class="flex-between mb-2" *ngIf="noTicketHistory !== null && noTicketHistory === false">
        <div class="custella-dashboard-top-right d-flex ms-auto" *ngIf="noTicketHistory !== null && noTicketHistory === false">
            <button class="btn-list-icon ms-2" [pTooltip]="'refresh'|translate" tooltipPosition="bottom" id="btn_TixHistoryRefresh" (click)="refresh()">
                <i class="fa-regular fa-arrows-rotate"></i>
            </button>

            <span class="p-input-icon-left w-100 ms-2">
                <i class="pi pi-search me-3"></i>
                <input type="text" pInputText autocomplete="off" placeholder="Search" [(ngModel)]="searchValue" id="input_TixHistorySearchField" (keyup)="refresh()" />
            </span>
        </div>
    </div>

    <div *ngIf="noTicketHistory" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
            <img src="assets\svg\empty-state.svg" alt="No Records Found" id="img_NoRecordsFound" />
            <p class="text-6 my-1 font-weight-bold" translate>
                {{ "no_records_found" }}
            </p>
        </div>
    </div>

    <div [hidden]="noTicketHistory === null || noTicketHistory === true" class="position-relative fw-normal display-table" [style]="loadedDymanically ? 'height: calc(100vh - 17rem);' : 'height: calc(100% - 70px)'">
        <div [hidden]="noTicketHistory === null || noTicketHistory === true" class="table-space ag-grid-table-full" style="height: calc(100% - 16px)">
            <ag-grid-angular
                #agGrid
                style="height: 100%;"
                class="ag-theme-balham"
                [columnDefs]="columnDefsTicketHistory"
                [gridOptions]="gridOptionsTicketHistory"
                [defaultColDef]="defaultColDefTicketHistory"
                [maxConcurrentDatasourceRequests]="-1"
                [suppressDragLeaveHidesColumns]="true"
                [paginationPageSize]="25"
                [pagination]="false"
                [paginationPageSize]="25"
                (gridReady)="onGridReady($event)"
                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
            ></ag-grid-angular>
            <div class="col-12">
                <p class="ag-record-row-summary-panel padds text-end" id="label_TixHistTableRec">
                    {{ totalRecord }} {{'records' | translate}}
                </p>
            </div>
        </div>
    </div>
</div>
