import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssetService } from 'src/app/_services/asset.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';

@Component({
  selector: 'app-customer-summery-slider',
  templateUrl: './customer-summery-slider.component.html',
  styleUrls: ['./customer-summery-slider.component.scss']
})
export class CustomerSummerySliderComponent implements OnInit {

  @Input() CustomerSummary: any;
  @Output() onClosetAsset =  new EventEmitter();
  constructor( private util: UtilServiceService,
    private perm: PermissionService,
    private auth: AuthenticationService,
    private assetService: AssetService, ) {
     }

  ngOnInit(): void {
  }

  closeRightNav()
  {
    this.onClosetAsset.emit(true);
  }


  onCustomerViewOnNewTab()
  {localStorage.setItem("activeIndexCustomerView", "0");
          const id = this.CustomerSummary.id;
          const path = `/dashboard/customers/view/${id}?id=${id}`;
          this.util.openItemToNewTab(path);
  }
}
