import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  GridApi,
  GridOptions,
  GridParams,
  IGetRowsParams,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { Subscription, forkJoin } from "rxjs";
import { StatusRendererComponent } from "src/app/renderer/status-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { HelpdeskService } from "src/app/_services/helpdesk.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { ActionButtonRendererComponent } from "../action-button-renderer/action-button-renderer.component";
import {
  additionalColsExtras,
  assetsColumns,
  customersColumns,
  generalTicketsColumns,
  helpdeskColumnsArray,
  jobordersColumns,
  queueTicketColumns,
} from "../helpdesk-data/table-columns";

import * as moment from "moment-timezone";
import { TicketsService } from "src/app/_services/tickets.service";
import { AssetService } from "src/app/_services/asset.service";
import { CustomersService } from "src/app/_services/customers.service";
import { ServiceZoneService } from "src/app/_services/service-zone.service";

@Component({
  selector: "app-helpdesk-table",
  templateUrl: "./helpdesk-table.component.html",
  styleUrls: ["./helpdesk-table.component.scss"],
})
export class HelpdeskTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  subscriptions: Subscription[] = [];

  // the actual table columns
  public columnDefs: any[] = [];

  public allJobOrderStatus: any[] = [];

  // collumn options
  public defaultColDef = {
    enableRowGroup: false, //ag-Grid-Enterprise
    enablePivot: false, //ag-Grid-Enterprise
    enableValue: false, //ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: true,
    tooltipComponent: TableHeaderToolTipComponent,
  };

  public overlayNoRowsTemplate =
    '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  public blockLoadDebounceMillis = "100";
  public helpdeskTableStyle = {};

  public currentTableState: { [key: string]: any } = {
    general: {
      zeroResultsFound: false,
    },
    search: {
      searchItem: "",
      searchSelection: "",
    },

    ticketsTable: {
      operation: "all_tickets",
      isExpand: false,
      showAllBtn: false,
      isVisible: true,
      isActive: false,
      totalRowData: 0,
      tableStyle: {},
      sideBar: this.returnSidebarPanel(),
      showPagination: true,
      currentParamObj: {},
      gridAPI: <GridApi>{},
      gridParams: <GridParams>{},
      tableColumns: <any[]>[],
      rowData: [],
      getRowParams: <IServerSideGetRowsParams>{},
      gridOptions: {
        cacheBlockSize: 25,
        paginationPageSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params: any) => {
          // params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      },
      filterOn: false,
      filters: {
        dateRange: null,
        status: null,
        filterParams: <any>{},
      },
    },

    assetsTable: {
      isExpand: false,
      showAllBtn: false,
      isVisible: false,
      isActive: true,
      totalRowData: 0,
      tableStyle: {},
      sideBar: null,
      showPagination: true,
      tableColumns: assetsColumns,
      currentParamObj: {},
      gridAPI: <GridApi>{},
      gridParams: <GridParams>{},
      rowData: [],
      gridOptions: <GridOptions>{
        cacheBlockSize: 25,
        paginationPageSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params) => {
          // params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      },
      filterOn: false,
      filters: {
        customer: null,
        type: null,
        filterParams: <any>{},
      },
    },

    customersTable: {
      isExpand: false,
      showAllBtn: false,
      isVisible: false,
      isActive: true,
      totalRowData: 0,
      rowData: [],
      tableStyle: {},
      sideBar: null,
      showPagination: true,
      tableColumns: customersColumns,
      currentParamObj: {},
      gridParams: <GridParams>{},
      gridAPI: <GridApi>{},
      gridOptions: <GridOptions>{
        cacheBlockSize: 25,
        paginationPageSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params) => {
          // params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      },
      filterOn: false,
      filters: {
        serviceZone: null,
        categoryType: null,
        filterParams: <any>{},
      },
    },

    jobOrdersTable: {
      isExpand: false,
      showAllBtn: false,
      isVisible: false,
      isActive: true,
      totalRowData: 0,
      rowData: [],
      sideBar: null,
      showPagination: true,
      tableStyle: {},
      tableColumns: jobordersColumns,
      currentParamObj: {},
      gridParams: <GridParams>{},
      gridAPI: <GridApi>{},
      gridOptions: <GridOptions>{
        cacheBlockSize: 25,
        paginationPageSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params) => {
          // params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      },
      filterOn: false,
      filters: {
        customer: null,
        status: null,
        filterParams: <any>{},
      },
    },
  };

  public frameworkComponents = {
    ActionButtonRenderer: ActionButtonRendererComponent,
  };

  public joFrameworkComponents = {
    statusRenderer: StatusRendererComponent,
  };

  public allTicketStatus: any[] = [];
  public allAssetType: any[] = [];
  public allCustomers: any[] = [];
  public allServiceZones: any[] = [];
  public allCategoryTypes: any[] = [];

  public showingTable = {
    customer: false,
  };

  public currentSelectedTable = "";

  private allTables = [
    {
      name: "tickets",
    },
    {
      name: "assets",
    },
    {
      name: "customers",
    },
    {
      name: "jobOrders",
    },
  ];

  constructor(
    private utilService: UtilServiceService,
    private helpDeskService: HelpdeskService,
    private jobOrderService: JobOrdersService,
    private ticketService: TicketsService,
    private assetService: AssetService,
    private customerService: CustomersService,
    private serviceZone: ServiceZoneService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    this.cleanUp();
  }

  private preInit() {
    super.push(
      this.ticketService
        .getAllTicketStatus(true)
        .subscribe((allTicketStatus) => {
          this.allTicketStatus = allTicketStatus.sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }
  
            if (elementA?.order < elementB?.order) {
              return -1;
            }
  
            return 0;
          });
        })
    );

    super.push(
      this.assetService.getTypes(true).subscribe((allAssetTypes) => {
        this.allAssetType = allAssetTypes.body.sort((elementA: any, elementB: any) => {
          if (elementA?.order > elementB?.order) {
            return 1;
          }

          if (elementA?.order < elementB?.order) {
            return -1;
          }

          return 0;
        });
      })
    );

    super.push(
      this.serviceZone
        .getAllServiceZones()
        .subscribe((allServiceZones) => {
          this.allServiceZones = allServiceZones.sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }
  
            if (elementA?.order < elementB?.order) {
              return -1;
            }
  
            return 0;
          });
        })
    );

    super.push(
      this.customerService
        .getAllCustomerCategoryType()
        .subscribe((allCategoryTypes) => {
          this.allCategoryTypes = allCategoryTypes.sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }
  
            if (elementA?.order < elementB?.order) {
              return -1;
            }
  
            return 0;
          });
        })
    );
  }

  private init() {
    // start of the table
    this.onView();

    const sub_ = this.jobOrderService.getJobOrderStatuses().subscribe(
      (resp: any) => {
        if (resp) {
          this.allJobOrderStatus = resp.sort((elementA: any, elementB: any) => {
            if (elementA?.order > elementB?.order) {
              return 1;
            }
  
            if (elementA?.order < elementB?.order) {
              return -1;
            }
  
            return 0;
          });

          localStorage.setItem(
            "joStatuses",
            JSON.stringify(this.allJobOrderStatus)
          );
        }
      },
      (error: any) => {
        console.error(error);
      }
    );

    const sub = this.utilService.getData().subscribe(async (data: any) => {
      if (data) {
        // if (data.action === HELPDESK_APP_EVENTS.ON_SEARCH_RESULT) {
        //   const selectedOption = data.data.metaData.selectedOption as string;
        //   const searchItem = data.data.metaData.item as string;

        //   this.currentTableState.general.zeroResultsFound = false;

        //   this.processOnSearchResults(selectedOption, searchItem);
        // }

        if (data.action === HELPDESK_APP_EVENTS.ON_TAB_CHANGE) {
          this.toggleOffFilterAllTable();

          const type = data.data.type;
          const metaData = data.data.metaData;

          this.currentTableState.general.zeroResultsFound = false;

          if (type === "search") {
            const selectedOption = metaData.selectedOption;
            const searchItem = metaData.item;

            this.processOnSearchResults(selectedOption, searchItem);
          }
        }

        if (data.action === HELPDESK_APP_EVENTS.GET_TICKET_MENU) {
          this.toggleOffFilterAllTable();

          this.currentTableState.ticketsTable.operation = data.data.name;
          const parent = data.data.parent;
          this.currentTableState.general.zeroResultsFound = false;

          this.helpdeskTableStyle = {};

          Promise.all([
            this.setTable(
              "tickets",
              false,
              false,
              false,
              null,
              null,
              false,
              "reset",
              false,
              true
            ),
            this.setTable(
              "assets",
              false,
              false,
              false,
              null,
              null,
              false,
              "reset",
              false,
              false
            ),
            this.setTable(
              "customers",
              false,
              false,
              false,
              null,
              null,
              false,
              "reset",
              false,
              false
            ),
            this.setTable(
              "jobOrders",
              false,
              false,
              false,
              null,
              null,
              false,
              "reset",
              false,
              false
            ),
          ]).then(() => {
            // delay abit for onGrid to reinit
            setTimeout(() => {
              this.setTableColumns(
                parent,
                this.currentTableState.ticketsTable.operation
              ).then(() => {
                this.executeParams(
                  parent,
                  this.currentTableState.ticketsTable.operation
                );
              });
            }, 100);
          });
        }

        if (data.action == HELPDESK_APP_EVENTS.REMOVE_HELPDESK_TABLE_ROW) {
          // remove data at the index
          const index = data.data.rowIndex;
          const rowData = this.currentTableState.ticketsTable.rowData[index];

          // save the row data for undo (incase)
          this.helpDeskService.saveRowData(rowData, index);

          (this.currentTableState.ticketsTable.rowData as any[]).splice(
            index,
            1
          );

          this.setDatas("tickets", this.currentTableState.ticketsTable.rowData);

          // update ticket menu minus
          this.utilService.sendData({
            action: HELPDESK_APP_EVENTS.ON_UPDATE_TICKET_MENU_COUNT,
            data: {
              mathOperation: "-",
              value: 1,
              updateWhere: "queue",
            },
          });
        }

        if (data.action === HELPDESK_APP_EVENTS.UNDO_TICKET) {
          // add back the table row at its index
          const rowDataObject = this.helpDeskService.getRowData();

          this.currentTableState.ticketsTable.rowData.splice(
            rowDataObject.index,
            0,
            rowDataObject.rowData
          );

          this.setDatas("tickets", this.currentTableState.ticketsTable.rowData);

          // update ticket menu minus
          this.utilService.sendData({
            action: HELPDESK_APP_EVENTS.ON_UPDATE_TICKET_MENU_COUNT,
            data: {
              mathOperation: "+",
              value: 1,
              updateWhere: "queue",
            },
          });
        }

        if (data.action === HELPDESK_APP_EVENTS.ON_HELPDESK_ROUTE_CHANGE) {
          this.toggleOffFilterAllTable();

          this.setTable(
            "tickets",
            true,
            false,
            false,
            {
              height: "calc(100vh - 10rem)",
              // "padding-bottom": "1rem",
            },
            this.returnSidebarPanel(),
            false,
            "reset",
            false,
            true
          );
        }
      }
    });

    this.subscriptions.push(sub);
    this.subscriptions.push(sub_);
  }

  // when view is ready
  // for init tickets
  private async onView() {
    await this.setTable(
      "tickets",
      true,
      false,
      false,
      {
        height: "calc(100vh - 10rem)",
        // "padding-bottom": "1rem",
      },
      this.returnSidebarPanel(),
      false,
      "reset",
      false,
      true
    );

    // delay for a bit to let onGridReady run to set the params
    setTimeout(() => {
      // for init app, at start all tickets
      this.setTableColumns("my_tickets", "all_my_tickets").then(() => {
        this.executeParams("my_tickets", "all_my_tickets");
      });
    }, 1000);
  }

  // set table first before anything else
  // only for ticket table
  private setTableColumns(parent: string, operation: string): Promise<void> {
    return new Promise((resolve) => {
      // clear old columns
      if (
        this.currentTableState.ticketsTable.gridAPI &&
        "setColumnDefs" in this.currentTableState.ticketsTable.gridAPI
      ) {
        this.currentTableState.ticketsTable.gridAPI.setColumnDefs([]);
      }

      let finalColumn = [];
      finalColumn = [...generalTicketsColumns, ...additionalColsExtras];

      if (parent === "all_tickets" || parent === "my_tickets") {
        switch (operation) {
          case "all_tickets":
          case "open_&_pending_tickets":
          case "overdue_tickets":
          case "high_priority_tickets":
          case "unresolved_tickets":
          case "due_today":
          case "recently_updated_tickets":

          case "all_my_tickets":
          case "raised_by_me":
            if (parent === "my_tickets") {
              if (
                operation === "all_my_tickets" ||
                operation === "raised_by_me" ||
                operation === "due_today" ||
                operation === "unresolved_tickets" ||
                operation === "high_priority_tickets" ||
                operation === "overdue_tickets" ||
                operation === "open_&_pending_tickets"
              ) {
                // remove table owner column for my ticket section
                const copyOfGeneralTicketsColumns = [...generalTicketsColumns];
                const index = copyOfGeneralTicketsColumns.findIndex(
                  (gt) => gt.field === "ticketOwner.fullName"
                );
                copyOfGeneralTicketsColumns.splice(index, 1);

                finalColumn = [
                  ...copyOfGeneralTicketsColumns,
                  ...additionalColsExtras,
                ];
              }
            }
            break;

          default:
            break;
        }

        // set the columns
        if (
          this.currentTableState.ticketsTable.gridAPI &&
          "setColumnDefs" in this.currentTableState.ticketsTable.gridAPI
        ) {
          this.currentTableState["ticketsTable"].gridAPI.setColumnDefs(
            finalColumn
          );
        }
      }

      // for queue and recently viewed
      if (parent === null) {
        switch (operation) {
          case "queue":
            finalColumn = [...queueTicketColumns, ...additionalColsExtras];

            this.currentTableState.ticketsTable.tableColumns = finalColumn;
            break;

          case "recently_viewed":
            this.currentTableState.ticketsTable.tableColumns = finalColumn;
            break;

          default:
            break;
        }

        // set the columns
        if (
          this.currentTableState.ticketsTable.gridAPI &&
          "setColumnDefs" in this.currentTableState.ticketsTable.gridAPI
        ) {
          this.currentTableState["ticketsTable"].gridAPI.setColumnDefs(
            finalColumn
          );
        }
      }

      resolve();
    });
  }

  // set the individual params for each table
  public onGridReady(gridParams: any, tableType: string) {
    this.currentTableState[tableType + "Table"].gridParams = gridParams;
    this.currentTableState[tableType + "Table"].gridAPI = gridParams.api;
  }

  // process params
  // try to keep in order of code for object.assign to not override any exist prop and value that have been added in additional Params
  private processParamsObject(table: string, additionalParams?: any) {
    let finalParam = {};

    if (additionalParams) {
      finalParam = { ...additionalParams };

      if (table === "tickets") {
        finalParam = { ...finalParam };
      }
    }

    let startRow = 0;
    let page = 0;
    let perPage = 0;

    let filterModel = {};

    startRow = this.currentTableState[table + "Table"].getRowParams
      ? this.currentTableState[table + "Table"].getRowParams.startRow
      : 0;
    page = this.currentTableState[table + "Table"].gridAPI
      .paginationGetCurrentPage
      ? this.currentTableState[
          table + "Table"
        ].gridAPI.paginationGetCurrentPage()
      : 0;
    // perPage = this.currentTableState[table + "Table"].gridOptions
    //   .paginationPageSize
    //   ? this.currentTableState[table + "Table"].gridOptions.paginationPageSize
    //   : 25;
    perPage = additionalParams?.perPage ?? 25;

    const p = {
      startFrom: startRow ?? 0,
      // page: page,
      perPage: perPage,
    };

    // if got filter model
    if (
      this.currentTableState[table + "Table"].getRowParams &&
      Object.keys(this.currentTableState[table + "Table"].getRowParams).length >
        0
    ) {
      const fM =
        this.currentTableState[table + "Table"].getRowParams.filterModel ??
        this.currentTableState[table + "Table"].getRowParams.request
          .filterModel;

      filterModel = this.parseFilter(table, fM);
    }

    // check of sort of a column
    // needs to go last to override sorting and which column to sort
    const o = {
      sortOrder: "desc",
      columnName: table !== "jobOrders" ? "createdDate" : "createdDateTime",
    };

    if (
      this.currentTableState[table + "Table"].getRowParams &&
      Object.keys(this.currentTableState[table + "Table"].getRowParams)
        .length >= 1
    ) {
      const sort = this.currentTableState[table + "Table"].getRowParams
        .sortModel[0]
        ? this.currentTableState[table + "Table"].getRowParams.sortModel[0].sort
        : "desc";
      let col = "createdDate";

      if (table !== "jobOrders") {
        col = this.currentTableState[table + "Table"].getRowParams.sortModel[0]
          ? this.extractExactColumns(
              this.currentTableState[table + "Table"].getRowParams.sortModel[0]
                .colId,
              table
            )
          : "createdDate";
      } else {
        col = this.currentTableState[table + "Table"].getRowParams.sortModel[0]
          ? this.extractExactColumns(
              this.currentTableState[table + "Table"].getRowParams.sortModel[0]
                .colId,
              table
            )
          : "createdDateTime";
      }

      o.sortOrder = sort;
      o.columnName = col;
    }

    const last = { ...finalParam, ...o, ...p, ...filterModel };

    return last;
  }

  // for tickets table only
  private executeParams(parent: string, operation: string): void {
    let totalToShow = null;
    let showPagination = true;

    // for queue, recently viewed and all tickets + sub of all tickets
    if (parent === null || parent === "all_tickets") {
      switch (operation) {
        case "queue":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              isUnassignedTickets: true,
              sortOrder: "desc",
              columnName: "createdDate",
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "recently_viewed":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isRecentlyViewed: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          totalToShow =
            this.currentTableState.ticketsTable.gridOptions.paginationPageSize;
          showPagination = false;

          break;

        case "all_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "open_&_pending_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isOpenAndPendingTickets: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "overdue_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isOverdueTickets: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "high_priority_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isHighPriorityTickets: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "unresolved_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isUnresolvedTickets: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "due_today":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isDueToday: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });
          break;

        case "recently_updated_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "lastModifiedDate",
              isRecentlyUpdatedTickets: true,
              isAllTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          totalToShow =
            this.currentTableState.ticketsTable.gridOptions.paginationPageSize;
          showPagination = false;

          break;

        default:
          break;
      }
    }

    // for my tickets and sub of all my tickets
    if (parent === "my_tickets") {
      switch (operation) {
        case "all_my_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        case "open_&_pending_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isOpenAndPendingTickets: true,
              isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        case "overdue_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isOverdueTickets: true,
              isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        case "high_priority_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isHighPriorityTickets: true,
              isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        case "unresolved_tickets":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isUnresolvedTickets: true,
              isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        case "due_today":
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isDueToday: true,
              isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        case "raised_by_me":
          // commented out is all my tickets as not to point to logged in user?
          this.currentTableState.ticketsTable.currentParamObj =
            this.processParamsObject("tickets", {
              sortOrder: "desc",
              columnName: "createdDate",
              isRaisedByMe: true,
              // isAllMyTickets: true,
              columns: [
                "id",
                "subject",
                "description",
                "ticketNo",
                "rating",
                "externalId",
                "ticketOwner",
                "ticketStatus",
                "ticketType",
                "customer",
                "assets",
                "createdDate",
                "lastModifiedDate",
                "createdBy",
                "lastModifiedBy",
                "createdByUser",
                "lastModifiedByUser",
                "lastViewedDate",
                "channel",
                "contact",
                "department",
                "dueDate",
                "faultType",
                "priority",
                "resolution",
                "slaStatus",
                "userGroup",
                "slaPackages",
              ],
            });

          break;

        default:
          break;
      }
    }

    this.searchData(
      "tickets",
      this.currentTableState.ticketsTable.currentParamObj,
      totalToShow
    ).then((val) => {
      const isEmpty = this.isTableEmpty(val);

      if (!isEmpty) {
        this.currentTableState.general.zeroResultsFound = false;
        const style = {
          height: "calc(100vh - 10rem)",
          // "padding-bottom": "1rem",
        };
        const sideBar = this.returnSidebarPanel();

        this.setTable(
          "tickets",
          true,
          false,
          false,
          style,
          sideBar,
          false,
          "reset",
          false,
          showPagination
        );
      } else {
        this.currentTableState.general.zeroResultsFound = true;
      }
    });
  }

  // process on search results
  private async processOnSearchResults(
    selectedOption: string,
    searchItem: string
  ) {
    this.helpdeskTableStyle = {};

    this.currentTableState.search.searchItem = searchItem;
    this.currentTableState.search.searchSelection = selectedOption;

    let searchResultTicketsParams: any;
    let searchResultAssetsParams: any;
    let searchResultCustomersParams: any;
    let searchResultJobOrdersParams: any;

    switch (selectedOption) {
      case "all":
        searchResultTicketsParams = this.returnSearchResultParam(
          0,
          1,
          3,
          searchItem,
          this.returnSearchParamSpecifics("tickets")
        );
        searchResultAssetsParams = this.returnSearchResultParam(
          0,
          1,
          3,
          searchItem,
          this.returnSearchParamSpecifics("assets")
        );
        searchResultCustomersParams = this.returnSearchResultParam(
          0,
          1,
          3,
          searchItem,
          this.returnSearchParamSpecifics("customers")
        );
        searchResultJobOrdersParams = this.returnSearchResultParam(
          0,
          1,
          3,
          searchItem,
          this.returnSearchParamSpecifics("jobOrders")
        );

        // default style
        this.helpdeskTableStyle = {
          height: "calc(100vh - 10rem)",
          // "padding-bottom": "1rem",
        };

        // hide first
        await this.setTable(
          "tickets",
          false,
          false,
          false,
          null,
          null,
          false,
          "reset",
          false,
          false
        );
        await this.setTable(
          "assets",
          false,
          false,
          false,
          null,
          null,
          false,
          "reset",
          false,
          false
        );
        await this.setTable(
          "customers",
          false,
          false,
          false,
          null,
          null,
          false,
          "reset",
          false,
          false
        );
        await this.setTable(
          "jobOrders",
          false,
          false,
          false,
          null,
          null,
          false,
          "reset",
          false,
          false
        );
        setTimeout(() => {
          // only for ticket table reset columns
          this.currentTableState["ticketsTable"].gridAPI.setColumnDefs(
            generalTicketsColumns
          );

          Promise.all([
            this.searchData("tickets", searchResultTicketsParams, 3),
            this.searchData("assets", searchResultAssetsParams, 3),
            this.searchData("customers", searchResultCustomersParams, 3),
            this.searchData("jobOrders", searchResultJobOrdersParams, 3),
          ]).then((val) => {
            if (val && val.length > 0) {
              const isEmpty = this.isTableEmpty(val);

              // if at least one table has data show tables only - if not show no data image
              if (!isEmpty) {
                val.forEach(async (theVal) => {
                  const tableStyle = {
                    height: "120px",
                    width: "100%",
                    // "padding-bottom": "1rem",
                  };
                  const showAllBtn =
                    this.currentTableState[theVal + "Table"].totalRowData > 3 &&
                    this.currentTableState[theVal + "Table"].totalRowData !== 0;
                  this.setTable(
                    theVal,
                    true,
                    false,
                    true,
                    tableStyle,
                    null,
                    false,
                    "reset",
                    showAllBtn,
                    false
                  );
                });
              } else {
                this.currentTableState.general.zeroResultsFound = true;
              }
            }
          });
        }, 500);

        break;

      case "tickets":
      case "assets":
      case "customers":
      case "jobOrders":
        searchResultTicketsParams = this.returnSearchResultParam(
          0,
          1,
          this.currentTableState.ticketsTable.gridOptions.paginationPageSize,
          searchItem,
          this.returnSearchParamSpecifics("tickets")
        );
        searchResultAssetsParams = this.returnSearchResultParam(
          0,
          1,
          this.currentTableState.assetsTable.gridOptions.paginationPageSize,
          searchItem,
          this.returnSearchParamSpecifics("assets")
        );
        searchResultCustomersParams = this.returnSearchResultParam(
          0,
          1,
          this.currentTableState.customersTable.gridOptions.paginationPageSize,
          searchItem,
          this.returnSearchParamSpecifics("customers")
        );
        searchResultJobOrdersParams = this.returnSearchResultParam(
          0,
          1,
          this.currentTableState.jobOrdersTable.gridOptions.paginationPageSize,
          searchItem,
          this.returnSearchParamSpecifics("jobOrders")
        );

        let sideBar = this.returnSidebarPanel();

        let theParams: any = null;

        if (selectedOption === "tickets") {
          //
          sideBar = this.returnSidebarPanel();
          await this.setTable(
            "tickets",
            true,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "assets",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "customers",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "jobOrders",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );

          theParams = searchResultTicketsParams;
        }

        if (selectedOption === "assets") {
          //
          await this.setTable(
            "tickets",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "assets",
            true,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "customers",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "jobOrders",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );

          theParams = searchResultAssetsParams;
        }

        if (selectedOption === "customers") {
          //
          await this.setTable(
            "tickets",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "assets",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "customers",
            true,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "jobOrders",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );

          theParams = searchResultCustomersParams;
        }

        if (selectedOption === "jobOrders") {
          //
          await this.setTable(
            "tickets",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "assets",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "customers",
            false,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );
          await this.setTable(
            "jobOrders",
            true,
            false,
            false,
            null,
            null,
            false,
            "reset",
            false,
            false
          );

          theParams = searchResultJobOrdersParams;
        }

        setTimeout(() => {
          this.searchData(selectedOption, theParams, null).then(() => {
            const tableStyle = {
              height: "calc(100vh - 200px)",
              width: "100%",
            };

            this.setTable(
              selectedOption,
              true,
              false,
              false,
              tableStyle,
              sideBar,
              false,
              "reset",
              false,
              true
            );
          });
        }, 500);

        break;

      default:
        break;
    }
  }

  public async searchData(table: string, param: any, totalToShow: any) {
    return new Promise<string>((resolve) => {
      if ("hideOverlay" in this.currentTableState[table + "Table"].gridAPI) {
        this.currentTableState[table + "Table"].gridAPI.hideOverlay();
        this.currentTableState[table + "Table"].totalRowData = 0;
        this.currentTableState[table + "Table"].rowData = [];
      }

      var dataSource = {
        getRows: (getRowParams: IGetRowsParams) => {
          this.currentTableState[table + "Table"].getRowParams = getRowParams;

          // console.log("SEARCH DATA PARAMS START " + table, param);

          const params = this.processParamsObject(table, param);

          // console.log("SEARCH DATA PARAMS end " + table, params);

          this.helpDeskService
            .generalSearchItem(table, params)
            .subscribe((resp) => {
              // console.log("SEARCH RESULTS RESPONSE " + table, resp);

              const totalShow = totalToShow !== null ? totalToShow : resp.total;

              if (resp.data && resp.total > 0) {
                this.currentTableState[table + "Table"].totalRowData =
                  resp.total;

                this.currentTableState[table + "Table"].rowData = [
                  ...this.currentTableState[table + "Table"].rowData,
                  ...resp.data,
                ];

                getRowParams.successCallback(resp.data, totalShow);

                resolve(table);
              } else {
                this.currentTableState[table + "Table"].totalRowData = 0;
                this.currentTableState[table + "Table"].rowData = [];
                this.currentTableState[
                  table + "Table"
                ].gridAPI.showNoRowsOverlay();

                getRowParams.successCallback([], 0);

                resolve(table);
              }

              // console.log(this.currentTableState[table + "Table"]);
            });
        },
      };

      if (
        "setDatasource" in this.currentTableState[table + "Table"].gridAPI &&
        "sizeColumnsToFit" in this.currentTableState[table + "Table"].gridAPI
      ) {
        // console.log("SETTING DATASOURCE");

        this.currentTableState[table + "Table"].gridAPI.setDatasource(
          dataSource
        );

        this.currentTableState[table + "Table"].gridAPI.sizeColumnsToFit();
      }
    });
  }

  // search data results
  // public async searchData(table: string, param: any, totalToShow: any) {
  //   return new Promise<string>((resolve) => {
  //     if ("hideOverlay" in this.currentTableState[table + "Table"].gridAPI) {
  //       this.currentTableState[table + "Table"].gridAPI.hideOverlay();
  //       this.currentTableState[table + "Table"].totalRowData = 0;
  //       this.currentTableState[table + "Table"].rowData = [];
  //     }

  //     var dataSource = {
  //       getRows: (getRowParams: IGetRowsParams) => {
  //         this.currentTableState[table + "Table"].getRowParams = getRowParams;

  //         // console.log("SEARCH DATA PARAMS START " + table, param);

  //         const params = this.processParamsObject(table, param);

  //         // console.log("SEARCH DATA PARAMS end " + table, params);

  //         this.helpDeskService
  //           .generalSearchItem(table, params)
  //           .subscribe((resp) => {
  //             // console.log("SEARCH RESULTS RESPONSE " + table, resp);

  //             const totalShow = totalToShow !== null ? totalToShow : resp.total;

  //             if (resp.data && resp.total > 0) {
  //               this.currentTableState[table + "Table"].totalRowData =
  //                 resp.total;

  //               this.currentTableState[table + "Table"].rowData = [
  //                 ...this.currentTableState[table + "Table"].rowData,
  //                 ...resp.data,
  //               ];

  //               getRowParams.successCallback(resp.data, totalShow);

  //               resolve(table);
  //             } else {
  //               this.currentTableState[table + "Table"].totalRowData = 0;
  //               this.currentTableState[table + "Table"].rowData = [];
  //               this.currentTableState[
  //                 table + "Table"
  //               ].gridAPI.showNoRowsOverlay();

  //               getRowParams.successCallback([], 0);

  //               resolve(table);
  //             }

  //             console.log(this.currentTableState[table + "Table"]);
  //           });
  //       },
  //     };

  //     if (
  //       "setDatasource" in this.currentTableState[table + "Table"].gridAPI &&
  //       "sizeColumnsToFit" in this.currentTableState[table + "Table"].gridAPI
  //     ) {
  //       // console.log("SETTING DATASOURCE");

  //       this.currentTableState[table + "Table"].gridAPI.setDatasource(
  //         dataSource
  //       );

  //       this.currentTableState[table + "Table"].gridAPI.sizeColumnsToFit();
  //     }
  //   });
  // }

  // set table size
  public returnTableSize(table: string) {
    const currentTableRow =
      this.currentTableState[table + "Table"].rowData.length;

    // each row data
    const sizeRow = 28;
    const extra = 32;
    const max =
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize;

    let newHeight = "";

    if (currentTableRow < max) {
      newHeight = `${sizeRow * currentTableRow + extra}px`;
    } else {
      newHeight = "calc(100vh - 10rem)";
    }

    return newHeight;
  }

  // set new page size
  // thus triggering onGridReady method
  // public onPageSizeChanged($event: any, table: string) {
  //   const page = $event.target.value;

  //   this.currentTableState[table + "Table"].gridAPI.paginationSetPageSize(
  //     Number(page)
  //   );
  //   this.currentTableState[
  //     table + "Table"
  //   ].gridOptions.api.gridCore.rowModel.cacheParams.blockSize = Number(page);
  //   this.currentTableState[
  //     table + "Table"
  //   ].gridOptions.api.gridOptionsWrapper.setProperty(
  //     "cacheBlockSize",
  //     Number(page)
  //   );
  //   this.currentTableState[
  //     table + "Table"
  //   ].gridOptions.api.purgeServerSideCache();
  // }

  // returns sidebar panel
  private returnSidebarPanel() {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        // {
        //   id: "filters",
        //   labelDefault: "Filters",
        //   labelKey: "filters",
        //   iconKey: "filter",
        //   toolPanel: "agFiltersToolPanel",
        //   minWidth: 180,
        //   maxWidth: 400,
        //   width: 250,
        // },
      ],
    };
  }

  // for show all button per table
  public showAll(table: string): void {
    // toggle isExpand
    this.currentTableState[table + "Table"].isExpand =
      !this.currentTableState[table + "Table"].isExpand;
    const isExpand = this.currentTableState[table + "Table"].isExpand;

    // call api for full search
    let param = {};

    let totalToShow = null;

    if (isExpand) {
      param = {
        startFrom:
          this.currentTableState[table + "Table"].getRowParams.startRow,
        sortOrder: "desc",
        search: this.currentTableState.search.searchItem,
        // page: this.currentTableState[table + 'Table'].gridAPI.paginationGetCurrentPage(),
        perPage:
          this.currentTableState[table + "Table"].gridOptions
            .paginationPageSize,
      };

      param = Object.assign(param, this.returnSearchParamSpecifics(table));
    } else {
      // reset params for the table
      this.currentTableState[table + "Table"].currentParamObj = {};

      param = this.returnSearchResultParam(
        1,
        1,
        3,
        this.currentTableState.search.searchItem,
        this.returnSearchParamSpecifics(table)
      );

      totalToShow = 3;

      if (this.currentTableState[table + "Table"].filterOn) {
        this.currentTableState[table + "Table"].filterOn = false;
      }
    }

    this.searchData(table, param, totalToShow).then(() => {
      // set height
      const tableStyle = isExpand
        ? {
            height: this.returnTableSize(table),
            width: "100%",
            // "padding-bottom": "1rem",
          }
        : {
            height: "120px",
            width: "100%",
            // "padding-bottom": "1rem",
          };

      // toggle panel
      const sideBar = isExpand ? this.returnSidebarPanel() : null;
      const showAllBtn =
        this.currentTableState[table + "Table"].totalRowData > 3;

      // const showAllBtn =
      //   this.currentTableState[table + "Table"].totalRowData !== 0 &&
      //   this.currentTableState[table + "Table"].totalRowData > 3;
      const showPagination = isExpand ? true : false;

      // console.log("BEFORE", showAllBtn);

      this.setTable(
        table,
        true,
        isExpand,
        true,
        tableStyle,
        sideBar,
        false,
        "reset",
        showAllBtn,
        showPagination
      ).then(() => {
        setTimeout(() => {
          if (
            "sizeColumnsToFit" in
            this.currentTableState[table + "Table"].gridAPI
          ) {
            this.currentTableState[table + "Table"].gridAPI.sizeColumnsToFit();
          }
        }, 100);
      });
    });
  }

  private cleanUp() {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });

    super.ngOnDestroy();
  }

  // for ticket assign/claim tasks
  private setDatas(table: string, data: any[]) {
    var dataSource = {
      getRows: (getRowParams: IServerSideGetRowsParams) => {
        getRowParams.successCallback(data, data.length);
      },
    };

    this.currentTableState[table + "Table"].gridAPI.setDatasource(dataSource);
  }

  // set table and its option and other states
  private setTable(
    table: string,
    isVisible: boolean,
    isExpand: boolean,
    isActive: boolean,
    tableStyle: any,
    sideBar: any,
    toggleOverLay: boolean,
    filterActions: string,
    showAllBtn: boolean,
    showPagination: boolean
  ): Promise<void> {
    return new Promise((resolve) => {
      console.log("SETTING TABLE");

      // general state of table
      this.currentTableState[table + "Table"].isExpand = isExpand;
      this.currentTableState[table + "Table"].isVisible = isVisible;
      this.currentTableState[table + "Table"].tableStyle = tableStyle;
      this.currentTableState[table + "Table"].sideBar = sideBar
        ? sideBar
        : undefined;
      this.currentTableState[table + "Table"].isActive = isActive;
      this.currentTableState[table + "Table"].showAllBtn = showAllBtn;
      this.currentTableState[table + "Table"].showPagination = showPagination;

      // overlays
      if (toggleOverLay) {
        if (
          this.currentTableState[table + "Table"].gridAPI &&
          "showNoRowsOverlay" in this.currentTableState[table + "Table"].gridAPI
        ) {
          this.currentTableState[table + "Table"].gridAPI.showNoRowsOverlay();
        }
      } else {
        if (
          this.currentTableState[table + "Table"].gridAPI &&
          "hideOverlay" in this.currentTableState[table + "Table"].gridAPI &&
          toggleOverLay !== null
        ) {
          this.currentTableState[table + "Table"].gridAPI.hideOverlay();
        }
      }

      // filters of table
      if (filterActions === "reset") {
        if (
          this.currentTableState[table + "Table"].gridAPI &&
          "setFilterModel" in this.currentTableState[table + "Table"].gridAPI
        ) {
          this.currentTableState[table + "Table"].gridAPI.setFilterModel(null);
        }
      }

      resolve();
    });
  }

  // returns search result parameters
  private returnSearchResultParam(
    startFrom: number,
    page: number,
    perPage: number,
    searchItem: string,
    extraParams?: any
  ): any {
    let param = {
      startFrom: startFrom,
      sortOrder: "desc",
      search: searchItem,
      // page: page,
      perPage: perPage,
    };

    if (extraParams) {
      param = Object.assign(param, extraParams);
    }

    return param;
  }

  private returnSearchParamSpecifics(table: string) {
    if (table === "tickets") {
      return {
        isGlobalSearch: true,
        isAllTickets: true,
        columnName: "createdDate",
        columns: [
          "id",
          "subject",
          "description",
          "ticketNo",
          "rating",
          "externalId",
          "ticketOwner",
          "ticketStatus",
          "ticketType",
          "customer",
          "assets",
          "createdDate",
          "lastModifiedDate",
          "createdBy",
          "lastModifiedBy",
          "createdByUser",
          "lastModifiedByUser",
          "lastViewedDate",
          "channel",
          "contact",
          "department",
          "dueDate",
          "faultType",
          "priority",
          "resolution",
          "slaStatus",
          "userGroup",
          "slaPackages",
        ],
      };
    }

    if (table === "assets") {
      return {
        columnName: "createdDate",
        columns: [
          " id",
          "name",
          "serialNo",
          "installationDate",
          "customer",
          "location",
          "status",
          "assetTypeName",
          "assetStatusName",
          "nextMaintenanceDateTime",
          "manufacturer",
          "runningNo",
          "assetsCode",
          "equipmentNumber",
          "serviceZone",
          "assetsType",
          "assetsStatus",
          "model",
          "warrantyStartDate",
          "warrantyEndDate",
        ],
      };
    }

    if (table === "customers") {
      return {
        columnName: "createdDate",
        columns: [
          "id",
          "name",
          "email",
          "billingCity",
          "billingCountry",
          "billingPostcode",
          "billingState",
          "billingStreet",
          "shippingCity",
          "shippingCountry",
          "shippingPostcode",
          "shippingState",
          "shippingStreet",
          "billingLatitude",
          "billingLongitude",
          "isDeleted",
          "phone",
          "geoFence",
          "systemUsed",
          "customerSfExternalId",
          "customerExternalId",
          "shippingLatitude",
          "shippingLongitude",
          "serviceZone",
          "categoryType",
          "contactPersonName",
          "customerReferenceNo",
        ],
      };
    }

    if (table === "jobOrders") {
      return {
        columnName: "createdDateTime",
        columns: [
          "id",
          "jobOrderNumber",
          "jobOrderName",
          "installationDate",
          "installationTime",
          "completionDate",
          "completionTime",
          "status",
          "jobExternalId",
          "createdDateTime",
          "modifiedDateTime",
          "createdBy",
          "modifiedBy",
          "customer",
          "jobOrderType",
          "ticket",
          "assets",
        ],
      };
    }
  }

  // when cell table is clicked
  public onClickCell($event: any) {
    this.utilService.resetTabView();

    let data = $event.data;
    let id = null;

    const navigationTo = $event.column.colDef.hasOwnProperty("metaData")
      ? $event.column.colDef.metaData.navigationTo
      : null;

    if (navigationTo === null) {
      return;
    } else {
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.CLEAR_HELPDESK_COMPONENT,
        data: null,
      });
    }

    if (navigationTo === "tickets") {
      id = data.id;
      let name = data.ticketNo;

      this.helpDeskService
        .viewATicket({
          id,
        })
        .subscribe((resp) => {
          // console.log(resp);
        });

      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_TICKET_CLICK,
        data: {
          type: "tickets",
          item: name,
          metaData: { id },
        },
      });
    }

    if (navigationTo === "joTickets") {
      id = data.ticket.id;
      let name = data.ticket.ticketNo;

      this.helpDeskService
        .viewATicket({
          id,
        })
        .subscribe((resp) => {
          // console.log(resp);
        });

      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_TICKET_CLICK,
        data: {
          type: "tickets",
          item: name,
          metaData: { id },
        },
      });
    }

    if (navigationTo === "assets") {
      id = data?.assets?.id ?? data.id;
      this.utilService.openItemToNewTab(
        `/dashboard/assets/view/${id}?id=${id}`
      );
    }

    if (navigationTo === "customers") {
      id = data?.customer?.id ?? data.id;

      this.utilService.openItemToNewTab(
        `/dashboard/customers/view/${id}?id=${id}`
      );
    }

    if (navigationTo === "jobOrders") {
      id = data.id;
      localStorage.setItem("activeIndexJobOrder","0");
      this.utilService.openItemToNewTab(
        `/dashboard/jobOrders/view/${id}?jobOrderId=${id}`
      );
    }

    if (navigationTo === "users") {
      id = data.lastModifiedByUser.id;
      this.utilService.openItemToNewTab(
        `/dashboard/setup/allUser/view?id=${id}&edit:false`
      );
    }
  }

  private extractExactColumns(columnToCheck: string, forTable: string): string {
    let theColumn = null;

    for (let sm = 0; sm < helpdeskColumnsArray.length; sm++) {
      const tableFor = helpdeskColumnsArray[sm].table;
      if (tableFor.some((t: any) => t == forTable)) {
        if (helpdeskColumnsArray[sm].field === columnToCheck) {
          theColumn = helpdeskColumnsArray[sm].label;
          break;
        }
      }
    }

    return theColumn !== null ? theColumn : columnToCheck;
  }

  private parseFilter(table: string, filterModel: any) {
    const keyFilter = Object.keys(filterModel);
    let filterColumn = "";

    let param = {};

    keyFilter.forEach((filter) => {
      filterColumn = this.extractExactColumns(filter, table);
      // console.log('Filter Column', filterColumn);

      if (
        filterColumn === "status" ||
        filterColumn === "ticketStatus" ||
        filterColumn === "customerName" ||
        filterColumn === "assetsTypeName" ||
        filterColumn === "serviceZoneName" ||
        filterColumn === "categoryTypeName" ||
        filterColumn === "joStatus"
      ) {
        param = Object.assign(param, {
          [filterColumn]: filterModel[filter].filter,
        });
      }

      if (filterColumn === "createdDate") {
        const startDate = filterModel.createdDate.dateFrom || "";
        const endDate = filterModel.createdDate.dateTo || "";
        let dateParam = {};

        if (startDate !== "") {
          const start = startDate.split(" ")[0];
          dateParam = Object.assign(dateParam, {
            startDateTime: start,
          });
        }

        if (endDate !== "") {
          const end = endDate.split(" ")[0];
          dateParam = Object.assign(dateParam, {
            endDateTime: end,
          });
        }

        param = Object.assign(param, dateParam);
      }
    });
    return param;
  }

  public onGridSizeChange($event: any, table: string): void {
    if (
      this.currentTableState[table + "Table"].gridAPI &&
      "sizeColumnsToFit" in this.currentTableState[table + "Table"].gridAPI
    ) {
      this.currentTableState[table + "Table"].gridAPI.sizeColumnsToFit();
    }
  }

  public getContextMenuItems = (params: any) => {
    this.utilService.resetTabView();

    const navigationTo = params.column.colDef.metaData
      ? params.column.colDef.metaData.navigationTo
      : "";
    let url = "";
    let id = 0;
    let result: any[] = [
      {
        name: "Open link in new tab",
        action: () => this.utilService.openItemToNewTab(url),
        //cssClasses: ['redFont', 'bold'],
        //icon: createFlagImg('fr'),
      },
    ];

    if (navigationTo === "tickets") {
      id = params.node.data.id;
      url = `/dashboard/tickets/view/${id}?id=${id}`;

      return result;
    }

    if (navigationTo === "joTickets") {
      id = params.node.data.ticket.id;
      url = `/dashboard/tickets/view/${id}?id=${id}`;

      return result;
    }

    if (navigationTo === "customers") {
      id = params.node.data.id;
      url = `/dashboard/customers/view/${id}?id=${id}`;

      return result;
    }

    if (navigationTo === "assets") {
      id = params.node.data.id;
      url = `/dashboard/assets/view/${id}?id=${id}`;

      return result;
    }

    if (navigationTo === "users") {
      id = params.node.data.lastModifiedByUser.id;
      url = `/dashboard/setup/allUser/view${id}?id=${id}&edit:false`;

      return result;
    }

    if (navigationTo === "jobOrders") {
      id = params.node.data.id;
      url = `/dashboard/jobOrders/view/${id}?jobOrderId=${id}`;

      return result;
    }

    return result;
  };

  // checks if table is empty
  private isTableEmpty(val: any) {
    let totalDataOfAllTable = 0;

    if (val && typeof val === "object" && val.length > 0) {
      for (let v = 0; v < val.length; v++) {
        totalDataOfAllTable +=
          this.currentTableState[val[v] + "Table"].totalRowData;
      }
    } else {
      totalDataOfAllTable = this.currentTableState[val + "Table"].totalRowData;
    }

    if (totalDataOfAllTable === 0) {
      return true;
    }

    return false;
  }

  // this can work but needs to update ag-grid to latest
  // private sortColumnTool() {
  //   const columnsToolPanel = this.gridAPI.getToolPanelInstance("columns");
  //   const sortedColumnDefs = [...this.columnDefs].sort((cd1, cd2) => {
  //     if (cd1.headerName < cd2.headerName) return -1;
  //     if (cd1.headerName > cd2.headerName) return 1;
  //     return 0;
  //   });
  //   // set custom Columns Tool Panel layout
  //   columnsToolPanel.setColumnLayout(sortedColumnDefs);
  // }

  public onToggleFilter(table: string) {
    this.currentTableState[table + "Table"].filterOn =
      !this.currentTableState[table + "Table"].filterOn;
  }

  public onDateChange($event: any, table: string) {
    // console.log($event, table, this.currentTableState[table + "Table"]);

    if (
      this.currentTableState[table + "Table"].filters &&
      this.currentTableState[table + "Table"].filters.dateRange
    ) {
      const dateRange =
        this.currentTableState[table + "Table"].filters.dateRange;

      if (dateRange && dateRange[0] && dateRange[1]) {
        let param = this.returnSearchResultParam(
          0,
          1,
          this.currentTableState[table + "Table"].gridOptions
            .paginationPageSize,
          this.currentTableState.search.searchItem,
          this.returnSearchParamSpecifics(table)
        );

        const startDateTime = moment(dateRange[0]).format("YYYY-MM-DD");

        const endDateTime = moment(dateRange[1]).format("YYYY-MM-DD");

        param = Object.assign(param, { startDateTime, endDateTime });

        this.currentTableState[table + "Table"].filters.filterParams =
          Object.assign(
            this.currentTableState[table + "Table"].filters.filterParams,
            param
          );

        this.searchData(
          table,
          this.currentTableState[table + "Table"].filters.filterParams,
          null
        );
      }
    }
  }

  public onStatusChange($event: any, table: string) {
    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    if (
      this.currentTableState[table + "Table"].filters &&
      this.currentTableState[table + "Table"].filters.status
    ) {
      const status = this.currentTableState[table + "Table"].filters.status;

      if (status && status !== "") {
        if (table === "tickets") {
          param = Object.assign(param, {
            ticketStatus: String(status).toLowerCase(),
          });
        }

        if (table === "jobOrders") {
          param = Object.assign(param, {
            joStatus: String(status).toLowerCase(),
          });
        }

        this.currentTableState[table + "Table"].filters.filterParams =
          Object.assign(
            this.currentTableState[table + "Table"].filters.filterParams,
            param
          );

        this.searchData(
          table,
          this.currentTableState[table + "Table"].filters.filterParams,
          null
        );
      }
    } else {
      if (
        this.currentTableState[table + "Table"].filters.filterParams &&
        this.currentTableState[
          table + "Table"
        ].filters.filterParams.hasOwnProperty("ticketStatus")
      ) {
        delete this.currentTableState[table + "Table"].filters.filterParams
          .ticketStatus;
      }

      if (
        this.currentTableState[table + "Table"].filters.filterParams &&
        this.currentTableState[
          table + "Table"
        ].filters.filterParams.hasOwnProperty("joStatus")
      ) {
        delete this.currentTableState[table + "Table"].filters.filterParams
          .joStatus;
      }

      this.searchData(
        table,
        this.currentTableState[table + "Table"].filters.filterParams,
        null
      );
    }
  }

  public clearTable(table: string, justClear = false) {
    if (
      this.currentTableState[table + "Table"] &&
      this.currentTableState[table + "Table"].filters
    ) {
      if (table === "tickets") {
        this.currentTableState[table + "Table"].filters.dateRange = null;
        this.currentTableState[table + "Table"].filters.status = null;
      }

      if (table === "assets") {
        this.currentTableState[table + "Table"].filters.customer = null;
        this.currentTableState[table + "Table"].filters.type = null;
      }

      if (table === "customers") {
        this.currentTableState[table + "Table"].filters.serviceZone = null;
        this.currentTableState[table + "Table"].filters.categoryType = null;
      }

      if (table === "jobOrders") {
        this.currentTableState[table + "Table"].filters.customer = null;
        this.currentTableState[table + "Table"].filters.status = null;
      }

      this.currentTableState[table + "Table"].filters.filterParams = {};
    }

    if (justClear) {
      return;
    }

    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    this.searchData(table, param, null);
  }

  public onTypeChange($event: any, table: string) {
    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    if (
      this.currentTableState[table + "Table"].filters &&
      this.currentTableState[table + "Table"].filters.type
    ) {
      const type = this.currentTableState[table + "Table"].filters.type;

      if (type && type !== "") {
        param = Object.assign(param, { assetsTypeName: type });

        this.currentTableState[table + "Table"].filters.filterParams =
          Object.assign(
            this.currentTableState[table + "Table"].filters.filterParams,
            param
          );

        this.searchData(
          table,
          this.currentTableState[table + "Table"].filters.filterParams,
          null
        );
      }
    } else {
      if (
        this.currentTableState[table + "Table"].filters.filterParams &&
        this.currentTableState[
          table + "Table"
        ].filters.filterParams.hasOwnProperty("assetsTypeName")
      ) {
        delete this.currentTableState[table + "Table"].filters.filterParams
          .assetsTypeName;
      }

      this.searchData(
        table,
        this.currentTableState[table + "Table"].filters.filterParams,
        null
      );
    }
  }

  /******************************************** On Customer Select Value ******************************************/
  public onCustomerSelect(event: any, table: string) {
    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    if (
      this.currentTableState[table + "Table"] &&
      this.currentTableState[table + "Table"].filters.hasOwnProperty("customer")
    ) {
      this.currentTableState[table + "Table"].filters.customer = event;
      const name =
        this.currentTableState[table + "Table"].filters.customer.name;

      param = Object.assign(param, { customerName: name });

      this.currentTableState[table + "Table"].filters.filterParams =
        Object.assign(
          this.currentTableState[table + "Table"].filters.filterParams,
          param
        );

      this.searchData(
        table,
        this.currentTableState[table + "Table"].filters.filterParams,
        null
      );
    }

    this.closeTable("customer");
  }

  public closeTable(what: string) {
    if (what === "customer") {
      this.showingTable.customer = false;
    }
  }

  /******************************************** Get All Customer By Query ******************************************/
  public getCustomer(event: any) {
    const query = event.query.replace(
      /[&\/\\#,+()$~%!.„'":*‚^_¤?<>|@ª{«»§}©®™ ]/g,
      ""
    );

    if (query && query.length > 2) {
      this.push(
        this.customerService
          .getAllCustomerByName(query)
          .subscribe((customers: any) => {
            this.allCustomers = customers;
          })
      );
    }
  }

  /******************************************** Show Table Dynamic Picklist ******************************************/
  public showTable(what: string, table: string) {
    this.currentSelectedTable = table;

    if (what === "customer") {
      this.showingTable.customer = true;
    }
  }

  /******************************************** Clear Value Dynamic Picklist ******************************************/
  public clearValue(what: string, table: string) {
    if (what === "customer") {
      this.currentTableState[table + "Table"].filters.customer = null;
    }

    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    this.currentTableState[table + "Table"].filters.filterParams =
      Object.assign(
        this.currentTableState[table + "Table"].filters.filterParams,
        param
      );

    if (
      this.currentTableState[table + "Table"].filters.filterParams &&
      this.currentTableState[
        table + "Table"
      ].filters.filterParams.hasOwnProperty("customerName")
    ) {
      delete this.currentTableState[table + "Table"].filters.filterParams
        .customerName;
    }

    this.searchData(
      table,
      this.currentTableState[table + "Table"].filters.filterParams,
      null
    );
  }

  public onServiceZoneChange($event: any, table: string) {
    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    if (
      this.currentTableState[table + "Table"].filters &&
      this.currentTableState[table + "Table"].filters.serviceZone
    ) {
      const serviceZone =
        this.currentTableState[table + "Table"].filters.serviceZone;

      if (serviceZone && serviceZone !== "") {
        param = Object.assign(param, { serviceZoneName: serviceZone });

        this.currentTableState[table + "Table"].filters.filterParams =
          Object.assign(
            this.currentTableState[table + "Table"].filters.filterParams,
            param
          );

        this.searchData(
          table,
          this.currentTableState[table + "Table"].filters.filterParams,
          null
        );
      }
    } else {
      if (
        this.currentTableState[table + "Table"].filters.filterParams &&
        this.currentTableState[
          table + "Table"
        ].filters.filterParams.hasOwnProperty("serviceZoneName")
      ) {
        delete this.currentTableState[table + "Table"].filters.filterParams
          .serviceZoneName;
      }

      this.searchData(
        table,
        this.currentTableState[table + "Table"].filters.filterParams,
        null
      );
    }
  }

  public onCategoryTypeChange($event: any, table: string) {
    let param = this.returnSearchResultParam(
      0,
      1,
      this.currentTableState[table + "Table"].gridOptions.paginationPageSize,
      this.currentTableState.search.searchItem,
      this.returnSearchParamSpecifics(table)
    );

    if (
      this.currentTableState[table + "Table"].filters &&
      this.currentTableState[table + "Table"].filters.categoryType
    ) {
      const categoryType =
        this.currentTableState[table + "Table"].filters.categoryType;

      if (categoryType && categoryType !== "") {
        param = Object.assign(param, { categoryTypeName: categoryType });

        this.currentTableState[table + "Table"].filters.filterParams =
          Object.assign(
            this.currentTableState[table + "Table"].filters.filterParams,
            param
          );

        this.searchData(
          table,
          this.currentTableState[table + "Table"].filters.filterParams,
          null
        );
      }
    } else {
      if (
        this.currentTableState[table + "Table"].filters.filterParams &&
        this.currentTableState[
          table + "Table"
        ].filters.filterParams.hasOwnProperty("categoryTypeName")
      ) {
        delete this.currentTableState[table + "Table"].filters.filterParams
          .categoryTypeName;
      }

      this.searchData(
        table,
        this.currentTableState[table + "Table"].filters.filterParams,
        null
      );
    }
  }

  private toggleOffFilterAllTable() {
    this.allTables.forEach((table: any) => {
      if (this.currentTableState[table.name + "Table"].filters && this.currentTableState[table.name + "Table"].filterOn) {
        this.clearTable(table.name, true);
        this.currentTableState[table.name + "Table"].filterOn = false;
      }
    })
  }
}
