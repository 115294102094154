import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { VehicleService } from 'src/app/_services/vehicle.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { ModifyPlanComponent } from '../modify-plan/modify-plan.component';
import { PlanUpdateConfirmationComponent } from '../plan-update-confirmation/plan-update-confirmation.component';

@Component({
  selector: 'app-modify-change-vehicle-driver',
  templateUrl: './modify-change-vehicle-driver.component.html'
})
export class ModifyChangeVehicleDriverComponent implements OnInit {
  title: any;
data: any;

showVehicleDropDown = false;
displayTable1: boolean = false;
vehicleName: any= null;
orderNumber: any;
deliveryOrderId: any;
vehicleId: any;
vehicleData: any;
vehicles: any;
notInVehicleIds: any = [];
changeVehicleForm : UntypedFormGroup;
vehicles2: any = [];
searchVehicle: any;
existingVehicleData: any;

modalState : any;
  selectedConfig : any;
  selectedComponent : any;
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  };
  selectedVehicleData: any = null
  constructor(public modalRef : BsModalRef,
    private auth: AuthenticationService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private vehicleService: VehicleService) {
      this.changeVehicleForm = new UntypedFormGroup({
        'vehicle': new UntypedFormControl(null, [Validators.required]),
        'vehicle1': new UntypedFormControl(null, [Validators.required])
      })
     }

  ngOnInit(): void {
    for(var i=0;i<=this.data.routes.length-1;i++){
      this.notInVehicleIds.push(this.data.routes[i].vehicleData.vehicle.id)
    }
    console.log('notInVehicleIds',this.notInVehicleIds)
    for(var i=0;i<=this.data.routes.length-1;i++){
      if(!this.data.routes[i].locked){
        this.vehicles2.push(this.data.routes[i].vehicleData);
      }
    }
  }
  
   //vehicle search
  search2($event : any){
    
    console.log($event.value)
    this.searchVehicle = $event.value ? $event.value.vehicle.vehicleNumber: null;
    if(this.searchVehicle){
      var arr = this.vehicles2.filter((item : any) => (item.vehicle.vehicleNumber==this.searchVehicle));
      
      this.existingVehicleData = arr[0].vehicle;
      this.existingVehicleData.defaultDriver = arr[0].defaultDriver;
    }
    else{
      this.existingVehicleData ='';
    }
  }

switch(){
  
  if(!this.changeVehicleForm.valid){
    for (var i in this.changeVehicleForm.controls) {
      this.changeVehicleForm.controls[i].markAsTouched();
    }
    return false;
  }
  // else{
   this.openModal('changeVehicle','');
  //}
}

openModal(modalView : any, data: any) {
  this.modalRef.hide();
   if (modalView == "changeVehicle") {
    for(var i=0;i<=this.data.routes.length-1;i++){
      if(this.data.routes[i].vehicleData.vehicle.vehicleNumber==this.searchVehicle){
        var routeId =this.data.routes[i].id;
        // var vehicleName =this.data.routes[i].vehicleData.vehicle.name;
        var oldVehicleNumber =this.data.routes[i].vehicleData.vehicle.vehicleNumber;
        var oldDriverName =this.data.routes[i].vehicleData.defaultDriver.user.fullName;
      }
    }
   var data1 = {btnName:'changeVehicle',planId:this.data.draftPlanId,vehicleId:this.vehicleId,routeId:routeId,oldVehicleNumber:oldVehicleNumber,oldDriverName:oldDriverName,newVehicleNumber:this.vehicleData.vehicleNumber,newDriverName:this.vehicleData.defaultDriver.user.fullName}
    this.modalState = {
      data: data1,
      title:'Switch Vehicle'
    };
    this.selectedConfig = this.configSM;
    this.selectedComponent = PlanUpdateConfirmationComponent;
  }else if (modalView == "modifyPlan") {
    this.modalState = {
      title: "Modify Plan",
      mode: "add",
      data : data
    };
    this.selectedConfig = this.configCustomLg;
    this.selectedComponent = ModifyPlanComponent;
  }
  else{
    return;
  }
  this.selectedConfig.initialState = this.modalState;
  return this.modalRef = this.modalService.show(this.selectedComponent, this.selectedConfig);
}


///////////////////////////////////// start(Vehicle search component) /////////////////////

closeVehicleDropDown() {
  this.showVehicleDropDown = !this.showVehicleDropDown;
}
gotSelectedVehicle(val: any){
  this.selectVehicleValue(val);
  this.displayTable1 =false
}
closeVehicleView(){
  this.displayTable1 =false
}
showVehicleTable(){
  this.displayTable1 = !this.displayTable1;
}
selectVehicleValue(value: any) {
  
  this.vehicleId = value.id;
  this.vehicleName = value;
  this.vehicleData = value;
  this.showVehicleDropDown = false;
  this.vehicles=[];
}
clearVehicleValue() {
  this.vehicleId = null;
  this.vehicleName = null;
  this.vehicleData = null;
  this.showVehicleDropDown = false;
  this.vehicles = [];
}
onValidVehicle(){
 if(this.vehicleId==null){
   this.vehicleName = null;
 }
}
getVehicleIdsFirstWay(event: any) {
  var k = event.keyCode
  if (k == 8) {
    this.clearVehicleValue();
  }
  else {
    if (this.vehicleName != null && this.vehicleName.length > 2) {
      var params= { 
        sortModel :[{'colId': 'vehicleNumber', 'sort': 'asc'}],
        startRow:0 }
    this.vehicleService .getVehiclesList(
              null,
              this.vehicleName,
              null,
              null,
              params,
              this.notInVehicleIds,
              null,
              null
            )
            .subscribe((data: any) => {
             
              this.vehicles = data.data;
               this.spinner.hide();
            });

      
    }
  }
}
}
