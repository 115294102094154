<div class="custella-login" (click)="onClickBackground()">
    <div class="container">
        <div class="row">
            <!-- <div class="col-sm-9 col-md-7 col-lg-4 mx-auto"> -->
            <div class="mx-auto">
              <div class="card border-0 shadow rounded-3 my-5">
                <div class="card-body py-4 px-3 px-sm-3 py-sm-4">
                    <span>
                        <img src="../../../assets/svg/FieldEx_L_T.svg" alt="FieldEx Logo">
                    </span>
                    <h3 class="card-title font-bold pt-5 pb-4">{{'create_new_pwd'|translate}}</h3>
                    <form (ngSubmit)="activateAccount()" [formGroup]="accountForm">
                        <div class="form-group">
                            <label>{{'new_password'|translate}}</label>
                            <span class="p-input-icon-left p-input-icon-right">
                                <i class="fa-regular fa-lock-keyhole pe-none"></i>
                                <input pInputText [(ngModel)]="profile.password" id="input_PwdActivateNewPwdField" [type]="fieldTextType ? 'text' : 'password'" formControlName="password" name="password" (input)="getPassowrdLength($event)">
                                <i class="pi" [ngClass]="{'pi-eye-slash': !fieldTextType,'pi-eye': fieldTextType}" id="btn_PwdActivateNewPwd"(click)="toggleFieldTextType()"></i>
                            </span>
                            <div *ngIf="accountForm.controls['password'].dirty && accountForm.controls['password'].invalid">
                                <span  class="p-error text-10" id="label_PwdActivateNewPwdReqField" *ngIf="accountForm.controls['password'].errors?.required" translate>{{'required_field'}}</span>
                                <!-- <span  class="p-error text-10" id="label_PwdActivateNewPwdMustBe" *ngIf="accountForm.controls['password'].errors?.pattern" translate>{{'password_must_be'}}</span> -->
                            </div>
                            <div class="pwd-valid" *ngIf="showtooltip || isPasswordHasUserName">
                                <div class="pwd-valid-body">
                                    <div class="pb-1">
                                        <span>{{'pwd_contain_least'|translate}}&colon;</span>
                                    </div>
                                    <div class="flex-align-center pb-1">
                                        <span class="w-12px lh-1">
                                            <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('^(?=.*[A-Z])')"></i>
                                            <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('^(?=.*[A-Z])')"></i>
                                        </span>
                                        <span class="ms-2"[ngClass]="{'text-success':passwordFormField?.value?.match('^(?=.*[A-Z])')}">{{'one_uppercase'|translate}}</span>
                                    </div>
                                    <div class="flex-align-center pb-1">
                                        <span class="w-12px lh-1">
                                            <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('^(?=.*[a-z])')"></i>
                                            <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('^(?=.*[a-z])')"></i>
                                        </span>
                                        <span class="ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('^(?=.*[a-z])')}">{{'one_lowercase'|translate}}</span>
                                    </div>
                                    <div class="flex-align-center pb-1">
                                        <span class="w-12px lh-1">
                                            <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('(.*[0-9].*)')"></i>
                                            <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('(.*[0-9].*)')"></i>
                                        </span>
                                        <span class="ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('(.*[0-9].*)')}">{{'one_numeric'|translate}}</span>
                                    </div>
                                    <div class="flex-align-center pb-1">
                                        <span class="w-12px lh-1">
                                            <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"></i>
                                            <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"></i>
                                        </span>
                                        <span class="ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">{{'one_symbol'|translate}}</span>
                                    </div>
                                    <div class="flex-align-center pb-1">
                                        <span class="w-12px lh-1">
                                            <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('.{8,}')"></i>
                                            <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('.{8,}')"></i>
                                        </span>
                                        <span class="ms-2"  [ngClass]="{'text-success':passwordFormField?.value?.match('.{8,}')}">{{'eight_char_min'|translate}}</span>
                                    </div>
                                    <div class="flex-align-center" >
                                        <span class="w-12px lh-1">
                                            <i class="fa-regular fa-check text-success" *ngIf="!isPasswordHasUserName"></i>
                                            <i class="fa-regular fa-xmark p-error" *ngIf="isPasswordHasUserName"></i>
                                        </span>
                                        <span class="ms-2" [ngClass]="{'text-success':!isPasswordHasUserName}">{{'must_not_contain_username'|translate}}</span>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div class="form-group">
                            <label>{{'confirm_password'|translate}}</label>
                            <span class="p-input-icon-left p-input-icon-right">
                                <i class="fa-regular fa-lock-keyhole pe-none"></i>
                                <input pInputText [(ngModel)]="profile.cPassword" id="input_PwdActivateRetypePwdField" [type]="fieldTextType2 ? 'text' : 'password'" (input)="getCPassowrdLength($event)" formControlName="cPassword" name="cPassword">
                                <i class="pi" [ngClass]="{'pi-eye-slash': !fieldTextType2,'pi-eye': fieldTextType2}" id="btn_PwdActivateRetypePwd"(click)="toggleFieldTextType2()"></i>
                            </span>
                            <div *ngIf="accountForm.controls['cPassword'].dirty && accountForm.controls['cPassword'].invalid">
                                <span class="p-error text-10" id="label_PwdActivateRetypePwdReqField" *ngIf="accountForm.controls['cPassword'].errors?.required" translate>{{'required_field'}}</span>
                                <!-- <span class="p-error text-10" id="label_PwdActivateRetypePwdMustBe" *ngIf="accountForm.controls['cPassword'].errors?.pattern" translate>{{'password_must_be'}}</span> -->
                            </div>
                            <div *ngIf="!isPasswordMatching && profile.cPassword.length > 0">
                                <span class="p-error text-10" id="label_PwdActivateRetypePwdReqField">{{"pwd_not_match"|translate}}</span>
                                <!-- <span class="p-error text-10" id="label_PwdActivateRetypePwdMustBe" *ngIf="accountForm.controls['cPassword'].errors?.pattern" translate>{{'password_must_be'}}</span> -->
                            </div>
                        </div>

                        <div class="mt-4">
                            <button type="submit" class="custella-btn-long" id="btn_PwdActivateSubmit">
                                <span class="text-12">{{'create'|translate}}</span>
                            </button>
                        </div>
                    </form>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>



<!-- <div class="vh-100 overflow-hidden">
    <div class="d-flex vh-100">
        <div class="left-form">
            <form (ngSubmit)="activateAccount()" [formGroup]="accountForm">
                <div class="container">
                    <div class="custella-login p-5">
                        <img src="assets\images\custella_logo.png" />
                        <div class="text-20 font-medium" id="title_PwdActivation" translate>{{'create_new_pwd'}}</div>
                        <div class="custella-login-form">
                            <div class="mb-3">
                                <span class="mb-2 text-12 font-medium" id="label_PwdActivateNewPwd" translate>{{'new_password'}}</span>
                                <span class="p-input-icon-right w-100">
                                    <input pInputText [(ngModel)]="profile.password" id="input_PwdActivateNewPwdField" [type]="fieldTextType ? 'text' : 'password'" placeholder="{{'new_password' | translate}}" formControlName="password" name="password" (input)="getPassowrdLength($event)">

                                    <i class="pi" [ngClass]="{
                                        'pi-eye-slash': !fieldTextType,
                                        'pi-eye': fieldTextType}" id="btn_PwdActivateNewPwd"
                                      (click)="toggleFieldTextType()"></i>
                                </span>

                                <div class="alert col-12 px-0 py-0 text-right custella-content-row-input" *ngIf="accountForm.controls['password'].dirty && accountForm.controls['password'].invalid">
                                    <small class="custella-content-row-input-sub p-error" id="label_PwdActivateNewPwdReqField" *ngIf="accountForm.controls['password'].errors?.required" translate>{{'required_field'}}</small>
                                    <small class="custella-content-row-input-sub p-error" id="label_PwdActivateNewPwdMustBe" *ngIf="accountForm.controls['password'].errors?.pattern" translate>{{'password_must_be'}}</small>
                                  </div>
                            </div>
                            <div class="mb-3">
                                <span class="mb-2 text-12 font-medium" id="label_PwdActivateRetypePwd" translate>{{'retype_pwd'}}</span>
                                <span class="p-input-icon-right w-100">
                                    <input pInputText [(ngModel)]="profile.cPassword" id="input_PwdActivateRetypePwdField" [type]="fieldTextType2 ? 'text' : 'password'" (input)="getCPassowrdLength($event)" placeholder="{{'confirm_new_password' | translate}}" formControlName="cPassword" name="cPassword">

                                    <i class="pi" [ngClass]="{
                                        'pi-eye-slash': !fieldTextType2,
                                        'pi-eye': fieldTextType2}" id="btn_PwdActivateRetypePwd"
                                      (click)="toggleFieldTextType2()"></i>
                                </span>

                                <div class="alert col-12 px-0 py-0 text-right custella-content-row-input" *ngIf="accountForm.controls['cPassword'].dirty && accountForm.controls['cPassword'].invalid">
                                    <small class="custella-content-row-input-sub p-error" id="label_PwdActivateRetypePwdReqField" *ngIf="accountForm.controls['cPassword'].errors?.required" translate>{{'required_field'}}</small>
                                    <small class="custella-content-row-input-sub p-error" id="label_PwdActivateRetypePwdMustBe" *ngIf="accountForm.controls['cPassword'].errors?.pattern" translate>{{'password_must_be'}}</small>
                                  </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button type="submit" class="custella-btn-long" id="btn_PwdActivateSubmit">
                                <label translate>{{'create'}}</label>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="w-75" [ngStyle]="{background:'var(--surface-300)'}">
            <div class="right-form">
                <div class="custella-quote">
                    <div class="custella-quote-text">
                        “<span class="custella-quote-text-bold">Refine</span> the way your <span class="custella-quote-text-bold">Time</span> is spent, to <span class="custella-quote-text-bold">Achieve</span> more towards a greater
                        <span class="custella-quote-text-bold">Purpose”</span>
                        <br />
                        <span class="custella-quote-text-small"> – Custella Team</span>
                    </div>
                    <div class="custella-quote-img">
                        <img src="assets\images\login_view.png" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
