<div class="modal-header p-dialog-header flex-between">
  <div>
    <span class="text-16 font-medium ms-3" id="title_TaskCheckInMap">{{title}}</span>
  </div>
  <i class="pi pi-times w-24px" id="btn_TaskCheckInMapClose" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body d-flex" *ngIf="data">
  <div class="col p-0 taskMap" style="height:400px">
    <!-- Map -->
    <google-map #plannedMap height="100%" width="100%" [center]="optionsMap.center" [zoom]="zoom">
      <div class="flex-align-center">
        <map-marker #trackingUserData="mapMarker" *ngFor="let trackingUser of markers;let i = index"
          id="btn_TaskSelUserInfo" (mapClick)="openInfoWindow(trackingUserData, trackingUser)" [position]="trackingUser"
          [options]="trackingUser" zIndex="20"
          [icon]="{url: trackingUser.icon,labelOrigin:pointer, scaledSize: scaledSize}"> </map-marker>
        <map-info-window>
          <div [hidden]="!displayActual" class="custella-form-container-marker m-0 h-100 rounded-2">
            <div class="custella-form-container-marker-title" style="padding-left: 0.75rem; padding-right: 0.75rem;">
              <div class="flex-between">
                <span class="text-12 font-semiBold me-2" id="value_TaskSelUserName">{{selectedUserdata?.name}}</span>
              </div>
            </div>
               <!-- Name and Email -->
               <div class="custella-form-container-marker-content font-black px-2 m-0 pb-2" *ngIf="selectedUserdata?.startTime">
                <div class="custella-content-row">
                            <div class="custella-content-row-input">
                              <div class="d-flex align-items-center">
                                  <img src="assets/svg/table/clock.svg" alt="Order Time" class="w-24px">
                                  <span class="text-12 font-medium ms-2" id="value_TaskMapSelUserDateTime">{{selectedUserdata?.startTime | momentDate :'hh:mm a' : timezone}} - {{selectedUserdata?.endTime | momentDate :'hh:mm a' : timezone}}</span>
                              </div>
                            </div>
                        </div>
    
                        <div class="custella-content-row">
                            <div class="custella-content-row-input col-12">
                              <div class="d-flex">
                                  <img src="assets/svg/table/map-marker-black.svg" id="img_TaskMapSelUserLoc" alt="Location" class="w-24px">
                                  <span class="text-12 font-medium ms-2 text-wrap" id="value_TaskMapSelUserLoc">{{selectedUserdata?.location}}</span>
                              </div>
                            </div>
                        </div>
                </div>
            
          </div>
  
        </map-info-window>

      </div>
    </google-map>
  </div>
</div>