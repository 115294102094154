import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { VehicleService } from "src/app/_services/vehicle.service";

import * as moment from "moment-timezone";
import * as _ from "lodash";

import { ErrorUtil } from "src/app/_utilities/error";
import { Address } from "src/app/shared/ngx-google-places-autocomplete/objects/address";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { AppEvents } from "src/app/_models/global.data.model";
import { PermissionService } from "src/app/auth/permission.service";

declare var google: any;

@Component({
  selector: "app-add-edit-last-location",
  templateUrl: "./add-edit-last-location.component.html",
  styleUrls: ["./add-edit-last-location.component.scss"],
})
export class AddEditLastLocationComponent implements OnInit {
  public defaultDate = new Date();
  timezone!: string;
  dateSearch!: any;
  vehicleLocation: any;
  vehicleLocationForm: UntypedFormGroup[] = [];
  selectedRange: any;
  mode!: string;
  submitTouched!: boolean;
  dateConfig!: { dateInputFormat: string };
  data: any;

  update: any;

  constructor(
    private auth: AuthenticationService,
    private vehicleService: VehicleService,
    private spinner: NgxSpinnerService,
    public modalRef: BsModalRef,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService,
    private perm: PermissionService
  ) {}

  ngOnInit() {
    this.init();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    console.log(this.data);

    this.update = this.perm.capable("vehicles", "update");

    this.timezone = this.auth.getUserTimezone();
    this.dateSearch = this.data.selectedDate;
    this.getVehicleLocationList();
  }

  /******************************************** On Date Change ******************************************/
  public onDateChange(event: any) {
    this.selectedRange = event;
    this.dateSearch = moment(this.selectedRange).format("YYYY-MM-DD");

    this.filterResults();
  }

  /******************************************** Get Data ******************************************/
  private filterResults() {
    this.getVehicleLocationList();
  }

  /******************************************** Get Vehicle Location List ******************************************/
  private getVehicleLocationList() {
    this.spinner.show();
    var changeDate = moment(this.dateSearch).format("YYYY-MM-DD");
    this.vehicleService
      .getVehicleLocationList(this.timezone, changeDate)
      .subscribe((data: any) => {
        this.vehicleLocation = data;
        this.spinner.hide();

        this.addValidationTime();
      });
  }

  /******************************************** Get Data ******************************************/
  private addValidationTime() {
    this.vehicleLocation.forEach((element: any, ind: any) => {
      if (element.arrivalTime == null) {
        element.arrivalTime1 = null;
      } else {
        element.arrivalTime1 = moment(element.arrivalTime).format("HH:mm");
      }

      this.vehicleLocationForm[ind] = new UntypedFormGroup({
        arrivalTime: new UntypedFormControl(null),
        location: new UntypedFormControl(null),
      });
      console.log(element.arrivalTime1, "======", element.arrivalTime);
    });
  }

  /******************************************** Setup Validation ******************************************/
  public setupValidation(ind: any) {
    if (this.vehicleLocation[ind].address != "") {
      this.vehicleLocationForm[ind].controls["arrivalTime"].setValidators([
        Validators.required,
      ]);
      this.vehicleLocationForm[ind].controls[
        "arrivalTime"
      ].updateValueAndValidity();
    } else {
      this.vehicleLocationForm[ind].controls["arrivalTime"].clearValidators();
      this.vehicleLocationForm[ind].controls[
        "arrivalTime"
      ].updateValueAndValidity();
    }
  }

  /******************************************** Location ******************************************/
  public setStartLocation(val: any, ind: any) {
    var geocoder = new google.maps.Geocoder();
    var self = this;
    if (val == "current") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          //console.log(position);
          var latlng = { lat: latitude, lng: longitude };
          geocoder.geocode(
            { location: latlng },
            function (results: any, status: any) {
              if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                  results = _.remove(results, function (n: any) {
                    return n.geometry.location_type === "APPROXIMATE";
                  });
                  self.vehicleLocation[ind].address =
                    results[0].formatted_address;
                  self.vehicleLocation[ind].locationLat = latitude;
                  self.vehicleLocation[ind].locationLong = longitude;
                } else {
                }
              } else {
              }
            }
          );
          this.setupValidation(ind);
        });
      }
    } else if (val == "home") {
      var address: any;

      this.vehicleLocation[ind].address =
        "SNL Logistics & Marketing, 40, Jalan 1, Pandan Indah Industrial Park, 55100 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur";
      this.vehicleLocation[ind].locationLat = "3.132637";
      this.vehicleLocation[ind].locationLong = "101.765012";

      this.setupValidation(ind);
    }
  }

  /******************************************** Reset Address ******************************************/
  public resetAddress(val: any) {
    this.vehicleLocation[val].address = "";
    (this.vehicleLocation[val].locationLat = ""),
      (this.vehicleLocation[val].locationLong = "");
    this.setupValidation(val);
  }

  /******************************************** Handle Address ******************************************/
  public handleAddressChange(address: Address, val: any) {
    // Do some stuff
    this.vehicleLocation[val].address = address.formatted_address;
    if (address.types.includes("establishment")) {
      this.vehicleLocation[val].address =
        address.name + ", " + address.formatted_address;
    } else {
      this.vehicleLocation[val].address = address.formatted_address;
    }

    this.vehicleLocation[val].locationLat = address.geometry.location.lat();
    this.vehicleLocation[val].locationLong = address.geometry.location.lng();
    this.setupValidation(val);
  }

  /******************************************** Submit Form ******************************************/
  public submitForm() {
    this.submitTouched = true;
    var check = _.filter(this.vehicleLocationForm, (o) => {
      return o.status == "INVALID";
    });

    if (check.length > 0) {
      this.vehicleLocationForm.forEach((form) => {
        if (!form.valid) {
          for (var i in form.controls) {
            form.controls[i].markAsTouched();
          }
        }
      });
      this.submitTouched = false;
      return;
    }

    this.vehicleLocation.forEach((element: any) => {
      if (element.arrivalTime1) {
        var arrivalStartTime = moment(
          moment(this.dateSearch).format("YYYY-MM-DD") +
            " " +
            element.arrivalTime1
        );

        var startTimeData = moment
          .tz(arrivalStartTime, this.auth.getUserTimezone())
          .toDate();

        console.log(arrivalStartTime, startTimeData);

        // element.arrivalTime = new Date(startTimeData).toISOString();
        element.arrivalTime =
          moment(startTimeData).toISOString().split(".")[0] + "Z";
      }

      if (element.createdBy) {
        delete element.createdBy;
      }

      if (element.createdDate) {
        delete element.createdDate;
      }

      if (element.lastModifiedBy) {
        delete element.lastModifiedBy;
      }

      if (element.lastModifiedDate) {
        delete element.lastModifiedDate;
      }

      if (element.vehicle) {
        const id = element.vehicle.id;
        element.vehicle = {
          id,
        };
      }
    });

    if (this.mode == "add") {
      this.vehicleService
        .createVehicleLocation(this.vehicleLocation)
        .subscribe((res: any) => {
          if (res.status == 200 || res.status == 201) {
            this.util.sendData({
              action: AppEvents.REFRESH_LAST_LOCATION,
              data: null,
            });

            this.modalRef.hide();
          } else {
            this.errorUtil.setErrorMessage(
              res.status,
              null,
              res.error.title,
              "error",
              2000
            );
          }
        });
    }
  }
}
