import { Component, OnInit } from "@angular/core";
import { IAfterGuiAttachedParams } from "ag-grid-community";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-action-button-renderer",
  templateUrl: "./action-button-renderer.component.html",
  styleUrls: ["./action-button-renderer.component.scss"],
})
export class ActionButtonRendererComponent implements OnInit {
  public permissions: any;
  private agParams: any;

  constructor(
    private utilService: UtilServiceService,
    private authService: AuthenticationService,
    private perm: PermissionService
  ) {}

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: any): void {
    this.agParams = params;
    this.permissions = this.perm.capable("tickets", "update");
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

  ngOnInit() {}

  /******************************************** when user click assign or claim ******************************************/
  public onClick($event: any, action: string) {
    const row =
      $event.target.parentElement.parentElement.parentElement.parentElement;

    // console.log($event);
    // console.dir(row);

    // console.log($event.clientX);

    let x = this.utilService.rounding($event.clientX - ($event.clientX - row.offsetLeft));
    let y = this.utilService.rounding($event.clientY);

    // console.log('Final', x, y);

    let dataParam = {
      action,
      coordinates: {
        x: x,
        y: y,
      },
      rowData: {
        rowIndex: this.agParams.rowIndex,
        data: this.agParams.data,
      },
    };

    if (action === "assign") {
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_HELPDESK_TICKET_ASSIGN,
        data: dataParam,
      });
    }

    if (action === "claim") {
      dataParam = Object.assign(dataParam, {
        assigneeUser: { id: Number(this.authService.getUserId()) },
      });

      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_HELPDESK_TICKET_CLAIM,
        data: dataParam,
      });
    }
  }
}
