<div class="custella-form-container left-right-space rounded-2 mt-0 mb-3">
    <ng-container>
        <div class="custella-form-container-title flex-between rounded-top">
            <div class="font-medium text-14" translate>{{ "iqc_details" }}</div>
            <div *ngIf="(iqcData?.iqcReportAttachment != null)" class="pr-4 d-flex align-items-center margin-right">
                <img style="width: 20px;height:25px" src="../../../assets/images/pdf.png" alt="Generate PDF"
                    placement="left" tooltip="Generate PDF" (click)="downloadForm(iqcData?.iqcReportAttachment)">
            </div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "asset" }}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="iqcData?.assets"
                        (click)="viewPage(iqcData?.assets?.id,'assets')">
                        {{ iqcData?.assets?.name ?? "-" }}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="!iqcData?.assets">-</p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "Location" }}</span>
                    <p class="custella-content-row-input-title-three">{{ iqcData?.location ?? "-" }}</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "Customer (Damage claim)" }}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="iqcData?.customer"
                        (click)="viewPage(iqcData?.customer?iqcData?.customer?.id:-1,'customer')">
                        {{iqcData?.customer?.name?? "-"}}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="!iqcData?.customer">-</p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "Subsidiary (Repair service)"
                        }}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="iqcData?.subsidiary"
                        (click)="viewPage(iqcData?.subsidiary?iqcData?.subsidiary?.id:-1,'customer')">
                        {{iqcData?.subsidiary?.name?? "-"}}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="!iqcData?.subsidiary">-</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "status" }}</span>
                    <span [attr.style]="'background-color:' + colorCode + '50 !important;'" class="p-mr-2 p-tag-open p-tag p-component"><span class="p-tag-value">{{fieldLabel}}</span></span>
                     </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "iqc_type" }}</span>
                    <p class="custella-content-row-input-title-three">{{iqcData?.iqcType? iqcData?.iqcType: "-"}}</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "breakdown_service" }}</span>
                    <p class="custella-content-row-input-title-three">{{iqcData?.breakDownService?
                        iqcData?.breakDownService: "-"}}</p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two"></span>
                    <p class="custella-content-row-input-title"></p>
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Equipment Details -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "equipment_information" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "equipment_type" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ iqcData?.equipmentType?.name ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "equipment_number" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.equipmentNumber ?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "manufacturer"}}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.Manufacturer?? "-"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "make_model" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.model?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "serial_number" }}</span>
                    <p class="custella-content-row-input-title-three"> {{iqcData?.serialNo?? "-"}}</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_number" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.engineNumber?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_number_two" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.engineNumber2?? "-"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_make" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.engineMake?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_model" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.engineModel?? "-"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "current_value_hours" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{iqcData?.equipmentMeterReading?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate></span>
                    <p class="custella-content-row-input-title-three"></p>
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Hour Meter -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "hour_meter" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "new_value_hours" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ iqcData?.newMeterReading ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Service Order -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "service_order" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6" *ngIf="iqcData?.serviceOrderJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "repair_service_order" }}</span>
                    <div class="d-flex">
                        <p class="custella-content-row-input-title-three-link"
                            *ngIf="!serviceOrderJobOrders?.jobOrder?.deleted"
                            (click)="viewPage(iqcData?.serviceOrderJobOrder?iqcData?.serviceOrderJobOrder?.id:-1,'jobOrderNumber')">
                            {{iqcData?.serviceOrderJobOrder?.jobOrderNumber?? "-"}}
                        </p>
                        <div *ngIf="!serviceOrderJobOrders?.jobOrder?.deleted">
                            <span class="ms-2 p-tag p-component" [style.backgroundColor]="serviceOrderJobOrders?.jobOrder.statusColor"><span class="p-tag-value">{{serviceOrderJobOrders?.jobOrder?.statusLabel}}</span></span>
                        </div>
                        
                        <p class="custella-content-row-input-title-three"
                            *ngIf="serviceOrderJobOrders?.jobOrder?.deleted">
                            {{iqcData?.serviceOrderJobOrder?.jobOrderNumber}}
                            <strong style="color:#948e8ed6;font-style: italic;">(Deleted)</strong>
                        </p>
                    </div>
                </div>
                <div class="custella-content-row-input col-6" *ngIf="!iqcData?.serviceOrderJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "repair_service_order" }}</span>
                    <p class="custella-content-row-input-title-three">-</p>
                </div>
                <div class="custella-content-row-input col-6" *ngIf="iqcData?.damageClaimsJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "damaged_service_order" }}</span>
                    <div class="d-flex">
                        <p class="custella-content-row-input-title-three-link"
                            *ngIf="!damageClaimsJobOrders?.jobOrder?.deleted"
                            (click)="viewPage(iqcData?.damageClaimsJobOrder?iqcData?.damageClaimsJobOrder?.id:-1,'jobOrderNumber')">
                            {{iqcData?.damageClaimsJobOrder?.jobOrderNumber?? "-"}}
                        </p>
                        <div *ngIf="!damageClaimsJobOrders?.jobOrder?.deleted">
                            <div *ngIf="!damageClaimsJobOrders?.jobOrder?.deleted"> 
                                <span class="ms-2 p-tag p-component" [style.backgroundColor]="damageClaimsJobOrders?.jobOrder.statusColor"><span class="p-tag-value">{{damageClaimsJobOrders?.jobOrder?.statusLabel}}</span></span>
                            </div>
                        </div>
                        <p class="custella-content-row-input-title-three"
                            *ngIf="damageClaimsJobOrders?.jobOrder?.deleted">
                            {{iqcData?.damageClaimsJobOrder?.jobOrderNumber}}
                            <strong style="color:#948e8ed6;font-style: italic;">(Deleted)</strong>
                        </p>
                    </div>
                </div>
                <div class="custella-content-row-input col-6" *ngIf="!iqcData?.damageClaimsJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "damaged_service_order" }}</span>
                    <p class="custella-content-row-input-title-three">-</p>
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Final Quality Check -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "final_quality_check" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "FQC"}}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="iqcData?.fqcData != null"
                        (click)="viewPage(iqcData?.fqcData?._id,'iqc')">
                        {{iqcData?.fqcData?.runningNumber?? "-"}}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="iqcData?.fqcData == null">-</p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Equipment Checklist -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "equipment_checklist" }}</div>
        </div>
        
        <div *ngFor="let data of questionList;let i = index">
            <div class="custella-form-container-title-two-grey">
                <div class="font-medium text-14" translate>{{data?.name}}</div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12" *ngFor="let question of data.detail">
                        <span class="custella-content-row-input-title-two" translate>{{question.question.question}}</span>
                       <div *ngFor="let answer of question.question.answers">
                        <p class="custella-content-row-input-title-three"  *ngIf="question?.answer && question?.answer?._id== answer?._id">{{ answer.answer }}</p>
                    </div>
                    </div>
                   
                </div>
            </div>
        </div>

    </ng-container>
    <!--#################################### Remarks -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "remarks" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "Remarks" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ iqcData?.remark ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### System Information -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "system_information" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{'created_by'}}</span>
                    <div class="custella-vp-picture d-flex align-items-center">
                        <p-avatar *ngIf="iqcData?.createdByUser?.imageUrl"
                            [image]="iqcData?.createdByUser?.imageUrl" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                        <p-avatar *ngIf="!iqcData?.createdByUser?.imageUrl"
                            image="../../../../assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>

                        <span class="custella-content-row-input-title-three-link mx-2"
                            (click)="viewUser(iqcData?.createdByUser?.id, false)">{{iqcData?.createdByUser?.firstName}}
                            {{iqcData?.createdByUser?.lastName}},</span>
                        <span class="custella-content-row-input-title-three"> {{iqcData?.createdDate}}</span>
                    </div>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{'last_modified_by'}}</span>
                    <div class="custella-vp-picture d-flex align-items-center">
                        <p-avatar *ngIf="iqcData?.modifiedByUser?.imageUrl"
                            [image]="iqcData?.modifiedByUser?.imageUrl" styleClass="mr-2 mt-2" shape="circle">
                        </p-avatar>
                        <p-avatar *ngIf="!iqcData?.modifiedByUser?.imageUrl"
                            image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>

                        <span class="custella-content-row-input-title-three-link mx-2"
                            (click)="viewUser(iqcData?.modifiedByUser?.id, false)">{{iqcData?.modifiedByUser?.firstName}}
                            {{iqcData?.modifiedByUser?.lastName}},</span>
                        <span class="custella-content-row-input-title-three">{{iqcData?.lastModifiedDate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>