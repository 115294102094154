import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { TicketsService } from "src/app/_services/tickets.service";

@Component({
  selector: "app-custella-tickets",
  templateUrl: "./custella-tickets.component.html",
  styleUrls: ["./custella-tickets.component.scss"],
})
export class CustellaTicketsComponent implements OnInit {
  @Output() ticketSelector = new EventEmitter();
  @Output() closeTicketView = new EventEmitter();

  public searchValue = "";

  constructor(private ticketService: TicketsService) {
    this.ticketService.getSelectTicket().subscribe((data) => {
      if (data) {
        this.selectCustomer(data);
      }
    });
  }

  ngOnInit(): void {}

  selectCustomer(val: any) {
    this.ticketSelector.emit(val);
  }

  public closeTicketTable() {
    this.closeTicketView.emit(false);
  }
}
