<div>
    <div class="flex-between">
      <i class="pi pi-times w-24px dialog-close-icon" (click)="close()"></i>
    </div>
    <div class="position-relative text-start">
      <div class="d-flex align-items-center">
        <span class="p-input-icon-left w-100 pb-2">
          <i class="pi pi-search"></i>
          <input type="text" pInputText autocomplete="off"  [placeholder]="'type_ticket' | translate" [(ngModel)]="searchValue" />
        </span>
      </div>
      <div class="table-space" style="height: 40vh;">
          <app-vehicle-types-table [searchValue]="searchValue" (itemSelector)="itemSelected($event)" (closeView)="onClose($event)"></app-vehicle-types-table>
      </div>
    </div>
  </div>
  