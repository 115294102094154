import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PERSISTANT_ITEM } from 'src/app/_models/global.data.model';
import { MenuService } from 'src/app/_services/menu.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-setup-menu-item',
  templateUrl: './setup-menu-item.component.html',
  styleUrls: ['./setup-menu-item.component.scss']
})
export class SetupMenuItemComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() dataId: any;
  @Input() nodeIndex: any;

  isLoading = false;
  dataLoaded = false;
  toggelMenu: boolean = false;
  subMenu: any;
  menuItem: Subscription;
  constructor(
    private menuItemService: MenuService,
    private router: Router,
    private utils: UtilServiceService
  ) {
    this.menuItem = menuItemService.menuSourceHandler.subscribe((response: any) => {
      
      if(response.collapsSubMenu && (response.name =='module' || response.name =='workflows')){
        console.log(response)
        $('.'+response.name+'-1').addClass('pi-angle-right');
        $('.'+response.name+'-1').removeClass("pi-angle-down");
       

        $('.'+response.name+'-1').addClass('pi-angle-right');
        $('.'+response.name+'-1').removeClass("pi-angle-down");

        $('.'+response.name+'-2 .accordion-collapse').removeClass('show'); 
        $('.'+response.name+'-2 .accordion-collapse').attr('aria-expanded','true'); 
        $('.'+response.name+'-2 .accordion-collapse').removeClass('collapsed');


      }
      // if (response == 'inventory') {
      //   $('.inventory .accordion-collapse').addClass('show');
      // } else if (response == 'module') {
      //   $('.module .accordion-collapse').addClass('show');
      // } else if (response == 'sla') {
      //   $('.sla .accordion-collapse').addClass('show');
      // } else if (response == 'general') {
      //   $('.general .accordion-collapse').addClass('show');
      // } else if (response == 'users') {
      //   $('.users .accordion-collapse').addClass('show');
      // } else if (response == 'checklist') {
      //   $('.checklist .accordion-collapse').addClass('show');
      // }
    });
  }

  ngOnInit() {
    this.data.forEach((element) => {
      element.data = element.data == undefined ? [] : element.data;
      element.hide = element.data == undefined ? false : element.hide;
    });

    console.log(this.dataId,"MENU ITEM", this.data);
  }

  isExpandable(node: any) {
    return node.data.length == 0 ? false : true;
  }

  public openurl(node: any): void {
    //  console.log("Node", node);

    if (node.link) {
      // pass data to admin so that navbar on that can be shown
      this.utils.sendData({
        action: "menu-item-change",
        data: { section: node.name, link: node.link },
      });

      this.toggleExpand(node);
      this.router.navigate([node.link]);
    }
  }

  // collapse other menu items if click menu item
  public toggleExpand(node: any) {
    console.log(this.data);
  }
  changeData(data: any) {
    console.log(data)
    $('.accordion-button').attr('aria-expanded', 'false');
    $('.accordion-button').addClass('collapsed');
    //  $('.setup-sub-manu-two').removeClass('show'); 
  }

  /******************************************** Toggle ******************************************/
  public toggle(element: any) {
    const contains = element.classList.contains("pi-angle-right");
    this.utils.clearPersistantItem(PERSISTANT_ITEM.JOB_ORDER_TEMPLATE);
    console.log('BEFORE')
    console.dir(element)
    console.log(contains)


    if (contains) {
      element.className = "pi pi-angle-down";

    } else {
      element.className = "pi pi-angle-right";

    }

    console.dir('AFTER', element)



    // const contains = element.classList.contains("collapsed");

    // console.dir(element)
    // console.log(contains)


    // if (contains) {
    //   element.className = "pi pi-angle-right collapsed";
    // } else {
    //   element.className = "pi pi-angle-down";
    // }
  }

  getUrl() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {

        console.log(event.url, "-----init", event.url.indexOf('/dashboard/setup/inventory'))
        if (event.url.indexOf('/dashboard/setup/inventory') >= -1) {
          console.log("-----We are in ")
          $('#inventory').addClass('show');


        } else if (event.url.indexOf('/dashboard/setup/asset') >= 0) {
          $('#Assets').addClass('show');
        }
      }
    });
  }
  goToSetup(){
    this.utils.resetTabView()
    localStorage.setItem("activeIndexuserGroups", '0');
  }

}