<div class="">
    <!-- <div class="d-flex button-box-top-right">
        <p-button label="Edit" styleClass="ms-2 py-2 px-4 text-nowrap h-28" (click)="edit('edit')"></p-button>
       <div class="dropdown ps-2">
            <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown"  >
                    <button class="view-edit-btn border rounded-1 px-2 h-28" > <i class="pi pi-ellipsis-v pt-04"></i> </button>
            </div>
            <ul class="dropdown-menu dropdown-collaps p-0">
                   <li class="dropdown-item px-3 py-3 cursor-pointer" (click)="edit('delete')">Delete</li>
             </ul>
        </div>
    </div> -->
    <div class="">
        <div class="custella-form-container height-80 overflow-scroll position-relative d-block m-0">
            <form >
                <!-- Container Content -->
                <div class="custella-form-container-title-two text-14 font-medium" id="title_TaskJourneyPerform" translate>{{"journey_performed"}}</div>
                <div class="custella-form-container-content-border-bottom">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title color-var-500" id="label_TaskDistanceTravel" translate>{{"distance_travelled"}} </span>
                            <span *ngIf="taskJourney?.totalDistanceTravelled == 0" id="value_TaskDistanceTravel" class="custella-content-row-input-title-three">0.0 KM</span>
                            <span *ngIf="taskJourney?.totalDistanceTravelled != 0" id="value_TaskDistanceTravel" class="custella-content-row-input-title-three">{{taskJourney?.totalDistanceTravelled}} KM</span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title color-var-500" id="label_TaskCalcDuration" translate>{{"duration"}} </span>
                            <span class="custella-content-row-input-title-three" id="value_TaskCalcDuration">{{taskJourney?.duration}}</span>
                        </div>
                    </div>
                </div>
                <div class="custella-form-container-content-border-bottom">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title color-var-500" id="label_TaskJourneyUser" translate>{{"user"}} </span>
                            <span class="custella-content-row-input-title-three" id="value_TaskJourneyUser">{{taskJourney?.userFullName}}</span>
                        </div>
                    </div>
                </div>
                <!-- from/to -->
                <div class="custella-form-container-title-two text-14 font-medium" id="title_TaskJourneyLoc" translate>{{"location"}}</div>
                <div class="custella-form-container-content-border-bottom">
                    <div class="custella-content-row">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title color-var-500" id="label_TaskJourneyLocFrom" translate>{{"from"}} </span>
                            <span class="custella-content-row-input-title-three" id="value_TaskJourneyLocFrom">{{taskJourney?.startLocationAddress}}</span>
                        </div>
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title color-var-500" id="label_TaskJourneyLocTo" translate>{{"to"}} </span>
                            <span class="custella-content-row-input-title-three" id="value_TaskJourneyLocTo">{{taskJourney?.endLocationAddress}}</span>
                        </div>
                    </div>
                    <div class="custella-content-row">
                        <p-gmap *ngIf="options" (onMapReady)="setGMap($event)" [overlays]="overlays" [options]="options" [style]="{'width':'calc(100% - 3px)','height':'420px', 'margin-left':'3px', 'border': '1px solid #e2e2e2'}"></p-gmap>
                    </div>
                </div>
                <!-- System Information -->
                <!-- <div class="custella-form-container-title-two text-14 font-medium" translate>{{"system_information"}}</div>
                <app-system-information *ngIf="driverDetails" [createdByUser]="driverDetails?.driver.createdByUser" [createdDate]="driverDetails?.driver.createdDate" [modifiedByUser]="driverDetails?.driver.lastModifiedByUser" [lastModifiedDate]="driverDetails?.driver.lastModifiedDate"></app-system-information> -->

            </form>
        </div>
    </div>
</div>