import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { GridOptions } from "ag-grid-community";
import { NgxSpinnerService } from "ngx-spinner";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

import * as moment from "moment-timezone";
import { DriverService } from "src/app/_services/driver.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-drivers-table",
  templateUrl: "./drivers-table.component.html",
  styleUrls: ["./drivers-table.component.scss"],
})
export class DriversTableComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  filter: any = { status: "" };

  @Output() itemSelector = new EventEmitter();
  @Output() closeView = new EventEmitter();
  @Input() unAssignedDrivers = false;

  //aggrid
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  @Input() searchValue: any;
  gridParams: any;
  startFrom: any;
  drivers: any;
  rowData1: any[] = [];
  parameters: any;
  pageSize: any = 25;
  create!: boolean;
  noDrivers: boolean = false;
  noDriversIcon: boolean = false;

  public gridOptions!: Partial<GridOptions>;
  public overlayNoRowsTemplate!: string;

  totalRecord: any;

  public allUserStatus = [
    {
      status: "",
      label: "All Status",
    },
    {
      status: "ACTIVE",
      label: "Active",
    },
    {
      status: "PENDING_ACTIVATION",
      label: "Pending Activation",
    },
    {
      status: "INACTIVE",
      label: "Inactive",
    },
    {
      status: "SUSPENDED",
      label: "Suspended",
    },
    {
      status: "DEACTIVATED",
      label: "Deactivated",
    },
  ];

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private driversService: DriverService,
    private perm: PermissionService,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue !== "" &&
      changes.searchValue.currentValue.length > 0
    ) {
      this.filterResults();
    }

    if (
      changes &&
      changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue === "" &&
      changes.searchValue.currentValue.length === 0
    ) {
      this.filterResults();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefs = [
      {
        headerName: "Full Name",
        field: "user.fullName",
        width: 173,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
        cellRenderer: function (params: any) {
          if (params.data) {
            return params.data.user.fullName;
          }
        },
      },
      {
        headerName: "Username",
        field: "user.login",
        width: 194,
        headerTooltip: "#",
      },
      {
        headerName: "Default Vehicle",
        field: "vehicle.vehicleNumber",
        width: 194,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["text-1-5 font-weight-bold table_default_color"];
        },
      },
      {
        headerName: "License Expiry Date",
        field: "licenceExpireDate",
        width: 140,
        headerTooltip: "#",
        valueFormatter: function (params: any) {
          if (
            params &&
            params.data &&
            params.data.licenceExpireDate !== "" &&
            params.data.licenceExpireDate !== null
          ) {
            return moment(params.data.licenceExpireDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          }
        },
      },
      {
        headerName: "Status",
        field: "status",
        width: 100,
        headerTooltip: "#",
        cellRenderer: (params: any) => {
          if (params.value) {
            let status = this.util.formatText(
              String(params.value).toLowerCase(),
              "replace",
              { replaceFrom: "_", replaceTo: " " }
            );
            status = this.util.formatText(status, "titlecase", {
              seperator: " ",
            });

            let statusBallClass = "status-ball-" + params.value;

            return `<div class="${statusBallClass} mr-1"></div> <d>${status}</d>`;
          }
        },
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  }

  /******************************************** Init ******************************************/
  private init() {
    this.create = this.perm.capable("drivers", "create");
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** Grid Table Ready ******************************************/
  public onGridReady(params: any) {
    // this.spinner.show();
    if (params) {
      this.gridParams = params;
      this.searchValue = this.searchValue ? this.searchValue : "";
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var dataSource = {
        getRows: (params: any) => {
          this.startFrom = params.startRow + 1;
          if (params.sortModel.length <= 0) {
            this.parameters = {
              colId: "vehicle.defaultDriver.fullName",
              sort: "asc",
            };
            params.sortModel.push(this.parameters);
          }

          this.push(
            this.driversService
              .getDriversList(this.searchValue, this.filter.status, params)
              .subscribe((data: any) => {
                this.gridApi.hideOverlay();

                this.drivers = data.data;
                let finalDrivers = [];

                if (this.unAssignedDrivers) {
                  finalDrivers = (this.drivers as any[]).filter((driver) => {
                    console.log(driver);
                    if (
                      driver.vehicle === null ||
                      driver.vehicle === undefined
                    ) {
                      return true;
                    }

                    return false;
                  });
                } else {
                  finalDrivers = this.drivers;
                }

                if (!finalDrivers.length) {
                  this.noDrivers = true;
                }

                if (data.status === "NO_DATA") {
                  this.noDrivers = true;
                } else {
                  this.noDrivers = false;

                  if (finalDrivers.length === 0) {
                    this.totalRecord = 0;
                    this.gridApi.showNoRowsOverlay();

                    params.successCallback([], 0);
                  } else {
                    this.totalRecord = data.total;
                    params.successCallback(finalDrivers, finalDrivers.length);
                  }
                }
                // this.spinner.hide();
              })
          );
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** On Search Filter ******************************************/
  public filterResults() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** Select Item ******************************************/
  public viewDriver(e: any) {
    this.itemSelector.emit(e.data);
  }

    /******************************************** Right Click Context Menu ******************************************/
    public getContextMenuItems = (params: any) => {
      params.node.data.field = params.column.colId;
      let url = "";
      let data = "";
      console.log(params.node.data);

  
      let results = [];
  
      if (params.column.colId == "user.fullName") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }

      if (params.column.colId == "vehicle.vehicleNumber") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }
  
      return results;
    };
  
    /******************************************** Redirect To ******************************************/
    private redirectToNewTab(data: any) {
      let url;
      if (data.field === "user.fullName") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["dashboard/setup/drivers/view"],
            {
              queryParams: { id: data.user.id ,edit:true},
            }
          )
        );
      }

      if (data.field === "vehicle.vehicleNumber") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["dashboard/vehicles/view/" + data.id],
            {
              queryParams: { id: data.id },
            }
          )
        );
      }
  
      if (url) {
        url = url.replace("%23", "#");
        this.util.openItemToNewTab(url);
      }
    }

    push(obs:any) {
      super.push(obs);
    }
}
