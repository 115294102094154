<!-- <div class="modal-content modal-fullscreen p-0"> -->
    <div class="modal-header p-dialog-header flex-between">
        <div class=" d-flex align-items-center">
            <img src="assets\svg\dialog\Confirmation-w.svg">
            <span class="text-16 font-medium ms-3" id="title_SetSalesFormat">{{'sales_order_format'|translate}}</span>
        </div>
        <i class="pi pi-times w-24px" id="btn_SetSalesFormatClose" [pTooltip]="'close'|translate" tooltipPosition="left" (click)="modalRef.hide()"></i>
    </div>
    <div class="modal-body tix height-80">
        <div>
            <div class="m-0 pb-3 px-2">
                <span class="text-12 d-block font-semiBold" id="label_SetSalesFormat" translate>{{'what_is_so_format'}}</span>
                <span class="text-12" id="label_SetSalesFormatDesc" translate>{{'so_format_allows_to_customize'}}</span>
            </div>
            <div>
                <p-table [value]="tix" styleClass="p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr class="">
                            <th class="col-2" id="label_SetSalesVar">{{'variable'|translate}}</th>
                            <th class="col-2" id="label_SetSalesNec">{{'necessity'|translate}}</th>
                            <th class="col-2" id="label_SetSalesDesc">{{'description'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-tix>
                        <tr class="text-12 font-medium">
                            <td class="col-2" id="value_SetSalesVar">{{tix.variable}}</td>
                            <td class="col-2 text-left" id="value_SetSalesNec">{{tix.necessity}}</td>
                            <td class="col-2 text-left" id="value_SetSalesDesc">{{tix.desc}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="pt-2">
                <div class="m-1"><span class="text-12 font-medium" id="label_SetSalesFormatEg">{{'here_examples'|translate}}</span></div>
                <p-table [value]="tix2" styleClass="p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr class="">
                            <th class="col-2" id="label_SetSalesTixFormat">Ticket Format</th>
                            <th class="col-2" id="label_SetSalesSeqNo">{{'seq_no'|translate}}</th>
                            <th class="col-2" id="label_SetSalesFormatNo">{{'formatted_no'|translate}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-tix2>
                        <tr class="text-12 font-medium">
                            <td id="value_SetSalesTixFormat">{{tix2.format}}</td>
                            <td class="col-2 text-left">
                                <tr id="value_SetSalesSeqNo1">{{tix2.seq1}}</tr>
                                <tr id="value_SetSalesSeqNo2">{{tix2.seq2}}</tr>    
                            </td>
                            <td class="col-2 text-left">
                                <tr id="value_SetSalesFormatNo1">{{tix2.number1}}</tr>
                                <tr id="value_SetSalesFormatNo2">{{tix2.number2}}</tr>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    
    </div>
    <!-- </div> -->