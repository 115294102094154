import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DynamicFormService } from 'src/app/_services/dynamic-form.service';

@Component({
  selector: 'app-delete-dynamic-data',
  templateUrl: './delete-dynamic-data.component.html',
  styleUrls: ['./delete-dynamic-data.component.css']
})
export class DeleteDynamicDataComponent implements OnInit {
  @Input() data : any;
  @Input() page : any;
  @Input() formAPIName : any;
  @Input() apiServerType: any
  @Output() closeModel : EventEmitter<any> = new EventEmitter<any>();
  id: any;

  constructor( 
    private dynamicFormService : DynamicFormService,
   ) { }

  ngOnInit() {
    if(this.apiServerType == "magnum"){
      this.id= this.data._id

    }else  if(this.apiServerType == "java"){
       this.id= this.data.id
    }else{
      this.id= this.data._id
    }
  }

  onSubmit() {
    this.dynamicFormService.deleteDynamicData(this.id, this.page, this.formAPIName, this.apiServerType).subscribe((res:any)=>{
      //console.log("Response ", res);
      if(res.status === 200 || res.status === 201){
      //  this.modalRef.hide();
      //  this.toaster.success('', this.page+' removed successfully')
      } else {
       // this.modalRef.hide();
        //this.toaster.error('', res.error.title)
      }
    });
  }
  closeDeleteModel(){
    this.closeModel.emit(this.page);

  }

}