<div class="custella-modal">
  <div class="custella-modal-header align-items-center">
      <span class="text-14 font-medium" id="title_JobOrdersHpForm" > {{ "edit_hp_form" | translate }} </span>
      <i class="pi pi-times pt-1" (click)="modalRef.hide()"></i>
  </div>

  <div class="">
    <form (ngSubmit)="onSubmit()">
      <div>
        <div class="custella-form-container height-80 overflow-scroll w-100 rounded-2 m-0">
          <div class="custella-form-container-title-two-grey" >
            <span class="text-14 font-medium me-2" id="title_JobOrdersWoDetails" translate>{{ "wo_details" }}</span>
          </div>
          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersWoNumber" translate>{{ "WO_no." }} </span>
                <input pInputText autocomplete="off" type="text" id="input_JobOrdersWoNumberField" [(ngModel)]="hpForm.jobOrderNumber"
                  [ngModelOptions]="{ standalone: true }" />
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersHpCustomer" translate>{{ "HP_customer" }}</span>
                <input pInputText autocomplete="off" type="text" id="input_JobOrdersHpCustomerField" [(ngModel)]="hpForm.customerName"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
            </div>

            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersHpStatus" translate> {{ "hp_status" }} </span>
                <p-dropdown [placeholder]="'select_status' | translate" [options]="statuses" id="picklist_JobOrdersHpStatusField" optionLabel="label" optionValue="value"
                  name="status" [ngModelOptions]="{ standalone: true }" (onChange)="statusChange()"
                  [(ngModel)]="hpForm.status"></p-dropdown>
              </div>
            </div>
          </div>

          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersCaseDetails" translate>{{ "case_details" }}</span>
          </div>

          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersReceivedDate" translate>{{ "received_date" }} </span>
                <p-calendar [defaultDate]="defaultDate" name="dueDate" [placeholder]="'select_date' | translate" id="picklist_JobOrdersReceivedDateField" selectionMode="single"
                  dateFormat="mm/dd/yy" yearRange="2000:2030" [(ngModel)]="hpForm.receivedDate"
                  [ngModelOptions]="{ standalone: true }" [yearNavigator]="true"appendTo="body"></p-calendar>
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersReceivedTime" translate>{{ "received_time" }}</span>
                <p-calendar [placeholder]="'select_time' | translate" [timeOnly]="true" id="picklist_JobOrdersReceivedTimeField" hourFormat="12" inputId="timeonly"
                  [(ngModel)]="hpForm.receivedTime" [ngModelOptions]="{ standalone: true }"appendTo="body"></p-calendar>
              </div>
            </div>

            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersCaseId" translate>{{ "case_ID" }} </span>
                <input pInputText autocomplete="off" type="text" [(ngModel)]="hpForm.caseId" id="input_JobOrdersCaseIdField" [ngModelOptions]="{ standalone: true }" />
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersCaseDetails" translate> {{ "case_details" }} </span>
                <input pInputText autocomplete="off" type="text" [(ngModel)]="hpForm.caseDetails" id="input_JobOrdersCaseDetailsField" maxlength="255"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
            </div>
          </div>

          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersAssetDetails" translate>{{ "asset_details" }}</span>
          </div>

          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersAssetNo" translate> {{ "asset_no" }} </span>

                <input
                  pInputText autocomplete="off"
                  type="text"
                  [(ngModel)]="hpForm.assetNumber"
                  [ngModelOptions]="{ standalone: true }"
                  id="input_JobOrdersAssetNoField"
                />
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersSerialNo" translate> {{ "serial_no" }} </span>

                <input
                  pInputText autocomplete="off"
                  type="text"
                  [(ngModel)]="hpForm.serialNumber"
                  [ngModelOptions]="{ standalone: true }"
                  id="input_JobOrdersSerialNoField"
                />
              </div>
            </div>
          </div>

          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersResolutionDetails" translate>{{ "resolution_details" }}</span>
          </div>

          <div class="custella-form-container-content m-0">
            <div class="custella-content-row">
              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersClosureCode" translate> {{ "closure_code" }} </span>

                <p-dropdown
                  [placeholder]="'select_closure_code' | translate"
                  [options]="closureCodes"
                  optionLabel="code"
                  optionValue="id"
                  [(ngModel)]="hpForm.closureCode.id"
                  [ngModelOptions]="{ standalone: true }"
                  id="input_JobOrdersClosureCodeField"
                ></p-dropdown>
              </div>

              <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title" id="label_JobOrdersDelayCode" translate>  {{ "delay_codes" }}  </span>

                <p-dropdown
                  [placeholder]="'select_delay_code' | translate"
                  [options]="closureCodes"
                  optionLabel="code"
                  optionValue="id"
                  id="picklist_JobOrdersDelayCodeField"
                  [disabled]="
                    hpForm.status != 'Complete' &&
                    hpForm.status != 'Request to Close'
                  "
                  [(ngModel)]="hpForm.delayCode.id"
                  [ngModelOptions]="{ standalone: true }"
                ></p-dropdown>
              </div>
            </div>
          </div>

          <ng-container
            *ngIf="
              hpForm.status == 'Rejected' ||
              hpForm.status == 'Requested Reassign' ||
              hpForm.status == 'Rescheduled' ||
              hpForm.status == 'Customer Delay' ||
              hpForm.status == 'Request to Cancel' ||
              hpForm.status == 'Request Escalation' ||
              hpForm.status == 'Complete'
            "
          >
          <div class="custella-form-container-title-two-grey">
            <span class="text-14 font-medium me-2" id="title_JobOrdersFurtherDetails" translate>{{ "further_details" }}</span>
          </div>

            <div class="custella-form-container-content m-0">
              <div class="custella-content-row">
                <div
                  class="custella-content-row-input col-6"
                  *ngIf="
                    hpForm.status == 'Rejected' ||
                    hpForm.status == 'Requested Reassign' ||
                    hpForm.status == 'Rescheduled' ||
                    hpForm.status == 'Customer Delay' ||
                    hpForm.status == 'Request to Cancel' ||
                    hpForm.status == 'Request Escalation'
                  "
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersFurtherReason" translate>
                    {{ "reason" }}
                  </span>

                  <p-dropdown
                    [placeholder]="'select_reason' | translate"
                    [options]="rejectedCodes"
                    optionLabel="code"
                    optionValue="code"
                    id="picklist_JobOrdersFurtherReasonField"
                    name="reasonCode"
                    [(ngModel)]="hpForm.reason"
                    [ngModelOptions]="{ standalone: true }"
                  ></p-dropdown>
                </div>

                <div
                  class="custella-content-row-input col-6"
                  *ngIf="
                    hpForm.status == 'Rescheduled' ||
                    hpForm.status == 'Customer Delay'
                  "
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersRescheduleDate" translate>
                    {{ "reschedule_date_to" }}
                  </span>

                  <p-calendar
                    [defaultDate]="defaultDate"
                    name="rescheduleDate"
                    [placeholder]="'select_date' | translate"
                    selectionMode="single"
                    dateFormat="mm/dd/yy"
                    yearRange="2000:2030"
                    [(ngModel)]="hpForm.rescheduleDateTo"
                    [yearNavigator]="true"
                    id="picklist_JobOrdersRescheduleDateField"
                  appendTo="body"></p-calendar>
                </div>

                <div
                  class="custella-content-row-input col-6"
                  *ngIf="
                    hpForm.status == 'Request to Cancel' ||
                    hpForm.status == 'Request Escalation'
                  "
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersFollowUp" translate>
                    {{ "follow_up" }}
                  </span>

                  <textarea
                    pInputTextarea autocomplete="off"
                    rows="3"
                    [(ngModel)]="hpForm.followUp"
                    [ngModelOptions]="{ standalone: true }"
                    [maxlength]="320000"
                    id="input_JobOrdersFollowUpField"
                  ></textarea>
                </div>
              </div>

              <div class="custella-content-row">
                <div
                  class="custella-content-row-input col-6"
                  *ngIf="
                    hpForm.status == 'Request Escalation' ||
                    hpForm.status == 'Complete'
                  "
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersFindings" translate>
                    {{ "findings" }}
                  </span>

                  <textarea
                    pInputTextarea autocomplete="off"
                    type="text"
                    rows="3"
                    id="input_JobOrdersFindingsField"
                    [(ngModel)]="hpForm.findings"
                    [ngModelOptions]="{ standalone: true }"
                    [maxlength]="320000"
                  ></textarea>
                </div>

                <div
                  class="custella-content-row-input col-6"
                  *ngIf="hpForm.status == 'Request Escalation'"
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersAdditionalParts" translate>
                    {{ "additional_parts" }}
                  </span>

                  <textarea
                    pInputTextarea autocomplete="off"
                    type="text"
                    rows="3"
                    id="input_JobOrdersAdditionalPartsField"
                    [(ngModel)]="hpForm.additionalPart"
                    [ngModelOptions]="{ standalone: true }"
                    [maxlength]="320000"
                  ></textarea>
                </div>
              </div>

              <div class="custella-content-row">
                <div
                  class="custella-content-row-input col-6"
                  *ngIf="hpForm.status == 'Request Escalation'"
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersEscalatedTo" translate>
                    {{ "escalated_to" }}
                  </span>

                  <textarea
                    pInputTextarea autocomplete="off"
                    type="text"
                    rows="3"
                    id="input_JobOrdersEscalatedToField"
                    [(ngModel)]="hpForm.additionalPart"
                    [ngModelOptions]="{ standalone: true }"
                    [maxlength]="320000"
                  ></textarea>
                </div>
              </div>

              <div class="custella-content-row">
                <div
                  class="custella-content-row-input col-6"
                  *ngIf="hpForm.reason == 'Others'"
                >
                  <span class="custella-content-row-input-title" id="label_JobOrdersOtherReason" translate>
                    {{ "other_reason" }}
                  </span>

                  <textarea pInputTextarea autocomplete="off" id="input_JobOrdersOtherReasonField" type="text" rows="3" [(ngModel)]="hpForm.otherReason"
                    [ngModelOptions]="{ standalone: true }" [maxlength]="320000"></textarea>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="custella-form-container-footer-two pb-0">
            <div class="d-flex align-items-center justify-content-end">
              <button class="custella-btn-white text-12 mx-2" id="btn_JobOrdersCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
              <p-button [label]="'save' | translate" id="btn_JobOrdersSubmit" (click)="onSubmit()"></p-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
