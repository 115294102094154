import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Router } from '@angular/router';
import { GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import "ag-grid-enterprise"; 
import * as moment from 'moment';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeliveryOrderSetupService } from 'src/app/_services/delivery-order-setup.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { ErrorUtil } from 'src/app/_utilities/error';
import { PlanUpdateConfirmationComponent } from '../plan-update-confirmation/plan-update-confirmation.component';
import { ModifyPlanComponent } from '../modify-plan/modify-plan.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { DeliveryOrderStatus } from 'src/app/_models/global.data.model';

@Component({
  selector: 'app-modify-remove-order',
  templateUrl: './modify-remove-order.component.html'
})
export class ModifyRemoveOrderComponent implements OnInit {
  toggle1: boolean = false;
  data: any
  title: any
/********************** AG-grid Variable *************************/
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  gridParams : any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  nopaymentsDataIcon!: boolean;
  productsFamilies: any;
  productsType: any;
  searchValue: any
  currentRecordNumber: any;
  totalRecord: any;
/********************** AG-grid Variable *************************/

  rowData: any;
  originalRowData: any;
  addonFeature: any
  dateFormat: any;
  trips: any = [];
  searchVehicle: any;
  vehicleId: any;
  deliveryOrderId: any;
  deliveryOrderName: any;
  deliveryOrderStatus: any;
  dragableDataList: any;
  vehicles: any = [];
  vehiclesData: any = []
  vehicleIds: any = [];
  vehicleNames: any = [];
  orderData: any;
  removeForm !: UntypedFormGroup;
  type: any;
  doStatuses: any;

  note: any = [];
  note1: any = [];
  assignedOrders: any;
  unassigned: any= [];

  allCardsData: any;
  capacityTotal: any;
  originalDragableDataList: any;
  optionType: any

  selectedVehicle: any
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  configMd: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-md",
  };
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  };

  selectedConfig : any;
  modalState : any;
  selectedComponent : any;
  searchCancelDO : any
  constructor(public modalRef: BsModalRef,
    private planningHubService: PlanningHubService,
    private auth: AuthenticationService,
    public modalService: BsModalService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deliveryOrderSetupService : DeliveryOrderSetupService,
    private errorUtil: ErrorUtil,
    private cd: ChangeDetectorRef
    ) {
      this.removeForm = new UntypedFormGroup({
        'order': new UntypedFormControl(null),
      });
    this.addonFeature = this.auth.getAddonFeatureLists().addonSNL;

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'clientSide',
      paginationPageSize: 25,
      sortingOrder : ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      }
      this.columnDefs = [
        { headerName: "Select",field: "Select",minWidth: 110,
        headerTooltip: "#",
          cellRenderer: function (params : any) {
              return ' <div class="p-radiobutton planinghub-button" style="top: -6px;"> <input name="selected" type="radio" class="p-radiobutton-box-PH"></div>' ;
          }
           
          },
        { headerName: 'Order Name', field: 'name',minWidth:150,tooltipField: 'notes',
        headerTooltip: "#",  
        cellRenderer: function (params : any) {
            if(params.data){
              if(params.data.notes){
                return  params.data.name + '<img class="ml-2" style="height: 12px" src="../../../../assets/images/3.0/info_dark.png" alt="">' ;
              }else{
                return  params.data.name;
              }
            }
          }
        },
      { headerName: 'Pickup', field: 'pickupAddress',minWidth:150,headerTooltip: "#", },
      { headerName: 'Drop-off', field: 'dropOffAddress',minWidth:100,headerTooltip: "#"},
      { headerName: 'Pickup ETA', field: 'pickupDateTimeFormat', headerTooltip: "#",},
      { headerName: 'Drop-off ETA', field: 'dropOffDateTime',minWidth:150,
      headerTooltip: "#",
        valueFormatter: this.getMissingDates.bind(this)
      },
      { headerName: "Local Pickup",field: "",hide: this.addonFeature !='SNL_TENANT',width: 80,
        checkboxSelection: true,
        headerTooltip: "#",
        cellRenderer: (params : any) => {
          if(params.data && params.data.deliveryOrder && params.data.deliveryOrder.smallTruckDelivery){
             params.node.setSelected(true);
          }else{
            params.node.setSelected(false);
          }
        }
      },
      // { headerName: 'Capacity(m2) ', field: 'note',tooltipField: 'capacity', headerTooltip: "#",},
      { headerName: 'Vehicle No', field: 'vehicleNumber',minWidth:100,
      headerTooltip: "#"
      },
      { headerName: 'Driver', field: 'driver',hide:true, headerTooltip: "#",},
      ];
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    };
    this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";

   }

  
   ngOnInit() {
    ////debugger
    console.log(this.data)
    this.data.optionType = this.optionType
    this.dateFormat = localStorage.getItem('date_format');
    for(var i=0;i<=this.data.routes.length-1;i++){
      for(var k=0;k<=this.data.routes[i].trips.length-1;k++){
        this.data.routes[i].trips[k].vehicleData = this.data.routes[i].vehicleData;
        this.trips.push(this.data.routes[i].trips[k]);
      }
    }
    if(this.data.planStatus=="DISPATCHED" || this.data.planStatus=="IN_PROGRESS" || this.data.planStatus=="CANCELLED" || this.data.planStatus=="COMPLETED" || this.data.planStatus=="INCOMPLETE"){
      this.type = 'postDispatch';
    }
    if(this.data.optionType == 'removeVehicle'||this.data.optionType == 'adjustReplan'){
      for(var i=0;i<=this.data.routes.length-1;i++){
        if(!this.data.routes[i].locked || this.data.optionType == 'adjustReplan'){
          this.vehicles.push(this.data.routes[i].vehicleData);
          if(!this.data.routes[i].locked) this.vehiclesData.push(this.data.routes[i].vehicleData.vehicle);
        }
      }
      this.loadData(this.data.routes);
    }
  }

///////////////////////////////////// remove order  ////////////////////////////////////////

  getMissingDates(e: any) {
    ////debugger
    if(e.data){
      if (e.data.deliveryOrder.orderStatus != 'Unscheduled') {
        var date1 = e.data.pickupDateTime ? moment(e.data.pickupDateTime).format(this.dateFormat.toUpperCase()) : '';
        var date2 = e.data.dropOffDateTime ? moment(e.data.dropOffDateTime).format(this.dateFormat.toUpperCase()) : '';
        if(date1 != date2){
          var dateFormat = this.dateFormat.toUpperCase();
          var ms = (date2 && date1) ? moment(date2,dateFormat).diff(moment(date1,dateFormat)) : '';
          var d: any = ms ? moment.duration(ms) : '';
          var days = d ? d.days() : '';
          if (days === 0) {//if don't have pick date only have dropoff then 0 length
            return e.data.dropOffDateTime ? moment(e.data.dropOffDateTime).format('hh:mm A' + ', ' + this.dateFormat.toUpperCase()) : '';
          }
          else{
            return e.data.dropOffDateTime ? moment(e.data.dropOffDateTime).format('hh:mm A' + ', ' + this.dateFormat.toUpperCase()) : '';
          }
        }
        else {
          return e.data.dropOffDateTime ? moment(e.data.dropOffDateTime).format('hh:mm A' + ', ' + this.dateFormat.toUpperCase()) : '';
        }
      }
      else{
        return '-';
      }
    }
  }

  displayStatus(orderStatus : any){
    console.log(this.doStatuses,"----->>>")
    for (let i = 0; i < this.doStatuses.length; i++) {
      if (this.doStatuses[i].name === orderStatus) {
        return this.doStatuses[i];
      }
    }
  }
/******************************************** AG-grid Table init ********************************************/

  onGridReady(params:any) {
    //console.log(params)
    this.gridParams = params;
    this.gridApi = params ? params.api : params;
    
    ///this.deliveryOrderSetupService.getAllStatus().subscribe((res:any) => {
     // this.deliveryOrderSetupService.setDoStatusDynamically()
      this.doStatuses = DeliveryOrderStatus.statusList;
     var cards: any[] = [];
     //console.log(res)
    /// this.doStatuses = res;
 
         this.data.assignedOrders.forEach((assignedOrdersData : any) => {
             assignedOrdersData.deliveryOrderLineItemVMs.forEach((element : any) => {
               this.note = [];
               var assignedOrders = {deliveryOrder:{orderStatusFormat:null},deliveryOrderSummary:'',pickupAddress:'',dropOffAddress:'',pickupDateTime:'',dropOffDateTime:'',capacity:'',
               note:'',orderNumber:'',name:'',orderNumberFormat:'',description:assignedOrdersData.deliveryOrder.description,notes:assignedOrdersData.deliveryOrder.notes,vehicleNumber:'',vehicleName:'',
               vehicleType:'',vehicleId:null,driver:'',pickupDateTimeFormat:'',type:'',lat:'',lon:'',lineItemId:'',dropoffLat:'',dropoffLon:''};
               assignedOrders.deliveryOrder = assignedOrdersData.deliveryOrder;
               assignedOrders.deliveryOrderSummary = assignedOrdersData.deliveryOrderSummary;
               assignedOrders.orderNumber = assignedOrdersData.deliveryOrder.orderNumber;
               assignedOrders.orderNumberFormat = assignedOrdersData.deliveryOrder.orderNumber +'-'+ moment(assignedOrdersData.deliveryOrder.orderDateTime).format('DDMM');
               assignedOrders.name = assignedOrdersData.deliveryOrder.name;
                 
               if(assignedOrdersData.deliveryOrderSummary.vehicle.vehicleNumber){
                 assignedOrders.vehicleNumber = assignedOrdersData.deliveryOrderSummary.vehicle.vehicleNumber;
                 assignedOrders.vehicleId = assignedOrdersData.deliveryOrderSummary.vehicle.id;
                 assignedOrders.vehicleName = assignedOrdersData.deliveryOrderSummary.vehicle.name;
               }
               else{
                 assignedOrders.vehicleNumber = '-';
                 assignedOrders.vehicleName = '-';
               }
                //  if(assignedOrdersData.deliveryOrderSummary.vehicle.vehicleType.name){
                //    assignedOrders.vehicleType = assignedOrdersData.deliveryOrderSummary.vehicle.vehicleType.name;
                //  }
                //  else{
                //    assignedOrders.vehicleType = '-';
                //  }
                //  if(assignedOrdersData.deliveryOrderSummary.driver && assignedOrdersData.deliveryOrderSummary.driver.user){
                //    assignedOrders.driver = assignedOrdersData.deliveryOrderSummary.driver.user.fullName
                //  }
                //  else{
                //    assignedOrders.driver = '-';
                //  }
                /******************************** Pickup **********************************************/
               if(element.deleveryOrderLineItem.pickupDepot){
                 assignedOrders.type = 'pickup';
                 assignedOrders.lat = element.deleveryOrderLineItem.pickupLocationLat;
                 assignedOrders.lon = element.deleveryOrderLineItem.pickupLocationLong;
                 assignedOrders.lineItemId = element.deleveryOrderLineItem.id;
                 assignedOrders.pickupAddress = element.deleveryOrderLineItem.pickupDepot ? element.deleveryOrderLineItem.pickupDepot.name : null
               }
               else{
                 assignedOrders.type = 'pickup';
                 assignedOrders.lat = element.deleveryOrderLineItem.pickupLocationLat;
                 assignedOrders.lon = element.deleveryOrderLineItem.pickupLocationLong;
                 assignedOrders.lineItemId = element.deleveryOrderLineItem.id;
                 assignedOrders.pickupAddress = element.deleveryOrderLineItem.pickupBay ? element.deleveryOrderLineItem.pickupBay.name : element.deleveryOrderLineItem.pickupAddress
               }
               //drop off
               if(element.deleveryOrderLineItem.dropOffDepot){
                 assignedOrders.type = 'delivery';
                 assignedOrders.dropoffLat = element.deleveryOrderLineItem.dropOffLocationLat;
                 assignedOrders.dropoffLon = element.deleveryOrderLineItem.dropOffLocationLong;
                 assignedOrders.lineItemId = element.deleveryOrderLineItem.id;
                 assignedOrders.dropOffAddress = element.deleveryOrderLineItem.dropOffDepot ? element.deleveryOrderLineItem.dropOffDepot.name : null
               }
               else{
                 assignedOrders.type = 'delivery';
                 assignedOrders.dropoffLat = element.deleveryOrderLineItem.dropOffLocationLat;
                 assignedOrders.dropoffLon = element.deleveryOrderLineItem.dropOffLocationLong;
                 assignedOrders.lineItemId = element.deleveryOrderLineItem.id;
                 assignedOrders.dropOffAddress = element.deleveryOrderLineItem.dropOffBay ? element.deleveryOrderLineItem.dropOffBay.name : element.deleveryOrderLineItem.dropOffAddress
               }
                //  if(assignedOrdersData.deliveryOrder.orderStatus == 'IN_PROGRESS' || assignedOrdersData.deliveryOrder.orderStatus == 'COMPLETED' || assignedOrdersData.deliveryOrder.orderStatus == 'DISPATCHED'){
                //    assignedOrders.pickupDateTime = element.actualTourInfo.pickupDateTime;
                //    assignedOrders.pickupDateTimeFormat =  assignedOrders.pickupDateTime ? moment(assignedOrders.pickupDateTime).format('hh:mm A') : '';
                //    assignedOrders.dropOffDateTime = element.actualTourInfo.dropOffDateTime;
                //  }else if(assignedOrdersData.deliveryOrder.orderStatus == 'NEW' || assignedOrdersData.deliveryOrder.orderStatus == 'DRAFT' || assignedOrdersData.deliveryOrder.orderStatus == 'CONFIRMED'){
                //    assignedOrders.pickupDateTime = element.plannedTourInfo.pickupDateTime;
                //    assignedOrders.pickupDateTimeFormat =  assignedOrders.pickupDateTime ? moment(assignedOrders.pickupDateTime).format('hh:mm A') : '';
                //    assignedOrders.dropOffDateTime = element.plannedTourInfo.dropOffDateTime;
                //  }
               assignedOrders.capacity = element.deleveryOrderLineItem.capacity;
              //  element.deleveryOrderLineItemPackages.forEach((pack :any)=> {
              //    this.note.push('('+pack.quantity+')' + '-' + pack.name );
              //  })
 
               console.log(this.type,"^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^",element.deleveryOrderLineItem.status)
               assignedOrders.note = this.note;
               if(element.deleveryOrderLineItem.status !== 'WITH_DRIVER' && element.deleveryOrderLineItem.status !== 'DELIVERED'){
                 console.log("************************")
                 cards.push(assignedOrders);
                 this.assignedOrders = _.cloneDeep(cards);
               }
             })
         })
        
         console.log(cards)
       this.allCardsData = cards;//for status filter
       this.rowData = this.allCardsData;
       this.totalRecord = this.rowData.length
       this.originalRowData = _.cloneDeep(this.rowData);
     if(this.allCardsData.length > 0){
       this.allCardsData.forEach((data: any) => {
         if(data.deliveryOrder.orderStatus != 'Unscheduled'){
           this.capacityTotal = this.capacityTotal + data.capacity;
         }
       })
     }
  /// })
     if(this.gridApi){
       this.gridApi.sizeColumnsToFit();
     }
   }
 
 /******************************************** order search ********************************************/
   search(){
     ////debugger
     if(this.searchValue){
       var arr = this.originalRowData.filter((item: any) => (item.orderNumber==this.searchValue));
       this.rowData = arr;
     }
     else{
       this.rowData = _.cloneDeep(this.originalRowData);
     }
     this.totalRecord = this.rowData.length
   }
 /******************************************** Remove order ********************************************/
   remove(){
     if(!this.deliveryOrderId){
       this.errorUtil.setErrorMessage(404, null ,  'select order', 'error',3000);
     }
     else{
       this.openModal('removeOrder','');
     }
   }

   openModal(modalView : any, data2: any) {
    ////debugger
    this.modalRef.hide();
    var data = _.cloneDeep(data2)
    this.selectedConfig = this.configMd
    if(modalView == 'assignDriver'){
      this.modalState = {
        data : data,
        mode : 'add'
      };
      this.selectedConfig = this.configMd
     // this.selectedComponent = AssignDriverComponent;
    }
    else if(modalView == 'editPlanTimeline'){
      this.modalState = {
        title: 'Edit Plan',
        data : this.data
      };
     // this.selectedComponent = EditPlanTimelineComponent
    }
    else if(modalView == 'modifyOption'){
      this.modalState = {
        title: 'Modify',
        data : data
      };
      //this.selectedComponent = ModifyOptionComponent
    }
    else if (modalView == "removeOrder") {
      console.log(this.data)
      var data1 = {btnName:'removeOrder',planId:this.data.draftPlanId,planStatus:this.data.planStatus,vehicleId:this.vehicleId,deliveryOrderId:this.deliveryOrderId,deliveryOrderName:this.deliveryOrderName,deliveryOrderStatus:this.deliveryOrderStatus,planNumber:this.data.planNumber}
      this.modalState = {
        title: 'Remove order from plan',
        data: data1,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    }
    else if (modalView == "removeVehicle") {
      var dataVehicle = {btnName:'removeVehicle',planId:this.data.draftPlanId,vehicleIds:this.vehicleIds,vehicleNames:this.vehicleNames,planNumber:this.data.planNumber}
      this.modalState = {
        title: 'Remove vehicle from plan',
        data: dataVehicle,
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    }
    else if (modalView == "cancelOrder") {
      console.log(this.orderData.deliveryOrder)
      var dataOrder = {btnName:'cancelOrder',planId:this.data.draftPlanId,deliveryOrderId:this.orderData.deliveryOrder.id,vehicleId:this.orderData.deliveryOrderSummary.vehicle.id,orderNumber:this.orderData.deliveryOrder.orderNumber, orderName:this.orderData.deliveryOrder.name,planNumber:this.data.planNumber}
      this.modalState = {
        data: dataOrder,
        title: 'Cancel Single Order'
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    }else if (modalView == "modifyPlan") {
      this.modalState = {
        title: "Modify Plan",
        mode: "add",
        data : data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyPlanComponent;
    }
     else{
      return;
    }
    this.selectedConfig.initialState = this.modalState;
    return this.modalRef = this.modalService.show(this.selectedComponent, this.selectedConfig);
  }

  viewPlan(e: any){
    ////debugger
    // if (e.colDef.field === "orderNumber"){
    //   this.modalRef.hide();
    //   this.planningHubService.mapToolTipClick(e);//open order cards
    // }
    // else if (e.colDef.field === "vehicleNumber"){
    //   if(e.data.vehicleNumber != 'Assign'){
    //     this.modalRef.hide();
    //     this.router.navigate(['dashboard/vehicles/view/'+e.data.deliveryOrderSummary.vehicle.id],{ queryParams: { id : e.data.deliveryOrderSummary.vehicle.id}});
    //   }else{
    //     this.modalRef.hide();
    //     //assign driver pop up
    //     e.data.workIs = "AssignDriver";
    //     e.data.planId = this.data.draftPlanId;
    //     e.data.title = 'Assign';
    //     this.openModal('assignDriver', e.data)
    //   }
    // }
    // else if (e.colDef.field === "pickupAddress" && e.data.pickupAddress && e.data.deliveryOrder.orderStatus != "Unscheduled"){
    //   this.modalRef.hide();
     
    //   this.planningHubService.zoomInSelectedOrder(e.data);
    // }
    // else if (e.colDef.field === "dropOffAddress" &&  e.data.dropOffAddress && e.data.deliveryOrder.orderStatus != "Unscheduled"){
    //   this.modalRef.hide();
      
    //   e.data.lat =  e.data.dropoffLat;
    //   e.data.lon =  e.data.dropoffLon;
    //   this.planningHubService.zoomInSelectedOrder(e.data);
    //
     if(e.colDef.field == 'Select'){
      this.onSelectionChanged(e);//after select radio button
    }
    //this.viewOrderData.emit(e);
  }

  //table inside checkbox checked
  onSelectionChanged(event: any){
    
    //if(event.node.selected){
      console.log(event.data)
      this.deliveryOrderId = event.data.deliveryOrder.id;
      this.deliveryOrderName = event.data.deliveryOrder.name;
      this.deliveryOrderStatus = event.data.deliveryOrder.orderStatus;
      this.vehicleId = event.data.vehicleId;
   // }
    // else{
    //   this.deliveryOrderId = null;
    //   this.vehicleId = null;
    // }
  }

  ///////////////////////////////////// remove vehicle  ////////////////////////////////////////
  loadData(routes: any){
    var resource = [];
    var events = [];
    var relatedEvents : any[]= [];
    this.dragableDataList = [] ;
    routes.forEach((routes : any)=> {
    //  if(!routes.locked){
      var resource1 :any= {
        id: routes.vehicleData.vehicle.id,
        vehicleName: routes.vehicleData.vehicle.name,
        vehicleNumber: routes.vehicleData.vehicle.vehicleNumber,
        distance: routes.statistic.distance,
        duration:routes.statistic.duration,
        capacity: routes.statistic.capacity,
        //imageUrl: routes.vehicleData.vehicle.icon,
        relatedVehicleEvents: null,
        vehicleImage : routes.vehicleData.vehicle.vehicleImage ? routes.vehicleData.vehicle.vehicleImage.attachmentUrl : '',
        checkValue : false,
        locked : routes.locked,
        routeId : routes.id
      }
      routes.trips.forEach((trips  : any)=> {
        if(trips.type!="departure" && trips.type!="arrival"){
        var events1 :any= {
          type: trips.type,
          startTime : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('hh:mm A'),
          endTime : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('hh:mm A'),
          orderNo: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.name : "",
          orderId : trips.order.id,
          orderCustomer : null,
          note: null,
        }
        this.data.assignedOrders.forEach((assignedOrdersData : any) => {
          if(assignedOrdersData.deliveryOrder.orderNumber === events1.orderNo){
            assignedOrdersData.deliveryOrderLineItemVMs.forEach((element : any) => {
              var note: any[] = [];
              // element.deleveryOrderLineItemPackages.forEach((pack : any) => {
              //   note.push('('+pack.quantity+')' + '-' + pack.name );
              // })
              events1.note = note;
            })
          }
        })
        routes.deliveryOrders.forEach((order : any) => {
          if(order.deliveryOrderLite.id === events1.orderId){
            events1.orderCustomer = order.deliveryOrderLite.customer ? order.deliveryOrderLite.customer.name : null;
          }
        })
        events.push(events1);
        relatedEvents.push(events1);
      }
      })
      var relatedVehicleEvents = relatedEvents;
      relatedEvents = [];
      resource1.relatedVehicleEvents = relatedVehicleEvents;
      resource.push(resource1);
      this.dragableDataList.push(resource1);
      this.originalDragableDataList = _.cloneDeep(this.dragableDataList);
    //  }
    })
  }

  chkSelcted(list: any){
    ////debugger
    console.log(list)
    if(list.checkValue){
      this.vehicleIds.push(list.id);
      this.vehicleNames.push(list.vehicleName)
    }
    else{
      if(this.vehicleIds.length>=1){
        var ind = _.findIndex(this.vehicleIds, (row:any) => {
          return row.id == list.id;
        });
        this.vehicleIds.splice(ind, 1);
        this.vehicleNames.splice(ind, 1);
      }
    }
  }

  removeVehicle(){
    if(this.vehicleIds.length==0){
      this.errorUtil.setErrorMessage(400, null ,  'select vehicle', 'error',3000);
    }
    else{
      this.openModal('removeVehicle','');
    }
  }

  //vehicle search
  searchByVehicle($event: any) {
    console.log($event.value,"====",this.searchVehicle)
    if(this.searchVehicle){
      var arr = this.originalDragableDataList.filter((item :any)=> (item.vehicleNumber==this.searchVehicle));
      this.dragableDataList = arr;
    }
    else{
      this.dragableDataList = _.cloneDeep(this.originalDragableDataList);
    }
    
  }
   ////////////////////////////////////////////// cancel order //////////////////////////////
   searchCancelOrder($event : any){
    ////debugger
    console.log($event.value)
    if($event.value){
        this.searchValue = $event.value.deliveryOrder.orderNumber
        console.log(this.searchValue)
        if(this.searchValue){
          var arr = this.data.assignedOrders.filter((item : any) => (item.deliveryOrder.orderNumber==this.searchValue));
          this.orderData = arr[0];
        }

      } else{
        this.searchValue = null
      this.orderData = '';
    }
  }

  proceed(){
    if(!this.searchValue){
      this.removeForm.controls['order'].setValidators([Validators.required]);
      this.removeForm.controls['order'].updateValueAndValidity();
    }
    else{
      this.removeForm.controls['order'].clearValidators();
      this.removeForm.controls['order'].updateValueAndValidity();
    }
    if (!this.removeForm.valid) {
      for (const i in this.removeForm.controls) {
        this.removeForm.controls[i].markAsTouched();
      }
      return false;
    }
    else{
      this.openModal('cancelOrder','');
    }
  }

  ////////////////////////////////////////////// adjust replan //////////////////////////////
  getFleetRoutePlanById(planType: any){
    ////debugger
    this.spinner.show();
    this.planningHubService.getFleetRoutePlanById(this.data.draftPlanId,planType).subscribe((data: any) => {
      this.spinner.hide();
      if(data.status === 400){
        this.router.navigate(['/dashboard/planningHub']);
      }else{
        this.loadData(data.body.routes);
      }
    })
  }

  lockVehicle(locked: any,routeId: any,list: any){
    locked = !locked;
    this.spinner.show();
    console.log(this.vehiclesData)
    console.log(routeId,"====",list)
    //debugger
      this.planningHubService.lockVehicle(locked,routeId,true).subscribe((res:any)=>{
        this.spinner.hide();
        if (res.message == "Success") {
          if(locked){

            var index = _.findIndex(this.vehiclesData, (row:any) => {
              return row.id == list.id;
            });
            this.vehiclesData.splice(index, 1);
            this.searchVehicle = null;
            this.cd.detectChanges();
            this.errorUtil.setErrorMessage(200, null ,  'Vehicle Locked Successfully', 'success',1000);
          }
          else{
            this.vehiclesData.push(list);
            this.errorUtil.setErrorMessage(200, null ,  'Vehicle Unlocked Successfully', 'success',1000);
          }
          this.getFleetRoutePlanById(null);
        }
        else{
          this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);;
        }
      })
  }
  replan(){
   if(this.dragableDataList.length==0){
    this.errorUtil.setErrorMessage(404, null ,  'No unlocked vehicles to replan', 'warn',3000);
   }
   else{
    var formData = {planId:this.data.draftPlanId};
    this.spinner.show();
    this.planningHubService.adjustReplanConfirm(formData).subscribe((res: any) => {
      if (res.status === 200 || res.status === 201) {
        if(res.body.serverError){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(400, null ,  res.body.serverError.cause, 'error',3000);
         
        }
        else if(res.body.errors){
          this.spinner.hide();
          this.errorUtil.setErrorMessage(res.status, null ,  res.body.errors.messages, 'error',3000);
        }
        else{
          this.modalRef.hide();
          this.spinner.hide();
          this.errorUtil.setErrorMessage(200, null ,  'Unlocked vehicles will be reoptimized', 'success',1000);
          
        }
      }
      else if (res.status === 400) {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);
      }
      else {
        this.spinner.hide();
        this.errorUtil.setErrorMessage(res.status, null ,  res.error.title, 'error',3000);;
      }
    })
   }

  }

  /******************************************** Return Value ******************************************/
  public returnValue(value: any) {
    let returnItem = "";
    const assignedOrders = this.data.assignedOrders.find((assignedOrders: any) => assignedOrders.deliveryOrder.orderNumber == value.deliveryOrder.orderNumber)!;
    returnItem = `${assignedOrders.deliveryOrder.name}`;
    return returnItem;
  }

  /******************************************** Toggle ******************************************/
  public toggle(element: any) {
    const contains = element.classList.contains("collapsed");

    if (contains) {
      element.className = "pi pi-caret-down mt-1 ms-3 collapsed";
    } else {
      element.className = "pi pi-caret-up mt-1 ms-3";
    }
  }

  
}
