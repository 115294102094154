import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';

@Component({
  selector: 'app-download-export-data',
  templateUrl: './download-export-data.component.html',
  styleUrls: ['./download-export-data.component.scss']
})
export class DownloadExportDataComponent implements OnInit {
  display : boolean = true;
  buttonLabel : string = 'Next';

  constructor(public modalRef: BsModalRef, private util: UtilServiceService) { }

  ngOnInit(): void {
  }

  goBack(){
    this.util.goBack()
   }

}
