import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { PlanUpdateConfirmationComponent } from '../plan-update-confirmation/plan-update-confirmation.component';
import { DndDropEvent, DropEffect, EffectAllowed } from 'src/app/shared/dnd/src/public-api';
import { ModifyPlanComponent } from '../modify-plan/modify-plan.component';
@Component({
  selector: 'app-switch-order-same-vehicle',
  templateUrl: './switch-order-same-vehicle.component.html'
})
export class SwitchOrderSameVehicleComponent implements OnInit {
  toggle1: boolean = false;
  title:any;
  data: any;
  search2: any;
  filteredObj: any;
  tripsData: any;

  note: any;
  relatedVehicleEvents: any;
  resources: any;
  //events: any;
  orgResources: any;
  dragableDataList: any;
  formData: any;

  filteredRoutes: any;
  type: any = 'preDispatch';
  switchOrderForm!: UntypedFormGroup;
  ordersData: any;
  searchOrderValue: any;
  orderNumberFormat: any;
  orderNumber: any;
  vehicleData: any;
  lineItemId: any;
  orderName: any;
  vehiclesData: any = []
  optionType: any;

  modalState : any;
  selectedConfig : any;
  selectedComponent : any;
  configSM: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "modal-sm",
  };
  configCustomLg: any = {
    backdrop: true,
    keyboard: true,
    ignoreBackdropClick: true,
    class: "custom-modal-lg",
  };
  private currentDraggableEvent?:DragEvent;
  private currentDragEffectMsg?:string;

  constructor(public modalRef : BsModalRef,
    private auth: AuthenticationService,
    private spinner: NgxSpinnerService,
    private planningHubService: PlanningHubService,
    private router: Router,
    private errorUtil: ErrorUtil,
    public modalService: BsModalService) {
      this.switchOrderForm = new UntypedFormGroup({
        'vehicle': new UntypedFormControl(null, [Validators.required]),
        'order': new UntypedFormControl(null)
      })
     }

  ngOnInit(): void {
    console.log(this.optionType,'=====dataaaa',this.data)
     this.data.optionType = this.optionType
    console.log('dataaaa',this.data)
    if(this.data.planStatus=="DISPATCHED" || this.data.planStatus=="IN_PROGRESS"){
      this.type = 'postDispatch';
    }
    this.filteredRoutes = this.data.routes.filter((item: any) => (!item.locked));

    for(var i=0;i<=this.data.routes.length-1;i++){
      if(!this.data.routes[i].locked){
        this.vehiclesData.push(this.data.routes[i].vehicleData.vehicle);
      }
    }
   

    this.loadData();//vehicles
    this.loadOrders();
  }
  
  toggle(){
    this.toggle1=!this.toggle1
  }
  loadData(){
    var resource: any[] = [];
    var events: any[] = [];
    var relatedEvents: any[] = [];
    this.data.routes.forEach((routes: any) => {
      ////////debugger
      var resource1 = {
        id: routes.vehicleData.vehicle.id,
        vehicleNumber: routes.vehicleData.vehicle.vehicleNumber,
        vehicleName : routes.vehicleData.vehicle.name,
        vehicleType: routes.vehicleData.vehicle.vehicleType.name,
        distance: routes.statistic.distance,
        duration:routes.statistic.duration,
        //totalUnits: routes.statistic.totalUnits,
        capacity: routes.statistic.capacity,
        //imageUrl: routes.vehicleData.vehicle.icon,
        routeId : routes.id,
        vehicleImage : routes.vehicleData.vehicle.vehicleImage ? routes.vehicleData.vehicle.vehicleImage.attachmentUrl : '',
        //milkRun: routes.name,
        relatedVehicleEvents: null,
        //selectedCard: false,//for css
        //capacityChecked : routes.capacityChecked,
        orderName : routes.deliveryOrders[0].deliveryOrderLite.name,
        orderNumber : routes.deliveryOrders[0].deliveryOrderLite.orderNumber,
        orderNumberFormat : routes.deliveryOrders[0].deliveryOrderLite.orderNumber +'-'+ moment(routes.deliveryOrders[0].deliveryOrderLite.orderDateTime).format('DDMM'),
        vehicleData: routes.vehicleData
      }
      // resource.push(resource1);
      routes.trips.forEach((trips : any)=> {
        console.log(trips)
        if(trips.type!="departure" && trips.type!="arrival"){
        var events1 = {
          type: trips.type,
          tripId: trips.id,//for reorder schedular
          startTime : moment.tz(trips.startLocation.arrivalTime, this.auth.getUserTimezone()).format('hh:mm A'),
          endTime : moment.tz(trips.startLocation.departureTime, this.auth.getUserTimezone()).format('hh:mm A'),
          routeId : routes.id,
          orderNo: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.orderNumber : "",
          orderNumber : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.orderNumber  : "",
          orderNumberFormat : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? (trips.order.orderNumber +'-'+ moment(trips.order.orderDateTime).format('DDMM')) : "",
          orderName : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.name : '',
          orderStatus : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.orderStatus : '',
          orderId : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.id:'',
          orderCustomer : null,
          quantity: (trips.type!="break") ? trips.quantity : '',
          //orderEvent : false,
          note: null,
          deliveryOrderWay: (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.order.deliveryOrderWay : "",
          status:(trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.orderLineItem.deleveryOrderLineItem.status : "",
          lineItemId : (trips.type!="departure" && trips.type!="arrival" && trips.type!="break") ? trips.orderLineItem.deleveryOrderLineItem.id : '',
          address : null,
        }
        if(trips.type=="pickup"){
          if(trips.interChangeDepot){
            events1.address = trips.interChangeDepot.name
          }else if(trips.startFromDepot){
            events1.address = trips.orderLineItem.deleveryOrderLineItem.startDepot ? trips.orderLineItem.deleveryOrderLineItem.startDepot.address : null
          }else{
            events1.address = trips.orderLineItem.deleveryOrderLineItem.pickupBay ? trips.orderLineItem.deleveryOrderLineItem.pickupBay.name : trips.orderLineItem.deleveryOrderLineItem.pickupAddress
          }
        }else if(trips.type=="delivery"){
          if(trips.interChangeDepot){
            events1.address = trips.interChangeDepot.name
          }else if(trips.returnToDepot){
            events1.address = trips.orderLineItem.deleveryOrderLineItem.returnDepot ? trips.orderLineItem.deleveryOrderLineItem.returnDepot.address : null
          }else{
            events1.address = trips.orderLineItem.deleveryOrderLineItem.dropOffBay ? trips.orderLineItem.deleveryOrderLineItem.dropOffBay.name : trips.orderLineItem.deleveryOrderLineItem.dropOffAddress
          }
        }
        this.data.assignedOrders.forEach((assignedOrdersData : any)=> {
          if(assignedOrdersData.deliveryOrder.orderNumber === events1.orderNo){
            assignedOrdersData.deliveryOrderLineItemVMs.forEach((element: any) => {
              this.note = [];
              // element.deleveryOrderLineItemPackages.forEach((pack: any) => {
              //   this.note.push('('+pack.quantity+')' + '-' + pack.name );
              // })
              events1.note = this.note;
            })
          }
        })
        routes.deliveryOrders.forEach((order: any) => {
          if(order.deliveryOrderLite.id === events1.orderId){
            events1.orderCustomer = order.deliveryOrderLite.customer ? order.deliveryOrderLite.customer.name : null;
          }
        })
        events.push(events1);
        relatedEvents.push(events1);
      }
      })
      this.relatedVehicleEvents = relatedEvents;
      relatedEvents = [];
      resource1.relatedVehicleEvents = this.relatedVehicleEvents;
      resource.push(resource1);
    })
    this.orgResources = resource;
    this.resources = resource;
    this.filterResults2();//after drag and drop call
  }

  search(){
    //////debugger
   //this.loadData();
   this.filterResults2();
  }

  filterResults2(){
    for(var i=0;i<=this.orgResources.length-1;i++){
      console.log(this.orgResources[i].id," == ",this.search2)
      if(this.orgResources[i].id == this.search2){
        //this.resources[i].selectedCard = true;
        console.log(this.orgResources[i])
        this.dragableDataList = _.cloneDeep(this.orgResources[i]);
      }
      // else{
      //   this.resources[i].selectedCard = false;
      // }
    }
  }

  reorderSchedular() {
    // if(!this.search2){
    //   this.toaster.warn('', 'Select vehicle');
    // }
    if (!this.switchOrderForm.valid) {
      for (var i in this.switchOrderForm.controls) {
        this.switchOrderForm.controls[i].markAsTouched();
      }
      return false;
    }
    
    // else{
    //////debugger
    // console.log(this.dragableDataList)
    if (!this.formData) {
      var saveData: any = {
        "planId": this.data.draftPlanId,
        "routeId": '',
        "fleetDraftTrips": []
      }
      var routs_arr = this.data.routes.filter((route: any) =>
        route.vehicleData.vehicle.id == this.search2
      );
      saveData.routeId = routs_arr[0].id;
      for (var j = 0; j <= routs_arr[0].trips.length - 1; j++) {
        var list: any = { id: routs_arr[0].trips[j].id }
        saveData.fleetDraftTrips.push(list);
      }
      this.formData = saveData;
    }
    // this.formData = this.formData ? this.formData : saveData;
    // let unqArr: number[] = [];
    // if (this.formData.fleetDraftTrips) {
    //   this.formData.fleetDraftTrips = this.formData.fleetDraftTrips.filter((i: any) => {  if (unqArr.indexOf(i.id) == -1) { unqArr.push(i.id); return true } return false })
    // }
    // return ;
    this.spinner.show();
    this.planningHubService.swithOrderSameVeh(this.formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 200 || res.status === 201) {
        this.modalRef.hide();
        this.errorUtil.setErrorMessage(200, null, 'Changes updated successfully', 'success', 1000);
      }
      else {
        this.errorUtil.setErrorMessage(res.status, null, res.error.title, 'error', 3000);
      }
    })
    //}
  }


  log(event: any) {
    console.log("eventtttttttttttt ..................... ", event);
      this.formData = {
        planId: this.data.draftPlanId,
        routeId: event.data.routeId,
        fleetDraftTrips: this.dragableDataList?.relatedVehicleEvents? this.dragableDataList.relatedVehicleEvents.map((t:any)=>{return {id: t.tripId}}) :[]
      }
      if (this.dragableDataList.relatedVehicleEvents && (event.dropEffect === "copy" || event.dropEffect === "move")) {
        let index = typeof event.index === "undefined" ? this.dragableDataList.relatedVehicleEvents.length : event.index;

        let previousIndex = this.dragableDataList.relatedVehicleEvents.findIndex((l:any) => {
          if (l?.tripId == event.data.tripId) {
            l['orderingStatus'] = 'OLD'
            return true
          } else return false
        }); // you wont here in your solution
        // let currentIndex = index;
        // list[currentIndex] = list[previousIndex] + (list[previousIndex] =  list[currentIndex]) - list[currentIndex]; 
        this.dragableDataList?.relatedVehicleEvents.splice(index, 0, event.data);
        this.dragableDataList.relatedVehicleEvents = this.dragableDataList?.relatedVehicleEvents.filter((l:any) => l?.orderingStatus != 'OLD');
        this.formData.fleetDraftTrips = this.dragableDataList.relatedVehicleEvents.map((t:any)=> {return {id: t.tripId}})
      }  
    let routs = this.data.routes.filter((route: any) =>
      route.id == event.data.routeId
    );
    let trpId = {id:routs[0].trips[0].id}
    this.formData.fleetDraftTrips.unshift(trpId);
    // var list = {id:routs[0].trips[routs[0].trips.length-1].id}
    // this.formData.fleetDraftTrips.push(list);
  }

  logOld(event: any) {
    console.log("eventtttttttttttt ..................... ", event);
      this.formData = {
        "planId": '',
        "routeId": '',
        "fleetDraftTrips": []
      }
      this.formData.planId = this.data.draftPlanId;
      this.formData.routeId = event.data.routeId;
      for(var i=0;i<=this.dragableDataList.relatedVehicleEvents.length-1;i++){
        var list = {id:this.dragableDataList.relatedVehicleEvents[i].tripId}
        this.formData.fleetDraftTrips.push(list);
      }
    var arr = this.data.routes.filter((route: any) =>
      route.id == event.data.routeId
    );
    var list = {id:arr[0].trips[0].id}
    this.formData.fleetDraftTrips.unshift(list);
    var list = {id:arr[0].trips[arr[0].trips.length-1].id}
    this.formData.fleetDraftTrips.push(list);
  }

  // openModal(modalView,data) {
  //   this.modalRef.hide();
  //   if(modalView == 'modifyOption'){
  //     this.modalState = {
  //       title: 'Modify',
  //       mode: 'add',
  //       data : data
  //     };
  //   }
  //   this.config.initialState = this.modalState;
  //   this.modalRef = this.modalService.show(ModifyOptionComponent, this.config);
  // }

 
  /////////////////////////////////////Remove - return order///////////////////////////////////////////

  loadOrders() {
    var cards: any[] = [];
      this.data.assignedOrders.forEach((assignedOrdersData : any)=> {
          // assignedOrdersData.deliveryOrderLineItemVMs.forEach(element => {
            var assignedOrders = { orderNumber:'',orderNumberFormat:'',vehicleId:'',name:''};
            assignedOrders.orderNumber = assignedOrdersData.deliveryOrder.orderNumber;
            assignedOrders.name = assignedOrdersData.deliveryOrder.name;
            assignedOrders.orderNumberFormat = assignedOrdersData.deliveryOrder.orderNumber +'-'+ moment(assignedOrdersData.deliveryOrder.orderDateTime).format('DDMM');

              if(assignedOrdersData.deliveryOrderSummary.vehicle.vehicleNumber){
                //assignedOrders.vehicleNumber = assignedOrdersData.deliveryOrderSummary.vehicle.vehicleNumber;
                assignedOrders.vehicleId = assignedOrdersData.deliveryOrderSummary.vehicle.id;
              }
              cards.push(assignedOrders);

         // })
      })
      this.ordersData = cards;
      console.log(this.ordersData)
   }

   searchOrder(){
     ////debugger
    this.dragableDataList = [];
    this.orderNumberFormat = '';
    this.orderNumber = '';
    this.vehicleData = '';
    this.orderName = null
    console.log(this.orgResources)
    for(var i=0;i<=this.orgResources.length-1;i++){
      console.log(this.orgResources[i]," == ",this.searchOrderValue)
      this.orderNumberFormat = this.orgResources[i].orderNumberFormat;
      this.orderNumber = this.orgResources[i].orderNumber;
      this.vehicleData = this.orgResources[i].vehicleData;


      this.orgResources[i].relatedVehicleEvents.forEach((element: any) => {

          console.log(element.orderNumber," == ",this.searchOrderValue)
          if(element.orderNumber == this.searchOrderValue){
            this.orderName = element.orderName;
            this.dragableDataList.push(element);
            console.log(this.dragableDataList)
        }

      });

    }
   }

   selectCard(item: any,index: any){
     console.log(item,index)
    //  if(item.deliveryOrderWay=='pickup'||item.deliveryOrderWay=='delivery'||(item.deliveryOrderWay=='pickup_and_delivery'&&item.status!='WITH_DRIVER')){
      if((item.deliveryOrderWay != 'delivery' || item.deliveryOrderWay != 'pickup_and_delivery') && item.status != 'WITH_DRIVER'){
        this.errorUtil.setErrorMessage(400, null ,  'Should not select this order line item', 'warn',3000);
     }
     else{
       console.log(this.dragableDataList)
        this.lineItemId = this.dragableDataList[index].lineItemId;
        this.orderNumberFormat = this.dragableDataList[index].orderNumber;
        console.log(this.dragableDataList[index])
        for(var i=0;i<=this.dragableDataList.length-1;i++){
        if(i == index){
          this.dragableDataList[i].selectedCard = true;
        }
        else{
          this.dragableDataList[i].selectedCard = false;
        }
      }
     }
   }

   proceed(){
    this.switchOrderForm.controls['order'].setValidators([Validators.required]);
    this.switchOrderForm.controls['order'].updateValueAndValidity();

    this.switchOrderForm.controls['vehicle'].clearValidators();
    this.switchOrderForm.controls['vehicle'].updateValueAndValidity();
    if(!this.switchOrderForm.valid){
      for (var i in this.switchOrderForm.controls) {
        this.switchOrderForm.controls[i].markAsTouched();
      }
      return false;
    }
    if(!this.lineItemId){
      this.errorUtil.setErrorMessage(400, null ,  'Select order line item', 'warn',3000);
    }
    else{
      this.openModal('returnOrder','');
    }
  }

  openModal(modalView : any,data2 : any) {
    
    this.modalRef.hide();
    var data = _.cloneDeep(data2)
    if (modalView == "returnOrder") {
      var data1 = {btnName:'returnOrder',planId:this.data.draftPlanId,vehicleId:this.vehicleData.vehicle.id,deliveryOrderId:this.dragableDataList[0].orderId,lineItemId:this.lineItemId,orderNumberFormat:this.orderNumberFormat,depot:this.vehicleData.vehicle.depot.name}
      this.modalState = {
        data: data1,
        title: "Return Single Order",
      };
      this.selectedConfig = this.configSM;
      this.selectedComponent = PlanUpdateConfirmationComponent;
    }else if (modalView == "modifyPlan") {
      this.modalState = {
        title: "Modify Plan",
        mode: "add",
        data : data
      };
      this.selectedConfig = this.configCustomLg;
      this.selectedComponent = ModifyPlanComponent;
    }
    else{
      return;
    }
    this.selectedConfig.initialState = this.modalState;
    return this.modalRef = this.modalService.show(this.selectedComponent, this.selectedConfig);
  }
   
  onDragStart( event:DragEvent ) {
    this.currentDragEffectMsg = "";
    this.currentDraggableEvent = event;
    console.log(`Drag ended with effect "${event}"!`)
 }

  onDragged( item:any, list:any[], effect:DropEffect ) {
    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;
    console.log(effect)
    // if( effect === "move" ) { //todo remove
    //   const index = list.indexOf( item );
    //   list.splice( index, 1 );
    // }
  }

  onDragEnd( event:DragEvent ) {
    this.currentDraggableEvent = event;
  }

  onDrop(event: DndDropEvent, list?: any[]) {

    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      this.log(event)
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      let previousIndex = list.findIndex((l) => {
        if (l?.tripId == event.data.tripId) {
          l['orderingStatus'] = 'OLD'
          return true
        } else return false
      }); // you wont here in your solution
      // let currentIndex = index;
      // list[currentIndex] = list[previousIndex] + (list[previousIndex] =  list[currentIndex]) - list[currentIndex]; 
      list.splice(index, 0, event.data);
      list = list.filter((l) => l?.orderingStatus != 'OLD');
    }
  }

}
