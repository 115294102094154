<div class="custella-modal">
    <!-- Container -->
    <div class="custella-modal-header d-flex align-items-center" *ngIf="first">
        <div class="custella-modal-header-text">
            <i class="pi pi-arrow-left me-2" id="btn_TaskReturn" (click)="goBackToCreateTask()"></i>
            <span class="text-14 font-medium" id="title_TaskAssignToUsers"> {{ "assign_to_users" | translate }} </span>
        </div>
        <i class="pi pi-times"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
    </div>

    <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0" *ngIf="first" style="box-shadow: none;">
        <div>
            <div class="custella-form-container-content">
                <div class="custella-content-row pe-0">
                    <div class="custella-content-row-input col-12">
                        <ng-container>
                            <div *ngIf="assignMode == 'team'" id="label_TaskTeam" class="custella-content-row-input-title" translate>{{ "team" }}</div>
                            <div *ngIf="assignMode == 'user'" id="label_TaskUser" class="custella-content-row-input-title" translate>{{ "user" }}</div>

                            <div class="d-flex align-items-center">
                                <div *ngIf="assignMode == 'team'" class="p-input-icon-right col">
                                    <p-dropdown
                                        [placeholder]="'select_team' | translate"
                                        id="picklist_TaskTeamField"
                                        [options]="teams"
                                        optionLabel="teamName"
                                        name="team"
                                        (onChange)="selectTeam(assignTask.teamName)"
                                        [(ngModel)]="assignTask.teamName"
                                        [filter]="true"
                                        filterBy="teamName"
                                    ></p-dropdown>
                                </div>
                                <div *ngIf="assignMode == 'user'" class="p-input-icon-right col">
                                    <p-autoComplete
                                        [placeholder]="'select_user' | translate"
                                        [suggestions]="users"
                                        optionLabel="fullName"
                                        id="picklist_TaskUserField"
                                        name="users"
                                        [(ngModel)]="searchUserVal"
                                        (completeMethod)="getSearchedUserList(searchUserVal)"
                                        (onSelect)="addUser($event)"
                                    >
                                        <ng-template let-user pTemplate="item">
                                            <div class="d-flex align-items-center border p-1" style="border-radius: 4px;">
                                                <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" styleClass="mr-2 mt-2" shape="circle" id="img_TaskAvatar"></p-avatar>
                                                <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" id="img_TaskAvatar" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                                                <span class="ms-1" id="label_TaskUserFullName">{{ user?.fullName }}</span>
                                            </div>
                                        </ng-template>
                                    </p-autoComplete>
                                </div>
                                <div class="justify-content-between">
                                    <button type="button" class="border p-2 ms-1" id="btn_TaskResetUser" (click)="resetSelection('user')" [ngClass]=" assignMode == 'user' ? 'color-primary-bg text-white' : '' " style="border-radius: 4px;">
                                        <i class="pi pi-user fs-5 align-middle"></i>
                                    </button>
                                    <button type="button" class="border p-2 ms-1" id="btn_TaskResetTeam" (click)="callTeamApi('team')" [ngClass]=" assignMode == 'team' ? 'color-primary-bg text-white' : '' " style="border-radius: 4px;">
                                        <i class="pi pi-users fs-5 align-middle"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="assignMode === 'user'">
                    <div class="custella-form-container-content-border-bottom text-12 font-medium px-0" *ngFor="let user of assignTask?.userNames" style="min-height: unset;">
                        <div class="custella-content-row my-1 pe-0">
                            <div class="d-flex align-items-center my-2 col-12">
                                <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" styleClass="d-flex me-2" id="img_TaskUserAvatar" shape="circle"></p-avatar>
                                <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" styleClass="d-flex me-2" id="img_TaskUserAvatar" shape="circle"></p-avatar>
                                <span class="ms-1" id="label_TaskUserFullName">{{ user?.fullName }}</span>
                                <div class="action-container d-flex gap-1 ms-auto align-items-center">
                                    <button *ngIf="user.owner" id="btn_TaskCurrentOwner" class="border px-2 py-1 ms-1" [ngClass]="user.owner ? 'color-primary-bg text-white' : ''" style="border-radius: 4px; cursor: auto;">
                                        {{ "current_owner" | translate }}
                                    </button>
                                    <button *ngIf="!user.owner" id="btn_TaskMakeOwner" (click)="makeOwnerclick(user)" class="border px-2 py-1 ms-1" [ngClass]="user.owner ? 'color-primary-bg text-white' : ''" style="border-radius: 4px;">
                                        {{ "make_owner" | translate }}
                                    </button>
                                    <p class="p-error ms-3 hand cursor-pointer" id="btn_TaskRemoveOwner" (click)="removeUser(user)" translate>{{ "remove" }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="assignMode === 'team'">
                    <div class="custella-form-container-content-border-bottom text-12 font-medium px-0" *ngFor="let user of assignTask?.userNames" style="min-height: unset;">
                        <div class="custella-content-row my-1 pe-0">
                            <div class="d-flex align-items-center my-2 col-12">
                                <p-avatar *ngIf="user.imageUrl" [image]="user.imageUrl" id="img_TaskTeamAvatar" styleClass="d-flex me-2" shape="circle"></p-avatar>
                                <p-avatar *ngIf="!user.imageUrl" image="assets/svg/Avatar.svg" id="img_TaskTeamAvatar" styleClass="d-flex me-2" shape="circle"></p-avatar>
                                <span class="ms-1" id="img_TaskTeamFullName">{{ user?.fullName }}</span>
                                <div class="action-container d-flex gap-1 ms-auto align-items-center">
                                    <button type="button" id="btn_TaskCurrentTeamOwner" *ngIf="user.owner" class="border px-2 py-1 ms-1" [ngClass]="user.owner ? 'color-primary-bg text-white' : ''" style="border-radius: 4px; cursor: auto;">
                                        {{ "current_owner" | translate }}
                                    </button>
                                    <button type="button" *ngIf="!user.owner" id="btn_TaskMakeTeamOwner" (click)="makeOwnerclick(user)" class="border px-2 py-1 ms-1" [ngClass]="user.owner ? 'color-primary-bg text-white' : ''" style="border-radius: 4px;">
                                        {{ "make_owner" | translate }}
                                    </button>
                                    <button type="button" class="border px-2 py-1 ms-1" id="btn_TaskMakeTeamMember" (click)="makeMember(user)" [disabled]="user.owner" [ngClass]="user.owner ? 'disabled' : ''" [ngStyle]="{'background': user.member ? '#324954' : '#FFFFFF', 'color': user.member ? '#FFFFFF' : '#000000'}" style="border-radius: 4px;">
                                        {{ "member" | translate }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="custella-form-container-footer mb-0">
            <div class="d-flex align-items-center justify-content-end">
                <p-button styleClass="ms-2 h-32" id="btn_TaskAssign" [disabled]="submitTouched" type="button" *ngIf="parentMode === 'add' || parentMode === 'add&back'" label="{{ 'assign' | translate }}" (onClick)="onSubmit('quick')"> </p-button>
            </div>
        </div>
    </div>
</div>
