<!-- <div class="modal-content modal-fullscreen p-0"> -->
  <div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">
        <span class="text-16 font-medium ms-3">{{title}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>

<!---------------- CONFRIM, DISPATCH, COMPLETE or INCOMPLETE BUTTTON -------------------------->
<form *ngIf="(data.btnName!='confirmCancelBtn')&&(data.btnName!='dispatchedCancelBtn')&&(data.btnName !='inprogressCancelBtn')&&(data.btnName!='proceedAddOrderSpeVeh')&&(data.btnName!='proceedAddOrderCurrVeh')&&(data.btnName!='removeOrder')&&(data.btnName!='removeVehicle')&&(data.btnName!='breakdown')&&(data.btnName!='cancelOrder')&&(data.btnName!='returnOrder')&&(data.btnName!='changeVehicle')">
 
  <div class="modal-body pt-0">
    <div class="container text-center px-0" style="padding: 20px;">
      <!-- CONFIRM BUTTON (if draft) -->
      <p *ngIf="data.btnName==='confirmBtn'" class="mb-0 text-14 pb-2 px-3">{{data.planNumber}} will now be marked as 'Confirmed'.</p>
      <!-- DISPATCH BUTTON (if confirmed) -->
      <p *ngIf="data.btnName==='dispatchBtn'" class="mb-0 text-14 pb-2 px-3">{{data.planNumber}} will now be dispatched and all drivers will be notified of their orders.</p>
      <!-- COMPLETE BUTTON (if dispatched) -->
      <p *ngIf="data.btnName==='completeBtn'" class="mb-0 text-14 pb-2 px-3">{{data.planNumber}} will now be marked as 'Completed'.</p>
      <!-- INCOMPLETE BUTTON (if dispatched) -->
      <p *ngIf="data.btnName==='inCompleteBtn'" class="mb-0 text-14 pb-2 px-3">{{data.planNumber}} will now be marked as 'Incomplete'.</p>
      <p class="mb-0 text-14 pb-2 px-3">Do you wish to continue?</p>
    </div>
  </div>
  <div class="modal-footer p-0">
    <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
    <p-button type="button" (click)="onSubmit()"  label="{{'yes' | translate}}"></p-button>
</div>

</form>

<!------------------------------- CANCEL PLAN BUTTTON ------------------------------------------------------------>
<form *ngIf="(data.btnName === 'confirmCancelBtn') || (data.btnName === 'dispatchedCancelBtn') || (data.btnName === 'inprogressCancelBtn')">
  
    <div class="modal-body pt-0">
      <!-- CANCELLING WHEN CONFIRMED  -->
      <div *ngIf="data.btnName==='confirmCancelBtn'" class="container text-center px-0 pb-0" style="padding: 20px;">
        <p class="mb-0 text-14 pb-2 px-3">By cancelling {{data.planNumber}}, the status of ALL its orders will be changed from--</p>
        <div class="d-flex align-items-center justify-content-center px-3">
          <div class="py-2 me-3" style="border-radius: 6px; background-color: #78B4BC; width: 80px;">
            <div class="d-flex align-items-center">
              <div class="m-auto">
                <p class="mb-0 text-12 font-medium font-white">Confirmed</p>
              </div>
            </div>
          </div>
          <!-- <i class="pi pi-arrow-right me-3"></i> -->
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="formData.dispatched" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="dropDownData" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <p class="mb-0 text-12pt-2 pb-4 px-3 f-grey" translate>{{'keep_as_default_no_status_change'}}</p>
        <p class="mb-0 text-14 pb-2 px-3">Are you sure you want to cancel {{data.planNumber}}?</p>
      </div>
      <!-- CANCELLING WHEN DISPATCHED -->
      <div *ngIf="data.btnName==='dispatchedCancelBtn'" class="container text-center px-0 pb-0" style="padding: 20px;">
        <p class="mb-0 text-14 pb-2 px-3">By cancelling {{data.planNumber}}, the status of its orders will be changed as follows</p>
        <div *ngIf="data.deliveryOrderStatusCountVM.dispatchedCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <span class="me-3 p-tag p-component p-tag-dispatched" ><span class="p-tag-value text-dark">Dispatched</span></span>
                      
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            
            <p-dropdown [(ngModel)]="formData.dispatched" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="dropDownData" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <div *ngIf="data.deliveryOrderStatusCountVM.inprogressCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <span class="me-3 p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">In Progress</span></span>
                        
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
          
            <p-dropdown [(ngModel)]="formData.inProgress" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
                        [options]="dropDownData4" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <div *ngIf="data.deliveryOrderStatusCountVM.onHoldCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <div class="py-2 me-3" style="border-radius: 6px; background-color: #D96E12; width: 80px;">
            <div class="d-flex align-items-center">
              <div class="m-auto">
                <p class="mb-0 text-12 font-medium font-white">On Hold</p>
              </div>
            </div>
          </div>
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="formData.onHold" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
                        [options]="dropDownData3" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <div *ngIf="data.deliveryOrderStatusCountVM.inCompletedCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">Incomplete</span></span>
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="formData.inComplete" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="dropDownData2" optionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <p class="mb-0 text-12 pt-2 pb-4 px-3 f-grey" translate>{{'keep_as_default_no_status_change'}}</p>
        <p class="mb-0 text-14 pb-2 px-3">Are you sure you want to cancel {{data.planNumber}}?</p>
      </div>
      <!-- CANCELLING WHEN IN_PROGRESS -->
      <div *ngIf="data.btnName==='inprogressCancelBtn'" class="container text-center px-0 pb-0" style="padding: 20px;">
        <p class="mb-0 text-14 pb-2 px-3">By cancelling {{data.planNumber}}, the status of its orders will be changed as follows</p>
        <div *ngIf="data.deliveryOrderStatusCountVM.inprogressCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <span class="me-3 p-tag p-component p-tag-in-progress" ><span class="p-tag-value text-dark">In Progress</span></span>
                      
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="formData.inProgress" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="dropDownData4" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <div *ngIf="data.deliveryOrderStatusCountVM.onHoldCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <div class="py-2 me-3" style="border-radius: 6px; background-color: #D96E12; width: 80px;">
            <div class="d-flex align-items-center">
              <div class="m-auto">
                <p class="mb-0 text-12 font-medium font-white">On Hold</p>
              </div>
            </div>
          </div>
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
           
            <p-dropdown [(ngModel)]="formData.onHold" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="dropDownData3" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <div *ngIf="data.deliveryOrderStatusCountVM.inCompletedCount" class="d-flex align-items-center justify-content-center px-3 pb-1">
          <span class="p-mr-2  p-tag p-component p-tag-incomplete" ><span class="p-tag-value text-dark">Incomplete</span></span>
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="formData.inComplete" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="dropDownData2" optionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <p class="mb-0 text-12 pt-2 pb-4 px-3" translate>{{'keep_as_default_no_status_change'}}</p>
        <p class="mb-0 text-14 pb-2 px-3">Are you sure you want to cancel {{data.planNumber}}?</p>
      </div>
  </div>
 
    <div class="modal-footer p-0">
      <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
      <p-button type="button" (click)="onSubmit1()"  label="{{'yes' | translate}}"></p-button>
  </div>

</form>

<!-------------------------- plan - view - modify - conformation pop up --------------------------------------->
<form *ngIf="data.btnName=='proceedAddOrderSpeVeh'||data.btnName=='proceedAddOrderCurrVeh'||(data.btnName=='removeOrder'&&(data.planStatus!='CONFIRMED'&&data.planStatus!='DISPATCHED'))||data.btnName=='removeVehicle'||data.btnName=='cancelOrder'||data.btnName=='returnOrder'||data.btnName=='changeVehicle'">
  <div class="modal-header py-1 pr-3" style="border-bottom: transparent;">
    
  </div>
  <div class="modal-body pt-3">
    <div class="container text-center px-0">
      <p class="mb-0 pb-2 px-3" style="font-size: 16px; font-weight: bold;">Are you sure?</p>
      <p *ngIf="data.btnName=='proceedAddOrderSpeVeh'" class="mb-0 text-14 pb-2 px-3">{{data.orderNumber}} will be added to {{data.vehicleName}}</p>
      <p *ngIf="data.btnName=='proceedAddOrderCurrVeh'" class="mb-0 text-14 pb-2 px-3">{{data.orderNumber}} will be added to the optimal vehicle in {{data.planNumber}}</p>
      <p *ngIf="data.btnName=='removeOrder'" class="mb-0 text-14 pb-2 px-3">{{data.deliveryOrderName}} will be removed from {{data.planNumber}}</p>
      <p *ngIf="data.btnName=='removeVehicle'" class="mb-0 text-14 pb-2 px-3">{{data.vehicleNames}} will be removed from {{data.planNumber}}</p>
      <p *ngIf="data.btnName=='cancelOrder'" class="mb-0 text-14 pb-2 px-3">Order {{data.orderName}} will be canceled</p>
      <p *ngIf="data.btnName=='returnOrder'" class="mb-0 text-14 pb-2 px-3">{{data.orderNumberFormat}} will be returned to {{data.depot}}</p>
      <!-- <p *ngIf="data.btnName=='changeVehicle'" class="mb-0 text-14 pb-2 px-3">[{{data.driverName}}] will be driving [{{data.vehicleName}}]</p> -->
      <p *ngIf="data.btnName=='changeVehicle'" class="mb-0 text-14 pb-2 px-3">{{data.oldVehicleNumber}} - {{data.oldDriverName}} will be changed to {{data.newVehicleNumber}} - {{data.newDriverName}}</p>
      <!-- <p *ngIf="data.btnName=='breakdown'" class="mb-0 text-14 pb-2 px-3">Vehicle {{data.vehicleNumber}} and all its orders will be returned to {{data?.vehicleDepot?.name}}</p> -->
    </div>
  </div>
  <!-- footer -->

  <div class="modal-footer p-0">
    <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
    <p-button type="button" (click)="confirmModel()"  label="{{'confirm' | translate}}"></p-button>
</div>

</form>

<!-------------------------- plan - view - modify (remove order) - conformation pop up --------------------------------------->

<form *ngIf="data.btnName=='removeOrder'&&(data.planStatus=='CONFIRMED'|| data.planStatus=='DISPATCHED')">
 <div class="modal-body pt-0">
      <div class="container text-center px-0 pb-0" style="padding: 20px;">
        <p class="mb-0 text-14 pb-2 px-3">Order {{data.orderNumber}} will be removed from the plan and its status will be changed from</p>
        <div class="d-flex align-items-center justify-content-center px-3">
          <div class="py-2 me-3" style="border-radius: 6px; background-color: #78B4BC; width: 80px;">
            <div class="d-flex align-items-center">
              <div class="m-auto">
                <p class="mb-0 text-12 font-medium font-white">{{data.deliveryOrderStatus | titlecase}}</p>
              </div>
            </div>
          </div>
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="removeOrderStatus" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="doStatuses" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <p class="mb-0 text-12 pt-2 pb-4 px-3" translate>{{'keep_as_default_no_status_change'}}</p>
        <p class="mb-0 text-14 pb-2 px-3" translate>{{'are_you_sure_remove_order'}}</p>
      </div>
  </div>
  
    <div class="modal-footer p-0">
      <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
      <p-button type="button" (click)="removeOrder()"  label="{{'yes' | translate}}"></p-button>
  </div>
  
</form>


<!-------------------------- plan - view - modify (breakdown) - conformation pop up --------------------------------------->

<form *ngIf="data.btnName=='breakdown'">
    <div class="modal-body pt-0">
      <div class="container text-center px-0 pb-0" style="padding: 20px;">
        <p class="mb-0 text-14 pb-2 px-3">Vehicle {{data.vehicleName}} will return to {{data.vehicleDepot?.name}} and all its orders will be changed from</p>
        <div class="d-flex align-items-center justify-content-center px-3">
          <div class="py-2 me-3" >
            <div class="d-flex align-items-center">
              <div class="m-auto">
                <span class="p-mr-2 p-tag p-component ms-2 text-center" [ngStyle]="{'background-color': orderStatusColor}">
                  <span class="p-tag-value text-dark">{{orderStatusName}}</span>
              </span>
                <!-- <p class="mb-0 text-12 font-medium font-white">{{data.orderStatuses}}</p> -->
              </div>
            </div>
          </div>
          <i class="pi pi-arrow-right me-3"></i>
          <div class="text-center py-2 px-3 d-flex align-items-center" style="border-radius: 6px; background-color: #f0f0f0; width: 135px;">
            <p-dropdown [(ngModel)]="breakdownStatus" [ngModelOptions]="{standalone: true}"  name="status" class="col-12"
            [options]="doStatuses" ptionLabel="label" optionValue="name" [showClear]="false" placeholder="Select Status"></p-dropdown>

          </div>
        </div>
        <p class="mb-0 text-14 py-2 px-3" translate>{{'would_you_like_to'}}</p>
      </div>
  </div>
    <div class="modal-footer p-0">
      <button type="button"  class="custella-btn-white mx-1 text-12" (click)="modalRef.hide()">No</button>
      <p-button type="button" (click)="breakdown()"  label="{{'yes' | translate}}"></p-button>
  </div>
</form>

