<form class="custella-custom-dialog-no" >
  <div class="modal-header p-dialog-header flex-between">
    <div class=" d-flex align-items-center">
      <img src="assets\svg\dialog\Confirmation-w.svg">  
      <span class="text-16 font-medium ms-3" id="title_VerifyRequis">{{title}}</span>
    </div>
      <i class="pi pi-times w-24px" id="btn_VerifyRequisClose" [pTooltip]="'close'|translate" tooltipPosition="bottom" (click)="modalRef.hide()"></i>
  </div>
    <div class="modal-body">
    <div class="container">   
        <div class="custella-content-row-input col-12 mb-3">
          <span class="custella-content-row-input-title" id="label_VerifyRequisComment" translate>{{'verification_comment'}}</span>
          <textarea class="form-control txt-a" rows="3" id="input_VerifyRequisCommentField" [(ngModel)]="comments" [maxlength]="320000" [ngModelOptions]="{standalone: true}"></textarea>  
        </div>                 
        <div class="custella-content-row-input col-12 mb-3" *ngIf="data.type=='Approve'">
            <span class="custella-content-row-input-title" id="label_VerifyPicToNotify" translate>{{'pic_to_notify'}}</span>
            <p-dropdown [disabled]="data.type=='Reject' && submitBtnDisabled"
            id="picklist_VerifyPicToNotifyField"
            [ngModelOptions]="{standalone: true}" 
            name="picToNotify"  
            [(ngModel)]="picToNotify"
            optionLabel="fullName" 
            class="w-100" [filter]="true" 
            name="company" [options]="users" [showClear]="true" optionValue="id"
            placeholder="Select User">
            </p-dropdown>
        </div>
        <div class="custella-content-row-input col-12" *ngIf="data.type=='Approve'">
            <div class="d-flex position-relative align-items-center">
              <div class="p-field-checkbox">
                  <p-checkbox [binary]="true" id="check_SubmitToHod" [disabled]="data.type=='Reject' && submitBtnDisabled" [(ngModel)]="submitToHod"  [ngModelOptions]="{standalone: true}"></p-checkbox>
              </div>
              <span class="text-12 ms-2 mt-1" id="label_SubmitToHod">{{'submit_to_hod' | translate}}</span>
          </div> 
        </div>
    </div>
  </div>
  <div class="modal-footer p-0">
      <button class="custella-btn-white ms-2 text-12" id="btn_VerifyRequisCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
    
      <p-button *ngIf="data.type=='Approve'" type="button" id="btn_VerifyRequisApprove" (click)="approve()" styleClass="ms-2 h-32 p-button-success" label="{{'approve' | translate}}"></p-button>
      <p-button *ngIf="data.type=='Reject'" type="button" id="btn_VerifyRequisReject" (click)="reject()" styleClass="ms-2 h-32 p-button-danger" label="{{'reject' | translate}}"></p-button>
      <p-button *ngIf="data.type=='HODApprove'" type="button" (click)="approve1()" id="btn_VerifyRequisHodApprove" styleClass="ms-2 h-32 p-button-success" label="{{'approve' | translate}}"></p-button>
      <p-button *ngIf="data.type=='HODReject'" type="button" (click)="reject1()" id="btn_VerifyRequisHodReject" styleClass="ms-2 h-32 p-button-danger" label="{{'reject' | translate}}"></p-button>
      <p-button *ngIf="data.type=='consignmentApprove'" type="button" (click)="approve2()" id="btn_VerifyRequisConsignmentApprove" styleClass="ms-2 h-32 p-button-success" label="{{'approve' | translate}}"></p-button>
      <p-button *ngIf="data.type=='consignmentReject'" type="button" (click)="reject2()" id="btn_VerifyRequisConsignmentReject" styleClass="ms-2 h-32 p-button-danger" label="{{'reject' | translate}}"></p-button>
  </div>
</form>



 
    