import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from 'src/app/auth/auth-guard.service';
import { AddEditTaskComponent } from './add-edit-task/add-edit-task.component';
import { FqcCreateComponent } from './fqc/fqc-create/fqc-create.component';
import { FqcViewComponent } from './fqc/fqc-view/fqc-view.component';
import { IqcFqcComponent } from './iqc-fqc.component';
import { IqcCreateComponent } from './iqc/iqc-create/iqc-create.component';
import { IqcViewComponent } from './iqc/iqc-view/iqc-view.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'all',
    pathMatch: 'full'
  },
  {
    path: 'all',
    component: IqcFqcComponent,
    // canActivateChild: [AuthGuardService],
    // data: { module: "customers", "action": "read" }//set module iqc-fqc
    data: {navigation: {root: true} }
  },
  {
    path: 'iqc-view/:id',
    component: IqcViewComponent,
    canActivate: [AuthGuardService],
    data: { module: "job order", "action": "read" }
  },
  {
    path: 'fqc-view/:id',
    component: FqcViewComponent ,
    canActivate: [AuthGuardService],
    data: { module: "job order", "action": "read" }
  },
  {
    path: 'iqc-create/:id',
    component: IqcCreateComponent,
    canActivate: [AuthGuardService],
    data: { module: "job order", "action": "read", navigation: {isAddEdit: true} }
  },
  {
    path: 'fqc-create/:id',
    component: FqcCreateComponent,
    canActivate: [AuthGuardService],
    data: { module: "job order", "action": "read", navigation: {isAddEdit: true} }
  },
  {
    path: 'fqc-create/:id/task',
    component: AddEditTaskComponent,
    canActivate: [AuthGuardService],
    data: { module: "job order", "action": "read", navigation: {isAddEdit: true} }

  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FqcIqcRoutingModule { }
