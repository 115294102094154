<div class="left-right-space">
    <div class="flex-between my-3">
        <!-- <div class="main-list-header" *ngIf="!noSalesOrders"> -->
        <div class="main-list-header">
            {{ "my_tasks" | translate }}
        </div>
        <!-- Filter and New -->
        <div class="custella-dashboard-top-right d-flex">
            <p-dropdown optionLabel="name" placeholder="Select Day"></p-dropdown>
            <div class="dropdown ms-1">
                <div class="p-dropdown p-component p-dropdown-clearable" data-bs-toggle="dropdown"  >
                  <button class="custella-btn-icon no-border rounded-1 px-2 py-2" > <i class="pi pi-ellipsis-v pt-04"></i> </button>
                </div>
                <ul class="dropdown-menu dropdown-collaps p-0">
                  <li class="dropdown-item px-3 py-3 cursor-pointer">Example 1</li>
                  <li class="dropdown-item px-3 py-3 cursor-pointer">Example 2</li>
                </ul>
             </div>
            <p-button label="New" icon="pi pi-plus" styleClass="ms-2 h-32"></p-button>
            <p-button label="Optimize" icon="pi pi-plus" styleClass="ms-2 h-32" (click)="optimizeTask()"></p-button>
            <p-button label="Clone" icon="pi pi-clone" styleClass="ms-2 h-32" (click)="cloneTask()"></p-button>
        </div>
    </div>
</div>

<!-- <div *ngIf="noSalesOrders" class="col-12 mt-5 py-4"> -->
<div class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
        <img src="assets\svg\empty-state.svg" alt="" />
        <p class="text-6 my-1 font-weight-bold" translate>
            {{ "no_records_found" }}
        </p>
    </div>
</div>