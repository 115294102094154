<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <div class="custella-modal-header-text">
                <span class="text-14 font-medium" id="title_TaskUpdateLoc" translate> Update Location of the Following Tasks </span>
            </div>
            <i class="pi pi-times" id="btn_TaskUpdateLoc"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
        </div>
        <div class="custella-form-container position-relative d-block w-100 rounded-2 m-0 pt-3" style="height: fit-content;">
            <!-- Container Content -->
            <div class="custella-form-container-content m-0">
                <div class="custella-content-row mb-3 pe-0" *ngFor="let task of data; let i = index">
                    <form [formGroup]="updateLocationForm[i]" class="w-100">
                    <!------------------------------ Task View Header -------------------------------->
                        <div class="custella-form-container-two position-relative d-block rounded-2 mb-3 mx-0"  [ngStyle]="{'border-left': 'solid 8px '+task?.selectedPriority?.color }">
                            <div class="p-4">
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="text-24 font-medium" id="value_TaskLocName">{{task?.taskName}}</div>
                                </div>

                                <div class="row">
                                    <div class="col-12 d-flex align-items-center justify-content-start">
                                        <div class="me-2 d-flex align-items-center">
                                            <i class="pi pi-clock"></i>
                                            <span class="text-12 font-medium mx-2" id="value_TaskLocStartEndDate">{{task?.startDateTime | momentDate :'hh:mm a' : timezone}} - {{task?.endDateTime | momentDate :'hh:mm a' : timezone}}</span>
                                        </div>

                                        <div class="d-flex align-items-center">
                                            <i class="pi pi-calendar"></i>
                                            <span class="text-12 font-medium mx-2" id="value_TaskLocStartTime">{{task?.startDateTime | momentDate : dateFormat : timezone}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="text-12 font-medium" id="value_TaskLocView">{{task?.locationView}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="custella-content-row-input col-12 me-0">
                            <span class="custella-content-row-input-title" id="label_TaskLocNewLocation" translate>New location </span>
                            <div class="d-flex w-100 col">
                                <div class="d-flex align-items-center col">
                                    <div class="col">
                                        <input
                                            pInputText autocomplete="off"
                                            placeholder="Search for Location"
                                            autocorrect="off"
                                            autocapitalize="off"
                                            spellcheck="off"
                                            id="input_TaskLocNewLocationField"
                                            type="text"
                                             ngx-google-places-autocomplete (ngModelChange)="resetPlaceId(i)" [(ngModel)]="task.locationFull"  formControlName="location" name="location" #placesRef="ngx-places"  (onAddressChange)="handleAddressChange(i, $event)"
                                        />
                                        <div class="p-error text-10" *ngIf=" updateLocationForm[i].controls['location'].touched && updateLocationForm[i].controls['location'].invalid " >
                                            <span *ngIf=" updateLocationForm[i].controls['location'].errors?.required " id="label_TaskLocNewLocationReqField" translate > {{ "required_field" }}</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
               
               
            </div>
             <!-- Container Footer -->
             <div class="custella-form-container-footer mb-0">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 mx-2" id="btn_TaskLocCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
                    <p-button label="Save" (onClick)="onSubmit()" id="btn_TaskLocSubmit"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>