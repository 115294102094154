import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
  GridApi,
  GridOptions,
  GridParams,
  IServerSideGetRowsParams,
} from "ag-grid-community";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, map, take } from "rxjs/operators";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { ChubbDashboardAppEvents } from "src/app/_models/global.data.model";
import { ChubbServicesService } from "src/app/_services/chubb-services.service";
import { TeamService } from "src/app/_services/team.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { techniciansTableColumns } from "../../chubb-data/chubb-table-columns";

@Component({
  selector: "app-chubb-technician-table",
  templateUrl: "./chubb-technician-table.component.html",
  styleUrls: ["./chubb-technician-table.component.scss"],
})
export class ChubbTechnicianTableComponent
  extends SubscriptionUtil
  implements OnInit
{
  isFilterToggleOn = false;

  public noData: boolean = false;
  public rowData = [];

  totalRecord: any;

  public techTable = {
    selectedTimeframe: "daily",
    selectedTeam: null,
    selectedTechnician: -1,
    timeFrames: <any[]>[],
    teams: <any[]>[],
    technicians: <any[]>[],
    currentSearchValue: "",

    table: {
      gridAPI: <GridApi>{},
      gridParams: <GridParams>{},
      rowParams: <IServerSideGetRowsParams>(<unknown>null),
      gridOptions: <GridOptions>{
        paginationPageSize: 25,
        rowModelType: "infinite",
        sortingOrder: ["desc", "asc"],
        onGridSizeChanged: (params: any) => {
          params.api.sizeColumnsToFit();
        },
        tooltipShowDelay: 100,
      },

      defaultColDef: {
        enableRowGroup: false, //ag-Grid-Enterprise
        enablePivot: false, //ag-Grid-Enterprise
        enableValue: false, //ag-Grid-Enterprise
        sortable: true,
        resizable: true,
        filter: true,
        tooltipComponent: TableHeaderToolTipComponent,
      },

      overlayNoRowsTemplate:
        '<span style="padding: 10px; font-size: 14px;">Record not found</span>',

      sidebar: null,
    },
  };

  @ViewChild("searchTableInput") searchTableInput!: ElementRef;

  constructor(
    private router: Router,
    private chubbService: ChubbServicesService,
    private teamService: TeamService,
    private auth: AuthenticationService,
    private utilService: UtilServiceService,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.techTable.timeFrames = this.chubbService.getTimeFrames("standard");

    this.push(
      this.utilService.getData().subscribe((data: any) => {
        if (
          data &&
          data.action === ChubbDashboardAppEvents.REFRESH_PANEL_THREE
        ) {
          this.getData();
        }

        if (
          data &&
          data.action === ChubbDashboardAppEvents.DOWNLOAD_TECHNICIAN_DATA
        ) {
          this.downloadData();
        }
      })
    );

    // get all teams
    this.push(
      this.teamService
        .getAll(this.auth.getUserId())
        .pipe(take(1))
        .subscribe(
          (resp: any) => {
            const allTeams = resp.body;

            if (allTeams && allTeams.length > 0) {
              allTeams.sort((teamA: any, teamB: any) =>
                teamA.teamName
                  .toLowerCase()
                  .localeCompare(teamB.teamName.toLowerCase())
              );

              // set selected team
              this.techTable.teams = allTeams;
              this.techTable.selectedTeam = allTeams[0].id;

              // get all team users
              this.updateTechnicians(-1);
            }

            console.log(this.noData, allTeams);
          },
          (error: any) => {
            console.error(error);

            this.errorUtil.setErrorMessage(
              error.status,
              null,
              error.error.title,
              "error",
              2000
            );
          }
        )
    );
  }

  /******************************************** Init ******************************************/
  private init(): void {
    this.setupTable().then(() => {
      this.getData();
    });

    this.push(
      fromEvent(this.searchTableInput.nativeElement, "input")
        .pipe(
          map((event: any) => (event.target as any).value),
          debounceTime(500),
          distinctUntilChanged()
        )
        .subscribe((value: any) => {
          this.techTable.currentSearchValue = value;

          this.getData();
        })
    );
  }

  /******************************************** comment ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Get Data ******************************************/
  private getData() {
    let params = this.generateBaseParams();

    this.searchData(this.techTable.table.gridAPI, params);
  }

  /******************************************** Generate Base Params ******************************************/
  private generateBaseParams() {
    let params = {
      duration: this.techTable.selectedTimeframe,
      teamId: this.techTable.selectedTeam,
    };

    if (this.techTable.selectedTechnician !== -1) {
      params = Object.assign(params, {
        technicianId: this.techTable.selectedTechnician,
      });
    }

    if (this.techTable.currentSearchValue !== "") {
      params = Object.assign(params, {
        search: this.techTable.currentSearchValue,
      });
    }

    return params;
  }

  /******************************************** Search Data ******************************************/
  public async searchData(gridAPI: any, paramObj: any) {
    return new Promise<string | void>((resolve) => {
      if (gridAPI && "hideOverlay" in gridAPI) {
        gridAPI.hideOverlay();
        this.rowData = [];
      }

      var dataSource = {
        getRows: (rowParams: IServerSideGetRowsParams) => {
          this.techTable.table.rowParams = rowParams;

          const params = this.processParamsObject(
            this.techTable.table.rowParams,
            this.techTable.table.gridAPI,
            this.techTable.table.gridOptions,
            paramObj
          );

          this.push(
            this.chubbService
              .getAllTechniciansByTeam(params)
              .pipe(take(1))
              .subscribe(
                (resp) => {
                  if (resp.data && resp.total > 0) {
                    this.rowData = resp.data;
                    this.totalRecord = resp.total;
                    rowParams.successCallback(this.rowData, resp.total);
                  } else {
                    this.rowData = [];
                    this.totalRecord = 0;
                    this.techTable.table.gridAPI.showNoRowsOverlay();
                    rowParams.successCallback([], 0);
                  }

                  resolve();
                },
                (error) => {
                  console.error(error);

                  this.errorUtil.setErrorMessage(
                    error.status,
                    null,
                    error.error.title,
                    "error",
                    2000
                  );
                }
              )
          );
        },
      };

      if ("setDatasource" in gridAPI && "sizeColumnsToFit" in gridAPI) {
        gridAPI.setDatasource(dataSource);
        gridAPI.sizeColumnsToFit();
      }
    });
  }

  /******************************************** Process Params ******************************************/
  private processParamsObject(
    rowParams: any,
    gridAPI: GridApi,
    gridOptions: GridOptions,
    additionalParams?: any
  ) {
    let paramObj = {};

    let startRow = 0;
    let page = 0;
    let perPage = 0;

    startRow = rowParams ? rowParams.startRow + 1 : 1;
    page = gridAPI.paginationGetCurrentPage
      ? gridAPI.paginationGetCurrentPage() + 1
      : 1;
    perPage = gridOptions.paginationPageSize
      ? gridOptions.paginationPageSize
      : 25;

    const p = {
      startFrom: startRow,
      page: page,
      perPage: perPage,
    };

    paramObj = Object.assign(paramObj, { ...p });

    if (additionalParams) {
      // merge params
      paramObj = Object.assign(paramObj, additionalParams);
    }

    // check of sort of a column
    // needs to go last to override sorting and which column to sort
    const o = {
      sortOrder: "asc",
      columnName: "technician",
    };

    if (rowParams && Object.keys(rowParams).length >= 1) {
      const sort = rowParams.sortModel[0] ? rowParams.sortModel[0].sort : "asc";

      const colId = rowParams.sortModel[0]
        ? rowParams.sortModel[0].colId
        : "technician";

      o.sortOrder = sort;
      o.columnName = colId;
    }

    paramObj = Object.assign(paramObj, { ...o });

    return paramObj;
  }

  /******************************************** Update Technicians ******************************************/
  private updateTechnicians(id: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.push(
        this.teamService
          .getEmployeeByTeam(this.techTable.selectedTeam, true, true)
          .pipe(take(1))
          .subscribe(
            (allTechnicians) => {
              const tempAllTechniciansArr = [
                {
                  userId: -1,
                  userFirstName: "All",
                  userLastName: "",
                  fullName: "",
                },
              ];

              if (allTechnicians && allTechnicians.length > 0) {
                if (allTechnicians && allTechnicians.length > 0) {
                  const temp = [...tempAllTechniciansArr, ...allTechnicians];
                  temp.forEach((tech) => {
                    tech["fullName"] =
                      tech.userFirstName + " " + tech.userLastName;
                  });

                  this.techTable.technicians = temp.sort((techA, techB) =>
                    techA.fullName
                      .toLowerCase()
                      .localeCompare(techB.fullName.toLowerCase())
                  );

                  this.techTable.selectedTechnician = id;
                }
              } else {
                this.techTable.technicians = tempAllTechniciansArr;
              }

              resolve();
            },
            (error) => {
              console.log(error);
              reject();
            }
          )
      );
    });
  }

  /******************************************** Setup Table ******************************************/
  private setupTable(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.techTable.table.gridAPI.setColumnDefs(techniciansTableColumns);

        resolve();
      }, 1000);
    });
  }

  /******************************************** On Grid Ready ******************************************/
  public onGridReady(gridParams: any) {
    this.techTable.table.gridParams = gridParams;
    this.techTable.table.gridAPI = gridParams.api;
  }

  /******************************************** On Click ******************************************/
  public onClickCell($event: any) {
    this.utilService.resetTabView();

    const col = $event.column.colId;
    let data = $event.data;

    if (col === "technician") {
      const id = data.id;
      const name = data.technician;
      const teamId = this.techTable.selectedTeam;

      this.chubbService.setTempData({
        action: ChubbDashboardAppEvents.DATA_PASS_BY,
        data: {
          current: {
            duration: this.techTable.selectedTimeframe,
          },
        },
      });

      if (teamId) {
        this.router.navigate([
          `/dashboard/chubb/tasksByTechnicians/${id}/${name}/${teamId}`,
        ]);
      } else {
        throw new Error("No Teams");
      }
    }
  }

  /******************************************** Table Context Menu ******************************************/
  public getContextMenuItems = (params: any) => {
    this.utilService.resetTabView();

    let url = "";
    let result = [
      {
        name: "Open link in new tab",
        action: () => {
          const id = params.node.data.id;
          const techName = params.node.data.technician;
          const teamId = this.techTable.selectedTeam;

          url = `/#/dashboard/chubb/tasksByTechnicians/${id}/${techName}/${teamId}`;

          this.utilService.openItemToNewTab(url);
        },
        //cssClasses: ['redFont', 'bold'],
        //icon: createFlagImg('fr'),
      },
    ];

    return result;
  };

  /******************************************** On Grid Size Change ******************************************/
  public onGridSizeChange($event: any): void {
    if (
      this.techTable.table.gridAPI &&
      "sizeColumnsToFit" in this.techTable.table.gridAPI
    ) {
      this.techTable.table.gridAPI.sizeColumnsToFit();
    }
  }

  /******************************************** On Change ******************************************/
  public onSelectChange($event: any, whatChange: string) {
    if (whatChange === "durationChange") {
      this.getData();
    }

    if (whatChange === "teamChange") {
      this.updateTechnicians(-1).then(() => {
        this.getData();
      });
    }

    if (whatChange === "technicianChange") {
      this.getData();
    }
  }

  /******************************************** Download Data as CSV ******************************************/
  private downloadData() {
    let params = this.generateBaseParams();

    params = Object.assign(params, { isAll: true });

    this.push(
      this.chubbService
        .getAllTechniciansByTeam(params)
        .pipe(take(1))
        .subscribe(
          (resp) => {
            if (resp.data) {
              resp.data.forEach((data: any) => delete data.id);

              const customHeaders: any[] = [];
              techniciansTableColumns.forEach((col) =>
                customHeaders.push(col.headerName)
              );

              this.utilService
                .downloadDataTo("csv", resp.data.reverse(), "allTechnicians", {
                  customHeaders: customHeaders,
                })
                .then(() => {
                  console.log("Download succeeded");
                })
                .catch(() => {
                  console.error("Download csv failed");
                });
            }
          },
          (error) => {
            console.log(error);
          }
        )
    );
  }

  /******************************************** Refresh Data ******************************************/
  public refresh() {
    this.getData();
  }

  /******************************************** Toggle Filter ******************************************/
  public filterToggle() {
    this.isFilterToggleOn = !this.isFilterToggleOn;
  }

  /******************************************** Reset Search ******************************************/
  public resetSearch() {
    this.techTable.selectedTechnician = this.techTable.technicians[0].userId;
    this.techTable.selectedTimeframe = "daily";
    this.techTable.selectedTeam = this.techTable.teams[0].id;

    this.getData();
  }

  /******************************************** Return Value ******************************************/
  public returnValue(what: string, value: any) {
    let item = null;

    if (what === "user") {
      let user = this.techTable.technicians.find(
        (tech: any) => tech.id === value
      );

      if (user) {
        item = user.userFirstName + " " + user.userLastName;
      } else {
        item = "";
      }
    }

    return item;
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
