import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import * as moment from "moment-timezone";
import { fromEvent, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { HELPDESK_APP_EVENTS } from "src/app/_models/global.data.model";
import { HelpdeskService } from "src/app/_services/helpdesk.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-helpdesk-search-box",
  templateUrl: "./helpdesk-search-box.component.html",
  styleUrls: ["./helpdesk-search-box.component.scss"],
})
export class HelpdeskSearchBoxComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  public showSearchBox: boolean = false;
  public onlyOneOfResult: boolean = false;
  public noResultsAtAll: boolean = false;
  private hasClickedEnter = false;

  @ViewChild("helpDeskInput") searchInput!: ElementRef;

  @HostListener("document:click", ["$event"])
  clickOutside = (event: any) => {
    // only if not in search box then hide the box
    const paths = event.path;

    let isWithinSearchBox = false;

    if (paths) {
      for (let p = 0; p < paths.length; p++) {
        if (
          paths[p].className &&
          paths[p].className.includes("parentOfSearchBoxContainer")
        ) {
          isWithinSearchBox = true;
        }
      }
    }

    if (!isWithinSearchBox) {
      this.showSearchBox = false;
    }
  };

  public searchText = "";
  public hideSeeAll = false;
  public seeAllHasBeenClicked = false;

  public allSearchFilters = [
    {
      filter: "all",
      label: "All",
    },
    {
      filter: "tickets",
      label: "Tickets",
    },
    {
      filter: "assets",
      label: "Assets",
    },
    {
      filter: "customers",
      label: "Customers",
    },
    {
      filter: "jobOrders",
      label: "Job Orders",
    },
  ];

  public currentSelectOption = "all";

  public searchResults = [
    {
      parent: "tickets",
      metaHeader: "last_modified",
      results: <any[]>[],
      total: 0,
    },
    {
      parent: "assets",
      metaHeader: "serial_number",
      results: <any[]>[],
      total: 0,
    },
    {
      parent: "jobOrders",
      metaHeader: "last_modified",
      results: <any[]>[],
      total: 0,
    },
    {
      parent: "customers",
      metaHeader: "service_zone",
      results: <any[]>[],
      total: 0,
    },
  ];

  constructor(
    private helpDeskService: HelpdeskService,
    private utilService: UtilServiceService
  ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.setup();
  }

  /******************************************** Setup ******************************************/
  private setup() {
    this.push(
      fromEvent(this.searchInput.nativeElement, "input")
        .pipe(
          map((event) => (event as any).target.value),
          debounceTime(200),
          distinctUntilChanged()
        )
        .subscribe((value: any) => {
          if (value) {
            this.onInputSearch(value);
          }
        })
    );
  }

  /******************************************** On Input Search ******************************************/
  public async onInputSearch(value: any) {
    this.seeAllHasBeenClicked = false;
    this.searchResults = this.returnEmptySearchResult();
    let data = [];
    this.searchText = value as string;
    const param = this.generateBaseParam(this.currentSelectOption);

    if (this.searchText.length >= 3) {
      param["search"] = this.searchText;

      if (this.hasClickedEnter === false) {
        data = await this.getSearchData(param);
        this.insertIntoSearchResults(this.formatSearchResult(data)).then(() => {
          // show search ui box
          this.showSearchBox = true;
          this.processMetaUI();
        });
      }

      this.hasClickedEnter = false;
    } else {
      data = [];
      this.showSearchBox = false;
    }
  }

  /******************************************** On Key Enter ******************************************/
  public onKeyDownEnter() {
    this.hasClickedEnter = true;
    // for when they type and tap enter
    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.ON_SEARCH_RESULT,
      data: {
        selectedOption: this.currentSelectOption,
        item: this.searchText,
        type: "search",
        metaData: {
          selectedOption: this.currentSelectOption,
          item: this.searchText,
        },
      },
    });
    setTimeout(() => {
      this.showSearchBox = false;
    }, 500);
  }

  /******************************************** Get Search Based On Params Object ******************************************/
  private async getSearchData(paramObject: any) {
    let data = [];

    try {
      data = await this.helpDeskService
        .getHelpDeskSearchData(paramObject)
        .toPromise();

      if (data.hasOwnProperty("error")) {
        throw new Error();
      }
    } catch (e) {
      console.log(e);
      console.warn("No data found or undefined/null");
      data = this.returnEmptySearchResult();
    }

    if (!data) {
      data = this.returnEmptySearchResult();
    }

    return data;
  }

  /******************************************** Format The Result For Search UI ******************************************/
  private formatSearchResult(data: any) {
    const allResults = [];
    const keys = Object.keys(data);

    for (let k = 0; k < keys.length; k++) {
      const oneResult = {
        parent: keys[k],
        results: <any[]>[],
        total: 0,
      };

      for (let r = 0; r < data[keys[k]].data.length; r++) {
        let perResult = {
          item: "",
          meta: "",
          type: "",
          metaData: {},
        };
        // store total
        oneResult.total = data[keys[k]].total;

        if (keys[k] === "tickets") {
          perResult.item = data[keys[k]].data[r].ticketNo;
          perResult.meta = data[keys[k]].data[r].lastModifiedDate
            ? this.returnFormatDate(data[keys[k]].data[r].lastModifiedDate)
            : "N/A";
          perResult.type = keys[k];
          perResult.metaData = {
            id: data[keys[k]].data[r].id,
          };
          oneResult.results.push(perResult);
        }

        if (keys[k] === "assets") {
          perResult.item = data[keys[k]].data[r].name;
          perResult.meta = data[keys[k]].data[r].serialNo;
          perResult.type = keys[k];
          perResult.metaData = {
            id: data[keys[k]].data[r].id,
          };
          oneResult.results.push(perResult);
        }

        if (keys[k] === "jobOrders") {
          perResult.item =
            data[keys[k]].data[r].jobOrderNumber +
            "/" +
            data[keys[k]].data[r].jobOrderName;
          perResult.meta = data[keys[k]].data[r].modifiedDateTime
            ? this.returnFormatDate(data[keys[k]].data[r].modifiedDateTime)
            : "N/A";
          perResult.type = keys[k];
          perResult.metaData = {
            id: data[keys[k]].data[r].id,
          };
          oneResult.results.push(perResult);
        }

        if (keys[k] === "customers") {
          perResult.item = data[keys[k]].data[r].name;
          perResult.meta = data[keys[k]].data[r].serviceZone
            ? data[keys[k]].data[r].serviceZone.name
            : "N/A";
          perResult.type = keys[k];
          perResult.metaData = {
            id: data[keys[k]].data[r].id,
          };
          oneResult.results.push(perResult);
        }
      }

      allResults.push(oneResult);
    }

    return allResults;
  }

  /******************************************** Insert Into Search Results Array ******************************************/
  private insertIntoSearchResults(data: any[]) {
    return new Promise<void>((resolve) => {
      let tempArr = [];

      if (this.currentSelectOption !== "all") {
        tempArr = this.searchResults.filter(
          (sr) => sr.parent === this.currentSelectOption
        );
        this.searchResults = tempArr;
      }

      for (let sr = 0; sr < this.searchResults.length; sr++) {
        for (let d = 0; d < data.length; d++) {
          if (this.searchResults[sr].parent === data[d].parent) {
            this.searchResults[sr].results = data[d].results;
            this.searchResults[sr].total = data[d].total;
          }
        }
      }

      resolve();
    });
  }

  /******************************************** For Search Box Hide Line Or Show No Results At All Image ******************************************/
  private processMetaUI() {
    let counter = 0;
    // if search box only contains one result of a all parent
    this.onlyOneOfResult = false;
    this.noResultsAtAll = false;
    let allTotal = 0;

    for (let s = 0; s < this.searchResults.length; s++) {
      if (this.searchResults[s].results.length > 0) {
        counter++;
      }

      allTotal += this.searchResults[s].total;
    }

    if (counter === 1) {
      this.onlyOneOfResult = true;
    }

    if (counter === 0) {
      this.noResultsAtAll = true;
    }

    if (allTotal <= 5) {
      this.hideSeeAll = true;
    } else {
      this.hideSeeAll = false;
    }
  }

  /******************************************** When User Click The Search Button ******************************************/
  public onClickSearchResult(helpDeskInput: HTMLInputElement): void {
    this.utilService.sendData({
      action: HELPDESK_APP_EVENTS.ON_SEARCH_RESULT,
      data: {
        selectedOption: this.currentSelectOption,
        item: helpDeskInput.value,
        type: "search",
        metaData: {
          selectedOption: this.currentSelectOption,
          item: helpDeskInput.value,
        },
      },
    });
    this.forceClose();
  }

  /******************************************** Format Date String ******************************************/
  private returnFormatDate(dateString: string): string {
    return moment(dateString).format(
      localStorage.getItem("date_format")!.toUpperCase()
    );
  }

  private returnEmptySearchResult() {
    return [
      {
        parent: "tickets",
        metaHeader: "last_modified",
        results: <any[]>[],
        total: 0,
      },
      {
        parent: "assets",
        metaHeader: "serial_number",
        results: <any[]>[],
        total: 0,
      },
      {
        parent: "jobOrders",
        metaHeader: "last_modified",
        results: <any[]>[],
        total: 0,
      },
      {
        parent: "customers",
        metaHeader: "service_zone",
        results: <any[]>[],
        total: 0,
      },
    ];
  }

  /******************************************** Pass Clicked Result To Any Component Need The Result ******************************************/
  // click on the search result
  public onClickResult(result: any) {
    const type = result.type;
    let id = null;

    if (type === "tickets") {
      this.utilService.sendData({
        action: HELPDESK_APP_EVENTS.ON_TICKET_CLICK,
        data: result,
      });
    }

    if (type === "assets") {
      id = result.metaData.id;
      localStorage.setItem("activeIndexAssetView", "0");
      this.utilService.openItemToNewTab(`/dashboard/assets/view/${id}?id=${id}`);
    }

    if (type === "customers") {
      id = result.metaData.id;

      this.utilService.openItemToNewTab(
        `/dashboard/customers/view/${id}?id=${id}`
      );
    }

    if (type === "jobOrders") {
      id = result.metaData.id;
      this.utilService.openItemToNewTab(
        `/dashboard/jobOrders/view/${id}?jobOrderId=${id}`
      );
    }

    this.forceClose();
  }

  /******************************************** Delay The Action To Close The Searchbox - Due To Clickoutside Hostlistener Enabling For Showsearchbox ******************************************/
  private forceClose() {
    setTimeout(() => {
      this.showSearchBox = false;
    }, 100);
  }

  /******************************************** See All ******************************************/
  public async seeAllResults($event: any) {
    this.seeAllHasBeenClicked = true;
    let param = this.generateBaseParam(this.currentSelectOption);
    param = Object.assign(param, { search: this.searchText });

    if ("perPage" in param) {
      // remove per page from params
      delete (param as any).perPage;
    }

    // call the search again
    let data = await this.getSearchData(param);
    this.insertIntoSearchResults(this.formatSearchResult(data)).then(() => {
      this.showSearchBox = true;
      this.processMetaUI();
    });
  }

  /******************************************** On Search Option ******************************************/
  public onSearchOptionChange() {
    this.seeAllHasBeenClicked = false;
    this.generateBaseParam(this.currentSelectOption);
  }

  /******************************************** Generate Base Params ******************************************/
  private generateBaseParam(selectedOption: string) {
    let param = {
      startFrom: 0,
      perPage: 5,
      sortOrder: "desc",
      search: "",
      ticketColumns: [
        "id",
        "subject",
        "ticketNo",
        "customer",
        "assets",
        "createdDate",
        "lastModifiedDate",
      ],
      joColumns: ["id", "jobOrderNumber", "jobOrderName", "modifiedDateTime"],
      assetColumns: [
        "id",
        "name",
        "serialNo",
        "assetTypeName",
        "assetStatusName",
        "assetsCode",
      ],
      custommerColumns: [
        "id",
        "name",
        "email",
        "phone",
        "contactPersonName",
        "customerReferenceNo",
        "serviceZone",
      ],
      isGlobalSearch: true,
    };

    if (selectedOption === "all") {
      const otherParam = {
        isTicket: true,
        isAsset: true,
        isCustomer: true,
        isJobOrder: true,
      };

      param = Object.assign(param, otherParam);
    }

    if (selectedOption === "tickets") {
      const otherParam = {
        isTicket: true,
      };

      param = Object.assign(param, otherParam);
    }

    if (selectedOption === "assets") {
      const otherParam = {
        isAsset: true,
      };

      param = Object.assign(param, otherParam);
    }

    if (selectedOption === "customers") {
      const otherParam = {
        isCustomer: true,
      };

      param = Object.assign(param, otherParam);
    }

    if (selectedOption === "jobOrders") {
      const otherParam = {
        isJobOrder: true,
      };

      param = Object.assign(param, otherParam);
    }

    return param;
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
