import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-custella-vehicle-types",
  templateUrl: "./custella-vehicle-types.component.html",
  styleUrls: ["./custella-vehicle-types.component.scss"],
})
export class CustellaVehicleTypesComponent implements OnInit {
  public searchValue = '';

  @Output() itemSelector = new EventEmitter();
  @Output() closeView = new EventEmitter();

  constructor() {

  }

  ngOnInit(): void {
    
  }

  public onClose($event: any) {
    this.closeView.emit($event);
  }

  public close() {
    this.closeView.emit(false);
  }

  public itemSelected($event: any) {
    this.itemSelector.emit($event);
  }
}
