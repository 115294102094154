import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { TabView } from 'primeng/tabview';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { DataService } from '../../IqcFqc';

@Component({
  selector: 'app-fqc-view',
  templateUrl: './fqc-view.component.html',
  styleUrls: ['./fqc-view.component.scss']
})
export class FqcViewComponent implements OnInit, OnDestroy, AfterViewInit {

  activeIndex: any = 0;
  retrievalDetails: any;
  _id: any;
  items: any[] = [];
  iqc: any;

  joid: any;
  fields: any;
  RelocationEquipmentfields: any;
  modifiedField: any = [];
  refreshDynamicList: Subscription | any;
  display: any = 0

  equipmentDetails: any;
  equipmentList: any;
  public gridOptions: Partial<GridOptions> | any;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  searchValue: any;
  overlayNoRowsTemplate: string | any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  pageSize: any = 25;
  displayfaultUniticon: boolean = false;
  noEquipments: boolean = false;
  fields1: any
  dateFormat: string | any;
  timezone: string | any;

  public headerValue = "";
  public toggleEditVisibility = false;
  @ViewChild(TabView, { static: false }) tabView!: TabView;
  refresher: Subscription = new Subscription();
  subscriber: Subscription = new Subscription();
  addonFeature: any

  fqcDetails: boolean = false;
  isDso: any = false;
  isDso2: any = false;
  isRso: any = false;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public util: UtilServiceService,
    private iqcFqcService: IqcFqcService,
    private deleteRecordService: DeleteRecordService,
    private breadCrumb: BreadcrumbService,
    private dataService: DataService) {
    this.refresher = iqcFqcService.refreshIqc$.subscribe((item: any) => {
      if (item == 'Deleted IQC') {
        this.router.navigate(['/dashboard/fqc-fqc']);
      }
    })
    this.preInit();
  }
  ngAfterViewInit(): void {
    this.loadData(this.activeIndex, this.tabView);
  }

  ngOnDestroy(): void {
    this.breadCrumb.setItems([]);
    if (this.subscriber) this.subscriber.unsubscribe();
    if (this.refresher) this.refresher.unsubscribe();
  }

  ngOnInit(): void {
    this._id = this.route.snapshot.paramMap.get('id');
    let ind: any = this.route.snapshot.paramMap.get('activeIndex');
    this.activeIndex = (ind && !isNaN(ind)) ? Number(ind) : 0;
    this.init();
  }

  /******************************************** pre start ******************************************/
  private preInit() {

  }
  /******************************************** start ******************************************/
  private init() {
    this.subscriber = this.iqcFqcService.getFqcById(this._id).subscribe((resp: any) => {
      this.iqc = resp.data;

      this.breadCrumb.setItems([
        { label: "FQC", routerLink: ["/dashboard/iqc-fqc"], queryParams: { activeIndex: 1 }, meta: {navigation: {root: true}} },
        { label: resp.status ? resp.data.runningNumber : '', meta: {navigation: {include: true}} },
      ]);
    });

    // this.activeIndex = localStorage.getItem("activeIndex") == undefined ? 0 : Number(localStorage.getItem("activeIndex"));
  }


  /******************************************** menu action ******************************************/
  public menuAction(action: string) {
    if (action === "delete") {
      const data = {
        deleteType: "FQC",
        id: this._id + 2,
        message: `Are you sure you want to delete ${this.iqc.runningNumber}?`,
      };

      this.deleteRecordService.getItems(data).subscribe(() => {
        this.router.navigate(["dashboard/iqc-fqc"], { queryParams: { activeIndex: 1 } });
      });
    }
  }

  edit(e: any) {
    if (e !== null) {
      this.router.navigate(["dashboard/iqc-fqc/fqc-create/" + this._id], { queryParams: { isEdit: true } });
    }
  }

  /******************************************** on tab click ******************************************/
  public handleChange(e: any) {
    localStorage.setItem("activeIndex", e.index);
    this.activeIndex = e.index;
    const headerValue = this.tabView.tabs[e.index].header;

    this.setTabHeader(headerValue);
    this.dataService.changeMessage(e.index);
  }

  /******************************************** pre set tab ******************************************/
  private loadData(e: any, tabView: TabView) {
    const headerValue = this.tabView.tabs[e].header;
    this.activeIndex = e;
    this.setTabHeader(headerValue);
  }

  /******************************************** display tab content ******************************************/
  private setTabHeader(headerValue: any) {
    this.headerValue = headerValue;
    this.fqcDetails = this.activeIndex === 0;
    this.isRso = this.activeIndex === 1
    this.isDso = this.activeIndex === 2
    this.handleEditVisibility();
  }

  /******************************************** handle edit visibility ******************************************/
  private handleEditVisibility() {
    if (this.activeIndex == 0) {
      this.toggleEditVisibility = true;
    } else {
      this.toggleEditVisibility = false;
    }
  }

}
