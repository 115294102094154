<div class="mt-56px">
    <!-- Container -->
    <form [formGroup]="addFQCForm" >
        <div class="custella-form-container left-right-space rounded-2">
            <!-- Container Content -->
            <div class="custella-form-container-title text-14 font-medium rounded-top" translate>{{'fqc_details'}}</div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'asset'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="assetsName" name="assetsName"
                            placeholder="{{'select_asset'|translate}}">
                        <div class="p-error col px-0 py-0 text-left"
                            *ngIf=" addFQCForm.controls['assetsName']?.touched && (selectedAsset ==null || selectedAsset=='')">
                            <span
                                *ngIf=" addFQCForm.controls['assetsName']?.errors?.required && (selectedAsset ==null || selectedAsset=='')"
                                translate>{{
                                "required_field" }}</span>
                        </div>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'location'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="location" name="location"
                            placeholder="{{''|translate}}">
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'customer_damage_claim'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="customer" name="customer"
                            placeholder="{{''|translate}}">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'subsidiary_repair_service'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="subsidiary" name="subsidiary"
                            placeholder="{{''|translate}}">
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'iqc_type'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="iqcType" name="iqcType"
                            placeholder="{{''|translate}}">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'breakdown_service'}}</span>
                        <input type="number" type="text" pInputText autocomplete="off" formControlName="breakDownService"
                            name="breakDownService" placeholder="{{''|translate}}">
                    </div>
                </div>
            </div>
            <!-- Equipment Information -->
            <div class="custella-form-container-title-two" translate>{{'equipment_information'}}</div>
            <div class="custella-form-container-content">
                <div class="custella-content-row-two mb-3">
                    <div class="custella-content-row-two-input col-12">
                        <span class="custella-content-row-two-input-title" translate>{{'equipment_type'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="equipmentTypeName" name="equipmentTypeName">
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'equipment_number'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="equipmentNumber" name="equipmentNumber">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'manufacturer'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="manufacturer" name="manufacturer">
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'make_model'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="model" name="model">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'serial_no'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="serialNo" name="serialNo">
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'engine_number'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="engineNumber" name="engineNumber">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'engine_number_two'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="engineNumber2" name="engineNumber2">
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'engine_make'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="engineMake" name="engineMake">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'engine_model'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="engineModel" name="engineModel">
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'current_value_hours'}}</span>
                        <input type="text" pInputText autocomplete="off" formControlName="equipmentMeterReading"
                            name="equipmentMeterReading">
                    </div>
                    <div class="custella-content-row-input col-6">
                    </div>
                </div>
            </div>
            <!-- Hour Meter-->
            <div class="custella-form-container-title-two" translate>{{'hour_meter'}}</div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'new_value_hours'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="newMeterReading" name="newMeterReading"
                            placeholder="{{'enter_new_value'|translate}}">
                    </div>
                </div>
            </div>
            <!-- Test Reading-->
            <div class="custella-form-container-title-two" translate>{{'test_reading'}}</div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'frequency'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="frequency" name="frequency">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'operating_pressure'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="operatingPressure" name="operatingPressure">
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'alternator_reading'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="alternatorReading" name="alternatorReading">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'vacuum_pressure'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="vacuumPressure" name="vacuumPressure">
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'discharge_pressure'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="dischargePressure" name="dischargePressure">
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'terminal_voltage'}}</span>
                        <input type="number" pInputText autocomplete="off" formControlName="terminalVoltage" name="terminalVoltage">
                    </div>
                </div>
            </div>
            <!-- Test Reading-->
            <div class="custella-form-container-title-two" translate>{{'fqc_details'}}</div>
            <div  *ngFor="let data of questionList;let i = index">
                <div class="custella-form-container-title-two-grey" translate>{{data.name}}</div>
                <div class="custella-form-container-content" *ngFor="let question of data.detail">
                    <div class="custella-content-row mb-3">
                        <div class="custella-content-row-input col-6">
                            <span class="custella-content-row-input-title" translate>{{question.question}}</span>
                            <div class="setup d-flex align-items-center">
                                <button pButton type="button" class="rounded-default-button me-2 py-1" (click)="getAnswer(answer, question)" [ngClass]="{'rounded-default-button': question.selectedAnswer != answer._id, 'rounded-chosen-button': question.selectedAnswer == answer._id}"
                                    *ngFor="let answer of question.answers" translate>{{answer.answer}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Remarks-->
            <div class="custella-form-container-title-two" translate>{{'remarks'}}</div>
            <div class="custella-form-container-content">
                <div class="custella-content-row mb-3 pe-0">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title" translate>{{'remarks'}}</span>
                        <textarea [maxlength]="320000" [rows]="4" pInputTextarea autocomplete="off"area formControlName="remark" name="remark"></textarea>
                    </div>
                </div>
            </div>
            <!-- Container Footer -->
            <div class="custella-form-container-footer">
                <div class="d-flex align-items-center justify-content-end my-dialog">
                    <button class="custella-btn-white text-12 ms-2" (click)="goBack()">{{'cancel' | translate}}</button>
                    <p-button (click)="showModalDialog()" [label]="buttonLabel" styleClass="ms-2 h-32"></p-button>
                    <div>
                    <p-dialog header="" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
                        [baseZIndex]="10000" [draggable]="false" [resizable]="false">
                        <ng-template pTemplate="header">
                           <span class="w-100">Status Details</span> 
                            <span class="mx-1p-input-icon-right">
                            <i class="pi pi-times-circle" (click)="displayModal=false"></i></span>
                        </ng-template>
                        <span class="custella-content-row-input-title" translate>{{'status'}}</span>
                        <p-dropdown [options]="statuses" [showClear]="true" optionLabel="label" optionValue="name"
                                formControlName="status" name="status" [(ngModel)]="fqcData.status"
                                placeholder="{{ 'select_iqc_status' | translate }}" (onChange)="changeStatus()">
                                <ng-template let-user pTemplate="item">
                                    <div class="ticket-owner-user-list">
                                        <div>{{ user.label }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <div class="p-error col px-0 py-0 text-left"
                                *ngIf=" addFQCForm.controls['status']?.touched && addFQCForm.controls['status'].invalid ">
                                <span *ngIf=" addFQCForm.controls['status']?.errors?.required " translate>{{
                                    "required_field" }}</span>
                            </div>
                            <div class="row mt-2 mx-1">
                                <div class="col-md-12">
                                    <div *ngIf="statusMessage !=''" class="py-2 px-3 d-flex align-items-center"
                                        style="background-color: #efefef; border-radius: 8px;">
                                        <img class="mr-3" src="../../../../../assets/images/info_dark.png"
                                            style="height: 20px;margin-right: 3px;" alt="">
                                        <p class="mb-0 text-1-5">{{statusMessage}}</p>
                                    </div>
                                </div>
                            </div>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple class="p-button-secondary" (click)="displayModal=false" label="Cancel"></button>
                            <p-button icon="pi pi-check" (click)="onSubmitForm()" label="Proceed" class="p-button-text">
                            </p-button>
                        </ng-template>
                    </p-dialog>
                </div>
                </div>
            </div>
        </div>
    </form>
</div>