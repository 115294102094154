import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-trips',
  templateUrl: './vehicle-trips.component.html',
  styleUrls: ['./vehicle-trips.component.scss']
})
export class VehicleTripsComponent implements OnInit {
  @Input() vehicleDetail: any;

  constructor() { }

  ngOnInit(): void {
  }

}
