<div class="left-right-space h-100">
  <!-- Title -->
  <div class="flex-between mb-2">
    <div class="custella-dashboard-top-right d-flex ms-auto">

      <button class="border rounded-1 px-04 ms-2 btn-32" (click)="callLoadData()">
        <img alt="logo" src="assets\svg\refresh.svg" />
      </button>
      <button (click)="callFilterToggle($event)" class="border rounded-1 ms-2 px-06 btn-32" style="color: #435861">
        <i class="pi pi-filter pt-04"></i>
      </button>

      <span class="p-input-icon-left ms-2" [ngClass]="iqcSearch.value.length>0 ? 'p-input-icon-right':''">
        <i class="pi pi-search me-3"></i>
        <input #iqcSearch type="text" pInputText autocomplete="off" placeholder="Search"
          (input)="onSearchChange($event.target)" (keyup)="onSearchChange2($event.target)" />
        <i class="pi pi-times-circle" (click)="onSearchClear()" *ngIf="iqcSearch.value.length>0"></i>
      </span>

      <p-button *ngIf="create" label="New" icon="pi pi-plus" (click)="openCreateIqc()"
        styleClass="ms-2 h-32"></p-button>
    </div>
  </div>

  <div *ngIf="noIqcs" class="col-12 mt-5 py-4">
    <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
      <img src="assets\svg\empty-state.svg" alt="">
      <p *ngIf="create" class="text-6 my-1 font-weight-bold" translate>{{'no_records_found'}}</p>
    </div>
  </div>
  <!-- TabMenu -->
  <div [hidden]="noIqcs" class="position-relative fw-normal" style="height:100% ;">
    <div class="position-relative fw-normal col-12 d-flex" [ngClass]="{ 'ag-grid-table': isFilterToggleOn }"
      style="height: calc(100% - 108px);">
      <div class="table-space ag-grid-table-full" style="height: 100%;">
        <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [suppressDragLeaveHidesColumns]="true"
          [allowContextMenuWithControlKey]="false" [columnDefs]="columnDefs" [gridOptions]="gridOptions"
          [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1"
          [getContextMenuItems]="getContextMenuItems" [frameworkComponents]="frameworkComponents"
          (cellClicked)="viewData($event)" (gridReady)="onGridReady($event)"
          [overlayNoRowsTemplate]="overlayNoRowsTemplate">
        </ag-grid-angular>

        <div class="col-12">
          <p class="ag-record-row-summary-panel padds text-end">
            {{ totalRecord }} {{ "records" | translate }}
          </p>
        </div>
      </div>

      <div class="custella-form-container-content-pane layout-filter"
        [ngClass]="{ 'layout-filter-active2': isFilterToggleOn }">
        <div class="custella-form-container-content-pane-title">
          <span class="text-14 font-medium">{{ "filter_by" | translate }}</span>
          <i class="pi pi-arrow-right" (click)="filterToggle($event)"></i>
        </div>
        <div class="custella-content">
          <div class="custella-content-input col-12">
            <span class="custella-content-input-title">{{ "date_range" | translate }}</span>
            <span class="p-input-icon-right w-100">
              <i class="pi pi-calendar" style="z-index: 1;"></i>
              <p-calendar [(ngModel)]="dateRange" (ngModelChange)="onChangeDate()" 
                class="custella-calendar" [showButtonBar]="false" placeholder="dd/mm/yyyy - dd/mm/yyyy"
                selectionMode="range" dateFormat="dd/mm/yy" yearRange="2000:2030" [yearNavigator]="true">
              </p-calendar>
            </span>
          </div>

          <div class="custella-content-input col-12">
            <span class="custella-content-input-title">All Locations</span>
            <p-dropdown name="location" [(ngModel)]="filter.location" (onChange)="filterResults($event)"
              [options]="locations" [showClear]="filter.location ? true : false" [filter]="true"
              [placeholder]="'select_location' | translate" optionLabel="fieldLabel" optionValue="name">
            </p-dropdown>
          </div>
          <div>
            <span href="#" class="text-decoration-underline font-black" (click)="resetSearch()"><small
                class="d-flex align-items-center text-nowrap text-10 cursor-pointer">{{ "clear_all" | translate
                }}</small>
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="displayIcon" class="col-12 mt-5 py-4">
        <div class="col-md-12 px-0 my-2 text-center mt-2 pt-5">
          <img src="assets\svg\empty-state.svg" alt="">
          <p class="text-6 my-1 font-weight-bold" translate>{{'no_records_found'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->