<div class="custella-form-container left-right-space rounded-2 mt-0">
    <!-- General Details -->
    <div class="custella-form-container-title-two rounded-top">
        <div class="font-medium text-14" id="title_VehGenDet" translate>{{'general_details'}}</div>
    </div>
    <!-- name, status -->
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_VehName">{{'name' | translate}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehName" *ngIf="vehicleDetail?.vehicle?.vehicleType">{{vehicleDetail?.vehicle?.name}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehName" *ngIf="!vehicleDetail?.vehicle?.vehicleType">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_VehStatus">{{'status' | translate}}</span>
                <!-- depot status -->
                <div>
                    <ng-container *ngIf="vehicleDetail?.vehicle?.vehicleStatus === 'DEPOT'">
                        <span class="p-mr-2 p-tag p-component" style="background-color: #c8e6c9 !important ; color: #c8e6c9;" id="value_VehStatusDepot">
                            <span class="p-tag-value text-dark"> {{vehicleDetail?.vehicle?.vehicleStatus | titlecase}} </span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="vehicleDetail?.vehicle?.vehicleStatus === 'ENROUTE'">
                        <span class="p-mr-2 p-tag p-component" style="background-color: #edc054 !important ; color: #edc054;" id="value_VehStatusEnroute">
                            <span class="p-tag-value text-dark"> {{vehicleDetail?.vehicle?.vehicleStatus | titlecase}} </span>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="vehicleDetail?.vehicle?.vehicleStatus === 'MAINTENANCE'">
                        <span class="p-mr-2 p-tag p-component" style="background-color: #d77d7d !important ; color: #d77d7d;" id="value_VehStatusMain">
                            <span class="p-tag-value text-dark"> {{vehicleDetail?.vehicle?.vehicleStatus | titlecase}} </span>
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- number, type-->
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_VehNo">{{'vehicle_number' | translate}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehNo" *ngIf="vehicleDetail?.vehicle?.vehicleNumber">{{vehicleDetail?.vehicle?.vehicleNumber}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehNo" *ngIf="!vehicleDetail?.vehicle?.vehicleNumber">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_VehType">{{'vehicle_type' | translate}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehType" *ngIf="vehicleDetail?.vehicle?.vehicleType">{{vehicleDetail?.vehicle?.vehicleType?.name}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehType" *ngIf="!vehicleDetail?.vehicle?.vehicleType">-</span>
            </div>
        </div>
    </div>
    <!-- chassis no, geofence, photo -->
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title color-var-500" id="label_VehChassNo" translate>{{"chassis_no"}} </span>
                    <span class="custella-content-row-input-title-three" id="value_VehChassNo" *ngIf="vehicleDetail?.vehicle?.chassisNumber">{{vehicleDetail?.vehicle?.chassisNumber}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehChassNo" *ngIf="!vehicleDetail?.vehicle?.chassisNumber">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehPhoto" translate>{{"photo"}} </span>
                <p-avatar *ngIf="vehicleDetail?.vehicle?.vehicleImage?.attachmentUrl" id="img_VehPhotoValAvatar" [image]="vehicleDetail?.vehicle?.vehicleImage?.attachmentUrl" styleClass="mr-2 mt-2" size="large" shape="circle"> </p-avatar>
                <p-avatar *ngIf="!vehicleDetail?.vehicle?.vehicleImage?.attachmentUrl" id="img_VehPhotoValAvatar" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" size="large" shape="circle"> </p-avatar>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehGeofence" translate>{{"geofence"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehGeofence" *ngIf="vehicleDetail?.vehicle?.geoFence?.geoFenceName">{{vehicleDetail?.vehicle?.geoFence?.geoFenceName}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehGeofence" *ngIf="!vehicleDetail?.vehicle?.geoFence?.geoFenceName">-</span>
            </div>  
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehPostcode" translate>{{"postcode"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehPostcode" *ngIf="vehicleDetail?.vehicle?.postCode">{{vehicleDetail?.vehicle?.postCode}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehPostcode" *ngIf="!vehicleDetail?.vehicle?.postCode">-</span>
            </div>         
        </div>
    </div>

    <!-- Driver & Depot -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" id="title_VehDefaultDriverDepot" translate>{{ "default_driver_and_depot" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom">
            <div class="custella-content-row">
                <div *ngIf=" vehicleDetail?.vehicleDrivers.length !== 0" class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_VehDriver">{{'assigned_driver' | translate}}</span>
                    <div *ngFor="let assignVehicle of vehicleDetail?.vehicleDrivers; let i = index" class="custella-vp-picture flex-between">
                        <div class="d-flex align-items-center">
                            <p-avatar id="img_VehDriverAvatar" *ngIf="assignVehicle?.driver?.user?.imageUrl && assignVehicle?.driver?.user?.imageUrl !== ''" [image]="assignVehicle.driver.user.imageUrl" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                            <p-avatar id="img_VehDriverAvatar" *ngIf="!assignVehicle?.driver?.user?.imageUrl || assignVehicle?.driver?.user?.imageUrl === ''" image="assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                            <span class="custella-content-row-input-title-three-link mx-2" (click)="navigateToDriver(assignVehicle?.driver?.user)">{{assignVehicle?.driver?.user?.firstName }} {{assignVehicle?.driver?.user?.lastName }}</span>
                            <div class="d-flex align-items-center">
                                <span class="custella-content-row-input-title color-var-500 text-nowrap mb-0 me-3" id="label_VehDriverLicExp"><i>( {{'license_expires' | translate}}: </i></span>
                                <span class="custella-content-row-input-title color-var-500 text-nowrap mb-0" id="value_VehDriverLicExp" *ngIf="vehicleDetail?.defaultVehicleDriver?.driver?.licenceExpireDate">
                                    <i>{{vehicleDetail?.defaultVehicleDriver?.driver?.licenceExpireDate | momentDate : dateFormat : timezone}} )</i>
                                </span>
                                <span class="custella-content-row-input-title color-var-500 text-nowrap mb-0" id="value_VehDriverLicExp" *ngIf="!vehicleDetail?.defaultVehicleDriver?.driver?.licenceExpireDate"><i> - )</i></span>
                            </div>
                        </div>
                        <p-button type="button" [label]="'remove' | translate" id="btn_VehDriverDelete" styleClass="custella-btn-icon mx-3 text-nowrap h-28 px-2 e-btn" (onClick)="deleteDriver(assignVehicle)" *ngIf="create"></p-button>
                    </div>
                </div>

                <div *ngIf=" vehicleDetail?.vehicleDrivers.length === 0" class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_VehDriver2">{{'assigned_driver' | translate}}</span>
                    <span (click)="addDriver()" class="custella-content-row-input-title-three-link" id="link_VehDriver2">{{'assign_driver' | translate}}</span>
                    <div class="d-flex align-items-center">
                        <footer *ngIf="displayTable" class="custella-form-container-footer hidePopup">
                            <p-dialog [draggable]="false" header="Driver Details" id="title_DriverDets" [(visible)]="displayTable" showEffect="fade" [style]="{ width: '70vw' }" [modal]="true">
                                <app-custella-drivers (itemSelector)="gotValue($event)" (closeView)="closeTable()" [unAssignedDrivers]="true"></app-custella-drivers>
                            </p-dialog>
                        </footer>
                    </div>
                </div>

                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" id="label_VehDefDepot">{{'default_depot' | translate}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehDefDepot" *ngIf="vehicleDetail?.vehicle?.depot">{{vehicleDetail?.vehicle?.depot?.name}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehDefDepot" *ngIf="!vehicleDetail?.vehicle?.depot">-</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Vehicle Measurement -->
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_VehMeasure" translate>{{'vehicle_measurements'}}</div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehHeight" translate>{{"height"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehHeight" *ngIf="vehicleDetail?.vehicle?.vehicleHeight">{{vehicleDetail?.vehicle?.vehicleHeight | number:'.2-2'}}{{vehicleDetail?.vehicle?.lengthUnit | lowercase}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehHeight" *ngIf="!vehicleDetail?.vehicle?.vehicleHeight">-</span>
            </div>
            
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehWeight" translate>{{"weight"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehWeight" *ngIf="vehicleDetail?.vehicle?.vehicleWeight">{{vehicleDetail?.vehicle?.vehicleWeight | number:'.2-2'}}{{vehicleDetail?.vehicle?.weightUnit | lowercase}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehWeight" *ngIf="!vehicleDetail?.vehicle?.vehicleWeight">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehWidth" translate>{{"width"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehWidth" *ngIf="vehicleDetail?.vehicle?.vehicleWidth">{{vehicleDetail?.vehicle?.vehicleWidth | number:'.2-2'}}{{vehicleDetail?.vehicle?.lengthUnit | lowercase}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehWidth" *ngIf="!vehicleDetail?.vehicle?.vehicleWidth">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehLength" translate>{{"length"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehLength" *ngIf="vehicleDetail?.vehicle?.vehicleLength">{{vehicleDetail?.vehicle?.vehicleLength | number:'.2-2'}}{{vehicleDetail?.vehicle?.lengthUnit | lowercase}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehLength" *ngIf="!vehicleDetail?.vehicle?.vehicleLength">-</span>
            </div>
        </div>
    </div>

    <!-- Capacity Measurement -->
    <ng-container *ngIf="vehicleDetail?.vehicle?.vehicleCategory.name !='PRIME MOVER'">
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" id="title_VehCapacityMeasure" translate>{{'capacity_measurements'}}</div>
        </div>

        <div class="custella-form-container-content-border-bottom">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title color-var-500" id="label_VehCapHeight" translate>{{"height"}} </span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapHeight" *ngIf="vehicleDetail?.vehicle?.capacityHeight">{{vehicleDetail?.vehicle?.capacityHeight| number:'.2-2'}}{{vehicleDetail?.vehicle?.lengthUnit | lowercase}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapHeight" *ngIf="!vehicleDetail?.vehicle?.capacityHeight">-</span>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title color-var-500" id="label_VehCapWeight" translate>{{"weight"}} </span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapWeight" *ngIf="vehicleDetail?.vehicle?.maxStorageWeight">{{vehicleDetail?.vehicle?.maxStorageWeight | number:'.2-2'}}{{vehicleDetail?.vehicle?.weightUnit | lowercase}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapWeight" *ngIf="!vehicleDetail?.vehicle?.maxStorageWeight">-</span>
                </div>
               
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title color-var-500" id="label_VehCapWidth" translate>{{"width"}} </span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapWidth" *ngIf="vehicleDetail?.vehicle?.capacityWidth">{{vehicleDetail?.vehicle?.capacityWidth| number:'.2-2'}}{{vehicleDetail?.vehicle?.lengthUnit | lowercase}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapWidth" *ngIf="!vehicleDetail?.vehicle?.capacityWidth">-</span>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title color-var-500" id="label_VehCapUnits" translate>{{"units"}} </span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapUnits" *ngIf="vehicleDetail?.vehicle?.capacityUnit">{{vehicleDetail?.vehicle?.capacityUnit | number:'.2-2'}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapUnits" *ngIf="!vehicleDetail?.vehicle?.capacityUnit">-</span>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title color-var-500" id="label_VehCapLength" translate>{{"length"}} </span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapLength" *ngIf="vehicleDetail?.vehicle?.capacityLength">{{vehicleDetail?.vehicle?.capacityLength | number:'.2-2'}}{{vehicleDetail?.vehicle?.lengthUnit | lowercase}}</span>
                    <span class="custella-content-row-input-title-three" id="value_VehCapLength" *ngIf="!vehicleDetail?.vehicle?.capacityLength">-</span>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Trailer Section -->
    <ng-container *ngIf="vehicleDetail?.vehicle?.vehicleCategory?.name === 'PRIME MOVER'">
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" id="title_VehAssignTrailers" translate>{{'assigned_trailer' | translate}}</div>
        </div>

        <!-- No Trailer -->
        <ng-container *ngIf="vehicleDetail?.vehicle?.trailer === null">
            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span (click)="addDriver()" id="link_VehAssignTrailer" class="custella-content-row-input-title-three-link">{{'assigned_trailer' | translate}}</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- Got Trailer -->
        <ng-container *ngIf="vehicleDetail?.vehicle?.trailer !== null">
            <!-- Assigned Trailer -->
            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_VehTrailName">{{'trailer_name' | translate}}</span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailName" *ngIf="vehicleDetail?.vehicle?.trailer">{{vehicleDetail?.vehicle?.trailer?.name}}</span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailName" *ngIf="!vehicleDetail?.vehicle?.trailer">-</span>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title-two" id="label_VehTrailNo">{{'trailer_number' | translate}}</span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailNo" *ngIf="vehicleDetail?.vehicle?.trailer">{{vehicleDetail?.vehicle?.trailer?.trailerNumber}}</span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailNo" *ngIf="!vehicleDetail?.vehicle?.trailer">-</span>
                    </div>
                </div>
            </div>

            <!-- Trailer Measurement -->
            <div class="custella-form-container-title-two flex-between">
                <div class="font-medium text-14" id="title_VehTrailerMeasure">{{'trailer' | translate}} {{'measurements' | translate}}</div>
            </div>

            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailHeight" translate>{{"height"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailHeight" *ngIf="vehicleDetail?.vehicle?.trailer?.trailerHeight">
                            {{vehicleDetail?.vehicle?.trailer?.trailerHeight}}{{vehicleDetail?.vehicle?.trailer?.lengthUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailHeight" *ngIf="!vehicleDetail?.vehicle?.trailer?.trailerHeight">-</span>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailWeight" translate>{{"weight"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailWeight" *ngIf="vehicleDetail?.vehicle?.trailer?.trailerWeight">
                            {{vehicleDetail?.vehicle?.trailer?.trailerWeight}}{{vehicleDetail?.vehicle?.trailer?.weightUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailWeight" *ngIf="!vehicleDetail?.vehicle?.trailer?.trailerWeight">-</span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailWidth" translate>{{"width"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailWidth" *ngIf="vehicleDetail?.vehicle?.trailer?.trailerWidth">
                            {{vehicleDetail?.vehicle?.trailer?.trailerWidth}}{{vehicleDetail?.vehicle?.trailer?.lengthUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailWidth" *ngIf="!vehicleDetail?.vehicle?.trailer?.trailerWidth">-</span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailLength" translate>{{"length"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailLength" *ngIf="vehicleDetail?.vehicle?.trailer?.trailerLength">
                            {{vehicleDetail?.vehicle?.trailer?.trailerLength}}{{vehicleDetail?.vehicle?.trailer?.lengthUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailLength" *ngIf="!vehicleDetail?.vehicle?.trailer?.trailerLength">-</span>
                    </div>
                </div>
            </div>

            <!-- Trailer Capacity Measurement -->
            <div class="custella-form-container-title-two flex-between">
                <div class="font-medium text-14" id="title_VehTrailerCapMeasure">{{'trailer' | translate}} {{'capacity_measurements' | translate}}</div>
            </div>

            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailCapHeight" translate>{{"height"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapHeight" *ngIf="vehicleDetail?.vehicle?.trailer?.capacityHeight">
                            {{vehicleDetail?.vehicle?.trailer?.capacityHeight}}{{vehicleDetail?.vehicle?.trailer?.lengthUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapHeight" *ngIf="!vehicleDetail?.vehicle?.trailer?.capacityHeight">-</span>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailCapWeight" translate>{{"max_storage"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapWeight" *ngIf="vehicleDetail?.vehicle?.trailer?.maxStorageWeight">
                            {{vehicleDetail?.vehicle?.trailer?.maxStorageWeight}}{{vehicleDetail?.vehicle?.trailer?.weightUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapWeight" *ngIf="!vehicleDetail?.vehicle?.trailer?.maxStorageWeight">-</span>
                    </div>
                 
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailCapWidths" translate>{{"capacity_width"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapWidths" *ngIf="vehicleDetail?.vehicle?.trailer?.trailerWeight">
                            {{vehicleDetail?.vehicle?.trailer?.capacityWidth}}{{vehicleDetail?.vehicle?.trailer?.lengthUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapWidths" *ngIf="!vehicleDetail?.vehicle?.trailer?.trailerWeight">-</span>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailCapUnits" translate>{{"units"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapUnits" *ngIf="vehicleDetail?.vehicle?.trailer?.capacityUnit">{{vehicleDetail?.vehicle?.trailer?.capacityUnit}}</span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapUnits" *ngIf="!vehicleDetail?.vehicle?.trailer?.capacityUnit">-</span>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content-border-bottom">
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title color-var-500" id="label_VehTrailCapLength" translate>{{"length"}} </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapLength" *ngIf="vehicleDetail?.vehicle?.trailer?.capacityLength">
                            {{vehicleDetail?.vehicle?.trailer?.capacityLength}}{{vehicleDetail?.vehicle?.trailer?.lengthUnit | lowercase}}
                        </span>
                        <span class="custella-content-row-input-title-three" id="value_VehTrailCapLength" *ngIf="!vehicleDetail?.vehicle?.trailer?.capacityLength">-</span>
                    </div>
               
                </div>
            </div>
        </ng-container>
    </ng-container>

    <!-- Maintenance -->
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_VehMaintenance" translate>{{'maintenance'}}</div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehMainLastDate" translate>{{"last_maintenance_date"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehMainLastDate" *ngIf="vehicleDetail?.vehicle?.lastMaintainedDate">{{vehicleDetail?.vehicle?.lastMaintainedDate | momentDate : dateFormat : timezone}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehMainLastDate" *ngIf="!vehicleDetail?.vehicle?.lastMaintainedDate">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehMainFreqMonths" translate>{{"frequency_months"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehMainFreqMonths" *ngIf="vehicleDetail?.vehicle?.serviceFreq">{{vehicleDetail?.vehicle?.serviceFreq}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehMainFreqMonths" *ngIf="!vehicleDetail?.vehicle?.serviceFreq">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehMainNextDate" translate>{{"next_maintenance_date"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehMainNextDate" *ngIf="vehicleDetail?.vehicle?.nextMaintainanceDate">{{vehicleDetail?.vehicle?.nextMaintainanceDate | momentDate : dateFormat : timezone}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehMainNextDate" *ngIf="!vehicleDetail?.vehicle?.nextMaintainanceDate">-</span>
            </div>
        </div>
    </div>

    <!-- Cost -->
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_VehCost">{{'cost' | translate}}</div>
    </div>

    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehCostPerKm" translate>{{"costperKm"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehCostPerKm" *ngIf="vehicleDetail?.vehicle?.costPerKm">{{orgSettings?.currency}}{{vehicleDetail?.vehicle?.costPerKm | number:'.2-2'}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehCostPerKm" *ngIf="!vehicleDetail?.vehicle?.costPerKm">-</span>
            </div>
            
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehCostFixed" translate>{{"fixed_cost"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehCostFixed" *ngIf="vehicleDetail?.vehicle?.fixedCost">{{orgSettings?.currency}}{{vehicleDetail?.vehicle?.fixedCost | number:'.2-2'}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehCostFixed" *ngIf="!vehicleDetail?.vehicle?.fixedCost">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehCostPerMin" translate>{{"cost_per_min"}} </span>

                <span class="custella-content-row-input-title-three" id="value_VehCostPerMin" *ngIf="vehicleDetail?.vehicle?.costPerMin">{{orgSettings?.currency}}{{vehicleDetail?.vehicle?.costPerMin | number:'.2-2'}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehCostPerMin" *ngIf="!vehicleDetail?.vehicle?.costPerMin">-</span>
            </div>
        </div>
    </div>

    <!-- Additional Details -->
    <div class="custella-form-container-title-two flex-between">
        <div class="font-medium text-14" id="title_VehAddDet" translate>{{'additional_details'}}</div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehRegisterDate" translate>{{"registration_date"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehRegisterDate" *ngIf="vehicleDetail?.vehicle?.vehicleRegisteredDateTime">{{vehicleDetail?.vehicle?.vehicleRegisteredDateTime | momentDate : dateFormat : timezone}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehRegisterDate" *ngIf="!vehicleDetail?.vehicle?.vehicleRegisteredDateTime">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehSubcontractor" translate>{{"subcontractor"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehSubcontractor" *ngIf="vehicleDetail?.vehicle?.subContractor?.name">{{vehicleDetail?.vehicle?.subContractor?.name}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehSubcontractor" *ngIf="!vehicleDetail?.vehicle?.subContractor?.name">-</span>
            </div>
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehFuelCap" translate>{{"fuel_capacity"}} </span>
                <span class="custella-content-row-input-title-three" id="value_VehFuelCap" *ngIf="vehicleDetail?.vehicle?.fuelCapacity">{{vehicleDetail?.vehicle?.fuelCapacity | number:'.2-2'}}</span>
                <span class="custella-content-row-input-title-three" id="value_VehFuelCap" *ngIf="!vehicleDetail?.vehicle?.fuelCapacity">-</span>
            </div>
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title color-var-500" id="label_VehColor" translate>{{"color"}} </span>
                <div [style.backgroundColor]="vehicleDetail?.vehicle?.color" id="value_VehColor" *ngIf="vehicleDetail?.vehicle?.color" style="height: 20px; width: 20px;"></div>
                <span class="custella-content-row-input-title-three" id="value_VehColor" *ngIf="!vehicleDetail?.vehicle?.color">-</span>
            </div>
           
        </div>
    </div>
    <div class="custella-form-container-content-border-bottom">
        <div class="custella-content-row">
            <div class="custella-content-row-input col-6">
                <span class="custella-content-row-input-title-two" id="label_VehStatus">{{'active' | translate}}</span>
                <!-- vehicle status -->
                <div>
                    <p *ngIf="vehicleDetail?.vehicle.active" id="value_VehStatusActive" class="custella-content-row-input-title-three">{{ "active" | translate }}</p>
                    <p *ngIf="!vehicleDetail?.vehicle.active" id="value_VehStatusInactive" class="custella-content-row-input-title-three">{{ "inactive" | translate }}</p>
                </div>
            </div>
        </div>
    </div>
   


    <!-- System Information -->
    <app-system-information
        [createdByUser]="vehicleDetail?.vehicle?.createdByUser"
        [createdDate]="vehicleDetail?.vehicle?.createdDate"
        [modifiedByUser]="vehicleDetail?.vehicle?.lastModifiedByUser"
        [lastModifiedDate]="vehicleDetail?.vehicle?.lastModifiedDate"
    ></app-system-information>
</div>
