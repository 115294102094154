import { Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PermissionService } from "src/app/auth/permission.service";
import { PERSISTANT_ITEM, ServerRequest } from "src/app/_models/global.data.model";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { JobOrdersTableComponent } from "../../shared/job-orders-table/job-orders-table.component";
import { ColDef, GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { Subscription } from "rxjs";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";
var dateFormateChange: any;
var statusesJS: any = [];

@Component({
  selector: "app-checklist-job-orders",
  templateUrl: "./checklist-job-orders.component.html",
  styleUrls: ["./checklist-job-orders.component.scss"],
})
export class ChecklistJobOrdersComponent
extends SubscriptionUtil
implements OnInit, OnDestroy
{
  @Input() checklistRecordId: any = null
  @Input() pmvId: any = null

 searchValue = "";

gridApi: any;
gridColumnApi: any;
columnDefs: any = [];
defaultColDef: any;
gridParams: any;
startFrom: any;
product: any;
pageSize: any = 25;
parameters: any;
gridOptions!: Partial<GridOptions>;
overlayNoRowsTemplate!: string;

dateFormateChange: any;

statusesJS: any[] = [];
jobOrders: any = [];
totalRecord: any;

noChecklistJobOrders: boolean = false;
currentRecordNumber: any;

loadTable: any = 0;
searchValueData: boolean = false;
actionData!: ColDef;
deleteSubscription!: Subscription;
  frameworkComponents: any;

  sortBy: any;
  sortOrder: any;

constructor(
  private auth: AuthenticationService,
  private jobOrdersService: JobOrdersService,
  private spinner: NgxSpinnerService,
  private errorUtil: ErrorUtil,
  private router: Router,
  private util: UtilServiceService,
  private perm: PermissionService,
  private deleteRecordService: DeleteRecordService,
  private dynamicTableColumnsService: DynamicTableColumnsService
) {
  super();

  this.preInit();
}

ngOnInit(): void {
  this.init();
}


ngOnDestroy() {
  this.onDestroy();
}

/******************************************** Pre Init ******************************************/
private preInit() {
  this.frameworkComponents = {
    buttonRenderer: ButtonRendererComponent
  };

  
  this.gridOptions = {
    cacheBlockSize: 25,
    rowModelType: "infinite",
    sortingOrder: ["desc", "asc"],
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    tooltipShowDelay: 100,
  };

  this.defaultColDef = {
    enableRowGroup: false, //ag-Grid-Enterprise
    enablePivot: false, //ag-Grid-Enterprise
    enableValue: true, //ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent,
  };

  this.overlayNoRowsTemplate =
    '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
    this.actionData = {
      headerName: "Action",
      filter: false,
      sortable: false,
      width: 40,
      headerTooltip: "#",
      headerClass: "marging-auto hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("job order", "update"),
          remove: this.perm.capable("job order", "remove"),
        },
        hidden: {
          update: !this.perm.capable("job order", "update"),
          remove: !this.perm.capable("job order", "remove"),
        },
      },
    };
}

/******************************************** call table after filter ******************************************/
private edit(e: any) {
  if (e.action === "edit") {
   
    // if (this.extraData && this.extraData.hasOwnProperty("salesOrderId")) {
    //   e.rowData = Object.assign(
    //     {},
    //     { salesOrderId: this.extraData.salesOrderId, from: "salesOrder" }
    //   );
    // }

    this.util.setPersistantItem(PERSISTANT_ITEM.JOB_ORDER, e.rowData);

    this.router.navigate(
      ["/dashboard/jobOrders/update/" + e.rowData.jobOrder.id],
      { queryParams: { id: e.rowData.jobOrder.id } }
    );
  } else if (e.action === "delete") {
    let message = "";

    if (
      e.rowData &&
      e.rowData.jobOrder &&
      e.rowData.jobOrder.jobOrderName &&
      (e.rowData.jobOrder.jobOrderName !== "" ||
        e.rowData.jobOrder.jobOrderName !== null)
    ) {
      message = `Are you sure you want to delete ${e.rowData.jobOrder.jobOrderNumber} - ${e.rowData.jobOrder.jobOrderName}?`;
    } else {
      message = `Are you sure you want to delete ${e.rowData.jobOrder.jobOrderNumber}?`;
    }

    const item = {
      deleteType: "Job Order",
      id: e.rowData.jobOrder.id,
      message: message,
    };

    if (!this.deleteSubscription) {
      this.deleteSubscription = this.deleteRecordService
        .getItems(item)
        .pipe()
        .subscribe((data) => {
          // refresh the table

          if (data.status === 200) {
            this.onGridReady(this.gridParams);
          }
        });
    } else {
      this.deleteRecordService.getItems(item);
    }
  }
}

/******************************************** init ******************************************/
private init() { 
  this.spinner.show();
  dateFormateChange = this.auth.getUserTimezone();

  this.push(
    this.jobOrdersService.getJobOrderStatuses().subscribe((res: any) => {
      res.forEach((element: any) => {
        statusesJS.push(element);
      });
    })
  );

  this.push(
    this.jobOrdersService.getListColumn("job_order").subscribe((res: any) => {
      this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder

      let columnDefs = this.gridOptions.columnDefs as any;
      res.tableColumnSetups.forEach((element: any) => {
        const modifiedColumn = this.modifyColumnTable(element);

        columnDefs!.push(modifiedColumn);
        this.gridOptions.api!.setColumnDefs(columnDefs);
      });

      columnDefs!.push(this.actionData);

      this.gridOptions.api!.setColumnDefs(columnDefs);
      this.loadTable = 1;
      this.onGridReady(this.gridParams);
    })
  );
}

/******************************************** On Destroy ******************************************/
private onDestroy() {
  super.ngOnDestroy();
}

  /******************************************** modify column to fit new ag-grid ******************************************/
  public modifyColumnTable(element: any) {
    var dateFormat: any = localStorage.getItem("date_format");
    var getUserTimezone: any = this.auth.getUserTimezone();
    // base
    const column: ColDef = {
      field: "jobOrder." + element.field,
      headerName: this.util.formatText(element.headerName, "titlecase", {
        seperator: " ",
      }),
      hide: element.hide,
      width: 30,
      colId: element.field,
      cellClass: element.cellClass,
      headerTooltip: "#",
    };

   

    if (element.field === "jobOrderNumber") {
      column.headerName = "Job Order No.";
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeaderCheckJobOrd'),
      column.cellRenderer = function(params: any) {
        if (params && params.data && params.data.jobOrder) {
          const hasRedFlag = params.data.jobOrder.redFlag;

          const number = params.data.jobOrder.jobOrderNumber;
  
          let final = `${number}`;

          if (hasRedFlag) {
            final += '  <i class="pi pi-flag" style="color: red"></i>';
          }
  
          return final;
        }
      }
    }

    else if (element.field === "customer.name") {
      element.headerComponentParams =  Global.setHeaderComponentParams(element,'Tbl_HeadCheckCustomer')
    
    }

    else if (element.field === "assets.name") {
      column.cellClass = function (params: any) {
        return ["table_default_color"];
      };

   
    }

   else if (element.field === "installationDate" || element.field == "installationTime") {
      // column.headerName = "Order Date & Time";
       column.cellRenderer = (params: any) => {
         if (params.data) {
      //     console.log(params.data)
      //     if (
      //       params.data.jobOrder.installationDate !== null &&
      //       params.data.jobOrder.installationDate !== undefined &&
      //       params.data.jobOrder.installationDate !== "" &&
      //       params.data.jobOrder.installationTime !== null &&
      //       params.data.jobOrder.installationTime !== undefined &&
      //       params.data.jobOrder.installationTime !== ""
      //     ) {
            return (
              moment(params.data.jobOrder.installationDate).format(
                localStorage.getItem("date_format")?.toUpperCase()
              ) +
              ", " +
              moment(params.data.jobOrder.installationTime).format("hh:mm A")
            );
          // } else {
          //   return moment(params.data.jobOrder.installationDate).format(
          //     localStorage.getItem("date_format")?.toUpperCase()
          //   );
          // }
        }
      };
    }

    else  if (element.field === "completionDate") {
      column["cellRenderer"] = (params: any) => {
        if (params.data) {
          if (
            params.data.jobOrder.completionDate !== null &&
            params.data.jobOrder.completionDate !== undefined &&
            params.data.jobOrder.completionDate !== ""
          ) {
            return moment(params.data.jobOrder.completionDate).format(
              localStorage.getItem("date_format")!.toUpperCase()
            );
          } else {
            return "";
          }
        }
      };
    }
    else if (element.field == "completionDateTime") {
      column["cellRenderer"] = (params: any) => {
          if (params.data) {
            if (params.data.jobOrder.completionDateTime !== null && params.data.jobOrder.completionDateTime !== undefined && params.data.jobOrder.completionDateTime !== "") {
              return (
                moment(params.data.jobOrder.completionDateTime).format(
                  localStorage.getItem("date_format")?.toUpperCase()
                ) +
                ", " +
                moment(params.data.jobOrder.completionDateTime).format("hh:mm A")
              );
            } else {
              return "-";
            }   
      };
    }
  }
  
    else if (element.field === "status") {
      
      column.cellRenderer = (params: any) => {
        let status: any;
        console.log(params,"====",params.data)
        
        if (params.data) {
          if (params.data.jobOrder.status) {
            const currentStatus = params.data.jobOrder.status;
            let satusObj: any;
            satusObj = statusesJS.find((status: any) => status.name === currentStatus);
            status =
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              satusObj.colorCode +
              "50 !important ;color:" +
              satusObj.colorCode +
              '"><span class="p-tag-value text-dark">' +
              satusObj.fieldLabel +
              "</span></span>";
          }
        }
        return status;
      };
    }  else if (element.dataType == 'date') {
 
      column.cellRenderer = function (params: any) {
          if (params && params.value) {
            
            return moment.tz(params.value, getUserTimezone).format(dateFormat.toUpperCase());
          }else{
            return '-'
          }
        };
    }else if (element.dataType == "text") {
      column.cellRenderer = function (params: any) {
          if (params && params.value && params.value != undefined) {
            return params.value
            }else{
              return '-'
            }
          }  
    }else if (element.dataType == 'datetime') {
      column.cellRenderer = function (params: any) {
            console.log(params.value ,"===>>>>",getUserTimezone,"===>>>>",dateFormat.toUpperCase())
              if (params && params.value) {
                return moment.tz(params.value, getUserTimezone) .format("hh:mm A" + ", " + dateFormat.toUpperCase());
              
              }else{
                return '-'
              }
            };
          
        }else if (element.dataType == "boolean") {
          column.cellRenderer = function (params: any) {
              if (params && params.value) {
                return 'Yes'
                }else{
                  return 'No'
                }
              }  
        }

    return column;
  }


/******************************************** on click data ******************************************/
public onCellClicked(e: any) {
  this.util.resetTabView();
  console.log(e.colDef.field,"===",e.data.jobOrder)
  if (e.colDef.field === "jobOrder.jobOrderNumber") {
    localStorage.setItem("activeIndexJobOrder","0");
    this.router.navigate(['dashboard/jobOrders/view/' + e.data.jobOrder.id], { queryParams: { jobOrderId: e.data.jobOrder.id}});
 
  }else  if (e.colDef.field === "jobOrder.uncompleatedJobOrder.jobOrderNumber"  && e.data.jobOrder.uncompleatedJobOrder) {
    this.router.navigate(['dashboard/jobOrders/view/' + e.data.jobOrder.uncompleatedJobOrder.id], { queryParams: { jobOrderId: e.data.jobOrder.uncompleatedJobOrder.id}});
  }
  if (e.colDef.field === "jobOrder.customer.name") {
    this.router.navigate(['dashboard/customers/view/' + e.data.jobOrder.customer.id], { queryParams: { id: e.data.jobOrder.customer.id}});
  
  }else if (e.colDef.field === "salesOrder.orderName" && e.colDef.cellClass !== null && e.data.jobOrder.salesOrder){
    this.router.navigate(
      ["dashboard/salesOrders/view/" + e.data.jobOrder.salesOrder.id],
      {
        queryParams: {
          customerId: e.data.jobOrder.customer.id,
          salesOrderId: e.data.jobOrder.salesOrder.id,
        },
      }
    );
  }else if (e.colDef.field === "jobOrder.assets.name" && e.colDef.cellClass !== null && e.data.jobOrder.assets) {
    console.log(e.data);
    this.router.navigate(
      ["dashboard/assets/view/" + e.data.jobOrder.assets.id],
      {
        queryParams: { id: e.data.jobOrder.assets.id, serialNo: null },
      }
    );
  } else if (e.colDef.field === "assets.name" && e.colDef.cellClass !== null && e.data.jobOrder.assets) {
    console.log(e.data);
    this.router.navigate(
      ["dashboard/assets/view/" + e.data.jobOrder.assets.id],
      {
        queryParams: { id: e.data.jobOrder.assets.id, serialNo: null },
      }
    );
  } else if (e.colDef.field == "jobOrder.ticket.ticketNo" && e.colDef.cellClass !== null && e.data.jobOrder.ticket) {
    if (e.data.jobOrder.ticket) {
      this.router.navigate(
        ["dashboard/tickets/view/" + e.data.jobOrder.ticket.id],
        {
          queryParams: { id: e.data.jobOrder.ticket.id },
        }
      );
    }
  }else if ( (e.data.field === "jobOrder.site.siteName" || e.data.field == "site.siteName") && e.data.jobOrder.site) {
    this.router.navigate(
        ["dashboard/sites/view/" + e.data.jobOrder.site.id],
        {
          queryParams: { id: e.data.jobOrder.site.id },
        }
      
    );
  } else if ((e.data.field === "jobOrder.project.name" || e.data.field === "project.name") && e.data.jobOrder.project ) {
    this.router.navigate(
        ["dashboard/projects/view/" + e.data.jobOrder.project.id],
        {
          queryParams: { id: e.data.jobOrder.project.id },
        }
      )
    
  }
}

/******************************************** on grid ready ******************************************/
public onGridReady(params: any) {
  if (params != undefined) {
    this.gridParams = params;

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    var dataSource = {
      getRows: (params: IServerSideGetRowsParams) => {
        if (this.loadTable === 1) {
          this.getServerSideData(params);
        }
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
}

/******************************************** get server side data ******************************************/
private getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
  

  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);

  this.push(
    this.jobOrdersService
      .getAllJobOrders_2(
        serverRequest.startRow,
        serverRequest.pageSize,
        "",
        "",
        "",
        null,
        null,
        this.searchValue,
        serverRequest,
        null,
        null,
        this.checklistRecordId,
        this.pmvId
      )
      .subscribe(
        (data) => {
          // this.jobOrders = [];
          // data.data.forEach((element: any) => {
          //   this.jobOrders.push(element.jobOrder);
          // });
          this.jobOrders=data.data
          this.totalRecord = data.total;

          this.setGridData(
            agGridGetRowsParams,
            this.jobOrders,
            this.totalRecord,
            data.status
          );

            this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          this.errorUtil.setErrorMessage(error);
        }
      )
  );
}

/******************************************** set grid data ******************************************/
private setGridData(
  agGridGetRowsParams: IServerSideGetRowsParams,
  resultItems: any[],
  totalCount: number,
  status: string
) {
  if (status === "NO_DATA") {
    this.noChecklistJobOrders = false;
  } else {
    this.noChecklistJobOrders = true;

    if (resultItems.length === 0) {
      this.gridApi.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
     ///CS-4758 this.gridApi.sizeColumnsToFit();
    }
  }
}

/******************************************** get server request ******************************************/
private getRequestParams(agGridRequest: any): ServerRequest {
  if (agGridRequest.sortModel.length <= 0) {
    this.parameters = { colId: this.sortBy, sort: this.sortOrder };

    agGridRequest.sortModel.push(this.parameters);
  }

  this.currentRecordNumber = agGridRequest.startRow;
  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel,
  };
}

/******************************************** redirect to ******************************************/
private redirectToNewTab(data: any) {
  this.util.resetTabView();
  let url;
  console.log(data)
  
  if (data.field === "jobOrder.jobOrderNumber") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(["dashboard/jobOrders/view/" + data.jobOrder.id], {
        queryParams: { jobOrderId: data.jobOrder.id },
      })
    );
  
}else if (data.field == "uncompleatedJobOrder.jobOrderNumber") {
  
  url = this.router.serializeUrl(
    this.router.createUrlTree(["dashboard/jobOrders/view/" + data.jobOrder.uncompleatedJobOrder.id], {
      queryParams: { jobOrderId: data.jobOrder.uncompleatedJobOrder.id },
    })
  );
}
 else if (
    data.field === "jobOrder.customer.name" ||
    data.field === "customer.name"
  ) {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/customers/view/" + data.jobOrder.customer.id],
        {
          queryParams: { id: data.jobOrder.customer.id },
        }
      )
    );
  }

  else if (
    data.field === "jobOrder.salesOrder.salesOrderNumber" ||
    data.field === "salesOrder.orderName"
  ) {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/salesOrders/view/" + data.jobOrder.salesOrder.id],
        {
          queryParams: {
            // customerId: data.customer.id,
            salesOrderId: data.jobOrder.salesOrder.id,
          },
        }
      )
    );
  }

 else if (data.field === "jobOrder.assets.name" || data.field === "assets.name") {
    url = this.router.serializeUrl(
      this.router.createUrlTree(["dashboard/assets/view/" + data.jobOrder.assets.id], {
        queryParams: { id: data.jobOrder.assets.id, serialNo: null },
      })
    );
  }

 else if (
    data.field === "jobOrder.ticket.ticketNo" ||
    data.field === "ticket.ticketNo"
  ) {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/tickets/view/" + data.ticket.id],
        {
          queryParams: { id: data.ticket.id },
        }
      )
    );
  }else if (
    (data.field == "jobOrder.project.name" ||
    data.field == "project.name") && data.jobOrder.project
  ) {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/projects/view/" + data.jobOrder.project.id],
        {
          queryParams: { id: data.jobOrder.project.id },
        }
      )
    );
  }


 else if (
    (data.field == "jobOrder.site.name" ||
    data.field == "site.siteName") && data.jobOrder.site
  ) {
    url = this.router.serializeUrl(
      this.router.createUrlTree(
        ["dashboard/sites/view/" + data.jobOrder.site.id],
        {
          queryParams: { id: data.jobOrder.site.id },
        }
      )
    );
  }

  if (url) {
    url = url.replace("%23", "#");
    this.util.openItemToNewTab(url);
  }
}

/******************************************** right click cell context menu ******************************************/
 /******************************************** right click cell context menu ******************************************/
 public getContextMenuItems = (params: any) => {
  params.node.data.field = params.column.colId;
  let url = "";
  let data = "";

  let results = [];
console.log(params.column.colId)
  if (params.column.colId == "jobOrderNumber") {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });

    // results.push({
    //   name: "Export to Excel",
    //   action: () => this.onBtExport(data),
    // });
  
}else if (params.column.colId == "uncompleatedJobOrder.jobOrderNumber" && params.node.data.jobOrder.uncompleatedJobOrder) {
  const data = params.node.data;
  results.push({
    name: "Open link in new tab",
    action: () => this.redirectToNewTab(params.node.data),
  });
}

  if (
    (params.column.colId == "jobOrder.customer.name" ||
    params.column.colId == "customer.name" ) && params.node.data.jobOrder.customer
  ) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  if (
    (params.column.colId == "jobOrder.salesOrder.salesOrderNumber" ||
    params.column.colId == "salesOrder.orderName" ) && params.node.data.jobOrder.salesOrder
  ) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  if (
    (params.column.colId == "assets.name" ||
    params.column.field == "jobOrder.assets.name") && params.node.data.jobOrder.assets
  ) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  if (
    (params.column.colId == "jobOrder.ticket.ticketNo" ||
    params.column.colId == "ticket.ticketNo") && params.node.data.jobOrder.ticket
  ) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }


  if (
    (params.column.colId == "jobOrder.project.name" ||
    params.column.colId == "project.name") && params.node.data.jobOrder.project
  ) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }


  if (
    (params.column.colId == "jobOrder.site.name" ||
    params.column.colId == "site.siteName") && params.node.data.jobOrder.site
  ) {
    const data = params.node.data;

    results.push({
      name: "Open link in new tab",
      action: () => this.redirectToNewTab(data),
    });
  }

  return results;
};
 push(obs:any) {
  super.push(obs);
}
onRefreshList() {
  this.onGridReady(this.gridParams);
}
/********************************** Search Data **************************/
onSearch() {
  if (this.searchValue && this.searchValue.length > 0) {
    this.searchValueData = true
    this.onRefreshList();
  }
 
}
public onSearchCheck()
  {
    if(this.searchValue.length == 0)
    {
      this.searchValueData = false
      this.searchValue = ''
      this.onRefreshList()
    }
  }
  public onClear(){
    this.searchValueData = false
    this.searchValue = ''
    this.onRefreshList()
    
  }
  createView(){
    this.router.navigate(['dashboard/jobOrders/create'],{queryParams: { checkListRecordId: this.checklistRecordId ,pmvId: this.pmvId} })
  }
}

