<div class="mt-56px">
    <!-- Container -->
    <form [formGroup]="taskForm" >
        <div class="custella-form-container left-right-spacerounded-2">
            <!-- Container Content -->
            <div class="custella-form-container-title text-14 font-medium rounded-top" translate>{{'select_filter'}}
            </div>
            <div class="custella-form-container-content">
                <!-- <div class="custella-content-row mb-3">
                    <div class="d-flex justify-content-end">
                        <button class="border rounded-1 px-04 ms-2 btn-32">
                            <img alt="logo" src="assets\svg\refresh.svg" />
                          </button>                    
                          <span class="p-input-icon-left  mx-1">
                            <i class="pi pi-search me-3"></i>
                            <input type="text" pInputText autocomplete="off" placeholder="Search"/>
                          </span>
                    </div>
                </div> -->
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'equipment_type'}}</span>
                        <p-dropdown [options]="equipmentList" optionLabel="name" id="l-text" #myDropDown
                            optionValue="_id" formControlName="equipment" name="equipment" filterBy="name"
                            [showClear]="true" [(ngModel)]="filter.equipmentType"
                            placeholder="Select Equipment Type">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list">
                                    <div>{{ user.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'task_type'}}</span>
                        <p-dropdown [options]="taskTypes" optionLabel="label" id="l-text" #myDropDown optionValue="name"
                            formControlName="taskType" name="taskType" filterBy="name" [showClear]="true"
                            [(ngModel)]="filter.taskType" placeholder="Select Task Type">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list">
                                    <div>{{ user.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'location'}}</span>
                        <p-dropdown [options]="locations" optionLabel="fieldLabel" id="l-text" #myDropDown
                            optionValue="name" formControlName="location" name="location" filterBy="name"
                            [showClear]="true" [(ngModel)]="filter.location"
                            placeholder="{{ 'select_location' | translate }}">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list">
                                    <div>{{ user.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'make_model'}}</span>
                        <p-dropdown [options]="makeList" optionLabel="makeModel" id="l-text" #myDropDown
                            optionValue="_id" formControlName="makeModel" name="makeModel" filterBy="makeModel"
                            [showClear]="true" [(ngModel)]="filter.makeModel"
                            placeholder="Select Make Model">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list">
                                    <div>{{ user.makeModel }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="custella-content-row mb-3">
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'manufacturer'}}</span>
                        <p-dropdown [options]="manufacturerList" optionLabel="manufacturer" id="l-text" #myDropDown
                            optionValue="_id" formControlName="manufacturer" name="manufacturer" filterBy="name"
                            [showClear]="true" [(ngModel)]="filter.manufacturer"
                            placeholder="Select Manufacturer">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list">
                                    <div>{{ user.manufacturer }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="custella-content-row-input col-6">
                        <span class="custella-content-row-input-title" translate>{{'department'}}</span>
                        <p-dropdown [options]="departmentList" optionLabel="name" id="l-text" #myDropDown
                            optionValue="_id" formControlName="departmentId" name="departmentId" filterBy="name"
                            [showClear]="true" [(ngModel)]="filter.departmentId"
                            placeholder="{{ 'select_department' | translate }}">
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list">
                                    <div>{{ user.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer mb-0">
                <div class="d-flex align-items-center justify-content-end mb-0">
                    <button pButton pRipple class="p-button-secondary" (click)="resetSearch()" label="Reset" style="margin-right: 7px;"></button>
                    <p-button pRipple (click)="search()" label="Search" class="p-button-text">
                    </p-button>
                </div>
            </div>
            <!-- Task Information -->
            <div class="custella-form-container-title-two" translate>{{'task_list_details'}}</div>
            <div class="custella-form-container-content">
                <div *ngIf="taskTrigerList" class="custella-content-row mb-1 pe-0">
                    <div class="custella-content-row-input col-12">
                        <span class="p-input-icon-left w-100" [ngClass]="searchValue?.length>0 ? 'p-input-icon-right':''">
                            <i class="pi pi-search me-3" *ngIf="taskTrigerList"></i>
                            <input type="text" pInputText autocomplete="off" placeholder="Search"  (keyup)="filterResults($event)"
                                *ngIf="taskTrigerList" [(ngModel)]="searchValue" class="w-100" formControlName="searchValue" name="searchValue"/>
                            <i class="pi pi-times-circle" (click)="onSearchClear()" *ngIf="searchValue?.length>0"></i>
                        </span>
                    </div>
                </div>
                
                <!-- ag-grid -->
                <div class="custella-form-container-content-table">
                    <div class="custella-content-row mt-0 pe-0">
                        <div class="custella-content-row-input col-12">
                            <div style="padding-top: 0px;margin-top: 5px; height: 300px;width: 100%;">
                                <ag-grid-angular #agGrid id="myGrid" style="height: 100%;width:100%"
                                    class="ag-theme-balham" [pagination]=true [paginationPageSize]="25"
                                    [gridOptions]="gridOptions" [columnDefs]="columnDefs"
                                    [defaultColDef]="defaultColDef" [suppressRowClickSelection]="true"
                                    [rowSelection]="rowSelection" (rowSelected)="onSelectionChanged($event)"
                                    [rowData]="taskTrigerList" (gridReady)="onGridReady($event)"
                                    [overlayNoRowsTemplate]="overlayNoRowsTemplate"></ag-grid-angular>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Container Footer -->
            <div class="custella-form-container-footer">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" (click)="goBack()">{{'cancel' | translate}}</button>
                    <p-button [label]="buttonLabel" (click)="addTask()" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>