import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-default-confirmation-dialog',
  templateUrl: './default-confirmation-dialog.component.html',
  styleUrls: ['./default-confirmation-dialog.component.scss']
})
export class DefaultConfirmationDialogComponent implements OnInit {
  public onClose = new Subject<boolean>();
  data : any ={};

  constructor(public modalRef : BsModalRef, public options: ModalOptions, private modalService: BsModalService) { 
    // console.log(this.options.initialState);
    this.data = this.options.initialState;
  }

  ngOnInit(): void {
  }

  closedModal() {
    this.onClose.next(false);
    this.modalRef.hide();
  }

  onSubmit(){
    // this.modalService.hide(1);
    this.onClose.next(true);
    this.modalRef.hide();
  }
}
