import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TicketsService } from "src/app/_services/tickets.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import * as moment from "moment-timezone";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorUtil } from "src/app/_utilities/error";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { AuthenticationService } from "src/app/auth/authentication.service";
import * as FileSaver from "file-saver";
import { AttachmentService } from "src/app/_services/attachment.service";
import { PermissionService } from "src/app/auth/permission.service";

@Component({
  selector: "app-ticket-attachment",
  templateUrl: "./ticket-attachment.component.html",
  styleUrls: ["./ticket-attachment.component.scss"],
})
export class TicketAttachmentComponent
  extends SubscriptionUtil
  implements OnInit
{
  public attachmentsArray: any[] = [];
  private ticketDetails: any;
  @Input() ticketId: any;

  public seletedAttachment: any;

  public noAttachments = false;
  public timezone: any;
  public dateFormat: any;

  @ViewChild("fileUpload", { static: false })
  fileUpload!: any;

  createTicket: any;
  deleteTicket: any;

  constructor(
    private ticketsService: TicketsService,
    private util: UtilServiceService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private errorUtil: ErrorUtil,
    private deleteRecordService: DeleteRecordService,
    private auth: AuthenticationService,
    private attachmentService: AttachmentService,
    private perm: PermissionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  /******************************************** on init ******************************************/
  private init() {
    this.createTicket = this.perm.capable("tickets", "create");
    this.deleteTicket = this.perm.capable("tickets", "remove");

    if (!this.ticketId) {
      this.ticketId = this.route.snapshot.params["id"];
    }

    this.timezone = this.auth.getUserTimezone();
    this.dateFormat = localStorage.getItem("date_format");

    this.loadData();
  }

  /******************************************** get n load data ******************************************/
  public loadData() {
    this.spinner.show();

    this.attachmentsArray = [];


    this.push(
      this.ticketsService.getTicketById(this.ticketId).subscribe(
        (res: any) => {
          this.ticketDetails = res;

          const attachments = this.ticketDetails.attachmentList;

          if (attachments.length === 0) {
            this.noAttachments = true;
          } else {
            this.noAttachments = false;

            this.push(
            this.ticketDetails.attachmentList.forEach((attachment: any) => {
              attachment.calendarTime = moment(attachment.createdDate).calendar(
                null,
                this.util.returnCalendarFormat()
              );

              attachment.file = this.attachmentService.getFileType(
                attachment.contentContentType,
                attachment.attachmentUrl
              );

              attachment.icon = this.attachmentService.getFileicon(
                attachment.contentContentType
              );

              this.attachmentsArray.push(attachment);
            }));

            if (this.attachmentsArray[0]) {
              this.onSelectedAttachment(this.attachmentsArray[0]);
            }
          }

          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();

          this.errorUtil.setErrorMessage(
            error.statusCode,
            error,
            error.message,
            null,
            300000
          );
        }
      )
    );
  }

  /******************************************** upload file pre ******************************************/
  public uploadFile($event: any) {
    this.spinner.show();
    this.parse($event.files);
  }

  /******************************************** upload file during ******************************************/
  private parse(files: FileList): void {
    const file: File = files[0] as File;

    const form = new FormData();
    form.append("file", file);

    this.push(
    this.ticketsService.uploadAttachment(this.ticketId, form).subscribe(
      (res: any) => {
        if(res.status == 200 || res.status == 201){
        this.clearUpload();

        var body = JSON.parse(res._body);
        body.fileName = file.name;
        body.calendarTime = moment(body.createdDate).calendar(
          null,
          this.util.returnCalendarFormat()
        );

        body.file = this.attachmentService.getFileType(
          body.contentContentType,
          body.attachmentUrl
        );
        body.icon = this.attachmentService.getFileicon(body.contentContentType);

        this.attachmentsArray.push(body);
        const index = this.attachmentsArray.length - 1 > 0 ? this.attachmentsArray.length - 1 : 0;

        this.onSelectedAttachment(this.attachmentsArray[index]);
        this.spinner.hide();
        this.spinner.hide();
      }else{
        this.spinner.hide();
        this.clearUpload();
        var body = JSON.parse(res._body);
        this.errorUtil.setErrorMessage(res.status, null, body.title, 'error', 3000);
      }
    },
    (error) => {
      
      this.spinner.hide();
      this.clearUpload();
      var body = JSON.parse(error._body);
        this.errorUtil.setErrorMessage(error.status, null, body.title, 'error', 3000);
    }
    ));
  }

  /******************************************** download attachment ******************************************/
  public downloadAttachment(attachment: any) {
    let fileName = "";
    let fileType = "";
    let url = "";
    try {
      fileName = attachment.fileName;
      if (fileName)
        fileType = (attachment.fileName as string)
          ? (attachment.fileName as string).split(".")[
              (attachment.fileName as string).split(".").length - 1
            ]
          : "";
      url = attachment.attachmentUrl;

      if (fileName && fileType) {
        this.spinner.hide();
        FileSaver.saveAs(url, fileName);
      } else {
        throw new Error("Cannot find filename and filetype");
      }
    } catch (e) {
      url = attachment.attachmentUrl;
      fileName = (url as string)
        ? (url as string).split("/")[(url as string).split("/").length - 1]
        : "";
      fileType = (fileName as string)
        ? (fileName as string).split(".")[(url as string).split(".").length - 1]
        : "";

      this.spinner.hide();
      FileSaver.saveAs(url, fileName);
    }
  }

  /******************************************** delete attachment ******************************************/
  public deleteAttachment(attachment: any) {
    const obj = { deleteType: "Ticket Attachment", id: attachment.id, message: `Are you sure you want to delete ${attachment?.fileName}?` };

    this.push(
    this.deleteRecordService.getItems(obj).subscribe((data) => {
      this.onDeleteAttachments(attachment);
    }));
  }

    /******************************************** On Delete Attachments ******************************************/
  private onDeleteAttachments(attachment: any) {
    this.seletedAttachment = null;

    const foundAttachmentIndex = this.attachmentsArray.findIndex(a => a.id === attachment.id);

    if (foundAttachmentIndex !== -1) {
      this.attachmentsArray.splice(foundAttachmentIndex, 1);

      const index = this.attachmentsArray.length - 1 > 0 ? 0 : 0;

      this.onSelectedAttachment(this.attachmentsArray[index]);
    }
  }

  /******************************************** clear upload ******************************************/
  private clearUpload() {
    this.fileUpload.clear();
  }

  /******************************************** on select attahcment - set the attachment ******************************************/
  public onSelectedAttachment(attachment: any) {
    this.seletedAttachment = attachment;
  }
   push(obs:any) {
    super.push(obs);
  }
}
