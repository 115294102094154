import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FleetDashboardService } from "src/app/_services/fleet-dashboard.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { WebSocketsService } from "src/app/_services/web-sockets.service";
import { AuthenticationService } from "src/app/auth/authentication.service";
import {
  PushNotificationOptions,
  PushNotificationService,
} from "src/app/shared/ngx-push-notifications-master/push-notification.service";

import * as moment from "moment-timezone";
import { PermissionService } from "src/app/auth/permission.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { ScaleType } from "@swimlane/ngx-charts";
import { DeliveryOrderSetupService } from "src/app/_services/delivery-order-setup.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { UsersService } from "src/app/_services/admin/users.service";

@Component({
  selector: "app-fleet-dashboard",
  templateUrl: "./fleet-dashboard.component.html",
})
export class FleetDashboardComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  single: any;

  view: any | null = [null, 250];
  width: number = 700;
  height: number = 300;
  todaysDate!: Date;

  // options
  showXAxis = true;
  showYAxis = true;
  rotateXAxisTicks = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = false;
  xAxisLabel = "Country";
  showYAxisLabel = false;
  yAxisLabel = "Population";
  legendTitle = "Delivery Order Status";
  barPadding = 8;
  colorScheme = {
    name: "barScheme",
    selectable: true,
    group: ScaleType.Linear,
    domain: [
      "#BCBFC1",
      "#78B4BC",
      "#8A9EE1",
      "#FECD55",
      "#A2CA78",
      "#8e6e6e",
      "#D77D7D",
      "#D96E12",
    ],
  };
  notifications: any = [];
  deliveryOrder: any;
  planingList: any;
  chart: any;
  totalOrder!: number;
  dateFormat: any;
  dateFormat1: any;
  dateFormat2: any;
  plan: any = "";
  fleetDo: any;
  refreshCollapse!: Subscription;
  collapse: any;
  noPlans: boolean = false;
  noDeliveryOrders: boolean = false;
  refreshActivityNotifications!: Subscription;
  dateConfig: any;
  selectDate: any = "";
  compareDate: any;
  todaysDate1: any;
  diffDays = 0;

  timezone: any;

  public create: any;

  public allDoStatus: any[] = [];

  public loadingProgress = {
    boxOrderFor: false,
    chart: false,
    unscheduledOrder: false,
    activity: false
  };

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private fleetDashboardService: FleetDashboardService,
    private util: UtilServiceService,
    private webSocket: WebSocketsService,
    private pushNotificationService: PushNotificationService,
    private perm: PermissionService,
    private modalService: ModalServiceService,
    private deliveryOrderSetupService: DeliveryOrderSetupService,
     private userService : UsersService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.refreshActivityNotifications.unsubscribe();

    super.ngOnDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    // this.refreshCollapse = this.util.refreshCollapse$.subscribe((o) => {
    //   this.collapse = o;
    //   this.applyDimensions();
    // });

    this.refreshActivityNotifications =
      this.webSocket.refreshActivityNotifications$.subscribe((notification) => {
        this.getNotifications();
        var body = JSON.parse(notification.body);
        this.generatePushNotification(body, "message");
      });
  }

  /******************************************** Init ******************************************/
  private async init() {
    
    this.userService.getWelcomeNotice(this.auth.getUserId()).subscribe((res: any) => {
      console.log(res,"----->>>>>>>")
      if(res.message == 'incompleted'){
         //  this.modalService.openModal('welcomeUpdate', null);
      }
     
    })

    this.allDoStatus = await this.deliveryOrderSetupService
      .getAllStatus()
      .toPromise();

    if (!(this.allDoStatus instanceof Array)) {
      this.allDoStatus = [];
    }

    this.create = this.perm.capable("delivery orders", "create");

    this.timezone = this.auth.getUserTimezone();

    this.todaysDate = new Date();
    this.todaysDate1 = new Date();
    this.compareDate = new Date();
    this.days();

    this.selectDate = this.todaysDate.toISOString();

    this.dateConfig = {
      dateInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    this.dateFormat1 = localStorage.getItem("date_format");
    this.dateFormat = "dd/MM";
    this.dateFormat2 = "ddMM";

    this.getPlansList();
    this.getFleetDO();
    this.getChart();
    this.getNotifications();
    this.getPendingDO();
  }

  onDateChange(newDate: any) {
    this.selectDate = moment
      .tz(newDate, "YYYY-MM-DD HH:mm", this.auth.getUserTimezone())
      .utc()
      .format();
    this.compareDate = new Date(newDate);
    // this.compareDate=moment(newDate, 'YYYY-MM-DD').format()
    this.days();

    this.getPlansList();
    this.getFleetDO();
    this.getChart();
    this.getNotifications();
  }

  days() {
    var diffMs = this.todaysDate1 - this.compareDate; // milliseconds between now & Christmas
    this.diffDays = Math.floor(diffMs / 86400000); // days
    console.log("=====>>" + this.diffDays + " days");
    //this.driverDetails.days=diffDays
  }

  // applyDimensions() {
  //   this.width = 0;
  //   const myDiv = document.getElementById("mydiv");
  //   //console.log(this.width, "collaps ", this.collapse)
  //   if (myDiv) {
  //     this.width = myDiv.clientWidth;
  //   }

  //   if (!this.collapse) {
  //     this.width = this.width - 90;
  //     this.height = 300;
  //     this.view = [this.width, this.height];
  //   } else {
  //     this.view = null;
  //   }
  // }

  getPendingDO() {
    this.loadingProgress.unscheduledOrder = true;

    super.push(
      this.fleetDashboardService.getPendingDO().subscribe((res: any) => {
        // //debugger
        this.deliveryOrder = res;
        if (!this.deliveryOrder.length) {
          this.noDeliveryOrders = true;
        }

        this.loadingProgress.unscheduledOrder = false;
      })
    );
  }

  getPlansList() {
    super.push(
      this.fleetDashboardService
        .getPlansList(this.selectDate)
        .subscribe((res: any) => {
          console.log(res);
          this.planingList = res;
        })
    );
  }

  getFleetDO() {
    this.loadingProgress.boxOrderFor = true;

    super.push(
      this.fleetDashboardService
        .getFleetDO(this.plan, this.selectDate)
        .subscribe((res: any) => {
          this.fleetDo = res;
          if (!this.fleetDo.length) {
            this.noPlans = true;
          } else {
            this.noPlans = false;
          }

          this.loadingProgress.boxOrderFor = false;
        })
    );
  }

  getChart() {
    this.loadingProgress.chart = true;

    this.single = [];

    super.push(
      this.fleetDashboardService
        .getFleetChart(this.plan, this.selectDate)
        .subscribe((res: any) => {
          this.chart = res;
          this.totalOrder =
            this.chart.newOrders +
            this.chart.confirmedOrders +
            this.chart.unScheduledOrders +
            this.chart.dispatchedOrders +
            this.chart.inProgressOrders +
            this.chart.completedOrders +
            this.chart.inCompletedOrders +
            this.chart.cancelledOrders +
            this.chart.onHoldOrders;

          this.single = [
            {
              name: "New",
              value: this.chart.newOrders,
            },
            {
              name: "Confirmed",
              value: this.chart.confirmedOrders,
            },
            {
              name: "Dispatched",
              value: this.chart.dispatchedOrders,
            },
            {
              name: "In Progress",
              value: this.chart.inProgressOrders,
            },
            {
              name: "Completed",
              value: this.chart.completedOrders,
            },
            {
              name: "Incomplete",
              value: this.chart.inCompletedOrders,
            },
            {
              name: "Cancelled",
              value: this.chart.cancelledOrders,
            },
            {
              name: "On Hold",
              value: this.chart.onHoldOrders,
            },
          ];

          this.loadingProgress.chart = false;
        })
    );
    // Object.assign(this, this.single);
  }

  getFleetByPlanId() {
    console.log("we are in");
    this.getFleetDO();
    this.getChart();
    this.getNotifications();
  }

  getNotifications() {
    this.loadingProgress.activity = true;

    this.fleetDashboardService
      .getFleetNotifications(this.plan, this.selectDate)
      .subscribe((res: any) => {
        this.notifications = res;
        this.data();

      this.loadingProgress.activity = false;
      });
  }

  data() {
    let today: any = new Date();
    this.notifications.forEach((element: any, index: any) => {
      //console.log(element.description)
      if (element.description == "  ") {
        element.description = "-";
      }
      let Christmas: any = new Date(element.createdDate);
      // //console.log(today,"====",Christmas)
      var diffMs = today - Christmas; // milliseconds between now & Christmas
      var diffDays = Math.floor(diffMs / 86400000); // days
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      //console.log(diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes until Christmas 2009 =)");
      if (diffDays != 0) {
        element.day = diffDays;
        element.hours = 0;
        element.minute = 0;
      } else {
        if (diffHrs != 0) {
          element.day = 0;
          element.hours = diffHrs;
          element.minute = 0;
        } else {
          element.day = 0;
          element.hours = 0;
          element.minute = diffMins;
        }
      }
    });
    //console.log("Activity Notifications ", this.notifications);
  }

  /******************************************** View Do ******************************************/
  public viewDeliveryOrder(data: any) {
    this.router.navigate(["dashboard/deliveryOrders/view/" + data.id], {
      queryParams: { doId: data.id },
    });
  }

  /******************************************** View All Do ******************************************/
  public viewDeliveryOrderPage() {
    this.router.navigate(["dashboard/deliveryOrders"]);
  }

  public openModal($event: any, modalView: any, data: any) {
    $event.stopPropagation();

    let theData = data;

    if (modalView === "viewMapPin") {
      theData = {
        lat: data.locationLat,
        lng: data.locationLong,
      };
    }

    this.modalService.openModal(modalView, theData);
  }

  generatePushNotification(notification: any, type: any) {
    let options = new PushNotificationOptions();
    options.icon = "/assets/images/logo.png";
    options.sound = "/assets/sounds/message.mp3";

    var title: any = this.formatNotificationTitle(notification.type);
    options.body = notification.title;
    notification.notificationType = "notification";
    options.data = notification;

    this.pushNotificationService.create(title, options).subscribe(
      (notif) => {
        //console.log('onshow');
        setTimeout(() => {
          notif.notification.close();
        }, 10000);

        if (notif.event.type === "close") {
          //console.log('close');
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }

  /******************************************** Format Notification Title ******************************************/
  private formatNotificationTitle(str: any) {
    var frags = str.split("_");
    for (var i = 0; i < frags.length; i++) {
      frags[i] =
        frags[i].charAt(0).toUpperCase() + frags[i].slice(1).toLowerCase();
    }
    return frags.join(" ");
  }

  /******************************************** View Notification ******************************************/
  public viewNotification(notification: any) {
    if (notification.parentId != null && notification.parentType != null) {
      if (notification.parentType == "PLANING_HUB") {
        this.router.navigate(["/dashboard/planningHub/view/"], {
          queryParams: { id: notification.parentId },
        });
      }
    }
  }

  /******************************************** New Delivery Order ******************************************/
  public newDo() {
    this.router.navigate(["dashboard/deliveryOrders/create"]);
  }

  public returnItem(what: string, value: any) {
    let item = "";

    if (what === "colorCodeDoStatus") {
      const foundStatus = this.allDoStatus.find(
        (doStatus) => doStatus.name === value
      );

      if (foundStatus) {
        if (foundStatus.colorCode !== "" || foundStatus.colorCode !== null) {
          item = foundStatus.colorCode;
        } else {
          item = "#1b7e7750";
        }
      }
    }

    if (what === "doStatus") {
      const foundStatus = this.allDoStatus.find(
        (doStatus) => doStatus.name === value
      );

      if (foundStatus) {
        if (foundStatus.label !== "" || foundStatus.label !== null) {
          item = foundStatus.label;
        } else {
          item = foundStatus.name;
        }
      }
    }

    return item;
  }

  showPopup(modalView: any, data: any) {
    this.modalService.openModal(modalView, data, { class: "modal-fullscreen" });
  }
}
