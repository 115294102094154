 <form class="custella-custom-dialog-no" [formGroup]="passwordForm" *ngIf="type == 'self'"  (click)="onClickBackground()">
    <div class="modal-header p-dialog-header flex-between">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 font-medium ms-3" id="title_SetUserResetPwd">{{'reset_password' | translate}}</span>
      </div>
      <i class="pi pi-times w-24px" (click)="modalRef.hide()" id="btn_SetUserResetPwdClose"></i>
    </div>
      <div class="modal-body">
      <div class="container">   
          <div class="custella-content-row-input col-12 mb-3">
            <span class="custella-content-row-input-title" id="label_SetUserOldPwd" translate>{{'old_password'}}</span>
            <span class="p-input-icon-right w-100">
              <input pInputText autocomplete="off" formControlName="oldPassword" id="input_SetUserOldPwdField" [(ngModel)]="password.oldPassword" name="oldPassword" [type]="fieldTextType ? 'text' : 'password'"  >
              <i class="pi" [ngClass]="{ 'pi-eye-slash': !fieldTextType, 'pi-eye': fieldTextType}"(click)="showPassword()" id="btn_LoginPwdShow"></i>
            </span>
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['oldPassword'].touched && passwordForm.controls['oldPassword'].invalid">
                    <span *ngIf="passwordForm.controls['oldPassword'].errors?.required" class="text-10 p-error" id="label_SetUserOldPwdReqField" translate>{{'required_field'}}</span>
                    <!-- <span *ngIf="passwordForm.controls['oldPassword'].errors?.pattern" class="text-10 p-error" id="label_SetUserOldPwdMustBe" translate>{{'password_must_be'}}</span> -->
                  </div>  
          </div>                 
          <div class="custella-content-row-input col-12 mb-3">
              <span class="custella-content-row-input-title" id="label_SetUserNewPwd" translate>{{'new_password'}}</span>
              <span class="p-input-icon-right w-100">
                <input pInputText autocomplete="off" formControlName="password" [(ngModel)]="password.password" name="password" [type]="fieldTextType2 ? 'text' : 'password'"  id="input_SetUserNewPwdField" (input)="getPassowrdLength($event)">
                <i class="pi" [ngClass]="{ 'pi-eye-slash': !fieldTextType2, 'pi-eye': fieldTextType2}"(click)="showNewPassword()" id="btn_LoginPwdShow"></i>
              </span>
                  <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['password'].touched && passwordForm.controls['password'].invalid">
                    <span *ngIf="passwordForm.controls['password'].errors?.required" class="text-10 p-error" id="label_SetUserNewPwdReqField" translate>{{'required_field'}}</span>
                    <!-- <span *ngIf="passwordForm.controls['password'].errors?.pattern" class="text-10 p-error" id="label_SetUserNewPwdMustBe" translate>{{'password_must_be'}}</span> -->
                  </div>  
                  <div class="pwd-valid" *ngIf="showtooltip || isPasswordHasUserName">
                    <div class="pwd-valid-body">
                        <div class="pb-1">
                            <span class="text-10">{{'pwd_contain_least'|translate}}&colon;</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('^(?=.*[A-Z])')"></i>
                              <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('^(?=.*[A-Z])')"></i>
                            </span> 
                            <span class=" text-10 ms-2"[ngClass]="{'text-success':passwordFormField?.value?.match('^(?=.*[A-Z])')}">{{'one_uppercase'|translate}}</span>
                                   
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('^(?=.*[a-z])')"></i>
                              <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('^(?=.*[a-z])')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('^(?=.*[a-z])')}">{{'one_lowercase'|translate}}</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('(.*[0-9].*)')"></i>
                              <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('(.*[0-9].*)')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('(.*[0-9].*)')}">{{'one_numeric'|translate}}</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('.{8,}')"></i>
                              <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('.{8,}')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('.{8,}')}">{{'eight_char_min'|translate}}</span>
                        </div>
                        <div class="flex-align-center pb-1">
                            <span class="w-12px lh-1">
                              <i class="fa-regular fa-check text-success" *ngIf="passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"></i>
                              <i class="fa-regular fa-xmark p-error" *ngIf="!passwordFormField?.value?.match('(?=.*[!@#$%^&*])')"></i>
                            </span>
                            <span class="text-10 ms-2" [ngClass]="{'text-success':passwordFormField?.value?.match('(?=.*[!@#$%^&*])')}">{{'one_symbol'|translate}}</span>
                        </div>
                        <div class="flex-align-center" >
                          <span class="w-12px lh-1">
                            <i class="fa-regular fa-check text-success" *ngIf="!isPasswordHasUserName"></i>
                            <i class="fa-regular fa-xmark p-error" *ngIf="isPasswordHasUserName"></i>
                          </span>
                          <span class="text-10 ms-2" [ngClass]="{'text-success':!isPasswordHasUserName}">{{'must_not_contain_username'|translate}}</span>
                      </div>
                    </div>
                 </div> 
          </div>
          <div class="custella-content-row-input col-12">
              <span class="custella-content-row-input-title" id="label_SetUserConfirmNewPwd" translate>{{'confirm_new_password'}}</span>
              <span class="p-input-icon-right w-100">
                <input pInputText autocomplete="off" formControlName="cPassword" (input)="getCPassowrdLength($event)" [(ngModel)]="password.cPassword" id="input_SetUserConfirmNewPwdField" name="cPassword" type="password" [type]="fieldTextType3 ? 'text' : 'password'"  >
                <i class="pi" [ngClass]="{ 'pi-eye-slash': !fieldTextType3, 'pi-eye': fieldTextType3}"(click)="showConfirmPassword()" id="btn_LoginPwdShow"></i>
              </span>
                <div class="alert col px-0 py-0 text-left" *ngIf="passwordForm.controls['cPassword'].touched && passwordForm.controls['cPassword'].invalid">
                  <span *ngIf="passwordForm.controls['cPassword'].errors?.required" class="text-10 p-error" id="label_SetUserConfirmNewPwdReqField" translate>{{'required_field'}}</span>
                  <!-- <span *ngIf="passwordForm.controls['cPassword'].errors?.pattern" class="text-10 p-error" id="label_SetUserConfirmNewPwdMustBe" translate>{{'password_must_be'}}</span> -->
                </div>
                <div *ngIf="!isPasswordMatching && (password.cPassword && password.cPassword.length > 0)">
                  <span class="p-error text-10" id="label_PwdActivateRetypePwdReqField">{{"pwd_not_match"|translate}}</span>
                  <!-- <span class="p-error text-10" id="label_PwdActivateRetypePwdMustBe" *ngIf="accountForm.controls['cPassword'].errors?.pattern" translate>{{'password_must_be'}}</span> -->
              </div>    
          </div>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button class="custella-btn-white mx-1 text-12" id="btn_SetUserPwdCancel" (click)="modalRef.hide()">{{'cancel' | translate}}</button>
        <p-button  (click)="changePassword()" [disabled]="submitTouched" id="btn_SetUserPwdReset" label="{{'reset' | translate}}" styleClass="mx-1"></p-button>
    </div>
  </form>
  
  <form class="custella-custom-dialog-no"  *ngIf="type == 'others' || type == 'resendCustomerUserEmail'">
    <div class="modal-header p-dialog-header flex-between">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3" id="title_SetUserResetPwdEmail">{{'reset_password' | translate}}</span>
      </div>
      <i class="pi pi-times w-24px" (click)="modalRef.hide()" id="btn_SetUserResetPwdEmailClose"></i>
    </div>
    <div class="modal-body">
      <div class="container text-center my-4">       
        <p translate id="label_SetUserResetPwdEmailDesc">An email will be sent to {{data1.email}}. Do you wish to proceed password reset?</p>
      </div>
    </div>
    <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" id="btn_SetUserResetPwdEmailNo" (click)="modalRef.hide()">No</button>
        <p-button  [disabled]="submitTouched"  (click)="onSubmit('reset')" [disabled]="submitTouched" label="Yes" id="btn_SetUserResetPwdEmailYes" styleClass=""></p-button>
    </div>

  </form>
  
  
  <form class="custella-custom-dialog-no" *ngIf="type == 'resendEmail'">
    <div class="modal-header p-dialog-header">
      <div class="d-flex align-items-center">
        <img src="assets\svg\dialog\Confirmation-w.svg">  
        <span class="text-16 ffont-medium ms-3" id="title_SetUserResendEmail">{{'resend_email' | translate}}</span>
      </div>
      <i class="pi pi-times w-24px" id="btn_SetUserResendEmailClose" (click)="modalRef.hide()"></i>
    </div>
      <div class="modal-body">
        <div class="container text-center my-4">       
          <p id="label_SetUserResendEmailDesc">An email will be sent to {{data1?.email}}. Do you wish to proceed?</p>
        </div>
      </div>
      
      <div class="modal-footer p-0">
        <button [disabled]="submitTouched"  class="custella-btn-white text-12" id="btn_SetUserResendEmailNo" (click)="modalRef.hide()">No</button>
        <p-button  [disabled]="submitTouched"  (click)="onSubmit('email')" id="btn_SetUserResendEmailYes" [disabled]="submitTouched" label="Yes" styleClass=""></p-button>
    </div>
    </form>


   
      