<div class="custella-modal d-flex flex-column">
    <div class="custella-modal-header">
        <div class="custella-modal-header-text"> {{title}}</div>

        <i class="pi pi-times pt-1"  [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="modalRef.hide()"></i>
    </div>

    <div class="custella-modal-body mb-3 pt-0">
        <div class="d-flex align-items-center justify-content-end my-3">
            <!-- Filter and New -->
            <div class="custella-dashboard-top-right d-flex">
                <button class="border rounded-1 px-04 ms-2 btn-32">
                    <img alt="logo" src="assets\svg\refresh.svg" id="btn_MagDashRefresh" (click)="refresh()" />
                </button>
                <button class="border rounded-1 px-06 ms-2 btn-32" (click)="toggleFilterView()" id="btn_MagDashFilter" style="color: #435861;">
                    <i class="pi pi-filter pt-04"></i>
                </button>
                <span class="p-input-icon-right w-100 ms-2">
                    <!-- <i class="pi pi-search me-3"></i> -->
                    <input type="text" [(ngModel)]="searchValue" pInputText autocomplete="off" [placeholder]="'search' | translate" id="input_MagDashSearchField" (keydown.enter)="onSearch()" (keyup)="onSearchCheck()" />
                    <i *ngIf="searchValueData" class="p-autocomplete-clear-icon pi pi-times" id="btn_MagDashSearchClear" (click)="onClear()"></i>
                </span>
                <button type="button" class="border rounded-1 px-04 ms-2 btn-32" (click)="onSearch()" id="btn_MagDashSearch">
                    <img alt="logo" src="assets\svg\search-dark.svg" class="Search"/>
                </button>
            </div>
        </div>

        <div class="position-relative fw-normal d-flex" style="height: 60vh;">
            <div class="table-space ag-grid-table-full" *ngIf="title!='Workshop Spare Monitoring' && title!='Preventive Maintenance'">
                <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefs" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1" [frameworkComponents]="frameworkComponents"
                    (cellClicked)="view($event)" (gridReady)="onGridReady($event)" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_MagDashTableRec">
                        {{ totalRecord }} {{'records' | translate}}
                    </p>
                </div>
            </div>

            <div class="table-space ag-grid-table-full" *ngIf="title=='Workshop Spare Monitoring'">
                <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefsMonitoring" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1" [frameworkComponents]="frameworkComponents"
                    (cellClicked)="viewAsset($event)" (gridReady)="onGridReadyMonitoring($event)" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_MagDashWorkshopTableRec">
                        {{ totalRecord }} {{'records' | translate}}
                    </p>
                </div>
            </div>

            <div class="table-space ag-grid-table-full" *ngIf="title=='Preventive Maintenance'">
                <ag-grid-angular #agGrid style="height: 100%;" class="ag-theme-balham" [columnDefs]="columnDefsPreventiveMaintenance" [gridOptions]="gridOptions" [defaultColDef]="defaultColDef" [cacheOverflowSize]="2" [maxConcurrentDatasourceRequests]="-1" [frameworkComponents]="frameworkComponents"
                    (cellClicked)="viewMaintenance($event)" (gridReady)="onGridReadyPreventiveMaintenance($event)" [overlayNoRowsTemplate]="overlayNoRowsTemplate" [getContextMenuItems]="getContextMenuItems"></ag-grid-angular>
                <div class="col-12">
                    <p class="ag-record-row-summary-panel padds text-end" id="label_MagDashPreventiveTableRec">
                        {{ totalRecord }} {{'records' | translate}}
                    </p>
                </div>
            </div>

            <div class="custella-form-container-content-pane layout-filter" [ngClass]="{ 'layout-filter-active': filterView }" *ngIf="filterView">
                <div class="custella-form-container-content-pane-title">
                    <span class="text-14 font-medium" id="title_MagDashFilterBy">Filter by</span>
                    <i class="pi pi-arrow-right" id="btn_MagDashFilterClose" (click)="toggleFilterView()"></i>
                </div>
                <div class="custella-content">
                    <ng-container *ngIf="title != 'Workshop Spare Monitoring' && title!='Preventive Maintenance' && title!='Total Assignments'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatus">{{'status' | translate}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [showClear]="true"
                                    [options]="statuses"
                                    filterBy="fieldLabel"
                                    id="picklist_MagDashStatusField"
                                    optionLabel="fieldLabel"
                                    optionValue="name"
                                    placeholder="{{ 'filter_by_status' | translate }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title=='Total Assignments'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatusAssign">{{'status' | translate}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [showClear]="true"
                                    [options]="allStatus"
                                    filterBy="label"
                                    optionLabel="label"
                                    optionValue="value"
                                    id="picklist_MagDashStatusAssignField"
                                    placeholder="{{ 'filter_by_status' | translate }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title=='Workshop Spare Monitoring'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatusWorkshop">{{'status' | translate}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [options]="assetstatuses"
                                    [showClear]="true"
                                    filterBy="name"
                                    optionLabel="name"
                                    optionValue="id"
                                    id="picklist_MagDashStatusWorkshopField"
                                    placeholder="{{ 'filter_by_status' | translate }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title=='Preventive Maintenance'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashStatusPreventive">{{'status' | translate}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    id="picklist_MagDashStatusPreventiveField"
                                    [showClear]="true"
                                    [options]="allStatusPreventiveMaintenance"
                                    filterBy="label"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="{{ 'filter_by_status' | translate }}"
                                    [(ngModel)]="filter.status"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.status ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="title == 'Data Line Fault Calls'">
                        <div class="custella-content-input col-12">
                            <span class="custella-content-input-title" id="label_MagDashDataFault">{{'fault_type' | translate}}</span>
                            <span class="p-input-icon-right w-100">
                                <p-dropdown
                                    class="pe-2"
                                    name="status"
                                    [showClear]="true"
                                    [options]="faultTypes"
                                    filterBy="name"
                                    id="picklist_MagDashDataFaultField"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="{{ 'filter_by_type' | translate }}"
                                    [(ngModel)]="filter.fault"
                                    (onChange)="filterResults()"
                                    [filter]="true"
                                    [showClear]="filter.fault ? true : false"
                                >
                                </p-dropdown>
                            </span>
                        </div>
                    </ng-container>

                    <div>
                        <span href="#" class="text-decoration-underline font-black" id="subtitle_MagDashFilterClear" (click)="clearAll()"><small class="d-flex align-items-center text-nowrap text-10 cursor-pointer">{{ "clear_all" | translate }}</small> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>