import { ɵNullViewportScroller } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dropdown } from 'primeng/dropdown';
import { Subscription } from 'rxjs';
import { AssetService } from 'src/app/_services/asset.service';
import { BreadcrumbService } from 'src/app/_services/breadcrumb.service';
import { CustomersService } from 'src/app/_services/customers.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { locatns } from '../../IqcFqc';

@Component({
  selector: 'app-iqc-create',
  templateUrl: './iqc-create.component.html'
})
export class IqcCreateComponent implements OnInit, AfterViewInit, OnDestroy {

  _id: any;
  iqc: any = {};
  retrievalDetails: any;
  isEdit: Boolean = false;
  locatns: any;
  displayModal = false;
  buttonLabel: string = 'Save';

  addIQCForm!: UntypedFormGroup;
  iqcData: any = {
    subsidiary: { id: null, name: null, customerExternalId: null },
    customer: { id: null, name: null, customerExternalId: null },
    location: null,
    assets: { id: null, name: null, assetsCode: null },
    status: 'NEW',
    newMeterReading: null,
    equipmentType: { _id: null },
    QCSetup: { _id: null },
    userResponse: [],
    remark: null,
  }
  assetDetails: any;
  displayAssetTable: any;
  assetName: any;
  public selectedAsset: any;
  assets: any;
  customers: any;
  subsidiary: any;
  iqcType: any = [{ name: 'Off Hire', label: 'Off Hire' }, { name: 'Exchange', label: 'Exchange' }];
  questionForm: any;
  questionList: any = [];
  statusMessage = '';
  statuses = [{ name: 'NEW', label: 'New' }, { name: 'DRAFT', label: 'Draft' },
  { name: 'COMPLETED', label: 'Completed' }];

  displayTable = false;
  displaySubsidiaryTable = false;
  selectedCustomer: any = { id: null, name: null, customerExternalId: null };
  selectedSubsidiary: any = { id: null, name: null, customerExternalId: null };

  @ViewChild("myDropDown", { static: false }) myDropDown: Dropdown | any

  subscriber: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  subscriber2: Subscription = new Subscription();
  subscriber3: Subscription = new Subscription();
  subscriber4: Subscription = new Subscription();
  subscriber5: Subscription = new Subscription();
  subscriber6: Subscription = new Subscription();

  constructor(private route: ActivatedRoute,
    private router: Router,
    public util: UtilServiceService,
    private iqcFqcService: IqcFqcService,
    private spinner: NgxSpinnerService,
    private breadCrumb: BreadcrumbService,
    private assetsService: AssetService,
    private errorUtil: ErrorUtil,
    private customerService: CustomersService,
    private cd: ChangeDetectorRef) {
    this.locatns = locatns;

    this.addIQCForm = new UntypedFormGroup({
      'assetsName': new UntypedFormControl(null, [Validators.required]),
      'location': new UntypedFormControl(null, [Validators.required]),
      'customer': new UntypedFormControl(null),
      'subsidiary': new UntypedFormControl(null),
      'iqcType': new UntypedFormControl(null),
      'breakDownService': new UntypedFormControl(null),
      'equipmentTypeName': new UntypedFormControl({ value: null, disabled: true }),
      'equipmentNumber': new UntypedFormControl({ value: null, disabled: true }),
      'manufacturer': new UntypedFormControl({ value: null, disabled: true }),
      'model': new UntypedFormControl({ value: null, disabled: true }),
      'serialNo': new UntypedFormControl({ value: null, disabled: true }),
      'engineNumber': new UntypedFormControl({ value: null, disabled: true }),
      'engineNumber2': new UntypedFormControl({ value: null, disabled: true }),
      'engineMake': new UntypedFormControl({ value: null, disabled: true }),
      'engineModel': new UntypedFormControl({ value: null, disabled: true }),
      'equipmentMeterReading': new UntypedFormControl({ value: null, disabled: true }),
      'newMeterReading': new UntypedFormControl(null),
      'remark': new UntypedFormControl(null),
      'status': new UntypedFormControl(null, [Validators.required]),
    });

    this.subscriber = this.route.queryParams.subscribe(params => {
      this.isEdit = params['isEdit'] == 'true' ? true : false;
    });
    // get all assets
    this.subscriber1 = this.assetsService.getAssetsPicklist().subscribe((res: any) => {
      this.assets = res.body;
    });
    // get all customers
    this.subscriber2 = this.customerService.getAllV2Picklist().subscribe((res: any) => {
      this.customers = res
    });
  }

  ngOnInit(): void {
    this._id = this.route.snapshot.paramMap.get('id');
    if (this.myDropDown) this.myDropDown.clear(null);
  }

  ngAfterViewInit(): void {
    this.init();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscriber) this.subscriber.unsubscribe();
    if (this.subscriber1) this.subscriber.unsubscribe();
    if (this.subscriber2) this.subscriber.unsubscribe();
    if (this.subscriber3) this.subscriber.unsubscribe();
    if (this.subscriber4) this.subscriber.unsubscribe();
    if (this.subscriber5) this.subscriber.unsubscribe();
    if (this.subscriber6) this.subscriber.unsubscribe();
  }

  /******************************************** start ******************************************/
  private init() {
    if (this.isEdit) {
      this.subscriber3 = this.iqcFqcService.getIqcById(this._id).subscribe((resp: any) => {
        // this.iqc = resp.data;
        console.log(this.iqc);
        this.iqcData = resp.data;
        if (this.iqcData.customer == null) {
          this.iqcData.customer = { id: null, name: null, customerExternalId: null }
        } else this.selectedCustomer = this.iqcData.customer;
        if (this.iqcData.subsidiary == null) {
          this.iqcData.subsidiary = { id: null, name: null, customerExternalId: null }
        } else this.selectedSubsidiary = this.iqcData.subsidiary;
        
        this.addIQCForm.controls['location'].setValue(this.iqcData.location);
        this.addIQCForm.controls.location.disable();
        this.gotSelectedAsset(this.iqcData.assets);
        this.getAssets();
        this.getQandA();
        this.breadCrumb.setItems([
          { label: "IQC", routerLink: ["/dashboard/iqc-fqc"] },
          { label: resp.status ? 'Edit IQC - ' + resp.data.runningNumber : 'Edit IQC', routerLink: ["/dashboard/iqc-fqc/iqc-view/" + this._id] },
        ]);
      });
    } else {
      this.breadCrumb.setItems([
        { label: "IQC", routerLink: ["/dashboard/iqc-fqc"] },
        { label: 'New IQC' },
      ]);
    }
  }
  /******************************************** get assets ******************************************/
  getAssets() {
    // var d = this.assets.filter((asset:any) => asset.id === this.iqcData.assets.id);
    // this.iqcData.assets.name = d[0].name;
    // this.iqcData.assets.assetsCode = d[0].assetsCode;
    this.assetsService.getEditObj(this.iqcData.assets.id).subscribe((res: any) => {
      this.assetDetails = res.body
      this.iqcData.equipmentTypeName = this.assetDetails.equipmentType
      this.iqcData.equipmentNumber = this.assetDetails.equipmentNumber
      this.iqcData.manufacturer = this.assetDetails.manufacturer
      this.iqcData.model = this.assetDetails.model
      this.iqcData.serialNo = this.assetDetails.serialNo
      this.iqcData.engineNumber = this.assetDetails.engineNumber
      this.iqcData.engineModel = this.assetDetails.engineModel
      this.iqcData.equipmentMeterReading = this.assetDetails.equipmentMeterReading
      this.iqcData.engineMake = this.assetDetails.engineMake
      this.iqcData.engineNumber2 = this.assetDetails.engineNumber2
    })
  }

  /******************************************** get Q & A ******************************************/

  getQandA() {

    this.subscriber4 = this.iqcFqcService.getQuestionForm(this.iqcData.equipmentTypeName, this.iqcData.location, 'IQC').subscribe((res: any) => {
      this.questionForm = res.data
      console.log(res.data.QCSetup, "===")
      if (res.data.QCSetup != undefined) {
        this.iqcData.QCSetup._id = res.data.QCSetup._id
        this.iqcData.equipmentType._id = res.data.QCSetup.equipmentType._id
        this.iqc.equipmentType = res.data.QCSetup.equipmentType;

        this.questionForm.questions.forEach((element: any) => {
          var answerId = null
          element.selectedAnswer = ɵNullViewportScroller

          var userAnswers = _.filter(this.iqcData.userResponse, (elementUserAnswers) => {
            return elementUserAnswers.question._id == element._id;
          })

          element.answers.forEach((elementAnswers: any) => {
            if (!_.isEmpty(userAnswers)) {
              console.log("we are in")
              answerId = { _id: userAnswers[0].answer._id }
              element.selectedAnswer = userAnswers[0].answer._id
            } else {
              if (elementAnswers.default) {
                answerId = { _id: elementAnswers._id }
                element.selectedAnswer = elementAnswers._id
              }
            }
          })
          if (_.isEmpty(userAnswers)) {
            this.iqcData.userResponse.push({
              question: { _id: element._id },
              answer: answerId
            })
          }

        });

        const groupBy = (array: any, key: any) => {
          return array.reduce((result: any, currentValue: any) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
            return result;
          }, {}); // empty object is the initial value for result object
        };
        this.questionList = [];
        const personGroupedByColor = groupBy(this.questionForm.questions, 'sectionName');
        for (var i in personGroupedByColor) {
          this.questionList.push({
            name: i,
            detail: personGroupedByColor[i],
          });
        }
        console.log(this.questionList);

      }
      console.log(this.iqcData.userResponse);
    });
  }

  /******************************************** get asset by query ******************************************/
  public getAssetByQuery(event: any) {
    const query = event.query;

    if (query != null && query.length > 2) {
      this.subscriber5 = this.assetsService.getAssetsByName(query).subscribe(
        (data) => {
          this.assets = data.data;
        },
        (err) => this.errorUtil.setErrorMessage(err)
      );
    }
  }

  /******************************************** reset assets ******************************************/
  public onvalidAsset() {
    if (this.assetDetails == null || this.assetDetails.id == null) {
      this.assetName = null;
      this.selectedAsset = null;
    }
  }

  /************************************************** start(asset search component) ****************************************/
  // get selected asset
  public gotSelectedAsset(val: any) {
    // set selected asset
    this.selectedAsset = val;
    // get the asset
    this.selectValueAsset(val);
    // get the system based on asset
    // this.getSelectedSystem(val);
    this.iqcData.assets = val;
    this.getAssets();
    this.addIQCForm.controls['assetsName'].setValue(val);
    this.displayAssetTable = false;
    this.getQandA();

  }

  /******************************************** on value selected ******************************************/
  public selectValueAsset(value: any) {
    this.iqcData.assets.id = value.id;
    this.iqcData.assets.name = value.name;
    this.assetName = value;
    // this.assets = [];
  }

  /******************************************** clear asset value ******************************************/
  public clearAssetValue() {
    this.iqcData.assets.id = null;
    this.assetName = null;
    this.assets = [];
    this.addIQCForm.controls['assetsName'].setValue(null);
  }

  /******************************************** close asset table ******************************************/
  public closeAssetView() {
    this.displayAssetTable = false;
  }

  /******************************************** toggle asset table ******************************************/
  public showAssetTable() {
    this.displayAssetTable = !this.displayAssetTable;
  }

  /**************************************** Autocomplete Customer ****************************************/
  public getCustomer(event: any) {
    const query = event.query;

    if (query && query.length > 2) {
      this.customerService.getAllCustomerByName(query).subscribe(
        (data: any) => {
          this.customers = data;
        },
        (error) => this.errorUtil.setErrorMessage(error)
      )
    }
  }

  /**************************************** Autocomplete Customer ****************************************/
  public getSubsidiary(event: any) {
    const query = event.query;

    if (query && query.length > 2) {
      this.customerService.getAllCustomerByName(query).subscribe(
        (data: any) => {
          this.customers = data;
        },
        (error) => this.errorUtil.setErrorMessage(error)
      )
    }
  }

  /******************************************** on select customer value ******************************************/
  public selectCustomerValue(value: any) {
    this.selectedCustomer = { id: value?.id, name: value?.name, customerExternalId: value?.customerExternalId };
    this.iqcData.customer = { id: value?.id, name: value?.name, customerExternalId: value?.customerExternalId };
    this.customers = [];
  }

  /******************************************** on select Subsidiary value ******************************************/
  public selectSubsidiaryValue(value: any) {
    this.iqcData.subsidiary = { id: value?.id, name: value?.name, customerExternalId: value?.customerExternalId };
    this.selectedSubsidiary = { id: value?.id, name: value?.name, customerExternalId: value?.customerExternalId };
    this.customers = [];
  }

  /******************************************** show customer table ******************************************/
  public showTable() {
    this.displayTable = !this.displayTable;
  }
  /******************************************** show Subsidiary table ******************************************/
  public showSubsidiaryTable() {
    this.displaySubsidiaryTable = !this.displaySubsidiaryTable;
  }

  /******************************************** on selected customer ******************************************/
  public gotSelectedCustomer(val: any) {
    this.selectCustomerValue(val);
    this.closeCustomerView();
  }

  /******************************************** on selected Subsidiary ******************************************/
  public gotSelectedSubsidiary(val: any) {
    this.selectSubsidiaryValue(val);
    this.closeSubsidiaryView();
  }

  /******************************************** close customer table ******************************************/
  public closeCustomerView() {
    this.displayTable = false;
  }
  /******************************************** close Subsidiary table ******************************************/
  public closeSubsidiaryView() {
    this.displaySubsidiaryTable = false;
  }

  /******************************************** on remove customer ******************************************/
  public clearValue(isSubsidiary: boolean) {
    if (isSubsidiary) {
      this.iqcData.subsidiary.id = null;
      this.selectedSubsidiary = null;
    } else {
      this.iqcData.customer.id = null;
      this.selectedCustomer = null;
    }
    this.customers = [];
  }


  /**************************************** Submit Form ************************************/
  onSubmitForm() {

    if (!this.addIQCForm.valid) {
      for (var i in this.addIQCForm.controls) {
        // console.log(this.addIQCForm.controls[i])
        this.addIQCForm.controls[i].markAsTouched();
      }
      return false;
    }

    this.setupValues();
    // return false;
    this.spinner.show();
    if (this.isEdit) {
      this.subscriber6 = this.iqcFqcService.updateIqc(this.iqc).subscribe((res: any) => {
        console.log(res);
        this.spinner.hide();
        if (res.status === 200 || res.status === 201) {
          this.errorUtil.setErrorMessage(200, 'IQC updated successfully', null, 'success', 3000);
          this.router.navigate(['dashboard/iqc-fqc/iqc-view/' + res.body.data._id], { queryParams: { activeIndex: 0 } });
        } else {
          this.errorUtil.setErrorMessage(res.status, null, res.error.error, 'error', 3000);
        }
      })
    } else {
      this.subscriber6 = this.iqcFqcService.createIqc(this.iqc).subscribe((res: any) => {
        console.log("Response ", res);
        this.spinner.hide();
        if (res.status === 200 || res.status === 201) {
          this.errorUtil.setErrorMessage(200, 'IQC created successfully', null, 'success', 3000);
          this.router.navigate(['dashboard/iqc-fqc/iqc-view/' + res.body.data._id], { queryParams: { activeIndex: 0 } });
        } else {
          this.errorUtil.setErrorMessage(res.status, null, res.error.error, 'error', 3000);
        }
      })
    }
  }

  setupValues() {
    this.iqc = _.clone(this.iqcData);

    this.iqc.assets = this.addIQCForm.controls['assetsName'].value;
    this.iqc.location = this.addIQCForm.controls['location'].value;
    this.iqc.customer = this.selectedCustomer;//this.addIQCForm.controls['customer'].value;
    if (!isNaN(this.iqc.customer)) {
      this.iqc.customer = this.customers.filter((c: any) => c.id == this.iqc.customer)[0];
    }
    this.iqc.subsidiary = this.selectedSubsidiary;//this.addIQCForm.controls['subsidiary'].value;
    if (!isNaN(this.iqc.subsidiary)) {
      this.iqc.subsidiary = this.customers.filter((c: any) => c.id == this.iqc.subsidiary)[0];
    }
    this.iqc.iqcType = this.addIQCForm.controls['iqcType'].value;
    this.iqc.breakDownService = this.addIQCForm.controls['breakDownService'].value;

    this.iqc.equipmentTypeName = this.addIQCForm.controls['equipmentTypeName'].value;
    // this.iqc.equipmentType = {_id:this.iqcData.equipmentType._id};
    this.iqc.equipmentNumber = this.addIQCForm.controls['equipmentNumber'].value;
    this.iqc.manufacturer = this.addIQCForm.controls['manufacturer'].value;
    this.iqc.model = this.addIQCForm.controls['model'].value;
    this.iqc.serialNo = this.addIQCForm.controls['serialNo'].value;
    this.iqc.engineNumber = this.addIQCForm.controls['engineNumber'].value;
    this.iqc.engineNumber2 = this.addIQCForm.controls['engineNumber2'].value;
    this.iqc.engineModel = this.addIQCForm.controls['engineMake'].value;
    this.iqc.engineModel = this.addIQCForm.controls['engineModel'].value;
    this.iqc.equipmentMeterReading = this.addIQCForm.controls['equipmentMeterReading'].value;
    this.iqc.newMeterReading = this.addIQCForm.controls['newMeterReading'].value;

    this.iqc.remark = this.addIQCForm.controls['remark'].value;
    this.iqc.status = this.addIQCForm.controls['status'].value;
    this.iqc.userResponse = this.iqcData.userResponse;
    console.log(this.iqc);
  }

  getAnswer(userAnswer: any, question: any) {
    // console.log(userAnswer)
    this.iqcData.userResponse.forEach((element: any) => {
      if (element.question._id == userAnswer.question._id) {
        // console.log(element.question._id," == ",userAnswer.question._id)
        element.answer = { _id: userAnswer._id }
      }
    });
    question.selectedAnswer = userAnswer._id;
  }

  showModalDialog() {
    this.displayModal = true;
  }

  goBack() {
    this.util.goBack()
  }

  changeStatus() {
    console.log(this.iqcData.status)
    if (this.iqcData.status == "NEW") {
      this.statusMessage = ''
    } else if (this.iqcData.status == "DRAFT") {
      this.statusMessage = 'Please note that changes will be saved to be continued later'
    } else if (this.iqcData.status == "IN_PROGRESS") {
      this.statusMessage = ' Please note that once submitted, all relevant tasks will be created and actions will not reversible'
    } else if (this.iqcData.status == "COMPLETED") {
      this.statusMessage = ' Please note that once submitted, all relevant tasks will be created and actions will not reversible'
    }
    this.cd.detectChanges();
  }
}