<div class="modal-header flex-between border-0 pb-1">
    <div class="">
        <span class="text-16 font-medium">{{'update_calendly_theme'|translate}}</span>
        <span class="text-12 pt-1 color-var-500 d-block" translate>{{'update_logo_bg_color_slots'}}</span>
    </div>
    <i class="pi pi-times w-24px" (click)="modalRef.hide()"></i>
</div>
<div class="modal-body tix pt-2" *ngIf="themeConfg">
    <div class="custella-form-container rounded-2 ms-0 me-2" style="box-shadow: none; border: 1px solid #dee2e6;">
        <!-- Container Content -->
        <div class="custella-form-container-title text-14 font-medium ps-4 rounded-top" id="" translate>
            {{"calendly_theme"}}
        </div>
        <div class="d-flex align-items-start">
            <div class="custella-form-container-content my-2 col-6">
                <!-- Title -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="text-12 font-medium font-grey6" id="">{{'logo' | translate}}</span>
                        <div class="custella-vp-picture d-flex align-items-end mt-1">
                            <!-- PREETI CONFIGURATION -->
                            <div class="cal-logo-two">
                                <img *ngIf="!themeConfg.logoAttachment" src="../../../../../assets/svg/table/calendly-logo.svg" alt="">
                                <img *ngIf="themeConfg.logoAttachment" [src]="themeConfg.logoAttachment.attachmentUrl">
                            </div>
                            <!----------------------------------------------------------------------------------------------->

                            
                            <!-- <img src="../../../../../assets/svg/table/calendly-logo.svg" alt=""> -->
                            <!-- <p-avatar [image]="logoAttachment" styleClass="mr-2 mt-1 preview-calendly-mini"size="xlarge"></p-avatar> -->
                            <div class="flex-between">
                                <div class="dropdown ps-2">
                                    <div class="p-component p-dropdown-clearable" data-bs-toggle="dropdown">
                                        <button pButton type="button" icon="pi pi-pencil" [label]="'edit'|translate"
                                            class="p-button-secondary mx-1"></button>

                                           

                                    </div>
                                    <ul class="dropdown-menu dropdown-collaps p-0">
                                        <li class="dropdown-item px-3 py-3 cursor-pointer"  (click)="clearUpload()">{{'remove_photo' | translate}}</li>
                                        <li class="dropdown-item px-3 py-3 cursor-pointer" (click)="openModal('uploadCalendlyLogo',themeConfg)">{{'upload_photo' | translate}}</li>
                                    </ul>
                                    <!-- (click)="openModal('uploadCalendlyLogo',themeConfg)" -->
                                </div>
                            </div>
                            
                          
                        </div>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <form  [formGroup]="addThemeFrom">
                        <span class="text-12 font-medium font-grey6 mandatory">{{'title' | translate}}</span>
                        <div class="custella-color-picker flex-align-center mt-1">
                           
                            <input type="text" formControlName="title" pInputText autocomplete="off" [placeholder]="'your_company_name'|translate" class="me-1"
                                style="margin-right: 5px !important ;"  [(ngModel)]="themeConfg.title">
                            <div><p-colorPicker [ngModelOptions]="{standalone: true}" [(ngModel)]="color" (onChange)="changeFontcolor(color)"></p-colorPicker></div>
                            
                        </div>
                        <div *ngIf="addThemeFrom.controls['title'].touched && addThemeFrom.controls['title'].invalid">
                            <span class="text-10 p-error" *ngIf="addThemeFrom.controls['title'].errors?.required" id="label_SetInvLocNameReqField" translate>{{'required_field'}}</span>
                        </div>
                    </form>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="text-12 font-medium font-grey6" id="">{{'sub_title' | translate}}</span>
                        <div class="custella-color-picker flex-align-center mt-1">
                            <input type="text" pInputText autocomplete="off" [placeholder]="'your_company_name'|translate" class="me-1"  [(ngModel)]="themeConfg.subTitle">
                        </div>
                    </div>
                </div>
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-6">
                        <span class="text-12 font-medium font-grey6" id="">{{'background' | translate}}</span>
                        <div class="custella-color-picker flex-align-center mt-1">
                            <div class="color-scheme showOption color1 " (click)="changeBGcolor('#e7e7e7','#1a1a1a')"></div>
                            <div class="color-scheme showOption color2 ms-2" (click)="changeBGcolor('#1a1a1a','#FFFFFF')"></div>
                            <div class="color-scheme showOption color3 ms-2" (click)="changeBGcolor('#FFFFFF','#1a1a1a')"></div>
                            <div class="color-scheme showOption color4 ms-2" (click)="changeBGcolor('#6366f1','#FFFFFF')"></div>
                            <div class="ms-2 color-scheme">
                                <p-colorPicker [(ngModel)]="color5" appendTo="body" (onChange)="changeBGcolor_(color5)"></p-colorPicker>
                                <i class="pi pi-pencil colorEdit"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-content my-2 col-6 border-start">
                <!-- Title -->
                <div class="custella-content-row">
                    <div class="custella-content-row-input col-12">
                        <span class="text-14 font-medium font-grey6" id="">{{'preview' | translate}}</span>
                        <span class="text-12 color-9e d-block" id="">{{'sample_cust_book_slot' | translate}}</span>
                        <div class="preview-calendly mt-1">
                            <div class="flex-align-center">
                                <!-- PREETI CONFIGURATION -->
                                <div class="cal-logo-two-mini"> 
                                    <img *ngIf="!themeConfg.logoAttachment" src="../../../../../assets/svg/table/calendly-logo.svg" alt="">
                                    <img *ngIf="themeConfg.logoAttachment" [src]="themeConfg.logoAttachment.attachmentUrl">
                                </div>
                                <!----------------------------------------------------------------------------------------------->
                                <!-- <p-avatar [image]="themeConfg?.logoAttachment?.attachmentUrl" styleClass="mr-2 mt-1"></p-avatar> -->
                                <div>
                                    <span class="text-12 font-medium ms-2" id="comapny-name">{{themeConfg.title ?? 'Company Name'}}</span>
                                    <span class="text-10 ms-2 d-block" id="subTitle">{{themeConfg.subTitle ?? 'Confirm your slot'}}</span>
                                </div>
                            </div>
                            <div class="flex-align-center justify-content-center p-2">
                                <img src="../../../../../assets/svg/table/CALENDLY-schedule 1.svg" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="custella-form-container-footer flex-between mb-0">
            <button type="button" class="custella-btn-white text-12" id="" (click)="setRevertToDefault()">{{ "revert_to_default" | translate }}</button>
            <div class="flex-align-center justify-content-end">
                <button type="button" class="custella-btn-white text-12 ms-2" id="" (click)="modalRef.hide()">{{ "cancel" | translate }}</button>
                <p-button type="button" (click)="onSubmit()"  [disabled]="submitTouched" styleClass="ms-2 h-32" id="btn_JobCalendlySend" [label]="'save' | translate"></p-button>
            </div>
        </div>
    </div>
</div>

<div class="custella-custom-dialog">
    <p-confirmDialog
      #cd
      [style]="{ width: '30vw' }"
      [baseZIndex]="10000"
      key="confirm"
    >
      <ng-template pTemplate="header">
        <div class="d-flex align-items-center">
          <img src="assets\svg\dialog\Confirmation-w.svg" />
          <div class="text-16 font-medium ms-3">
            Confirm 
          </div>
        </div>
      </ng-template>
      <p-footer>
        <div class="d-flex align-items-center justify-content-end">
          <button class="custella-btn-white text-12 ms-2 me-0" (click)="cd.reject()">
            Cancel
          </button>
          <button
            pButton
            type="button"
            [label]="'yes' | translate"
            iconPos="left"
            class="p-button-info ms-2 me-0 h-32"
            (click)="cd.accept()"
          ></button>
        </div>
      </p-footer>
    </p-confirmDialog>
  </div>