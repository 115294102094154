<div>
  <div class="flex-between"> 
  <i class="pi pi-times w-24px dialog-close-icon" id="btn_AssetsClose" [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="closeAsset()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="title" id="label_AssetsSelectFromList">Select an {{'asset' | translate}} from the list</span>
    <div class="flex-align-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" class="w-100" pInputText autocomplete="off" id="input_AssetsSelectFromListField"  placeholder="Type {{'asset' | translate}} Name"  [(ngModel)]="searchValue"/>
      </span>
    </div>
    <div class="height-42-stat border">
      <app-asset-table  [filterData]="filterData" [siteId]="siteId" [lookupFilter]="lookupFilter" [serviceZoneName]="serviceZoneName" [searchValue]="searchValue" ></app-asset-table>
    </div>
  </div>
</div>