<div class="custella-form-container left-right-space rounded-2 mt-0 mb-3">
    <ng-container>
        <div class="custella-form-container-title flex-between rounded-top">
            <div class="font-medium text-14" translate>{{ "fqc_details" }}</div>
            <div *ngIf="(fqcData?.fqcReportAttachment != null)" class="pr-4 d-flex align-items-center margin-right">
                <img style="width: 20px;height:25px" src="../../../assets/images/pdf.png" alt="Generate PDF"
                    placement="left" tooltip="Generate PDF" (click)="downloadForm(fqcData?.fqcReportAttachment)">
            </div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "asset" }}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="fqcData?.assets"
                        (click)="viewPage(fqcData?.assets?.id,'assets')">
                        {{ fqcData?.assets?.name ?? "-" }}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="!fqcData?.assets">-</p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "location" }}</span>
                    <p class="custella-content-row-input-title-three">{{ fqcData?.location ?? "-" }}</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "customer_damage_claim" }}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="fqcData?.customer"
                        (click)="viewPage(fqcData?.customer?fqcData?.customer?.id:-1,'customer')">
                        {{fqcData?.customer?.name?? "-"}}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="!fqcData?.customer">-</p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "subsidiary_repair_service"}}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="fqcData?.subsidiary"
                        (click)="viewPage(fqcData?.subsidiary?fqcData?.subsidiary?.id:-1,'customer')">
                        {{fqcData?.subsidiary?.name?? "-"}}
                    </p>
                    <p class="custella-content-row-input-title-three" *ngIf="!fqcData?.subsidiary">-</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate> {{ "status" }}</span>
                    <span [attr.style]="'background-color:' + colorCode + '50 !important;'" class="p-mr-2 p-tag-open p-tag p-component"><span class="p-tag-value">{{fieldLabel}}</span></span>
                   
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "iqc_type" }}</span>
                    <p class="custella-content-row-input-title-three">{{fqcData?.iqcType? fqcData?.iqcType: "-"}}</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "breakdown_service" }}</span>
                    <p class="custella-content-row-input-title-three">{{fqcData?.breakDownService?
                        fqcData?.breakDownService: "-"}}</p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two"></span>
                    <p class="custella-content-row-input-title"></p>
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Equipment Details -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "equipment_information" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "equipment_type" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.equipmentType?.name ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "equipment_number" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.equipmentNumber ?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "manufacturer"}}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.Manufacturer?? "-"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "make_model" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.model?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "serial_number" }}</span>
                    <p class="custella-content-row-input-title-three"> {{fqcData?.serialNo?? "-"}}</p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_number" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.engineNumber?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_number_two" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.engineNumber2?? "-"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_make" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.engineMake?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "engine_model" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.engineModel?? "-"}}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "current_value_hours" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{fqcData?.equipmentMeterReading?? "-"}}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate></span>
                    <p class="custella-content-row-input-title-three"></p>
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Hour Meter -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "hour_meter" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "new_value_hours" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.newMeterReading ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Test Reading -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "test_reading" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "frequency" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.frequency ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "operating_pressure" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.operatingPressure ?? "-" }}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "alternator_reading" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.alternatorReading ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "vacuum_pressure" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.vacuumPressure ?? "-" }}
                    </p>
                </div>
            </div>
        </div>
        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "discharge_pressure" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.dischargePressure ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "terminal_voltage" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.terminalVoltage ?? "-" }}
                    </p>
                </div>
            </div>
        </div>
    </ng-container>

    <!--#################################### Service Order -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "service_order" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6" *ngIf="fqcData?.serviceOrderJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "repair_service_order" }}</span>
                    <div class="d-flex">
                        <p class="custella-content-row-input-title-three-link me-2"
                            *ngIf="!serviceOrderJobOrders?.jobOrder?.deleted"
                            (click)="viewPage(fqcData?.serviceOrderJobOrder?fqcData?.serviceOrderJobOrder?.id:-1,'jobOrderNumber')">
                            {{fqcData?.serviceOrderJobOrder?.jobOrderNumber?? "-"}}
                        </p>
                        <div *ngIf="!serviceOrderJobOrders?.jobOrder?.deleted">
                            <span class="p-mr-2 p-tag p-component" [attr.style]="'background-color:' + serviceOrderJobOrders?.jobOrder?.statusColor + '50 !important;'">
                            <span class="p-tag-value text-dark">
                                {{ serviceOrderJobOrders?.jobOrder?.statusLabel ? serviceOrderJobOrders?.jobOrder?.statusLabel : "-" }}
                            </span>
                        </span>

                        </div>
                        <p class="custella-content-row-input-title-three"
                            *ngIf="serviceOrderJobOrders?.jobOrder?.deleted">
                            {{fqcData?.serviceOrderJobOrder?.jobOrderNumber}}
                            <strong style="color:#948e8ed6;font-style: italic;">(Deleted)</strong>
                        </p>
                    </div>
                </div>
                <div class="custella-content-row-input col-6" *ngIf="!fqcData?.serviceOrderJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "repair_service_order" }}</span>
                    <p class="custella-content-row-input-title-three">-</p>
                </div>
                <div class="custella-content-row-input col-6" *ngIf="fqcData?.damageClaimsJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "damaged_service_order" }}</span>
                    <div class="d-flex">
                        <p class="custella-content-row-input-title-three-link me-2"
                            *ngIf="!damageClaimsJobOrders?.jobOrder?.deleted"
                            (click)="viewPage(fqcData?.damageClaimsJobOrder?fqcData?.damageClaimsJobOrder?.id:-1,'jobOrderNumber')">
                            {{fqcData?.damageClaimsJobOrder?.jobOrderNumber?? "-"}}
                        </p>
                        <div *ngIf="!damageClaimsJobOrders?.jobOrder?.deleted">
                            <span class="p-mr-2 p-tag p-component" [attr.style]="'background-color:' + serviceOrderJobOrders?.jobOrder?.statusColor + '50 !important;'">
                            <span class="p-tag-value text-dark">
                                {{ serviceOrderJobOrders?.jobOrder?.statusLabel ? serviceOrderJobOrders?.jobOrder?.statusLabel : "-" }}
                            </span>
                        </span>
                        </div>
                        <p class="custella-content-row-input-title-three"
                            *ngIf="damageClaimsJobOrders?.jobOrder?.deleted">
                            {{fqcData?.damageClaimsJobOrder?.jobOrderNumber}}
                            <strong style="color:#948e8ed6;font-style: italic;">(Deleted)</strong>
                        </p>
                    </div>
                </div>
                <div class="custella-content-row-input col-6" *ngIf="!fqcData?.damageClaimsJobOrder">
                    <span class="custella-content-row-input-title-two" translate>{{ "damaged_service_order" }}</span>
                    <p class="custella-content-row-input-title-three">-</p>
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Final Quality Check -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "initial_quality_check" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{'iqc'}}</span>
                    <p class="custella-content-row-input-title-three-link" *ngIf="fqcData?.iqcData != null"
                        (click)="viewPage(fqcData?.iqcData?fqcData?.iqcData?._id:-1,'fqc')">
                        {{fqcData?.iqcData?.runningNumber?? "-"}}
                    </p>
                    <!-- <p class="custella-content-row-input-title-three" *ngIf="fqcData?.iqcData == null">-</p> -->
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Equipment Checklist -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "equipment_checklist" }}</div>
        </div>
        <div *ngFor="let data of questionList;let i = index">
            <div class="custella-form-container-title-two-grey">
                <div class="font-medium text-14" translate>{{data?.name}}</div>
            </div>
            <div class="custella-form-container-content-border-bottom text-12 font-medium">
                <div class="row">
                    <div class="custella-content-row-input col-12 mt-2" *ngFor="let question of data.detail">
                        <span class="custella-content-row-input-title-two" translate>{{question.question.question}}</span>
                       <div *ngFor="let answer of question.question.answers">
                        <p class="custella-content-row-input-title-three"  *ngIf="question?.answer && question?.answer?._id== answer?._id">{{ answer.answer }}</p>
                    </div>
                    </div>
                   
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### Remarks -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "remarks" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{ "Remarks" }}</span>
                    <p class="custella-content-row-input-title-three">
                        {{ fqcData?.remark ?? "-" }}
                    </p>
                </div>
                <div class="custella-content-row-input col-6">
                </div>
            </div>
        </div>
    </ng-container>
    <!--#################################### System Information -->
    <ng-container>
        <div class="custella-form-container-title-two flex-between">
            <div class="font-medium text-14" translate>{{ "system_information" }}</div>
        </div>

        <div class="custella-form-container-content-border-bottom text-12 font-medium">
            <div class="custella-content-row">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{'created_by'}}</span>
                    <div class="custella-vp-picture d-flex align-items-center">
                        <p-avatar *ngIf="fqcData?.createdByUser?.imageUrl"
                            [image]="fqcData?.createdByUser?.imageUrl" styleClass="mr-2 mt-2" shape="circle"></p-avatar>
                        <p-avatar *ngIf="!fqcData?.createdByUser?.imageUrl"
                            image="../../../../../assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>

                        <span class="custella-content-row-input-title-three-link mx-2"
                            (click)="viewUser(fqcData?.createdByUser?.id, false)">{{fqcData?.createdByUser?.firstName}}
                            {{fqcData?.createdByUser?.lastName}},</span>
                        <span class="custella-content-row-input-title-three"> {{fqcData?.createdDate}}</span>
                    </div>
                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title-two" translate>{{'last_modified_by'}}</span>
                    <div class="custella-vp-picture d-flex align-items-center">
                        <p-avatar *ngIf="fqcData?.modifiedByUser?.imageUrl"
                            [image]="fqcData?.modifiedByUser?.imageUrl" styleClass="mr-2 mt-2" shape="circle">
                        </p-avatar>
                        <p-avatar *ngIf="!fqcData?.modifiedByUser?.imageUrl"
                            image="../../../../../assets/svg/Avatar.svg" styleClass="mr-2 mt-2" shape="circle"></p-avatar>

                        <span class="custella-content-row-input-title-three-link mx-2"
                            (click)="viewUser(fqcData?.modifiedByUser?.id, false)">{{fqcData?.modifiedByUser?.firstName}}
                            {{fqcData?.modifiedByUser?.lastName}},</span>
                        <span class="custella-content-row-input-title-three">{{fqcData?.lastModifiedDate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>