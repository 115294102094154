import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlanningHubService } from 'src/app/_services/planning-hub.service';
import { ErrorUtil } from 'src/app/_utilities/error';

@Component({
  selector: 'app-switch-order-diff-vehicle-confirmation',
  templateUrl: './switch-order-diff-vehicle-confirmation.component.html',
  styleUrls: ['./switch-order-diff-vehicle-confirmation.component.scss']
})
export class SwitchOrderDiffVehicleConfirmationComponent implements OnInit {

  data: any;
  title: any;

  
  constructor(public modalRef : BsModalRef,
    private errorUtil: ErrorUtil,
    private spinner : NgxSpinnerService,
    private planningHubService: PlanningHubService
    ){}

  ngOnInit() {
    console.log(this.data)
     this.spinner.hide();
  }
  cancelModel(){
    this.modalRef.hide()
    this.planningHubService.swithOrderDiffVehCancel();//open vehicle cards
  }

  confirmModel(){
   
    //debugger
    this.save()
   
  }
  save(){
    
     this.spinner.show();
    this.planningHubService.swithOrderDiffVeh(this.data).subscribe((res:any)=>{
      this.spinner.hide();
      if (res.status === 200 || res.status === 201) {
         this.modalRef.hide();
         this.errorUtil.setErrorMessage(res.status, null , 'Changes updated successfully', 'success',1000);
      }
      else{
        this.errorUtil.setErrorMessage(res.status, null , res.error.title, 'error',3000);
       
      }
    })
   }
 
}
