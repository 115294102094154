import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-no-permission",
  templateUrl: "./no-permission.component.html",
  styleUrls: ["./no-permission.component.scss"],
})
export class NoPermissionComponent implements OnInit {
  public access = {
    module: "",
    action: "",
  };

  constructor(private router: Router, private route: ActivatedRoute) {
    this.preInit();
  }

  ngOnInit(): void {
    this.preInit();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    const extras = this.router.getCurrentNavigation()?.extras ?? null;

    console.log(extras);

    if (extras && extras.hasOwnProperty('state') && extras.state?.access && extras.state.access.module && extras.state?.access.action) {
      this.access.module = (extras.state?.access.module as string).replace(" ", "_");
      this.access.action = extras.state?.access.action;
    } else {
      this.access.module = "no_permission";
      this.access.action = "action";
    }
  }
}
