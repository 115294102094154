<div>
    <div class="flex-between"> 
     <i class="pi pi-times w-24px dialog-close-icon" id="btn_SalesOrderClose" [pTooltip]="'close'|translate" tooltipPosition="left" (click)="closeProduct()"></i>
    </div>
    <div class="position-relative text-start">
      <span class="title-lookup" id="label_SalesOrderSelectFromList">{{'select_so_from_list'|translate}}</span>
      <div class="d-flex align-items-center mt-2">
        <span class="p-input-icon-left w-100 py-2">
          <i class="pi pi-search"></i>
          <input type="text" pInputText autocomplete="off" id="input_SalesOrderSelectFromListField"  placeholder="Type Sale Order Id, Name"  [(ngModel)]="searchValue"/>
        </span>
       </div>
      <div class="height-42-stat border">
        <app-so-table  [filterData]="filterData" [searchValue]="searchValue" [customerId]="customerId"></app-so-table>
      </div>
    </div>
  
  </div>
