import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef, GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { PermissionService } from 'src/app/auth/permission.service';
import { ButtonRendererComponent } from 'src/app/renderer/button-renderer.component';
import { StatusRendererComponent } from 'src/app/renderer/status-renderer.component';
import { TableHeaderToolTipComponent } from 'src/app/shared/table-header-tool-tip/table-header-tool-tip.component';
import { DeleteRecordService } from 'src/app/_services/delete-record.service';
import { IqcFqcService } from 'src/app/_services/iqc-fqc.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { UtilServiceService } from 'src/app/_services/utilService/util-service.service';
import { ErrorUtil } from 'src/app/_utilities/error';
import { DataService, ServerRequest, TableDef } from '../IqcFqc';

@Component({
  selector: 'app-iqc-fqc-so',
  templateUrl: './iqc-fqc-so.component.html',
  styleUrls: ['./iqc-fqc-so.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IqcFqcSoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isRSo: any = true;
  @Input() joId: any;
  @Input() _id: any;
  @Input() mode: any = 'IQC';

  public searchValue = "";

  @ViewChild("pInputText") pInputText!: ElementRef;
  isFilterToggleOn: boolean = false;

  dateRange!: any;
  filter: ServerRequest = {
    location: '',
    fromDate: '',
    toDate: '',
    startFrom: 0,
    perPage: 25,
    columnName: 'runningNumber',
    sortOrder: 'desc'
  };
  filterOld: any;

  create: boolean = true;

  gridApi: any;
  columnDefs: any = [];
  columnDefsTmp: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: GridOptions;
  overlayNoRowsTemplate!: string;
  displayIcon: boolean = false;
  currentRecordNumber: any;
  totalRecord: any = 0;
  noTask!: boolean;
  tasks: any = [];
  // refresher: Subscription
  locations: any;// = locatns;
  dateFormateChange: any;
  createTask: boolean = false;
  refresherIqc1: Subscription = new Subscription();
  refresherIqc2: Subscription = new Subscription();
  subscriber1: Subscription = new Subscription();
  activeIndex = 0;
  skipTaskListIds: any = [];
  taskTemp = [];
  searchValueData: boolean= false;

  constructor(private errorUtil: ErrorUtil,
    private router: Router,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private auth: AuthenticationService,
    private taskService: TasksService,
    private perm: PermissionService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private iqcFqcService: IqcFqcService,
    private cd: ChangeDetectorRef) {
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
      statusRenderer: StatusRendererComponent,
    };
    this.preInit();
    this.overlayNoRowsTemplate = "<div class='not-found'><span>Record not found</span> </div>";
  }

  ngOnInit(): void {
    this.getTask();
    this.createTask = this.perm.capable('tasks', 'create');
    this.dateFormateChange = this.auth.getUserTimezone();
    let ind: any = this.route.snapshot.paramMap.get('activeIndex');
    this.activeIndex = (ind && !isNaN(ind)) ? Number(ind) : 0;
  }


  ngAfterViewInit(): void {
    this.subscriber1 = this.dataService.currentMessage
      .subscribe(message => {
        console.log(message);
        this.activeIndex = Number(message);
      }, (err) => { console.log(err) });
  }

  ngOnDestroy(): void {
    if (this.refresherIqc1)
      this.refresherIqc1.unsubscribe();
    if (this.refresherIqc2)
      this.refresherIqc2.unsubscribe();
    if (this.subscriber1)
      this.refresherIqc2.unsubscribe();
  }

  /******************************************** pre-load variables ******************************************/
  private preInit() {
    let tblOption = new TableDef(this.mode === 'IQC');
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: 'clientSide',
      paginationPageSize: 25,
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
    this.defaultColDef = {
      enableRowGroup: true,//ag-Grid-Enterprise
      enablePivot: true,//ag-Grid-Enterprise
      enableValue: true,//ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent
    }
    this.columnDefs = [];
    this.getColDef().forEach((element: any) => {
      const modifiedColumn = this.modifyColumnTable(element);
      this.columnDefs.push(modifiedColumn);
    });
    tblOption.action.cellRendererParams = {
      onClick: this.editBtn.bind(this),
      hidden: { update: false, remove: false },
      permissions: { update: this.perm.capable('tasks', 'update'), remove: this.perm.capable('tasks', 'remove') }
    };
    this.columnDefs.push(tblOption.action);

  }

  /********************************** Call API to Display data **************************/
  getTask() {
    if (this.joId == null || typeof this.joId == undefined) {
      this._id = this.route.snapshot.paramMap.get('id');
      if (this.mode === 'IQC') {
        this.refresherIqc1 = this.iqcFqcService.getIqcById(this._id).subscribe((resp: any) => {
          this.joId = resp?.data?.serviceOrderJobOrder?.id;
          this.setTask();
        });
      } else this.refresherIqc1 = this.iqcFqcService.getFqcById(this._id).subscribe((resp: any) => {
        this.joId = resp?.data?.serviceOrderJobOrder?.id;
        this.setTask();
      });
    } else this.setTask();
  }

  setTask() {
    this.refresherIqc1 = this.taskService.getTasksByJobOrderId(this.joId).subscribe((res: any) => {
      console.log(res.body);
      if (res.status === 200 || res.status === 201) {
        this.tasks = res.body;
        this.taskTemp = _.clone(this.tasks);
        this.totalRecord = this.tasks?.length ?? 0;
        if (this.gridApi)
          this.gridApi.setRowData(this.tasks);
        if (this.tasks) {
          this.skipTaskListIds = [];
          this.tasks.forEach((t: any) => this.skipTaskListIds.push(t.id));
        }
      }
      this.spinner.hide();
    },
      (err) => {
        this.tasks = [];
        this.totalRecord = 0;
        this.spinner.hide();
        this.errorUtil.setErrorMessage(err);
      });
    this.spinner.hide();
    this.cd.detectChanges();
  }


  /******************************************** reload table ******************************************/
  public rerender() {
    this.setTask();
    this.onGridReady(this.gridParams);
  }
  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    this.gridParams = params;
    this.gridApi = params.api;
    if (this.activeIndex++ < 3) {
      this.gridApi.sizeColumnsToFit();
    }

    if (this.gridApi) if (this.tasks) {
      this.gridApi.hideOverlay();
      this.gridApi.setRowData(this.tasks);
    } else this.gridApi.showNoRowsOverlay();
  }

  /******************************************** Filter ******************************************/
  filterTaskResults() {
    this.tasks = _.clone(this.taskTemp);
    if (this.searchValue != null && this.searchValue.length > 0) {
      this.searchValueData = true
      this.gridApi.setQuickFilter(this.searchValue);
      if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length == 0) {
        this.gridApi.showNoRowsOverlay();
      }
    } else { this.gridApi.setQuickFilter(''); this.gridApi.setRowData(this.tasks); }
    // this.gridApi.resetQuickFilter();
    this.totalRecord = this.gridApi.getDisplayedRowCount();
    this.cd.detectChanges();
  }

  public refresh() {
    this.searchValue = "";
    this.tasks = _.clone(this.taskTemp);
    this.gridApi.setQuickFilter(this.searchValue);
    if (this.gridApi && this.gridApi.rowModel.rowsToDisplay.length == 0) {
      this.gridApi.showNoRowsOverlay();
    }

    // this.gridApi.resetQuickFilter();
    this.totalRecord = this.gridApi.getDisplayedRowCount();
    this.cd.detectChanges();
  }

  /******************************************** navigate to create new job order ******************************************/
  public newTask() {
    this.router.navigate(["/dashboard/iqc-fqc/fqc-create/" + this.joId + "/task"],
      { queryParams: { _id: this._id, skipTaskListIds: this.skipTaskListIds, mode: this.mode } });
  }

  /******************************************** one item view ******************************************/

  view(e: any) {
    if (e.colDef.field === "taskName")
      this.util.openTaskVIewPage(e.data.id)
    // this.router.navigate(["/dashboard/tasks/view"], { queryParams: { id: e.data.id } });//todo set root
  }

  /******************************************** right click cell context menu ******************************************/
  public getContextMenuItems = (params: any) => {
    params.node.data.field = params.column.colId;

    let results: any = [];

    if (params.column.colId == "taskName") {
      const data = params.node.data;

      // results.push({
      //   name: "Open link in new tab",
      //   action: () => this.redirectToNewTab(data),
      // });

      results.push({
        name: "Export to Excel",
        action: () => this.onBtExport(data),
      });
    }

    return results;
  };

  /******************************************** redirect to ******************************************/
  private redirectToNewTab(data: any) {
    let url;
    // if (data.field === "taskName") {
    //   url = this.router.serializeUrl(
    //     this.router.createUrlTree(["/dashboard/tasks/view"], {//todo set root
    //       queryParams: { id: data.id },
    //     })
    //   );
    //   url = url.replace("%23", "#");

    //   this.util.openItemToNewTab(url);
    // }
  }

  /******************************************** export data ******************************************/
  public onBtExport(params: any) {
    if (params.field === "taskName") {
      this.gridApi.exportDataAsExcel();
    }
  }

  editBtn(e: any) {
    if (e.action === 'edit') {
      // this.router.navigate(["/dashboard/iqc-fqc/fqc-create/:id/task"]);
    }
    else if (e.action === 'delete') {
      e.rowData.deleteType = 'Task'
      e.rowData.message = "Are you sure you want to delete " + e.rowData?.taskName + " ?";
      console.log(e.rowData)
      this.deleteRecordService.getItems(e.rowData);
      this.setTask();
      this.onGridReady(this.gridParams);
      this.cd.detectChanges();
    }
  }

  getColDef() {
    return this.isRSo ? [
      { headerName: 'Task Name', field: 'taskName', width: 150 },
      { headerName: 'Date', field: 'startDateTime', width: 140, },
      { headerName: 'Time', field: 'startDateTime', width: 140, },
      { headerName: 'Assigned To', field: 'assignedTo', width: 70, },
      { headerName: 'Task Status', field: 'taskStatus', width: 130, },
    ] : [
      { headerName: 'Task Name', field: 'taskName', width: 200, },
      { headerName: 'Date', field: 'startDateTime', width: 140, },
      { headerName: 'Time', field: 'startDateTime', width: 140, },
      { headerName: 'Assigned To', field: 'assignedTo', width: 70, },
      { headerName: 'Task Status', field: 'taskStatus', width: 130, }
    ];
  }

  /******************************************** modify column to fit new ag-grid ******************************************/
  public modifyColumnTable(element: any) {
    // base
    const column: ColDef = {
      field: element.field,
      headerName: this.util.formatText(element.headerName, "titlecase", {
        seperator: " ",
      }),
      hide: element.hide,
      width: 60,
      colId: element.field,
      cellClass: [],
      headerTooltip: "#",
    };

    if (element.field === "taskName") {
      column.cellClass = function () {
        return ["table_default_color"];
      };
      column.getQuickFilterText = params => {
        return params.data.taskName;
      }
    } else {
      column.cellClass = function () {
        return ["d-flex align-items-center"];
      };

    }

    if (element.field === "startDateTime" && element.headerName === 'Date') {
      column.valueFormatter = (params: any) => {
        let a = '';
        if (params.data && params.data.startDateTime !== '' && params.data.startDateTime !== null) {
          a = moment(params.data.startDateTime).format(localStorage.getItem('date_format')!.toUpperCase());
        }
        return a;
      }
      column.getQuickFilterText = params => {
        return params.data.startDateTime;
      }
      column.width = 40;
    }

    if (element.field === "startDateTime" && element.headerName === 'Time') {
      column.valueFormatter = function (params: any): string {
        if (params.data && params.data.startDateTime !== '' && params.data.endDateTime !== null) {
          return moment(params.data.startDateTime).format(' hh:mm a') + ' - ' + moment(params.data.endDateTime).format('hh:mm a') + '';
        } else {
          return '';
        }
      }
      column.width = 40;
    }

    if (element.field === "assignedTo") {
      column.cellRenderer = (params: any) => {
        var img = '';
        var status = ''
        var assignedUsersList = ''
        if (params.data) {
          if (params.data.assignedTo && params.data.assignedTo.imageUrl !== null) {
            img = '<img class="prof-img-task mx-2 my-0 ml-4" src="' + params.data.assignedTo.imageUrl + '">';
          } else if (params.data.assignedTo && params.data.assignedTo.imageUrl === null) {
            img = '<img  class="prof-img-task mx-2 my-0 ml-4" src="../../../../assets/svg/Avatar.svg">';
          }

          if (params.data.assignedTo && params.data.assignedTo.isOnline) {
            status = ' <div class="on-status status-green"></div>';
          } else {
            status = ' <div class="on-status status-red"></div>';
          }
          if (params.data.assignedUsersList && params.data.assignedUsersList.length > 1) {
            let coun = params.data.assignedUsersList.length - 1
            assignedUsersList = '<div class="py-1 px-2 text-1" style="background-color: #3f5764; color: white; border-radius: 6px; z-index: 9999;line-height: 1;margin-left:3px;">+' + coun + ' </div>';
          }
          if (img !== '' && status !== '') {
            return '<div class="text-1-5 py-1 d-flex align-items-center"><p class="mb-0 normal-font" style="color:#000">' + params.data.assignedTo.firstName + ' ' + params.data.assignedTo.lastName + '</p>' + assignedUsersList + '</div> ';
          }
        }
      };
      column.getQuickFilterText = params => {
        return params.data.assignedTo?.firstName + ' ' + params.data.assignedTo?.lastName;
      }
      column.width = element.width;
      column.cellClass = function () { return ['text-1-5 font-weight-bold hand']; };
    }

    if (element.field === "taskStatus") {
      column.cellRenderer = (params: any) => {
        let status: any;
        if (params.data && params.data) {
          if (params.data.taskStatus) {
            const currentStatus = params.data.taskStatus;
            let satusObj: any;
            satusObj = statusTask.find((status: any) => status.name === currentStatus);
            status =
              ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
              satusObj.colorCode +
              "50 !important ;color:" +
              satusObj.colorCode +
              '"><span class="p-tag-value text-dark">' +
              satusObj.fieldLabel +
              "</span></span>";
          }
        }
        return status;
      };
      column.width = 20;
    }
    return column;
  }
  public onSearchCheck()
  {
    if(this.searchValue.length == 0)
    {
      this.searchValueData = false
      this.searchValue = ''
    }
  }

  public onClear(){
    this.searchValueData = false
    this.searchValue = ''
    
  }

}


const statusTask = [
  {
    "id": 1,
    "name": "OPEN",
    "fieldLabel": "Open",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#B2B2B2",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 1,
    "name": "NEW",
    "fieldLabel": "New",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#B2B2B2",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 2,
    "name": "IN_PROGRESS",
    "fieldLabel": "In Progress",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#FDA31A",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 3,
    "name": "COMPLETED",
    "fieldLabel": "Completed",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#4ED15E",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 4,
    "name": "PENDING",
    "fieldLabel": "Pending",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#8C34B0",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 5,
    "name": "CANCELLED",
    "fieldLabel": "Cancelled",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#ED3636",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "System",
    "createdDate": "2021-09-28T07:41:10Z",
    "lastModifiedBy": null,
    "lastModifiedDate": null,
    "id": 6,
    "name": "IN_COMPLETED",
    "fieldLabel": "In Completed",
    "description": null,
    "active": true,
    "order": 1,
    "colorCode": "#975E50",
    "isDefault": null,
    "systemValue": true
  },
  {
    "createdBy": "arfan2@custella.com",
    "createdDate": "2022-08-09T01:37:29Z",
    "lastModifiedBy": "arfan2@custella.com",
    "lastModifiedDate": "2022-08-09T01:37:29Z",
    "id": 7,
    "name": "CLOSED_ESCALATED",
    "fieldLabel": "Closed Escalated",
    "description": null,
    "active": true,
    "order": 6,
    "colorCode": "#1F9EB5",
    "isDefault": null,
    "systemValue": false
  },
  {
    "createdBy": "arfan2@custella.com",
    "createdDate": "2022-08-09T01:37:29Z",
    "lastModifiedBy": "arfan2@custella.com",
    "lastModifiedDate": "2022-08-09T01:37:29Z",
    "id": 7,
    "name": "DRAFT",
    "fieldLabel": "Draft",
    "description": null,
    "active": true,
    "order": 6,
    "colorCode": "#1F9EB5",
    "isDefault": null,
    "systemValue": false
  }
];