<div class="mt-5 h-95 ">
  <div class="d-flex button-box-top-right">
    <p-button *ngIf="iqcData?.status=='NEW' || iqcData?.status=='DRAFT'" label="Edit" styleClass="ms-2 py-2 px-4 text-nowrap h-28" (click)="edit(_id)"></p-button>
    <div class="dropdown options ms-2"  *ngIf="iqcData?.status=='NEW' || iqcData?.status=='DRAFT'">
      <div class="" data-bs-toggle="dropdown">
        <button class="no-border btn-28 rounded-1 ms-2"> <i class="pi pi-ellipsis-v pt-04"></i> </button>
      </div>
      <ul class="dropdown-menu dropdown-collaps p-0">
        <li class="dropdown-item px-3 py-3 cursor-pointer" (click)="delete('delete')">Delete</li>
      </ul>
    </div>
  </div>


  <p-tabView class="px-0" (onChange)="handleChange($event)" [(activeIndex)]="activeIndex">
    <p-tabPanel [header]="'details' | translate">
      <div style="overflow-y: hidden!important;height: 80vh;">
        <div style="overflow: scroll!important;height: 83vh">
          <app-iqc-details [id]="_id" *ngIf="iqcDetails" (getIQCdata)="getIQCdata($event)"></app-iqc-details>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="'repair_service_order' | translate">
      <div style="overflow-y: hidden!important;height: 80vh;">
        <!-- <div style="overflow: scroll!important;height: 83vh"> -->
        <app-iqc-fqc-so [joId]="iqc?.serviceOrderJobOrder?.id" [_id]="_id" *ngIf="activeIndex==1">
          <!-- *ngIf="iqc?.serviceOrderJobOrder?.id" -->
        </app-iqc-fqc-so>
        <!-- </div> -->
      </div>
    </p-tabPanel>

    <p-tabPanel [header]="'damaged_service_order' | translate">
      <div style="overflow-y: hidden!important;height: 80vh;">
        <!-- <div style="overflow: scroll!important;height: 83vh"> -->
        <app-iqc-fqc-so [isRSo]="false" [joId]="iqc?.damageClaimsJobOrder?.id" [_id]="_id" *ngIf="activeIndex==2">
          <!-- *ngIf="iqc?.damageClaimsJobOrder?.id" -->
        </app-iqc-fqc-so>
        <!-- </div> -->
      </div>
    </p-tabPanel>

  </p-tabView>
</div>