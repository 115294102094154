<ul class="nav nav-tabs" style="border-bottom: 1px solid #dedede;" id="heldesk-tab">
  <ng-container *ngIf="allTabs.length > 1">
    <li class="nav-item hand" [ngClass]="tab.active ? 'selectedTab' : ''" *ngFor="let tab of allTabs; let i = index; let first = first" (click)="onTabClick(tab)" >
      <a class="nav-link rounded-0 active pl-2 pr-2" [ngClass]="first ? 'text-center' : ''" *ngIf="first" >{{ tab.name | translate }}</a>
      <div *ngIf="!first" class="d-flex justify-content-center align-items-center ms-1" >
        <a class="nav-link rounded-0 active ps-2 pe-2 d-flex align-items-center">
          <span class="me-2"> {{ tab.name }} </span>
          <i class="pi pi-times-circle" title="Close" (click)="closeTab(tab.name)" *ngIf="tab.closable && tab.active" ></i>
          <i title="Close" class="pi pi-times-circle" (click)="closeTab(tab.name)" *ngIf="tab.closable && tab.active !== true" ></i>
        </a>
      </div>
    </li>
  </ng-container>
</ul>
