import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LegendPosition, ScaleType } from "@swimlane/ngx-charts";
import { GridOptions } from "ag-grid-community";

import * as _ from "lodash";
import * as moment from "moment-timezone";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { DashboardService } from "src/app/_services/dashboard.service";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { ModalServiceService } from "src/app/_services/modal-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-job-order-dashboard",
  templateUrl: "./job-order-dashboard.component.html",
})
export class JobOrderDashboardComponent
  extends SubscriptionUtil
  implements OnInit, AfterViewInit, OnDestroy
{
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;

  companies: any;
  roleId: any = null;

  //WORKSHOP FAULT CALLS (pie chart)
  viewPie: any = [300, 300]; //Width,Height
  multiPie: any[] = [];

  colorSchemePie = {
    name: "pieScheme",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: <any[]>[],
  };

  //OVERDUE FAULT CALLS
  overDue: any;

  //PREVENTATIVE MAINTENANCE
  preventiveMaintenance: any;
  multiPreventiveMaintenance: any = [];
  colorSchemePie4 = {
    name: "pieScheme4",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#C3FFD5", "#FF9898", "#1F78B4"],
  };

  //WORKSHOP PROGRESS
  multiProgressBar: any = [];

  LegendPosition = {
    Below: LegendPosition.Below,
    Right: LegendPosition.Right,
  };

  // -------------------

  //DATA LINE FAULT CALLS(bar chart)
  // viewBar: any[] = [650, 1000];//Width,Height
  viewBar: any = [650, 350]; //Width,Height
  colorSchemeBar = {
    name: "barScheme",
    selectable: true,
    group: ScaleType.Linear,
    domain: ["#767CA0", "#E67B7B", "#9BEA8D", "#EA8722", "#1F78B4", "#FECC7B"],
  };
  multiBar: any = [];

  //table
  totalDailyJobs: any;

  //SERVICE REQUESTS
  viewPie1: any = [200, 200]; //Width,Height
  viewPie2: any = [220, 220]; //Width,Height
  colorSchemePie1 = {
    name: "pieScheme1",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: <any>[],
  };
  colorSchemePie2 = {
    name: "pieScheme2",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: <any>[],
  };
  colorSchemePie3 = {
    name: "pieScheme3",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: <any>[],
  };
  multiTechnicalPie: any = [];
  multiNonTechnicalPie: any = [];
  multiServiceRequestPie: any = [];

  //WORKSHOP SPARE MONITORING
  //viewBarHorizontalStacked: any[] = [650, 1000];//Width,Height
  viewBarHorizontalStacked: any = [300, 300]; //Width,Height
  multiBarHorizontalStacked: any = [];
  colorSchemeHorizontal = {
    name: "schemeHorizontal",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#1F78B4", "#C3FFD5", "#FECC7B", "#FE9898"],
  };

  //aggrid
  gridApi: any;
  //gridColumnApi: any;
  columnDefs: any;
  defaultColDef: any;
  frameworkComponents: any;
  searchValue: any;
  gridParams: any;
  startFrom: any;
  salesOrder: any;

  parameters: any;
  pageSize: any = 25;
  public gridOptions!: Partial<GridOptions>;
  overlayNoRowsTemplate: any;
  jobs: any;
  selectedRange: any;
  filter: any = { fromDate: "", toDate: "" };
  dateRange!: Date[];
  dateConfig: any;
  noAssets: boolean = false;
  displayIcon: boolean = false;

  statuses: any[] = [];
  threePiedefaultIconShow: boolean = true;
  role: any;
  fromD: any;
  toD: any;

  totalRecord: any;

  @ViewChild("containerRef", { static: false }) containerRef!: ElementRef;
  @ViewChild('myCalendar') calendar: any;

  constructor(
    private dashboardService: DashboardService,
    private http: HttpClient,
    private jobOrderService: JobOrdersService,
    private modalService: ModalServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngAfterViewInit(): void {
    this.afterView();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefs = [
      {
        headerName: "Technicians ",
        field: "fullName",
        width: 160,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data && params.data.imgUrl) {
            return (
              '<img style="height:16px;width:16px;border-radius: 60px" src="' +
              params.data.imgUrl +
              '" >  ' +
              params.data.fullName
            );
          } else if (params.data && !params.data.imgUrl) {
            return (
              "<img style='height:16px;width:16px;' src='assets/svg/Avatar.svg' alt=''>  " +
              params.data.fullName
            );
          }
        },
      },
      {
        headerName: "Assignments",
        field: "value",
        width: 100,
        headerTooltip: "#",
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

    this.filter.fromDate = new Date();
    this.filter.toDate = new Date();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.dateConfig = {
      rangeInputFormat: localStorage.getItem("date_format")!.toUpperCase(),
    };

    this.role = JSON.parse(localStorage.getItem("role")!);

    this.push(
      this.dashboardService.getMagnumCompanies().subscribe((res: any) => {
        this.companies = res;
        this.roleId = this.role.id;

        this.push(
          this.jobOrderService.getJobOrderStatuses().subscribe((res: any) => {
            this.statuses = res;

            var date = new Date();
            var range = [];
            range[0] = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
            range[1] = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate()
            );
            this.dateRange = range;

            this.loadCharts();
          })
        );
      })
    );
  }

  /******************************************** After View ******************************************/
  public afterView() {
    this.viewBarHorizontalStacked = [
      this.containerRef.nativeElement.clientWidth - 50,
      300,
    ];
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Load Charts ******************************************/
  private loadCharts() {
    this.threePiedefaultIconShow = true;
    ////debugger

    var fromDate = moment(this.filter.fromDate).format("YYYY-MM-DD");
    var toDate = moment(this.filter.toDate).format("YYYY-MM-DD");

    this.push(
      this.dashboardService
        .getMagnumPieChart(
          "Fault Call",
          "status",
          this.roleId,
          fromDate,
          toDate,
          ""
        )
        .subscribe((res: any) => {
          this.multiPie = [];
          this.colorSchemePie.domain = [];
          for (var k = 0; k <= res.length - 1; k++) {
            if (res[k].value > 0) {
              this.multiPie.push(res[k]);
            }
          }
          for (var i = 0; i <= this.multiPie.length - 1; i++) {
            for (var j = 0; j <= this.statuses.length - 1; j++) {
              if (
                this.multiPie[i].name.toLowerCase() ==
                this.statuses[j].fieldLabel.toLowerCase()
              ) {
                this.multiPie[i].colorCode = this.statuses[j].colorCode;
              }
            }
          }
          for (var m = 0; m <= this.multiPie.length - 1; m++) {
            this.colorSchemePie.domain.push(this.multiPie[m].colorCode);
          }
        })
    );

    this.push(
      this.dashboardService
        .getMagnumPieChart(
          "Miscellaneous Technical,Repair service",
          "status",
          this.roleId,
          fromDate,
          toDate,
          ""
        )
        .subscribe((res: any) => {
          this.multiTechnicalPie = res;

          for (var i = 0; i <= this.multiTechnicalPie.length - 1; i++) {
            for (var j = 0; j <= this.statuses.length - 1; j++) {
              if (
                this.multiTechnicalPie[i].name.toLowerCase() ==
                this.statuses[j].fieldLabel.toLowerCase()
              ) {
                this.multiTechnicalPie[i].colorCode =
                  this.statuses[j].colorCode;
                this.colorSchemePie1.domain.push(this.statuses[j].colorCode);
              }
            }
            if (this.multiTechnicalPie[i].value > 0) {
              this.threePiedefaultIconShow = false;
            }
          }
        })
    );

    this.push(
      this.dashboardService
        .getMagnumPieChart(
          "Miscellaneous Non-Technical",
          "status",
          this.roleId,
          fromDate,
          toDate,
          ""
        )
        .subscribe((res: any) => {
          this.multiNonTechnicalPie = res;
          for (var i = 0; i <= this.multiNonTechnicalPie.length - 1; i++) {
            for (var j = 0; j <= this.statuses.length - 1; j++) {
              if (
                this.multiNonTechnicalPie[i].name.toLowerCase() ==
                this.statuses[j].fieldLabel.toLowerCase()
              ) {
                this.colorSchemePie2.domain.push(this.statuses[j].colorCode);
              }
            }
            if (this.multiNonTechnicalPie[i].value > 0) {
              this.threePiedefaultIconShow = false;
            }
          }
        })
    );

    this.push(
      this.dashboardService
        .getMagnumPieChart(
          "Miscellaneous Technical,Repair service,Miscellaneous Non-Technical",
          "status",
          this.roleId,
          fromDate,
          toDate,
          ""
        )
        .subscribe((res: any) => {
          this.multiServiceRequestPie = res;
          for (var i = 0; i <= this.multiServiceRequestPie.length - 1; i++) {
            for (var j = 0; j <= this.statuses.length - 1; j++) {
              if (
                this.multiServiceRequestPie[i].name.toLowerCase() ==
                this.statuses[j].fieldLabel.toLowerCase()
              ) {
                this.colorSchemePie3.domain.push(this.statuses[j].colorCode);
              }
            }
            if (this.multiServiceRequestPie[i].value > 0) {
              this.threePiedefaultIconShow = false;
            }
          }
        })
    );

    this.push(
      this.dashboardService
        .getMagnumPieChart(
          "Fault Call",
          "fault",
          this.roleId,
          fromDate,
          toDate,
          "IPVPN Router (Network),IPVPN Switch (Network),TM Line (Network)"
        )
        .subscribe((res: any) => {
          this.multiBar = res;
        })
    );

    this.push(
      this.dashboardService
        .getMagnumOverDue(this.roleId, fromDate, toDate)
        .subscribe((res: any) => {
          this.overDue = res.overdue;
        })
    );

    this.push(
      this.dashboardService
        .getMagnumPreventiveMaintenance(this.roleId, fromDate, toDate)
        .subscribe((res: any) => {
          this.preventiveMaintenance = res;
          this.multiPreventiveMaintenance = res.series;
        })
    );

    this.push(
      this.dashboardService
        .getMagnumBarHorizontalStacked(this.roleId, fromDate, toDate)
        .subscribe((res: any) => {
          this.multiBarHorizontalStacked = res;
        })
    );

    this.push(
      this.dashboardService
        .getMagnumProgressBar(this.roleId, fromDate, toDate)
        .subscribe((res: any) => {
          this.multiProgressBar = res;
          this.multiProgressBar.series.forEach((element: any) => {
            element.percent = Math.floor(
              (element.totalForFilterStatus / element.totalForAllStatus) * 100
            );
            element.percentWidth = element.percent + "%";
            element.remainPercent = 100 - element.percent;
            element.remainPercentWidth = element.remainPercent + "%";
            element.tootltipData =
              element.totalForFilterStatus + "/" + element.totalForAllStatus;
            element.tootltipData1 =
              element.totalForAllStatus -
              element.totalForFilterStatus +
              "/" +
              element.totalForAllStatus;
          });
        })
    );
  }

  /******************************************** On Grid Ready ******************************************/
  public onGridReady(params: any) {
    this.gridParams = params;
    this.searchValue = this.searchValue ? this.searchValue : "";
    this.gridApi = params ? params.api : params;

    var dataSource = {
      getRows: (params: any) => {
        this.startFrom = params.startRow;
        if (params.sortModel.length <= 0) {
          this.parameters = { colId: "fullName", sort: "asc" };
          params.sortModel.push(this.parameters);
        }
        var fromDate = moment(this.filter.fromDate).format("YYYY-MM-DD");
        var toDate = moment(this.filter.toDate).format("YYYY-MM-DD");
        var roleId = this.roleId ? this.roleId : "";

        this.push(
          this.http
            .get(
              environment.base_url +
                "/dashboard/task-assignment?startFrom=" +
                this.startFrom +
                "&perPage=25&search=" +
                this.searchValue +
                "&roleId=" +
                roleId +
                "&fromDate=" +
                fromDate +
                "&toDate=" +
                toDate +
                "&columnName=" +
                params.sortModel[0].colId +
                "&sortOrder=" +
                params.sortModel[0].sort
            )
            .subscribe((data: any) => {
              this.gridApi.hideOverlay();

              this.jobs = data.data;
              this.totalDailyJobs = data.taskCount;

              if (data.status === "NO_DATA") {
                this.noAssets = false;
                this.displayIcon = true;
              } else {
                this.noAssets = true;
                this.displayIcon = false;
              }
              if (this.jobs.length === 0) {
                this.gridApi.showNoRowsOverlay();

                this.totalRecord = 0;

                params.successCallback([], 0);
              } else {
                this.totalRecord = data.total;

                params.successCallback(data.data, data.total);
              }
            })
        );
      },
    };
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** Format X Axis ******************************************/
  public xAxisTickFormattingFn(value: any) {
    if (value % 1 === 0) {
      return value.toLocaleString();
    } else {
      return "";
    }
  }

  /******************************************** Open Modal ******************************************/
  public openModal(what: string, title: string) {
    var fromDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
    var toDate = moment(this.dateRange[1]).format("YYYY-MM-DD");

    let fromD = moment.utc(this.dateRange[0]).format();
    let toD = moment.utc(this.dateRange[1]).format();

    var data = {
      roleId: this.roleId,
      fromDate: fromDate,
      toDate: toDate,
      fromD: fromD,
      toD: toD,
      title: title,
    };
    this.calendar.hideOverlay();
    this.modalService.openModal(what, data);
  }

  /******************************************** Change Role ******************************************/
  public changeRole() {
    this.loadCharts();
    this.onGridReady(this.gridParams);
  }

  /******************************************** On Date Change ******************************************/
  public onDateChange(event: any) {
    if (event && event.length) {
      this.selectedRange = event;
      this.fromD = moment.utc(this.selectedRange[0]).format();
      this.toD = moment.utc(this.selectedRange[1]).format();
      this.filter.fromDate = moment(this.selectedRange[0]).format("YYYY-MM-DD");
      this.filter.toDate = moment(this.selectedRange[1]).format("YYYY-MM-DD");

      if (this.selectedRange[0] && this.selectedRange[1]) {
        this.calendar.hideOverlay();
        this.loadCharts();
        this.onGridReady(this.gridParams);
      }
    } else {
      this.fromD = "";
      this.toD = "";
      this.filter.fromDate = "";
      this.filter.toDate = "";
    }

  }

  /******************************************** Reset Search ******************************************/
  public resetSearch() {
    this.filter.fromDate = new Date();
    this.filter.fromDate = new Date();
    this.fromD = moment.utc(new Date()).format();
    this.toD = moment.utc(new Date()).format();
    var date = new Date();
    var range = [];
    range[0] = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    range[1] = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    this.dateRange = range;
    this.roleId = this.role.id;

    this.loadCharts();
    this.onGridReady(this.gridParams);
  }

  /******************************************** Toggle ******************************************/
  public toggle(element: any) {
    const contains = element.classList.contains("collapsed");

    if (contains) {
      element.className = "pi pi-caret-down mt-1 collapsed";
    } else {
      element.className = "pi pi-caret-up mt-1";
    }
  }

  /******************************************** On Resize Chart ******************************************/
  public resizeChart(width: any) {
    this.viewBarHorizontalStacked = [width - 50, 300];
  }
   
  push(obs:any) {
    super.push(obs);
  }
}
