import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FqcIqcRoutingModule } from './fqc-iqc-routing.module';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { IqcFqcComponent } from './iqc-fqc.component';
import { IqcComponent } from './iqc/iqc.component';
import { TranslateV1Pipe } from 'src/app/_pipes/translate-v1.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { FqcComponent } from './fqc/fqc.component';
import { CommanComponentModule } from 'src/app/shared/comman-component/comman-component.module';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { IqcViewComponent } from './iqc/iqc-view/iqc-view.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { IqcFqcMainComponent } from './iqc-fqc-main.component';
import { PickListModule } from 'primeng/picklist';
import { IqcDetailsComponent } from './iqc/iqc-details/iqc-details.component';
import { IqcFqcSoComponent } from './iqc-fqc-so/iqc-fqc-so.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { IqcCreateComponent } from './iqc/iqc-create/iqc-create.component';
import { FqcCreateComponent } from './fqc/fqc-create/fqc-create.component';
import { FqcDetailsComponent } from './fqc/fqc-details/fqc-details.component';
import { FqcViewComponent } from './fqc/fqc-view/fqc-view.component';
import { AddEditTaskComponent } from './add-edit-task/add-edit-task.component';
import { DataService } from './IqcFqc';


@NgModule({
  declarations: [
    IqcFqcMainComponent,
    IqcFqcComponent,
    IqcComponent,
    FqcComponent,
    IqcViewComponent,
    IqcDetailsComponent,
    IqcFqcSoComponent,
    IqcCreateComponent,
    FqcCreateComponent,
    FqcDetailsComponent,
    FqcViewComponent,
    AddEditTaskComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    AgGridModule,
    FqcIqcRoutingModule,
    TranslateModule,
    ButtonModule,
    ToastModule,
    DialogModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    TableModule,
    TabViewModule,
    InputTextModule,
    DropdownModule,
    CalendarModule,
    NgxSpinnerModule,
    CommanComponentModule,
    DropdownModule,
    PickListModule,
  ],
  exports: [IqcFqcComponent],
  providers:[TranslateV1Pipe, DataService]
})
export class FqcIqcModule { }
