import { Component, OnDestroy, OnInit } from "@angular/core";
import { HillsService } from "src/app/_services/hills.service";

import * as _ from "lodash";
import { LegendPosition, ScaleType } from "@swimlane/ngx-charts";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";

@Component({
  selector: "app-hills-dashboard",
  templateUrl: "./hills-dashboard.component.html",
  styleUrls: ["./hills-dashboard.component.scss"],
})
export class HillsDashboardComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  boxChart: any = [];
  multiBar: any = [];
  multiPie: any = [];
  multi: any = [];
  multiHorizontal1: any = [];
  multiHorizontal2: any = [];
  multiVertical1: any = [];
  multiVertical2: any = [];
  tableData: any = [];
  tableHeaderSeries: any;
  columnDefs: any;
  gridOptions: any;
  defaultColDef: any;
  gridApi: any;
  rowData: any[] = [];
  rowData1: any[] = [];
  orgRowData: any[] = [];
  obj: any;
  searchValue: any;

  noData = false;

  //Line Chart
  viewVerticalBar: any = [700, 300]; //Width,Height
  viewPie: any = [300, 300];
  viewLine: any = [1000, 300];
  viewHorizontalBar1: any = [1000, 300];
  viewHorizontalBar2: any = [1000, 300];
  viewStackedBar1: any = [1000, 300];
  viewStackedBar2: any = [1000, 300];

  // options
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  timeline: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;

  colorSchemeBar = {
    name: "schemeBarColor",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#4D516A", "#CE4A4A", "#9BEA8D"],
  };
  colorSchemePie = {
    name: "schemePieColor",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#E3B800", "#D07920", "#41AEA9", "#5F2940"],
  };
  colorSchemeLine = {
    name: "schemeLineColor",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#5B8DD7"],
  };
  colorSchemeHorizontal = {
    name: "schemeHorizontalColor",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: [
      "#562F88",
      "#5EBAE2",
      "#E77ADD",
      "#AAAAAA",
      "#90C74E",
      "#C0A7E0",
      "#C35B5B",
    ],
  };
  colorSchemeVertical = {
    name: "schemeVerticalColor",
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ["#3B4358", "#EABF91", "#AF7067", "#CD8D85", "#F9F5D2"],
  };

  boxColors = ["#e3b800", "#d07920", "#41aea9", "#5f2940"];
  boxBottomColors = ["#af8e00", "#996529", "#2e7a77", "#401f2d"];

  LegendPosition = {
    Below: LegendPosition.Below,
  };

  totalRecord: any;
  searchValueData: boolean = false;

  constructor(private hillsService: HillsService) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.defaultColDef = {
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 10,
      paginationPageSize: 10,
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params: any) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };
  }

  /******************************************** Init ******************************************/
  private init() {
    this.firstClick();
    this.secondClick();
    this.thirdClick();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** First Click ******************************************/
  public firstClick() {
    this.push(
      this.hillsService.getBoxesData().subscribe((res: any) => {
        this.boxChart = res;
        for (var i = 0; i <= this.boxChart.length - 1; i++) {
          this.boxChart[i].color = this.boxColors[i];
          this.boxChart[i].bottomColors = this.boxBottomColors[i];
        }
      })
    );

    this.push(
      this.hillsService.getBarChart().subscribe((res: any) => {
        this.multiBar = res;
      })
    );

    this.push(
      this.hillsService.getPieChart().subscribe((res: any) => {
        this.multiPie = res;
      })
    );
  }

  /******************************************** Second Click ******************************************/
  public secondClick() {
    this.push(
      this.hillsService.getLineChart().subscribe((res: any) => {
        this.multi = [{ name: "", series: res.series }];
      })
    );

    this.push(
      this.hillsService.getHorizontalStacked(false).subscribe((res: any) => {
        this.multiHorizontal1 = res;
      })
    );

    this.push(
      this.hillsService.getHorizontalStacked(true).subscribe((res: any) => {
        this.multiHorizontal2 = res;
      })
    );

    this.push(
      this.hillsService.getVerticalStacked(false).subscribe((res: any) => {
        this.multiVertical1 = res;
      })
    );

    this.push(
      this.hillsService.getVerticalStacked(true).subscribe((res: any) => {
        this.multiVertical2 = res;
      })
    );
  }

  /******************************************** Generate Columns ******************************************/
  private generateColumns(data: any[]) {
    let columnDefinitions: any[] = [];
    data.map((object) => {
      Object.keys(object).map((key) => {
        if (key != "Image") {
          let mappedColumn = {
            headerName: key == "TechnicianName" ? "Technician Name" : key,
            field: key,
            headerTooltip: "#",
            cellRenderer: function (params: any) {
              if (params.data.Image && key === "TechnicianName") {
                return (
                  '<img style="height:16px;width:16px;border-radius: 60px" src="' +
                  params.data.Image +
                  '" >  ' +
                  params.data.TechnicianName
                );
              } else if (!params.data.Image && key === "TechnicianName") {
                return (
                  "<img style='height:16px;width:16px;'  src='assets/svg/Avatar.svg' alt=''>  " +
                  params.data.TechnicianName
                );
              } else {
                return params.data[key];
              }
            },
          };
          columnDefinitions.push(mappedColumn);
        }
      });
    });
    //Remove duplicate columns
    columnDefinitions = columnDefinitions.filter(
      (column, index, self) =>
        index ===
        self.findIndex((colAtIndex) => colAtIndex.field === column.field)
    );
    return columnDefinitions;
  }

  /******************************************** Third Click ******************************************/
  public thirdClick() {
    this.rowData = [];

    this.push(
      this.hillsService.getTechniciansData().subscribe((res: any) => {
        if (res) {
          this.tableData = res;

          this.tableHeaderSeries = res[0].series;
          this.rowData1 = [];
          for (var i = 0; i <= res.length - 1; i++) {
            this.obj = {};
            for (var j = 0; j <= res[i].series.length - 1; j++) {
              this.obj.Image = res[i].imageUrl;
              this.obj.TechnicianName = res[i].technicianName;
              this.obj[res[i].series[j].name] = res[i].series[j].value;
            }
            this.rowData1.push(this.obj);
          }
          this.rowData = [];
          this.rowData = this.rowData1;

          this.totalRecord = this.rowData.length;

          this.orgRowData = [];
          this.orgRowData = _.cloneDeep(this.rowData);
          this.columnDefs = this.generateColumns(this.rowData);
        } else {
          this.totalRecord = 0;

          this.rowData = [];

          this.noData = true;
        }
      })
    );
  }

  /******************************************** Quick Search ******************************************/
  public quickSearch(searchValue: any) {
    var searchResult = [];
    if (searchValue) {
      this.searchValueData = true;
      for (var i = 0; i <= this.orgRowData.length - 1; i++) {
        if (this.orgRowData[i].TechnicianName) {
          if (
            this.orgRowData[i].TechnicianName.toLowerCase().includes(
              searchValue.toLowerCase()
            )
          ) {
            searchResult.push(this.orgRowData[i]);
          }
        }
        this.rowData = [];
        this.rowData = searchResult;
      }
    } else {
      this.rowData = [];
      this.rowData = this.orgRowData;
    }
  }

  public onSearchCheck()
  {
    if (this.searchValue.length == 0) {
      this.searchValueData = false
      this.searchValue = ''
      this.refresh()
    }
  }
    public onClear(){
    this.searchValueData = false
    this.searchValue = ''
    this.refresh()
  
  }

  /******************************************** On Data First Rendered ******************************************/
  public onFirstDataRendered(params: any) {
    params.api.sizeColumnsToFit();
  }

  /******************************************** On Grid Ready ******************************************/
  public onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  /******************************************** Refresh ******************************************/
  public refresh() {
    this.thirdClick();
  }
 
  push(obs:any) {
    super.push(obs);
  }
}
