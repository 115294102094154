import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  GetContextMenuItemsParams,
  GridOptions,
  IServerSideGetRowsParams,
  MenuItemDef,
} from "ag-grid-community";
import "ag-grid-enterprise";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { PermissionService } from "src/app/auth/permission.service";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import {
  PERSISTANT_ITEM,
  ServerRequest,
} from "src/app/_models/global.data.model";
import { AssetService } from "src/app/_services/asset.service";
import { DeleteRecordService } from "src/app/_services/delete-record.service";
import { DynamicFormService } from "src/app/_services/dynamic-form.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
var dateFormateChange: any;
var router: Router;
var statusesJS: any = [];

@Component({
  selector: "app-asset-table",
  templateUrl: "./asset-table.component.html",
  styleUrls: ["./asset-table.component.scss"],
})
export class AssetTableComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy, OnChanges
{
  @Input() assetId: any = null;
  @Input() serviceZoneName: any = null;
  @Input() lookupFilter: any = "";
  @Input() displayAction: any = false;
  @Input() searchValue: any;

  @Input() assetTypes: any = null;
  @Input() fromDate: any = null;
  @Input() toDate: any = null;
  @Input() assetStatuses: any = null;

  @Input() filterData: any;

  @Input() siteId: any = null;
  
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions: Partial<GridOptions>;
  overlayNoRowsTemplate: string;

  assets: any;
  actionData: any;
  totalRecord: any;
  currentRecordNumber: any;
  refresher!: Subscription;
  noCustomers: boolean = false;
  noCustomersIcon: boolean = false;
  dateFormat: any;
  loadTable: any = 0;

  sortBy: any;
  sortOrder: any;
  statusesJS: any[] = [];

  constructor(
    private assetService: AssetService,
    private auth: AuthenticationService,
    private dynamicFormService: DynamicFormService,
    private router: Router,
    private errorUtil: ErrorUtil,
    private spinner: NgxSpinnerService,
    private deleteRecordService: DeleteRecordService,
    private util: UtilServiceService,
    private perm: PermissionService
  ) {
    super();
    this.frameworkComponents = {
      buttonRenderer: ButtonRendererComponent,
    };

    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };
    this.overlayNoRowsTemplate =
      "<div class='not-found'><span>Record not found</span> </div>";

    this.actionData = {
      headerName: "Action",
      filter: false,
      sortable: false,
      width: 150,
      headerTooltip: "#",
      headerClass: "marging-auto hide-action-border",
      cellStyle: { "text-align": "center" },
      cellRenderer: "buttonRenderer",
      cellRendererParams: {
        onClick: this.edit.bind(this),
        permissions: {
          update: this.perm.capable("assets", "update"),
          remove: this.perm.capable("assets", "remove"),
        },
        hidden: {
          update: !this.perm.capable("assets", "update"),
          remove: !this.perm.capable("assets", "remove"),
        },
      },
    };
  }

  ngOnInit() {
    this.push(
      this.assetService.getStatuses().subscribe((res: any) => {
        res.body.forEach((element: any) => {
          statusesJS.push(element);
        });
      })
    );

    router = this.router;
    console.log(this.lookupFilter, "========");
    this.dateFormat = localStorage.getItem("date_format");
    dateFormateChange = this.auth.getUserTimezone();

    this.push(
      this.dynamicFormService.getTableColumn("assets").subscribe((res: any) => {
        // console.log(res)
        this.sortBy = res.sortBy
      this.sortOrder = res.sortOrder
     
        var columnDefs: any = this.gridOptions.columnDefs;
        res.tableColumnSetups.forEach((element: any) => {
          this.cellRenderer(element);

          //console.log(element)
          columnDefs.push(element);
          this.gridOptions.api!.setColumnDefs(columnDefs);
        });
        if (this.displayAction) {
          columnDefs.push(this.actionData);
        }
        this.loadTable = 1;
        this.gridOptions.api!.setColumnDefs(columnDefs);
        this.onGridReady(this.gridParams);
      })
    );
  }

  cellRenderer(element: any) {
    var dateFormateChange: any = localStorage.getItem("date_format");
    var authUserTimezone: any = this.auth.getUserTimezone();


    element.headerTooltip = "#";

    if (element.field === "runningNo") {
      element.cellClass = function (params: any) {
        return ["text-1-5 font-weight-bold table_default_color"];
      };
    }

   else if (element.field === "customer.name") {
     // element.headerName = "Customer";
      element.cellClass = function (params: any) {
        return ["text-1-5 font-weight-bold table_default_color"];
      };
    }

 else if (element.field == "assetsStatus.name" || element.field == "assetsStatus.label") {
      element.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.assetsStatus) {
            var data = { colorCode: "", name: "" };
            for (var i = 0; i < statusesJS.length; i++) {
              if (String(params.data.assetsStatus.name) == String(statusesJS[i].name)) {
                data = statusesJS[i];
                return (
                  ' <span class="p-mr-2  p-tag p-component" style="background-color:' +
                  data.colorCode +
                  "50 !important ;color:" +
                  "#212121" +
                  '"><span class="p-tag-value">' +
                  data.name +
                  "</span></span>"
                );
              }
            }
          }
        }
      };
    } else if (element.dataType == 'date') {
      
      element.cellRenderer = function (params: any) {
        //console.log(params,"===",dateFormateChange,"===",timezone)
        
          if (params && params.value) {
            
            return moment.tz(params.value, authUserTimezone).format(dateFormateChange.toUpperCase());
          }else{
            return '-'
          }
        };
    }else if (element.dataType == "text") {
      element.cellRenderer = function (params: any) {
          if (params && params.value && params.value != undefined) {
            return params.value
            }else{
               return '-'
            }
          }  
    }else if (element.dataType == 'datetime') {
      element.cellRenderer = function (params: any) {
              if (params && params.value) {
                return moment.tz(params.value, authUserTimezone) .format("hh:mm A" + ", " + dateFormateChange.toUpperCase());
              }else{
                return '-'
              }
            };
          
        }else if (element.dataType == "boolean") {
          element.cellRenderer = function (params: any) {
              if (params && params.value) {
                return 'Yes'
                }else{
                  return 'No'
                }
              }  
        }
  }
  /********************************** Click Cell Event *****************************/
  edit(e: any) {
    if (e.action === "edit") {
      this.util.setPersistantItem(PERSISTANT_ITEM.ASSET, e.rowData);

      this.router.navigate(["dashboard/assets/updateAsset"], {
        queryParams: { id: e.rowData.id },
      });
    } else if (e.action === "delete") {
      e.rowData.deleteType = "Asset";
      e.rowData.message =
        "Are you sure you want to delete " +
        e.rowData.id +
        " - " +
        e.rowData.name +
        "?";

      console.log(e.rowData);

      this.push(
        this.deleteRecordService.getItems(e.rowData).subscribe((data) => {
          this.onGridReady(this.gridParams);
        })
      );
    }
  }
  onRefreshList() {
    this.onGridReady(this.gridParams);
  }
  /********************************** Search Data **************************/
  quickSearch() {
    this.onGridReady(this.gridParams);
  }
  /********************************** Initilize Grid Table **************************/
  onGridReady(params: any) {
    //console.log(params)
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      console.log("ch1")
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          if (this.loadTable == 1) {
            this.getServerSideData(params);
          }
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);
    // //console.log('Making a server request: ', serverRequest);
    console.log(this.serviceZoneName);
    this.push(
      this.assetService
        .getAssetList(
          this.serviceZoneName,
          this.assetId,
          this.searchValue,
          this.assetTypes,
          this.assetStatuses,
          this.fromDate,
          this.toDate,
          serverRequest,
          this.siteId
        )
        .subscribe(
          (data: any) => {
            console.log("asst table",data.body);
            this.assets = data.body.data;
            this.totalRecord = data.body.total;
            this.setGridData(
              agGridGetRowsParams,
              this.assets,
              data.body.total,
              data.body.status
            );
            //this.spinner.hide()
          },
          (err) => {
            //console.log(err,"===>>>")
            this.errorUtil.setErrorMessage(err);
          }
        )
    );
  }
  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    console.log(resultItems, "===");

    if (status === "NO_DATA") {
      this.noCustomers = false;
      this.noCustomersIcon = true;
      this.gridApi.showNoRowsOverlay();
    } else {
      this.noCustomers = true;
      this.noCustomersIcon = false;
      console.log(this.assets.length)
      if (this.assets.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
        agGridGetRowsParams.successCallback(this.assets, totalCount);
        ///CS-4758 this.gridApi.sizeColumnsToFit();
      }
    }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
     this.parameters = { colId: this.sortBy, sort: this.sortOrder };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }
  /********************************** View Singal Record **************************/

  viewAsset(e: any) {
    console.log(e.colDef, "===", this.filterData);
    if (!this.filterData) {
      if (e.colDef.field === "runningNo" && e.colDef.cellClass !== null) {
        localStorage.setItem("activeIndexAssetView", "0");
        this.router.navigate(["dashboard/assets/view/" + e.data.id], {
          queryParams: { id: e.data.id, serialNo: null },
        });
      }

      if (e.colDef.field === "customer.name" && e.colDef.cellClass !== null && e.data.customer) {
        this.router.navigate(["dashboard/customers/view/" + e.data.customer.id], {
          queryParams: { id: e.data.customer.id },
        });
      }
    } else {
      // //debugger
      this.assetService.setItems(e.data);
    }
  }

      /******************************************** Table Context Menu ******************************************/
      public getContextMenuItems = (params: any) => {
        const col = params.column.colId;
        let url = "";
        let id = 0;
        let result = [
          {
            name: "Open link in new tab",
            action: () => this.util.openItemToNewTab(url),
          },
        ];
    
        if (col === "runningNo") {
          id = params.node.data.id;
          url = `/dashboard/assets/view/${id}?id=${id}`;
        }
  
        if (col === "customer.name" && params.node.data.customer) {
          id = params.node.data.customer.id;
          url = `/dashboard/customers/view/${id}?id=${id}`;
        }
    
        return result;
      };

  // getContextMenuItems(
  //   params: GetContextMenuItemsParams
  // ): (string | MenuItemDef)[] {
  //   var tabData: any | null = params;

  //   if (tabData.column.colId == "runningNo") {
  //     var result: (string | MenuItemDef)[] = [
  //       {
  //         // custom item
  //         name: "Open link in new tab",
  //         action: () => {
  //           console.log(tabData);
  //           var url: any = router.serializeUrl(
  //             router.createUrlTree(["/dashboard/asset/view"], {
  //               queryParams: { id: tabData.id },
  //             })
  //           );
  //           url = url.replace("%23", "#");
  //           window.open(url, "_blank");
  //         },
  //         cssClasses: ["redFont", "bold"],
  //       },
  //     ];
  //     return result;
  //   }
  //   else if (tabData.column.colId == "customer.name") {
  //     var result: (string | MenuItemDef)[] = [
  //       {
  //         // custom item
  //         name: "Open link in new tab",
  //         action: () => {
  //           var url: any = this.router.serializeUrl(
  //             this.router.createUrlTree(["/dashboard/customers/view/" + tabData.customer.id], {
  //               queryParams: { id: tabData.customer.id },
  //             })
  //           );
  //           url = url.replace("%23", "#");
  //           window.open(url, "_blank");
  //         },
  //         cssClasses: ["redFont", "bold"],
  //       },
  //     ];
  //     return result;
  //   } 
  //   {
  //     return [];
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes, "====");
    //if(changes["searchValue"]!= undefined && changes["assetStatuses"]!= undefined){
    console.log("we are in");
    this.onGridReady(this.gridParams);
    //}
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
  push(obs:any) {
    super.push(obs);
  }
}
