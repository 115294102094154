import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { VehicleService } from "src/app/_services/vehicle.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { GridOptions } from "ag-grid-community";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "../../table-header-tool-tip/table-header-tool-tip.component";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";

@Component({
  selector: "app-custella-vehicle",
  templateUrl: "./custella-vehicle.component.html",
  styleUrls: ["./custella-vehicle.component.scss"],
})
export class CustellaVehicleComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Output() vehicleSelector = new EventEmitter();
  @Output() closeVehicleView = new EventEmitter();
  @Input() notInVehicleIds: any = "";

  public filterToggle = false;
  public totalRecord: any;

  dateConfig: any;
  dateRange: any;
  customers: any[] = [];
  filter: any = {
    OrderStatus: null,
    customerId: null,
    salesOrderId: null,
    orderType: null,
    fromDate: "",
    toDate: "",
    splitType: null,
  };
  deliveryOrders: any[] = [];
  deliveryOrders1: any[] = [];
  deliveryOrder = [];
  public gridOptions!: Partial<GridOptions>;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  @Input() searchValue: any;
  gridParams: any;
  startFrom: any;
  parameters: any;
  nodeliveryOrder: boolean = false;
  selectedRange: any;
  pageSize: any = 25;
  maxDate = new Date();
  overlayNoRowsTemplate: any;
  note: any;
  actionData: any;
  statuses: any;

  constructor(
    private router: Router,
    private vehicleService: VehicleService,
    private errorUtil: ErrorUtil,
    private util: UtilServiceService
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {}

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      paginationPageSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
      tooltipShowDelay: 100,
    };

    this.columnDefs = [
      {
        headerName: "Vehicle Name",
        field: "name",
        width: 160,
        headerTooltip: "#",
        cellClass: function (params: any) {
          return ["table_default_color"];
        },
      },
      {
        headerName: "Vehicle Number",
        field: "vehicleNumber",
        width: 110,
        headerTooltip: "#",
      },
      {
        headerName: "Vehicle Type",
        field: "vehicleType.name",
        width: 173,
        headerTooltip: "#",
      },
      {
        headerName: "Depot",
        field: "depot.name",
        width: 125,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params.data) {
            if (params.data.depot) {
              return params.data.depot.name;
            }
          }
        },
      },
      {
        headerName: "Default Driver",
        field: "defaultDriver.user.firstName",
        width: 194,
        headerTooltip: "#",
      },
      {
        headerName: "Chassis Number",
        field: "chassisNumber",
        width: 140,
        headerTooltip: "#",
      },
      {
        headerName: "Operation Status",
        field: "active",
        width: 94,
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params && params.data && params.data.active) {
            return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
          } else {
            return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
          }
        },
      },
      {
        headerName: "Status",
        field: "vehicleStatus",
        headerClass: "marging-auto hide-action-border",
        width: 150,
        cellStyle: { "text-align": "left" },
        headerTooltip: "#",
        cellRenderer: function (params: any) {
          if (params && params.data && params.data.vehicleStatus == "DEPOT") {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #C8E6C9 !important ;color: #C8E6C9"><span class="p-tag-value text-dark">' +
              params.data.vehicleStatus.charAt(0).toUpperCase() +
              params.data.vehicleStatus.slice(1).toLowerCase() +
              "</span></span>"
            );
          } else if (
            params &&
            params.data &&
            params.data.vehicleStatus == "MAINTENANCE"
          ) {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #D77D7D !important ;color: #D77D7D"><span class="p-tag-value text-dark">' +
              params.data.vehicleStatus.charAt(0).toUpperCase() +
              params.data.vehicleStatus.slice(1).toLowerCase() +
              "</span></span>"
            );
          } else if (
            params &&
            params.data &&
            params.data.vehicleStatus == "ENROUTE"
          ) {
            return (
              '<span class="p-mr-2 p-tag p-component" style="background-color: #edc054 !important ;color: #edc054"><span class="p-tag-value text-dark">' +
              params.data.vehicleStatus.charAt(0).toUpperCase() +
              params.data.vehicleStatus.slice(1).toLowerCase() +
              "</span></span>"
            );
          }
        },
      },
    ];

    this.defaultColDef = {
      enableRowGroup: true, //ag-Grid-Enterprise
      enablePivot: true, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      suppressHorizontalScroll: true,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      '<span style="padding: 10px; font-size: 14px;">Record not found</span>';
  }

  /******************************************** Init ******************************************/
  private init() {}

  quickSearch() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** On Grid Ready ******************************************/
  public onGridReady(params: any) {
    //console.log(params)
    // this.spinner.show();
    if (params) {
      this.gridParams = params;
      this.searchValue = this.searchValue ? this.searchValue : "";
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      var dataSource = {
        getRows: (params: any) => {
          this.startFrom = params.startRow;
          if (params.sortModel.length <= 0) {
            this.parameters = { colId: "vehicleNumber", sort: "asc" };
            params.sortModel.push(this.parameters);
          }

          this.push(
            this.vehicleService
              .getVehiclesList(
                null,
                this.searchValue,
                null,
                null,
                params,
                this.notInVehicleIds,
                true,
                null
              )
              .subscribe((data: any) => {
                this.gridApi.hideOverlay();

                if (data.status === "NO_DATA") {
                  this.nodeliveryOrder = true;
                } else {
                  this.nodeliveryOrder = false;
                  this.deliveryOrders = data.data;
                  this.deliveryOrders.forEach((element) => {
                    if (element.deliveryOrderLineItemVMS) {
                      this.note = [];
                      var bay = [];
                      element.deliveryOrderLineItemVMS.forEach((ele: any) => {
                        ele.deleveryOrderLineItemPackages.forEach(
                          (pack: any) => {
                            this.note.push(
                              "(" + pack.quantity + ")" + "-" + pack.name
                            );
                          }
                        );
                      });

                      if (element.deliveryOrderLineItemVMS.length > 1) {
                        for (
                          var i = 1;
                          i <= element.deliveryOrderLineItemVMS.length - 1;
                          i++
                        ) {
                          if (
                            element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem &&
                            !element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem.dropOffBay
                          ) {
                            bay.push(
                              element.deliveryOrderLineItemVMS[i]
                                .deleveryOrderLineItem.dropOffAddress
                            );
                          } else if (
                            element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem &&
                            element.deliveryOrderLineItemVMS[i]
                              .deleveryOrderLineItem.dropOffBay
                          ) {
                            bay.push(
                              element.deliveryOrderLineItemVMS[i]
                                .deleveryOrderLineItem.dropOffBay.name
                            );
                          }
                        }
                      }
                    }
                    element.tooltipValue = bay;
                    element.note = this.note;
                  });
                  if (this.deliveryOrders.length === 0) {
                    this.gridApi.showNoRowsOverlay();

                    this.totalRecord = 0;

                    params.successCallback([], 0);
                  } else {
                    this.totalRecord = data.total;

                    params.successCallback(this.deliveryOrders, data.total);
                  }
                }
                // this.spinner.hide();
              })
          );
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /******************************************** On Refresh ******************************************/
  public refresh() {
    this.onGridReady(this.gridParams);
  }

  /******************************************** View Delivery Order ******************************************/
  public viewDeliveryOrder(e: any) {
    this.vehicleSelector.emit(e.data);
  }

  closeDO() {
    this.closeVehicleView.emit(false);
  }

    /******************************************** Right Click Context Menu ******************************************/
    public getContextMenuItems = (params: any) => {
      params.node.data.field = params.column.colId;
      let url = "";
      let data = "";
  
      let results = [];
  
      if (params.column.colId == "name") {
        const data = params.node.data;
  
        results.push({
          name: "Open link in new tab",
          action: () => this.redirectToNewTab(data),
        });
      }
  
      return results;
    };
  
    /******************************************** Redirect To ******************************************/
    private redirectToNewTab(data: any) {
      let url;
      if (data.field === "name") {
        url = this.router.serializeUrl(
          this.router.createUrlTree(
            ["dashboard/vehicles/view/" + data.id],
            {
              queryParams: { id: data.id },
            }
          )
        );
      }
  
      if (url) {
        url = url.replace("%23", "#");
        this.util.openItemToNewTab(url);
      }
    }

    push(obs:any) {
      super.push(obs);
    }
}
