<div>
  <div class="flex-between">
    <i class="pi pi-times w-24px dialog-close-icon" id="btn_JobOrdersClose" [pTooltip]="'close'|translate" tooltipPosition="left"  (click)="closeTable()"></i>
  </div>
  <div class="position-relative text-start">
    <span class="title" id="label_JobOrdersSelectFromList">{{"select_job_order_from_list" | translate}}</span>
    <div class="d-flex align-items-center mt-2">
      <span class="p-input-icon-left w-100 py-2">
        <i class="pi pi-search"></i>
        <input type="text" pInputText autocomplete="off" id="input_JobOrdersSelectFromListField"[placeholder]="'type_job_order' | translate" [(ngModel)]="searchValue" />
      </span>
    </div>
    <div class="height-42-stat border">
      <app-jo-table [searchValue]="searchValue"></app-jo-table>
    </div>
  </div>
</div>