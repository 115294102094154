import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { TicketsService } from "src/app/_services/tickets.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { Sla, SlaForm } from "./SlaResponse";
import { JobOrdersService } from "src/app/_services/job-orders.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-sla-response",
  templateUrl: "./sla-response.component.html",
  styleUrls: ["./sla-response.component.scss"],
})
export class SlaResponseComponent
  extends SubscriptionUtil
  implements OnInit, OnChanges, OnDestroy
{
  @Input() id = null;
  @Input() isJobOrder = false;

  currentValiolationValue = 10;
  ticketsId: any;
  slaForms: Array<Sla> = [];
  violationSoonSlaEndTime: any;
  timezone: any;
  dateFormat: any;

  //timers
  timeOutForProgress: any = null;
  timerForProgress: any = null;

  constructor(
    private ticketsService: TicketsService,
    private joService: JobOrdersService,
    private util: UtilServiceService,
    private auth: AuthenticationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes && changes.id.currentValue !== "") ||
      changes.id.currentValue !== null
    ) {
      if (this.timeOutForProgress) {
        clearTimeout(this.timeOutForProgress);
      }
      if (this.timerForProgress) {
        clearInterval(this.timerForProgress);
      }
      let $this = this;
      this.timeOutForProgress = setTimeout(() => {
        $this.getFirstResponce(this.count);
      }, 500);
    }
  }

  ngOnDestroy(): void {
    if (this.timeOutForProgress) {
      clearTimeout(this.timeOutForProgress);
    }
    if (this.timerForProgress) {
      clearInterval(this.timerForProgress);
    }

    super.ngOnDestroy();
  }

  private init() {
    this.timezone = this.auth.getUserTimezone();
    this.dateFormat = localStorage.getItem("date_format");

    this.getFirstResponce(this.count);
  }

  public getFirstResponce(callback: any) {
    if (this.timerForProgress) {
      clearInterval(this.timerForProgress);
    }
    if (this.id !== null) {
      let $this = this;
      const id = String(this.id);

      if (this.isJobOrder) {
        this.push(
          this.joService.getJobOrderFirstResponse(id).subscribe((res: any) => {
            $this.slaForms = [];
            res.forEach((element: any) => {
              var slaStatusData = element.slaStatus.replace(" ", "_");
              element.slaStatusData = slaStatusData.toUpperCase();
              element["clicked"] = false;
              element.slaStatus = "OPEN";
              element.slaStepViolationDuration = this.mySteps(
                element.slaStepViolationDuration
              );
              $this.slaForms.push(new SlaForm(element, this.util));
              callback($this);
            });
          })
        );
      } else {
        this.push(
          this.ticketsService.getAllFirstResponse(id).subscribe((res: any) => {
            $this.slaForms = [];
            res.forEach((element: any) => {
              var slaStatusData = element.slaStatus.replace(" ", "_");
              element.slaStatusData = slaStatusData.toUpperCase();
              element["clicked"] = false;
              element.slaStatus = "OPEN";
              element.slaStepViolationDuration = this.mySteps(
                element.slaStepViolationDuration
              );
              $this.slaForms.push(new SlaForm(element, this.util));
              callback($this);
            });
          })
        );
      }
    }
  }

  count($this: any) {
    let f = 0;
    $this.timerForProgress = setInterval(() => {
      $this.propagateData(++f);
    }, 1000);
  }

  mySteps(element: any) {
    if (element != null && !isNaN(element)) {
      let timeConversion = this.util.timeConversion(element * 60 * 1000);
      let timeConverData = timeConversion.split("-");
      let val = timeConverData[1];
      return val != "" ? "(" + val + ")" : "";
    } else return "";
  }

  propagateData(f: any) {
    let today: any = new Date();
    // console.log(today);
    this.slaForms.forEach((element: Sla) => {
      var slaStatusData = element.slaStatus.replace(" ", "_");
      element.slaStatusData = slaStatusData.toUpperCase();

      if (element.status == "PAUSED") {
        element.slaStatusData = "ON_HOLD";
      }
      if (element.status == "RUNNING") {
        element.slaStatusData = "IN_PROGRESS";
      }
      element.isPaused = element.status == "PAUSED";

      let violationDateTime: any = new Date(element.violationDateTime);
      // if (element.isPaused) {
      //   violationDateTime.setSeconds(violationDateTime.getSeconds() + 1);
      //   element.violationDateTime = violationDateTime;
      //   let atRiskDateTime: any = new Date(element.atRiskDateTime);
      //   atRiskDateTime.setSeconds(atRiskDateTime.getSeconds() + 1);
      //   element.atRiskDateTime = atRiskDateTime;
      // }
      if (!element.isPaused || f < 2) {
        var diffMs = violationDateTime - today; // milliseconds between now & Christmas
        var diffDays = Math.floor(diffMs / 86400000); // days

        /*================================Change progress text ========================= */
        element.violationdays = diffDays;

        var timeConversion = this.util.timeConversion(diffMs);
        var timeConverData = timeConversion.split("-");
        element.timeConverType = timeConverData[0];
        element.diffMs = timeConverData[1];
        element.statusTest = "before violation";

        if (diffDays < 0) {
          var diffMs = today - violationDateTime; // milliseconds between now & Christmas
          var diffDays = Math.floor(diffMs / 86400000); // days

          element.violationdays = diffDays;
          var timeConversion = this.util.timeConversion(diffMs);
          var timeConverData = timeConversion.split("-");
          element.timeConverType = timeConverData[0];
          element.diffMs = timeConverData[1];
          if (!element.isPaused) {
            element.statusTest = "since violated";
            element.violated = true;
            element.slaStatusData = "VIOLATED";
          }
        }

        this.updateForm(element);

        /*================================Change progress % ========================= */
        let percentage = 100;
        // let todayUnix = new Date().getTime();
        let initDateTimeUnix: any = new Date(element.initDateTime);
        let pause: any = new Date(element.lastPausedAt);
        let dealy = today - pause;
        // conver milliseconds

        let violationDurationInMinSec: any = new Date(
          element.violationDateTime
        );
        if (element.isPaused) {
          violationDurationInMinSec.setTime(
            violationDurationInMinSec.getTime() + dealy
          );
        }
        violationDurationInMinSec =
          violationDurationInMinSec - initDateTimeUnix;
        if (element.slaStatusData != "VIOLATED") {
          let durationBetWeenTodayToStart = today - initDateTimeUnix;
          percentage =
            (durationBetWeenTodayToStart / violationDurationInMinSec) * 100;
          if (percentage > 99 && percentage < 100) percentage = 99;
          if (percentage > 0 && percentage < 1) percentage = 1;
          percentage = Math.round(percentage);
        } else {
          percentage = 100;
        }
        violationDateTime = new Date(element.violationDateTime);
        if (pause - violationDateTime > 0) {
          percentage = 100;
        }
        /*================================Change progress color ========================= */
        // percentage = percentage > 100 ? 100 : percentage;
        element.percentage = percentage;
        if (element.slaStatusData == "IN_PROGRESS") {
          element.colorCode = "#0091E2";
        } else if (element.slaStatusData == "ON_HOLD") {
          element.colorCode = "#FEB934";
          // element.percentage = 100;
        } else if (element.slaStatusData == "VIOLATED") {
          element.colorCode = "#FD715B";
        } else if (element.slaStatusData == "AT_RISK") {
          element.colorCode = "#EF7224";
        }
        // if (element.slaStatus == "Paused") {
        //   element.isPaused = true;
        // }
        // console.log(element);
        this.setProColor(element.percentage, element.id);
      }
    });
  }

  private setProColor(percentage: Number, id: any) {
    let pr = document.getElementById("sla-time2" + id);
    if (pr != null) {
      // pr.style.color = "#fff";
      if (percentage < 50) {
        pr.style.textAlign = "left";
        if (percentage < 2) {
          pr.style.color = "black";
        }
      } else {
        pr.style.textAlign = "right";
        if (percentage <= 98.5) {
          pr.style.color = "black";
        }
      }
    }
  }

  progressBarClass(element: any): string {
    if (element.slaStatusData == "IN_PROGRESS") {
      return "in-progress";
    } else if (element.slaStatusData == "ON_HOLD") {
      return "on-hold";
    } else if (element.slaStatusData == "VIOLATED") {
      return "violated";
    } else if (element.slaStatusData == "AT_RISK") {
      return "at-risk";
    }
    return "";
  }

  private updateForm(item: Sla) {
    let startTime: any =
      item.initDateTime != null ? new Date(item.initDateTime) : 0;
    let completeTime: any =
      item.completedAt != null ? new Date(item.completedAt) : 0;
    let riskTime: any =
      item.atRiskDateTime != null ? new Date(item.atRiskDateTime) : 0;
    let violateTime: any =
      item.violationDateTime != null ? new Date(item.violationDateTime) : 0;
    let now: any = new Date();
    if (item.status != "PAUSED") {
      if (
        (completeTime == 0 || this.isValid(now, completeTime)) &&
        this.isValid(now, violateTime) &&
        this.isValid(now, riskTime)
      ) {
        //#1
        item.risked = item.violated = item.completed = false;
        item.slaStatusData == "IN_PROGRESS";
      } else if (
        completeTime != 0 &&
        this.isValid(completeTime, now) &&
        this.isValid(now, violateTime) &&
        this.isValid(now, riskTime)
      ) {
        //#2
        item.risked = item.violated = false;
        item.completed = true;
        item.status = "ENDED";
        item.slaStatusData == "COMPLETED";
      } else if (
        (completeTime == 0 || this.isValid(now, completeTime)) &&
        this.isValid(now, violateTime) &&
        this.isValid(riskTime, now)
      ) {
        //#3
        item.risked = true;
        item.violated = false;
        item.completed = false;
        item.slaStatusData == "AT_RISK";
      } else if (
        completeTime != 0 &&
        this.isValid(completeTime, now) &&
        this.isValid(now, violateTime) &&
        this.isValid(riskTime, now)
      ) {
        //#4
        item.risked = true;
        item.violated = false;
        item.completed = true;
        item.slaStatusData == "COMPLETED";
      } else if (
        (completeTime == 0 || this.isValid(now, completeTime)) &&
        this.isValid(violateTime, now) &&
        this.isValid(riskTime, now)
      ) {
        //#5
        item.risked = true;
        item.violated = true;
        item.completed = false;
        item.slaStatusData == "VIOLATED";
      } else if (
        completeTime != 0 &&
        this.isValid(completeTime, now) &&
        this.isValid(violateTime, now) &&
        this.isValid(riskTime, now)
      ) {
        //#6
        item.risked = true;
        item.violated = true;
        item.completed = true;
        item.slaStatusData == "COMPLETED";
      }
    } else {
      if (
        (completeTime == 0 || this.isValid(now, completeTime)) &&
        this.isValid(now, violateTime) &&
        this.isValid(now, riskTime)
      ) {
        //#7
        item.risked = false;
        item.violated = false;
        item.completed = false;
      } else if (
        (completeTime == 0 || this.isValid(now, completeTime)) &&
        this.isValid(now, violateTime) &&
        this.isValid(riskTime, now)
      ) {
        //#8
        item.risked = false;
        item.violated = false;
        item.completed = false;
      }
    }

    item.setFields(item);
  }

  private isValid(d1: any, d2: any): boolean {
    return d1 != 0 && d2 != 0 && d2 - d1 > 0;
  }
  push(obs:any) {
    super.push(obs);
  }
}
