<div class="custella-modal">
    <!-- Container -->
    <div class="custella-modal-header flex-align-center" >
        <div class="custella-modal-header-text" id="title_SetJobAssignTaskTempl"> {{ "add_task_template" | translate }} </div>
        <i class="pi pi-times"  [pTooltip]="'close'|translate" tooltipPosition="left"  id="btn_SetJobAssignTaskTemplClose" (click)="modalRef.hide()"></i>
    </div>

    <div class="custella-form-container position-relative d-block w-100 m-0"  style="box-shadow: none;">
        <!-- Container Content -->
        <div class="custella-form-container-title-two-grey fst-italic ps-4" id="title_SetCheckQuestDets" translate>
            <span class="text-12 font-bold">{{'survey_step_one_number'|translate}}&colon;&nbsp;</span>
            <span class="text-12 font-medium">{{'use_below_fields_filter'|translate}}</span>
        </div>
        <div class="custella-form-container-content">
            <div class="custella-content-row mb-3">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" id="label_SetJobAssignTaskSite" translate>{{'site'}}</span>
                    <p-dropdown class="pe-2" id="picklist_SetJobAssignTaskSiteField" [options]="allSites" [placeholder]="'select_site' | translate" optionLabel="siteName" optionValue="id" [(ngModel)]="filter.siteId" [filter]="true"  [showClear]="filter.siteId ? true : false"></p-dropdown>
               

                </div>
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" id="label_SetJobAssignTaskProj" translate>{{'project'}}</span>
                    <p-dropdown  id="picklist_SetJobAssignTaskProjField" [options]="allProjects" [filter]="true" [placeholder]="'select_project' | translate" optionLabel="name" optionValue="id" [(ngModel)]="filter.projectId"  [showClear]="filter.projectId ? true : false"></p-dropdown>
              
                </div>
            </div>
            <div class="custella-content-row mb-3">
                <div class="custella-content-row-input col-6">
                    <span class="custella-content-row-input-title" id="label_SetJobAssignTaskTeam" translate>{{'team'}}</span>
                    <p-dropdown class="pe-2" id="picklist_SetJobAssignTaskTeamField"  [(ngModel)]="filter.teamId" name="status" [options]="teams" optionLabel="name"  optionValue="id" placeholder="Select Teams" ></p-dropdown>
      

                </div>
                <div class="custella-content-row-input col-6">
                   
                </div>
            </div>
          
        </div>
        <div class="custella-form-container-footer mb-0">
            <div class="flex-align-center justify-content-end">
                <button pButton pRipple class="p-button-secondary" (click)="resetSearch()" id="btn_SetJobAssignTaskReset" [label]="'reset'|translate" style="margin-right: 7px;"></button>
                <p-button pRipple (click)="quickSearch()" id="btn_SetJobAssignTaskSearch" [label]="'search'|translate" class="p-button-text">
                </p-button>
            </div>
        </div>
        <!-- Task Information -->
        <div class="custella-form-container-title-two" id="title_SetJobAssignTaskDets" translate>{{'task_list_details'}}</div>
        <div class="custella-form-container-title-two-grey fst-italic ps-4" id="subtitle_SetJobAssignTaskDets" translate>
            <span class="text-12 font-bold">{{'survey_step_two_number'|translate}}&colon;&nbsp;</span>
            <span class="text-12 font-medium">{{'select_task_templ_generated'|translate}}</span>
        </div>
        <div class="custella-form-container-content">
            <div  class="custella-content-row mb-1 pe-0">
                <div class="custella-content-row-input col-12">
                    <span class="p-input-icon-left w-100" [ngClass]="searchValue?.length>0 ? 'p-input-icon-right':''">
                        <i class="pi pi-search me-3" style="width: 10px;"></i>
                        <input type="text" pInputText autocomplete="off" placeholder="Search" id="input_SetJobAssignTaskSearchField"
                            [(ngModel)]="searchValue" class="w-100" name="searchValue" (keyup)="quickSearch()"/>
                       
                        <!-- <i class="pi pi-times-circle" (click)="onSearchClear()"  *ngIf="searchValue?.length>0"></i> -->
                    </span>
                </div>
            </div>
            
            <!-- ag-grid -->
            <div class="custella-form-container-content-table">
                <div class="custella-content-row mt-0 pe-0">
                    <div class="custella-content-row-input col-12">
                        <div style="padding-top: 0px;margin-top: 5px; height: 200px;width: 100%;">
                            <ag-grid-angular #agGrid id="myGrid" style="height: 100%;width:100%"
                                class="ag-theme-balham" 
                                [pagination]=true 
                                [paginationPageSize]="25"
                                [gridOptions]="gridOptions" 
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef" 
                                [suppressRowClickSelection]="true"
                                [rowSelection]="rowSelection"
                                 (rowSelected)="onSelectionChanged($event)"
                                (gridReady)="onGridReady($event)"
                                [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                                [maxConcurrentDatasourceRequests]="-1"
                                [allowContextMenuWithControlKey]="false" ></ag-grid-angular>
                               
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="custella-form-container-footer mb-0">
            <div class="flex-align-center justify-content-end">
                <button  (click)="modalRef.hide()" id="btn_SetJobAssignTaskCancel" class="custella-btn-white text-12 ms-2">{{'cancel' | translate}}</button>
                <p-button styleClass="ms-2 h-32" id="btn_SetJobAssignTaskSubmit" [disabled]="submitTouched" type="button"  [label]="'save' | translate" (click)="addTask()"> </p-button>
            </div>
        </div>
    </div>
</div>
