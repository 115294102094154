// Author: T4professor

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-button-renderer',
  template: `
  <span class="cursor-pointer border rounded p-1 declineBtn" [hidden]="this.permissions.cancel" (click)="onClick($event,'cancel')"> {{ "decline" | translate }} </span>
  <span class="cursor-pointer ms-1 border rounded p-1 acceptBtn" [hidden]="this.permissions.confirm" (click)="onClick($event,'confirm')"> {{ "accept" | translate }} </span>   
  `

    //  <div class="helpdesk-action-container flex"></div>
     
   
     
   






})


export class IconRendererComponent implements ICellRendererAngularComp {
  params!: any;
  permissions: any={confirm:false,cancel:false};
  hidden: any={confirm:false,cancel:false};

 
 

  agInit(params:any): void {
   console.log(params)
    this.params = params;
    this.permissions = this.params.permissions;
    this.permissions = this.params.hidden;

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event : any,action: any) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        action:action
        // ...something
      }
      this.params.onClick(params);

    }
  }
}
