<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_HillsFaultTypeSyst" translate>{{ "fault_type_by_system" }}</span>
        <button class="border rounded-1 px-04 ms-2 btn-32"><img alt="logo" id="btn_HillsFtsRefresh" src="assets\svg\refresh.svg" (click)="$event.stopPropagation(); firstClick()" /></button>
    </div>
    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium"  id="label_HillsHtrFaultType">{{'htr_fault_types' | translate }}</span>
    </div>
    <div class="custella-content-row">
        <div class="col-12">
            <div class="p-3 mb-2" #htrContainer>
                <p *ngIf="multi1.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_HtrNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi1.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [scheme]="colorSchemeHTR"
                        [results]="multi1"
                        [view]="viewHorizontalBar1"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        (window:resize)="resizeChart(htrContainer.offsetWidth, htrContainer.offsetHeight, 'htr')"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsHostelFaultType">{{'hostel_fault_types' | translate }}</span>
    </div>
    <div class="custella-content-row">
        <div class="col-12">
            <div class="p-3 mb-2" #hostelContainer>
                <p *ngIf="multi2.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_HostelNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi2.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar2"
                        [scheme]="colorSchemeHostel"
                        [results]="multi2"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        (window:resize)="resizeChart(hostelContainer.offsetWidth, hostelContainer.offsetHeight, 'hostel')"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsLincorFaultType">{{'lincor_fault_types' | translate }}</span>
    </div>
    <div class="custella-content-row" #lincorContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi3.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_LincorNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi3.length != 0" class="d-flex align-items-center mb-2 chartScroll" style="padding-left: 60px;">
                    <ngx-charts-bar-horizontal [view]="viewHorizontalBar3" [scheme]="colorSchemeLincor" [results]="multi3"
                        [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis" [yAxis]="showYAxis"
                        [legend]="false" [xAxisTickFormatting]="xAxisTickFormattingFn" (window:resize)="resizeChart(lincorContainer.offsetWidth, lincorContainer.offsetHeight, 'lincor')">
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsGetwellFaultType">{{'getwell_fault_types' | translate }}</span>
    </div>
    <div class="custella-content-row" #getWellContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi4.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_GetwellNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi4.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar4"
                        [scheme]="colorSchemeGetwell"
                        [results]="multi4"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        (window:resize)="resizeChart(getWellContainer.offsetWidth, getWellContainer.offsetHeight, 'getwell')"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title rounded-top flex-between">
        <span class="text-14 font-medium" id="title_HillsResolTypeSyst" translate>{{ "resolution_by_system" }}</span>
        <button class="border rounded-1 px-04 ms-2 btn-32"><img alt="logo" src="assets\svg\refresh.svg" (click)="$event.stopPropagation();resolutionClick()" /></button>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsHtrResol">{{'htr_resolution' | translate }}</span>
    </div>
    <div class="custella-content-row" #htrResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi5.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_HtrResolNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi5.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar5"
                        [scheme]="colorSchemeHTR"
                        [results]="multi5"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(htrResoContainer.offsetWidth, htrResoContainer.offsetHeight, 'htr')"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsHostelResol">{{'hostel_resolution' | translate }}</span>
    </div>
    <div class="custella-content-row" #hostelResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi6.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_HostelResolNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi6.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar6"
                        [scheme]="colorSchemeHostel"
                        [results]="multi6"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(hostelResoContainer.offsetWidth, hostelResoContainer.offsetHeight, 'hostel')"

                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsLincorResol">{{'lincor_resolution' | translate }}</span>
    </div>
    <div class="custella-content-row" #lincorResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi7.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_LincorResolNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi7.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar7"
                        [scheme]="colorSchemeLincor"
                        [results]="multi7"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(lincorResoContainer.offsetWidth, lincorResoContainer.offsetHeight, 'lincor')"

                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsGetwellResol">{{'getwell_resolution' | translate }}</span>
    </div>
    <div class="custella-content-row" #getWellResoContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multi8.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_GetwellResolNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multi8.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBar8"
                        [scheme]="colorSchemeGetwell"
                        [results]="multi8"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        [xAxisTickFormatting]="xAxisTickFormattingFn"
                        (window:resize)="resizeChart(getWellResoContainer.offsetWidth, getWellResoContainer.offsetHeight, 'getwell')"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title flex-between">
        <span class="text-14 font-medium" id="title_HillsProduct" translate>
            {{ "product" }}
        </span>
        <button class="border rounded-1 px-04 ms-2 btn-32" id="btn_HillsProductRefresh"><img alt="logo" src="assets\svg\refresh.svg" (click)="$event.stopPropagation();productClick()" /></button>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsProdUsage">{{'product_usage' | translate }}</span>
    </div>
    <div class="custella-content-row" #productContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multiProduct.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_ProdUsageNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multiProduct.length != 0" class="d-flex align-items-center mb-2">
                    <ngx-charts-bar-horizontal
                        [view]="viewHorizontalBarProduct"
                        [scheme]="colorSchemeProduct"
                        [results]="multiProduct"
                        [showXAxisLabel]="showXAxisLabel"
                        [showYAxisLabel]="showYAxisLabel"
                        [xAxis]="showXAxis"
                        [yAxis]="showYAxis"
                        [legend]="false"
                        (window:resize)="resizeChart(productContainer.offsetWidth, productContainer.offsetHeight, 'product')"
                    >
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="custella-form-container left-right-space rounded-2">
    <div class="custella-form-container-title rounded-top flex-between">
        <span class="text-14 font-medium" id="title_HillsTechJobs" translate>{{ "technician_jobs" }}</span>
        <button class="border rounded-1 px-04 ms-2 btn-32" id="btn_HillsTechJobsRefresh"><img alt="logo" src="assets\svg\refresh.svg" (click)="$event.stopPropagation();technicianClick()" /></button>
    </div>

    <div class="custella-form-container-title-two-grey">
        <span class="text-14 font-medium" id="label_HillsTechJobsByTech">{{'jobs_by_technician' | translate }}</span>
    </div>
    <div class="custella-content-row" #joTechContainer>
        <div class="col-12">
            <div class="p-3 mb-2">
                <p *ngIf="multiTechnician.length == 0" class="mb-3 text-4" style="margin-left: 18px;" id="label_TechJobsNoRecordsFound">{{'no_records_found' | translate}}</p>
                <div *ngIf="multiTechnician.length != 0" class="d-flex align-items-center mb-2 chartScroll" style="padding-left: 35px;">
                    <ngx-charts-bar-horizontal [view]="viewHorizontalBarTechnician" [scheme]="colorSchemeProduct"
                        [results]="multiTechnician" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel" [xAxis]="showXAxis"
                        [yAxis]="showYAxis" [legend]="false" (window:resize)="resizeChart(joTechContainer.offsetWidth, joTechContainer.offsetHeight, 'jotech')">
                    </ngx-charts-bar-horizontal>
                </div>
            </div>
        </div>
    </div>
</div>
