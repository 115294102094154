import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/authentication.service';
@Component({
  selector: 'app-system-information',
  templateUrl: './system-information.component.html',
  styleUrls: ['./system-information.component.scss']
})
export class SystemInformationComponent implements OnInit, OnChanges {

  @Input() createdByUser: any
  @Input() createdDate: any
  @Input() lastModifiedDate: any
  @Input() modifiedByUser: any

  timezone: any;
  dateFormat: any
  
  constructor(
    private auth: AuthenticationService
  ) { }


  ngOnChanges(changes: SimpleChanges): void {
    // if(!this.createdByUser.fullName){
    //   this.createdByUser.fullName = this.createdByUser.firstName + " " + this.createdByUser.lastName
    // }
    // else if(this.createdByUser){
    //   this.createdByUser.fullName = this.createdByUser
    // }
    // if(!this.modifiedByUser.fullName){
    //   this.modifiedByUser.fullName = this.modifiedByUser.firstName + " " + this.modifiedByUser.lastName
    // }
    // else if(this.modifiedByUser){
    //   this.modifiedByUser.fullName = this.modifiedByUser;
    // }
    console.log(this.createdByUser)
    console.log(this.modifiedByUser )
    this.timezone = this.auth.getUserTimezone();
    this.dateFormat = localStorage.getItem('date_format');
  }

  ngOnInit(): void {

    console.log()

  }

}
