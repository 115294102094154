export class ServerRequest {
  filterModel: any;
  sortModel: any;
  startRow: number | undefined;
  pageSize: number | undefined;
}
export class SortModel {
  colId: any;
  sort: any;
}

export class LoadingBayModel {
  monday: boolean = true;
  tuesday: boolean = true;
  wednesday: boolean = true;
  thursday: boolean = true;
  friday: boolean = true;
  saturday: boolean = false;
  sunday: boolean = false;

  mondayAllDay: boolean = false;
  tuesdayAllDay: boolean = false;
  wednesdayAllDay: boolean = false;
  thursdayAllDay: boolean = false;
  fridayAllDay: boolean = false;
  saturdayAllDay: boolean = false;
  sundayAllDay: boolean = false;

  mondayStart: any | null;
  mondayEnd: any | null;
  tuesdayStart: any | null;
  tuesdayEnd: any | null;
  wednesdayStart: any | null;
  wednesdayEnd: any | null;
  thursdayStart: any | null;
  thursdayEnd: any | null;
  fridayStart: any | null;
  fridayEnd: any | null;
  saturdayStart: any | null;
  saturdayEnd: any | null;
  sundayStart: any | null;
  sundayEnd: any | null;
}

export enum AppEvents {
  "ADD_CONVERSATION_MODAL_SUCCESS" = "ADD_CONVERSATION_MODAL_SUCCESS",
  "CREATE_JOB_ORDER_TICKET_SUCCESS" = "CREATE_JOB_ORDER_TICKET_SUCCESS",
  "REFRESH_JOB_ORDER_TABLE" = "REFRESH_JOB_ORDER_TABLE",
  "CREATE_JOB_ORDER_TICKET_ESCALATE_SUCCESS" = "CREATE_JOB_ORDER_TICKET_ESCALATE_SUCCESS",
  "PASS_JO_PRODUCT_TO_JO_PRODUCT_CREATE" = "PASS_JO_PRODUCT_TO_JO_PRODUCT_CREATE",
  "REFRESH_TASK_TABLE" = "REFRESH_TASK_TABLE",
  "ASSIGN_TASK" = "ASSIGN_TASK",
  "PASS_JO_DATA_TO_PART_ORDER_CREATE" = "PASS_JO_DATA_TO_PART_ORDER_CREATE",
  "PASS_TICKET_DATA" = "PASS_TICKET_DATA",
  "REFRESH_CONTRACT_DATA" = "REFRESH_CONTRACT_DATA",
  "REFRESH_SALES_ORDER_DETAILS" = "REFRESH_SALES_ORDER_DETAILS",
  "REFRESH_VEHICLE_DETAILS" = "REFRESH_VEHICLE_DETAILS",
  "REFRESH_LAST_LOCATION" = "REFRESH_LAST_LOCATION",
  "UPDATE_ESTIMATE_DISTANCE" = "UPDATE_ESTIMATE_DISTANCE",
  "CLOSE_DO_NOTES_ATTACHMENTS" = "CLOSE_DO_NOTES_ATTACHMENTS",
  "REFRESH_DO" = "REFRESH_DO",
  "REFRESH_SALES_ORDER_PRODUCT" = "REFRESH_SALES_ORDER_PRODUCT",
  "REFRESH_VIEW_USER_GROUP" = "REFRESH_VIEW_USER_GROUP",
  "TERMINATE_FULLCALENDAR"= "TERMINATE_FULLCALENDAR"
}

export enum HELPDESK_APP_EVENTS {
  GET_TICKET_MENU = "GET_TICKET_MENU",
  ON_SEARCH_RESULT = "ON_SEARCH_RESULT",
  ON_HELPDESK_TICKET_ASSIGN = "ON_HELPDESK_TICKET_ASSIGN",
  ON_HELPDESK_TICKET_CLAIM = "ON_HELPDESK_TICKET_CLAIM",
  ON_HELPDESK_TICKET_ASSIGNEE = "ON_HELPDESK_TICKET_ASSIGNEE",
  ON_TAB_CHANGE = "ON_TAB_CHANGE",
  CLEAR_HELPDESK_COMPONENT = "CLEAR_HELPDESK_COMPONENT",
  REMOVE_HELPDESK_TABLE_ROW = "REMOVE_HELPDESK_TABLE_ROW",
  UNDO_TICKET = "UNDO_TICKET",
  ON_UPDATE_TICKET_MENU_COUNT = "ON_UPDATE_TICKET_MENU_COUNT",
  ON_HELPDESK_ROUTE_CHANGE = "ON_HELPDESK_ROUTE_CHANGE",
  ON_TICKET_CLICK = "ON_TICKET_CLICK",
  ON_VIEW_TICKET = "ON_VIEW_TICKET",
  ON_DELETE_TICKET = "ON_DELETE_TICKET",
  ON_CLOSE_TAB_TICKET = "ON_CLOSE_TAB_TICKET",

  // RELATED BUT FAR
  ON_CLAIM_SUCCESS = "ON_CLAIM_SUCCESS"
}

export enum COMPONENT_CREATION {
  "HELPDESK_ASSIGNEE_SELECTION" = "HELPDESK_ASSIGNEE_SELECTION",
  "HELPDESK_TOAST" = "HELPDESK_TOAST",
  "TICKET_VIEW" = "TICKET_VIEW",
}

export enum PERSISTANT_ITEM {
  "VISITS" = "VISITS",
  "PRODUCTIVITY" = "PRODUCTIVITY",
  "ATTENDANCE" = "ATTENDANCE",
  "OVERTIME" = "OVERTIME",
  "JOB_ORDER" = "JOB_ORDER",
  "ASSET" = "ASSET",
  "ASSET_ACCESSORIES" = "ASSET_ACCESSORIES",
  "CONTRACT_ACCESSORIES" = "CONTRACT_ACCESSORIES",
  "CONTRACT_ASSETS" = "CONTRACT_ASSETS",
  "CONTRACT_MAINTENANCE" = "CONTRACT_MAINTENANCE",
  "CONTRACTS" = "CONTRACTS",
  "CONTRACT_ITEM" = "CONTRACT_ITEM",
  "SCHEDULE_MAINTENANCE" = "SCHEDULE_MAINTENANCE",
  "MAINTENACE" = "MAINTENACE",
  "SALES_ORDER" = "SALES_ORDER",
  "SALES_ORDER_PRODUCT_1" = "SALES_ORDER_PRODUCT_1",
  "SALES_ORDER_PRODUCT_2" = "SALES_ORDER_PRODUCT_2",
  "PRODUCT" = "PRODUCT",
  "ADJUST_INVENTORY" = "ADJUST_INVENTORY",
  "CHECK_AVAILABILITY" = "CHECK_AVAILABILITY",
  "DO_STATUS" = "DO_STATUS",
  "VEHICLE" = "VEHICLE",
  "VEHICLE_TYPE" = "VEHICLE_TYPE",
  "VEHICLE_FUEL" = "VEHICLE_FUEL",
  "TRAILER" = "TRAILER",
  "DELIVERY_ORDER" = "DELIVERY_ORDER",
  "PACKAGE_TYPE" = "PACKAGE_TYPE",
  "PACKAGE_SUB_TYPE" = "PACKAGE_SUB_TYPE",
  "ROLE" = "ROLE",
  "ACCESS" = "ACCESS",
  "VEHICLE_GROUP" = "VEHICLE_GROUP",
  "VIEW_VEHICLE_GROUP" = "VIEW_VEHICLE_GROUP",
  "VEHICLE_CHECKLIST" = "VEHICLE_CHECKLIST",
  "SUBCONTRACTOR" = "SUBCONTRACTOR",
  "DEPOT" = "DEPOT",
  "USER_GROUPS" = "USER_GROUPS",
  "USER_SKILLS" = "USER_SKILLS",
  "LEAVE" = "LEAVE",
  "SHIFTS" = "SHIFTS",
  "VIEW_SHIFTS" = "VIEW_SHIFTS",
  "DEVICE" = "DEVICE",
  "PLANING_HUB" = "PLANING_HUB",
  "SITE_TYPE" = "SITE_TYPE",
  "SITE_AREA" = "SITE_AREA",
  "SITE_CATEGORY" = "SITE_CATEGORY",
  "SITE_SUB_CATEGORY" = "SITE_SUB_CATEGORY",
  "PROJECT_TYPE" = "PROJECT_TYPE",
  "PROJECT_CATEGORY" = "PROJECT_CATEGORY",
  "SITES" = "SITES",
  "PROJECTS" = "PROJECTS",
  "CONTACTS" = "CONTACTS",
  "TICKETS" = "TICKETS",
  "SERVICE_ZONES" = "SERVICE_ZONES",
  "JOB_ORDER_REFRESH" = "JOB_ORDER_REFRESH",
  "PAYMENT" = "PAYMENT",
  "JOB_ORDER_TEMPLATE"="JOB_ORDER_TEMPLATE",
  "MS_VISIT"="MS_VISIT"

}

export enum ChubbDashboardAppEvents {
  REFRESH_PANEL_ONE = "REFRESH_PANEL_ONE",
  REFRESH_PANEL_TWO = "REFRESH_PANEL_TWO",
  REFRESH_PANEL_THREE = "REFRESH_PANEL_THREE",
  REFRESH_TASK_BY_TECHNICIAN = "REFRESH_TASK_BY_TECHNICIAN",
  DOWNLOAD_TECHNICIAN_DATA = "DOWNLOAD_TECHNICIAN_DATA",

  DATA_PASS_BY = "DATA_PASS_BY"
};

export class faultRequest {
  id: any;
  name: any;
  selected: boolean = false;
  code : any;
}
export class markerData {
    latitude : any;
    longitude : any;
    lat : any;
    lng : any;
    icon : any;
    startTime : any;
    endTime : any;
    type: any;
    load: any;
    quantity: any;
    orderNumber: any;
    orderNumberDate : any;
    orderName: any;
    deliveryOrderWay: any;
    lineItemNumber: any;
    orderStatus : any;
    id: any;
    vehicleId: any;
    isOpen: any;
    lineItemId: any;
    customer: any;
    vehicleData:  any;
    depot: any;
    label: any;
    contactName:any;
    contactNumber:any
    deliveryOrder: any;
    deliveryOrderLineItemVMs:any
}
export class DeliveryOrderStatus {
 
  public static statusList: any=[]
  public static orderWayList: any=[]
  public static orderTypeList: any=[]

}