import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  ColDef,
  GridApi,
  GridOptions,
  GridParams,
  IGetRowsParams,
} from "ag-grid-community";
import { ButtonRendererComponent } from "src/app/renderer/button-renderer.component";
import { TableHeaderToolTipComponent } from "src/app/shared/table-header-tool-tip/table-header-tool-tip.component";
import { ServerRequest } from "src/app/_models/global.data.model";
import { SiteService } from "src/app/_services/site.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { DynamicTableColumnsService } from "src/app/_services/dynamic-table-column.service";
import { Global } from "src/app/_utilities/global";

@Component({
  selector: "app-custella-sites-table",
  templateUrl: "./custella-sites-table.component.html",
  styleUrls: ["./custella-sites-table.component.scss"],
})
export class CustellaSitesTableComponent
extends SubscriptionUtil
implements OnInit, OnChanges, OnDestroy
{
// table
public frameworkComponents!: any;
public gridOptions!: GridOptions;
public defaultColDef!: ColDef;
public columnDefs: any[] = [];
private gridParams!: GridParams;
private gridApi!: GridApi;
public overlayNoRowsTemplate =
  '<span style="padding: 10px; font-size: 14px;">Record not found</span>';

public totalRecords: any;

public noData: boolean | null = null;
public isFilterViewToggle = false;

@Input() searchValue = "";
@Input() customerId: any;
public filters = this.returnFilterObject();

@Input() additionalData: any;

loadTable: any = 0;
public allSiteTypes: any[] = [];
public allServiceZones: any[] = [];
public allSiteCategorys: any[] = [];
public allGeofences: any[] = [];
public allProjects: any[] = [];

ids = {
  customerId: null,
};

private filterAPILoaded = false;

private deleteHasPushed = false;
sortBy: any;
sortOrder: any;

constructor(
  private util: UtilServiceService,
  private sitesService: SiteService,
  private errorUtil: ErrorUtil,
  private dynamicTableColumnsService:DynamicTableColumnsService
) {
  super();

  this.preInit();
}

ngOnInit(): void {
  this.init();
}

ngOnChanges(changes: SimpleChanges): void {
  console.log(changes)
  if (
   changes &&
      (changes.searchValue &&
      changes.searchValue.previousValue !== changes.searchValue.currentValue &&
      changes.searchValue.currentValue.length > 2)
  ) {
    console.log(changes)
    //debugger;
    this.refresh();
  }
  if(this.searchValue == "") this.refresh()

  if (
    changes.additionalData &&
    changes.additionalData.currentValue !==
      changes.additionalData.previousValue
  ) {
    //debugger;
    console.log("additionDataChanges")
    this.refresh();
  }

  if (
    changes.customerId &&
    changes.customerId.currentValue !==
      changes.customerId.previousValue
  ) {
      this.ids.customerId = this.customerId
  }

}

ngOnDestroy(): void {
  super.ngOnDestroy();
}

/******************************************** Pre Init ******************************************/
private preInit() {
  this.frameworkComponents = {
    buttonRenderer: ButtonRendererComponent,
  };

  this.gridOptions = {
    cacheBlockSize: 25,
    rowModelType: "infinite",
    sortingOrder: ["desc", "asc"],
    onGridSizeChanged: (params: any) => {
      params.api.sizeColumnsToFit();
    },
    tooltipShowDelay: 100,
  };

  this.defaultColDef = {
    enableRowGroup: false, //ag-Grid-Enterprise
    enablePivot: false, //ag-Grid-Enterprise
    enableValue: true, //ag-Grid-Enterprise
    sortable: true,
    resizable: true,
    filter: false,
    tooltipComponent: TableHeaderToolTipComponent,
  };

  this.sitesService.getListColumn("site").subscribe((resp) => {
    if (resp && resp.tableColumnSetups) {
      this.sortBy = resp.sortBy
      this.sortOrder = resp.sortOrder
      this.configureColumns(resp.tableColumnSetups);
      
     
      this.gridOptions.api!.setColumnDefs(this.columnDefs);
    }
    this.loadTable = 1;
    this.onGridReady(this.gridParams);
    console.log(this.loadTable,"===>>>")
  });
}

/******************************************** Init ******************************************/
private init() {}

/******************************************** On Destroy ******************************************/
// private onDestroy() {
//   super.ngOnDestroy();
// }

/******************************************** Configure Columns ******************************************/
private configureColumns(tableColumns: any[]) {
  tableColumns.forEach((tc) => {
    const column = {
      field: tc.field,
      headerName: tc.headerName,
      hide: tc.hide,
      cellClass: tc.cellClass,
      width: 200,
      headerTooltip: "#",
      cellRenderer: <any>null,
      headerComponentParams: <any>{}
    };

    if (tc.field === "siteNumber") {
      column.headerComponentParams =  Global.setHeaderComponentParams(column,'Tbl_HeadSitNo'),
      column.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId, params.data.siteNumber)
        }
      };
      
    }

    if (tc.field === "customer.name") {
      column.headerComponentParams =  Global.setHeaderComponentParams(column,'Tbl_HeadSiteCust'),
      column.cellRenderer = function (params: any) {
        if(params.data){
          return  Global.setCellRenderer(params,params.data.rowId2, params.data.customer?.name)
        }
      };
      
    }

    // if (tc.field === "customer.name") {
    //   column.cellClass = ["font-weight-bold table_default_color"];

    //   if (this.additionalData?.data?.from === "customer") {
    //     column.hide = true;
    //   }
    // }

    // if (tc.field === "service_zone.name") {
    //   column.field = "serviceZone.name";
    // }

    // if (tc.field === "type.name") {
    //   column.field = "type.label";
    // }

    // if (tc.field === "category.name") {
    //   column.field = "category.label";
    // }

    // if (tc.field === "GeoFence.geoFenceName") {
    //   column.field = "geoFence.geoFenceName";
    // }

    if (tc.field === "active") {
      column.cellRenderer = function (params: any) {
        if (params.data) {
          if (params.data.active) {
            return '<span class="p-mr-2  p-tag p-component p-tag-success" ><span class="p-tag-value text-dark">Active</span></span>';
          } else {
            return '<span class="p-mr-2  p-tag p-component p-tag-danger" ><span class="p-tag-value text-dark">Inactive</span></span>';
          }
        }
      };
    }

    // if (tc.field === "site_status.name") {
    //   column.hide = true;
    // }

    // if (tc.field === "siteStatus.name") {
    //   column.hide = true;
    // }

    this.columnDefs.push(column);
  });
}



/******************************************** On Table Ready ******************************************/
public onGridReady(params: any) {
  if (params) {
    
   console.log(this.loadTable)
    this.gridParams = params;

    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();

    var dataSource = {
      getRows: (params: IGetRowsParams) => {
       if (this.loadTable == 1) {
          this.getServerSideData(params);
         }
      },
    };

    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDatasource(dataSource);
  }
}

/******************************************** Get Data ******************************************/
private getServerSideData(agGridGetRowsParams: IGetRowsParams) {
  this.gridApi.hideOverlay();
  const serverRequest = this.getRequestParams(agGridGetRowsParams);
  var count: any=serverRequest.startRow;

  if (this.additionalData && this.additionalData.customer) {
    this.ids.customerId = this.additionalData.customer.id;
  }

  let from = null;

  if (
    this.additionalData &&
    this.additionalData.data &&
    this.additionalData.data.from === "project"
  ) {
    this.filters.project = Number(this.additionalData.data.id);
  }

  if (
    this.additionalData &&
    this.additionalData.data &&
    this.additionalData.data.from
  ) {
    from = this.additionalData.data.from;
  }

  if (
    this.filters.category_type ||
    this.filters.geofence ||
    this.filters.service_zone ||
    this.filters.type
  ) {
    from = null;
  }

  if (this.searchValue && this.searchValue.length > 0) {
    from = null;
  } else {
    from = this.additionalData?.data?.from;
  }

  this.sitesService
    .getAllSites(
      serverRequest,
      this.searchValue,
      this.ids.customerId,
      this.filters.project,
      this.filters.type,
      this.filters.service_zone,
      this.filters.category_type,
      this.filters.geofence,
      from
    )
    .subscribe(
      (resp) => {
        console.log(resp);

        if (resp) {
          resp.data.forEach((element: any) => {
            count++
            element.rowId = 'Tbl_RecSitNo' + count
            element.rowId2 = 'Tbl_RecSiteCust' + count
            element.buttonEdit = 'Btn_SitEdit' + count
            element.buttonDelete = 'Btn_SitDel' + count
          });
          
          this.setGridData(
            agGridGetRowsParams,
            resp.data,
            resp.total,
            resp.status
          );
        }
      },
      (err) => {
        this.errorUtil.setErrorMessage(err);
      }
    );
}

/******************************************** Get Params ******************************************/
private getRequestParams(agGridRequest: any): ServerRequest {
  if (agGridRequest.sortModel.length <= 0) {
    const parameters =  { colId: this.sortBy, sort: this.sortOrder };
    agGridRequest.sortModel.push(parameters);
  }

  return {
    startRow: agGridRequest.startRow,
    pageSize: 25,
    filterModel: null,
    sortModel: agGridRequest.sortModel,
  };
}

/******************************************** Set Data ******************************************/
private setGridData(
  agGridGetRowsParams: IGetRowsParams,
  resultItems: any[],
  totalCount: number,
  status: string
) {
  this.gridApi.hideOverlay();

  this.totalRecords = totalCount;

  if (status === "NO_DATA") {
    this.noData = true;
    this.gridApi.showNoRowsOverlay();
  } else {
    this.noData = false;

    if (resultItems.length === 0) {
      this.gridApi.showNoRowsOverlay();
    } else {
      agGridGetRowsParams.successCallback(resultItems, totalCount);
    }

    ///CS-4758 this.gridApi.sizeColumnsToFit();
  }

}

/******************************************** Refresh Table ******************************************/
public refresh() {
  //debugger;
  this.onGridReady(this.gridParams);
}



/******************************************** Clear All ******************************************/
public clearAll() {
  this.filters = this.returnFilterObject();

  this.refresh();
}

/******************************************** View Cell ******************************************/
public viewCell($event: any) {
  const field = $event.colDef.field;

  if (field === "siteNumber") {
    this.sitesService.select($event.data);
  }
}


/******************************************** Table Context Menu ******************************************/
public getContextMenuItems = (params: any) => {
  const col = params.column.colId;
  return this.dynamicTableColumnsService.rightClick(params);
};

/******************************************** Returns Filter Object ******************************************/
private returnFilterObject() {
  return {
    type: null,
    service_zone: null,
    category_type: null,
    geofence: null,
    project: <number | null>null,
  };
}
/******************************************** Add ALl the Subscription ******************************************/
push(obs: any) {
  super.push(obs);
}
}
