
import { of as observableOf, Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilServiceService } from '../utilService/util-service.service';

@Injectable()
export class SettingService {


  refreshList$ = new EventEmitter();
  refreshTenant$ = new EventEmitter();
  refreshOrg$ = new EventEmitter();
  private ENDPOINT_ORG_SETTINGS = "/get-org-settings/";
  private ENDPOINT_LICENSE_INFO = "/get-licenses";
  private ENDPOINT_UPDATE_ORG_SETTINGS = "/update-org-settings";
  private ENDPOINT_CONNECT_SALESFORCE = "/saleforce/authenticate";
  private ENDPOINT_DISCONNECT_SALESFORCE = "/saleforce/disconnect";
  private ENDPOINT_ORG_SF_SETTINGS = "/get-org-settings/1/salesforce-settings";
  private ENDPOINT_UPDATE_ORG_SF_SETTINGS = "/update-org-sf-settings";
  //fleet configuration
  private ENDPOINT_FLEET_CONFIG_ORG = "/fleet-configs/org";
  private ENDPOINT_FLEET_CONFIG = "/fleet-configs";

  constructor(private http: HttpClient,
    private util: UtilServiceService) {
    this.refreshList$ = new EventEmitter();
    this.refreshOrg$ = new EventEmitter();
    this.refreshTenant$ = new EventEmitter();
  }

  public getOrgSettings(id: any) {
    return this.http.get(environment.base_url + this.ENDPOINT_ORG_SETTINGS + id).pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public getPartOrders(id: any) {
    return this.http.get(environment.base_url + '/part-orders/get-all?jobOrderId=' + id).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public updateOrgSettings(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_UPDATE_ORG_SETTINGS, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public getLicenseInfo() {
    return this.http.get(environment.base_url + this.ENDPOINT_LICENSE_INFO).pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public connectToSalesforce(body: any) {
    return this.http.post(environment.base_url + this.ENDPOINT_CONNECT_SALESFORCE, body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public disconnectFromSalesforce() {
    return this.http.get(environment.base_url + this.ENDPOINT_DISCONNECT_SALESFORCE).pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      this.refreshOrg$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public getOrgSFSettings() {
    return this.http.get(environment.base_url + this.ENDPOINT_ORG_SF_SETTINGS).pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public updateOrgSFSettings(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_UPDATE_ORG_SF_SETTINGS, body, { observe: 'response' }).pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      this.refreshOrg$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public updateAlertSettings(value: any, bool: any, distance: any) {
    return this.http.put(`${environment.base_url}/update-org-settings/update-alerts/1?alertValue=${value}&alertFlag=${bool}&taskCheckinCheckoutDistanceValue=${distance}`, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  public updateUserAlertSettings(value: any, bool: any) {
    return this.http.put(`${environment.base_url}/users/alert?alertForTaskTime=${value}&isTaskAlert=${bool}`, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public getTenantList() {
    return this.http.get(environment.base_url + '/tenant-addon-features', { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshTenant$.emit(resp)
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  // -------------------------------------------fleet configuration-----------------------------------
  public getFleetConfig() {
    return this.http.get(environment.base_url + this.ENDPOINT_FLEET_CONFIG_ORG, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }

  public editFleetConfig(body: any) {
    return this.http.put(environment.base_url + this.ENDPOINT_FLEET_CONFIG, body, { observe: 'response' }).pipe(map((resp: any) => {
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      return observableOf(error);
    }),);
  }
  // -------------------------------------------JO configuration-----------------------------------
  public getJOSettings() {
    return this.http.get(environment.base_url + '/job-order-config').pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public updateJoSettings(body: any) {
    return this.http.put(environment.base_url + '/job-order-configs', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  // -------------------------------------------JO configuration-----------------------------------
  public getSOSettings() {
    return this.http.get(environment.base_url + '/sales-order-config').pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public updateSOSettings(body: any) {
    return this.http.put(environment.base_url + '/sales-order-configs', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  // -------------------------------------------Project configuration-----------------------------------
  public getProjectSettings() {
    return this.http.get(environment.base_url + '/project-configs').pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public updateProjectSettings(body: any) {
    return this.http.put(environment.base_url + '/project-configs', body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  // -------------------------------------------Site configuration-----------------------------------
  public getSiteSettings() {
    return this.http.get(environment.base_url + '/site-configs').pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public updateSiteSettings(body: any) {
    return this.http.put(environment.base_url + '/site-configs', body, { observe: 'response' }).pipe(map((resp: any) => {
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }

  // -------------------------------------------Billing-----------------------------------
  public getTransactionInfo(
    startCreatedDate: any,
    endCreatedDate: any,
    parameters: any,
  ) {
    let params = new HttpParams();
    if (startCreatedDate && endCreatedDate && startCreatedDate !== "" && endCreatedDate !== "") {
      params = params
        .set("startCreatedDate", startCreatedDate)
        .set("endCreatedDate", endCreatedDate);
    }

    if (parameters) {
      params = params
        .set("startFrom", parameters.startRow)
        .set("perPage", "25")
        .set("columnName", parameters.sortModel[0].colId)
        .set("sortOrder", parameters.sortModel[0].sort);
    }

    let url = '/here-map-credit-transactions'

    return this.http
      .get(`${environment.base_url}${url}`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }


  public getPurchaseInfo(
    startValidityDate: any,
    endValidityDate: any,
  ) {
    let params = new HttpParams();
    if (startValidityDate && endValidityDate && startValidityDate !== "" && endValidityDate !== "") {
      params = params
        .set("startValidityDate", startValidityDate)
        .set("endValidityDate", endValidityDate);
    }

    let url = '/here-map-credits'

    return this.http
      .get(`${environment.base_url}${url}`, { params: params })
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }
  public reqTopupCreditsOrUpgradeTier(type: string) {
    let params = new HttpParams();
    if (type && type !== "") {
      params = params.set("type", type);
    }
    let url = '/here-map-credits/request-credit'
    return this.http.get(`${environment.base_url}${url}`, { params: params })
      .pipe(map((resp: any) => { return resp; }),
        catchError((error) => {
          return observableOf(error);
        })
      );
  }


  // -------------------------------------------Asset configuration-----------------------------------
  public getAssetSettings() {
    return this.http.get(environment.base_url + '/assets-config').pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public updateAssetSettings(body: any) {
    return this.http.put(environment.base_url + '/assets-configs', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  // -------------------------------------------Customer configuration-----------------------------------
  public getCustomerSettings() {
    return this.http.get(environment.base_url + '/customer-config').pipe(map((resp: any) => {
      ////console.log(resp);
      // return <Admin>resp;
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
  public updateCustomerSettings(body: any) {
    return this.http.put(environment.base_url + '/customer-configs', body, { observe: 'response' }).pipe(map((resp: any) => {
      this.util.setCompanyName(resp.body.companyName);
      this.refreshList$.emit(resp);
      return resp;
    }
    ), catchError(error => {
      ////console.log(error);
      return observableOf(error);
    }),);
  }
}
