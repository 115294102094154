import { Component, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AppEvents } from "src/app/_models/global.data.model";
import { DeliveryOrderService } from "src/app/_services/delivery-order.service";
import { UtilServiceService } from "src/app/_services/utilService/util-service.service";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-view-do-details",
  templateUrl: "./view-do-details.component.html",
})
export class ViewDoDetailsComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  data: any;

  deliveryOrder: any;
  currentLineItem: any;

  allItems: any[] = [];

  constructor(
    public modalRefPlanning: BsModalRef,
    private deliveryOrderService: DeliveryOrderService,
    private util: UtilServiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Init ******************************************/
  private init() {
    this.util.getData().subscribe((data: any) => {
      if (data) {
        if (data && data.action === AppEvents.REFRESH_DO) {
          this.loadDO();
        }
      }
    });

    this.loadDO();
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Load DO ******************************************/
  private loadDO() {
    this.push(
      this.deliveryOrderService
        .getDeliveryOrderById(this.data.id)
        .subscribe((resp) => {
          if (resp) {
            this.deliveryOrder = resp;

            if (
              this.deliveryOrder.deliveryOrderLineItemVMS &&
              this.deliveryOrder.deliveryOrderLineItemVMS.length > 0
            ) {
              this.allItems = this.deliveryOrderService.buildLineOrderItems(
                this.deliveryOrder.deliveryOrderLineItemVMS
              );

              this.currentLineItem = this.allItems[0];
            }
          }
        })
    );
  }

  /******************************************** On Select Item Change ******************************************/
  public onSelectItemChange($event: any) {
    let selectedLI;

    this.allItems.forEach((doLI) => {
      if (doLI.id === $event.value) {
        selectedLI = doLI;
      }
    });

    if (selectedLI) {
      this.currentLineItem = null;
      this.currentLineItem = { ...JSON.parse(JSON.stringify(selectedLI)) };
    }
  }
  push(obs:any) {
    super.push(obs);
  }
}
