<div [ngClass]="isLoadedDynamically ? 'height-92 overflow-scroll pb-56px' : 'bin mt-5 h-97'">
    <div class="d-flex button-box-top-right" [ngClass]="isLoadedDynamically ? 'dynamicEdit' : ''">
        <ng-container *ngIf="toggleEditVisibility">
            <ng-container *ngIf="canClaim">
                <p-button label="{{'claim'|translate}}" styleClass="ms-2 h-28 dark-alt" *ngIf="update" id="btn_TixClaim" (onClick)="claimTicket($event)"></p-button>
            </ng-container>
            <p-button type="button" *ngIf="update" [label]="'edit' | translate" styleClass="ms-2 h-28"  id="btn_TixEdit" (onClick)="editTicket()"></p-button>
            <button class="btn-view-icon ms-2" pTooltip="More" tooltipPosition="bottom" *ngIf="delete" id="btn_TixDropdown" (click)="menu.toggle($event)">
                <i class="fa-regular fa-ellipsis-vertical"></i>
            </button>
            <p-slideMenu [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'" #menu [model]="items" [popup]="true" class="options2"></p-slideMenu>
        </ng-container>
    </div> {{isLoadedDynamically}}--{{toggleEditVisibility}}
    <p-tabView class="px-0" (onChange)="handleChange($event, tabView)" [(activeIndex)]="activeIndex">
        <p-tabPanel class="details" [header]="'details' | translate" id="title_TixDetails">
            <app-ticket-details *ngIf="loadDetail" [ticketsId]="id" [ticketDetails]="ticketData"></app-ticket-details>
        </p-tabPanel>
        <p-tabPanel class="details" [header]="'conversation' | translate" id="title_TixConv">
            <app-conversation *ngIf="loadConversation" [ticketId]="id"></app-conversation>
        </p-tabPanel>
        <p-tabPanel [header]="'job_order' | translate" id="title_TixJob" *ngIf="(jobsAccess && menuType === 'service' && servicePer) || (jobsAccess && menuType === 'sales' && servicePer)">
            <app-ticket-job-orders *ngIf="loadJobOrder" [loadedDymanically]="isLoadedDynamically" [ticketId]="id"></app-ticket-job-orders>
        </p-tabPanel>
        <p-tabPanel [header]="'history' | translate" id="title_TixHistory">
            <app-ticket-history *ngIf="loadTicketHistory" [loadedDymanically]="isLoadedDynamically" [ticketId]="id"></app-ticket-history>
        </p-tabPanel>
        <p-tabPanel [header]="'sla' | translate" id="title_TixSla">
            <app-sla-table *ngIf="loadSLA" [loadedDymanically]="isLoadedDynamically" [ticketId]="id"></app-sla-table>
        </p-tabPanel>
        <p-tabPanel [header]="'attachments' | translate" id="title_TixAttachments">
            <app-ticket-attachment *ngIf="loadTicketAttachment" [ticketId]="id"></app-ticket-attachment>
        </p-tabPanel>
    </p-tabView>

    <template #componentContainer> </template>
</div>
