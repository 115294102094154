import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-check-availability',
  templateUrl: './check-availability.component.html'
})
export class CheckAvailabilityComponent implements OnInit {
  files !: any[];
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    this.files=[
      {name: '50ft Truck', category: '50 Feet Truck', model: 'Honda'}
    ]
  }

}
