import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { GridOptions, IServerSideGetRowsParams } from "ag-grid-community";
import { Subscription } from "rxjs";
import { InventoryService } from "src/app/_services/inventory.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";
import { TableHeaderToolTipComponent } from "../table-header-tool-tip/table-header-tool-tip.component";
import { ServerRequest } from "src/app/_models/global.data.model";

@Component({
  selector: "app-custella-bin-combination",
  templateUrl: "./custella-bin-combination.component.html",
  styleUrls: ["./custella-bin-combination.component.scss"],
})
export class CustellaBinCombinationComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  @Output() selecetedBinEvent = new EventEmitter();
  @Output() closeView = new EventEmitter();
  @Input() transferType: any;
  @Input() productId: any;

  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any;
  frameworkComponents: any;
  gridParams: any;
  startFrom: any;
  product: any;
  pageSize: any = 25;
  parameters: any;
  gridOptions!: Partial<GridOptions>;
  overlayNoRowsTemplate!: string;

  binTransferData: any;
  actionData: any;
  totalRecord: any;
  currentRecordNumber: any;
  refresher!: Subscription;
  searchValue: any = "";

  noData: null | boolean = null;

  constructor(
    private inventoryService: InventoryService,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private preInit() {
    this.gridOptions = {
      cacheBlockSize: 25,
      rowModelType: "infinite",
      sortingOrder: ["desc", "asc"],
      tooltipShowDelay: 100,
      onGridSizeChanged: (params) => {
        params.api.sizeColumnsToFit();
      },
    };

    this.defaultColDef = {
      enableRowGroup: false, //ag-Grid-Enterprise
      enablePivot: false, //ag-Grid-Enterprise
      enableValue: true, //ag-Grid-Enterprise
      sortable: true,
      resizable: true,
      filter: false,
      tooltipComponent: TableHeaderToolTipComponent,
    };

    this.overlayNoRowsTemplate =
      "<div class='not-found'><span>Record not found</span> </div>";
  }

  private init() {
    if (this.transferType == "LOCATION_BIN") {
      this.columnDefs = [
        {
          headerName: "Display Name",
          field: "binDisplayName",
          width: 150,
          headerTooltip: "#",
        },
        {
          headerName: "Short Code",
          field: "shortCode",
          width: 100,
          headerClass: " hide-action-border",
          headerTooltip: "#",
        },
      ];
    } else {
      this.columnDefs = [
        {
          headerName: "User Name",
          field: "binDisplayName",
          width: 150,
          headerTooltip: "#",
        },
        {
          headerName: "Short Code",
          field: "shortCode",
          width: 100,
          headerClass: " hide-action-border",
          headerTooltip: "#",
        },
      ];
    }
  }

  /********************************** Search Data **************************/
  quickSearch() {
    this.onGridReady(this.gridParams);
  }
  /********************************** View Singal Record **************************/
  onGridReady(params: any) {
    //console.log(params)
    if (params != undefined) {
      this.gridParams = params;

      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      var dataSource = {
        getRows: (params: IServerSideGetRowsParams) => {
          this.getServerSideData(params);
        },
      };

      this.gridApi.sizeColumnsToFit();
      this.gridApi.setDatasource(dataSource);
    }
  }

  /********************************** Call Customer API to Display data **************************/
  getServerSideData(agGridGetRowsParams: IServerSideGetRowsParams) {
    // this.spinner.show()
    this.gridApi.hideOverlay();
    const serverRequest = this.getRequestParams(agGridGetRowsParams);

    let binParams = {};

    var transferType = "user";

    if (this.transferType == "LOCATION_BIN") {
      transferType = "location";
      binParams = Object.assign(binParams, { transferType: transferType });
    } else if (this.transferType == "USER_BIN") {
      transferType = "user";
      binParams = Object.assign(binParams, { transferType: transferType });
    }

    if (this.productId) {
      binParams = Object.assign(binParams, { productId: this.productId });
    }

    this.inventoryService
      .getAllFromBinCombination(this.searchValue, serverRequest, binParams)
      .subscribe(
        (data: any) => {
          console.log(data.body);
          this.binTransferData = data.body.data;
          this.totalRecord = data.body.total;

          this.setGridData(
            agGridGetRowsParams,
            this.binTransferData,
            data.body.total,
            data.body.status
          );
        },
        (err) => {
          this.errorUtil.setErrorMessage(err);
        }
      );
  }

  /********************************** Set Data for Grid Table **************************/
  private setGridData(
    agGridGetRowsParams: IServerSideGetRowsParams,
    resultItems: any[],
    totalCount: number,
    status: string
  ) {
    console.log(resultItems, "===");

    if (status === "NO_DATA") {
      this.noData = true;

      this.gridApi.showNoRowsOverlay();
    } else {
      this.noData = false;

      if (this.binTransferData.length === 0) {
        this.gridApi.showNoRowsOverlay();
      } else {
        agGridGetRowsParams.successCallback(this.binTransferData, totalCount);
       ///CS-4758 this.gridApi.sizeColumnsToFit();
      }
    }
  }
  /********************************** Request Parameter for api **************************/

  getRequestParams(agGridRequest: any): ServerRequest {
    if (agGridRequest.sortModel.length <= 0) {
      this.parameters = { colId: "binDisplayName", sort: "desc" };
      agGridRequest.sortModel.push(this.parameters);
    }

    //console.log(agGridRequest)
    this.currentRecordNumber = agGridRequest.startRow;
    return {
      startRow: agGridRequest.startRow,
      pageSize: 25,
      filterModel: null,
      sortModel: agGridRequest.sortModel,
    };
  }
  /********************************** View Singal Record **************************/

  selectLocationBinData(e: any) {
    this.selectLocationBin(e.data);
  }
  selectLocationBin(val: any) {
    this.selecetedBinEvent.emit(val);
  }

  closeBin() {
    this.closeView.emit(false);
  }

  push(obs: any) {
    super.push(obs);
  }
}
