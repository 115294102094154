import { Component, EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { AuthenticationService } from "src/app/auth/authentication.service";
import { ShiftsService } from "src/app/_services/shifts.service";
import { ErrorUtil } from "src/app/_utilities/error";
import { SubscriptionUtil } from "src/app/_utilities/subscription";

@Component({
  selector: "app-accept-reject-shift",
  templateUrl: "./accept-reject-shift.component.html",
  styleUrls: ["./accept-reject-shift.component.scss"],
})
export class AcceptRejectShiftComponent
  extends SubscriptionUtil
  implements OnInit, OnDestroy
{
  data: any;
  timezone!: string;
  dateFormat: any;
  start: any;
  end: any;
  formData: any = {
    cancellationReason: "",
    userShift: {
      id: null,
    },
  };

  afterEvent = new EventEmitter();

  constructor(
    public modalRef: BsModalRef,
    private auth: AuthenticationService,
    private shiftService: ShiftsService,
    private errorUtil: ErrorUtil
  ) {
    super();

    this.preInit();
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.onDestroy();
  }

  /******************************************** Pre Init ******************************************/
  private preInit() {
    this.timezone = this.auth.getUserTimezone();
  }
  
  /******************************************** Init ******************************************/
  private init() {
    console.log(this.data);
    this.dateFormat = localStorage.getItem("date_format");
  }

  /******************************************** On Destroy ******************************************/
  private onDestroy() {
    super.ngOnDestroy();
  }

  /******************************************** Submit - To Request for Cancelation ******************************************/
  public onSubmit() {
    this.formData.userShift.id = this.data.userShiftsId;

    if (this.formData.cancellationReason && (this.formData.cancellationReason === "" || this.formData.cancellationReason.length === 0)) {
      this.formData.cancellationReason = null;
    }

    this.push(
      this.shiftService.cancelShift(this.formData).subscribe((res: any) => {
        if (res.status === 200 || res.status === 201) {
          this.afterEvent.emit({
            status: "SUCCESS",
            from: "accept-reject-shift",
          });

          this.modalRef.hide();

          this.errorUtil.setErrorMessage(
            200,
            "Shift cancelled successfully",
            null,
            "success",
            1000
          );
        } else {
          this.errorUtil.setErrorMessage(
            res.status,
            null,
            res.error.title,
            "error",
            2000
          );
        }
      })
    );
  }

  /******************************************** Reject ******************************************/
  public rejectShift(status: any) {
    this.formData.userShift.id = this.data.userShiftsId;

    if (this.formData.cancellationReason && (this.formData.cancellationReason === "" || this.formData.cancellationReason.length === 0)) {
      this.formData.cancellationReason = null;
    }

    this.push(
      this.shiftService
        .rejectShift(this.data.taskId, status)
        .subscribe((res: any) => {
          if (res === "null" || res === null || res === " ") {
            let val =
              status === "REJECT_CANCELLATION" ? "rejected" : "accepted";
            this.modalRef.hide();

            this.errorUtil.setErrorMessage(
              200,
              "Shift " + val + " successfully",
              null,
              "success",
              2000
            );
          } else {
            this.errorUtil.setErrorMessage(
              res.status,
              null,
              res.error.title,
              "error",
              2000
            );
          }
        })
    );
  }
   /******************************************** Add ALl the Subscription ******************************************/
  push(obs:any) {
    super.push(obs);
  }
}
