<div class="custella-modal">
    <form>
        <div class="custella-modal-header align-items-center">
            <span class="text-14 font-medium ms-2" id="title_SetImportDataList"> {{ "import_data" | translate }} </span>
            <i class="pi pi-times" id="btn_SetImportDataListClose" (click)="modalRef.hide()"></i>
        </div>
        <!-- Container -->
        <div class="custella-form-container position-relative d-block w-100 rounded-2 pb-3 m-0"
            style="height: fit-content;">
            <!--  -->
            <div class="py-2">
                <div class="d-flex align-items-center justify-content-center m-4">
                    <div class="custella-import-checkpoint ms-4" id="label_SetImportDataListCheck1"></div>
                    <div class="custella-import-path" id="label_SetImportDataListGap1"></div>
                    <div class="custella-import-checkpoint" id="label_SetImportDataListCheck2"></div>
                    <div class="custella-import-path" id="label_SetImportDataListGap2"></div>
                    <div class="custella-import-checkpoint" id="label_SetImportDataListCheck3"></div>
                    <div class="custella-import-path-dash" id="label_SetImportDataListGap3"></div>
                    <div class="custella-import-uncheckpoint me-4" id="label_SetImportDataListUncheck1"></div>
                </div>
                <div class="d-flex align-items-center justify-content-center m-4">
                    <span class="text-12 font-medium ms-4" id="label_SetImportDataListSelect" translate>{{'select'}}</span>
                    <div class="custella-import-path-none" id="label_SetImportDataListNon1"></div>
                    <span class="text-12 font-medium" id="label_SetImportDataListUpload" translate>{{'upload'}}</span>
                    <div class="custella-import-path-none" id="label_SetImportDataListNon2"></div>
                    <span class="text-12 font-medium" id="label_SetImportDataListMapping" translate>{{'mapping'}}</span>
                    <div class="custella-import-path-none" id="label_SetImportDataListNon3"></div>
                    <span class="text-12 font-medium me-4" id="label_SetImportDataListListImport" translate>{{'import'}}</span>
                </div>
            </div>

            <div class="custella-content-row pe-0">
                <div class="custella-content-row-input col-12">
                    <div
                        class="custella-form-container-title-two-grey d-flex justify-content-between align-items-center">
                        <span class="text-14 font-medium" id="title_SetImportDataListMapping" translate>{{"mapping"}}</span>
                    </div>
                </div>
            </div>

            <div class="custella-form-container-content">
                <!-- Update Fields -->
                <div class="custella-content-row pe-0" *ngIf="type=='update'">
                    <div class="custella-content-row-input col-12">
                        <span class="custella-content-row-input-title" id="label_SetImportDataListUpdateFields" translate>{{'Update fields by'}}</span>
                        <p-dropdown [options]="keysByModules" [showClear]="true" optionLabel="name" optionValue="field" id="picklist_SetImportDataListUpdateFieldsField"
                            name="selectedKey" [(ngModel)]="selectedKey" placeholder="Select Field" >
                            <ng-template let-user pTemplate="item">
                                <div class="ticket-owner-user-list" id="value_SetImportDataListUpdateFieldsField">
                                    <div>{{ user.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <!-- Title -->
                <div>
                    <div class="border overflow-scroll position-relative d-block rounded-2" style="height: 40vh;">
                        <p-table [value]="fileHeaderValues" responsiveLayout="scroll">
                            <ng-template pTemplate="header">
                                <tr class="">
                                    <th class="col-2" id="label_SetImportDataListCsvHead" translate>{{'csv_header'}}</th>
                                    <th class="col-2" id="label_SetImportDataListCustellaHead" translate>{{'custella_header'}}</th>
                                    <th class="col-2" id="label_SetImportDataListSample" translate>{{'sample'}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-itm let-i="rowIndex">
                                <tr class="text-12 font-medium">
                                    <td id="value_SetImportDataListCsvHead">{{itm?.csvHeaderName}}</td>
                                    <td>
                                        <div *ngIf="!itm.deleteClick && itm.custellaHeader"
                                            class="flex-between">
                                            <p class="mb-0 text-2 py-2" id="value_SetImportDataListCustellaHead">{{itm?.custellaHeader}}</p>
                                            <i class="pi pi-trash" style="font-size: 1rem" id="value_SetImportDataListCustellaHeadRemove" (click)="delete(itm,i)"></i>
                                            <!-- <img class="hand pl-2 pr-3" style="height: 15px;"  src="../../../../assets/images/delete-black.png" alt=""> -->
                                        </div>

                                        <p-dropdown [options]="allList[i]" [showClear]="false" optionLabel="name"
                                            optionValue="name"
                                            [(ngModel)]="selectedFileHeaderValues[itm?.csvHeaderIndex]"
                                            name="selectedFileHeaderValues{{itm?.csvHeaderIndex}}"
                                            (onChange)="onModuleFieldChanged($event,itm,i)"
                                            placeholder="Select field to map to"
                                            *ngIf="itm.deleteClick || !itm.custellaHeader" id="picklist_SetImportDataListCustellaHeadField">
                                            <ng-template let-field pTemplate="item">
                                                <div class="ticket-owner-user-list" id="value_SetImportDataListCustellaHeadField">
                                                    <div>{{ field.name }}</div>
                                                </div>
                                            </ng-template>
                                        </p-dropdown>

                                        <div *ngIf="itm.lookupData && itm.lookupData?.length>0"
                                            class="mt-1  align-items-center">
                                            <div class="col-2 px-0 w-100">
                                                <span>
                                                    <div class="p-field-checkbox">
                                                        <p-checkbox [(ngModel)]="itm.lookUpChk"
                                                            [name]="selectedModuleFieldList[i]" [value]="itm.lookUpChk"
                                                            [binary]="true" inputId="ny2"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (onChange)="lookupChkChange(i,itm)" id="check_SetImportDataListCustellLookup">
                                                        </p-checkbox>
                                                        <label for="ny2" style="margin-left: 5px;" id="label_SetImportDataListCustellLookup">Lookup via:</label>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-2 px-0 w-75 p-2" style="margin-left: 10%;"
                                            *ngIf="itm.lookupData && itm.lookupData?.length>0 && itm.lookUpChk">
                                            <p-dropdown [options]="itm.lookupData" [showClear]="false" id="picklist_SetImportDataListCsvHeadField"
                                                optionLabel="name" optionValue="id" name="lookup{{itm?.csvHeaderIndex}}"
                                                [(ngModel)]="itm.lookup" placeholder="{{'select-l'|translate}}">
                                                <ng-template let-field pTemplate="item">
                                                    <div class="ticket-owner-user-list" id="value_SetImportDataListCsvHeadField">
                                                        <div>{{ field.name }}</div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div *ngIf="itm.lookUpChk" class="d-flex py-1 align-items-center">
                                            <div class="d-flex" style="width: 90%;">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [(ngModel)]="itm.errorIfMultiple"
                                                        [name]="selectedModuleFieldList[i]"
                                                        [value]="itm.errorIfMultiple" [binary]="true" inputId="ny1"
                                                        [ngModelOptions]="{standalone: true}" id="check_SetImportDataListModuleField">
                                                    </p-checkbox>
                                                    <label for="ny1" style="margin-left: 5px;" id="label_SetImportDataListMarkRecord">Mark record with an error
                                                        if more than one match is found</label>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12"><p class="mb-0 pl title-font">Mark record with an error if more than one match is found</p></div> -->
                                        </div>

                                        <div *ngIf="itm.lookUpChk" class="d-flex py-1 align-items-center">
                                            <div class="d-flex">
                                                <div class="p-field-checkbox">
                                                    <p-checkbox [(ngModel)]="itm.allowNull" id="check_SetImportDataListModule"
                                                        [name]="selectedModuleFieldList[i]" [value]="itm.allowNull"
                                                        [binary]="true" inputId="ny"
                                                        [ngModelOptions]="{standalone: true}">
                                                    </p-checkbox>
                                                    <label for="ny" style="margin-left: 5px;" id="label_SetImportDataListModule">Insert null value(s) if no
                                                        match is found</label>
                                                </div>
                                            </div>
                                        </div>

                                    </td>

                                    <!-- <td class="col-2 text-center"><span
                                            class="p-mr-2 p-tag-success p-tag p-component"><span
                                                class="p-tag-value">Active</span></span></td> -->
                                    <td>{{itm?.csvSampleValue}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
            <div class="custella-form-container-footer-two">
                <div class="d-flex align-items-center justify-content-end">
                    <button class="custella-btn-white text-12 ms-2" id="btn_SetImportDataListCancel" (click)="goBack()">Back</button>
                    <p-button [label]="buttonLabel" id="btn_SetImportDataListSubmit" (click)="fieldsMapped()" styleClass="ms-2 h-32"></p-button>
                </div>
            </div>
        </div>
    </form>
</div>